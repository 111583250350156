import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: {
    width: 'fit-content',
  },

  select: {
    width: '180px',
    height: '50px',
  },
}));

export default function SelectSkeleton(): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Skeleton className={classes.select} />
    </Box>
  );
}
