import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    width: '100%',

    '@media (min-width: 768px)': {
      minHeight: 286,
      minWidth: 575,
      maxWidth: 575,
    },
  },
  header: {
    marginBottom: 6,
  },
  addressSection: {
    paddingTop: 10,
  },
  existingAddress: {
    width: 340,
  },
  label: {
    marginBottom: 5,
  },
  field: {
    width: 'inherit',
  },
  multiInputLine: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    gap: '5%',
  },
  oneLine: {
    width: '100%',
  },
  amountLabel: {
    marginBottom: theme.spacing(3),
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    gap: theme.spacing(6),
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),
    gap: theme.spacing(5),
  },
  button: {
    minWidth: 140,
  },
}));
