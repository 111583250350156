import React from 'react';

type RowCheckedAction = { type: 'rowChecked'; payload: string };
type RowUncheckedAction = { type: 'rowUnchecked'; payload: string };
type EnableSelectModeAction = { type: 'selectModeEnabled' };
type DisableSelectModeAction = { type: 'selectModeDisabled' };
type RowTransferCheckedAction = {
  type: 'rowTransferChecked';
  // TODO: This needs to be strongly typed, ParentAsset and AssetInstance are both types it would need to accept.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Record<string, any>;
};
type RowTransferUncheckedAction = { type: 'rowTransferUnchecked'; payload: number | string | undefined };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AllTransferRowsCheckedAction = { type: 'allTransferRowsChecked'; payload: Record<string, any>[] };
type AllTransferRowsUncheckedAction = { type: 'allTransferRowsUnchecked'; payload: Array<{ id: string }> };
type EnableSelectTransferModeAction = { type: 'selectTransferModeEnabled' };
type OpenTransferModalAction = { type: 'setOpenTransferModal'; payload: boolean };
type ResetStateAction = { type: 'reset' };
type Action =
  | RowCheckedAction
  | RowUncheckedAction
  | EnableSelectModeAction
  | DisableSelectModeAction
  | AllTransferRowsCheckedAction
  | RowTransferCheckedAction
  | RowTransferUncheckedAction
  | OpenTransferModalAction
  | EnableSelectTransferModeAction
  | AllTransferRowsUncheckedAction
  | ResetStateAction;
type Dispatch = React.Dispatch<Action>;
export type SelectedRowsState = {
  selectedRows: Array<string>;
  selectedTransferedRows: Array<Record<string, unknown>>;
  selectModeActive: boolean;
  selectTransferModeActive: boolean;
  expandedTransferContent: boolean;
  parentRowChecked: boolean | null;
  limitReached: boolean;
  maxRows: number;
  instanceId: string;
  rowId: string | undefined;
  subRowUnChecked: boolean;
  openTransferModal: boolean;
};
type Reducer = (state: SelectedRowsState, action: Action) => SelectedRowsState;
type SelectedRowsProviderProps = {
  children: React.ReactNode;
  defaultState?: Partial<SelectedRowsState>;
};

// eslint-disable-next-line consistent-return
const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      return {
        instanceId: '',
        maxRows: 8,
        openTransferModal: false,
        rowId: undefined,
        subRowUnChecked: false,
        selectedRows: [],
        selectedTransferedRows: [],
        selectModeActive: false,
        selectTransferModeActive: false,
        limitReached: false,
        parentRowChecked: false,
        expandedTransferContent: false,
      };
    }

    case 'rowChecked': {
      const selectedRows = [...state.selectedRows, action.payload];
      return {
        ...state,
        selectedRows,
        limitReached: selectedRows.length === state.maxRows,
      };
    }

    case 'rowUnchecked': {
      const indexToRemove = state.selectedRows.indexOf(action.payload);
      return {
        ...state,
        selectedRows: [...state.selectedRows.slice(0, indexToRemove), ...state.selectedRows.slice(indexToRemove + 1)],
        limitReached: false,
      };
    }

    // TRANSFER ASSET(S)

    /* Parent asset */
    case 'rowTransferChecked': {
      const selectedTransferedRows = [...state.selectedTransferedRows, action.payload];
      return {
        ...state,
        selectedTransferedRows,
        parentRowChecked: true,
        rowId: action.payload.id,
        subRowUnChecked: false,
      };
    }
    /* Parent asset */
    case 'rowTransferUnchecked': {
      const selectedTransferedRows = state.selectedTransferedRows.filter((row) => row.id !== action.payload);
      return {
        ...state,
        selectedTransferedRows,
        parentRowChecked: false,
      };
    }

    case 'allTransferRowsChecked': {
      const selectedTransferedRows = [...state.selectedTransferedRows, ...action.payload];
      // When the card component re-renders, it can push duplicated id's to the selectedTransferedRows array, therefore this logic was added
      const uniqueSelectedRows = selectedTransferedRows.filter(
        (a, i) => selectedTransferedRows.findIndex((s) => a.id === s.id) === i,
      );
      return {
        ...state,
        selectedTransferedRows: uniqueSelectedRows,
      };
    }

    case 'allTransferRowsUnchecked': {
      const idsToRemove = action.payload.map((item) => item.id);
      const itemsToRemove = state.selectedTransferedRows.filter((item) => idsToRemove.includes(item.id as string));
      const arrCopy = state.selectedTransferedRows.slice();
      // eslint-disable-next-line no-restricted-syntax, guard-for-in, no-plusplus
      for (let i = 0; i < itemsToRemove.length; i++) {
        const itemToRemove = itemsToRemove[i];
        const index = arrCopy.findIndex((item) => (item.id as number) === (itemToRemove.id as number));
        arrCopy.splice(index, 1);
      }

      return { ...state, selectedTransferedRows: arrCopy };
    }

    case 'selectTransferModeEnabled': {
      return {
        ...state,
        selectTransferModeActive: true,
        selectModeActive: false,
        expandedTransferContent: true,
      };
    }
    case 'setOpenTransferModal': {
      return {
        ...state,
        openTransferModal: action.payload,
      };
    }
    // COMMON
    case 'selectModeEnabled': {
      return {
        ...state,
        selectModeActive: true,
        selectTransferModeActive: false,
        expandedTransferContent: false,
      };
    }

    case 'selectModeDisabled': {
      return {
        ...state,
        selectedRows: [],
        selectedTransferedRows: [],
        selectModeActive: false,
        selectTransferModeActive: false,
        limitReached: false,
        parentRowChecked: false,
        expandedTransferContent: false,
      };
    }
  }
};

const SelectedRowsContext = React.createContext<{ state: SelectedRowsState; dispatch: Dispatch } | undefined>(
  undefined,
);

function SelectedRowsProvider({ children, defaultState = {} }: SelectedRowsProviderProps): JSX.Element {
  const initialState: SelectedRowsState = {
    selectedRows: [],
    selectedTransferedRows: [],
    selectModeActive: false,
    limitReached: false,
    selectTransferModeActive: false,
    maxRows: 8,
    expandedTransferContent: false,
    parentRowChecked: null,
    rowId: undefined,
    subRowUnChecked: false,
    instanceId: '',
    openTransferModal: false,
    ...defaultState,
  };
  const [state, dispatch] = React.useReducer<Reducer, SelectedRowsState>(reducer, initialState, () => initialState);
  return <SelectedRowsContext.Provider value={{ dispatch, state }}>{children}</SelectedRowsContext.Provider>;
}

function useSelectedRowsContext(): { state: SelectedRowsState; dispatch: Dispatch } {
  const context = React.useContext(SelectedRowsContext);
  if (context === undefined) {
    throw new Error('useSelectedRowsContext must be used within a SelectedRowsProvider');
  }

  return context;
}

export { SelectedRowsProvider, useSelectedRowsContext };
