import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ErrorImage from '../../assets/images/error.png';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 600,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  text: {
    marginTop: 20,
  },
}));

type ErrorPlaceholderProps = { error: string; action?: React.ReactNode; styleContainer?: string; styleText?: string };

export default function ErrorPlaceholder({
  error,
  action,
  styleContainer,
  styleText,
}: ErrorPlaceholderProps): JSX.Element {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${styleContainer}`}>
      <div>
        <img src={ErrorImage} alt="error" />
      </div>
      <Typography customVariant="h3" className={`${classes.text} ${styleText}`}>
        {error}
      </Typography>
      {action && action}
    </div>
  );
}
