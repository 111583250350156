import { Namespace, Resources, TFunction } from 'react-i18next';
import * as yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';

enum ModelKey {
  Amount = 'amount',
}
type ModelValidationSchemaType = Record<string, RequiredStringSchema<string | undefined, Record<string, unknown>>>;

export const getInsertAmountValidationSchema = (
  t: TFunction<Namespace<keyof Resources>>,
): ModelValidationSchemaType => ({
  [ModelKey.Amount]: yup
    .string()
    .test(
      'amount',
      t('wallet:topUpModal.amount.error.greaterThanZero'),
      (amount) => amount === undefined || Number(amount) > 0,
    )
    .required(t('wallet:topUpModal.amount.error.noAmountEntered')),
});
