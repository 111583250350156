import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import BaseTextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '../Typography';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';

export default function TextField({ helperText, className, style, error, ...rest }: TextFieldProps): JSX.Element {
  const { InputProps = {}, ...moreElem } = rest;
  return (
    <div className={className} style={style}>
      <BaseTextField
        fullWidth
        error={error}
        {...moreElem}
        InputProps={{
          endAdornment: error ? (
            <InputAdornment position="end">
              <WarningIcon />
            </InputAdornment>
          ) : undefined,
          ...InputProps,
        }}
      />
      {helperText && (
        <Typography customVariant="subtitle4" zellarColour="textFailure">
          {helperText}
        </Typography>
      )}
    </div>
  );
}
