import makeStyles from '@mui/styles/makeStyles';

export const useAddressFormStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    gap: theme.spacing(6),
  },
  oneLine: {
    width: '100%',
  },
  multiInputLine: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    rowGap: theme.spacing(6),

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      columnGap: '5%',
    },
  },
  field: {
    width: 'inherit',
  },
  submitButton: {
    height: 'fit-content',
    padding: '7px 34px',
    marginLeft: 'auto',
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      alignSelf: 'flex-end',
    },
  },
  select: {
    width: '200px',
  },
  label: {
    marginBottom: 5,
  },
}));
