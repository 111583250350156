import React from 'react';
import { useTranslation } from 'react-i18next';
import { deltaPercentage } from '../../utils/deltaPercentage';
import Typography from '../Typography';

interface PercentageChangeProps {
  value: number | undefined;
}

export default function MarketPricePercentageChange({ value }: PercentageChangeProps): JSX.Element {
  const { t } = useTranslation();
  const { zellarColour, deltaSymbol } = deltaPercentage(value);
  return (
    <Typography customVariant="subtitle2" zellarColour={zellarColour}>
      {deltaSymbol}
      {value === undefined ? '' : `${value.toFixed(2)}% ${t('product:lastTwelveMonths')}`}
    </Typography>
  );
}
