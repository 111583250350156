import {
  AddressType,
  billingCountrySelectOptions,
  Country,
  DeliveryCountry,
  deliveryCountrySelectOptions,
  isUndefined,
  SelectOption,
} from '@cultwines/zellar-client-sdk';
import { Address } from '../../__generated__/graphql';

export function selectCountry(
  address: Omit<Address, 'createdDateTime'> | undefined,
  addressType: AddressType,
): SelectOption<DeliveryCountry> | SelectOption<Country> | undefined {
  if (isUndefined(address)) {
    // default to UK
    return addressType === 'Billing'
      ? billingCountrySelectOptions.find((c) => c.label === 'United Kingdom')!
      : deliveryCountrySelectOptions.find((c) => c.label === 'United Kingdom')!;
  }

  if (addressType === 'Billing') {
    return billingCountrySelectOptions.find((c) => address.country === c.label);
  }

  return deliveryCountrySelectOptions.find((c) => address.country === c.label);
}
