import React from 'react';
import ExpanderCell from '../TableElements/Controls/ExpanderCell';
import CheckboxCell from '../TableElements/Controls/CheckboxCell';
import CheckboxExpanderCell from '../TableElements/Controls/CheckboxExpanderCell';
import { SelectMode } from './types';

interface ControlsProps {
  checked?: boolean;
  selectMode?: SelectMode;
  disableCheckbox?: boolean;
  expanded: boolean;
  onCheckedChanged?: () => void;
  setExpanded: (e: boolean) => void;
}

export const Controls = ({
  selectMode,
  checked,
  expanded,
  disableCheckbox,
  onCheckedChanged,
  setExpanded,
}: ControlsProps) => {
  if (selectMode === 'transfer') {
    return (
      <CheckboxExpanderCell
        checked={checked}
        onCheckedChanged={onCheckedChanged}
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
        disabled={disableCheckbox}
      />
    );
  }

  if (selectMode === 'compare') {
    return <CheckboxCell checked={checked} onCheckedChanged={onCheckedChanged} disabled={disableCheckbox} />;
  }

  return <ExpanderCell expanded={expanded} onClick={() => setExpanded(!expanded)} />;
};
