import React from 'react';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { formatterWholeNumber } from '../../../utils/currencyFormatter';
import DotGauge from '../../DotGauge';
import { ExpandedContentProps } from '../../Table/types';
import GridItem from '../GridItem';
import { useExpandedSearchResultContentStyles } from './styles';
import { EXPANDED_SEARCH_RESULT_DATA } from './gql';
import { selectExpandedSearchResultData } from './selectors';
import DrinkingWindow from '../../WineCard/DrinkingWindow';
import EmptyPlaceholder from './EmptyPlaceholder';
import ErrorPlaceholder from './ErrorPlaceholder';
import WineImage from './WineImage';
import { isNullOrUndefined } from '../../../utils/isNullOrUndefined';

export default function ExpandedContent({ rowId }: ExpandedContentProps): JSX.Element {
  const { t } = useTranslation();
  const {
    data: rawData,
    error,
    loading,
  } = useQuery(EXPANDED_SEARCH_RESULT_DATA, {
    variables: { assetId: Number(rowId) },
  });

  const data = selectExpandedSearchResultData(rawData, t);
  const classes = useExpandedSearchResultContentStyles();
  return (
    <div className={classes.container}>
      {loading && <Skeleton variant="rectangular" width="100%" height="240px" />}
      {error && <ErrorPlaceholder error={error} />}
      {!data && !loading && !error && <EmptyPlaceholder />}
      {data && !error && (
        <>
          <WineImage region={data.region} imageUrl={data.imageFileName} />
          <div className={classes.grid}>
            <GridItem label={t('search:labels.lastTrade')} value={data.lastTradeDate ? data.lastTradeDate : '-'} />
            <GridItem
              label={t('search:labels.lastTradeValue')}
              value={!isNullOrUndefined(data.lastTradeValue) ? formatterWholeNumber.format(data.lastTradeValue) : '-'}
            />
            <GridItem label={t('search:labels.spread')} value={!isNull(data.spread) ? `${data.spread}%` : '-'} />
            <GridItem
              label={t('search:labels.drinkingWindow')}
              value={<DrinkingWindow adviceType={data.drinkingWindow.advice} text={data.drinkingWindow.text} />}
            />
            <GridItem
              label={t('search:labels.liquidityScore')}
              value={!isNull(data.liquidityScore) ? <DotGauge dotCount={5} filledDots={data.liquidityScore} /> : '-'}
            />
            <GridItem label={t('search:labels.wineType')} value={data.wineType ?? '-'} />
            <GridItem label={t('search:filters.classification')} value={data.classification ?? '-'} />
            <GridItem label={t('search:filters.producer')} value={data.producer ?? '-'} />
          </div>
        </>
      )}
    </div>
  );
}
