import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Button, Skeleton, Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AddIcon from '@mui/icons-material/Add';
import Typography from '../Typography';
import { GET_STORED_CARD_DETAILS } from '../../graphql/queries/storedCardDetails';
import { useStyles } from './styles';
import PaymentCard from '../PaymentCard';
import ConfirmDeleteCardDialog from '../PaymentCardModal/ConfirmDeleteCardDialog';
import ManageCardModal from '../ManageCardModal';
import { selectPaymentCards } from './selectors';
import TopUpModal from '../TopUpModal';
import { CardDetailResponse } from '../../__generated__/graphql';

export default function SavedCards(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { merchantInitiatedTransaction12341, addCard13226 } = useFlags();
  const { data, loading, startPolling, stopPolling } = useQuery(GET_STORED_CARD_DETAILS);
  const [cardSelectedForManagement, setCardSelectedForManagement] = React.useState<CardDetailResponse | null>(null);
  const [instrumentSelectedForDeletion, setInstrumentSelectedForDeletion] = React.useState<null | undefined | string>(
    null,
  );
  const [addPaymentCardModalOpen, setAddPaymentCardModalOpen] = React.useState(false);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      startPolling(1000);
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      stopPolling();
    }, 20000);

    return () => {
      clearInterval(intervalId);
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const cards = merchantInitiatedTransaction12341
    ? selectPaymentCards(data)
    : {
        defaultCard: null,
        savedCards: data?.paymentGetCardDetails ?? [],
      };

  function handleDeleteCardClicked(instrumentId: string | null | undefined): void {
    setInstrumentSelectedForDeletion(instrumentId);
  }

  async function handleCloseConfirmDeleteCardPrompt() {
    setInstrumentSelectedForDeletion(null);
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const CardSkeleton = () => (
    <div aria-label="display-card-skeleton">
      <Typography variant="h2">
        <Skeleton />
      </Typography>
      <Typography variant="h2">
        <Skeleton />
      </Typography>
      <Typography variant="h2">
        <Skeleton />
      </Typography>
      <Typography variant="h2">
        <Skeleton />
      </Typography>
      <Typography variant="h2">
        <Skeleton />
      </Typography>
    </div>
  );

  return (
    <>
      <div className={classes.container}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{t('wallet:savedCards')}</Typography>
          {addCard13226 && (
            <Button
              className={classes.addCardBtn}
              variant="contained"
              color="info"
              size="small"
              endIcon={<AddIcon fontSize="small" />}
              onClick={() => setAddPaymentCardModalOpen(true)}
            >
              {t('wallet:addCard')}
            </Button>
          )}
        </Stack>
        <div className={classes.cardItems}>
          {loading ? (
            <CardSkeleton />
          ) : (
            <>
              {!data?.paymentGetCardDetails?.length && (
                <div className={classes.placeholder}>{t('wallet:emptyCards')}</div>
              )}

              {!!data?.paymentGetCardDetails?.length && (
                <>
                  {cards?.defaultCard && (
                    <>
                      <Typography variant="subtitle1">{t('wallet:defaultCard')}</Typography>
                      <PaymentCard
                        key={cards?.defaultCard.instrumentId}
                        className={classes.cardItem}
                        showCheckBox={false}
                        data={cards?.defaultCard}
                        onDeleted={() => handleDeleteCardClicked(cards?.defaultCard?.instrumentId ?? null)}
                        onManageCardClicked={setCardSelectedForManagement}
                      />

                      {!!cards?.savedCards?.length && (
                        <Typography variant="subtitle1">{t('wallet:savedCards')}</Typography>
                      )}
                    </>
                  )}

                  {cards?.savedCards?.map((item) => (
                    <PaymentCard
                      key={item.instrumentId}
                      className={classes.cardItem}
                      showCheckBox={false}
                      data={item}
                      onDeleted={() => handleDeleteCardClicked(item.instrumentId)}
                      onManageCardClicked={setCardSelectedForManagement}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {cardSelectedForManagement && (
        <ManageCardModal data={cardSelectedForManagement} onClose={() => setCardSelectedForManagement(null)} />
      )}
      {instrumentSelectedForDeletion && (
        <ConfirmDeleteCardDialog
          open={Boolean(instrumentSelectedForDeletion)}
          handleClose={handleCloseConfirmDeleteCardPrompt}
          handleDone={handleCloseConfirmDeleteCardPrompt}
          instrumentId={instrumentSelectedForDeletion}
        />
      )}
      {addPaymentCardModalOpen && (
        <TopUpModal open={addPaymentCardModalOpen} onClose={() => setAddPaymentCardModalOpen(false)} isAddNewCard />
      )}
    </>
  );
}
