import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { OrderDirection } from '../../components/OrderBookModal/types';
import { GetOpenOrdersByAssetIdQuery } from '../../__generated__/graphql';

export function selectOpenOrders(
  data: GetOpenOrdersByAssetIdQuery | undefined,
  orderDirection?: OrderDirection,
): GetOpenOrdersByAssetIdQuery['openOrders'] {
  if (isUndefined(data)) {
    return [];
  }

  return isUndefined(orderDirection)
    ? data.openOrders
    : data.openOrders.filter((oo) => oo.direction === orderDirection);
}
