import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import DialogTitle from '../Dialog/DialogTitle';
import { DialogContent } from '../Dialog/DialogContent';
import Typography from '../Typography';
import TextField from '../TextField';
import { PAYMENT_REFERENCE_QUERY } from '../../graphql/queries/paymentReferenceQuery';
import { CONFIRM_MANUAL_BANK_TRANSFER } from '../../graphql/mutations/confirmManualBankTransfer';

interface BankDetailProps {
  onClose: () => void;
  onConfirmSuccess: () => void;
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      gap: theme.spacing(6),
    },
    label: {
      marginBottom: 5,
    },
    field: {
      width: 'inherit',
    },
  };
});

export default function BankDetail({ onClose, onConfirmSuccess }: BankDetailProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data: paymentRef } = useQuery(PAYMENT_REFERENCE_QUERY);

  const bankName = `${process.env.REACT_APP_BANK_NAME}`;
  const sortCode = `${process.env.REACT_APP_SORT_CODE}`;
  const accountNumber = `${process.env.REACT_APP_ACCOUNT_NUMBER}`;
  const iban = `${process.env.REACT_APP_IBAN}`;
  const bic = `${process.env.REACT_APP_BIC}`;
  const refNumber = paymentRef?.paymentReferenceNumber || '';

  const [disable, setDisable] = useState(true);

  const [confirmManualBankTransfer] = useMutation(CONFIRM_MANUAL_BANK_TRANSFER);

  const handleConfirm = async () => {
    const { data } = await confirmManualBankTransfer();
    if (data?.confirmManualBankTransfer?.isSuccess) {
      onConfirmSuccess();
    } else {
      // const errorMsg = data?.confirmManualBankTransfer.errorMessage;
    }
  };

  useEffect(() => {
    if (paymentRef?.paymentReferenceNumber) {
      setDisable(false);
    }
  }, [paymentRef]);

  return (
    <>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {t('wallet:paymentMethodModal.bankDetails.title')}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>{t('wallet:paymentMethodModal.bankDetails.description')}</Typography>
      </DialogContent>
      <form className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <div>
              <InputLabel htmlFor="bankName" className={classes.label}>
                <Typography variant="subtitle2">{t('wallet:paymentMethodModal.bankDetails.bankName')}</Typography>
              </InputLabel>
              <TextField
                id="bankName"
                name="bankName"
                aria-label="bank-name"
                value={bankName}
                disabled
                className={classes.field}
                placeholder={t('wallet:paymentMethodModal.bankDetails.bankName')}
                variant="outlined"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <InputLabel htmlFor="sortCode" className={classes.label}>
                <Typography variant="subtitle2">{t('wallet:paymentMethodModal.bankDetails.sortCode')}</Typography>
              </InputLabel>
              <TextField
                id="sortCode"
                name="sortCode"
                value={sortCode}
                disabled
                className={classes.field}
                placeholder={t('wallet:paymentMethodModal.bankDetails.sortCode')}
                variant="outlined"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <div>
              <InputLabel htmlFor="accountNumber" className={classes.label}>
                <Typography variant="subtitle2">{t('wallet:paymentMethodModal.bankDetails.accountNumber')}</Typography>
              </InputLabel>
              <TextField
                id="accountNumber"
                name="accountNumber"
                value={accountNumber}
                disabled
                className={classes.field}
                placeholder={t('wallet:paymentMethodModal.bankDetails.accountNumber')}
                variant="outlined"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <InputLabel htmlFor="IBAN" className={classes.label}>
                <Typography variant="subtitle2">{t('wallet:paymentMethodModal.bankDetails.IBAN')}</Typography>
              </InputLabel>
              <TextField
                id="IBAN"
                name="IBAN"
                value={iban}
                disabled
                className={classes.field}
                placeholder={t('wallet:paymentMethodModal.bankDetails.IBAN')}
                variant="outlined"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container item spacing={4}>
          <Grid item xs={6}>
            <div>
              <InputLabel htmlFor="BIC" className={classes.label}>
                <Typography variant="subtitle2">{t('wallet:paymentMethodModal.bankDetails.BIC')}</Typography>
              </InputLabel>
              <TextField
                id="BIC"
                name="BIC"
                value={bic}
                disabled
                className={classes.field}
                placeholder={t('wallet:paymentMethodModal.bankDetails.BIC')}
                variant="outlined"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <InputLabel htmlFor="refNumber" className={classes.label}>
                <Typography variant="subtitle2">{t('wallet:paymentMethodModal.bankDetails.refNumber')}</Typography>
              </InputLabel>
              <TextField
                id="refNumber"
                name="refNumber"
                value={refNumber}
                disabled
                className={classes.field}
                placeholder={t('wallet:paymentMethodModal.bankDetails.refNumber')}
                variant="outlined"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container item spacing={4}>
          <Grid item xs={10}>
            <Typography zellarColour="textLabel">{t('wallet:paymentMethodModal.bankDetails.hint')}</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={4}>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <Button variant="contained" color="primary" size="large" onClick={handleConfirm} disabled={disable}>
              {t('wallet:paymentMethodModal.bankDetails.confirm')}
            </Button>
          </Grid>
        </Grid>
      </form>
      <DialogContent />
    </>
  );
}
