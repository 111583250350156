import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { DateRangeVariant } from '../../types';
import PerformanceChip from './PerformanceChip';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    '@media (min-width: 800px)': {
      gap: '0px',
    },
  },
}));

interface DateRangePickerProps {
  selectedDateRangeVariant: DateRangeVariant;
  variants: DateRangeVariant[];
  dateRangeUpdated: (newDateRange: DateRangeVariant) => void;
  disableAllChips?: boolean;
  assetId?: number;
}

export default function DateRangePicker({
  dateRangeUpdated,
  selectedDateRangeVariant,
  variants,
  disableAllChips = false,
  assetId,
}: DateRangePickerProps): JSX.Element {
  const classes = useStyles();

  function handleDateRangeChanged(rangeVariant: DateRangeVariant): void {
    dateRangeUpdated(rangeVariant);
  }

  return (
    <div className={classes.container}>
      {variants.map((rangeVariant) => (
        <PerformanceChip
          key={rangeVariant}
          assetId={assetId}
          rangeVariant={rangeVariant}
          selectedDateRangeVariant={selectedDateRangeVariant}
          onClick={handleDateRangeChanged}
          disabled={disableAllChips}
        />
      ))}
    </div>
  );
}
