/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/naming-convention */
import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useQuery } from '@apollo/client';
import { calculateFee } from '@cultwines/zellar-client-sdk/utils/calculateFee';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import { Fees } from '@cultwines/zellar-client-sdk/types/Fees';
import { ModalStep } from '../Dialog/ContentSwitcher';
import BuyNowSuccessContent from './BuyNowSuccessContent';
import BuyNowFailureContent from './BuyNowFailureContent';
import BuyNowFormContent from './BuyNowFormContent';
import BuyNowSummaryContent from './BuyNowSummaryContent';
import { ProductData, Unit } from './types';
import { OrderDirection } from '../OrderBookModal/types';
import { GET_OPEN_ORDERS_BY_ASSET_ID } from '../../graphql/queries/getOpenOrdersByAssetId';
import { selectOpenOrders } from '../../graphql/selectors/selectOpenOrders';
import { ModalError } from '../../types/ModalError';
import BuyNowProcessingTransaction from './BuyNowProcessingTransaction';
import useUserDetails from '../../hooks/useUserDetails';
import { logError } from '../../utils/logger';
import { cache } from '../../graphql/cache';
import DialogContentSwitcherUnstyled from '../Dialog/ContentSwitcherUnstyled';
import useMediaQuery from '../../hooks/useMediaQuery';
import { AccountType } from '../../types/AccountType';
import { GetOpenOrdersByAssetIdQuery } from '../../__generated__/graphql';

interface ModalProps {
  assetId: number;
  open: boolean;
  onClose: () => void;
}

function selectProductData(openOrders: GetOpenOrdersByAssetIdQuery['openOrders']): ProductData | null {
  if (openOrders.length === 0) {
    return null;
  }

  // This data will be the same for all orders in this array, because they are queried by asset id.
  const assetId = openOrders[0].assetId;
  const wineName = openOrders[0].asset.vintage.wine.name;
  const vintage = openOrders[0].asset.vintage.vintage;
  const unitSize = openOrders[0].asset.unitSize;
  const unitCount = openOrders[0].asset.unitCount;

  return {
    assetId,
    wineName,
    vintage,
    unitSize: `${unitCount} x ${unitSize}`,
  };
}

function selectCheapestNUnits(orders: GetOpenOrdersByAssetIdQuery['openOrders'], qty: number): Array<Unit> {
  const flattenedSortedAsc: Unit[] = orders
    .flatMap((o) =>
      new Array<Unit>(o.outstandingQuantity).fill({
        assetId: o.assetId,
        price: o.price,
        vintage: o.asset.vintage.vintage,
        wineName: o.asset.vintage.wine.name,
      }),
    )
    .sort((a, b) => a.price - b.price);
  return flattenedSortedAsc.slice(0, qty);
}

function selectTotalAvailableAssets(orders: GetOpenOrdersByAssetIdQuery['openOrders']): number {
  return orders.map((o) => o.outstandingQuantity).reduce((prev, curr) => prev + curr, 0);
}

function getModalWidth(modalStep: ModalStep): 'xs' | 'sm' | 'md' | 'lg' | 'xl' {
  switch (modalStep) {
    case ModalStep.Success:
    case ModalStep.Failure:
    case ModalStep.Processing:
    case ModalStep.Summary: {
      return 'xs';
    }

    case ModalStep.Initial:
    default: {
      return 'md';
    }
  }
}

export default function BuyNowModal({ open, onClose, assetId }: ModalProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [currentModalStep, setCurrentModalStep] = useState(ModalStep.Initial);
  const {
    data,
    error: failedToLoadOpenOrders,
    loading: loadingOpenOrders,
  } = useQuery(GET_OPEN_ORDERS_BY_ASSET_ID, {
    variables: {
      assetId,
    },
  });
  const [clientOrderId, setClientOrderId] = React.useState<string | null>(null);
  const [error, setError] = useState<ModalError | null>(null);
  const { userDetails, error: failedToLoadUserDetails, loading: loadingUserDetails } = useUserDetails();
  const openOrders = React.useMemo(
    () => selectOpenOrders(data, OrderDirection.Offers).filter((offer) => offer.userId !== userDetails?.userId),
    [data, userDetails],
  );
  const productData = React.useMemo(() => selectProductData(openOrders), [openOrders]);
  const [quantity, setQuantity] = React.useState(1);
  const unitBreakDownItems = React.useMemo(() => selectCheapestNUnits(openOrders, quantity), [openOrders, quantity]);
  const totalUnitPrice = unitBreakDownItems.map((unit) => unit.price).reduce((prev, curr) => prev + curr, 0);
  const showFees = userDetails?.accountType !== AccountType.Bidder;
  const transactionFee = showFees ? calculateFee(totalUnitPrice, Fees.Standard) - totalUnitPrice : 0;
  const vat = transactionFee * 0.2;
  const totalUnitPricePlusFees = totalUnitPrice + transactionFee + vat;

  React.useEffect(() => {
    if (failedToLoadOpenOrders || failedToLoadUserDetails) {
      logError({
        error: failedToLoadOpenOrders || failedToLoadUserDetails || new Error('Failed to get some crucial data'),
        filename: 'BuyNowModal',
        additionalInfo: {
          failedToLoadOpenOrders: JSON.stringify(failedToLoadOpenOrders),
          failedToLoadUserDetails: JSON.stringify(failedToLoadUserDetails),
        },
      });
      setError({
        title: t('product:detailsModal.buyNow.genericError.title'),
        buttonText: t('product:detailsModal.buyNow.genericError.button'),
        message: t('product:detailsModal.buyNow.genericError.message'),
      });
    }
    if ((failedToLoadOpenOrders || failedToLoadUserDetails) && currentModalStep !== ModalStep.Failure) {
      setCurrentModalStep(ModalStep.Failure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedToLoadOpenOrders, failedToLoadUserDetails]);

  React.useEffect(() => {
    if (!loadingOpenOrders && isNull(productData) && currentModalStep !== ModalStep.Success) {
      logError({ error: new Error('productData is null'), filename: 'BuyNowModal' });
      setError({
        title: t('product:detailsModal.buyNow.genericError.title'),
        buttonText: t('product:detailsModal.buyNow.genericError.button'),
        message: t('product:detailsModal.buyNow.genericError.message'),
      });
      setCurrentModalStep(ModalStep.Failure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOpenOrders, productData]);

  function handleSubmit(): void {
    setCurrentModalStep(ModalStep.Summary);
  }
  function handleEdit(): void {
    setCurrentModalStep(ModalStep.Initial);
  }
  function handleMutationSubmit(orderId: string): void {
    setClientOrderId(orderId);
    setCurrentModalStep(ModalStep.Processing);
  }

  function handleSuccess(): void {
    setCurrentModalStep(ModalStep.Success);
    cache.evict({ fieldName: 'omsGetMyOrders' });
    cache.evict({ fieldName: 'productAssetInstances' });
    cache.evict({ fieldName: 'isUserHoldingHasAsset' });
    cache.evict({ fieldName: 'externalPortfolioUserHasAsset' });
    cache.evict({ fieldName: 'openOrders' });
  }

  function handleFormError(): void {
    setError({
      title: t('product:detailsModal.buyNow.genericError.title'),
      buttonText: t('product:detailsModal.buyNow.genericError.button'),
      message: t('product:detailsModal.buyNow.genericError.message'),
    });
    setCurrentModalStep(ModalStep.Failure);
  }

  function handleSubmitError(): void {
    setCurrentModalStep(ModalStep.Failure);
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      disableEscapeKeyDown
      maxWidth={getModalWidth(currentModalStep)}
      fullScreen={lessThanMd}
    >
      <DialogContentSwitcherUnstyled
        modalStep={currentModalStep}
        InitialComponent={
          <BuyNowFormContent
            onError={handleFormError}
            onClose={onClose}
            productData={productData}
            onSubmit={handleSubmit}
            quantity={quantity}
            maxQuantity={selectTotalAvailableAssets(openOrders)}
            quantityUpdated={setQuantity}
            totalUnitPrice={totalUnitPrice}
            totalUnitPricePlusFees={totalUnitPricePlusFees}
            transactionFee={transactionFee}
            loading={loadingOpenOrders || loadingUserDetails}
            unitBreakDownItems={unitBreakDownItems}
            vat={vat}
            showFees={showFees}
          />
        }
        SummaryComponent={
          <BuyNowSummaryContent
            onClose={onClose}
            goBack={handleEdit}
            onSuccess={handleMutationSubmit}
            onFailure={handleSubmitError}
            productData={productData!}
            quantity={quantity}
            totalUnitPrice={totalUnitPrice}
            transactionFee={transactionFee}
            totalUnitPricePlusFees={totalUnitPricePlusFees}
            loading={loadingOpenOrders}
            vat={vat}
            showFees={showFees}
          />
        }
        ProcessingComponent={
          <BuyNowProcessingTransaction
            clientOrderId={clientOrderId}
            onFailure={handleSubmitError}
            onSuccess={handleSuccess}
          />
        }
        SuccessComponent={<BuyNowSuccessContent onClose={onClose} />}
        FailureComponent={<BuyNowFailureContent onClose={onClose} error={error} />}
      />
    </Dialog>
  );
}
