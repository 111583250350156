import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  label: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    justifyContent: 'flex-start',
  },
  icon: {
    width: 32,
    height: 32,

    '& circle': {
      fill: theme.palette.navigation.background.active.main,
    },

    '& path': {
      stroke: theme.palette.navigation.background.active.contrastText,
    },
  },
}));

interface LabelProps {
  Icon: React.FC<{ className?: string }>;
  text: string;
}

export default function Label({ Icon, text }: LabelProps): JSX.Element {
  const classes = useStyles();
  return (
    <Typography customVariant="buttonLarge" className={classes.label}>
      <Icon className={classes.icon} />
      {text}
    </Typography>
  );
}
