import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { formatter } from '../../utils/currencyFormatter';

export type AssetInstance = {
  id: number;
  wineName: string;
  unitSize: string;
  purchasedPrice: number;
  currentLocation: string;
  marketValue?: number;
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 280,
  },
}));

interface TransferWineProps {
  assetInstances: AssetInstance[];
}

export default function TransferWineTable({ assetInstances }: TransferWineProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <div>{t('portfolio:transferToExternalLocation.wineName')}</div>
            </TableCell>
            <TableCell>
              <div>{t('portfolio:transferToExternalLocation.units')}</div>
            </TableCell>
            <TableCell>
              <div>{t('portfolio:transferToExternalLocation.purchasedPrice')}</div>
            </TableCell>
            <TableCell>
              <div>{t('portfolio:transferToExternalLocation.currentLocation')}</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assetInstances.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <Box fontWeight="fontWeightBold">{item.wineName}</Box>
              </TableCell>
              <TableCell>
                <Box fontWeight="fontWeightBold">{item.unitSize}</Box>
              </TableCell>
              <TableCell>
                <Box fontWeight="fontWeightBold">{formatter.format(item.purchasedPrice)}</Box>
              </TableCell>
              <TableCell>
                <Box fontWeight="fontWeightBold">{item.currentLocation}</Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
