/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { HighchartsChart } from '../../types/Highcharts';
import { CallToAction } from './CallToAction';
import PercentageTooltip from './PercentageTooltip';
import Tooltip from './Tooltip';
import { MarketComparisonProductDisplay } from './types';
import { dateFormatDisplayMonthAndYear } from '../../utils/dateFormatter';
import Logo from '../../assets/images/logo-grey.svg';
import calculateWatermarkPosition from '../../utils/calculateWatermarkPosition';

interface Props {
  data: Array<{ assetId: number; data: Highcharts.SeriesAreasplineOptions['data'] }>;
  onAddClicked: () => void;
  assetIds: number[];
  colorByAssetId: Record<string, string>;
  seriesNames: MarketComparisonProductDisplay[];
  title: string;
  min: number;
  max: number;
  loading: boolean;
  isAbsoluteChart?: boolean;
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
}));

export default function MultiSeriesLineChart({
  data: seriesData,
  onAddClicked,
  assetIds,
  colorByAssetId,
  seriesNames,
  title,
  min,
  max,
  loading,
  isAbsoluteChart = true,
}: Props): JSX.Element {
  const [options, setOptions] = useState<Highcharts.Options | null>(null);
  const theme = useTheme();
  const chartRef = React.createRef<{ chart: HighchartsChart; container: React.RefObject<HTMLDivElement> }>();
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (loading) {
      chart?.hideNoData();
      chart?.showLoading();
    } else {
      chart?.hideLoading();
    }
  }, [loading, chartRef]);

  useEffect(() => {
    const series = seriesData.map((d) => {
      const selectedSeriesName = seriesNames.find((name) => name.assetId === d.assetId);
      return {
        type: 'spline',
        data: d.data,
        name: selectedSeriesName?.displayName,
        color: colorByAssetId[d.assetId],
        fillOpacity: 0.2,
      };
    }) as Highcharts.SeriesOptionsType[];
    let watermark: Highcharts.SVGElement | null = null;

    setOptions({
      loading: {
        showDuration: 333,
      },
      series,
      chart: {
        events: {
          load() {
            const chart = this;
            watermark = chart.renderer.image(Logo).attr(calculateWatermarkPosition(chart)).add();
          },
          render() {
            const chart = this;
            watermark?.attr(calculateWatermarkPosition(chart));
          },
        },
      },
      plotOptions: {
        spline: {
          lineWidth: 3,
        },
      },
      yAxis: {
        min: isAbsoluteChart ? 0 : min,
        max,
        startOnTick: false,
        title: {
          text: title,
          style: {
            color: theme.palette.label,
          },
        },
        labels: {
          formatter: isAbsoluteChart
            ? undefined
            : function () {
                return this.value < 0 ? `${this.value}%` : `+${this.value}%`;
              },
          style: {
            color: theme.palette.label,
          },
        },
        gridLineDashStyle: 'Dash',
      },
      xAxis: {
        tickWidth: 0,
        labels: {
          y: 32,
          style: {
            textTransform: 'uppercase',
            color: theme.palette.label,
            fontSize: theme.typography.body3Bold.fontSize?.toString(),
            fontWeight: theme.typography.body3Bold.fontWeight?.toString(),
          },
          formatter() {
            return dateFormatDisplayMonthAndYear(new Date(this.value));
          },
        },
      },
      lang: {
        noData: t('product:noData'),
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          // Make label invisible if there are assets selected
          // Changing noData label to undefined does not work dynamically
          color: assetIds.length ? theme.palette.textPrimary : theme.palette.primary.contrastText,
        },
      },
      tooltip: {
        split: true,
        useHTML: true,
        borderColor: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
        style: {
          color: theme.palette.text.primary,
          textAlign: 'center',
        },
        formatter() {
          const tooltips = this.points?.length
            ? this.points.map((point) =>
                renderToString(isAbsoluteChart ? <Tooltip point={point} /> : <PercentageTooltip point={point} />),
              )
            : [];
          return [`<b>${dateFormatDisplayMonthAndYear(new Date(this.x))}</b>`, ...tooltips];
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesData, seriesNames, title, theme, max, min, assetIds, colorByAssetId, isAbsoluteChart]);

  return (
    <div className={classes.container}>
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
      {!assetIds.length && <CallToAction handleClick={onAddClicked} />}
    </div>
  );
}
