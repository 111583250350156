import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { selectWalletBalance } from '../components/WalletSummary/selectors';
import { WALLET_BALANCE_QUERY } from '../graphql/queries/walletBalance';
import { FinanceCurrentBalanceQuery, FinancialOperationCurrentBalance } from '../__generated__/graphql';

interface UseWalletBalanceHookResponse {
  walletBalance: number | null;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<ApolloQueryResult<FinanceCurrentBalanceQuery>>;
}

/**
 * AKA funds on account
 * @returns
 */
export const useWalletBalance = (): UseWalletBalanceHookResponse => {
  const { data, loading, error, refetch } = useQuery(WALLET_BALANCE_QUERY, {
    pollInterval: 2000,
  });

  const walletBalance = useMemo(
    () => selectWalletBalance(data?.financeCurrentBalance as FinancialOperationCurrentBalance),
    [data],
  );

  return { walletBalance, loading, error, refetch };
};
