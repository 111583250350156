import React from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import Routes from './Routes';

function App(): JSX.Element {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID!,
})(App);
