import React from 'react';
import TableCell, { TableCellProps as MuiTableCellProps } from '@mui/material/TableCell';
import { SortDirection } from '@cultwines/zellar-client-sdk/types';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import styled from '@emotion/styled';
import { IconButton, IconButtonProps } from '@mui/material';
import CalculationToolTip from '../CalculationToolTip';

const TableCellInner = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
});

const ArrowDropDownButton = (props: IconButtonProps) => {
  return (
    <IconButton {...props} sx={{ p: 0.5 }}>
      <ArrowDropDown />
    </IconButton>
  );
};

const ArrowDropUpButton = (props: IconButtonProps) => {
  return (
    <IconButton {...props} sx={{ p: 0.5 }}>
      <ArrowDropUp />
    </IconButton>
  );
};

interface TableCellHeadProps {
  sortActive?: boolean;
  sortDirection?: SortDirection;
  onSortButtonClicked?: () => void;
  hideSortButton?: boolean;
  tooltip?: string;
  title?: string;
  TableCellProps?: MuiTableCellProps;
  size?: MuiTableCellProps['size'];
}

export default function TableCellHead({
  sortActive,
  sortDirection,
  title,
  onSortButtonClicked,
  hideSortButton = false,
  tooltip,
  TableCellProps,
  size = 'medium',
}: TableCellHeadProps) {
  return (
    <TableCell
      align="center"
      size={size}
      padding="normal"
      {...TableCellProps}
      // the props below should not be overriden.
      // eslint-disable-next-line no-nested-ternary
      sortDirection={sortActive ? (sortDirection === 'asc' ? 'asc' : 'desc') : false} // setting https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-sort
      variant="head"
      sx={{ fontWeight: sortActive ? 700 : 400 }}
    >
      <TableCellInner>
        {title && title}
        {tooltip && <CalculationToolTip title={tooltip} />}
        {!hideSortButton && sortActive && sortDirection === 'desc' && (
          <ArrowDropDownButton onClick={onSortButtonClicked} />
        )}
        {!hideSortButton && sortActive && sortDirection === 'asc' && (
          <ArrowDropUpButton onClick={onSortButtonClicked} />
        )}
        {!hideSortButton && !sortActive && sortDirection === undefined && (
          <ArrowDropDownButton onClick={onSortButtonClicked} />
        )}
      </TableCellInner>
    </TableCell>
  );
}
