import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../hooks/useMediaQuery';
import { DateRangeVariant } from '../../types/DateRangeVariant';
import MonthwiseFlowBarChart from '../MonthwiseFlowBarChart';
import DateRangePicker from '../ProductLineChart/DateRangePicker';
import Typography from '../Typography';

const dateRangeVariants = ['1M', '3M', '6M', '1Y'] as DateRangeVariant[];

const useStyles = makeStyles((theme) => ({
  monthwiseFlowTitle: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(-2),
  },
  footer: {
    display: 'flex',
    gap: theme.spacing(7),
    marginLeft: theme.spacing(9),
  },
}));

interface MonthwiseFlowProps {
  className?: string;
}

export default function MonthwiseFlow({ className = '' }: MonthwiseFlowProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [dateRangeVariant, setDateRangeVariant] = useState<DateRangeVariant>('1M');

  function handleDateRangeChanged(variant: DateRangeVariant) {
    setDateRangeVariant(variant);
  }

  return (
    <div className={className}>
      <Typography className={classes.monthwiseFlowTitle} variant="h4">
        {t('wallet:monthWiseFlow.chartTitle')}
      </Typography>
      <MonthwiseFlowBarChart dateRangeVariant={dateRangeVariant} />
      {greaterThanSm && (
        <div className={classes.footer}>
          <DateRangePicker
            dateRangeUpdated={handleDateRangeChanged}
            selectedDateRangeVariant={dateRangeVariant}
            variants={dateRangeVariants}
          />
          <Typography variant="body2" zellarColour="textInformation">
            {t('wallet:monthWiseFlow.helperText')}
          </Typography>
        </div>
      )}
    </div>
  );
}
