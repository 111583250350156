import makeStyles from '@mui/styles/makeStyles';

export const useOTPFormStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: theme.spacing(4),
    textAlign: 'center',
    alignItems: 'center',
  },
  icon: {
    alignSelf: 'center',
  },
  narrow: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
  },
  otpContainer: {
    display: 'flex',
    width: '100%',
    gap: theme.spacing(2.5),
    alignSelf: 'center',
    marginTop: theme.spacing(4),
  },
  otpInput: {
    width: '40px !important',
    height: '47px',
    border: 'none',
    borderRadius: '4px',
    background: theme.palette.dividers.primary,
    color: theme.palette.textField.text,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 800,

    '&:hover': {
      border: `1px solid ${theme.palette.textField.hover}`,
    },
  },
  otpInputFocus: {
    border: `1px solid ${theme.palette.textField.active}`,
    outline: 'none',
  },
}));
