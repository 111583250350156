/* eslint-disable no-nested-ternary */
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { formatter } from '../../utils/currencyFormatter';
import Typography from '../Typography';
import CalculationToolTip from '../CalculationToolTip';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    color: theme.palette.secondary.contrastText,
  },
  skeleton: {
    background: theme.palette.grey[200],
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export default function PortfolioMarketValueHeading({
  portfolioValue,
  loading,
}: {
  portfolioValue: number | undefined;
  loading: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Typography variant="h3">
        {loading ? (
          <Skeleton width="120px" className={classes.skeleton} />
        ) : isUndefined(portfolioValue) ? (
          t('common:currentlyUnavailable')
        ) : (
          formatter.format(portfolioValue)
        )}
      </Typography>
      <Typography className={classes.flex} customVariant="body2">
        {t('portfolio:attributes.portfolioValue.label')}
        <CalculationToolTip title={t('portfolio:attributes.portfolioValue.tooltip')} />
      </Typography>
    </div>
  );
}
