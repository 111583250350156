import makeStyles from '@mui/styles/makeStyles';

export const useAccountDetailsStyles = makeStyles((theme) => ({
  fields: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  expandedContent: {
    display: 'flex',
    padding: theme.spacing(5),
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    background: theme.palette.grey[50],
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    gap: theme.spacing(4),
  },
  passwordFields: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(4),
  },
  halfWidth: {
    width: '50%',
  },
  textFieldLabel: {
    marginBottom: theme.spacing(2),
  },
  buttonLabel: {
    display: 'flex',
    gap: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  inputLabel: {
    color: theme.palette.text.primary,

    // Genuinely the only way I was able to override the material ui disabled input styling.
    '&.Mui-disabled': {
      color: theme.palette.grey[200],
    },
  },
}));
