import { graphql } from '../../__generated__';

export const EXTERNAL_PORTFOLIO_QUERY = graphql(`
  query ExternalPortfolio {
    externalPortfolioUserAssets {
      quantityOwned
      purchasePrice
      totalPurchasePrice
      calculatedMarketData {
        combinedScore
        performance {
          valueDifference
          percentageDifference
        }
      }
      asset {
        id
        spread {
          highestBid {
            price
          }
          lowestOffer {
            price
          }
        }
        marketValue
        calculatedMarketData {
          combinedScore
        }
        unitSize
        unitCount
        vintage {
          id
          imageFileName
          drinkingWindow {
            dateFrom
            dateTo
            advice
          }
          wine {
            id
            name
            region {
              name
            }
          }
          vintage
        }
      }
    }
  }
`);
