import { useLazyQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SUGGEST_WINES } from '../../graphql/queries/suggestWines';
import { useDebounce } from '../../utils/useDebounce';
import Typography from '../Typography';
import { WineSuggestion } from '../../__generated__/graphql';

interface Props {
  onChange: (e: React.SyntheticEvent, value: WineSuggestion | null) => void;
  setInputValue: (value: string) => void;
  inputValue: string;
  readOnly?: boolean;
}

const useStyles = makeStyles(() => ({
  autocompleter: {
    // width: 400,
  },
  inputRoot: {
    height: 52,
  },
}));

export default function WinesAutocompleter({
  onChange,
  inputValue,
  setInputValue,
  readOnly = false,
}: Props): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<WineSuggestion[]>([]);
  const [fetchWineSuggestions, { data, loading }] = useLazyQuery(SUGGEST_WINES);
  const { t } = useTranslation();
  const classes = useStyles();

  function fetchWines() {
    if (inputValue) {
      fetchWineSuggestions({ variables: { queryString: inputValue } });
    } else {
      setOptions([]);
    }
  }

  const fetchWithDebounce = useDebounce(fetchWines, 300);

  React.useEffect(() => {
    if (loading) {
      return undefined;
    }

    return fetchWithDebounce();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  React.useEffect(() => {
    if (!data?.suggestWines || !data.suggestWines.suggests.length) {
      setOptions([]);
      return;
    }

    setOptions(data.suggestWines.suggests);
  }, [data, inputValue]);

  function handleInputChange(e: React.SyntheticEvent, value: string) {
    if (readOnly) {
      return;
    }
    setInputValue(value);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Autocomplete
      className={classes.autocompleter}
      classes={{ inputRoot: classes.inputRoot }}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.wineName === value.wineName}
      getOptionLabel={(option) => option.wineName}
      options={options}
      noOptionsText={<Typography zellarColour="textLabel">{t('market:addWineModal.noWinesFound')}</Typography>}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t('market:addWineModal.searchByWine')}
          InputProps={{
            ...params.InputProps,
            readOnly,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
