import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ProductChartTabs from '../../components/ProductChartTabs';
import ProductSummary from '../../components/ProductSummary';
import Details from '../../components/ProductTabSections';
import ProductTradingSection from '../../components/ProductTradingSection';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useStyles } from './styles';
import CardPaymentErrorModal from '../../components/CardPaymentCallbackModal/errorContent';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';
import CardPaymentSuccessModal from '../../components/CardPaymentCallbackModal/successContent';
import { getAssetIdFromUrl } from '../../utils/common';
import QualityGuarantee from '../../components/ProductTradingSection/QualityGuarantee';

export default function Product({ isLoggedIn = true }: { isLoggedIn?: boolean }): JSX.Element {
  const theme = useTheme();

  const onlyMd = useMediaQuery(theme.breakpoints.only('md'));
  const lessThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const greaterThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { showCarPaymentErrorModal, showCarPaymentSuccessModal } = useCardPaymentCallback();

  function closeCardPaymentErrorModal() {
    history.push({ pathname: location.pathname, search: location.search, hash: '' });
  }
  function closeCardPaymentSuccessModal() {
    history.push({ pathname: location.pathname, search: location.search, hash: '' });
  }

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     const locationPath = location.pathname;
  //     alert(locationPath);
  //     if (locationPath) history.push(locationPath);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname, isLoggedIn]);

  console.log('Location_Path', location.pathname);

  // the asset id
  const { id: urlSlug } = useParams<{ id: string | undefined }>();
  const id = useMemo(() => {
    if (!urlSlug) return null;
    return getAssetIdFromUrl(urlSlug);
  }, [urlSlug]);

  return (
    <Grid className={classes.container} spacing={greaterThanSm ? 10 : 4} container>
      <Grid className={classes.leftColumn} item xs={12} sm={12} md={12} lg={8}>
        <ProductSummary assetId={Number(id)} />
        {greaterThanLg && <ProductChartTabs assetId={Number(id)} />}
      </Grid>

      <Grid className={classes.rightColumn} item xs={12} sm={12} md={12} lg={4}>
        <Grid container spacing={greaterThanSm ? 10 : 4}>
          <Grid className={classes.tradingSection} item xs={12} sm={6} md={6} lg={12} xl={12}>
            <ProductTradingSection assetId={Number(id)} isLoggedIn={isLoggedIn} />
            {/* {isLoggedIn ? (
              <ProductTradingSection assetId={Number(id)} />
            ) : (
              <div className={classes.guestProductTradingSection}>
                <Typography customVariant="subtitle1">{t('product:trading.guestUser')}</Typography>
                <Button onClick={handleSignInClick} variant="contained" color="primary">
                  {t('common:login')}
                </Button>
              </div>
            )} */}
          </Grid>

          {lessThanSm && (
            <Grid item xs={12} sm={6} md={6} lg={12} xl={12}>
              <ProductChartTabs assetId={Number(id)} />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={6} lg={12} xl={12}>
            <Details assetId={Number(id)} />
          </Grid>

          {onlyMd && (
            <Grid item xs={12} sm={6} md={12} lg={12} xl={12}>
              <ProductChartTabs assetId={Number(id)} />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={onlyMd ? 12 : 6} lg={12} xl={12}>
            <QualityGuarantee />
          </Grid>
        </Grid>
      </Grid>
      {showCarPaymentErrorModal && isLoggedIn && (
        <CardPaymentErrorModal open={showCarPaymentErrorModal} onClose={closeCardPaymentErrorModal} />
      )}
      {showCarPaymentSuccessModal && isLoggedIn && (
        <CardPaymentSuccessModal open={showCarPaymentSuccessModal} onClose={closeCardPaymentSuccessModal} />
      )}
    </Grid>
  );
}
