import i18n from '../../../i18n';
import { BulletPointsData } from './types';
import { Asset, Producer } from '../../../__generated__/graphql';

const selectProducerLabel = (producer?: Producer | null): string => {
  if (!producer || (!producer.name && !producer.title)) {
    return i18n.t('product:notAvailable');
  }
  let result = '';
  const { name, title } = producer;
  if (name) {
    result = `${result}${name}`;
  }
  if (title) {
    result = `${result} ${title}`;
  }
  return result;
};

const selectAssetType = (sparkling?: boolean | null) => {
  if (sparkling === true) {
    return i18n.t('product:sparkling');
  }
  if (sparkling === false) {
    return i18n.t('product:still');
  }
  return i18n.t('product:notAvailable');
};

export const selectNormalizedBulletPointsData = (asset: Asset): BulletPointsData => {
  const NA = i18n.t('product:notAvailable');
  if (!asset.vintage) {
    return {
      Producer: NA,
      Vintage: NA,
      Type: NA,
      Colour: NA,
      Region: NA,
      Appellation: NA,
      Classification: NA,
    };
  }
  const { vintage, wine } = asset.vintage;
  const { producer, sparkling, regionalisedWineColour, region, appellation, classification } = wine;
  return {
    Producer: selectProducerLabel(producer),
    Vintage: (vintage && vintage.toString()) || NA,
    Type: selectAssetType(sparkling),
    Colour: regionalisedWineColour || NA,
    Region: region?.name || NA,
    Appellation: appellation?.name || NA,
    Classification: classification || NA,
  };
};
