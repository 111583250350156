import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme, { rowCount: number }>((theme) => ({
  grid: {
    width: '100%',
    display: 'grid',
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(4),
  },
  bids: {
    gridColumn: 2,
  },
  offers: {
    gridColumn: 3,
  },
  rowCapStart: {
    gridColumn: 1,
  },
  rowCapEnd: {
    gridColumn: 4,
  },
  header: {
    row: 1,
  },
  row: {
    gridRow: (props) => `repeat(${props.rowCount + 1}, auto)`,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  textAlignEnd: {
    textAlign: 'end',
  },
}));
