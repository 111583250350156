import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  pill: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    borderRadius: 72,
    border: '1px solid #E0E0E0',
    textAlign: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    maxWidth: 'fit-content',
    background: 'white',
  },
  text: {
    whiteSpace: 'nowrap',
  },
}));

interface PillProps {
  text: string;
  colour: string;
}

export default function Pill({ text, colour }: PillProps): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.pill}>
      <svg
        width={12}
        height={12}
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <circle fill={colour} r={6} cx={6} cy={6} />
      </svg>
      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
}
