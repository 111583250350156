import { isUndefined } from '@cultwines/zellar-client-sdk';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Pencil } from '../../assets/images/pencil.svg';
import { formatter } from '../../utils/currencyFormatter';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import IconButton from '../IconButton';
import Typography from '../Typography';
import { ProductData } from './types';
import { BUY_NOW } from '../../graphql/mutations/buyNow';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { logError } from '../../utils/logger';
import { FeeType } from '../../__generated__/graphql';
import { useWalletBalance } from '../../hooks';
import { useQueryParameters } from '../../hooks/useQueryParameters';
import { isNull } from '../../utils/isNull';
import { useAvailableBalance } from '../../hooks/useAvailableBalance';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  modalContainer: {
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '60%',
    alignSelf: 'center',
  },
  summaryContainer: {
    gap: theme.spacing(3),
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  iconContainer: {
    marginLeft: theme.spacing(4),
    width: 40,
    height: 40,
  },
  detailRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}));

interface ModalProps {
  onClose: () => void;
  onSuccess: (clientOrderId: string) => void;
  onFailure: () => void;
  goBack: () => void;
  productData: ProductData;
  totalUnitPrice: number;
  transactionFee: number;
  totalUnitPricePlusFees: number;
  quantity: number;
  loading: boolean;
  vat?: number;
  showFees?: boolean;
}

export default function BuyNowSummaryContent({
  onClose,
  onSuccess,
  onFailure,
  goBack,
  productData,
  loading,
  transactionFee,
  totalUnitPrice,
  totalUnitPricePlusFees,
  quantity,
  vat,
  showFees = true,
}: ModalProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryParams = useQueryParameters();
  const cardCharge = queryParams.get('cardCharge');
  const { walletBalance: fundsOnAccount, loading: loadingFundsOnAccount } = useWalletBalance();
  const { availableBalance } = useAvailableBalance();
  const [buyNow, { loading: processingBuyNowRequest }] = useMutation(BUY_NOW, {
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });

  async function handleSubmit(): Promise<void> {
    if (processingBuyNowRequest) {
      return;
    }

    const { errors, data: buyNowResponse } = await buyNow({
      variables: {
        buyItNowInitiatedInput: {
          assetId: productData.assetId,
          quantity,
          totalPrice: totalUnitPrice,
          fees: [
            { type: FeeType.VAT_FEES, value: showFees && vat ? vat : 0 },
            { type: FeeType.TRANSACTION_FEES, value: showFees ? transactionFee : 0 },
          ],
        },
      },
    });
    if (
      errors ||
      buyNowResponse?.buyItNow.errorMessage ||
      isNullOrUndefined(buyNowResponse) ||
      isUndefined(buyNowResponse?.buyItNow) ||
      isNullOrUndefined(buyNowResponse?.buyItNow.clientOrderId)
    ) {
      logError({
        error: new Error(`Failed to buy asset ${productData.assetId}`),
        filename: 'BuyNowSummaryContent',
        additionalInfo: {
          errors: JSON.stringify(errors),
          buyNowResponse: JSON.stringify(buyNowResponse),
        },
        tags: { userFlow: 'trade' },
      });
      onFailure();
      return;
    }

    onSuccess(buyNowResponse.buyItNow.clientOrderId);
  }

  const insufficientFunds =
    (fundsOnAccount || 0) < Number(totalUnitPricePlusFees.toFixed(2)) &&
    availableBalance < Number(totalUnitPricePlusFees.toFixed(2));

  return (
    <div className={classes.modalContainer}>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {t('product:detailsModal.buyNow.summary.title')}
      </DialogTitle>
      <DialogContentBorderTopOnly className={classes.modalContent} dividers>
        <div className={classes.titleContainer}>
          {!loading ? (
            <Typography className={classes.title} variant="h2">
              {productData.wineName}
            </Typography>
          ) : (
            <Typography variant="h2" className={classes.title}>
              <Skeleton />
            </Typography>
          )}
          <IconButton variant="filled" className={classes.iconContainer} onClick={goBack} size="large">
            <Pencil />
          </IconButton>
        </div>
        <div className={classes.summaryContainer}>
          {!loading ? (
            <>
              <div className={classes.detailRow}>
                <Typography customVariant="body1Bold">{t('product:detailsModal.buyNow.summary.unitSize')}</Typography>
                <Typography customVariant="body1">{productData.unitSize}</Typography>
              </div>
              <div className={classes.detailRow}>
                <Typography customVariant="body1Bold">{t('product:detailsModal.buyNow.summary.quantity')}</Typography>
                <Typography customVariant="body1">{quantity}</Typography>
              </div>
              <div className={classes.detailRow}>
                <Typography customVariant="body1Bold">{t('product:detailsModal.buyNow.totalUnitPrice')}</Typography>
                <Typography customVariant="body1" data-testid="total-unit-price">
                  {formatter.format(totalUnitPrice)}
                </Typography>
              </div>
              {showFees && (
                <>
                  <div className={classes.detailRow}>
                    <Typography customVariant="body1Bold">
                      {t('product:detailsModal.buyNow.transactionFee', { fee: 2.5 })}
                    </Typography>
                    <Typography customVariant="body1">{formatter.format(transactionFee)}</Typography>
                  </div>
                  {!isUndefined(vat) && (
                    <div className={classes.detailRow}>
                      <Typography customVariant="body1Bold">VAT (20%)</Typography>
                      <Typography customVariant="body1">{formatter.format(vat)}</Typography>
                    </div>
                  )}
                </>
              )}
              <div className={classes.detailRow}>
                <Typography customVariant="body1Bold">{t('product:detailsModal.buyNow.totalPrice')}</Typography>
                <Typography customVariant="body1" data-testid="total-price">
                  {formatter.format(totalUnitPricePlusFees)}
                </Typography>
              </div>
              {cardCharge && (
                <>
                  <div className={classes.detailRow}>
                    <Typography customVariant="body1Bold">{t('product:detailsModal.totalFunds')}</Typography>
                    <Typography customVariant="body1" data-testid="total-price">
                      {isNull(fundsOnAccount) ||
                      insufficientFunds ||
                      loadingFundsOnAccount ||
                      Number(formatter.format(fundsOnAccount - Number(cardCharge))) < 0
                        ? t('common:currentlyUnavailable')
                        : formatter.format(fundsOnAccount - Number(cardCharge))}
                    </Typography>
                  </div>
                  <div className={classes.detailRow}>
                    <Typography customVariant="body1Bold">{t('product:detailsModal.buyNow.cardCharge')}</Typography>
                    <Typography customVariant="body1" data-testid="total-price">
                      {isNull(cardCharge) ? t('common:currentlyUnavailable') : formatter.format(+cardCharge)}
                    </Typography>
                  </div>
                </>
              )}
            </>
          ) : (
            <Skeleton variant="rectangular" width="100%" height="600px" />
          )}
        </div>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          disabled={processingBuyNowRequest || loadingFundsOnAccount || insufficientFunds}
        >
          {insufficientFunds
            ? t('product:detailsModal.buyNow.summary.wait')
            : t('product:detailsModal.buyNow.summary.button')}
        </Button>
      </DialogContentBorderTopOnly>
    </div>
  );
}
