import { ApolloError, useQuery } from '@apollo/client';
import { WATCHLIST_ITEMS } from '../graphql';
import { WatchListItem } from '../__generated__/graphql';

interface UseWatchlistItemsResponse {
  watchlistItems: WatchListItem[];
  error: ApolloError | undefined;
  loading: boolean;
}

export const useWatchlistItems = (): UseWatchlistItemsResponse => {
  const { data, loading, error } = useQuery(WATCHLIST_ITEMS);
  const watchlistItems = data?.watchListItems || [];
  return <UseWatchlistItemsResponse>{ watchlistItems, loading, error };
};
