/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import DialogTitle from '../Dialog/DialogTitle';
import { ReactComponent as WineBottleIcon } from '../../assets/icons/wine-bottle.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    width: '50%',
    textAlign: 'center',
  },
  circle: {
    borderRadius: 180,
    borderWidth: 20,
    marginTop: 40,
    marginBottom: 40,
  },
}));

interface ReviewAddressProps {
  onClose: () => void;
}

export default function KycPending({ onClose }: ReviewAddressProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <DialogTitle id="dialog-title" onClose={onClose}>
        <></>
      </DialogTitle>
      <Typography variant="h1" />
      <div className={classes.container}>
        <Typography customVariant="h5">{t('wallet:kycPending.title')}</Typography>
        <div className={classes.circle}>
          <WineBottleIcon />
        </div>
        <Typography customVariant="body1" className={classes.description}>
          {t('wallet:kycPending.description')}
        </Typography>
      </div>
    </>
  );
}
