/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CurrencyTextField from '../../CurrencyTextField';
import Typography from '../../Typography';
import { useStyles } from '../styles';
import { Displayfield, ModelValidationSchemaType } from '../types';

const model = yup.object({
  amount: yup.string(),
});

interface InsertAmountProps {
  value: string | undefined;
  placeholder: string;
  isDisabled?: boolean;
  onChange?: (value: string, fieldId: string, fieldValid: boolean) => void;
  onError?: Displayfield['onError'];
  validationSchema?: ModelValidationSchemaType;
}

const InsertAmountField: React.FC<InsertAmountProps> = ({
  value,
  placeholder,
  onError,
  onChange,
  validationSchema,
  isDisabled = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const mValidationSchema = yup.object({
    ...validationSchema,
  });

  const formik = useFormik<yup.InferType<typeof model>>({
    initialValues: { amount: value || '' },
    onSubmit: () => {},
    validationSchema: mValidationSchema || model,
  });

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    formik.handleChange(e);

    const { value: val } = e.target;
    const amountIsValid = formik.errors.amount;
    if (!!amountIsValid && onError) {
      onError(val, 'amount', false);
    } else if (onChange && !amountIsValid) {
      onChange(val, 'amount', true);
    }
  };

  return (
    <div>
      <Typography
        className={`${classes.amountLabel} ${classes.uppercase}`}
        customVariant="label1Active"
        zellarColour="textLabel"
      >
        {t('wallet:paymentMethodModal.insertAmountLabel')}
      </Typography>
      <CurrencyTextField
        name="amount"
        value={formik.values.amount!}
        placeholder={placeholder}
        onChange={handleChange}
        error={formik.touched.amount && Boolean(formik.errors.amount)}
        helperText={formik.touched.amount && formik.errors.amount}
        disabled={isDisabled}
      />
    </div>
  );
};

export default InsertAmountField;
