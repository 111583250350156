import { ApolloCache, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { CREATE_BID, CREATE_OFFER } from '../graphql';
import { TradeVariant } from '../components/OrderModal/types';
import { CreateBidMutation, CreateOfferMutation, CreateOrderInput } from '../__generated__/graphql';

interface MutationResponse {
  clientOrderId: string | undefined;
  /**
   * @deprecated
   */
  data: CreateOfferMutation | CreateBidMutation | null | undefined;
  errors: readonly GraphQLError[] | undefined;
}

export type CreateOrderFn = (params: CreateOrderInput, tradeType: TradeVariant) => Promise<MutationResponse>;

interface ReturnProps {
  createOrder: CreateOrderFn;
  loading: boolean;
}

export const useCreateOrder = (options = {}): ReturnProps => {
  const [postOfferMutation, { loading: postingOffer }] = useMutation(CREATE_OFFER, {
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });

  const [postBidMutation, { loading: postingBid }] = useMutation(CREATE_BID, {
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });
  const createOrder = useCallback(
    async (variables: CreateOrderInput, tradeType: TradeVariant) => {
      if (tradeType === 'bid') {
        const { data, errors } = await postBidMutation({
          variables: { createBidVariables: variables },
          update: (cache: ApolloCache<CreateBidMutation>) => {
            cache.modify({
              fields: {
                omsGetMyOrders() {
                  return undefined;
                },
              },
            });
          },
          ...options,
        });

        return { data, clientOrderId: data?.bid, loading: postingBid, errors };
      }
      const { data, errors } = await postOfferMutation({
        variables: { createOfferVariables: variables },
        update: (cache: ApolloCache<CreateOfferMutation>) => {
          cache.modify({
            fields: {
              omsGetMyOrders() {
                return undefined;
              },
            },
          });
        },
        ...options,
      });

      return { data, clientOrderId: data?.offer, loading: postingOffer, errors };
    },
    [postOfferMutation, options, postingOffer, postBidMutation, postingBid],
  );
  return { createOrder, loading: postingBid || postingOffer };
};
