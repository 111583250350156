import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { SEARCH_ACTIVE_MARKETS } from '../../../../graphql/queries/searchActiveMarkets';
import { ActiveMarket } from '../../../../__generated__/graphql';
import { SortDirection } from '../../../../components/Table/types';
import { ActiveMarketExtended } from '../../../../types/ActiveMarketType';

interface FetchResponseType {
  searchActiveMarkets: {
    total: number;
    results: ActiveMarket[];
  };
}

interface DataResponseType {
  isLoading: boolean;
  data: ActiveMarketExtended[];
}

interface UseFetchSilentlyParams {
  variables: {
    from?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection: SortDirection;
    wineName?: string;
    isLoggedIn?: boolean;
  };
  timeOutBetweenFetch?: number;
  isLoggedIn?: boolean;
  totalCount: number;
  pageSize: number;
}

export const useAllActiveMarketAssets = ({
  timeOutBetweenFetch = 2000,
  variables,
  isLoggedIn,
  totalCount,
  pageSize,
}: UseFetchSilentlyParams) => {
  const [dataResponse, setDataResponse] = useState<DataResponseType>({
    isLoading: true,
    data: [],
  });

  const { fetchMore } = useQuery(SEARCH_ACTIVE_MARKETS, {
    variables: { ...variables, from: 0, pageSize },
    skip: true,
    ...(isLoggedIn ? {} : { context: { serviceName: 'insecure' } }),
  });

  useEffect(() => {
    // const controller = new AbortController();

    const startFetch = async () => {
      const numberOfPages = Math.floor((totalCount > pageSize ? totalCount : 0) / pageSize);
      const remainingPage = totalCount % pageSize;

      // console.log('numberOfPages ====', numberOfPages);
      // console.log('remainingPage  ===', remainingPage);

      const fetchResult: ActiveMarketExtended[] = [];

      if (numberOfPages <= 0) {
        setDataResponse((prev) => ({ ...prev, data: [], isLoading: false }));
        return;
      }

      setDataResponse((prev) => ({ ...prev, data: [], isLoading: true }));

      /* eslint-disable no-await-in-loop */
      for (let page = 0; page < numberOfPages; page += 1) {
        await new Promise((resolve, reject) => {
          // if (controller.signal.aborted) resolve(null);
          setTimeout(async () => {
            try {
              const fetchResponse = await fetchMore({
                variables: { ...variables, from: pageSize * page, pageSize },
                ...(isLoggedIn ? {} : { context: { serviceName: 'insecure' } }),
              });

              const response = (fetchResponse?.data ?? {
                searchActiveMarkets: { results: [], total: 0 },
              }) as FetchResponseType;

              const result = response.searchActiveMarkets.results;

              if (result.length > 0) fetchResult.push(...result);
              resolve(null);
            } catch (error) {
              reject(new Error('bg fetch error'));
            }
          }, timeOutBetweenFetch);
        });
      }

      if (remainingPage) {
        // console.log('startSilentFetch-- has remainingPage ', remainingPage);
        // console.log('pageSize * (numberOfPages + 1) ', pageSize * numberOfPages);
        try {
          const fetchResponse = await fetchMore({
            variables: { ...variables, from: pageSize * numberOfPages, pageSize: remainingPage },
            ...(isLoggedIn ? {} : { context: { serviceName: 'insecure' } }),
          });
          const response = (fetchResponse?.data ?? {
            searchActiveMarkets: { results: [], total: 0 },
          }) as FetchResponseType;
          const result = response.searchActiveMarkets.results;

          if (result.length > 0) fetchResult.push(...result);
        } catch (error) {
          console.log('fetch error');
        }
      }

      setDataResponse((prev) => ({ ...prev, isLoading: false, data: fetchResult.length ? fetchResult : [] }));
    };

    if (totalCount) startFetch();

    return () => {
      // controller.abort();
      // console.log('================================= CANCEL WDWDWDWD');
    };
  }, [pageSize, totalCount, timeOutBetweenFetch, variables, setDataResponse, fetchMore, isLoggedIn]);

  return { data: dataResponse.data, isLoading: dataResponse.isLoading };
};
