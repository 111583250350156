import { SuggestWineVintagesQuery } from '../../__generated__/graphql';

export type WineVintageSuggestion = {
  defaultAssetId: number;
  wineName: string;
  vintage: number;
  region: string;
  appellation: string;
};

export type WineVintageSearchSuggestions = {
  wines: WineVintageSuggestion[];
  appellations: string[];
  regions: string[];
  producers: string[];
};

export function selectWineVintagesSuggestions(data: SuggestWineVintagesQuery): WineVintageSearchSuggestions {
  const appellations: string[] = data.suggestWineVintages.suggestedAppellation.map((sa) => sa.appellation);
  const regions: string[] = data.suggestWineVintages.suggestedRegion.map((sr) => sr.region);
  const producers: string[] = data.suggestWineVintages.suggestedProducer.map((sp) => sp.producer);
  const wines: WineVintageSuggestion[] = data.suggestWineVintages.suggests.map((wv) => ({
    defaultAssetId: wv.defaultAssetId,
    vintage: wv.vintage,
    wineName: wv.wineName,
    region: wv.region,
    appellation: wv.appellation,
  }));

  return { appellations, regions, producers, wines };
}
