import { ApolloError, useQuery } from '@apollo/client';
import React from 'react';
import { OrderDirection } from '../components/OrderBookModal/types';
import { OrderStatus } from '../types/OrderStatus';
import { CAN_CREATE_OFFER_QUERY } from './useCanCreateOffer';

type UseLastIndexAvailableForOfferReturnType = {
  lastIndexAvailableForOffer: number;
  loading: boolean;
  error?: ApolloError;
};

export function useLastIndexAvailableForOffer({
  assetId,
}: {
  assetId: number;
}): UseLastIndexAvailableForOfferReturnType {
  const [lastIndex, setLastIndex] = React.useState(0);
  const { data, error, loading } = useQuery(CAN_CREATE_OFFER_QUERY, {
    variables: { assetId },
  });

  React.useEffect(() => {
    if (data) {
      const totalCasesOnOffer = data.omsGetMyOrders
        .filter(
          (order) =>
            order.direction === OrderDirection.Offers &&
            order.asset.id === assetId &&
            order.status === OrderStatus.Live,
        )
        .map((offer) => offer.outstandingQuantity)
        .reduce((prev, curr) => prev + curr, 0);

      setLastIndex(Math.abs(totalCasesOnOffer - data.productAssetInstances.length) - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { lastIndexAvailableForOffer: lastIndex, loading, error };
}
