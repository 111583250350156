import MuiDialogTitle from '@mui/material/DialogTitle';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import IconButton from '../IconButton';
import Typography from '../Typography';

const dialogTitleStyles = () =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0,
      margin: 0,
      height: 56,
      width: '100%',
    },
    closeButton: {
      height: 32,
      width: 32,
      alignSelf: 'center',
    },
    title: {
      alignSelf: 'center',
    },
  });

interface DialogTitleProps extends WithStyles<typeof dialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  disableClose?: boolean;
}

export default withStyles(dialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, disableClose, ...other } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography className={classes.title} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          variant="filled"
          className={classes.closeButton}
          disabled={disableClose}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
