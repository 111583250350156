import { RequiredStringSchema } from 'yup/lib/string';
import { WithdrawConfirmationInput } from '../../../__generated__/graphql';

export interface Displayfield {
  id: string;
  name: string;
  type?: DisplayfieldType;
  thousandSeparate?: boolean;
  numberFormat?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field?: Displayfield) => void;
  ariaLabel?: string;
  className?: string;
  translationKey?: string;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, field?: Displayfield) => void;
  onError?: (value: string, fieldId: string, fieldValid: boolean) => void;
  helperText?: string | boolean | undefined;
  label?: {
    text: string;
    isShow?: boolean;
    className?: string;
    variant?: string;
  };
}

enum DisplayfieldType {
  NUMBER = 'number',
  TEXT = 'text',
}

export type ModelValidationSchemaType = Record<
  string,
  RequiredStringSchema<string | undefined, Record<string, unknown>>
>;

export enum ModelKey {
  AccountName = 'accountName',
  SortCode = 'sortCode',
  AccountNumber = 'accountNumber',
  Iban = 'iban',
  Bic = 'bic',
  Amount = 'amount',
}

export type WithdrawFundsModelType = Omit<WithdrawConfirmationInput, 'amount'>;
