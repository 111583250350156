import React from 'react';
import SummaryDetails from './SummaryDetails';

interface Props {
  assetId: number;
}

export default function ProductSummary({ assetId }: Props): JSX.Element {
  return <SummaryDetails assetId={assetId} description="" />;
}
