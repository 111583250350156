import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input-rc-17';
import { ReactComponent as CircledCrossIcon } from '../../assets/icons/circled-cross.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email-circled.svg';
import { appIsWorkingVar, isLoggedInVar } from '../../graphql/cache';
import { updateAccessToken, updateRefreshToken, updateUserToken } from '../../services/auth';
import { logError } from '../../utils/logger';
import { useOTPFormStyles } from './styles';
import { graphql } from '../../__generated__';

const SUBMIT_ONE_TIME_PASSCODE = graphql(`
  mutation SubmitOneTimePasscode($args: AuthMfaOobCodeVerifyRequestInput!) {
    authMfaVerify(mfaOobCodeVerifyRequestInput: $args) {
      accessToken
      idToken
      userToken
      refreshToken
      error
      errorDescription
    }
  }
`);

interface OTPFormProps {
  mfaToken: string;
  mfaOOBCode: string;
}

export default function OTPForm({ mfaToken, mfaOOBCode }: OTPFormProps): JSX.Element {
  const [oneTimePasscode, setOneTimePasscode] = useState('');
  const [error, setError] = useState<Error | null>(null);

  const [submitOTP] = useMutation(SUBMIT_ONE_TIME_PASSCODE, {
    context: { serviceName: 'insecure' },
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });

  const { t } = useTranslation();
  const classes = useOTPFormStyles();

  async function handleSubmit(event: React.FormEvent) {
    // If we don't do this, the page refreshes
    // and the request gets cancelled ->
    // https://stackoverflow.com/a/19454346
    event.preventDefault();

    appIsWorkingVar(true);

    try {
      const { data, errors } = await submitOTP({
        variables: {
          args: {
            clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
            mfaOobCode: mfaOOBCode,
            mfaOtpCode: oneTimePasscode,
            mfaToken,
          },
        },
      });

      if (errors) {
        const _error = new Error(t('auth:loginForm.failedToLogin'));
        logError({
          error: _error,
          originalError: errors[0],
          filename: 'OTPForm',
          additionalInfo: { errors: JSON.stringify(errors) },
          tags: { userFlow: 'auth' },
        });
        console.error(errors[0].originalError?.message);
        throw _error;
      }

      // For some reason there is an error property returned in the main body of `data`.
      // Let's cover ourselves and ensure that any error info which might be stored in there
      // is captured and reported accordingly.
      if (data?.authMfaVerify.error) {
        const _err = new Error(data.authMfaVerify.error);
        logError({
          error: new Error('Failed to login'),
          originalError: _err,
          filename: 'OTPForm',
          tags: { userFlow: 'auth' },
        });
        throw _err;
      }

      if (!data) {
        const _error = new Error(t('auth:loginForm.failedToLogin'));
        logError({
          originalError: new Error('data returned by authMfaVerify is null or undefined'),
          error: _error,
          filename: 'OTPForm',
          tags: { userFlow: 'auth' },
          additionalInfo: { message: 'data is null or undefined' },
        });
        throw _error;
      }

      updateAccessToken(data.authMfaVerify.accessToken);
      updateRefreshToken(data.authMfaVerify.refreshToken);
      updateUserToken(data.authMfaVerify.userToken);
      isLoggedInVar(true);
      appIsWorkingVar(false);
    } catch (err) {
      appIsWorkingVar(false);
      setError(err as Error);
    }
  }

  return (
    <form aria-label="one-time-passcode" className={classes.container} onSubmit={handleSubmit}>
      <EmailIcon className={classes.icon} />
      <Typography variant="h1">{t('auth:otpForm.title')}</Typography>
      {error && (
        <Alert icon={<CircledCrossIcon stroke="white" />} severity="error">
          {error.message}
        </Alert>
      )}
      <div className={classes.narrow}>
        <OtpInput
          value={oneTimePasscode}
          onChange={setOneTimePasscode}
          numInputs={6}
          containerStyle={classes.otpContainer}
          inputStyle={classes.otpInput}
          focusStyle={classes.otpInputFocus}
          shouldAutoFocus
          isInputNum
        />
        <Button type="submit" disabled={oneTimePasscode.length !== 6}>
          {t('common:submit')}
        </Button>
      </div>
    </form>
  );
}
