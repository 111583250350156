import React from 'react';
import DrinkingWindowChip, { DrinkingWindowAdvice } from '../DrinkingWindowChip';
import Typography from '../Typography';

interface DrinkingWindowProps {
  adviceType: DrinkingWindowAdvice;
  text: string;
  className?: string;
}

export default function DrinkingWindow({ adviceType, text, className = '' }: DrinkingWindowProps): JSX.Element {
  return (
    <div className={className}>
      <DrinkingWindowChip advice={adviceType} />
      <Typography customVariant="body2" zellarColour="textInformation">
        {text}
      </Typography>
    </div>
  );
}
