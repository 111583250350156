import React from 'react';
import { Skeleton, Stack } from '@mui/material';
import { TFunction } from 'i18next';
import Typography from '../Typography';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import CalculationToolTip from '../CalculationToolTip';
import MarketPricePercentageChange from '../ProductSummary/MarketPricePercentageChange';

interface Props {
  percentageDifference: number;
  marketValue: number;
  loading: boolean;
  translation: TFunction;
  greaterThanSm?: boolean;
}

export default function MarketValue({
  percentageDifference,
  marketValue,
  loading,
  translation,
  greaterThanSm,
}: Props): JSX.Element | null {
  if (loading) {
    return (
      <Stack direction="column" alignItems="baseline" spacing={2} ml={greaterThanSm ? 10 : 0}>
        <Typography customVariant="h3">
          <Skeleton width={80} />
        </Typography>
        <Typography customVariant="subtitle2">
          <Skeleton width={60} />
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      alignItems={greaterThanSm ? 'baseline' : 'center'}
      spacing={2}
      ml={greaterThanSm ? 10 : 0}
    >
      <Typography
        className="tw-uppercase tw-flex tw-items-center tw-gap-2"
        customVariant="label2"
        zellarColour="textLabel"
      >
        <CalculationToolTip title={translation('market:marketValueCalculation')} />
        {translation('product:marketValue')}
      </Typography>

      {marketValue && marketValue !== 0 && (
        <Typography customVariant="h3" zellarColour="textPrimary">
          {formatterWholeNumber.format(marketValue)}
        </Typography>
      )}

      {percentageDifference && percentageDifference !== 0 && (
        <MarketPricePercentageChange value={percentageDifference || undefined} />
      )}
    </Stack>
  );
}
