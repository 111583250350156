import { graphql } from '../../__generated__';

export const CALCULATED_MARKET_DATA = graphql(`
  query CalculatedMarketData($assetId: Int!) {
    calculatedMarketData(assetId: $assetId) {
      liquidityScore
      currentPrice
      combinedScore
      performance {
        valueDifference
        percentageDifference
      }
    }
  }
`);
