/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import Typography from '../Typography';
import { colours } from '../../theme/onboarding/palette';
import { Mode, TradeVariant } from './types';
import { OrderData } from '../OrderForm/types';
import {
  UseProcessCreateOrderProps,
  UseProcessEditOrderProps,
  useProcessOrder,
  UseProcessOrderPropsBase,
  UseProcessCancelOrderProps,
} from '../../hooks/useProcessOrder';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
    gap: theme.spacing(8),
    height: 300,
    paddingBottom: 0,
  },
  fontSize14px: {
    fontSize: '14px',
  },
  fontSize18px: {
    fontSize: '18px',
  },
  bold: {
    fontWeight: 'bold',
  },
  purpleMid: {
    color: colours.purpleMid,
  },
  green: {
    color: colours.brightGreen,
  },
}));

export type OrderModalProcessTransactionPropsBase = {
  onSuccess: () => void;
  onError: () => void;
  assetId: number;
  orderData: OrderData | null;
  tradeType: TradeVariant;
};

type CreateOrderProps = OrderModalProcessTransactionPropsBase & {
  mode: Mode.Create;
  onCreateFailure: () => void;
};

type EditOrderProps = OrderModalProcessTransactionPropsBase & {
  mode: Mode.Edit;

  /**
   * The order id used by the system to track the order internally.
   */
  systemOrderId: string;
  /**
   * The client facing ID returned by `bid` or `offer` mutations.
   */
  existingOrderClientId: string;
  onEditFailure: () => void;
  onCancelFailure: () => void;
};

type CancelOrderProps = OrderModalProcessTransactionPropsBase & {
  mode: Mode.Cancel;
  systemOrderId: string;
  existingOrderClientId: string;
  onCancelFailure: () => void;
};

export default function OrderModalProcessTransaction(
  props: CreateOrderProps | EditOrderProps | CancelOrderProps,
): JSX.Element {
  const { mode, onSuccess, onError, orderData, tradeType, assetId } = props;
  const commonProps: UseProcessOrderPropsBase = {
    assetId,
    onError,
    onSuccess,
    orderData,
    tradeType,
    shouldAddToWatchlist: true,
  };

  const modeSpecificProps: UseProcessCreateOrderProps | UseProcessEditOrderProps | UseProcessCancelOrderProps =
    mode === Mode.Cancel
      ? {
          ...commonProps,
          mode: Mode.Cancel,
          systemOrderId: (props as CancelOrderProps).systemOrderId,
          existingOrderClientId: (props as CancelOrderProps).existingOrderClientId,
          shouldAddToWatchlist: false,
          onCancelFailure: (props as CancelOrderProps).onCancelFailure,
        }
      : mode === Mode.Create
      ? {
          ...commonProps,
          mode: Mode.Create,
          onCreateFailure: (props as CreateOrderProps).onCreateFailure,
        }
      : {
          ...commonProps,
          mode: Mode.Edit,
          existingOrderClientId: (props as EditOrderProps).existingOrderClientId,
          systemOrderId: (props as EditOrderProps).systemOrderId,
          onCancelFailure: (props as EditOrderProps).onCancelFailure,
          onEditFailure: (props as EditOrderProps).onEditFailure,
        };

  const { processOrder } = useProcessOrder({
    ...modeSpecificProps,
  });
  const classes = useStyles();
  const { t } = useTranslation();

  processOrder();

  return (
    <div className={classes.modalContainer}>
      <Typography className={clsx(classes.fontSize18px, classes.bold)}>
        {t('product:detailsModal.buyNow.processing.title')}
      </Typography>
      <CircularProgress classes={{ circle: classes.green }} />
      <Typography className={clsx(classes.fontSize14px, classes.purpleMid)}>
        {mode === Mode.Cancel && tradeType === 'bid' && t('product:detailsModal.bid.cancel.processing')}
        {mode === Mode.Cancel && tradeType === 'offer' && t('product:detailsModal.offer.cancel.processing')}
        {(mode === Mode.Create || mode === Mode.Edit) &&
          tradeType === 'bid' &&
          t('product:detailsModal.bid.processing')}
        {(mode === Mode.Create || mode === Mode.Edit) &&
          tradeType !== 'bid' &&
          t('product:detailsModal.buyNow.processing.message')}
      </Typography>
    </div>
  );
}
