import makeStyles from '@mui/styles/makeStyles';

export const useExpandedSearchResultContentStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[50],
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(5),
  },
  grid: {
    alignItems: 'center',
    rowGap: theme.spacing(8),
    columnGap: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    width: '100%',
  },
  drinkingWindow: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
}));
