import { graphql } from '../../__generated__';

export const PRODUCT_DATA_WITH_USERID_QUERY = graphql(`
  query ProductDataWithUserIdByAssetId($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      vintage {
        id
        vintage
        wine {
          id
          name
        }
      }
    }

    authUser {
      userId
    }
  }
`);

export const PRODUCT_DATA_QUERY = graphql(`
  query ProductData($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      vintage {
        id
        vintage
        wine {
          id
          name
        }
      }
    }
  }
`);
