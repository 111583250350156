/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_PAYMENT_CARD } from '../graphql/mutations/deletePaymentCard';
import { logError } from '../utils/logger';
import { selectErrorMessage } from '../graphql/selectors/selectErrorMessage';
import { cache } from '../graphql/cache';

export function useDeletePaymentCard() {
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [deletePaymentCard, { loading }] = useMutation(DELETE_PAYMENT_CARD, { onError: () => null });

  async function deleteCard(instrumentId: string): Promise<void> {
    try {
      const response = await deletePaymentCard({ variables: { instrumentId } });
      if (response.data?.deleteCardDetail.isSuccess) {
        cache.evict({ fieldName: 'paymentGetCardDetails' });
        cache.evict({ fieldName: 'authUser' });
        setSuccess(true);
        return;
      }

      if (response.errors) {
        throw new Error(selectErrorMessage(response.errors));
      }

      if (response.data?.deleteCardDetail.errorMessage) {
        throw new Error(response.data.deleteCardDetail.errorMessage);
      }
    } catch (e) {
      setError(e as Error);
      logError({
        error: new Error('Failed to delete card'),
        originalError: e as Error,
        additionalInfo: { instrumentId },
        filename: 'src/hooks/useDeletePaymentCard',
      });
    }
  }

  return { deleteCard, loading, error, success };
}
