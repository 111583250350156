import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useIconButtonStyles = makeStyles<Theme, { filled: boolean }>((theme) => ({
  button: {
    background: (props) => (props.filled ? theme.palette.grey[100] : 'inherit'),
    padding: theme.spacing(1.5),
    color: theme.palette.text.primary,

    '&:disabled': {
      background: (props) => (props.filled ? theme.palette.grey[50] : theme.palette.background.default),
      opacity: 0.5,
    },
  },
}));
