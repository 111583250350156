import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    color: theme.palette.text.primary,
    alignItems: 'center',
    width: 400,
  },
  divider: {
    width: 5,
    // Above 24px, does not manage to fit 8 tooltips vertically
    height: 24,
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  title: {
    /**
     * Unable to reference any of the custom typography
     * variants that have been added here without breaking
     * the application when it tries to render the tooltip.
     * It is directly related to how this component is rendered,
     * as the custom variants can be accessed in other components
     * without issue.
     */
    fontSize: '12px',
    fontWeight: 700,
    width: '75%',
    whiteSpace: 'normal',
  },
  valueLabel: {
    /**
     * Unable to reference any of the custom typography
     * variants that have been added here without breaking
     * the application when it tries to render the tooltip.
     * It is directly related to how this component is rendered,
     * as the custom variants can be accessed in other components
     * without issue.
     */
    width: '25%',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '18px',
  },
}));

interface Props {
  point: Highcharts.TooltipFormatterContextObject;
}

export default function PercentageTooltip({ point }: Props): JSX.Element {
  const { classes: classNames } = useStyles();
  const background = point.color as string;
  const label = point.y < 0 ? `${point.y.toFixed(2)}%` : `+${point.y.toFixed(2)}%`;
  return (
    <div className={classNames.container}>
      <div className={classNames.title}>{point.series.name}</div>
      <div style={{ background }} className={classNames.divider} />
      <div className={classNames.valueLabel}>{label}</div>
    </div>
  );
}
