/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material';
import { useStyles } from './styles';
import WithdrawFundsModal from './WithdrawFundsModal';
import WithdrawResultModal, { ResultTpe } from './WithdrawResultModal';
import useMediaQuery from '../../hooks/useMediaQuery';

interface WithdrawFundsModalProps {
  onClose: () => void;
}

const WithdrawModal: React.FC<WithdrawFundsModalProps> = ({ onClose }) => {
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const classes = useStyles();
  const [initialModalType, setInitialModalType] = useState<'success' | 'error' | 'withdraw'>('withdraw');
  return (
    <Dialog fullScreen={lessThanMd} open>
      <div className={classes.container}>
        {(() => {
          switch (initialModalType) {
            case 'withdraw':
              return (
                <WithdrawFundsModal
                  onClose={onClose}
                  onConfirmSuccess={() => setInitialModalType('success')}
                  onConfirmError={() => setInitialModalType('error')}
                />
              );
            case 'success':
              return <WithdrawResultModal initModalTpe={ResultTpe.Success} onClose={onClose} onclick={onClose} />;
            case 'error':
              return (
                <WithdrawResultModal
                  initModalTpe={ResultTpe.Error}
                  onClose={onClose}
                  onclick={() => setInitialModalType('withdraw')}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    </Dialog>
  );
};

export default WithdrawModal;
