import { SelectOption } from '@cultwines/zellar-client-sdk';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CircledCrossIcon } from '../../assets/icons/circled-cross.svg';
import { DialogContent } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import UnitSizeSelectByVintageId from '../Select/UnitSizeSelectByVintageId';
import VintageSelect from '../Select/VintageSelect';
import Typography from '../Typography';
import WinesAutocompleter from '../WinesAutocompleter';
import { useStyles } from './styles';
import { WineSuggestion } from '../../__generated__/graphql';

interface ModalProps {
  open: boolean;
  onAssetAdded: (assetId: string) => void;
  onClose: () => void;
  selectedAssetIds: string[];
}

export default function AddWineToCompareModal({
  onAssetAdded,
  open,
  onClose,
  selectedAssetIds,
}: ModalProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [sameAssetSelected, setSameAssetSelected] = useState(false);
  const [wine, setWine] = useState<WineSuggestion | null>(null);
  const [vintage, setVintage] = useState<SelectOption<string> | null>(null);
  const [unitSize, setUnitSize] = useState<SelectOption<string> | null>(null);

  useEffect(() => {
    if (selectedAssetIds.findIndex((assetId) => Number(assetId) === unitSize?.value) > -1) {
      setSameAssetSelected(true);
    } else {
      setSameAssetSelected(false);
    }
  }, [unitSize, selectedAssetIds]);

  function onCloseWithModalStateReset() {
    onClose();
    setWine(null);
  }

  function handleWineChange(e: React.SyntheticEvent, value: WineSuggestion | null) {
    setWine(value);
  }

  function handleVintageChange(selectedOption: SelectOption<string> | null) {
    setVintage(selectedOption);
  }

  function handleUnitSizeChange(selectedOption: SelectOption<string> | null) {
    setUnitSize(selectedOption);
  }

  async function handleSubmit() {
    if (!unitSize) {
      return;
    }
    onAssetAdded(unitSize.value.toString());
    onClose();
  }

  return (
    <Dialog
      classes={{ paper: classes.modalContainer }}
      onClose={onCloseWithModalStateReset}
      open={open}
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle id="dialog-title" onClose={onClose}>
        {t('market:addWineModal.title')}
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.container}>
          {/* Removed temporary https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/7098/ */}
          {/* <div className={classes.tilesContainer}>
            <div className={classes.tile}>
              <BottlesIcon />
              <Typography customVariant="subtitle2Bold">{t('common:wines')}</Typography>
            </div>
            <div className={classes.tile}>
              <IndicesIcon className={classes.disabled} />
              <Typography className={classes.disabled} customVariant="subtitle2Bold">
                {t('trade:indices')}
              </Typography>
            </div>
          </div> */}
          <div className={classes.content}>
            <Typography customVariant="subtitle1Bold">{t('common:wine')}</Typography>
            <WinesAutocompleter onChange={handleWineChange} />

            <div className={classes.inputsContainer}>
              <div className={classes.inputContainer}>
                <Typography customVariant="subtitle1Bold">{t('common:vintage')}</Typography>
                <VintageSelect wineId={wine?.wineId} onChange={handleVintageChange} />
              </div>
              <div className={classes.inputContainer}>
                <Typography customVariant="subtitle1Bold">{t('common:unitSize')}</Typography>
                <UnitSizeSelectByVintageId wineVintageId={vintage?.value} onChange={handleUnitSizeChange} />
              </div>
            </div>
            {sameAssetSelected && (
              <Alert icon={<CircledCrossIcon stroke="red" />} severity="error">
                {t('market:addWineModal.alreadySelectedError')}
              </Alert>
            )}
          </div>
        </div>
      </DialogContent>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleSubmit}
        disabled={!wine || !vintage || !unitSize || sameAssetSelected}
      >
        {t('market:addWineModal.add')}
      </Button>
    </Dialog>
  );
}
