import { useQuery } from '@apollo/client';
import { GET_MY_ORDERS } from '../graphql/queries/omsGetMyOrders';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useMyOrders(skip = false) {
  const { data, loading, error, startPolling, stopPolling } = useQuery(GET_MY_ORDERS, { skip });

  return {
    data,
    loading,
    error,
    startPolling,
    stopPolling,
  };
}

export type UseMyOrdersReturnType = ReturnType<typeof useMyOrders>;
