import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SortDirection } from './types';
import Typography from '../Typography';
import { ActiveMarketExtended } from '../../types/ActiveMarketType';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(8),
  },
}));

export type SortOption<K> = {
  id: string;
  key: K;
  active?: boolean;
  primaryLabel: string;
  secondaryLabel?: string;
  filterKey?: keyof ActiveMarketExtended;

  /**
   * TODO: As of https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/12310
   * sort options now no longer always have a direction associated with them. See comments on
   * https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/12322. Essentially
   * BestBids, BestOffers, LatestBids, LatestOffers do not have an order that we on the client side
   * need to specify, the order is implicit in the sort option itself. We have technical debt here
   * whereby we are forced to tie a SortDirection to a SortOption, this should be changed to support
   * optional SortDirection.
   * Created ticket to represent effort here https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/12427
   */
  direction: SortDirection;
};

interface SortMenuProps<K> extends MenuProps {
  options: SortOption<K>[];

  /**
   * @deprecated
   * This function will be replaced with one where sortDirection is optional.
   */
  optionSelected: (id: string, sortDirection: SortDirection) => void;
  selected?: (id: string, sortDirection?: SortDirection) => void;
}

export default function SortMenu<K>({ options, optionSelected, selected, ...rest }: SortMenuProps<K>): JSX.Element {
  const classes = useStyles();
  return (
    <Menu {...rest}>
      {options.map((o, i) => (
        <MenuItem
          className={classes.item}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          selected={o.active}
          onClick={() =>
            selected ? selected(o.id, o.direction) : optionSelected(o.id, o.direction ? o.direction : 'asc')
          }
        >
          <Typography customVariant="body1">{o.primaryLabel}</Typography>
          {o.secondaryLabel && (
            <Typography customVariant="body1" zellarColour="textInformation">
              {o.secondaryLabel}
            </Typography>
          )}
        </MenuItem>
      ))}
    </Menu>
  );
}
