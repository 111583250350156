import { useMutation } from '@apollo/client';
import { SelectOption } from '@cultwines/zellar-client-sdk';
import { CircularProgress, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import { ADD_TO_EXTERNAL_PORTFOLIO } from '../../graphql/mutations/addToExternalPortfolio';
import { selectErrorMessage } from '../../graphql/selectors/selectErrorMessage';
import useMediaQuery from '../../hooks/useMediaQuery';
import { humanReadableDateToDefault } from '../../utils/humanReadableDateToDefault';
import { isDateValid } from '../../utils/isDateValid';
import CurrencyTextField from '../CurrencyTextField';
import DateFieldLabel from '../DateFieldLabel';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import UnitSizeSelectByVintageId from '../Select/UnitSizeSelectByVintageId';
import VintageSelect from '../Select/VintageSelect';
import TextField from '../TextField';
import Typography from '../Typography';
import WinesAutocompleter from '../WinesAutocompleter/Controlled';
import { useStyles } from './styles';
import { WineSuggestion } from '../../__generated__/graphql';

interface Props {
  open: boolean;
  onClose: () => void;
}

interface FormState {
  wineName: string;
  quantity: string;
  purchaseDate: string | undefined;
  purchasePrice: string | undefined;
}

export default function TransferAssetToExternalPortfolioModal({ open, onClose }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { t } = useTranslation();
  const classes = useStyles();
  const [wine, setWine] = React.useState<WineSuggestion | null>(null);
  const [vintage, setVintage] = React.useState<SelectOption<string> | null>(null);
  const [unitSize, setUnitSize] = React.useState<SelectOption<string> | null>(null);

  const [addToExternalPortfolio] = useMutation(ADD_TO_EXTERNAL_PORTFOLIO);

  const validationSchema = yup.object({
    wineName: yup.string().required(),
    quantity: yup
      .string()
      .test('val', t('product:detailsModal.quantityError'), (val) => Number(val) > 0)
      .required(t('product:detailsModal.quantityError')),
    purchaseDate: yup
      .string()
      .test(
        'val',
        t('product:detailsModal.dateFormatInvalid'),
        (val) => val === undefined || /^\d{2}\/\d{2}\/\d{4}$/.test(val),
      )
      .test('val', t('product:detailsModal.dateInvalid'), (val) => val === undefined || isDateValid(val)),
    purchasePrice: yup
      .string()
      .test('val', t('product:detailsModal.numberError'), (val) => val === undefined || Number(val) > 0),
  });

  const handleSubmit = async ({ purchasePrice, purchaseDate, quantity }: FormState) => {
    if (!unitSize?.value) {
      return;
    }
    const { errors } = await addToExternalPortfolio({
      variables: {
        assetId: unitSize.value,
        purchasePrice: purchasePrice !== undefined ? Number(purchasePrice) : null,
        purchaseDate: purchaseDate ? humanReadableDateToDefault(purchaseDate) : null,
        quantity: Number(quantity),
      },
    });
    if (errors) {
      onClose();
      enqueueSnackbar(selectErrorMessage(errors), {
        variant: 'error',
      });
    } else {
      onClose();
      enqueueSnackbar(t('portfolio:transferToExternalPortfolio.success'), {
        variant: 'success',
      });
    }
  };

  const formik = useFormik<yup.InferType<typeof validationSchema>>({
    initialValues: {
      wineName: '',
      quantity: '',
      purchaseDate: undefined,
      purchasePrice: undefined,
    },
    onSubmit: async (formData) => handleSubmit(formData),
    validationSchema,
  });

  function handleUnitSizeChange(selectedOption: SelectOption<string> | null) {
    setUnitSize(selectedOption);
  }

  function handlePurchaseDateOnBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const year = e.target.value.split('/')[2];
    if (Number(year) && Number(year) < Number(vintage?.label)) {
      formik.setFieldError(e.target.name, t('product:detailsModal.dateVintageError'));
    }
    formik.setFieldTouched(e.target.name, true);
  }

  function handleWineChange(e: React.SyntheticEvent, value: WineSuggestion | null) {
    setWine(value);
  }

  function handleVintageChange(selectedOption: SelectOption<string> | null) {
    setVintage(selectedOption);
  }

  return (
    // needs to be maxWidth="md" to suit other values set in ModalContentSwitcher and modalStyles()
    <Dialog onClose={onClose} open={open} disableEscapeKeyDown maxWidth="md" fullScreen={lessThanMd}>
      <div className={classes.modalContainer}>
        <DialogTitle id="dialog-title" onClose={onClose} disableClose={formik.isSubmitting}>
          {t('portfolio:transferToExternalPortfolio.title')}
        </DialogTitle>
        <DialogContentBorderTopOnly className={classes.modalContent} dividers>
          <div className={classes.inputsContainer}>
            <div className={`${classes.inputContainer} ${classes.search}`}>
              <Typography variant="subtitle2">{t('common:wineName')}</Typography>
              <WinesAutocompleter
                onChange={handleWineChange}
                setInputValue={(value) => formik.setFieldValue('wineName', value)}
                inputValue={formik.values.wineName}
              />
            </div>
            <div className={classes.inputContainer}>
              <Typography variant="subtitle2">{t('common:vintage')}</Typography>
              <VintageSelect wineId={wine?.wineId} onChange={handleVintageChange} />
            </div>
            <div className={`${classes.inputContainer} ${classes.unitSize}`}>
              <Typography variant="subtitle2">{t('common:unitSize')}</Typography>
              <UnitSizeSelectByVintageId wineVintageId={vintage?.value} onChange={handleUnitSizeChange} />
            </div>
            <div className={`${classes.inputContainer} ${classes.quantity}`}>
              <Typography variant="subtitle2">{t('common:quantity')}*</Typography>
              <TextField
                name="quantity"
                type="number"
                value={formik.values.quantity}
                placeholder={t('common:quantity')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                helperText={formik.touched.quantity && formik.errors.quantity}
                variant="outlined"
              />
            </div>
            <div className={`${classes.inputContainer} ${classes.purchaseDate}`}>
              <DateFieldLabel
                labelVariant="subtitle2"
                captionColour="textLabel"
                label={t('portfolio:transferToExternalPortfolio.purchasedDate')}
              />
              <InputMask
                className={`${classes.inputContainer} ${classes.purchaseDate}`}
                mask="99/99/9999"
                value={formik.values.purchaseDate}
                onChange={formik.handleChange}
                onBlur={handlePurchaseDateOnBlur}
              >
                {() => (
                  <TextField
                    name="purchaseDate"
                    placeholder="DD/MM/YYYY"
                    error={formik.touched.purchaseDate && Boolean(formik.errors.purchaseDate)}
                    helperText={formik.touched.purchaseDate && formik.errors.purchaseDate}
                    variant="outlined"
                  />
                )}
              </InputMask>
            </div>
            <div className={`${classes.inputContainer} ${classes.purchasePrice}`}>
              <Typography variant="subtitle2">{t('portfolio:transferToExternalPortfolio.purchasedPrice')}</Typography>
              <CurrencyTextField
                name="purchasePrice"
                value={formik.values.purchasePrice || ''}
                placeholder={t('portfolio:transferToExternalPortfolio.purchasedPrice')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.purchasePrice && Boolean(formik.errors.purchasePrice)}
                helperText={formik.touched.purchasePrice && formik.errors.purchasePrice}
              />
            </div>
          </div>
          <div className={classes.actions}>
            <Button
              variant="text"
              className={classes.cancelLabel}
              color="info"
              disabled={formik.isSubmitting}
              onClick={onClose}
            >
              {t('common:cancel')}
            </Button>
            <Button
              className={classes.submit}
              variant="contained"
              color="primary"
              size="large"
              onClick={formik.submitForm}
              disabled={formik.isSubmitting || !formik.isValid || !unitSize?.value}
            >
              {formik.isSubmitting ? (
                <CircularProgress color="primary" size={20} />
              ) : (
                t('portfolio:transferToExternalPortfolio.submit')
              )}
            </Button>
          </div>
        </DialogContentBorderTopOnly>
      </div>
    </Dialog>
  );
}
