import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

const useStyles = makeStyles<Theme, { width: number; height: number }>((theme) => ({
  container: {
    border: `2px dashed ${theme.palette.grey[100]}`,
    background: theme.palette.grey[50],
    width: (props) => props.width,
    height: (props) => props.height,
    cursor: 'pointer',
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  capitalise: {
    textTransform: 'capitalize',
  },
  plusIcon: {
    stroke: theme.palette.text.primary,
    width: 20,
    height: 20,
  },
}));

interface EmptyAddressButtonProps {
  onClick: () => void;
  width?: number;
  height?: number;
  className?: string;
}

export default function EmptyAddressButton({
  onClick,
  width = 340,
  height = 136,
  className = '',
}: EmptyAddressButtonProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles({ width, height });
  return (
    <button type="button" className={`${classes.container} ${className}`} onClick={onClick}>
      <PlusIcon className={classes.plusIcon} />
      <Typography customVariant="subtitle1" className={classes.capitalise}>
        {' '}
        {t('common:addAddress')}
      </Typography>
    </button>
  );
}
