import { graphql } from '../../__generated__';

export const GET_MY_ORDERS = graphql(`
  query OMSGetMyOrders {
    omsGetMyOrders {
      id
      clientOrderId
      status
      asset {
        id
        unitSize
        unitCount
        spread {
          lowestOffer {
            userId
            price
          }
          highestBid {
            userId
            price
          }
        }
        vintage {
          id
          imageFileName
          calculatedMarketData {
            combinedScore
          }
          wine {
            id
            name
            region {
              name
            }
            appellation {
              name
            }
          }
          vintage
        }
      }
      executableWindow {
        endTime
      }
      price
      direction
      cancelled
      submittedTimeUtc
      outstandingQuantity
      quantity
    }
  }
`);
