import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHistoricalMarketValueChangeData from '../../hooks/useHistoricalMarketValueChangeData';
import useMediaQuery from '../../hooks/useMediaQuery';
import { DateRangeVariant } from '../../types/DateRangeVariant';
import calculateDateRange from '../../utils/calculateDateRange';
import { logError } from '../../utils/logger';
import normalizeMultipleChartDateDataWithId from '../../utils/normalizeMultipleChartDateDataWithId';
import selectChartExtremesFromMultipleSeries from '../../utils/selectChartExtremesFromMultipleSeries';
import selectChartSeriesNamesWithId from '../../utils/selectChartSeriesNamesWithId';
import DateRangePicker from '../ProductLineChart/DateRangePicker';
import ToggleButton from '../ToggleButton';
import MultiSeriesLineChart from './MultiSeriesLineChart';
import { graphql } from '../../__generated__';
import { Asset, HistoricalMarketDataChange } from '../../__generated__/graphql';

const dateRangeVariants = ['6M', '1Y', '5Y', '10Y', 'YTD'] as DateRangeVariant[];

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  footer: {
    marginLeft: theme.spacing(9),
  },
  buttonsContainer: {
    marginLeft: 'auto',
  },
}));

interface ProductLineChartProps {
  assetIds: number[];
  colorByAssetId: Record<string, string>;
  onAddClicked: () => void;
}

const GET_MARKET_DATAS_QUERY = graphql(`
  query MarketDatasByAssetIds($assetIds: [Int!]!, $marketDataDateFrom: String, $marketDataDateTo: String) {
    productAssets(assetIds: $assetIds) {
      id
      vintage {
        id
        vintage
        wine {
          id
          name
        }
      }
      marketData(dateFrom: $marketDataDateFrom, dateTo: $marketDataDateTo) {
        wineSearcher
      }
    }
  }
`);

enum ChartType {
  Absolute,
  Rebase,
}

export default function MarketComparisonLineChart({
  assetIds,
  colorByAssetId,
  onAddClicked,
}: ProductLineChartProps): JSX.Element {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeVariant>('1Y');
  const [dateFrom, setDateFrom] = useState<string>(calculateDateRange(selectedDateRange).from);
  const [dateTo, setDateTo] = useState<string>(calculateDateRange(selectedDateRange).to);
  const [chartType, setChartType] = useState<ChartType>(ChartType.Absolute);
  const classes = useStyles();
  const theme = useTheme();
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const {
    data,
    loading,
    error: marketDataError,
  } = useQuery(GET_MARKET_DATAS_QUERY, {
    skip: assetIds.length === 0,
    variables: {
      assetIds,
      marketDataDateFrom: dateFrom,
      marketDataDateTo: dateTo,
    },
  });
  const { data: percentageData, error: historicalMarketValueQueryError } = useHistoricalMarketValueChangeData({
    skip: assetIds.length === 0,
    variables: {
      assetIds,
      dateFrom,
      dateTo,
    },
  });

  function handleDateRangeChanged(dateRangeVariant: DateRangeVariant) {
    setSelectedDateRange(dateRangeVariant);
    const range = calculateDateRange(dateRangeVariant);
    setDateFrom(range.from);
    setDateTo(range.to);
  }

  function handleChange(toggleState: boolean): void {
    setChartType(toggleState ? ChartType.Rebase : ChartType.Absolute);
  }

  const latestData = data?.productAssets as Asset[];

  const latestPercentageData = percentageData?.historicalMarketValueChange as HistoricalMarketDataChange[];
  const normalisedData = normalizeMultipleChartDateDataWithId(latestData);
  const normalisedPercentageData = normalizeMultipleChartDateDataWithId(latestPercentageData);
  const seriesNames = useMemo(() => selectChartSeriesNamesWithId(latestData), [latestData]);
  const [min, max] = useMemo(() => selectChartExtremesFromMultipleSeries(latestData), [latestData]);
  const [percentageMin, percentageMax] = useMemo(
    () => selectChartExtremesFromMultipleSeries(latestPercentageData),
    [latestPercentageData],
  );
  if (marketDataError || historicalMarketValueQueryError) {
    logError({
      originalError: marketDataError || historicalMarketValueQueryError,
      error: new Error('Chart data failed to load'),
      filename: 'MarketComparisonLineChart/ChartWrapper',
      additionalInfo: {
        marketDataError: JSON.stringify(marketDataError),
        historicalMarketValueQueryError: JSON.stringify(historicalMarketValueQueryError),
      },
    });
    return <Typography>An error occurred</Typography>;
  }

  const isAbsoluteChart = chartType === ChartType.Absolute;
  return (
    <div className={classes.container}>
      <div className={classes.buttonsContainer}>
        <ToggleButton
          falseStateName={t('market:absolute')}
          trueStateName={t('market:rebase')}
          onToggle={handleChange}
          toggleState={!!chartType}
        />
      </div>
      <MultiSeriesLineChart
        data={isAbsoluteChart ? normalisedData : normalisedPercentageData}
        onAddClicked={onAddClicked}
        assetIds={assetIds}
        colorByAssetId={colorByAssetId}
        seriesNames={seriesNames}
        title={isAbsoluteChart ? t('product:price') : t('common:percentage')}
        isAbsoluteChart={isAbsoluteChart}
        min={isAbsoluteChart ? min : percentageMin}
        max={isAbsoluteChart ? max : percentageMax}
        loading={loading}
      />
      {greaterThanSm && (
        <div className={classes.footer}>
          <DateRangePicker
            dateRangeUpdated={handleDateRangeChanged}
            selectedDateRangeVariant={selectedDateRange}
            variants={dateRangeVariants}
          />
        </div>
      )}
    </div>
  );
}
