import { useMutation } from '@apollo/client';
import { graphql } from '../__generated__';

const RESEND_VERIFICATION_EMAIL = graphql(`
  mutation AuthResendVerificationEmail($resendVerificationEmailInput: AuthResendVerificationEmailInput!) {
    authResendVerificationEmail(resendVerificationEmailInput: $resendVerificationEmailInput) {
      wasVerificationEmailResent
    }
  }
`);

export const useResendVerificationEmail = () => {
  const [resendVerificationEmail, { loading }] = useMutation(RESEND_VERIFICATION_EMAIL, {
    context: { serviceName: 'insecure' },
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });

  return { resendVerificationEmail, loading };
};
