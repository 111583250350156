import clsx from 'clsx';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Typography, { TypographyVariantClassKey } from '../Typography';
import CalculationToolTip from '../CalculationToolTip';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    textTransform: 'uppercase',
    gap: theme.spacing(1),
  },
  firstCapital: {
    textTransform: 'capitalize',
  },
}));

interface GridItemProps {
  label: string;
  conatinerClassName?: string;
  labelVariant?: TypographyVariantClassKey;
  value: string | JSX.Element;
  statusColor?: 'textSuccess' | 'textSecondaryHighlight';
  valueCapitalize?: boolean;
  extraElement?: JSX.Element;
}

export default function GridItem({
  label,
  value,
  labelVariant = 'label2',
  statusColor,
  extraElement,
  valueCapitalize = false,
  conatinerClassName = '',
}: GridItemProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={clsx(classes.container, conatinerClassName)}>
      <Typography className={clsx(classes.label)} customVariant={labelVariant} zellarColour="textLabel">
        {label} {extraElement}
        {label === 'Liquidity score' ? <CalculationToolTip title={t('portfolio:liquidityScoreCalculation')} /> : ''}
      </Typography>
      {typeof value === 'string' ? (
        <Typography
          zellarColour={statusColor}
          customVariant="subtitle2"
          style={{ textTransform: valueCapitalize ? 'capitalize' : 'none' }}
        >
          {value}
        </Typography>
      ) : (
        value
      )}
    </div>
  );
}
