import { graphql } from '../../__generated__';

export const SEARCH_WINE_VINTAGES_FACETS = graphql(`
  mutation SearchWineVintagesFacets($queryString: String!, $filterNames: [String!]!, $preQueryString: String) {
    searchWineVintagesFacets(queryString: $queryString, filterNames: $filterNames, preQueryString: $preQueryString) {
      facets {
        Region
        MarketValue
        WineName
        Score
        PercentageDifference
        Vintage
        Appellation
        Classification
        Producer
        LiquidityRank
      }
    }
  }
`);
