import React from 'react';
import MuiCheckbox, { CheckboxProps as BaseProps } from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';

const useCheckboxStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

interface CheckboxProps extends BaseProps {
  error?: boolean;
}

export default function Checkbox({ error = false, ...rest }: CheckboxProps): JSX.Element {
  const classes = useCheckboxStyles();
  return <MuiCheckbox className={error ? classes.error : ''} {...rest} />;
}
