import React from 'react';
import { useTranslation } from 'react-i18next';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '../Typography';
import TabPanel from '../TabPanel';
import WithdrawUKForm from './WithdrawUKForm';
import WithdrawInternationalForm from './WithdrawInternationalForm';
import { WithdrawFundsModelType } from './types';

export interface WithdrawFundsModalProps {
  disabled?: boolean;
  onSubmit: (values: WithdrawFundsModelType) => void;
}

export default function DisplayForm({ disabled, onSubmit }: WithdrawFundsModalProps): JSX.Element {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  return (
    <>
      <Typography textTransform="uppercase" marginBottom={1} customVariant="label1Active" zellarColour="textLabel">
        {t('account:paymentDetails.withdrawableForm.title')}
      </Typography>

      <Tabs
        value={value}
        indicatorColor="primary"
        onChange={(event: React.SyntheticEvent, newValue: number) => setValue(newValue)}
        variant="fullWidth"
        centered
      >
        <Tab label={t('wallet:withdraw.uk')} />
        <Tab label={t('wallet:withdraw.international')} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <WithdrawUKForm onSubmit={onSubmit} disabled={disabled} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WithdrawInternationalForm onSubmit={onSubmit} disabled={disabled} />
      </TabPanel>
    </>
  );
}
