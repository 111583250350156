import { useQuery } from '@apollo/client';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { sort } from '@cultwines/zellar-client-sdk/utils/sort';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SelectedRowsProvider, useSelectedRowsContext } from '../../context/selectedRowsContext';
import { OWNED_ASSETS_QUERY } from '../../graphql/queries/ownedAssets';
import { OwnedAssetFacets, selectOwnedAssets } from '../../graphql/selectors/ownedAssets';
import useMediaQuery from '../../hooks/useMediaQuery';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';
import { getPage } from '../../utils/getPage';
import { uuid } from '../../utils/uuid';
import EmptyPlaceholder from '../EmptyPlaceholder';
import ErrorPlaceholder from '../ErrorPlaceholder';
import Toolbar, { ViewType } from '../Table/Toolbar';
import { SortDirection } from '../Table/types';
import TablePagination from '../TablePagination';
import TransferToExternalLocationModal from '../TransferToExternalLocationModal';
import CurrentHoldingsAssetCard from '../WineCard/CurrentHoldingsAssetCard';
import WineCardSkeleton from '../WineCardSkeleton';
import { getSortOptions } from './sortOptions';
import CurrentHoldingsTable from './Table';
import OrderModal from '../OrderModal';
import { Mode } from '../OrderModal/types';
import CreateBuyNowModal from '../BuyNowModal';
import { logError } from '../../utils/logger';
import CultXPortfolioTable from '../CultXPortfolioTable';
import { TableRefactorFlagSet } from '../../types/FeatureFlags';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gap: theme.spacing(4),
    },
  },
  cardList: {
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
    padding: '0 10px',
  },
  card: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: `calc(50% - ${theme.spacing(2)})`,
    },

    [theme.breakpoints.up('xl')]: {
      width: `calc(33% - ${theme.spacing(2.2)})`,
    },
  },

  filters: {
    background: theme.palette.grey[50],
  },
  searchResults: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      minWidth: '75%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

interface CurrentHoldingsProps {
  className?: string;
}

enum Modal {
  Bid,
  Offer,
  Buy,
  Delete,
  Transfer,
}

const PAGE_SIZE_VARIANTS = [20, 60, 90];

function CurrentHoldings({ className }: CurrentHoldingsProps): JSX.Element {
  const { tableRefactor } = useFlags<{ tableRefactor?: TableRefactorFlagSet }>();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { state: selectedRowsState, dispatch } = useSelectedRowsContext();
  const theme = useTheme();
  const { showTopUpBuySuccessSummaryModal } = useCardPaymentCallback();
  const { data: ownedAssetsRaw, error, loading } = useQuery(OWNED_ASSETS_QUERY);
  const lessThanXl = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));
  const [viewType, setViewType] = React.useState<ViewType>('table');
  const [assetForOfferModal, setAssetForOfferModal] = React.useState<number | null>(null);
  const [openModal, setOpenModal] = React.useState<Modal | null>(null);
  const [assetId, setAssetId] = React.useState<number | null>(null);
  const [sortFacet, setSortFacet] = React.useState<keyof OwnedAssetFacets>('name');
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc');
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE_VARIANTS[0]);
  const ownedAssets = React.useMemo(
    () =>
      selectOwnedAssets(ownedAssetsRaw)
        .sort((a, b) => sort(a[sortFacet] ?? null, b[sortFacet] ?? null, sortDirection))
        .slice(...getPage(page, pageSize)),
    [ownedAssetsRaw, sortFacet, sortDirection, page, pageSize],
  );

  const sortOptions = React.useMemo(() => getSortOptions(sortDirection, sortFacet, t), [sortDirection, sortFacet, t]);

  function handleToolbarSortChanged(id: string): void {
    const opt = sortOptions.find((sO) => sO.id === id);
    if (isUndefined(opt)) {
      return;
    }

    setSortFacet(opt.key);
    setSortDirection(opt.direction);
  }

  function handleSortUpdated(key: keyof OwnedAssetFacets): void;
  function handleSortUpdated(key: keyof OwnedAssetFacets, direction: SortDirection): void;
  function handleSortUpdated(key: keyof OwnedAssetFacets, direction?: SortDirection): void {
    if (!direction) {
      if (key !== sortFacet) {
        // a different facet has been selected
        setSortFacet(key);
        setSortDirection('asc');
      } else {
        // it's just the sort direction that has changed
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      }
    } else {
      setSortFacet(key);
      setSortDirection(direction);
    }
  }

  function handleChangePage(_: unknown, newPage: number): void {
    setPage(newPage);
  }

  function handleChangeResultPerPage(event: React.ChangeEvent<HTMLInputElement>): void {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleCompareClick(_assetId: number): void;
  function handleCompareClick(rowId: string): void;
  function handleCompareClick(id: string | number): void {
    history.push(`/market?assets=${id}`);
  }

  function handleOfferModalOpenClick(_assetId: number): void;
  function handleOfferModalOpenClick(rowId: string): void;
  function handleOfferModalOpenClick(id: string | number): void {
    setAssetForOfferModal(Number(id));
  }

  function handleTransferOutsideCultwineClicked() {
    setOpenModal(Modal.Transfer);
  }

  function handleBulkCompareClicked() {
    history.push(`/market?assets=${selectedRowsState.selectedRows.join(',')}`);
  }

  function onBidClicked(_assetId: number): void;
  function onBidClicked(rowId: string): void;
  function onBidClicked(id: string | number): void {
    setOpenModal(Modal.Bid);
    setAssetId(Number(id));
  }

  function onBuyClicked(_assetId: number): void;
  function onBuyClicked(rowId: string): void;
  function onBuyClicked(id: string | number): void {
    setOpenModal(Modal.Buy);
    setAssetId(Number(id));
  }

  function handleTransferAssetModalClosed(): void {
    setOpenModal(null);
    dispatch({ type: 'reset' });
  }
  function handleEmpty(): void {
    history.push('/discover');
  }

  if (error) {
    logError({ error: new Error('Failed to load owned assets'), originalError: error, filename: 'CurrentHoldings' });
  }

  const TableComponent = tableRefactor?.cultxPortfolio ? (
    <CultXPortfolioTable
      assets={ownedAssets}
      sortColumn={sortFacet}
      sortDirection={sortDirection}
      onSortChanged={handleSortUpdated}
      loading={loading}
      error={error?.message}
      onBidClicked={onBidClicked}
      onBuyNowClicked={onBuyClicked}
      onCompareClicked={handleCompareClick}
      onOfferClicked={handleOfferModalOpenClick}
      onEmptyClicked={handleEmpty}
    />
  ) : (
    <CurrentHoldingsTable
      assets={ownedAssets}
      error={error}
      loading={loading}
      activeSortKey={sortFacet}
      onSortChanged={handleSortUpdated}
      sortDirection={sortDirection}
      onEmptyClicked={handleEmpty}
    />
  );

  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.searchResults}>
        <Toolbar
          resultCount={ownedAssetsRaw?.holdingsCurrent.length ?? 0}
          viewType={viewType}
          onChangeViewType={setViewType}
          onSortChanged={handleToolbarSortChanged}
          sortOptions={sortOptions}
          hideChangeViewType={lessThanXl}
          displayCompareButton
          displayTransferOutsideCultwineButton
          onTransferOutsideCultwineClicked={handleTransferOutsideCultwineClicked}
          onCompareClicked={handleBulkCompareClicked}
        />
        {viewType === 'card' || lessThanXl ? (
          <div className={classes.cardList}>
            {loading &&
              !error &&
              new Array(20).fill(0).map(() => <WineCardSkeleton className={classes.card} key={uuid()} />)}
            {error && <ErrorPlaceholder error={error.message} />}
            {!ownedAssets.length && !loading && !error && (
              <EmptyPlaceholder
                label={t('portfolio:noPortfolio')}
                ActionButton={
                  <Button variant="contained" color="primary" size="large" onClick={handleEmpty}>
                    {t('common:discover')}
                  </Button>
                }
              />
            )}
            {!error &&
              !loading &&
              ownedAssets.length > 0 &&
              ownedAssets.map((asset) => (
                <CurrentHoldingsAssetCard
                  className={classes.card}
                  key={`card-${asset.assetId}`}
                  asset={asset}
                  onBidClicked={onBidClicked}
                  onOfferClicked={handleOfferModalOpenClick}
                  onCompareClicked={handleCompareClick}
                  onBuyClicked={onBuyClicked}
                />
              ))}
          </div>
        ) : (
          TableComponent
        )}
        <TablePagination
          component="div"
          rowsPerPageOptions={PAGE_SIZE_VARIANTS}
          count={ownedAssetsRaw?.holdingsCurrent.length ?? 0}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeResultPerPage}
        />
      </div>
      {assetId && openModal === Modal.Buy && (
        <CreateBuyNowModal assetId={assetId} open={openModal === Modal.Buy} onClose={() => setOpenModal(null)} />
      )}
      {assetId && showTopUpBuySuccessSummaryModal && (
        <CreateBuyNowModal assetId={Number(assetId)} open onClose={() => setOpenModal(null)} />
      )}
      {!isNull(assetForOfferModal) && (
        <OrderModal
          assetId={assetForOfferModal}
          open={!isNull(assetForOfferModal)}
          onClose={() => setAssetForOfferModal(null)}
          mode={Mode.Create}
          tradeType="offer"
        />
      )}
      {assetId && openModal === Modal.Bid && (
        <OrderModal
          assetId={assetId}
          open={openModal === Modal.Bid}
          onClose={() => setOpenModal(null)}
          mode={Mode.Create}
          tradeType="bid"
        />
      )}
      {openModal === Modal.Transfer && (
        <TransferToExternalLocationModal onClose={handleTransferAssetModalClosed} open={openModal === Modal.Transfer} />
      )}
    </div>
  );
}

export default function CurrentHoldingsWithContext(props: CurrentHoldingsProps): JSX.Element {
  return (
    <SelectedRowsProvider defaultState={{ maxRows: 8 }}>
      <CurrentHoldings {...props} />
    </SelectedRowsProvider>
  );
}
