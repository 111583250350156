import { useMutation } from '@apollo/client';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TransferArrow } from '../../../../assets/icons/transfer-arrow.svg';
import { ReactComponent as Trash } from '../../../../assets/images/trash.svg';
import { DELETE_FROM_EXTERNAL_PORTFOLIO } from '../../../../graphql/mutations/deleteFromExternalPortfolio';
import { formatter } from '../../../../utils/currencyFormatter';
import { humanReadableDate } from '../../../../utils/humanReadableDate';
import GridItem from '../../../SearchResults/GridItem';
import TransferAssetModal from '../../../TransferAssetModal';
import Typography from '../../../Typography';
import { useAssetInstanceStyles } from './styles';
import { ExternalPortfolioInstance } from '../../../../__generated__/graphql';

export interface AssetInstanceProps {
  className?: string;
  instance: ExternalPortfolioInstance;
  assetId: number;
}

export default function AssetInstance({ className = '', instance, assetId }: AssetInstanceProps): JSX.Element {
  const classes = useAssetInstanceStyles();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { purchasePrice, purchaseDate } = instance;
  const { enqueueSnackbar } = useSnackbar();

  const [deleteExternalPortfolioAssetInstance, { loading: deleteFromExternalPortfolioLoading }] =
    useMutation(DELETE_FROM_EXTERNAL_PORTFOLIO);

  const handleDeleteClicked = async () => {
    const { errors } = await deleteExternalPortfolioAssetInstance({
      variables: {
        externalPortfolioId: instance.id,
      },
    });
    if (errors) {
      enqueueSnackbar(t('common:somethingWentWrong'), {
        variant: 'error',
      });
    } else {
      enqueueSnackbar(t('portfolio:externalPortfolio.deleteSuccess'), {
        variant: 'success',
      });
    }
  };
  return (
    <div className={`${className} ${classes.container}`}>
      <div className={classes.row}>
        <GridItem
          label={t('portfolio:table.headers.entryDate')}
          labelVariant="caption"
          value={purchaseDate ? humanReadableDate(purchaseDate.split('T')[0]) : t('product:notAvailable')}
        />
        <GridItem
          label={t('portfolio:table.headers.purchasePrice')}
          labelVariant="caption"
          value={purchasePrice ? formatter.format(purchasePrice) : t('product:notAvailable')}
        />
      </div>
      <div className={clsx({ [classes.right]: true, [classes.actions]: true })}>
        <Button
          onClick={() => setModalOpen(true)}
          className={`${classes.link} ${classes.transferLink}`}
          variant="text"
          disabled
        >
          <Typography customVariant="body2">{t('common:transfer')}</Typography>
          <TransferArrow />
        </Button>
        <IconButton
          className={classes.icon}
          onClick={handleDeleteClicked}
          disabled={deleteFromExternalPortfolioLoading}
          size="large"
        >
          <Trash />
        </IconButton>
      </div>
      {modalOpen && <TransferAssetModal assetId={assetId} open={modalOpen} onClose={() => setModalOpen(false)} />}
    </div>
  );
}
