import React from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import BulletPoint from './BulletPoint';
import BulletPointSkeleton from './BulletPointSkeleton';
import { selectNormalizedBulletPointsData } from './selectors';
import { BulletPointKeys } from './types';
import { graphql } from '../../../__generated__';
import { Asset } from '../../../__generated__/graphql';
import { isLoggedInVar } from '../../../graphql/cache';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    /**
     * To cater for the top margin we give to
     * the child elements. Keeps consistent
     * spacing between the dividers.
     * https://stackoverflow.com/a/46765935
     */
    marginTop: `-${theme.spacing(2)}`,

    '& div': {
      marginTop: theme.spacing(2),
    },
  },
}));

export const GET_ASSET_BY_ID = graphql(`
  query AssetById($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      vintage {
        id
        vintage
        alcoholPct
        wine {
          id
          regionalisedWineColour
          producer {
            id
            title
            name
          }
          sparkling
          country
          region {
            id
            name
          }
          appellation {
            id
            name
          }
          sweetness
          storingPosition
          classification
        }
      }
    }
  }
`);

interface BulletPointsProps {
  assetId?: number;
  isProductError?: boolean;
}

// Memoized because uses expensive selector - selectNormalizedBulletPointsData
export default React.memo(function BulletPoints({ assetId: id, isProductError }: BulletPointsProps): JSX.Element {
  const classes = useStyles();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const assetId = Number(id);

  const { loading, error, data } = useQuery(GET_ASSET_BY_ID, {
    variables: { assetId },
    ...(isLoggedIn
      ? {}
      : {
          context: {
            serviceName: 'insecure',
          },
        }), // Conditionally add serviceName
  });

  if (loading)
    return (
      <div className={classes.container}>
        {[...Array(9)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <BulletPointSkeleton key={`bullet-point-skeleton-${i}`} />
        ))}
      </div>
    );

  if (error || isProductError) return <>Not found...</>;
  if (!data || !data.productAsset) return <>No data...</>;
  const wineDetails = selectNormalizedBulletPointsData(data.productAsset as Asset);

  return (
    <div className={classes.container}>
      {Object.keys(wineDetails).map((key) => (
        <BulletPoint key={key} title={key} label={wineDetails[key as BulletPointKeys]} />
      ))}
    </div>
  );
});
