import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Tokens, getRefreshToken, updateAccessToken, updateRefreshToken, updateUserToken } from '../services/auth';
import { REFRESH_ACCESS_TOKEN } from '../graphql/mutations/refreshAccessToken';
import { logMessage } from './logger';
import { RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables } from '../__generated__/graphql';

export async function refreshAccessToken(client: ApolloClient<NormalizedCacheObject>) {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    throw new Error('refreshToken retrieved from local storage is either null or undefined');
  }
  return client
    .mutate<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>({
      context: {
        serviceName: 'insecure',
      },
      mutation: REFRESH_ACCESS_TOKEN,
      variables: {
        input: {
          clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
          refreshToken,
        },
      },
    })
    .then(({ data }) => {
      if (
        !data?.authRefreshAccessToken.accessToken ||
        !data.authRefreshAccessToken.refreshToken ||
        !data.authRefreshAccessToken.userToken
      ) {
        logMessage('Missing tokens when attempting refresh', {
          additionalInfo: {
            accessTokenIsAvailable: Boolean(data?.authRefreshAccessToken.accessToken),
            refreshTokenIsAvailable: Boolean(data?.authRefreshAccessToken.refreshToken),
            userTokenIsAvailable: Boolean(data?.authRefreshAccessToken.userToken),
          },
        });
        throw new Error('Missing tokens');
      }
      // TODO: This is a side-effect that this function shouldn't include. This function also violates the SRP.
      // Refactor this part out if at all possible.
      updateAccessToken(data.authRefreshAccessToken.accessToken);
      updateRefreshToken(data.authRefreshAccessToken.refreshToken);
      updateUserToken(data.authRefreshAccessToken.userToken);

      return data.authRefreshAccessToken as Tokens;
    });
}
