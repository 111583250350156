import { Mode } from '../OrderModal/types';
import { FormState, Order } from './types';
import { ExpirationDate } from '../Select/types';

export function selectFormState({
  initialState,
  mode,
  order,
}: {
  mode: Mode;
  order: Order | null;
  initialState: FormState | undefined;
}): FormState {
  if (initialState) {
    return initialState;
  }

  if (mode === Mode.Edit && order) {
    return {
      expirationDate: ExpirationDate.ThirtyDays,
      price: order.price.toString(),
      quantity: order.outstandingQuantity.toString(),
    };
  }

  return { expirationDate: ExpirationDate.ThirtyDays, price: '', quantity: '' };
}
