import { useQuery, ApolloError } from '@apollo/client';
import { graphql } from '../__generated__';
import { AuthUser } from '../__generated__/graphql';

export const USER_DETAILS_QUERY = graphql(`
  query UserDetailsQuery {
    authUser {
      userId
      emailAddress
      firstName
      surname
      kycStatus
      accountType
      mitConsentedFlag
    }
  }
`);

export interface UseUserDetailsResponse {
  loading: boolean;
  error: ApolloError | undefined;
  userDetails: Omit<AuthUser, 'fullName' | 'isPhoneNumberVerified'> | undefined;
  refetch: () => void;
}

export default function useUserDetails(): UseUserDetailsResponse {
  const { data, error, loading, refetch } = useQuery(USER_DETAILS_QUERY);
  return { loading, error, userDetails: data?.authUser, refetch };
}
