import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { logError } from '../../utils/logger';
import { colours } from '../../theme/onboarding/palette';
import { useResendVerificationEmail } from '../../hooks/useResendVerificationEmail';

export const ResendVerificationEmail = ({ userEmailForResend }: { userEmailForResend: string | null }) => {
  const [resent, setResent] = useState(false);
  const { resendVerificationEmail, loading } = useResendVerificationEmail();
  const { t } = useTranslation();

  return (
    <Typography
      variant="body1"
      color={resent || loading ? colours.grey : colours.brightOrange}
      textAlign="center"
      onClick={async () => {
        try {
          if (resent || !userEmailForResend || loading) {
            return;
          }

          await resendVerificationEmail({
            variables: {
              resendVerificationEmailInput: {
                emailAddress: userEmailForResend,
                clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
                application: 'Zellar',
              },
            },
          });

          setResent(true);
        } catch (error) {
          logError({
            error: new Error(`Failed to resend verification email for user ${userEmailForResend}`),
            filename: 'AuthV2/index',
            additionalInfo: {
              errors: JSON.stringify(error),
            },
            tags: { userFlow: 'account' },
          });
        }
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      {t('account:register.notSent')}
    </Typography>
  );
};
