import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import articleData from '../../assets/data/articles';
import Typography from '../../components/Typography';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2.5),
  },
  firstParagraph: {
    marginBottom: theme.spacing(7.5),
  },
  image: {
    width: '100%',
    marginBottom: theme.spacing(10),
  },
  li: {
    ...theme.typography.body1,
  },
}));

interface StaticArticleContent {
  body1?: string;
  body2?: string;
  h4?: string;
  ul?: Array<{ body1: string }>;
}

export default function Article(): JSX.Element {
  const queryParams = window.location.pathname.split('/');
  const slug = queryParams[queryParams.length - 1];
  const content = articleData.articles.find((contentItem) => contentItem.slug === slug);
  const [image, setImage] = React.useState();

  React.useEffect(() => {
    import(`../../assets/images/articles/${content!.image}`).then((lazyImage) => {
      setImage(lazyImage.default);
    });
  }, [content]);

  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title} variant="h1">
        {content!.title}
      </Typography>
      <Typography className={classes.firstParagraph} zellarColour="textLabel" variant="body1">
        {content!.description}
      </Typography>
      {content?.secondaryDescription && (
        <Typography className={classes.firstParagraph} zellarColour="textLabel" variant="body1">
          {content.secondaryDescription}
        </Typography>
      )}
      {image && <img className={classes.image} src={image} alt={content!.title} />}

      {content!.content.map((contentSegment: StaticArticleContent, index) => {
        const key = Object.keys(contentSegment)[0] as keyof StaticArticleContent;

        if (key === 'ul') {
          return (
            <ul key={index}>
              {contentSegment[key]?.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li className={classes.li} key={i}>
                  {item.body1}
                </li>
              ))}
            </ul>
          );
        }
        return (
          <>
            <Typography zellarColour="textPrimary" customVariant={key}>
              {contentSegment[key]}
            </Typography>
            <br />
          </>
        );
      })}
    </div>
  );
}
