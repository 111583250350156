import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formBox: {
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'stretch',
    justifyContent: 'space-evenly',
    boxShadow: 'none',

    [theme.breakpoints.up('sm')]: {},

    [theme.breakpoints.up('lg')]: {},
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    alignItems: 'center',
    width: '100%',

    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: '100%',
    },
  },
}));

export { useStyles };
