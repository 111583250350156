import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import Typography from '../Typography';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    gap: 4,
  },
}));

interface LastTradedCellProps {
  tradingInfo: {
    lastTraded: string | null;
    lastTradedValue: number | null | undefined;
  };
  active: boolean;
  className?: string;
}

function LastTradedCell({ className = '', tradingInfo, active }: LastTradedCellProps): JSX.Element {
  const { lastTraded, lastTradedValue } = tradingInfo;
  const { t } = useTranslation();
  const classes = useStyles();
  if (!lastTraded) {
    return (
      <div className={clsx(classes.container, className)} role="cell">
        <Typography
          customVariant={active ? 'body2Bold' : 'body2'}
          zellarColour={active ? 'textPrimary' : 'textInformation'}
        >
          {t('product:notAvailable')}
        </Typography>
        <Typography customVariant="label2" zellarColour="textInformation">
          ---
        </Typography>
      </div>
    );
  }
  return (
    <div
      className={clsx(classes.container, className)}
      aria-label="last-traded-cell"
      role="cell"
      title="last-traded-cell"
    >
      <Typography
        customVariant={active ? 'body1Bold' : 'body1'}
        zellarColour={active ? 'textPrimary' : 'textInformation'}
      >
        {!isNullOrUndefined(lastTradedValue)
          ? formatterWholeNumber.format(lastTradedValue).split('.')[0]
          : t('product:notAvailable')}
      </Typography>
      <Typography customVariant="body2" zellarColour="textInformation">
        {lastTraded}
      </Typography>
    </div>
  );
}

export default LastTradedCell;
