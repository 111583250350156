import { Fees, isUndefined } from '@cultwines/zellar-client-sdk';
import { calculateFee } from '@cultwines/zellar-client-sdk/utils/calculateFee';
import {
  CalculateCostArgs,
  CostBreakdown,
  UserHasSufficientFundsToCoverCreateOrderArgs,
  UserHasSufficientFundsToCoverEditOrderArgs,
} from './types';
import { Mode } from '../OrderModal/types';

function isNewOrder(args: unknown): args is UserHasSufficientFundsToCoverCreateOrderArgs {
  return (args as UserHasSufficientFundsToCoverCreateOrderArgs).mode === Mode.Create;
}

export function userHasSufficientFundsToCoverOrder(
  args: UserHasSufficientFundsToCoverCreateOrderArgs | UserHasSufficientFundsToCoverEditOrderArgs,
): boolean {
  if (args.tradeType === 'offer') {
    return true;
  }
  if (isNewOrder(args)) {
    return args.tradingHeadroom >= args.costBreakdown.totalPricePlusFee;
  }

  const { tradingHeadroom, newCostBreakdown, originalCostBreakdown } = args;
  return tradingHeadroom + (originalCostBreakdown?.totalPricePlusFee ?? 0) >= newCostBreakdown.totalPricePlusFee;
}

export function calculateCost({ price, quantity, tradeType, showFees }: CalculateCostArgs): CostBreakdown | null {
  if (isUndefined(price) || isUndefined(quantity)) {
    return null;
  }

  const totalPrice = price * quantity;
  const transactionFee = showFees ? calculateFee(totalPrice, Fees.Standard) - totalPrice : 0;
  const vat = transactionFee * 0.2;
  const totalPricePlusFee = totalPrice + transactionFee + vat;

  return {
    totalPrice,
    transactionFee,
    vat,
    totalPricePlusFee: tradeType === 'bid' ? totalPricePlusFee : transactionFee + vat,
  };
}
