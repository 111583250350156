import { graphql } from '../../__generated__';

export const MULTIPLE_ASSETS_WITH_HOLDINGS = graphql(`
  query MultipleAssetsWithHoldings($assetIds: [Int!]!) {
    productAssets(assetIds: $assetIds) {
      id
      marketValue
      releasePrice
      calculatedMarketData {
        liquidityScore
        performance {
          percentageDifference
        }
        combinedScore
      }
      spread {
        highestBid {
          userId
          price
        }
        lowestOffer {
          userId
          price
        }
      }
      vintage {
        id
        drinkingWindow {
          advice
        }
        vintage
      }
      tradingInfo {
        lastTraded
        tradesMTD
        tradesYTD
      }
    }
  }
`);
