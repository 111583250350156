import makeStyles from '@mui/styles/makeStyles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import { Unit } from './types';
import { formatter } from '../../utils/currencyFormatter';

const useStyles = makeStyles((theme) => ({
  itemFontStyle: {
    fontSize: '14px',
    fontWeight: 700,
  },
  unitBreakDownContainer: {
    overflowY: 'auto',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '4px',
    background: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '200px',
    padding: 16,
  },
  unitBreakDownTitle: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    background: 'inherit',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
  },
  breakdownItemsList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    gap: theme.spacing(2),
  },
  unitBreakDownCollapse: {
    marginTop: 8,
    padding: 10,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '4px',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

interface UnitBreakDownProps {
  unitBreakDownItems: Array<Unit>;
}
export default function UnitBreakDownContent({ unitBreakDownItems }: UnitBreakDownProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  function handleExpanded() {
    setExpanded(!expanded);
  }

  return (
    <div className={classes.unitBreakDownContainer} data-testid="unit-breakdown">
      <button className={classes.unitBreakDownTitle} onClick={handleExpanded} type="button">
        <Typography customVariant="subtitle2">{t('product:detailsModal.buyNow.unitBreakDown')}</Typography>
        <div className={classes.right}>{expanded ? <ExpandLess /> : <ExpandMore />}</div>
      </button>
      {expanded && (
        <Collapse in={expanded}>
          <ul className={classes.breakdownItemsList}>
            {unitBreakDownItems.map((item, index) => (
              <li key={index.toString()} className={classes.unitBreakDownCollapse}>
                <Typography className={classes.itemFontStyle}>
                  {item.wineName} {item.vintage}
                </Typography>
                <Typography className={classes.itemFontStyle}>{formatter.format(item.price)}</Typography>
              </li>
            ))}
          </ul>
          <div className={classes.marginBottom} />
        </Collapse>
      )}
    </div>
  );
}
