import { CardCategories } from './types';
import isUndefined from '../../utils/isUndefined';
import { PaymentGetCardDetailsQuery } from '../../__generated__/graphql';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

export function selectPaymentCards(cardDetails: PaymentGetCardDetailsQuery | undefined): CardCategories | null {
  if (isUndefined(cardDetails) || isNullOrUndefined(cardDetails?.paymentGetCardDetails)) {
    return null;
  }

  const result: CardCategories = {
    defaultCard: null,
    savedCards: [],
  };

  cardDetails.paymentGetCardDetails.forEach((item) => {
    if (item.defaultMitFlag) {
      result.defaultCard = item;
    } else {
      result.savedCards.push(item);
    }
  });

  return result;
}
