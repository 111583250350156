/* eslint-disable react/destructuring-assignment */
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import WineCard from '..';
import DotGauge from '../../DotGauge';
import GridItem from '../../SearchResults/GridItem';
import { WineVintage } from '../../SearchResults/types';
import { Actions } from '../../Table/types';
import DrinkingWindow from '../DrinkingWindow';
import LastTrade from '../LastTrade';

interface SearchWineVintageCardProps {
  wineVintage: WineVintage;
  className?: string;
  actions?: Actions;
}

export default function SearchWineVintageCard({
  actions,
  className = '',
  wineVintage,
}: SearchWineVintageCardProps): JSX.Element {
  const { t } = useTranslation();
  const { clickableTradeTiles13816, showPerformaceDataInSearchResults } = useFlags();
  const {
    appellation,
    drinkingWindow,
    highestBid,
    lastTradeDate,
    lastTradeValue,
    liquidityScore,
    lowestOffer,
    defaultAssetUnitCount,
    defaultAssetUnitSize,
    marketValue,
    name,
    performance,
    region,
    score,
    spread,
    vintage,
    defaultAssetId,
    classification,
    producer,
    wineType,
    imageFileName,
  } = wineVintage;
  return (
    <WineCard
      className={className}
      cardId={defaultAssetId}
      assetId={Number(defaultAssetId)}
      imageUrl={imageFileName}
      wineName={name}
      marketValue={marketValue}
      performancePercentageDelta={showPerformaceDataInSearchResults ? performance.percentageDelta : null}
      region={region}
      score={score}
      vintage={vintage}
      actions={actions}
      appellation={appellation}
      lowestOffer={lowestOffer}
      highestBid={highestBid}
      unitCount={defaultAssetUnitCount}
      unitSize={defaultAssetUnitSize}
      spread={spread}
      tradeTileVariant={clickableTradeTiles13816 ? 'basic' : 'unit-size'}
      marketValueTooltipText={t('market:marketValueCalculation12x75cl')}
      ExpandedContent={
        <>
          <GridItem
            label={t('search:labels.lastTrade')}
            labelVariant="caption"
            value={<LastTrade lastTradeDate={lastTradeDate} lastTradeValue={lastTradeValue} />}
          />
          <GridItem
            label={t('search:labels.drinkingWindow')}
            labelVariant="caption"
            value={<DrinkingWindow adviceType={drinkingWindow.advice} text={drinkingWindow.text} />}
          />
          <GridItem
            label={t('search:labels.liquidityScore')}
            labelVariant="caption"
            value={!isNull(liquidityScore) ? <DotGauge dotCount={5} filledDots={liquidityScore} /> : '-'}
          />
          <GridItem label={t('search:filters.classification')} labelVariant="caption" value={classification ?? '-'} />
          <GridItem label={t('search:filters.producer')} labelVariant="caption" value={producer ?? '-'} />
          <GridItem label={t('search:labels.wineType')} labelVariant="caption" value={wineType ?? '-'} />
        </>
      }
    />
  );
}
