/**
 * Formats the date strings we get from the backend (YYYY-MM-DD or ISO date) and displays them in
 * the format which is in the designs (DD/MM/YYYY).
 *
 * @param dateString The date you want formatted, in the form YYYY-MM-DD
 */
export function humanReadableDate(dateString: string): string {
  // Handle two different datetime formats, will cleanup and leave a single
  // one in the future. The reason why we have two right now is because one is
  // originating from services and another one from graphql
  // the one from graphql will be left as soon as we migrate fully
  // e.g. 2022-01-01T00:00:00Z
  // https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/7923
  if (
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}/.test(dateString) ||
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(dateString)
  ) {
    return dateString
      .substring(0, 10)
      .split('-')
      .reverse()
      .reduce((prev, curr) => `${prev}/${curr}`);
  }

  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    throw new Error('Pass a date matching the following format: YYYY-MM-DD');
  }

  return dateString
    .split('-')
    .reverse()
    .reduce((prev, curr) => `${prev}/${curr}`);
}
