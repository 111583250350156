import { graphql } from '../../__generated__';

export const GET_WINE_SCORES = graphql(`
  query GetWineScores($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      vintage {
        id
        wineScores {
          sourceName
          score
          scoreDate
          tastingNotes
        }
      }
    }
  }
`);
