import React from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { TableCell } from '@mui/material';

export interface ExpanderCellProps {
  expanded: boolean;
  onClick: () => void;
}

export default function ExpanderCell({ expanded, onClick }: ExpanderCellProps) {
  return (
    <TableCell align="center" sx={{ padding: 0 }}>
      <IconButton aria-label="expand row" onClick={onClick} size="large">
        {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>
    </TableCell>
  );
}
