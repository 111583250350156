/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useLDClient, useFlags, ProviderConfig } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/material';
import useMediaQuery from './useMediaQuery';

interface DetermineWhetherToRenderMarketRouteArgs {
  desktopOnly: boolean;
  ldUser: ProviderConfig['user'];
  greaterThanMd: boolean;
}

function determineWhetherToRenderMarketRoute({
  desktopOnly,
  ldUser,
  greaterThanMd,
}: DetermineWhetherToRenderMarketRouteArgs) {
  if (desktopOnly && ldUser && !ldUser.anonymous) {
    return true;
  }

  if (!greaterThanMd) {
    return false;
  }

  return true;
}

export function useShouldRenderMarketRoute() {
  const theme = useTheme();
  const greaterThanMd = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md));
  const ldClient = useLDClient();
  const ldUser = ldClient?.getUser();
  const { desktopOnly } = useFlags();

  const [shouldRenderMarketRoute, setShouldRenderMarketRoute] = React.useState(
    determineWhetherToRenderMarketRoute({ desktopOnly, greaterThanMd, ldUser }),
  );

  React.useEffect(() => {
    const _shouldRenderMarketRoute = determineWhetherToRenderMarketRoute({ desktopOnly, greaterThanMd, ldUser });
    if (_shouldRenderMarketRoute !== shouldRenderMarketRoute) {
      setShouldRenderMarketRoute(_shouldRenderMarketRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldUser, desktopOnly, greaterThanMd]);

  return { shouldRenderMarketRoute };
}
