import makeStyles from '@mui/styles/makeStyles';

export const useSplashStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'auto',
    width: '100%',
    justifyContent: 'center',
    margin: '40px 0',

    [theme.breakpoints.up('sm')]: {
      gap: theme.spacing(13),
      minHeight: '100vh',
    },
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },

  appBar: {
    background: theme.palette.background.default,
    boxShadow: 'none',
  },

  backButton: {
    stroke: theme.palette.navigation.background.default.contrastText,
    color: theme.palette.navigation.background.default.contrastText,
    width: '40px',
    height: '40px',
  },

  header: {
    marginTop: '64px',
    width: '50%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',

    '& img': {
      width: '246px',

      [theme.breakpoints.up('sm')]: {
        width: 'fit-content',
        height: 'fit-content',
      },
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
