/* eslint-disable @typescript-eslint/naming-convention */
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AssetColours } from '../../hooks/useColourMap';
import { useQueryParameters } from '../../hooks/useQueryParameters';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';
import { Modal } from '../../types/Modal';
import Card, { EmptyAssetTileCard } from '../AssetTileCard';
import BuyNowModal from '../BuyNowModal';
import Carousel from '../Carousel';
import MarketComparisonLineChart from './ChartWrapper';
import OrderModal from '../OrderModal';
import { Mode } from '../OrderModal/types';

const useStyles = makeStyles((theme) => ({
  tileContainer: {
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    height: 200,
    alignItems: 'center',
    gap: theme.spacing(2.5),
  },
  card: {
    height: 200,
  },
  quarter: {
    width: '25%',
  },
  half: {
    width: '50%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  greyBackground: {
    background: theme.palette.grey[50],
  },
}));

interface ChartTabProps {
  assetIds: string[];
  carouselPagesCount: 2 | 4;
  visibleColumnIndex: number;
  columnCount: number;
  coloursByAssetId: AssetColours;
  onAddClicked: () => void;
  onRemoveClicked: (assetId: string) => void;
}

export default function ChartTab({
  assetIds,
  carouselPagesCount,
  visibleColumnIndex,
  coloursByAssetId,
  columnCount,
  onAddClicked,
  onRemoveClicked,
}: ChartTabProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const queryParams = useQueryParameters();
  const modal = queryParams.get('modal');
  const assetId = queryParams.get('assetId');
  const history = useHistory();
  const { showTopUpBuySuccessSummaryModal } = useCardPaymentCallback();

  const handleOpenBuyNowModalClicked = React.useCallback((_assetId: string) => {
    history.push(`${history.location.pathname}${history.location.search}&modal=${Modal.BuyNow}&assetId=${_assetId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenBidModalClicked = React.useCallback((_assetId: string) => {
    history.push(`${history.location.pathname}${history.location.search}&modal=${Modal.Bid}&assetId=${_assetId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenOfferModalClicked = React.useCallback((_assetId: string) => {
    history.push(`${history.location.pathname}${history.location.search}&modal=${Modal.Offer}&assetId=${_assetId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCloseModal() {
    queryParams.delete('modal');
    queryParams.delete('assetId');
    history.push({ pathname: history.location.pathname, search: queryParams.toString(), hash: history.location.hash });
  }

  const cardMap = React.useCallback(
    (page: number, column: number): JSX.Element => {
      const index = page === 0 ? column : column + columnCount * page;
      const _assetId = assetIds[index];
      return !isUndefined(_assetId) ? (
        <Card
          className={clsx({
            [classes.half]: carouselPagesCount === 4,
            [classes.quarter]: carouselPagesCount === 2,
            [classes.greyBackground]: column % 2 === 0,
            [classes.card]: true,
          })}
          cardsPerPage={carouselPagesCount === 2 ? 4 : 2}
          colour={coloursByAssetId[_assetId] ?? theme.palette.grey[200]}
          key={column}
          assetId={Number(_assetId)}
          onRemoveClicked={() => onRemoveClicked(_assetId)}
          onBuyNowClicked={() => handleOpenBuyNowModalClicked(_assetId)}
          onBidClicked={() => handleOpenBidModalClicked(_assetId)}
          onOfferClicked={() => handleOpenOfferModalClicked(_assetId)}
        />
      ) : (
        <EmptyAssetTileCard
          className={clsx({
            [classes.half]: carouselPagesCount === 4,
            [classes.quarter]: carouselPagesCount === 2,
            [classes.greyBackground]: column % 2 === 0,
            [classes.card]: true,
          })}
          onAddClicked={onAddClicked}
          borderColour={coloursByAssetId[_assetId] ?? theme.palette.grey[200]}
        />
      );
    },
    [
      columnCount,
      assetIds,
      classes.half,
      classes.quarter,
      classes.greyBackground,
      classes.card,
      carouselPagesCount,
      coloursByAssetId,
      theme.palette.grey,
      onAddClicked,
      onRemoveClicked,
      handleOpenBuyNowModalClicked,
      handleOpenBidModalClicked,
      handleOpenOfferModalClicked,
    ],
  );

  const Cards = React.useMemo(() => {
    if (carouselPagesCount === 2) {
      return Array(2)
        .fill(0)
        .map((_, page) => (
          <>
            {Array(4)
              .fill(0)
              .map((__, column) => cardMap(page, column))}
          </>
        ));
    }

    return Array(4)
      .fill(0)
      .map((_, page) => (
        <>
          {Array(2)
            .fill(0)
            .map((__, column) => cardMap(page, column))}
        </>
      ));
  }, [cardMap, carouselPagesCount]);

  return (
    <div className={classes.container}>
      <div className={classes.tileContainer}>
        <Carousel columns={Cards} visibleColumnIndex={visibleColumnIndex} percentage={100} />
      </div>

      <MarketComparisonLineChart
        assetIds={assetIds.map((id) => Number(id))}
        colorByAssetId={coloursByAssetId}
        onAddClicked={onAddClicked}
      />
      {modal === Modal.Bid && assetId && (
        <OrderModal
          assetId={Number(assetId)}
          open={modal === Modal.Bid}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="bid"
        />
      )}
      {modal === Modal.Offer && assetId && (
        <OrderModal
          assetId={Number(assetId)}
          open={modal === Modal.Offer}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="offer"
        />
      )}
      {modal === Modal.BuyNow && assetId && (
        <BuyNowModal assetId={Number(assetId)} open={modal === Modal.BuyNow} onClose={handleCloseModal} />
      )}
      {assetId && showTopUpBuySuccessSummaryModal && (
        <BuyNowModal assetId={Number(assetId)} open onClose={handleCloseModal} />
      )}
    </div>
  );
}
