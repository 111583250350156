import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { getDaysDifferenceBetweenDates } from '../../utils/getDaysDifferenceBetweenDates';
import Typography from '../Typography';
import { formatDateTime } from './formatDateTime';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}));

interface ExpiryDateProps {
  expiryDate: string | null | undefined;
}

export default function ExpiryDate({ expiryDate }: ExpiryDateProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="subtitle2">
        {!isNullOrUndefined(expiryDate) ? formatDateTime(expiryDate) : t('common:unavailable')}{' '}
      </Typography>
      {!isNullOrUndefined(expiryDate) && (
        <Typography variant="body2">
          ({getDaysDifferenceBetweenDates(new Date(expiryDate), new Date())} {t('trade:daysToGo')})
        </Typography>
      )}
    </div>
  );
}
