import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1),
    flex: 3,
  },

  image: {
    flex: 1,
    height: '121px',

    [theme.breakpoints.up('xs')]: {
      height: '230px',
    },

    [theme.breakpoints.up('sm')]: {
      height: '300px',
    },

    [theme.breakpoints.up('lg')]: {
      height: '344px',
    },
  },

  title: {
    width: '75%',
  },

  description: {
    width: '90%',
  },

  select: {
    width: '180px',
    height: '50px',
  },

  marketValueTitle: {
    width: '100px',
  },

  wrap: {
    flexWrap: 'wrap',
  },
}));

export default function ProductSummarySkeleton(): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.container} aria-label="product-summary-skeleton">
      <Skeleton variant="rectangular" className={classes.image} />
      <div className={classes.details}>
        <Typography variant="h2" className={classes.title}>
          <Skeleton />
        </Typography>
        <Hidden mdDown>
          <Typography variant="body1">
            <Skeleton width="90%" />
            <Skeleton width="35%" />
          </Typography>
        </Hidden>
        <Skeleton className={classes.select} />
        <Typography variant="subtitle2">
          <Skeleton width="20%" />
        </Typography>
        <Typography customVariant="numberLarge">
          <Skeleton width="120px" />
        </Typography>
        <Typography variant="body1">
          <Skeleton width="40px" />
        </Typography>
        <Typography variant="subtitle1">
          <Skeleton width="80px" />
        </Typography>
        <Typography variant="body1">
          <Skeleton width="40px" />
        </Typography>
      </div>
    </Box>
  );
}
