import { graphql } from '../../__generated__';

export const CREATE_CARD_PAYMENT = graphql(`
  mutation CreateCardPaymentMutation($args: CardPaymentInput!) {
    createCardPayment(cardPayment: $args) {
      id
      status
      errorMessage
      redirectUrl
    }
  }
`);
