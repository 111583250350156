import React from 'react';
import { styled } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { CheckboxCellProps } from './CheckboxCell';
import { ExpanderCellProps } from './ExpanderCell';

const CheckboxExpanderCellInner = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  alignItems: 'center',
}));

type CheckboxExpanderCellProps = CheckboxCellProps & ExpanderCellProps;

/**
 * Exposes a single table cell which contains both a checkbox and an expander.
 */
export default function CheckboxExpanderCell({
  checked,
  onCheckedChanged,
  disabled,
  onClick,
  expanded,
}: CheckboxExpanderCellProps) {
  return (
    <TableCell align="center">
      <CheckboxExpanderCellInner>
        <Checkbox checked={checked} onChange={onCheckedChanged} disabled={disabled} />
        <IconButton aria-label="expand row" onClick={onClick} size="large">
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </CheckboxExpanderCellInner>
    </TableCell>
  );
}
