import React from 'react';
import TableCell from '@mui/material/TableCell';
import { SxProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { SortDirection } from '../types';
import { ReactComponent as ArrowUpV2 } from '../../../assets/icons/arrow-up-v2.svg';
import { ReactComponent as ArrowDownV2 } from '../../../assets/icons/arrow-down-v2.svg';
import { ReactComponent as TableFilterIcon } from '../../../assets/icons/table-filter-icon.svg';

const useStyles = makeStyles<Theme, { clickable: boolean }>((theme) => ({
  uppercase: {
    textTransform: 'uppercase',
  },

  root: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    background: theme.palette.grey[50],
  },

  active: {
    fontWeight: 500,
  },

  icon: {
    color: theme.palette.textLabel,
    cursor: 'pointer',
  },

  inactiveIcon: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },

  activeIcon: {
    opacity: 1,
  },

  inner: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

interface HeaderProps<K> {
  title?: string;
  id?: K;
  active?: boolean;
  sortDirection?: SortDirection;
  onClick?: (key: K, direction: SortDirection) => void;
  extraElement?: JSX.Element;
  className?: string;
  sx?: SxProps;
  sortIsDisabled?: boolean;
}

/**
 * @deprecated use material-ui and src/components/TableElements
 */
export default function Header<K>({
  onClick,
  id,
  active,
  sortDirection,
  title = '',
  extraElement,
  className,
  sx,
  sortIsDisabled,
}: HeaderProps<K>): JSX.Element {
  const clickable = React.useMemo(() => !isUndefined(onClick), [onClick]);
  const classes = useStyles({ clickable });

  const handleClick = React.useCallback(() => {
    if (isUndefined(onClick) || isUndefined(id)) {
      return;
    }

    // eslint-disable-next-line no-nested-ternary
    onClick(id, isUndefined(sortDirection) ? 'desc' : sortDirection === 'asc' ? 'desc' : 'asc');
  }, [id, onClick, sortDirection]);

  const Arrow = React.useMemo(() => {
    if (sortIsDisabled) {
      return null;
    }
    if (!active) {
      return (
        <TableFilterIcon
          fill="#8A809A"
          className={`${classes.icon} ${active ? classes.active : ''} tw-w-3 tw-h-3 tw-min-w-[14px] tw-min-h-[14px]`}
        />
      );
    }

    return sortDirection === 'asc' ? (
      <ArrowUpV2
        fill="#8A809A"
        className={`${classes.icon} ${active ? classes.active : ''} tw-w-3 tw-h-3 tw-min-w-[14px] tw-min-h-[14px]`}
      />
    ) : (
      <ArrowDownV2
        fill="#8A809A"
        className={`${classes.icon} ${active ? classes.active : ''} tw-w-3 tw-h-3 tw-min-w-[14px] tw-min-h-[14px]`}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, sortDirection, handleClick]);

  return (
    <TableCell
      sx={sx}
      classes={className ? {} : { root: classes.root }}
      variant="head"
      className={className ?? classes.uppercase}
    >
      <button
        type="button"
        className={`tw-bg-transparent tw-border-none tw-cursor-pointer ${classes.inner}`}
        onClick={() => {
          if (clickable && !sortIsDisabled) handleClick();
        }}
      >
        <span
          className={`tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-[#8A809A] ${active ? classes.active : ''}`}
        >
          {extraElement}
          {title}
        </span>
        {clickable && Arrow}
      </button>
    </TableCell>
  );
}
