import { ApolloError, useQuery } from '@apollo/client';
import React from 'react';
import { OrderDirection } from '../components/OrderBookModal/types';
import { OrderStatus } from '../types/OrderStatus';
import { CAN_CREATE_OFFER_QUERY } from './useCanCreateOffer';

type UseMaxCasesAvailableForOfferReturnType = {
  maximumCasesAvailableForOffer: number;
  loading: boolean;
  error?: ApolloError;
};

interface UseMaxCasesAvailableForOfferProps {
  assetId: number;

  /**
   * Provide this if you want to get the max available cases
   * excluding the given order (e.g. when editing an order)
   */
  excludeOrders?: string[];
  skip?: boolean;
}

export function useMaxCasesAvailableForOffer({
  assetId,
  excludeOrders,
  skip = false,
}: UseMaxCasesAvailableForOfferProps): UseMaxCasesAvailableForOfferReturnType {
  const [maximumCases, setMaximumCases] = React.useState(0);
  const { data, error, loading } = useQuery(CAN_CREATE_OFFER_QUERY, {
    variables: { assetId },
    skip,
  });

  function filterExlcudedOrders(order: { id: string }): boolean {
    if (!excludeOrders || !excludeOrders.length) {
      return true;
    }

    if (excludeOrders.find((orderId) => orderId === order.id)) {
      return false;
    }

    return Boolean(!excludeOrders.find((orderId) => orderId === order.id));
  }

  React.useEffect(() => {
    if (data) {
      const assetVintage = data?.productAsset?.vintage.vintage || 1000;
      const totalCasesOnOffer = data.omsGetMyOrders
        .filter(
          (order) =>
            order.direction === OrderDirection.Offers &&
            order.asset.id === assetId &&
            order.status === OrderStatus.Live,
        )
        // If orderIds are provided, then we need to include the quantity from those orders when calculating the maximum possible cases they can have in the new offer.
        .filter(filterExlcudedOrders)
        .map((offer) => offer.outstandingQuantity)
        .reduce((prev, curr) => prev + curr, 0);

      setMaximumCases(
        Math.abs(
          totalCasesOnOffer -
            data.productAssetInstances.filter(
              (a) => !a.transferRequested && (assetVintage > 2021 || a.linkedToPhysical),
            ).length,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, excludeOrders]);

  return { maximumCasesAvailableForOffer: maximumCases, loading, error };
}
