import { graphql } from '../../__generated__';

export const GET_USER_NOTIFICATIONS = graphql(`
  query UserNotifications($from: Int!, $pageSize: Int!) {
    userNotifications(from: $from, pageSize: $pageSize) {
      total
      pageSize
      from
      totalPages
      results {
        id
        summary
        category
        description
        isRead
        createdDateTime
        updatedDateTime
        assetId
      }
      unreadCount
    }
  }
`);
