import { ApolloError, useQuery } from '@apollo/client';
import { AVAILABLE_BALANCE } from '../graphql/queries/availableBalance';

interface UseAvailableBalanceHookResponse {
  availableBalance: number;
  loading: boolean;
  error: ApolloError | undefined;
}

interface UseAvailableBalanceProps {
  skip?: boolean;
}

export const useAvailableBalance = (props?: UseAvailableBalanceProps): UseAvailableBalanceHookResponse => {
  const { data, loading, error } = useQuery(AVAILABLE_BALANCE, {
    pollInterval: 5000,
    skip: props?.skip,
  });
  return { availableBalance: data?.availableBalance ?? 0, loading, error };
};
