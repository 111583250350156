/* eslint-disable react/no-this-in-sfc */
import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { monthsAmountByVariant } from '../../constants/dateRangeVariants';
import { MONTHWISE_FLOW_QUERY } from '../../graphql/queries/monthwiseFlow';
import { DateRangeVariant } from '../../types';
import { HighchartsChart } from '../../types/Highcharts';
import { logError } from '../../utils/logger';
import { selectMonthwiseChartData } from './selectors';
import Tooltip from './Tooltip';

interface Props {
  dateRangeVariant: DateRangeVariant;
}

export default function MonthwiseFlowBarChart({ dateRangeVariant }: Props): JSX.Element {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Highcharts.Options | null>(null);
  const theme = useTheme();
  const chartRef = React.createRef<{ chart: HighchartsChart; container: React.RefObject<HTMLDivElement> }>();
  const { data, loading, error } = useQuery(MONTHWISE_FLOW_QUERY, {
    variables: {
      monthRange: monthsAmountByVariant[dateRangeVariant] || 1,
    },
  });
  const chartData = useMemo(() => selectMonthwiseChartData(data?.financeGetMonthlyWiseFlowByMonthRange), [data]);

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (loading) {
      chart?.hideNoData();
      chart?.showLoading();
    } else {
      chart?.hideLoading();
    }
  }, [loading, chartRef]);

  if (error) {
    logError({
      originalError: error,
      error: new Error('Failed to load chart data'),
      filename: 'MonthwiseFlowBarChart',
    });
  }

  const getCandleWidth = (month: string): { pointWidth: number } => {
    switch (month) {
      case '1M':
        return {
          pointWidth: 50,
        };
      case '3M':
        return {
          pointWidth: 40,
        };
      case '6M':
        return {
          pointWidth: 20,
        };
      case '1Y':
        return {
          pointWidth: 12,
        };
    }
    return {
      pointWidth: 20,
    };
  };
  const { pointWidth } = getCandleWidth(dateRangeVariant);
  useEffect(() => {
    setOptions({
      chart: {
        height: 240,
        type: 'column',
      },
      series: [
        {
          type: 'column',
          name: t('wallet:monthWiseFlow.inward'),
          color: theme.palette.chart1.main,
          data: chartData.inward,
        },
        {
          type: 'column',
          name: t('wallet:monthWiseFlow.outward'),
          color: theme.palette.chart4.main,
          data: chartData.outward,
        },
      ],
      xAxis: {
        type: 'category',
        tickWidth: 0,
        labels: {
          style: {
            color: theme.palette.textLabel,
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        labels: {
          style: {
            color: theme.palette.textLabel,
          },
        },
        gridLineDashStyle: 'Dash',
      },
      plotOptions: {
        column: {
          borderWidth: 0,
          pointRange: -0.01,
          pointWidth,
        },
      },
      lang: {
        noData: t('product:noData'),
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: theme.palette.textPrimary,
        },
      },
      tooltip: {
        useHTML: true,
        borderColor: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
        style: {
          textAlign: 'center',
        },
        formatter() {
          return renderToString(<Tooltip point={this.point} />);
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      legend: {
        itemStyle: {
          color: theme.palette.textLabel,
        },
        align: 'left',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, theme]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
