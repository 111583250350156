import { MarketComparisonProductDisplay } from '../components/MarketComparisonLineChart/types';
import { Asset } from '../__generated__/graphql';

export default function selectChartSeriesNamesWithId(
  data: (Asset | null)[] | null | undefined,
): Array<MarketComparisonProductDisplay> {
  if (!data || !data.length) {
    return [];
  }
  return data.map((d) => {
    return {
      assetId: d?.id,
      displayName: `${d?.vintage.wine.name} ${d?.vintage.vintage}`,
    } as MarketComparisonProductDisplay;
  });
}
