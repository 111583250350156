/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { OrderData } from '../components/OrderForm/types';
import { selectCreateOrderVariables } from '../components/OrderModal/selectors';
import { Mode, TradeVariant } from '../components/OrderModal/types';
import { OrderStatus } from '../types/OrderStatus';
import { logError } from '../utils/logger';
import { useAddWatchListItem } from './useAddWatchListItem';
import { useCancelOrder } from './useCancelOrder';
import { useCreateOrder } from './useCreateOrder';
import { useMyOrders } from './useMyOrders';
import { BookedOrder } from '../__generated__/graphql';

export type UseProcessOrderPropsBase = {
  onSuccess: () => void;
  onError: () => void;
  assetId: number;
  orderData: OrderData | null;
  tradeType: TradeVariant;
  shouldAddToWatchlist?: boolean;
};

export type UseProcessCreateOrderProps = UseProcessOrderPropsBase & {
  mode: Mode.Create;
  onCreateFailure: () => void;
};

export type UseProcessEditOrderProps = UseProcessOrderPropsBase & {
  mode: Mode.Edit;

  /**
   * The order id used by the system to track the order internally.
   */
  systemOrderId: string;
  /**
   * The client facing ID returned by `bid` or `offer` mutations.
   */
  existingOrderClientId: string;
  onEditFailure: () => void;
  onCancelFailure: () => void;
};

export type UseProcessCancelOrderProps = UseProcessOrderPropsBase & {
  mode: Mode.Cancel;
  systemOrderId: string;
  existingOrderClientId: string;
  onCancelFailure: () => void;
};

const POLL_INTERVAL_MS = 5000;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useProcessOrder(
  props: UseProcessCreateOrderProps | UseProcessEditOrderProps | UseProcessCancelOrderProps,
) {
  const { mode, onSuccess, onError, orderData, tradeType, assetId, shouldAddToWatchlist = false } = props;
  const { expirationDate, price: unitPrice, quantity } = orderData || {};
  const [newOrderClientId, setNewOrderClientId] = React.useState<null | string>(
    mode === Mode.Create
      ? null
      : (props as UseProcessEditOrderProps | UseProcessCancelOrderProps).existingOrderClientId,
  );
  const [newOrder, setNewOrder] = React.useState<BookedOrder | null>(null);
  const [orderCancelled, setOrderCancelled] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const { data: orders, error: failedToLoadOrders, startPolling, stopPolling } = useMyOrders();
  const { createOrder } = useCreateOrder();
  const { cancelOrder } = useCancelOrder();
  const { addToWatchlist } = useAddWatchListItem();

  async function _createOrder() {
    if (unitPrice && quantity && expirationDate) {
      const createOrderVariables = selectCreateOrderVariables({ price: unitPrice, quantity, expirationDate }, assetId);

      const { clientOrderId: _clientOrderId, errors } = await createOrder(createOrderVariables, tradeType);
      if (shouldAddToWatchlist) {
        const { errors: addToWatchlistErrors } = await addToWatchlist({ assetId });
        if (addToWatchlistErrors) {
          logError({
            error: new Error('Error encountered when addding asset to watchlist'),
            originalError: addToWatchlistErrors[0],
            filename: 'useProcessOrder',
            additionalInfo: {
              errors: JSON.stringify(addToWatchlistErrors),
              assetId,
            },
          });
        }
      }
      setNewOrderClientId(_clientOrderId ?? null);
      if (errors) {
        logError({
          error: new Error('Error encountered when creating order'),
          originalError: errors[0],
          filename: 'useProcessOrder',
          tags: { userFlow: 'trade' },
          additionalInfo: {
            errors: JSON.stringify(errors),
            assetId,
            mode,
            tradeType,
          },
        });
        setProcessing(false);
        stopPolling();
        if (mode === Mode.Edit) {
          (props as UseProcessEditOrderProps).onEditFailure();
        } else {
          (props as UseProcessCreateOrderProps).onCreateFailure();
        }
      }
    }
  }

  async function processOrder() {
    if (processing) {
      return;
    }

    setProcessing(true);
    startPolling(POLL_INTERVAL_MS);
    if (mode === Mode.Create) {
      await _createOrder();
    }

    if (mode === Mode.Edit) {
      const { errors: cancelErrors } = await cancelOrder({
        orderId: (props as UseProcessEditOrderProps).systemOrderId,
      });
      startPolling(POLL_INTERVAL_MS);
      if (cancelErrors) {
        logError({
          error: new Error('Error encountered when trying to cancel existing order'),
          originalError: cancelErrors[0],
          filename: 'useProcessOrder',
          additionalInfo: {
            assetId,
            mode,
            tradeType,
            errors: JSON.stringify(cancelErrors),
            orderId: (props as UseProcessEditOrderProps).systemOrderId,
          },
          tags: {
            userFlow: 'trade',
          },
        });
        setProcessing(false);
        stopPolling();
        (props as UseProcessEditOrderProps).onCancelFailure();
      }
    }

    if (mode === Mode.Cancel) {
      const { errors: cancelErrors } = await cancelOrder({
        orderId: (props as UseProcessCancelOrderProps).systemOrderId,
      });
      startPolling(POLL_INTERVAL_MS);
      if (cancelErrors) {
        logError({
          error: new Error('Error encountered when trying to cancel an order'),
          originalError: cancelErrors[0],
          filename: 'useProcessOrder',
          additionalInfo: {
            orderId: (props as UseProcessCancelOrderProps).systemOrderId,
            assetId,
            mode,
            tradeType,
            errors: JSON.stringify(cancelErrors),
          },
          tags: {
            userFlow: 'trade',
          },
        });
        setProcessing(false);
        stopPolling();
        (props as UseProcessCancelOrderProps).onCancelFailure();
      }
    }
  }

  React.useEffect(() => {
    if (failedToLoadOrders) {
      logError({
        error: new Error('Error when loading orders'),
        originalError: failedToLoadOrders,
        filename: 'useProcessOrder',
        additionalInfo: {
          assetId,
        },
        tags: {
          userFlow: 'trade',
        },
      });
      setProcessing(false);
      onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedToLoadOrders]);

  React.useEffect(() => {
    if (orders) {
      if (mode === Mode.Create) {
        if (!newOrder) {
          const order = orders.omsGetMyOrders.find(
            (rawOrder) => rawOrder.clientOrderId.toLowerCase() === newOrderClientId?.toLowerCase(),
          ) as BookedOrder;
          if (order && (order.status === OrderStatus.Live || order.status === OrderStatus.Fulfilled)) {
            setNewOrder(order);
          }
        }
      }

      if (mode === Mode.Edit) {
        if (orderCancelled && !newOrder) {
          const order = orders.omsGetMyOrders.find(
            (rawOrder) => rawOrder.clientOrderId.toLowerCase() === newOrderClientId?.toLowerCase(),
          ) as BookedOrder;
          if (order && (order.status === OrderStatus.Live || order.status === OrderStatus.Fulfilled)) {
            setNewOrder(order);
          }
        }

        if (!orderCancelled) {
          const order = orders.omsGetMyOrders.find(
            (rawOrder) =>
              rawOrder.clientOrderId.toLowerCase() ===
              (props as UseProcessEditOrderProps).existingOrderClientId.toLowerCase(),
          );
          if (order && order.status === OrderStatus.Cancelled) {
            setOrderCancelled(true);
          }
        }
      }

      if (mode === Mode.Cancel) {
        const order = orders.omsGetMyOrders.find(
          (rawOrder) =>
            rawOrder.clientOrderId.toLowerCase() ===
            (props as UseProcessCancelOrderProps).existingOrderClientId.toLowerCase(),
        );
        if (order && order.status === OrderStatus.Cancelled) {
          setProcessing(false);
          stopPolling();
          onSuccess();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  React.useEffect(() => {
    if (orderCancelled) {
      _createOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCancelled]);

  React.useEffect(() => {
    if (newOrder) {
      setProcessing(false);
      stopPolling();
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOrder]);

  return {
    processOrder,
  };
}
