import { graphql } from '../../__generated__';

export const REFRESH_ACCESS_TOKEN = graphql(`
  mutation RefreshAccessToken($input: AuthRefreshAccessTokenRequestInput!) {
    authRefreshAccessToken(refreshAccessTokenRequestInput: $input) {
      accessToken
      refreshToken
      userToken
    }
  }
`);
