/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import WineCard from '..';
import { MyOrder } from '../../../graphql/selectors/selectMyOrders';
import ExpiryDate from '../../MyOrders/ExpiryDate';
import { formatDateTime } from '../../MyOrders/formatDateTime';
import { OrderDirection } from '../../OrderBookModal/types';
import GridItem from '../../SearchResults/GridItem';
import { AssetStatus } from '../../../types/assetStatus';
import OrderStatusIndicator from '../../OrderStatusIndicator';

type OrderCardProps = {
  className?: string;
  order: MyOrder;
  onEditClicked: (rowId: string) => void;
  onDeleteClicked: (rowId: string) => void;
  viewType: OrderDirection;
};

const useStyles = makeStyles(() => ({
  label: {
    textTransform: 'uppercase',
  },
  statusIndicator: {
    marginTop: 1,
  },
}));

export default function OrderCard({
  className = '',
  order,
  viewType,
  onEditClicked,
  onDeleteClicked,
}: OrderCardProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    appellation,
    vintage,
    score,
    region,
    wineName,
    unitSize,
    assetId,
    unitCount,
    status,
    expiryDate,
    placedDate,
    imageFilename,
  } = order;

  const ExpandedContent = React.useMemo(
    () => (
      <>
        <GridItem
          label={viewType === OrderDirection.Bids ? t('trade:myTable.bidStatus') : t('trade:myTable.offerStatus')}
          value={<OrderStatusIndicator status={status} className={classes.statusIndicator} />}
        />
        <GridItem label={t('trade:myTable.expiryDate')} value={<ExpiryDate expiryDate={expiryDate} />} />
        {/* Surely placedDate should never come back as null right?! */}
        <GridItem label={t('trade:myTable.date')} value={formatDateTime(placedDate)!} />
      </>
    ),
    [expiryDate, placedDate, status, t, viewType, classes.statusIndicator],
  );

  const Card = React.useMemo(
    () =>
      viewType === OrderDirection.Bids ? (
        <WineCard
          className={className}
          actions={{
            options: {
              disabled:
                order.status === AssetStatus.Expired ||
                order.status === AssetStatus.Cancelled ||
                order.status === AssetStatus.Fulfilled,
              actions: [
                { callback: onEditClicked, text: t('product:detailsModal.editBidTitle') },
                { callback: onDeleteClicked, text: t('trade:cancelBid') },
              ],
            },
          }}
          cardId={order.orderId}
          assetId={assetId}
          highestBid={order.highestBid}
          myBid={order.price}
          score={score}
          region={region}
          vintage={vintage}
          wineName={wineName}
          appellation={appellation}
          unitCount={unitCount}
          unitSize={unitSize}
          imageUrl={imageFilename}
          quantity={order.quantity}
          outstandingQuantity={order.outstandingQuantity}
          ExpandedContent={ExpandedContent}
        />
      ) : (
        <WineCard
          className={className}
          actions={{
            options: {
              disabled:
                order.status === AssetStatus.Expired ||
                order.status === AssetStatus.Cancelled ||
                order.status === AssetStatus.Fulfilled,
              actions: [
                { callback: onEditClicked, text: t('product:detailsModal.editOfferTitle') },
                { callback: onDeleteClicked, text: t('trade:cancelOffer') },
              ],
            },
          }}
          cardId={order.orderId}
          assetId={assetId}
          lowestOffer={order.lowestOffer}
          myOffer={order.price}
          score={score}
          region={region}
          vintage={vintage}
          wineName={wineName}
          appellation={appellation}
          unitCount={unitCount}
          unitSize={unitSize}
          imageUrl={imageFilename}
          quantity={order.quantity}
          outstandingQuantity={order.outstandingQuantity}
          ExpandedContent={ExpandedContent}
        />
      ),

    [
      viewType,
      className,
      onEditClicked,
      t,
      onDeleteClicked,
      order.orderId,
      order.highestBid,
      order.price,
      order.status,
      order.lowestOffer,
      order.quantity,
      order.outstandingQuantity,
      assetId,
      score,
      region,
      vintage,
      wineName,
      appellation,
      unitCount,
      unitSize,
      imageFilename,
      ExpandedContent,
    ],
  );

  return Card;
}
