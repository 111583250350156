import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '50%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    height: '46px',
    alignItems: 'center',
  },
}));

export default function BulletPointSkeleton(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.container} aria-label="bullet-point-skeleton">
      <Skeleton variant="circular" width="28px" height="28px" />
      <Typography>
        <Skeleton width="100px" height="38px" />
      </Typography>
    </div>
  );
}
