import React from 'react';
import { Skeleton, Stack } from '@mui/material';
import { TFunction } from 'i18next';
import { deltaPercentage } from '../../utils/deltaPercentage';
import Typography from '../Typography';
import { HoldingPerformanceResponse } from '../../__generated__/graphql';
import { formatterWholeNumber } from '../../utils/currencyFormatter';

interface Props {
  performance: HoldingPerformanceResponse | null | undefined;
  dateRange: string;
  loading: boolean;
  translation: TFunction;
  greaterThanSm?: boolean;
}

export default function MarketPerformanceDisplay({
  greaterThanSm,
  performance,
  dateRange,
  loading,
  translation,
}: Props): JSX.Element | null {
  if (loading) {
    return (
      <Stack direction="column" alignItems="baseline" spacing={2} ml={10}>
        <Typography customVariant="h3">
          <Skeleton width={80} />
        </Typography>
        <Typography customVariant="subtitle2">
          <Skeleton width={60} />
        </Typography>
      </Stack>
    );
  }
  const { zellarColour, deltaSymbol } = deltaPercentage(performance?.valueDifference);
  return (
    <Stack direction="column" alignItems={greaterThanSm ? 'baseline' : 'center'} spacing={2} ml={10}>
      <Typography className="tw-uppercase" customVariant="label2" zellarColour="textLabel">
        {translation('search:filters.performance')}
      </Typography>

      {performance?.valueDifference && (
        <Typography customVariant="h3" zellarColour="textPrimary">
          {deltaSymbol}
          {formatterWholeNumber.format(Number(performance?.valueDifference?.toFixed(2)))}
        </Typography>
      )}

      {performance?.percentageDifference && (
        <Typography customVariant="subtitle2" zellarColour={zellarColour}>
          {deltaSymbol}
          {`${performance.percentageDifference.toFixed(2)}% (${dateRange})`}
        </Typography>
      )}
    </Stack>
  );
}
