import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { selectColumnTransformStyle } from './selectors';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  column: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'absolute',
    transitionDuration: '0.2s',
  },
}));

interface Props {
  columns: JSX.Element[];
  visibleColumnIndex: number;
  percentage: number;
}

export default function Carousel({ columns, visibleColumnIndex, percentage }: Props): JSX.Element | null {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {columns.map((column, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={classes.column}
          style={selectColumnTransformStyle(index + 1, visibleColumnIndex, percentage)}
        >
          {column}
        </div>
      ))}
    </div>
  );
}
