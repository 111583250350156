import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 240,
    width: 360,
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  cancelLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onCancelConfirm: () => void;
}

export default function CancelOfferModal({ open, onClose, onCancelConfirm }: ModalProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog onClose={onClose} open={open} disableEscapeKeyDown maxWidth="md">
      <div className={classes.modalContainer}>
        <DialogTitle id="dialog-title" onClose={onClose}>
          {t('trade:cancelOffer')}
        </DialogTitle>
        <DialogContentBorderTopOnly className={classes.modalContent} dividers>
          <Typography customVariant="subtitle1">{t('trade:cancelOfferLabel')}</Typography>
          <div className={classes.actions}>
            <Typography variant="body1" className={classes.cancelLabel} onClick={onClose}>
              {t('common:cancel')}
            </Typography>
            <Button variant="contained" color="primary" size="large" onClick={onCancelConfirm}>
              {t('trade:cancelConfirmButton')}
            </Button>
          </div>
        </DialogContentBorderTopOnly>
      </div>
    </Dialog>
  );
}
