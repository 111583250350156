import { OmsOrder } from './BidsTable';
import { OrderDirection } from './types';
import { BookedOrder } from '../../__generated__/graphql';

export function selectBids(data: BookedOrder[] | undefined, userId: string | undefined): OmsOrder[] | null {
  if (!data || !data.length) {
    return null;
  }
  const filteredBids = data.filter((x) => x.direction === OrderDirection.Bids);

  return filteredBids.reduce((acc, cur) => {
    if (!cur) return acc;

    const { userId: omsUserId, price, quantity } = cur;
    if (userId && price && quantity) {
      acc.push({
        isOwner: omsUserId === userId,
        price,
        quantity,
      });
    }
    return acc;
  }, [] as OmsOrder[]);
}

export function selectOffers(data: BookedOrder[] | undefined, userId: string | undefined): OmsOrder[] | null {
  if (!data || !data.length) {
    return null;
  }
  const filteredBids = data.filter((x) => x.direction === OrderDirection.Offers);

  return filteredBids.reduce((acc, cur) => {
    if (!cur) return acc;

    const { userId: omsUserId, price, quantity } = cur;
    if (userId && price && quantity) {
      acc.push({
        isOwner: omsUserId === userId,
        price,
        quantity,
      });
    }
    return acc;
  }, [] as OmsOrder[]);
}
