import { useQuery } from '@apollo/client';
import { SelectOption } from '@cultwines/zellar-client-sdk';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '.';
import { ASSETS_BY_VINTAGE_ID } from '../../graphql/queries/assetsByVintageId';
import isUndefined from '../../utils/isUndefined';
import { WineVintage } from '../../__generated__/graphql';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '120px',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 700,
    lineHeight: theme.typography.body1.lineHeight,

    [theme.breakpoints.up('sm')]: {
      width: '160px',
    },
  },
}));

function selectOptions(data: WineVintage | null | undefined): SelectOption[] {
  if (!data?.assets?.length) {
    return [];
  }
  return data.assets.reduce((acc, cur) => {
    if (!cur) return acc;

    const { id, unitSize, unitCount } = cur;
    if (id && unitSize && unitCount) {
      acc.push({
        value: id,
        label: `${unitCount}x${unitSize}`,
      });
    }
    return acc;
  }, [] as SelectOption[]);
}

function selectActiveOption(data: WineVintage | null | undefined): SelectOption | null {
  if (!data?.assets?.length) {
    return null;
  }
  const { id, unitSize, unitCount } = data.assets[0];
  return {
    value: id,
    label: `${unitCount}x${unitSize}`,
  };
}

interface Props {
  wineVintageId: number | undefined;
  onChange: (option: SelectOption | null) => void;
}

export default function UnitSizeSelectByVintageId({ wineVintageId, onChange }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const label = t('product:unitSize');
  const [optionId, setOptionId] = useState<number | undefined>(undefined);
  const { error, data, loading } = useQuery(ASSETS_BY_VINTAGE_ID, {
    variables: { wineVintageId: wineVintageId ?? 0 },
    skip: isUndefined(wineVintageId),
  });
  const options = useMemo(() => selectOptions(data?.productWineVintage as WineVintage), [data]);
  const initialOption = useMemo(() => selectActiveOption(data?.productWineVintage as WineVintage), [data]);

  useEffect(() => {
    setOptionId(initialOption?.value);
    onChange(initialOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOption]);

  function handleChange(selectedId: number) {
    const selectedOption = options.find((size) => size.value === selectedId);
    if (selectedOption) {
      setOptionId(selectedOption.value);
      onChange(selectedOption);
    }
  }

  if (error) {
    return <Typography>Failed to load data.</Typography>;
  }
  return (
    <Select
      optionId={optionId || initialOption?.value}
      setOption={handleChange}
      options={options}
      inputLabel={label}
      inputClass={classes.select}
      inlineLabel
      placeholder={t('common:select')}
      loading={loading}
    />
  );
}
