import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  summaryDetails: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(4),

    '@media (min-width: 600px)': {
      flexDirection: 'row',
    },
  },

  actions: {
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: theme.spacing(4),
    justifyContent: 'space-between',

    '@media (min-width: 800px)': {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
  },

  rightSideActions: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1.5),
    marginLeft: 'auto',
  },

  selects: {
    display: 'flex',
    columnGap: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    '@media (min-width: 800px)': {
      flexWrap: 'wrap',
      marginBottom: 0,
      marginTop: 0,
    },
  },

  image: {
    width: '144px',

    [theme.breakpoints.up('sm')]: {
      width: '200px',
      height: '300px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '240px',
      height: '344px',
    },
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
  },

  productTitle: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '33.6px',
  },

  badgesContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },

  horizontalContainer: {
    display: 'flex',
    columnGap: theme.spacing(5),
    rowGap: theme.spacing(3),
    marginBottom: theme.spacing(3),
    flexWrap: 'wrap',
    '@media (min-width: 800px)': {
      justifyContent: 'flex-end',
      marginBottom: 0,
    },
  },

  marketValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },

  marketValue: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  uppercase: {
    textTransform: 'uppercase',
  },

  item: {
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'center',
  },

  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.contrastText,
  },
  liveBid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.25rem',
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    borderRadius: '1.5rem',
    height: '28px',
    width: '82px',
  },

  // TODO: Create a button wrapper that supports our various styles, because the
  // offering from Mui is not sufficient, and we cannot extend the variant props in v4.
  // See https://mui.com/material-ui/customization/theme-components/#adding-new-component-variants
  // https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/5705
  retryButton: {
    color: theme.palette.button.tertiary.text.main,
    background: theme.palette.button.tertiary.background.main,

    '&:hover': {
      background: theme.palette.button.tertiary.background.hover,
    },

    '&:disabled': {
      background: theme.palette.button.tertiary.background.disabled,
      color: theme.palette.button.tertiary.text.disabled,
    },
  },
}));
