/**
 * Formats the date format used in UI (DD/MM/YYYY)
 * to the default used in API (YYYY-MM-DD)
 *
 * @param dateString The date you want formatted, in the form DD/MM/YYYY
 */
export function humanReadableDateToDefault(dateString: string): string {
  if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    throw new Error('Pass a date matching the following format: DD/MM/YYYY');
  }

  return dateString
    .split('/')
    .reverse()
    .reduce((prev, curr) => `${prev}-${curr}`);
}
