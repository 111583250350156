import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WineConfirmSuccess from '../../assets/images/wine-confirm-success.png';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  iconContainer: {
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  label: {
    marginBottom: theme.spacing(8),
  },
  button: {
    minWidth: 200,
    alignSelf: 'center',
  },
}));

interface Props {
  onClose: () => void;
}

export default function BidSuccessContent({ onClose }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.modalContainer}>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {' '}
      </DialogTitle>
      <div className={classes.iconContainer}>
        <img src={WineConfirmSuccess} alt="success" />
      </div>
      <Typography className={classes.title} variant="h2">
        {t('common:kyc.success.title')}
      </Typography>
      <Typography className={classes.label} variant="body1">
        {t('common:kyc.success.message')}
      </Typography>
      <Button className={classes.button} variant="contained" color="primary" size="large" onClick={onClose}>
        {t('common:kyc.success.continue')}
      </Button>
    </div>
  );
}
