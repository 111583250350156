import { GraphQLError } from 'graphql';
import { Namespace, Resources, TFunction } from 'react-i18next';
import React from 'react';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { uuid } from '../../utils/uuid';
import { SearchWinesFacets } from '../../views/Search/selectors';
import { SortOption } from '../Table/SortMenu';
import {
  ActionCallback,
  BasicCell,
  Row,
  ScoreCell,
  SortDirection,
  SparklineCell,
  WineNameCell,
  CustomCell,
  UnitSizeCell,
} from '../Table/types';
import { WineVintage } from './types';
import { isNull } from '../../utils/isNull';
import TradingTile from '../TradingTile';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { getProductUrl } from '../../utils/common';

export interface SearchResultsTableData extends Row {
  wineName: WineNameCell;
  vintage: BasicCell;
  score: ScoreCell;
  unitSize: UnitSizeCell;
  marketValue: BasicCell;
  performance?: SparklineCell;
  highestBid: CustomCell;
  lowestOffer: CustomCell;
  spread: BasicCell;
}

interface SelectTableDataProps {
  wineVintageArray: WineVintage[];
  onBidClicked: ActionCallback;
  onOfferClicked: ActionCallback;
  onBuyNowClicked: ActionCallback;
  onAddToWatchlistClicked: ActionCallback;
  onRemoveFromWatchlistClicked: ActionCallback;
  onWatchlistButtonError: (errors: readonly GraphQLError[]) => void;
  clickableTradeTiles13816: boolean;
}

export function selectTableData({
  onAddToWatchlistClicked,
  onBidClicked,
  onBuyNowClicked,
  onOfferClicked,
  onRemoveFromWatchlistClicked,
  onWatchlistButtonError,
  clickableTradeTiles13816,
  wineVintageArray,
}: SelectTableDataProps): SearchResultsTableData[] {
  return wineVintageArray.map((wv) => {
    const { name: wineName, vintage, defaultAssetId: assetId } = wv;
    const prodUrl = getProductUrl({ wineName, vintage, assetId });

    const HighestBidTradeTile = clickableTradeTiles13816 ? (
      <TradingTile mode="bid" value={wv.highestBid} onClick={() => onBidClicked(wv.defaultAssetId)} />
    ) : (
      <TradingTile
        mode="bid"
        value={wv.highestBid}
        unitCount={wv.defaultAssetUnitCount}
        unitSize={wv.defaultAssetUnitSize}
      />
    );

    const LowestOfferTradeTile = clickableTradeTiles13816 ? (
      <TradingTile
        mode="offer"
        value={wv.lowestOffer}
        onClick={!isNullOrUndefined(wv.lowestOffer) ? () => onBuyNowClicked(wv.defaultAssetId) : undefined}
      />
    ) : (
      <TradingTile
        mode="offer"
        value={wv.lowestOffer}
        unitCount={wv.defaultAssetUnitCount}
        unitSize={wv.defaultAssetUnitSize}
      />
    );

    return {
      wineName: {
        appellation: wv.appellation ?? null,
        region: wv.region,
        wineName: wv.name,
        link: `${prodUrl}`,
      },
      vintage: {
        mainContent: wv.vintage,
      },
      score: {
        score: wv.score,
      },
      unitSize: {
        unitCount: wv.defaultAssetUnitCount,
        unitSize: wv.defaultAssetUnitSize,
      },
      marketValue: {
        mainContent: formatterWholeNumber.format(wv.marketValue),
      },
      highestBid: {
        content: HighestBidTradeTile,
      },
      lowestOffer: {
        content: LowestOfferTradeTile,
      },
      performance: {
        data: wv.performance.marketData,
        deltaNum: wv.performance.valueDelta,
        deltaPct: wv.performance.percentageDelta,
      },
      spread: {
        mainContent: !isNull(wv.spread) ? `${wv.spread}%` : '-',
      },
      actions: {
        trade: {
          assetId: Number(wv.defaultAssetId),
          onBidClicked,
          onBuyClicked: onBuyNowClicked,
          onOfferClicked,
          condensed: true,
        },
        watchlist: {
          onAdd: onAddToWatchlistClicked,
          onRemove: onRemoveFromWatchlistClicked,
          onError: onWatchlistButtonError,
        },
      },
      rowId: wv.defaultAssetId,
    };
  });
}

export function selectSortOptions(
  sortDirection: SortDirection | null,
  activeSortKey: keyof SearchWinesFacets | null,
  t: TFunction<Namespace<keyof Resources>>,
): SortOption<keyof SearchWinesFacets>[] {
  const sortOptions: SortOption<keyof SearchWinesFacets>[] = [
    {
      id: uuid(),
      direction: 'desc',
      key: 'Score',
      active: activeSortKey === 'Score' && sortDirection === 'desc',
      primaryLabel: t('search:filters.score'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'Score',
      active: activeSortKey === 'Score' && sortDirection === 'asc',
      primaryLabel: t('search:filters.score'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'Vintage',
      active: activeSortKey === 'Vintage' && sortDirection === 'desc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.newestToOldest'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'Vintage',
      active: activeSortKey === 'Vintage' && sortDirection === 'asc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.oldestToNewest'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'MarketValue',
      active: activeSortKey === 'MarketValue' && sortDirection === 'desc',
      primaryLabel: t('search:filters.marketValue'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'MarketValue',
      active: activeSortKey === 'MarketValue' && sortDirection === 'asc',
      primaryLabel: t('search:filters.marketValue'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'PercentageDifference',
      active: activeSortKey === 'PercentageDifference' && sortDirection === 'desc',
      primaryLabel: t('search:filters.performance'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'PercentageDifference',
      active: activeSortKey === 'PercentageDifference' && sortDirection === 'asc',
      primaryLabel: t('search:filters.performance'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'Appellation',
      active: activeSortKey === 'Appellation' && sortDirection === 'desc',
      primaryLabel: t('search:filters.appellation'),
      secondaryLabel: t('search:sort.zToA'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'Appellation',
      active: activeSortKey === 'Appellation' && sortDirection === 'asc',
      primaryLabel: t('search:filters.appellation'),
      secondaryLabel: t('search:sort.aToZ'),
    },
  ];

  return sortOptions;
}
