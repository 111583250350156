import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily:
    'Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  h1: {
    fontSize: '2.125rem',
    lineHeight: '2.55rem',
    fontWeight: 700,
  },

  h2: {
    fontSize: '1.75rem',
    lineHeight: '2.1rem',
    fontWeight: 700,
  },

  h3: {
    fontSize: '1.625rem',
    lineHeight: '1.95rem',
    fontWeight: 700,
  },

  /**
   * @deprecated ❗️
   */
  h3Black: {
    fontSize: '1.125rem',
    lineHeight: '1.375rem',
    fontWeight: 900,
  },

  h4: {
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
    fontWeight: 700,
  },

  h5: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
  },

  h6: {
    fontSize: '1.125rem',
    lineHeight: '1.35rem',
    fontWeight: 700,
  },

  subtitle1: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 700,
  },

  /**
   * @deprecated ❗️
   */
  subtitle1Bold: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 700,
  },

  subtitle2: {
    fontSize: '0.875rem',
    lineHeight: '1.05rem',
    fontWeight: 700,
  },

  /**
   * @deprecated ❗️
   */
  subtitle2Bold: {
    fontSize: '0.6875rem',
    lineHeight: '0.9375rem',
    fontWeight: 700,
  },

  subtitle3: {
    fontSize: '0.75rem',
    lineHeight: '0.9rem',
    fontWeight: 700,
  },

  subtitle4: {
    fontSize: '0.625rem',
    lineHeight: '1.125rem',
    fontWeight: 400,
  },

  /**
   * @deprecated ❗️
   */
  subtitle3Bold: {
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    fontWeight: 700,
  },

  body1: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    fontWeight: 400,
  },

  /**
   * @deprecated ❗️
   */
  body1Bold: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    fontWeight: 700,
  },

  body2: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontWeight: 400,
  },

  caption: {
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    fontWeight: 400,
  },

  link1: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    fontWeight: 700,
    textDecoration: 'underline',
  },

  link2: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontWeight: 700,
    textDecoration: 'underline',
  },

  label1Active: {
    fontSize: '0.875rem',
    lineHeight: '1.05rem',
    fontWeight: 700,
  },

  label1: {
    fontSize: '0.875rem',
    lineHeight: '1.05rem',
    fontWeight: 400,
  },

  label2Active: {
    fontSize: '0.75rem',
    lineHeight: '0.9rem',
    fontWeight: 700,
  },

  label2: {
    fontSize: '0.75rem',
    lineHeight: '0.9rem',
    fontWeight: 400,
  },

  /**
   * @deprecated ❗️
   */
  body2Bold: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    fontWeight: 700,
  },

  /**
   * @deprecated ❗️
   */
  body3: {
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    fontWeight: 400,
  },

  /**
   * @deprecated ❗️
   */
  body3Bold: {
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    fontWeight: 700,
  },

  /**
   * @deprecated ❗️
   */
  buttonMedium: {
    textTransform: 'none',
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    fontWeight: 700,
  },

  /**
   * @deprecated ❗️
   */
  buttonLarge: {
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: '1.188rem',
    fontWeight: 700,
  },

  /**
   * @deprecated ❗️
   */
  navigation: {
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    fontWeight: 700,
  },

  /**
   * @deprecated ❗️
   */
  numberExtraLarge: {
    fontSize: '2.125rem',
    lineHeight: '2.5rem',
    fontWeight: 900,
  },

  /**
   * @deprecated ❗️
   */
  numberLarge: {
    fontSize: '1.625rem',
    lineHeight: '2rem',
    fontWeight: 900,
  },

  /**
   * @deprecated ❗️
   */
  numberMedium: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
  },

  /**
   * @deprecated ❗️
   */
  numberSmall: {
    fontSize: '0.875rem',
    lineHeight: '1.063rem',
    fontWeight: 700,
  },

  /**
   * @deprecated ❗️
   */
  numberExtraSmall: {
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    fontWeight: 700,
  },
  htmlFontSize: 16,
};

export default typography;
