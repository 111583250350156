import React from 'react';
import { RangeFacet, RangeFilter } from '@cultwines/zellar-client-sdk/types/Filters';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '../Typography';
import FilterContainer from './FilterContainer';
import { isNull } from '../../utils/isNull';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'flex-start',
  },
  fields: {
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'center',
  },
  textField: {
    background: theme.palette.background.default,
  },
  button: {
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
  },
}));

interface RangeFilterProps<T> {
  className?: string;
  facet: RangeFacet<T>;
  onChange: (filter: RangeFilter<T>) => void;
}

export default function RangeFilterComponent<T>({ facet, onChange, className = '' }: RangeFilterProps<T>): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const [min, setMin] = React.useState<string>(isNull(facet.filters.min) ? '' : facet.filters.min.toString());
  const [max, setMax] = React.useState<string>(isNull(facet.filters.max) ? '' : facet.filters.max.toString());

  React.useEffect(() => {
    setMin(isNull(facet.filters.min) ? '' : facet.filters.min.toString());
  }, [facet.filters.min]);

  React.useEffect(() => {
    setMax(isNull(facet.filters.max) ? '' : facet.filters.max.toString());
  }, [facet.filters.max]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    onChange({
      ...facet.filters,
      min: min.length === 0 ? null : Number(min),
      max: max.length === 0 ? null : Number(max),
    });
  }

  function handleMinChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setMin(event.target.value);
  }

  function handleMaxChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setMax(event.target.value);
  }

  return (
    <FilterContainer title={facet.name}>
      <form className={`${classes.container} ${className}`} onSubmit={handleSubmit}>
        <div className={classes.fields}>
          <TextField
            className={classes.textField}
            variant="outlined"
            type="number"
            id="min"
            name="min"
            placeholder={t('common:min')}
            value={min}
            onChange={handleMinChange}
          />
          <Typography customVariant="subtitle2">-</Typography>
          <TextField
            className={classes.textField}
            variant="outlined"
            type="number"
            id="max"
            name="max"
            placeholder={t('common:max')}
            onChange={handleMaxChange}
            value={max}
          />
        </div>
        <Button className={classes.button} variant="contained" color="secondary" type="submit">
          {t('common:apply')}
        </Button>
      </form>
    </FilterContainer>
  );
}
