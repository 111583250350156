import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  container: { transition: '500ms ease', paddingBottom: theme.spacing(4) },
}));

export enum ModalStep {
  Initial,
  Summary,
  Processing,
  Success,
  Failure,
}

interface DialogContentSwitcherProps {
  InitialComponent?: React.FC;
  // TODO: This needs improving, it exists to prevent us having
  // to pass in a function, which causes the inner component to get
  // re-created on parent re-render (e.g. state change), which is causing very gnarly bugs,
  // noticed on the bid modal when trying to lift state up.
  InitialComponentJSX?: JSX.Element;
  SuccessComponent?: React.FC;
  ProcessingComponent?: JSX.Element;
  SummaryComponent?: React.FC;
  FailureComponent?: React.FC;
  modalStep: ModalStep;
  selectStyles?: (step: ModalStep, lowerThanMd: boolean) => React.CSSProperties;
}

function selectModalStyles(step: ModalStep, lowerThanMd: boolean) {
  if (step === ModalStep.Initial) {
    return { minHeight: 700, width: lowerThanMd ? 550 : 768, minWidth: lowerThanMd ? 550 : 768 };
  }
  if (step === ModalStep.Summary) {
    return { minHeight: 450, width: lowerThanMd ? 350 : 420, minWidth: lowerThanMd ? 350 : 420 };
  }
  return { minHeight: 400, minWidth: lowerThanMd ? 380 : 600 };
}

export default function DialogContentSwitcher({
  InitialComponent,
  InitialComponentJSX,
  SummaryComponent,
  ProcessingComponent,
  SuccessComponent,
  FailureComponent,
  modalStep,
  selectStyles = selectModalStyles,
}: DialogContentSwitcherProps): JSX.Element {
  const theme = useTheme();
  const lowerThanMd = useMediaQuery(theme.breakpoints.down('lg'));
  const classes = useStyles();
  const style = selectStyles(modalStep, lowerThanMd);
  return (
    <div className={classes.container} style={style}>
      {modalStep === ModalStep.Initial && InitialComponent && <InitialComponent />}
      {modalStep === ModalStep.Initial && InitialComponentJSX && InitialComponentJSX}
      {modalStep === ModalStep.Summary && SummaryComponent && <SummaryComponent />}
      {modalStep === ModalStep.Processing && ProcessingComponent && ProcessingComponent}
      {modalStep === ModalStep.Success && SuccessComponent && <SuccessComponent />}
      {modalStep === ModalStep.Failure && FailureComponent && <FailureComponent />}
    </div>
  );
}
