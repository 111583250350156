import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 240,
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  label: {
    textTransform: 'capitalize',
    fontWeight: 700,
    paddingBottom: 20,
  },
  cancelLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export { useStyles };
