/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { FC, ReactNode, useMemo } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

type SocialShareOption = Partial<{
  showLabel: boolean;
  iconContainer: string;
  iconLabelClassName: string;
  size: number;
}>;

export enum SocialShareTypeEnum {
  EAMIL = 'email',
  WHATSAPP = 'whatsapp',
  Instagram = 'instagram',
  Twitter = 'twitter',
  CUSTOM = 'custom',
}

interface SocialShare {
  label: string;
  type: SocialShareTypeEnum;
  className?: string;
  icon?: ReactNode;
  iconProps?: Record<string, unknown>;
  onClick?: () => void;
}

const getSocial = (id: string, socialShare: SocialShare, url: string, title: string, options: SocialShareOption) => {
  let icon = null;
  const size = options.size || 32;

  const className = (socialShare.className || '').trim();
  const urlIn = `${encodeURI(url)}`;
  switch (socialShare.type) {
    case SocialShareTypeEnum.EAMIL:
      icon = (
        <EmailShareButton url={urlIn} subject={title} className={className}>
          <EmailIcon size={size} round />
        </EmailShareButton>
      );
      break;

    case SocialShareTypeEnum.WHATSAPP:
      icon = (
        <WhatsappShareButton url={urlIn} title={title} separator=":: " className={className}>
          <WhatsappIcon size={size} round {...(socialShare.iconProps || {})} />
        </WhatsappShareButton>
      );

      break;

    case SocialShareTypeEnum.Twitter:
      icon = (
        <TwitterShareButton url={urlIn} title={title} className={className}>
          <TwitterIcon size={size} round />
        </TwitterShareButton>
      );

      break;

    case SocialShareTypeEnum.CUSTOM:
      icon = socialShare.icon || null;
      break;

    default:
      break;
  }

  if (!icon) return null;
  return (
    <div
      key={id}
      onClick={socialShare.onClick}
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      className={` ${options.iconContainer || ''}`}
    >
      {icon}
      {options.showLabel && socialShare.label && (
        <span style={{ justifySelf: 'flex-end' }} className={options.iconLabelClassName}>
          {socialShare.label || ''}
        </span>
      )}
    </div>
  );
};

interface SocialShareProps {
  title?: string;
  url: string;
  socials: SocialShare[];
  options?: SocialShareOption;
}

const SocialSharePanel: FC<SocialShareProps> = ({ url, title, socials, options }) => {
  const config = {
    showLabel: true,
    iconClassName: '',
    ...options,
  };

  const socialShares = useMemo(() => {
    if (!socials || socials.length === 0) return [];

    return socials.map((item, index) => {
      const social = getSocial(`social-share-${index}`, item, url, title || '', config);
      return social;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, url, socials]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{socialShares.length > 0 && socialShares.map((item) => item)}</>;
};

export default SocialSharePanel;
