import { IconButton } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import Clear from '@mui/icons-material/Clear';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { ReactComponent as Arrows } from '../../assets/icons/trade-arrows.svg';
import { ReactComponent as DownArrowFilled } from '../../assets/images/down-arrow-filled.svg';
import useCanCreateOffer from '../../hooks/useCanCreateOffer';
import { useStyles } from './styles';
import { getKycStatus } from '../../services/auth';
import { KycStatus } from '../../__generated__/graphql';
import { useTotalCountOfAssetUnitsOnOffer } from '../../hooks/useTotalCountOfAssetUnitsOnOffer';
import useUserDetails from '../../hooks/useUserDetails';
import { logError } from '../../utils/logger';
import { isLoggedInVar } from '../../graphql/cache';

const TRANSITION_DURATION = 333;
const ANCHOR_ORIGIN: PopoverOrigin = { horizontal: 'center', vertical: 'top' };
const TRANSFORM_ORIGIN: PopoverOrigin = { horizontal: 'center', vertical: 'top' };

type Func = () => void;
interface BuyTradeActionButtonProps {
  onBidClicked: Func | null;
  onOfferClicked: Func | null;
  onBuyClicked?: Func | null;
  onSignInClicked?: Func | null;
  size?: Size;
  condensed?: boolean;
  disabled?: boolean;
  assetId: number;
}

export enum Size {
  Small,
  Large,
}

/**
 * https://www.figma.com/file/TXv0dwPTUpo1949Z8ZFXmD/CW-ZELLAR-UI?node-id=352%3A7246
 */
export default function BuyTradeActionButton({
  onBidClicked,
  onOfferClicked,
  onBuyClicked,
  onSignInClicked = null,
  condensed = false,
  size = Size.Large,
  disabled = false,
  assetId,
}: BuyTradeActionButtonProps): JSX.Element {
  const { t } = useTranslation();
  const classesObject = useStyles();
  const { userDetails, error: failedToLoadUserDetails } = useUserDetails();
  const history = useHistory();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const {
    canCreateOffer,
    loading: canCreateOffQueryLoading,
    error: useCanCreateOfferError,
  } = useCanCreateOffer({ assetId });
  const {
    totalUnitAssetsAvailableToBuyNow,
    loading: totalAssetUnitsAvailableQueryloading,
    error: failedToLoadTotalAssetUnitsAvailable,
  } = useTotalCountOfAssetUnitsOnOffer({ assetId, excludeOwnedAssets: true, userId: userDetails?.userId });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { kyc: kycFlagEnabled, buyNow: buyNowFlagEnabled } = useFlags();
  const allowBuyTrade = !kycFlagEnabled || getKycStatus() === KycStatus.successful;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const allowBuyNow =
    buyNowFlagEnabled &&
    !totalAssetUnitsAvailableQueryloading &&
    totalUnitAssetsAvailableToBuyNow > 0 &&
    !failedToLoadUserDetails;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'fancy-button-select' : undefined;

  function handleBidClicked() {
    if (!onBidClicked) {
      return;
    }
    onBidClicked();
    handleClose();
  }

  function handleOfferClicked() {
    if (!onOfferClicked) {
      return;
    }
    onOfferClicked();
    handleClose();
  }

  function handleBuyClicked() {
    if (!onBuyClicked) {
      return;
    }
    onBuyClicked();
    handleClose();
  }

  function handleSignInClick(): void {
    if (!onSignInClicked) {
      history.push('/login');
    } else onSignInClicked();
    handleClose();
  }

  React.useEffect(() => {
    if (useCanCreateOfferError) {
      logError({
        error: new Error('Failed to load canCreateOffer query'),
        originalError: useCanCreateOfferError,
        filename: 'BuyTradeActionButton',
      });
    }
  }, [useCanCreateOfferError]);

  React.useEffect(() => {
    if (failedToLoadUserDetails) {
      logError({
        error: new Error('Failed to load user details'),
        originalError: failedToLoadUserDetails,
        filename: 'BuyTradeActionButton',
      });
    }
  }, [failedToLoadUserDetails]);

  React.useEffect(() => {
    if (failedToLoadTotalAssetUnitsAvailable) {
      logError({
        error: new Error('Failed to load total asset units available'),
        originalError: failedToLoadTotalAssetUnitsAvailable,
        filename: 'BuyTradeActionButton',
      });
    }
  }, [failedToLoadTotalAssetUnitsAvailable]);

  const Content = (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={ANCHOR_ORIGIN}
      transformOrigin={TRANSFORM_ORIGIN}
      PaperProps={{ className: classesObject.popover }}
      transitionDuration={TRANSITION_DURATION}
    >
      <div className={classesObject.container}>
        <Button
          color="primary"
          variant="contained"
          className={`${classesObject.iconButton} ${
            size === Size.Large ? classesObject.largeIconButton : classesObject.smallIconButton
          }`}
          onClick={handleClose}
        >
          <Clear />
        </Button>
        {!isLoggedIn && (
          <Button
            color="primary"
            variant="contained"
            className={size === Size.Large ? classesObject.largeButton : classesObject.smallButton}
            onClick={handleSignInClick}
            fullWidth
          >
            {t('common:login')}
          </Button>
        )}
        {isLoggedIn && (
          <>
            <Button
              disabled={!allowBuyNow}
              aria-label="buyNowButton"
              color="primary"
              variant="contained"
              className={size === Size.Large ? classesObject.largeButton : classesObject.smallButton}
              fullWidth
              onClick={handleBuyClicked}
            >
              {t('common:buyNow')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={onBidClicked === null}
              className={size === Size.Large ? classesObject.largeButton : classesObject.smallButton}
              onClick={handleBidClicked}
              fullWidth
            >
              {t('common:bid')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={
                onOfferClicked === null ||
                canCreateOffQueryLoading ||
                !canCreateOffer ||
                Boolean(useCanCreateOfferError)
              }
              className={size === Size.Large ? classesObject.largeButton : classesObject.smallButton}
              onClick={handleOfferClicked}
              fullWidth
            >
              {t('common:offer')}
            </Button>
          </>
        )}
      </div>
    </Popover>
  );
  const buttonClassName =
    size === Size.Large
      ? `${classesObject.largeButton} ${classesObject.largeAnchorButton}`
      : `${classesObject.smallButton} ${classesObject.smallAnchorButton}`;
  return (
    <>
      {condensed ? (
        <IconButton
          className={classesObject.condensed}
          classes={{ root: classesObject.iconButtonRoot }}
          aria-describedby={id}
          onClick={handleClick}
          disabled={disabled}
          size="large"
        >
          <Arrows className={classesObject.tradeArrows} />
        </IconButton>
      ) : (
        <Button
          color="primary"
          variant="contained"
          aria-describedby={id}
          aria-label="buyTradeButton"
          onClick={handleClick}
          disabled={!allowBuyTrade}
          className={buttonClassName}
        >
          <>
            <div className={classesObject.anchorButtonInner}>
              <Arrows className={classesObject.tradeArrows} />
              {t('common:buy')} / {t('common:trade')}
            </div>
            <DownArrowFilled className={classesObject.downArrow} />
          </>
        </Button>
      )}
      <Backdrop className={classesObject.backdrop} open={open}>
        {Content}
      </Backdrop>
    </>
  );
}
