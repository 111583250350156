import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import { CheckboxFilter, isRangeFilter, RangeFilter } from '@cultwines/zellar-client-sdk/types/Filters';
import { FilterDispatch } from '@cultwines/zellar-client-sdk/hooks/useFilters';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import DotGauge from '../DotGauge';

const useStyles = makeStyles((theme) => ({
  outlined: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    fontWeight: theme.typography.body2.fontWeight,
    color: theme.palette.textPrimary,
  },
  deleteIcon: {
    color: theme.palette.textInformation,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function selectFilterLabel<T>(filter: RangeFilter<T> | CheckboxFilter<T>): string | JSX.Element {
  if (isRangeFilter<T>(filter)) {
    if (isNull(filter.min)) {
      return `${filter.label}: <= ${filter.max}`;
    }

    if (isNull(filter.max)) {
      return `${filter.label}: >= ${filter.min}`;
    }

    return `${filter.label}: [${filter.min} TO ${filter.max}]`;
  }

  if (filter.parentId === 'LiquidityRank') {
    return <DotGauge dotCount={5} filledDots={Number(filter.label)} hideEmptyDots />;
  }

  return filter.label;
}

interface FilterChipProps<T> {
  filter: RangeFilter<T> | CheckboxFilter<T>;
  dispatch: FilterDispatch<T>;
}

export default function FilterChip<T>({ filter, dispatch }: FilterChipProps<T>): JSX.Element {
  const classes = useStyles();
  function handleDelete(): void {
    if (isRangeFilter<T>(filter)) {
      const payload: RangeFilter<T> = { ...filter, min: null, max: null };
      dispatch({ type: 'toggleFilter', payload });
    } else {
      const payload: CheckboxFilter<T> = { ...filter, checked: false };
      dispatch({ type: 'toggleFilter', payload });
    }
  }

  return <Chip classes={classes} label={selectFilterLabel(filter)} onDelete={handleDelete} variant="outlined" />;
}
