import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Typography as TTypography } from '@mui/material/styles/createTypography';
import Typography, { TextColour } from '../Typography';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}));

interface DateFieldLabelProps {
  className?: string;
  labelVariant?: keyof TTypography;
  captionColour?: TextColour;
  label: string;
}

export default function DateFieldLabel({
  label,
  labelVariant = 'subtitle2',
  captionColour = 'textLabel',
  className = '',
}: DateFieldLabelProps): JSX.Element {
  const classes = useStyles();
  return (
    <div className={clsx(classes.label, className)}>
      <Typography customVariant={labelVariant}>{label}</Typography>
      <Typography customVariant="caption" zellarColour={captionColour}>
        {' '}
        (DD/MM/YYYY)
      </Typography>
    </div>
  );
}
