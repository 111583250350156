import makeStyles from '@mui/styles/makeStyles';

export const useSearchInputStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '400px',
  },
  condensed: {
    width: 'auto',
  },
  expanded: {
    width: '100%',
  },
  searchBarRoot: {
    width: '100%',
    borderRadius: '35px',
    border: `1px solid ${theme.palette.grey[100]}`,
    background: theme.palette.grey[50],
    padding: `${theme.spacing(0.25)} ${theme.spacing(4)}`,
    height: 'auto',
    color: theme.palette.textLabel,
    fontSize: theme.typography.subtitle3.fontSize,
    fontWeight: theme.typography.subtitle3.fontWeight,
  },
  searchBarActive: {
    border: `1px solid ${theme.palette.textField.active}`,
    caretColor: theme.palette.textField.active,
    color: theme.palette.textPrimary,
  },
  elevated: {
    zIndex: theme.zIndex.modal,
  },
  searchBarCondensed: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  circledCrossIcon: {
    padding: 0,
    stroke: theme.palette.grey[200],
  },
}));

export type SearchInputClasses = ReturnType<typeof useSearchInputStyles>;
