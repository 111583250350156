import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import EmptyImage from '../../../assets/images/oops-image.png';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    textAlign: 'center',
  },
}));

export default function EmptyPlaceholder(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={EmptyImage} alt="empty" />
    </div>
  );
}
