import { DeprecatedThemeOptions } from '@mui/material/styles';
import sharedOverrides from './overrides';
import typography from './typography';

const sharedOptions: DeprecatedThemeOptions = {
  breakpoints: {
    unit: 'px',
    values: {
      xs: 480,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1366,
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography,
};

export { sharedOverrides, sharedOptions };
