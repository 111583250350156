import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { OrderDirection } from '../components/OrderBookModal/types';
import { graphql } from '../__generated__';

export const TOTAL_COUNT_OF_ASSET_UNITS_ON_OFFER = graphql(`
  query TotalCountOfAssetUnitsOnOffer($assetId: Int!) {
    openOrders(assetId: $assetId) {
      id
      status
      outstandingQuantity
      direction
      userId
    }
  }
`);

export type UseTotalCountOfAssetUnitsOnOfferResponse = {
  totalUnitAssetsAvailableToBuyNow: number;
  loading: boolean;
  error?: ApolloError;
};

export type UseTotalCountOfAssetUnitsOnOfferProps = {
  assetId: number;
  excludeOwnedAssets?: boolean;
  userId?: string;
};

export const useTotalCountOfAssetUnitsOnOffer = ({
  assetId,
  excludeOwnedAssets,
  userId,
}: UseTotalCountOfAssetUnitsOnOfferProps): UseTotalCountOfAssetUnitsOnOfferResponse => {
  const [totalUnitAssetsAvailableToBuyNow, setTotalUnitAssetsAvailableToBuyNow] = React.useState(0);
  const { data, error, loading } = useQuery(TOTAL_COUNT_OF_ASSET_UNITS_ON_OFFER, {
    skip: Boolean(process.env.REACT_APP_MOCK_GQL),
    variables: {
      assetId,
    },
  });

  React.useEffect(() => {
    if (data) {
      const totalAvailableToBuyNow = data.openOrders
        .filter((o) => o.direction === OrderDirection.Offers)
        .filter((offer) => (userId && excludeOwnedAssets ? offer.userId.toUpperCase() !== userId.toUpperCase() : true))
        .map((o) => o.outstandingQuantity)
        .reduce((prev, curr) => prev + curr, 0);

      setTotalUnitAssetsAvailableToBuyNow(totalAvailableToBuyNow);
    } else {
      setTotalUnitAssetsAvailableToBuyNow(0);
    }
    // no need to include assetId, that'll trigger data to be changed anyway
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, userId, excludeOwnedAssets]);

  if (Boolean(process.env.REACT_APP_MOCK_GQL) === true) {
    return { totalUnitAssetsAvailableToBuyNow: 5, loading: false };
  }

  return { totalUnitAssetsAvailableToBuyNow, loading, error };
};
