import { useQuery } from '@apollo/client';
import { OWNED_ASSET_INSTANCES_QUERY } from '../graphql/queries/ownedAssetInstance';

interface UseOwnedAssetInstancesArgs {
  assetId: number;
}

export const useOwnedAssetInstances = ({ assetId }: UseOwnedAssetInstancesArgs) => {
  const { data, error, loading } = useQuery(OWNED_ASSET_INSTANCES_QUERY, {
    variables: { assetId },
  });

  return { ownedAssetInstances: data?.productAssetInstances ?? [], error, loading };
};
