import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Point2d } from '../../types';

const useStyles = makeStyles((theme) => ({
  filled: {
    fill: theme.palette.success.main,
  },
  empty: {
    fill: theme.palette.grey[100],
  },
}));

interface Circle {
  filled: boolean;
  position: Point2d;
}

interface CalculateProps {
  circleCount: number;
  filledCircles: number;
  circleDiameter: number;
  spacing: number;
}

function calculate({ circleCount, filledCircles, circleDiameter, spacing }: CalculateProps): Circle[] {
  const radius = circleDiameter / 2;
  return new Array(circleCount).fill(0).map((_, i) => {
    return {
      filled: i + 1 <= filledCircles,
      position: {
        y: radius,
        x: (i === 0 ? 0 : i * (radius * 2 + spacing)) + radius,
      },
    };
  });
}

interface DotGaugeProps {
  /**
   * 3 for stock level
   * 5 for liquidity score
   */
  dotCount: 3 | 5;
  filledDots: number;
  hideEmptyDots?: boolean;
}

export default function DotGauge({ dotCount, filledDots, hideEmptyDots = false }: DotGaugeProps): JSX.Element {
  const classes = useStyles();
  const circleDiameter = 12;
  const spacing = 4;
  const totalDots = hideEmptyDots ? filledDots : dotCount;
  const width = totalDots * (circleDiameter + spacing) - spacing;
  const height = circleDiameter;
  const circles = React.useMemo(
    () =>
      calculate({
        circleCount: hideEmptyDots ? filledDots : dotCount,
        circleDiameter,
        spacing,
        filledCircles: filledDots,
      }),
    [dotCount, filledDots, circleDiameter, spacing, hideEmptyDots],
  );

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {circles.map((c, i) => (
        <circle
          // eslint-disable-next-line react/no-array-index-key
          key={`circle-${i}`}
          cx={c.position.x}
          cy={c.position.y}
          r={circleDiameter / 2}
          className={c.filled ? classes.filled : classes.empty}
        />
      ))}
    </svg>
  );
}
