/* eslint-disable no-nested-ternary */
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet_deprecated.svg';
import { useWalletBalance } from '../../hooks';
import { useAvailableBalance } from '../../hooks/useAvailableBalance';
import { formatter } from '../../utils/currencyFormatter';
import { isNull } from '../../utils/isNull';
import Typography from '../Typography';
import TopUpModal from '../TopUpModal';
import WithdrawFundsModal from '../WithdrawFundsModal';
import { getKycStatus } from '../../services/auth';
import { KycStatus } from '../../__generated__/graphql';
import useMediaQuery from '../../hooks/useMediaQuery';
import CultXTooltip from '../CultxTooltip';
import useUserDetails from '../../hooks/useUserDetails';
import { AccountType } from '../../types/AccountType';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(7),
    background: theme.palette.card.background.dark,
    borderRadius: 10,
    paddingTop: 55,
    paddingBottom: 65,
    paddingLeft: 40,
    alignItems: 'center',
  },
  containerResponsive: {
    padding: 50,
  },
  verticalContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  verticalContainerResponsive: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    color: theme.palette.secondary.main,

    '@media (min-width: 768px)': {
      width: '60%',
    },
  },
  horizontalContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(4),
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),
    gap: theme.spacing(5),
  },
  iconContainer: {
    width: 120,
    height: 120,
    padding: '36px 20px',
    borderRadius: 10,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  button: {
    minWidth: 140,
  },
  skeleton: {
    background: theme.palette.grey[200],
  },
  subtitle: {
    textTransform: 'uppercase',
  },
  explainerLabel: {
    maxWidth: 200,
  },
}));

enum WalletSummaryModalType {
  Topup,
  WithdrawFunds,
}

interface WalletSummaryProps {
  className?: string;
}

export default function WalletSummary({ className = '' }: WalletSummaryProps): JSX.Element {
  const { withdrawFunds, kyc: kycFlagEnabled, desktopOnly } = useFlags();
  const classes = useStyles();
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { t } = useTranslation();
  const [modal, setModal] = useState<WalletSummaryModalType | null>(null);
  const { walletBalance, loading } = useWalletBalance();
  const kycStatus = getKycStatus();
  let allowTopUp = kycStatus === KycStatus.successful || kycStatus === KycStatus.pending;
  if (!kycFlagEnabled) {
    allowTopUp = true;
  }
  const { userDetails } = useUserDetails();
  const { availableBalance, loading: availableBalanceLoading } = useAvailableBalance();
  const isWhaleAccount = userDetails?.accountType === AccountType.Whale;
  // eslint-disable-next-line no-nested-ternary
  const tradingHeadroomTitle = userDetails?.mitConsentedFlag
    ? isWhaleAccount
      ? t('wallet:tradingHeadroomTooltipMITEnabledWhaleAccount')
      : t('wallet:tradingHeadroomTooltipMITEnabled')
    : t('wallet:tradingHeadroomTooltipMITDisabled');

  return (
    <div className={clsx(classes.container, { [classes.containerResponsive]: !desktopOnly }, className)}>
      {(!lessThanMd || desktopOnly) && (
        <div className={classes.iconContainer}>
          <WalletIcon fill={theme.palette.secondary.main} />
        </div>
      )}
      <div className={clsx(classes.verticalContainer, { [classes.verticalContainerResponsive]: !desktopOnly })}>
        <div>
          <Typography
            style={{ display: 'inline', marginRight: 3 }}
            className={classes.subtitle}
            variant="body1"
            zellarColour="textLabel"
          >
            {t('wallet:totalFunds')}
          </Typography>
          <CultXTooltip style={{ color: theme.palette.textLabel }} title={t('wallet:fundsOnAccountTooltip')} />
        </div>
        <div className={classes.horizontalContainer}>
          <Typography variant="h1">
            {loading ? (
              <Skeleton className={classes.skeleton} />
            ) : isNull(walletBalance) ? (
              t('common:currentlyUnavailable')
            ) : (
              formatter.format(walletBalance)
            )}
          </Typography>
          <Typography customVariant="body2" zellarColour="textLabel" className={classes.explainerLabel}>
            {t('wallet:walletDescription')}
          </Typography>
        </div>
        <div>
          <Typography
            style={{ display: 'inline', marginRight: 3 }}
            className={classes.subtitle}
            variant="body1"
            zellarColour="textLabel"
          >
            {t('product:detailsModal.availableBalance')}
          </Typography>
          <CultXTooltip
            aria-label="tradingheadroom"
            style={{ color: theme.palette.textLabel }}
            title={tradingHeadroomTitle}
          />
        </div>
        <Typography variant="h4">
          {availableBalanceLoading ? <Skeleton className={classes.skeleton} /> : formatter.format(availableBalance)}
        </Typography>

        <div className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="large"
            aria-label="topUp"
            disabled={!allowTopUp}
            onClick={() => setModal(WalletSummaryModalType.Topup)}
          >
            {t('wallet:topUp')}
          </Button>
          <Button
            className={classes.button}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => setModal(WalletSummaryModalType.WithdrawFunds)}
          >
            {t('wallet:withdrawFunds')}
          </Button>
        </div>
      </div>
      {modal === WalletSummaryModalType.Topup && (
        <TopUpModal open={modal === WalletSummaryModalType.Topup} onClose={() => setModal(null)} />
      )}
      {withdrawFunds && modal === WalletSummaryModalType.WithdrawFunds && (
        <WithdrawFundsModal onClose={() => setModal(null)} />
      )}
    </div>
  );
}
