import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Divider from '../../Divider';
import WineDetails from './WineDetails';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}));

interface WineDetailsSectionProps {
  assetId?: number;
  isProductError?: boolean;
}

export default function WineDetailsSection({ assetId, isProductError }: WineDetailsSectionProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <WineDetails assetId={assetId} isProductError={isProductError} />
      <Divider customVariant="primary" />
    </div>
  );
}
