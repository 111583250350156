import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo, useState } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { usePortfolioMarketValueData } from '../../hooks/usePortfolioMarketValueData';
import { DateRangeVariant } from '../../types/DateRangeVariant';
import calculateDateRange from '../../utils/calculateDateRange';
import { logError } from '../../utils/logger';
import normalizeChartDateDataWithMarker from '../../utils/normalizeChartDateDataWithMarker';
import selectChartExtremes from '../../utils/selectChartExtremes';
import DateRangePicker from '../ProductLineChart/DateRangePicker';
import PortfolioValueLineChart from './PortfolioValueLineChart';

const dateRangeVariants = ['6M', '1Y', '5Y', '10Y', 'YTD'] as DateRangeVariant[];
const useStyles = makeStyles((theme) => ({
  footer: {
    marginLeft: theme.spacing(3),
  },
}));

const placeholderMin = 10;
const placeholderMax = 10000;
const placeholderData = {
  '2021-01-01': 10,
  '2021-01-10': 1000,
  '2021-01-20': 900,
  '2021-01-28': 2000,
  '2021-02-01': 2500,
  '2021-02-10': 2200,
  '2021-02-20': 3000,
  '2021-02-28': 2800,
  '2021-03-10': 3700,
  '2021-03-20': 5100,
  '2021-03-28': 5000,
  '2021-04-01': 5800,
  '2021-04-10': 6500,
  '2021-04-20': 6100,
  '2021-04-28': 6700,
  '2021-05-10': 7800,
  '2021-05-20': 8200,
  '2021-05-28': 7500,
  '2021-06-01': 8200,
  '2021-06-10': 9500,
  '2021-06-20': 9800,
};

interface Props {
  hasFirstTrade: boolean;
}

export default function PortfolioMarketValueLineChart({ hasFirstTrade }: Props): JSX.Element {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeVariant>('6M');
  const [dateFrom, setDateFrom] = useState<string | undefined>(calculateDateRange(selectedDateRange).from);
  const [dateTo, setDateTo] = useState<string | undefined>(calculateDateRange(selectedDateRange).to);
  const classes = useStyles();
  const theme = useTheme();
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { portfolioMarketData, loading, previousData, portfolioMarketValueLoadError } = usePortfolioMarketValueData({
    dateFrom,
    dateTo,
  });
  const [min, max] = useMemo(
    () => selectChartExtremes(portfolioMarketData || previousData),
    [portfolioMarketData, previousData],
  );

  const normalisedData = hasFirstTrade
    ? normalizeChartDateDataWithMarker(portfolioMarketData || previousData)
    : normalizeChartDateDataWithMarker(placeholderData);

  function handleDateRangeChanged(dateRangeVariant: DateRangeVariant) {
    setSelectedDateRange(dateRangeVariant);
    const range = calculateDateRange(dateRangeVariant);
    setDateFrom(range.from);
    setDateTo(range.to);
  }

  if (portfolioMarketValueLoadError) {
    logError({
      originalError: portfolioMarketValueLoadError,
      error: new Error('Error when loading portfolio value data'),
      filename: 'PortfolioMarketValueLineChart',
    });
  }

  return (
    <div>
      <PortfolioValueLineChart
        data={normalisedData}
        min={hasFirstTrade ? min : placeholderMin}
        max={hasFirstTrade ? max : placeholderMax}
        loading={loading}
        disabled={!hasFirstTrade}
      />
      {greaterThanSm && (
        <div className={classes.footer}>
          <DateRangePicker
            dateRangeUpdated={handleDateRangeChanged}
            selectedDateRangeVariant={selectedDateRange}
            variants={dateRangeVariants}
            disableAllChips={!hasFirstTrade}
          />
        </div>
      )}
    </div>
  );
}
