import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { uuid } from '../../utils/uuid';

interface RowSkeletonProps {
  columnCount?: number;
  rowCount?: number;
  rowHeight?: number;
}

// ts-prune-ignore-next
export default function RowSkeleton({ columnCount, rowHeight = 70, rowCount = 6 }: RowSkeletonProps) {
  return (
    <>
      {new Array(rowCount).fill(0).map(() => (
        <TableRow key={uuid()}>
          <TableCell colSpan={columnCount}>
            <Skeleton width="100%" variant="rectangular" height={rowHeight} aria-label="display-row-skeleton" />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
