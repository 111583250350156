/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery, useSubscription } from '@apollo/client';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import React from 'react';
import { SortDirection } from '../components/Table/types';
import { SEARCH_ACTIVE_MARKETS } from '../graphql/queries/searchActiveMarkets';
import { ACTIVE_MARKET_SUBSCRIPTION } from '../graphql/subscriptions/activeMarket';
import { logError } from '../utils/logger';
import { ActiveMarketSortObject } from '../views/Discover/components/ActiveMarkets/types';
import { ActiveMarket, ActiveMarketOrder, SearchActiveMarketsQuery } from '../__generated__/graphql';
import { isNullOrUndefined } from '../utils/isNullOrUndefined';

interface UseActiveMarketAssetProps {
  from: number;
  pageSize: number;
  sortBy?: keyof ActiveMarketSortObject;
  sortDirection?: SortDirection;
  wineName?: string;
  isLoggedIn?: boolean;
}

function selectResults(data: SearchActiveMarketsQuery | undefined): ActiveMarket[] {
  if (isUndefined(data)) {
    return [];
  }

  return data.searchActiveMarkets.results as ActiveMarket[];
}

function selectOrderData(order: ActiveMarketOrder | null | undefined): ActiveMarketOrder {
  if (isNullOrUndefined(order)) {
    return {
      createdDate: null,
      price: null,
      __typename: 'ActiveMarketOrder',
    };
  }

  return {
    ...order,
    __typename: 'ActiveMarketOrder',
  };
}

type SelectDirectionArgs = {
  sortDirection?: SortDirection;
  sortBy?: keyof ActiveMarketSortObject;
};
function selectDirection({ sortBy, sortDirection }: SelectDirectionArgs) {
  if (!sortDirection) {
    return undefined;
  }

  if (sortBy === 'BestBids' || sortBy === 'BestOffers' || sortBy === 'LatestBids' || sortBy === 'LatestOffers') {
    return undefined;
  }

  return sortDirection;
}

export function useActiveMarketAssets({
  from,
  pageSize,
  sortBy,
  sortDirection,
  wineName,
  isLoggedIn = false,
}: UseActiveMarketAssetProps) {
  const [results, setResults] = React.useState<ActiveMarket[]>([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const {
    data,
    loading: _loading,
    error,
    fetchMore,
  } = useQuery(SEARCH_ACTIVE_MARKETS, {
    variables: {
      from,
      pageSize,
      sortFilter: sortBy,
      sortOrder: selectDirection({ sortDirection, sortBy }),
      wineName,
    },
    ...(isLoggedIn ? {} : { context: { serviceName: 'insecure' } }),
  });

  const { data: subscriptionData, error: subscriptionError } = useSubscription(ACTIVE_MARKET_SUBSCRIPTION, {
    ...(isLoggedIn ? {} : { context: { serviceName: 'insecure' } }),
    skip: !isLoggedIn,
  });

  async function _fetchMore({ from: _from, pageSize: _pageSize }: Partial<UseActiveMarketAssetProps>) {
    try {
      const _results = await fetchMore({
        variables: {
          from: isUndefined(_from) ? from : _from,
          pageSize: isUndefined(_pageSize) ? pageSize : _pageSize,
          sortFilter: sortBy,
          sortOrder: selectDirection({ sortDirection, sortBy }),
          wineName,
        },
      });
      setLoading(_results.loading);
      setResults(_results.data.searchActiveMarkets.results as ActiveMarket[]);
      setTotal(_results.data.searchActiveMarkets.total);
    } catch (e) {
      console.error(e);
      logError({ error: new Error('Failed to fetch active market assets'), filename: 'useActiveMarketAssets.ts' });
    }
  }

  React.useEffect(() => {
    if (subscriptionError) {
      logError({
        error: new Error('ACTIVE_MARKET_SUBSCRIPTION failed'),
        originalError: subscriptionError,
        filename: 'useActiveMarketAssets',
      });
    }
  }, [subscriptionError]);

  React.useEffect(() => {
    if (subscriptionData) {
      const assetInMemory = results.map((r) => r.assetId).includes(subscriptionData.getActiveMarket.result.assetId);
      if (!assetInMemory) {
        return;
      }

      if (subscriptionData.getActiveMarket.result.toBeRemove) {
        // TODO: come back and make this work.
        // cache.evict({
        //   id: cache.identify({
        //     __ref: subscriptionData.getActiveMarket.assetId.toString(),
        //     __typename: 'ActiveMarket',
        //   }),
        // });

        // If one needs to be removed, we should refetch the current page, otherwise there would be
        // one fewer results left on the page if we simply remove it. See comment on
        // https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/12289
        _fetchMore({ from, pageSize });
      } else {
        // replace asset
        const _r = results.map((r) => {
          if (r.assetId !== subscriptionData.getActiveMarket.result.assetId) {
            return r;
          }

          // We can safely assume that if `toBeRemoved` is false/null then these values will not be null
          // (if they are not nullable in the corresponding query schema)
          const mapped: ActiveMarket = {
            ...subscriptionData.getActiveMarket.result,
            __typename: 'ActiveMarket',

            // these fields are static
            unitCount: r.unitCount,
            unitSize: r.unitSize,
            imageFileName: r.imageFileName,
            region: r.region,
            vintage: r.vintage,
            wineName: r.wineName,
            lwin18: '',

            // dynamic data
            highestBid: selectOrderData(subscriptionData.getActiveMarket.result.highestBid),
            lowestOffer: selectOrderData(subscriptionData.getActiveMarket.result.lowestOffer),
            marketValue: subscriptionData.getActiveMarket.result.marketValue!,
          };
          return mapped;
        });
        setResults(_r);
      }
      setTotal(subscriptionData.getActiveMarket.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData]);

  React.useEffect(() => {
    if (_loading !== loading) {
      setLoading(_loading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_loading]);

  React.useEffect(() => {
    setResults(selectResults(data));

    if (!isUndefined(data) && data.searchActiveMarkets.total !== total) {
      setTotal(data.searchActiveMarkets.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    _fetchMore({ from, pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, pageSize]);

  return {
    results,
    total,
    loading,
    error: error || subscriptionError,
  };
}
