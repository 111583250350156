import { graphql } from '../../__generated__';

export const UPDATE_NOTIFICATION = graphql(`
  mutation UpdateNotification($id: String!, $payload: JSON!) {
    updateNotification(id: $id, payload: $payload) {
      id
      summary
      description
      isRead
      createdDateTime
      updatedDateTime
    }
  }
`);
