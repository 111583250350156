import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import MuiListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '../Typography';

const useStyles = makeStyles(() => ({
  icon: {
    justifyContent: 'center',
  },
  center: {
    justifyContent: 'center',
  },
}));

interface ListItemProps {
  nameTranslated: string;
  link: string;
  icon?: React.ReactNode;
}

export default function ListItem({ nameTranslated, link, icon }: ListItemProps): JSX.Element {
  const classes = useStyles();
  return (
    <MuiListItem component={RRLink} key={nameTranslated} to={link} button className={!icon ? classes.center : ''}>
      {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
      <Typography customVariant="subtitle1Bold">{nameTranslated}</Typography>
    </MuiListItem>
  );
}
