import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GET_SPREAD_PERCENTAGE } from '../../../graphql/queries/spreadValuePercentage';
import { logError } from '../../../utils/logger';
import Typography from '../../Typography';
import { isLoggedInVar } from '../../../graphql/cache';
import { deltaPercentage } from '../../../utils/deltaPercentage';

export default function PercentageDifference({ assetId }: { assetId: number }): JSX.Element {
  const { t } = useTranslation();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const gqLContext = isLoggedIn
    ? {}
    : {
        context: {
          serviceName: 'insecure',
        },
      };
  const { data, loading, error } = useQuery(GET_SPREAD_PERCENTAGE, {
    variables: { assetId },
    ...gqLContext,
  });

  const { zellarColour, deltaSymbol } = deltaPercentage(data?.omsSpreadByAssetId.percentageDifference ?? 0);
  if (loading) {
    return <Typography>...</Typography>;
  }
  if (error) {
    logError({ error, filename: 'PercentageDifference', additionalInfo: { assetId } });
    return <>{t('common:somethingWentWrong')}</>;
  }

  return (
    <Typography customVariant="subtitle1Bold" zellarColour={zellarColour}>
      {deltaSymbol}
      {data?.omsSpreadByAssetId.percentageDifference}%
    </Typography>
  );
}
