export type Tokens = {
  refreshToken: string;
  accessToken: string;
  userToken: string;
};

export function getAccessToken(): string | null {
  return localStorage.getItem('ACCESS_TOKEN');
}

export function getRefreshToken(): string | null {
  return localStorage.getItem('REFRESH_TOKEN');
}

export function getUserToken(): string | null {
  return localStorage.getItem('USER_TOKEN');
}

export function getKycStatus(): string | null {
  return localStorage.getItem('KYC_STATUS');
}

export function updateAccessToken(token: string): void {
  localStorage.setItem('ACCESS_TOKEN', token);
}

export function updateKycStatus(kycStatus: string): void {
  localStorage.setItem('KYC_STATUS', kycStatus);
}

export function updateRefreshToken(token: string): void {
  localStorage.setItem('REFRESH_TOKEN', token);
}

export function updateUserToken(token: string): void {
  localStorage.setItem('USER_TOKEN', token);
}

export function signOut(): void {
  localStorage.clear();
}
