import { DateRangeVariant } from '../types/DateRangeVariant';

export interface DateRange {
  /**
   * e.g. 2021-01-01
   */
  from: string;

  /**
   * e.g. 2021-01-01
   */
  to: string;
}

/**
 * Formats the given date and returns a string
 * in the form YYYY-MM-DD.
 *
 * @param date The date you wish to format
 */
function format(date: Date): string {
  return date.toISOString().split('T')[0];
}

// eslint-disable-next-line consistent-return
export default function calculateDateRange(rangeVariant: DateRangeVariant): DateRange {
  const now = new Date();
  const offset = now.getTimezoneOffset();
  const pointInTime = new Date(now.getTime() - offset * 60 * 1000);
  const to = format(pointInTime);

  switch (rangeVariant) {
    case '1M': {
      pointInTime.setMonth(pointInTime.getMonth() - 1);
      const from = format(pointInTime);
      return { from, to };
    }

    case '3M': {
      pointInTime.setMonth(pointInTime.getMonth() - 3);
      const from = format(pointInTime);
      return { from, to };
    }

    case '6M': {
      pointInTime.setMonth(pointInTime.getMonth() - 6);
      const from = format(pointInTime);
      return { from, to };
    }

    case '1Y': {
      pointInTime.setFullYear(pointInTime.getFullYear() - 1);
      const from = format(pointInTime);
      return { from, to };
    }

    case '5Y': {
      pointInTime.setFullYear(pointInTime.getFullYear() - 5);
      const from = format(pointInTime);
      return { from, to };
    }

    case '10Y': {
      pointInTime.setFullYear(pointInTime.getFullYear() - 10);
      const from = format(pointInTime);
      return { from, to };
    }

    case 'YTD': {
      const from = format(new Date(Date.UTC(pointInTime.getFullYear(), 0, 1)));
      return { from, to };
    }

    case 'MAX': {
      return { from: '1970-01-01', to };
    }
  }
}
