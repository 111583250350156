import { TextColour } from '../components/Typography';
import { colours } from '../theme/light/palette';

export const deltaPercentage = (
  percentage: number | undefined | null,
): {
  zellarColour: TextColour | undefined;
  deltaSymbol: string | null;
  graphColorNumber: 0 | 1 | 2;
  textColor: 'inherit' | 'textPrimary' | 'textSecondary';
  backGroundPillColor: string;
} => {
  if (typeof percentage === 'number') {
    if (percentage === 0) {
      return {
        zellarColour: 'textInformation',
        deltaSymbol: null,
        graphColorNumber: 1,
        textColor: 'inherit',
        backGroundPillColor: colours.grey,
      };
    }
    if (percentage > 0) {
      return {
        zellarColour: 'textSuccess',
        deltaSymbol: '+',
        graphColorNumber: 0,
        textColor: 'textPrimary',
        backGroundPillColor: colours.brightGreen,
      };
    }
    if (percentage < 0) {
      return {
        zellarColour: 'textFailure',
        deltaSymbol: '',
        graphColorNumber: 2,
        textColor: 'textSecondary',
        backGroundPillColor: colours.red,
      };
    }
  }

  return {
    zellarColour: 'textInformation',
    deltaSymbol: null,
    graphColorNumber: 1,
    textColor: 'inherit',
    backGroundPillColor: colours.grey,
  };
};
