/* eslint-disable @typescript-eslint/no-use-before-define */
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GraphQLError } from 'graphql';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SelectedRowsProvider } from '../../context/selectedRowsContext';
import { selectErrorMessage } from '../../graphql/selectors/selectErrorMessage';
import useMediaQuery from '../../hooks/useMediaQuery';
import { SearchWinesFacets } from '../../views/Search/selectors';
import EmptyPlaceholder from '../EmptyPlaceholder';
import Table from '../Table';
import Toolbar, { ViewType } from '../Table/Toolbar';
import { Headers, SortDirection } from '../Table/types';
import TablePagination from '../TablePagination';
import SearchWineVintageCard from '../WineCard/SearchWineVintageCard';
import ExpandedContent from './ExpandedContent';
import ErrorPlaceholder from '../ErrorPlaceholder';
import { SearchResultsTableData, selectSortOptions, selectTableData } from './selectors';
import { WineVintage } from './types';
import WineCardSkeleton from '../WineCardSkeleton';
import { uuid } from '../../utils/uuid';
import OrderModal from '../OrderModal';
import BuyNowModal from '../BuyNowModal';
import { useQueryParameters } from '../../hooks';
import { Modal } from '../../types/Modal';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';
import CardPaymentErrorModal from '../CardPaymentCallbackModal/errorContent';
import CardPaymentSuccessModal from '../CardPaymentCallbackModal/successContent';
import { Mode } from '../OrderModal/types';
import CalculationToolTip from '../CalculationToolTip';
import { TableRefactorFlagSet } from '../../types/FeatureFlags';
import SearchResultsTable from './Table';

const useStyles = makeStyles((theme) => ({
  cardList: {
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
    padding: '0 10px',
  },
  card: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: `calc(50% - ${theme.spacing(2)})`,
    },

    [theme.breakpoints.up('xl')]: {
      width: `calc(33% - ${theme.spacing(2.2)})`,
    },
  },
}));

interface SearchResultsProps {
  className?: string;
  results: WineVintage[];

  /**
   * The total number of results, across all pages of data.
   */
  resultsCount: number;

  loading: boolean;
  error: null | string;
  rowsPerPage: number;
  page: number;
  pageSizeVariants: number[];
  onRowsPerPageChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePage: (event: unknown, newPage: number) => void;
  activeSortKey: keyof SearchWinesFacets | null;
  sortDirection: SortDirection | null;
  onSortChanged: (key: keyof SearchWinesFacets, direction?: SortDirection) => void;
  onFiltersOpened: () => void;
  activeFiltersCount: number;
}

function SearchResults({
  results,
  resultsCount,
  loading,
  error,
  rowsPerPage,
  page,
  pageSizeVariants,
  onChangePage,
  onRowsPerPageChanged,
  activeSortKey,
  sortDirection,
  onSortChanged,
  onFiltersOpened,
  activeFiltersCount,
  className = '',
}: SearchResultsProps): JSX.Element {
  const theme = useTheme();
  const { clickableTradeTiles13816, showPerformaceDataInSearchResults } = useFlags();
  const { tableRefactor } = useFlags<{ tableRefactor?: TableRefactorFlagSet }>();
  const queryParams = useQueryParameters();
  const modal = queryParams.get('modal');
  const assetId = queryParams.get('assetId');
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { showTopUpBuySuccessSummaryModal } = useCardPaymentCallback();
  const lessThanLg = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));
  const classes = useStyles();
  const { t } = useTranslation();
  const tableData = React.useMemo(
    () =>
      selectTableData({
        wineVintageArray: results,
        onAddToWatchlistClicked: handleAddToWatchlist,
        onRemoveFromWatchlistClicked: handleRemoveFromWatchlist,
        onWatchlistButtonError: handleWatchlistButtonError,
        onBidClicked: handleOpenBidModalClicked,
        onBuyNowClicked: handleOpenBuyNowModalClicked,
        onOfferClicked: handleOpenOfferModalClicked,
        clickableTradeTiles13816,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results],
  );
  const [viewType, setViewType] = React.useState<ViewType>('table');
  const { showCarPaymentErrorModal, showCarPaymentSuccessModal } = useCardPaymentCallback();

  let headers: Headers<Omit<SearchResultsTableData, 'rowId'>, keyof SearchWinesFacets> = {
    wineName: {
      title: t('market:table.wineName'),
      id: 'WineName',
      onClick: onSortChanged,
      active: activeSortKey === 'WineName',
      direction: activeSortKey === 'WineName' ? sortDirection! : undefined,
    },
    vintage: {
      title: t('common:vintage'),
      id: 'Vintage',
      onClick: onSortChanged,
      active: activeSortKey === 'Vintage',
      direction: activeSortKey === 'Vintage' ? sortDirection! : undefined,
    },
    score: {
      title: t('product:score'),
      id: 'Score',
      active: activeSortKey === 'Score',
      onClick: onSortChanged,
      direction: activeSortKey === 'Score' ? sortDirection! : undefined,
    },
    unitSize: {
      title: t('common:unitSize'),
    },
    marketValue: {
      title: t('product:marketValue'),
      id: 'MarketValue',
      active: activeSortKey === 'MarketValue',
      onClick: onSortChanged,
      direction: activeSortKey === 'MarketValue' ? sortDirection! : undefined,
      extraElement: <CalculationToolTip title={t('market:marketValueCalculation12x75cl')} />,
    },
    highestBid: {
      title: t('product:trading.highestBid'),
    },
    lowestOffer: {
      title: t('product:trading.lowestOffer'),
    },
    spread: {
      title: t('product:trading.spread'),
    },
  };

  if (showPerformaceDataInSearchResults) {
    headers = {
      ...headers,
      performance: {
        title: t('market:performanceLTM'),
        id: 'PercentageDifference',
        active: activeSortKey === 'PercentageDifference',
        onClick: onSortChanged,
        direction: activeSortKey === 'PercentageDifference' ? sortDirection! : undefined,
      },
    };
  }

  function handleAddToWatchlist(): void {
    enqueueSnackbar(t('product:watchList.addedMessage'), {
      variant: 'info',
    });
  }

  function handleRemoveFromWatchlist(): void {
    enqueueSnackbar(t('product:watchList.deletedMessage'), {
      variant: 'info',
    });
  }

  function handleWatchlistButtonError(errors: readonly GraphQLError[]): void {
    enqueueSnackbar(selectErrorMessage(errors), {
      variant: 'error',
    });
  }

  function handleOpenBuyNowModalClicked(rowId: string) {
    history.push(`${history.location.pathname}${history.location.search}&modal=${Modal.BuyNow}&assetId=${rowId}`);
  }

  function handleOpenBidModalClicked(rowId: string) {
    history.push(`${history.location.pathname}${history.location.search}&modal=${Modal.Bid}&assetId=${rowId}`);
  }

  function handleOpenOfferModalClicked(rowId: string) {
    history.push(`${history.location.pathname}${history.location.search}&modal=${Modal.Offer}&assetId=${rowId}`);
  }

  function handleCloseModal() {
    queryParams.delete('modal');
    queryParams.delete('assetId');
    history.push({ pathname: history.location.pathname, search: queryParams.toString(), hash: history.location.hash });
  }

  const sortOptions = React.useMemo(
    () => selectSortOptions(sortDirection, activeSortKey, t),
    [sortDirection, activeSortKey, t],
  );

  function handleToolbarSortChanged(id: string): void {
    const opt = sortOptions.find((sO) => sO.id === id);
    if (isUndefined(opt)) {
      return;
    }

    onSortChanged(opt.key, opt.direction);
  }

  function closeCardPaymentErrorModal() {
    history.push({ pathname: history.location.pathname, search: history.location.search, hash: '' });
  }

  function closeCardPaymentSuccessModal() {
    history.push({ pathname: history.location.pathname, search: history.location.search, hash: '' });
  }

  const TableComponent = tableRefactor?.searchResults ? (
    <SearchResultsTable
      wineVintages={results}
      sortColumn={activeSortKey}
      sortDirection={sortDirection}
      onSortChanged={onSortChanged}
      loading={loading}
      error={error ?? undefined}
      onAddToWatchlist={handleAddToWatchlist}
      onRemoveFromWatchlist={handleRemoveFromWatchlist}
      onWatchlistError={handleWatchlistButtonError}
      onBuyNowClicked={handleOpenBuyNowModalClicked}
      onBidClicked={handleOpenBidModalClicked}
      onOfferClicked={handleOpenOfferModalClicked}
    />
  ) : (
    <Table
      data={tableData}
      headers={headers}
      emptyTableText={t('search:noSearchResults')}
      loading={loading}
      error={error}
      SubContent={ExpandedContent}
    />
  );

  return (
    <div className={className}>
      <Toolbar
        resultCount={resultsCount}
        viewType={viewType}
        onChangeViewType={setViewType}
        hideChangeViewType={lessThanLg}
        onSortChanged={handleToolbarSortChanged}
        sortOptions={sortOptions}
        openFiltersClicked={onFiltersOpened}
        activeFiltersCount={activeFiltersCount}
      />
      {viewType === 'card' || lessThanLg ? (
        <div className={classes.cardList}>
          {loading &&
            !error &&
            new Array(20).fill(0).map(() => <WineCardSkeleton className={classes.card} key={uuid()} />)}
          {error && <ErrorPlaceholder error={error} />}
          {!results.length && !loading && !error && <EmptyPlaceholder label={t('search:noSearchResults')} />}
          {!error &&
            !loading &&
            results.length > 0 &&
            results.map((wineVintage) => (
              <SearchWineVintageCard
                key={wineVintage.wineVintageId}
                className={classes.card}
                wineVintage={wineVintage}
                actions={{
                  trade: {
                    onBidClicked: handleOpenBidModalClicked,
                    onOfferClicked: handleOpenOfferModalClicked,
                    onBuyClicked: handleOpenBuyNowModalClicked,
                    assetId: Number(wineVintage.defaultAssetId),
                    condensed: true,
                  },
                  watchlist: {
                    onAdd: handleAddToWatchlist,
                    onRemove: handleRemoveFromWatchlist,
                    onError: handleWatchlistButtonError,
                  },
                }}
              />
            ))}
        </div>
      ) : (
        TableComponent
      )}
      <TablePagination
        component="div"
        rowsPerPageOptions={pageSizeVariants}
        count={resultsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onRowsPerPageChanged}
      />
      {assetId && modal === Modal.Bid && (
        <OrderModal
          assetId={Number(assetId)}
          open={modal === Modal.Bid}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="bid"
        />
      )}
      {assetId && modal === Modal.Offer && (
        <OrderModal
          assetId={Number(assetId)}
          open={modal === Modal.Offer}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="offer"
        />
      )}
      {assetId && modal === Modal.BuyNow && (
        <BuyNowModal assetId={Number(assetId)} open={modal === Modal.BuyNow} onClose={handleCloseModal} />
      )}
      {assetId && showTopUpBuySuccessSummaryModal && (
        <BuyNowModal assetId={Number(assetId)} open onClose={handleCloseModal} />
      )}

      {showCarPaymentErrorModal && (
        <CardPaymentErrorModal open={showCarPaymentErrorModal} onClose={closeCardPaymentErrorModal} />
      )}
      {showCarPaymentSuccessModal && (
        <CardPaymentSuccessModal open={showCarPaymentSuccessModal} onClose={closeCardPaymentSuccessModal} />
      )}
    </div>
  );
}

export default function SearchResultsWithContext(props: SearchResultsProps): JSX.Element {
  return (
    <SelectedRowsProvider>
      <SearchResults {...props} />
    </SelectedRowsProvider>
  );
}
