import React from 'react';
import { ModalStep } from './ContentSwitcher';

interface DialogContentSwitcherUnstyledProps {
  InitialComponent?: JSX.Element;
  SuccessComponent?: JSX.Element;
  ProcessingComponent?: JSX.Element;
  SummaryComponent?: JSX.Element;
  FailureComponent?: JSX.Element;
  modalStep: ModalStep;
}

export default function DialogContentSwitcherUnstyled({
  InitialComponent,
  SummaryComponent,
  ProcessingComponent,
  SuccessComponent,
  FailureComponent,
  modalStep,
}: DialogContentSwitcherUnstyledProps): JSX.Element {
  return (
    <>
      {modalStep === ModalStep.Initial && InitialComponent && InitialComponent}
      {modalStep === ModalStep.Summary && SummaryComponent}
      {modalStep === ModalStep.Processing && ProcessingComponent && ProcessingComponent}
      {modalStep === ModalStep.Success && SuccessComponent}
      {modalStep === ModalStep.Failure && FailureComponent}
    </>
  );
}
