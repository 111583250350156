import { useApolloClient } from '@apollo/client';
import { isLoggedInVar } from '../graphql/cache';
import { signOut } from '../services/auth';
import { clearLoggerUser } from '../utils/logger';

type UseSignOut = () => Promise<void>;

export function useSignOut(): UseSignOut {
  const client = useApolloClient();

  return async () => {
    // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
    await client.clearStore();
    client.cache.gc();

    signOut();
    isLoggedInVar(false);
    clearLoggerUser();
  };
}
