import { useMutation, useQuery } from '@apollo/client';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Namespace, Resources, TFunction, useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DELETE_FROM_EXTERNAL_PORTFOLIO } from '../../../graphql/mutations/deleteFromExternalPortfolio';
import { EXTERNAL_PORTFOLIO_INSTANCE_QUERY } from '../../../graphql/queries/externalPortfolioInstance';
import { formatter } from '../../../utils/currencyFormatter';
import { humanReadableDate } from '../../../utils/humanReadableDate';
import { logError } from '../../../utils/logger';
import SubTable from '../../Table/SubTable';
import { BasicCell, Headers, Row } from '../../Table/types';
import TransferAssetModal from '../../TransferAssetModal';
import { ExternalPortfolioInstanceQuery } from '../../../__generated__/graphql';
import { TableRefactorFlagSet } from '../../../types/FeatureFlags';
import ExternalPortfolioSubTable from '../../ExternalPortfolioTable/SubTable';

interface TableData extends Row {
  location: BasicCell;
  currentLocation: BasicCell;
  purchasePrice: BasicCell;
  purchaseDate: BasicCell;
}
interface SelectTableDataProps {
  rawData: ExternalPortfolioInstanceQuery | undefined;
  t: TFunction<Namespace<keyof Resources>>;
  handleTransferRequested: (rowId: string) => void;
  handleDeleteClicked: (rowId: string) => void;
}
function selectTableData({ rawData, t, handleDeleteClicked }: SelectTableDataProps): TableData[] {
  if (isUndefined(rawData) || isUndefined(rawData.externalPortfolioUserAssetInstances)) {
    return [];
  }

  const { externalPortfolioUserAssetInstances } = rawData;
  return externalPortfolioUserAssetInstances.map((instance) => ({
    location: {
      // TODO: mocked
      mainContent: t('common:unavailable'),
    },
    currentLocation: {
      // TODO: mocked
      mainContent: t('common:unavailable'),
    },
    purchasePrice: {
      mainContent: formatter.format(instance.purchasePrice ?? 0),
    },
    purchaseDate: {
      mainContent: instance.purchaseDate ? humanReadableDate(instance.purchaseDate.slice(0, 10)) : '--',
    },
    rowId: instance.id.toString(),
    actions: {
      delete: { onDeleteClicked: handleDeleteClicked },
    },
  }));
}

interface AssetInstanceTableProps {
  assetId: number;
}

export default function ExternalPortfolioInstanceTable({ assetId }: AssetInstanceTableProps): JSX.Element {
  const { t } = useTranslation();
  const { tableRefactor } = useFlags<{ tableRefactor?: TableRefactorFlagSet }>();
  const [modalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading,
    data,
    error: externalPortfolioInstanceQueryError,
  } = useQuery(EXTERNAL_PORTFOLIO_INSTANCE_QUERY, {
    variables: { assetId },
    pollInterval: 3000,
  });
  const [deleteExternalPortfolioAssetInstance] = useMutation(DELETE_FROM_EXTERNAL_PORTFOLIO);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleTransferRequested(_: string): void {
    setModalOpen(true);
  }

  async function handleDeleteClicked(rowId: string | number) {
    const { errors } = await deleteExternalPortfolioAssetInstance({
      variables: {
        externalPortfolioId: Number(rowId),
      },
    });
    if (errors) {
      logError({
        originalError: errors[0],
        error: new Error(`Failed to delete asset unit from external portfolio`),
        filename: 'ExternalPortfolioInstanceTable',
        additionalInfo: { rowId },
      });
      enqueueSnackbar(t('common:somethingWentWrong'), {
        variant: 'error',
      });
    } else {
      enqueueSnackbar(t('portfolio:externalPortfolio.deleteSuccess'), {
        variant: 'success',
      });
    }
  }

  if (externalPortfolioInstanceQueryError) {
    logError({
      originalError: externalPortfolioInstanceQueryError,
      error: new Error('externalPortfolioUserAssetInstances query failed'),
      filename: 'ExternalPortfolioInstanceTable',
    });
    return <div>{externalPortfolioInstanceQueryError.message}</div>;
  }

  const tableData = selectTableData({ rawData: data, t, handleDeleteClicked, handleTransferRequested });
  const headers: Headers<Omit<TableData, 'rowId'>> = {
    location: { title: t('portfolio:table.headers.location') },
    currentLocation: { title: t('portfolio:externalPortfolio.currentLocation') },
    purchasePrice: { title: t('portfolio:table.headers.purchasePrice') },
    purchaseDate: { title: t('portfolio:externalPortfolio.purchaseDate') },
  };

  const TableComponent = tableRefactor?.externalPortfolio ? (
    <ExternalPortfolioSubTable
      instances={data?.externalPortfolioUserAssetInstances}
      loading={loading}
      error={externalPortfolioInstanceQueryError ?? null}
      onDeleteClicked={handleDeleteClicked}
    />
  ) : (
    <SubTable
      error={externalPortfolioInstanceQueryError ?? null}
      headers={headers}
      loading={loading}
      rows={tableData}
      emptyTableText={t('common:empty')}
    />
  );

  return (
    <>
      {TableComponent}
      {modalOpen && <TransferAssetModal assetId={assetId} open={modalOpen} onClose={() => setModalOpen(false)} />}
    </>
  );
}
