import makeStyles from '@mui/styles/makeStyles';
import { colours } from '../../theme/light/palette';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: '400px',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
  cardItem: {
    background: colours.white,
    borderColor: theme.palette.dividers.primary,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textDisabled: {
    color: '#B8B8B8',
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  textCenter: {
    textAlign: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  marginBottom: {
    marginBottom: 20,
  },
}));
