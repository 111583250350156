import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { humanReadableDate } from '../../utils/humanReadableDate';
import { OrderDirection } from '../OrderBookModal/types';
import { CellOpacity } from './constants';
import { Asset, Order, OrderBookCellData, OrderBookRow } from './types';
import { OrderBookQuery } from '../../__generated__/graphql';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

function mapCell(order: Order): OrderBookCellData {
  return {
    price: order.price,
    source: order.source,
    quantity: order.quantity,
  };
}

export function selectAssets(data: OrderBookQuery | undefined): Asset[] {
  if (isUndefined(data)) {
    return [];
  }

  if (isNullOrUndefined(data.productWineVintage)) {
    return [];
  }

  return data.productWineVintage.assets.map((asset) => {
    const date: string | null = asset.tradingInfo.lastTraded ? humanReadableDate(asset.tradingInfo.lastTraded) : null;

    return {
      id: asset.id,
      tradingInfo: { ...asset.tradingInfo, lastTraded: date },
      unitCount: asset.unitCount,
      unitSize: asset.unitSize,
    } as Asset;
  });
}

function combineOrders(orders: Order[]): Order[] {
  const combinedDuplicateOrders: Order[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const order of orders) {
    if (order.source === 'WineSearcher') {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (combinedDuplicateOrders.some((o) => o.price === order.price)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const matchingOrders = orders.filter((o) => o.price === order.price);
    const combined: Order = matchingOrders.reduce((prev, curr) => {
      // We can safely assert that quantity exists as we've already ruled out wine searcher data.
      return { ...prev, quantity: prev.quantity! + curr.quantity! };
    });
    combinedDuplicateOrders.push(combined);
  }
  return combinedDuplicateOrders;
}

function selectOrders(data: Readonly<OrderBookQuery> | undefined, direction: OrderDirection): Order[] {
  if (isUndefined(data)) {
    return [];
  }

  if (isNullOrUndefined(data.productWineVintage)) {
    return [];
  }

  return direction === OrderDirection.Bids
    ? data.productWineVintage.assets
        .map((asset) => {
          const orders: Order[] = asset.openOrders
            .filter((order) => order.direction === OrderDirection.Bids)
            .map((bid) => ({
              assetId: asset.id,
              direction: OrderDirection.Bids,
              price: bid.price,
              quantity: bid.outstandingQuantity,
              source: 'CultX',
            }));

          const combinedDuplicateOrders = combineOrders(orders);
          return [...combinedDuplicateOrders].sort((a, b) => b.price - a.price);
        })
        .reduce((prev, curr) => [...prev, ...curr], [])
    : data.productWineVintage.assets
        .map((asset) => {
          const omsOffers: Order[] = asset.openOrders
            .filter((order) => order.direction === OrderDirection.Offers)
            .map((offer) => ({
              assetId: asset.id,
              direction: OrderDirection.Offers,
              price: offer.price,
              source: 'CultX',
              quantity: offer.outstandingQuantity,
            }));
          const wineSearcherOffers: Order[] = asset.recentWineSearcherOffer.offers.map((wsOffer) => ({
            assetId: asset.id,
            direction: OrderDirection.Offers,
            price: wsOffer.price,
            source: 'WineSearcher',
          }));

          const combinedDuplicateOrders = combineOrders(omsOffers);
          return [...combinedDuplicateOrders, ...wineSearcherOffers].sort((a, b) => a.price - b.price);
        })
        .reduce((prev, curr) => [...prev, ...curr], []);
}

export function selectRows(data: Readonly<OrderBookQuery> | undefined, direction: OrderDirection): OrderBookRow[] {
  if (isUndefined(data)) {
    return [];
  }

  if (isNullOrUndefined(data.productWineVintage)) {
    return [];
  }

  const orders = selectOrders(data, direction);
  return data.productWineVintage.assets.map((asset) => ({
    id: asset.id,
    cells: orders.filter((order) => order.assetId === asset.id).map(mapCell),
  }));
}

export function selectOpacity(index: number, assetId: number, row: OrderBookRow): number {
  const even = index % 2 === 0;
  if (assetId === row.id) {
    return even ? CellOpacity.DefaultEven : CellOpacity.DefaultOdd;
  }
  return even ? CellOpacity.Even : CellOpacity.Odd;
}

export function selectPriceLabel(price: number | undefined): string {
  if (price === undefined) {
    return '';
  }
  const str = formatterWholeNumber.format(price);
  // Removes currency delimiter from the string
  return str;
}
