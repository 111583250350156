import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OwnedAsset } from '../../graphql/selectors/ownedAssets';
import BasicCell from '../Table/Cells/Basic';
import WineNameCell from '../Table/Cells/WineName';
import ScoreCell from '../Table/Cells/Score';
import DrinkingWindowCell from '../Table/Cells/DrinkingWindow';
import selectDrinkingWindowAdvice from '../../graphql/selectors/selectDrinkingWindowAdvice';
import { formatter } from '../../utils/currencyFormatter';
import SparklineCell from '../Table/Cells/Sparkline';
import TradingTile from '../TradingTile';
import BuyTradeActionButton, { Size as BuyTradeButtonSize } from '../BuyTradeActionButton';
import IconButton from '../IconButton';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import ActionsCell from '../TableElements/ActionsCell';
import AssetInstanceTable from '../CurrentHoldings/AssetInstanceTable';
import { useOwnedAssetInstances } from '../../hooks/useOwnedAssetInstances';
import { SelectedRowsState, useSelectedRowsContext } from '../../context/selectedRowsContext';
import { Controls } from './Controls';
import { COLUMN_COUNT, SelectMode } from './types';
import { useLastIndexAvailableForOffer } from '../../hooks/useLastIndexAvailableForOffer';
import UnitSizeCell from '../Table/Cells/UnitSize';
import isUndefined from '../../utils/isUndefined';
import { getProductUrl } from '../../utils/common';

interface CultXPortfolioTableRowProps {
  asset: OwnedAsset;
  onBuyNowClicked: (assetId: number) => void;
  onBidClicked: (assetId: number) => void;
  onOfferClicked: (assetId: number) => void;
  onCompareClicked: (assetId: number) => void;
}

// TODO: This is temporary until we can refactor/remove the selectedRowsContext
// -> https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/10861
function getSelectedAssetIds(selectedRowsState: SelectedRowsState): number[] {
  if (selectedRowsState.selectModeActive) {
    return selectedRowsState.selectedRows.map((rowId) => Number(rowId));
  }

  if (selectedRowsState.selectTransferModeActive) {
    return selectedRowsState.selectedTransferedRows.map((row) => Number(row.id));
  }

  return [];
}

// TODO: This is temporary until we can refactor/remove the selectedRowsContext
// -> https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/10861
function getSelectMode(selectedRowsState: SelectedRowsState): SelectMode | undefined {
  if (selectedRowsState.selectModeActive) {
    return 'compare';
  }

  if (selectedRowsState.selectTransferModeActive) {
    return 'transfer';
  }

  return undefined;
}

export default function CultXPortfolioTableRow({
  asset,
  onBidClicked,
  onBuyNowClicked,
  onCompareClicked,
  onOfferClicked,
}: CultXPortfolioTableRowProps) {
  const { clickableTradeTiles13816 } = useFlags();
  const [expanded, setExpanded] = React.useState(false);
  const [shouldDisableCheckbox, setShouldDisableCheckbox] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const { state: selectedAssetsState, dispatch } = useSelectedRowsContext();
  const { lastIndexAvailableForOffer } = useLastIndexAvailableForOffer({
    assetId: asset.assetId,
  });
  const selectMode = getSelectMode(selectedAssetsState);
  const selectedAssetIds = getSelectedAssetIds(selectedAssetsState);
  const {
    ownedAssetInstances,
    error: failedToGetOwnedAssetInstances,
    loading: loadingOwnedAssetInstances,
  } = useOwnedAssetInstances({ assetId: asset.assetId });

  React.useEffect(() => {
    if (selectMode === 'transfer') {
      setChecked(
        (ownedAssetInstances.filter((instance) => !instance.transferRequested).length === 0 ||
          selectedAssetIds.includes(asset.assetId)) &&
          !loadingOwnedAssetInstances,
      );
    }

    if (selectMode === 'compare') {
      setChecked(selectedAssetIds.includes(asset.assetId));
    }
  }, [
    ownedAssetInstances,
    loadingOwnedAssetInstances,
    selectMode,
    shouldDisableCheckbox,
    selectedAssetIds,
    asset.assetId,
  ]);

  React.useEffect(() => {
    if (selectMode === 'compare') {
      setShouldDisableCheckbox(
        !selectedAssetsState.selectedRows.map((sr) => Number(sr)).includes(asset.assetId) &&
          selectedAssetsState.limitReached,
      );
      return;
    }

    if (selectMode === 'transfer') {
      setShouldDisableCheckbox(
        Boolean(failedToGetOwnedAssetInstances) ||
          loadingOwnedAssetInstances ||
          ownedAssetInstances.filter((instance) => !instance.transferRequested).length === 0,
      );
      return;
    }

    setShouldDisableCheckbox(false);
  }, [
    asset.assetId,
    failedToGetOwnedAssetInstances,
    loadingOwnedAssetInstances,
    ownedAssetInstances,
    selectMode,
    selectedAssetsState.limitReached,
    selectedAssetsState.selectedRows,
  ]);

  function onRowCheckboxClicked(): void {
    if (selectMode === 'compare') {
      dispatch({
        type: selectedAssetIds.includes(asset.assetId) ? 'rowUnchecked' : 'rowChecked',
        payload: asset.assetId.toString(),
      });
      return;
    }

    // TODO: This code is copied from the src/components/Table/Row.tsx component.
    // We need to iterate on the refactor effort so we can remove this gnarly code from here, but first
    // we need to complete https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/9651 &
    // https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/10861
    if (selectMode === 'transfer') {
      const isAlreadyChecked = Boolean(
        selectedAssetsState.selectedTransferedRows.find((str) => str.id === asset.assetId),
      );
      if (isAlreadyChecked) {
        const selectedAssetInstances = selectedAssetsState.selectedTransferedRows.filter(
          (row) => (row.assetId as number) === asset.assetId,
        );

        const parentAsset = { type: 'ParentAsset', id: asset.assetId, assetId: asset.assetId };
        const assetInstanceIds = selectedAssetInstances?.map((instance) => ({ id: instance.id as string })) || [];
        dispatch({
          type: 'allTransferRowsUnchecked',
          payload: [...assetInstanceIds, { id: parentAsset.id.toString() }],
        });
      } else {
        const parent = { type: 'ParentAsset', id: asset.assetId, assetId: asset.assetId };
        const selectedInstanceRows = ownedAssetInstances
          .filter((instance, index) => !instance.transferRequested && index <= lastIndexAvailableForOffer)
          .map((instance) => {
            return {
              type: 'AssetInstance',
              id: instance.id,
              assetId: instance.assetId,
              currentLocation: instance.location,
              purchasedPrice: instance.purchasePrice,
              wineName: asset.name,
              unitSize: `${asset.unitCount} x ${asset.unitSize}`,
              marketValue: asset.marketValue,
            };
          });
        dispatch({ type: 'allTransferRowsChecked', payload: [...selectedInstanceRows, parent] });
      }
    }
  }

  const { name: wineName, vintage, assetId, unitSize, unitCount } = asset;
  const prodUrl = getProductUrl({ wineName, vintage, assetId, unitSize, unitCount });

  return (
    <>
      <TableRow key={asset.assetId}>
        <Controls
          onCheckedChanged={onRowCheckboxClicked}
          expanded={expanded}
          setExpanded={setExpanded}
          checked={checked}
          selectMode={selectMode}
          disableCheckbox={shouldDisableCheckbox}
        />
        <WineNameCell wineName={asset.name} appellation={asset.appellation} link={`${prodUrl}`} region={asset.region} />
        <BasicCell mainContent={asset.vintage} />
        <ScoreCell score={asset.score} />
        <DrinkingWindowCell
          advice={selectDrinkingWindowAdvice(asset.drinkingWindow.advice)}
          date={asset.drinkingWindow.dateFrom}
        />
        <BasicCell mainContent={asset.units} />
        <UnitSizeCell unitCount={asset.unitCount} unitSize={asset.unitSize} />
        <BasicCell mainContent={formatter.format(asset.totalPurchasePrice)} />
        <BasicCell mainContent={formatter.format(asset.totalMarketValue)} />
        <SparklineCell
          data={asset.marketData}
          deltaNum={asset.performanceValueDelta}
          deltaPct={asset.performancePercentageDelta}
        />
        <TableCell>
          {clickableTradeTiles13816 ? (
            <TradingTile mode="bid" value={asset.highestBid} onClick={() => onBidClicked(asset.assetId)} />
          ) : (
            <TradingTile mode="bid" value={asset.highestBid} unitCount={asset.unitCount} unitSize={asset.unitSize} />
          )}
        </TableCell>
        <TableCell>
          {clickableTradeTiles13816 ? (
            <TradingTile
              mode="offer"
              value={asset.lowestOffer}
              onClick={!isUndefined(asset.lowestOffer) ? () => onBuyNowClicked(asset.assetId) : undefined}
            />
          ) : (
            <TradingTile mode="offer" value={asset.lowestOffer} unitCount={asset.unitCount} unitSize={asset.unitSize} />
          )}
        </TableCell>
        <ActionsCell>
          <BuyTradeActionButton
            assetId={asset.assetId}
            onBidClicked={() => onBidClicked(asset.assetId)}
            onOfferClicked={() => onOfferClicked(asset.assetId)}
            onBuyClicked={() => onBuyNowClicked(asset.assetId)}
            size={BuyTradeButtonSize.Small}
            condensed
          />
          <IconButton onClick={() => onCompareClicked(asset.assetId)} size="large">
            <CopyIcon style={{ width: '1em', height: '1em' }} />
          </IconButton>
        </ActionsCell>
      </TableRow>
      {expanded && selectMode !== 'compare' && (
        <TableRow>
          <TableCell sx={{ padding: 0 }} colSpan={COLUMN_COUNT}>
            <Collapse in={expanded} unmountOnExit>
              <AssetInstanceTable assetId={asset.assetId} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
