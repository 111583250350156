import { DrinkingWindowAdvice } from '../../components/DrinkingWindowChip';

export default function selectDrinkingWindowAdvice(raw?: string | null): DrinkingWindowAdvice {
  if (!raw) {
    return 'unknown';
  }
  switch (raw.toLowerCase()) {
    case 'hold': {
      return 'hold';
    }

    case 'drink': {
      return 'drink';
    }

    // Whilst waiting on this to be deployed
    // https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/7201
    case 'pass':
    case 'past': {
      return 'past';
    }

    default: {
      return 'unknown';
    }
  }
}
