import { graphql } from '../../__generated__';

export const ACTIVE_MARKET_SUBSCRIPTION = graphql(`
  subscription ActiveMarketSubscription {
    getActiveMarket {
      total
      result {
        assetId
        highestBid {
          createdDate
          price
        }
        imageFileName
        lowestOffer {
          createdDate
          price
        }
        marketValue
        region
        spread
        toBeRemove
        unitCount
        unitSize
        vintage
        wineName
      }
    }
  }
`);
