import React from 'react';

type Dispatch = (value: number) => void;
type State = { padding: number };
type ContentBottomPaddingProviderProps = {
  children: React.ReactNode;
};

const ContentBottomPaddingContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function ContentBottomPaddingProvider({ children }: ContentBottomPaddingProviderProps): JSX.Element {
  const [padding, setPadding] = React.useState(0);
  return (
    <ContentBottomPaddingContext.Provider value={{ dispatch: setPadding, state: { padding } }}>
      {children}
    </ContentBottomPaddingContext.Provider>
  );
}

function useContentBottomPadding(): { state: State; dispatch: Dispatch } {
  const context = React.useContext(ContentBottomPaddingContext);
  if (context === undefined) {
    throw new Error('useContentBottomPadding must be used within a ContentBottomPaddingProvider');
  }

  return context;
}

export { ContentBottomPaddingProvider, useContentBottomPadding };
