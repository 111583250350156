import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TablePaginationProps } from '@mui/material/TablePagination/TablePagination';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  button: {
    color: theme.palette.textPrimary,
  },
}));

export default function TablePaginationActions(props: TablePaginationProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { count, page, rowsPerPage, onPageChange } = props;
  const lastPage = Math.ceil(count / rowsPerPage) - 1;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, lastPage));
  };

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.button}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        size="small"
        aria-label={t('common:table.pagination.firstPage')}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        className={classes.button}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        size="small"
        aria-label={t('common:table.pagination.previousPage')}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        className={classes.button}
        onClick={handleNextButtonClick}
        disabled={page >= lastPage}
        size="small"
        aria-label={t('common:table.pagination.nextPage')}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        className={classes.button}
        onClick={handleLastPageButtonClick}
        disabled={page >= lastPage}
        size="small"
        aria-label={t('common:table.pagination.lastPage')}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}
