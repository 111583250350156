import { useMutation, useQuery } from '@apollo/client';
import { AddressType, isUndefined } from '@cultwines/zellar-client-sdk';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GET_ADDRESSES_QUERY } from '../../graphql/queries/userAddressesQuery';
import { logError } from '../../utils/logger';
import AddressForm, { AddressFormProps } from '../AddressForm';
import { DialogContent } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import { selectCountry } from './selectors';
import { graphql } from '../../__generated__';

const UPDATE_ADDRESS_MUTATION = graphql(`
  mutation UpdateAddressMutation($addressId: String!, $address: AddressInput!) {
    zellarClientUpdateAddress(addressId: $addressId, address: $address) {
      addressId
    }
  }
`);

const ADD_ADDRESS_MUTATION = graphql(`
  mutation AddAddressMutation($address: AddressInput!) {
    zellarClientAddAddress(address: $address) {
      addressId
    }
  }
`);

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
}));

interface AddressFormContentProps {
  addressType: AddressType;
  onCreateSuccess: () => void;
  onCreateFailure: () => void;
  onEditFailure: () => void;
  onEditSuccess: () => void;
  onClose: () => void;
}

export default function AddressFormContent({
  addressType,
  onClose,
  onCreateSuccess,
  onCreateFailure,
  onEditSuccess,
  onEditFailure,
}: AddressFormContentProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, error: failedToLoadAddress } = useQuery(GET_ADDRESSES_QUERY);
  const [addAddress] = useMutation(ADD_ADDRESS_MUTATION, {
    refetchQueries: [{ query: GET_ADDRESSES_QUERY }],
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });
  const [updateAddress] = useMutation(UPDATE_ADDRESS_MUTATION, {
    refetchQueries: [{ query: GET_ADDRESSES_QUERY }],
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });

  const handleSubmit: AddressFormProps['onSubmit'] = async (address, addressId) => {
    if (isUndefined(addressId)) {
      const { errors } = await addAddress({
        variables: {
          address,
        },
      });
      if (errors) {
        logError({
          originalError: errors[0],
          error: new Error(errors[0].message),
          filename: 'AddressFormContent',
          additionalInfo: {
            message: 'Failed to create address',
            addressType: address.type,
          },
        });
        onCreateFailure();
      } else {
        onCreateSuccess();
      }
    } else {
      const { errors } = await updateAddress({
        variables: {
          address,
          addressId,
        },
      });
      if (errors) {
        logError({
          originalError: errors[0],
          error: new Error(errors[0].message),
          filename: 'AddressFormContent',
          additionalInfo: {
            message: 'Failed to edit address',
            addressType: address.type,
          },
        });
        onEditFailure();
      } else {
        onEditSuccess();
      }
    }
  };

  const address = data?.zellarClientAddresses.find((a) => a.type === addressType);
  const country = selectCountry(address, addressType);

  const addressId = address?.addressId;
  // eslint-disable-next-line no-nested-ternary
  const title = address
    ? addressType === 'Billing'
      ? t('account:addressDetails.billingAddress.edit')
      : t('account:addressDetails.deliveryAddress.edit')
    : addressType === 'Billing'
    ? t('account:addressDetails.billingAddress.add')
    : t('account:addressDetails.deliveryAddress.add');

  React.useEffect(() => {
    if (failedToLoadAddress) {
      logError({
        originalError: failedToLoadAddress,
        error: new Error(failedToLoadAddress.message),
        filename: 'AddressFormContent',
        additionalInfo: { message: 'Failed to load address' },
      });
    }
  }, [failedToLoadAddress]);

  return (
    <div className={classes.modalContainer}>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent>
        <AddressForm
          initialValues={{
            addressLine1: address?.addressLine1 ?? '',
            addressLine2: address?.addressLine2 ?? '',
            addressLine3: address?.addressLine3 ?? '',
            country: country ?? { label: '', value: 0 },
            countyState: address?.county ?? '',
            postZipCode: address?.postcode ?? '',
            town: address?.town ?? '',
          }}
          addressType={addressType}
          onSubmit={handleSubmit}
          addressId={addressId}
        />
      </DialogContent>
    </div>
  );
}
