import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WATCHLIST_ITEMS } from '../../graphql';
import { selectErrorMessage } from '../../graphql/selectors/selectErrorMessage';
import { useAddWatchListItem } from '../../hooks/useAddWatchListItem';
import { useDeleteWatchListItem } from '../../hooks/useDeleteWatchListItem';
import getCurrentDate from '../../utils/getCurrentDate';
import getFirstDayOfYear from '../../utils/getFirstDayOfYear';
import { logError } from '../../utils/logger';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import UndoButton from '../Snackbar/UndoButton';
import Typography from '../Typography';
import { useStyles } from './styles';

interface ModalProps {
  assetId: number;
  open: boolean;
  onClose: () => void;
}

export default function DeleteWatchListItemModal({ open, onClose, assetId }: ModalProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { removeFromWatchlist: deleteWatchListItem } = useDeleteWatchListItem();
  const { addToWatchlist: addWatchListItem } = useAddWatchListItem({
    awaitRefetchQueries: false,
    refetchQueries: [
      {
        query: WATCHLIST_ITEMS,
        variables: {
          marketDataDateFrom: getFirstDayOfYear(),
          marketDataDateTo: getCurrentDate(),
        },
      },
    ],
  });

  const { t } = useTranslation();
  const classes = useStyles();

  async function onUndo() {
    const { errors } = await addWatchListItem({ assetId });
    if (errors) {
      logError({
        error: new Error('Failed to undo delete and re-add item to watchlist'),
        originalError: errors[0],
        filename: 'DeleteWatchlistItemModal',
        additionalInfo: {
          errors: JSON.stringify(errors),
          assetId,
        },
      });
    }
  }

  async function onDelete() {
    onClose();
    const { errors } = await deleteWatchListItem({ assetId });
    if (errors) {
      logError({
        error: new Error('Failed to delete item from watchlist'),
        originalError: errors[0],
        filename: 'DeleteWatchlistItemsModal',
        additionalInfo: {
          errors: JSON.stringify(errors),
          assetId,
        },
      });
      enqueueSnackbar(selectErrorMessage(errors), {
        variant: 'error',
      });
    } else {
      enqueueSnackbar(t('product:watchList.deletedMessage'), {
        variant: 'info',
        // eslint-disable-next-line react/no-unstable-nested-components
        action: (key: string) => <UndoButton snackbarKey={key} callback={onUndo} />,
      });
    }
  }

  return (
    <Dialog onClose={onClose} open={open} disableEscapeKeyDown maxWidth="md">
      <div className={classes.modalContainer}>
        <DialogTitle id="dialog-title" onClose={onClose}>
          {t('product:watchList.deleteWines')}
        </DialogTitle>
        <DialogContentBorderTopOnly className={classes.modalContent} dividers>
          <Typography className={classes.label} customVariant="h3Black">
            {t('product:watchList.deleteLabel')}
          </Typography>

          <div className={classes.actions}>
            <Typography variant="body1" className={classes.cancelLabel} onClick={onClose}>
              {t('common:cancel')}
            </Typography>
            <Button variant="contained" color="primary" size="large" onClick={onDelete}>
              {t('common:delete')}
            </Button>
          </div>
        </DialogContentBorderTopOnly>
      </div>
    </Dialog>
  );
}
