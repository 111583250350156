import { graphql } from '../../__generated__';

export const MY_TRADES_SUBSCRIPTION = graphql(`
  subscription OnTradeAdded {
    getMyTrades {
      assetId
      assetUnitIds
      bid {
        userId
        id
        quantity
        quantityOutstanding
        price
      }
      offer {
        userId
        id
        quantity
        quantityOutstanding
        price
      }
      tradeDateTime
    }
  }
`);
