import { useQuery, useSubscription } from '@apollo/client';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { cache } from '../../graphql/cache';
import { ORDER_BOOK_SUBSCRIPTION } from '../../graphql/subscriptions/orderBook';
import { logError } from '../../utils/logger';
import { OrderDirection } from '../OrderBookModal/types';
import Typography from '../Typography';
import LastTradedCell from './LastTradedCell';
import { ORDER_BOOK_QUERY } from './queries';
import Row from './Row';
import { selectAssets, selectRows } from './selectors';
import { useStyles } from './styles';
import UnitSizeCell from './UnitSizeCell';

export interface OrderBookProps {
  selectedAssetId: number;
  wineVintageId?: number;
  isLoggedIn?: boolean;
  isProductError?: boolean;
}

export default function OrderBook({
  selectedAssetId,
  wineVintageId,
  isLoggedIn = false,
  isProductError,
}: OrderBookProps): JSX.Element {
  const { t } = useTranslation();
  const { orderBookSubscription: orderBookSubscriptionEnabled } = useFlags();
  const { data, error, loading } = useQuery(ORDER_BOOK_QUERY, {
    skip: isUndefined(wineVintageId),
    variables: {
      wineVintageId: wineVintageId as number,
    },
    ...(isLoggedIn
      ? {}
      : {
          context: {
            serviceName: 'insecure',
          },
        }), // Conditionally add serviceName
  });

  const { data: subscriptionData, error: subscriptionError } = useSubscription(ORDER_BOOK_SUBSCRIPTION, {
    skip: !orderBookSubscriptionEnabled,
    variables: { assetId: selectedAssetId },
  });

  const assets = selectAssets(data);
  const bidRows = selectRows(data, OrderDirection.Bids);
  const offerRows = selectRows(data, OrderDirection.Offers);
  const classes = useStyles({ rowCount: assets.length });

  React.useEffect(() => {
    if (subscriptionData && orderBookSubscriptionEnabled) {
      cache.evict({ fieldName: 'productWineVintage' });
    }
  }, [subscriptionData, orderBookSubscriptionEnabled]);

  if (loading) {
    return <Skeleton width="100%" height="300px" variant="rectangular" />;
  }

  if (subscriptionError) {
    logError({
      originalError: subscriptionError,
      error: new Error('Order book subscription failed'),
      filename: 'OrderBook',
      additionalInfo: { wineVintageId: wineVintageId ?? '' },
    });
  }

  if (error || isProductError) {
    logError({
      originalError: error,
      error: new Error('Failed to load order book data'),
      filename: 'OrderBook',
      additionalInfo: { wineVintageId: wineVintageId ?? '' },
    });
    return <div>{error ? error.message : ''}</div>;
  }

  return (
    <div className={classes.grid} role="grid">
      <Typography className={clsx(classes.rowCapStart, classes.uppercase)} variant="body2">
        {t('product:unitSize')}
      </Typography>
      <Typography
        className={clsx(classes.bids, classes.uppercase, classes.textAlignEnd)}
        variant="body2"
        zellarColour="textPrimary"
      >
        {t('product:trading.highestBid')}
      </Typography>
      <Typography className={clsx(classes.offers, classes.uppercase)} variant="body2" zellarColour="textPrimary">
        {t('product:trading.lowestOffer')}
      </Typography>
      <Typography className={clsx(classes.rowCapEnd, classes.uppercase)} variant="body2">
        {t('product:trading.lastTrade')}
      </Typography>
      {assets.map((asset) => (
        <React.Fragment key={asset.id}>
          <UnitSizeCell
            className={classes.rowCapStart}
            active={asset.id === selectedAssetId}
            unitCount={asset.unitCount}
            unitSize={asset.unitSize}
          />
          <Row
            className={classes.bids}
            assetId={asset.id}
            row={bidRows.find((row) => row.id === asset.id)!}
            variant="bid"
            title="bids"
          />
          <Row
            className={classes.offers}
            assetId={asset.id}
            row={offerRows.find((row) => row.id === asset.id)!}
            variant="offer"
            title="offers"
          />
          <LastTradedCell
            className={classes.rowCapEnd}
            active={asset.id === selectedAssetId}
            tradingInfo={asset.tradingInfo}
          />
        </React.Fragment>
      ))}
    </div>
  );
}
