import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDeletePaymentCard } from '../../hooks/useDeletePaymentCard';
import { logMessage } from '../../utils/logger';

const useStyles = makeStyles(() => ({
  centerAlign: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface ConfirmDeleteCardDialogProps {
  open: boolean;
  instrumentId: string | null | undefined;
  handleClose: () => void;
  handleDone: () => void;
}

export default function ConfirmDeleteCardDialog({
  open,
  instrumentId,
  handleClose,
  handleDone,
}: ConfirmDeleteCardDialogProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { deleteCard, error, loading, success } = useDeletePaymentCard();

  async function handleConfirm() {
    if (!instrumentId) {
      logMessage('Tried to delete a card but the instrumentId was null', {
        additionalInfo: { filename: 'src/components/PaymentCard/ConfirmDeleteCardDialog' },
      });
      return;
    }

    await deleteCard(instrumentId);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {!error && !success && (
        <DialogTitle id="alert-dialog-title">{t('wallet:payByCard.confirmDeleteDialog.title')}</DialogTitle>
      )}
      {error && (
        <DialogTitle id="alert-dialog-title">{t('wallet:payByCard.confirmDeleteDialog.errorTitle')}</DialogTitle>
      )}
      {!error && success && (
        <DialogTitle id="alert-dialog-title">{t('wallet:payByCard.confirmDeleteDialog.successTitle')}</DialogTitle>
      )}
      {!error && !success && (
        <DialogActions>
          <Button disabled={loading} onClick={handleClose} variant="outlined" color="info" aria-label="cancel">
            {t('common:cancel')}
          </Button>
          <Button
            disabled={loading}
            onClick={handleConfirm}
            variant="outlined"
            color="info"
            aria-label="confirm"
            autoFocus
          >
            {t('common:confirm')}
          </Button>
        </DialogActions>
      )}
      {(error || success) && (
        <DialogActions className={classes.centerAlign}>
          <Button onClick={handleDone} variant="outlined" color="info" autoFocus>
            {t('common:ok')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
