import { isUndefined } from '@cultwines/zellar-client-sdk';
import { ImgSize } from '../types';

type Size = {
  width?: ImgSize;
  height?: ImgSize;
};

function getImageUrl(fileName: string, size?: Size): string {
  if (!isUndefined(size)) {
    if (!isUndefined(size.width && isUndefined(size.height))) {
      return `${process.env.REACT_APP_CDN_URL}/width/${size.width}/${fileName}`;
    }
    if (!isUndefined(size.height) && isUndefined(size.width)) {
      return `${process.env.REACT_APP_CDN_URL}/height/${size.height}/${fileName}`;
    }
    // if both width and height are defined, it returns the image with the given width from the api
  }
  return `${process.env.REACT_APP_CDN_URL}/upload/${fileName}`;
}

export default getImageUrl;
