import makeStyles from '@mui/styles/makeStyles';

export const useAssetInstanceStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(4),
    flex: 1,
    alignItems: 'flex-start',
  },

  capitalise: {
    textTransform: 'capitalize',
  },

  row: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(5),
    rowGap: theme.spacing(4),
    alignItems: 'flex-start',
  },

  right: {
    marginLeft: 'auto',
  },
  actions: {
    display: 'flex',
  },

  transferLink: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    padding: `0 ${theme.spacing(2)}`,

    '&:hover': {
      background: 'none',
    },
  },

  link: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
  },

  wineTraceability: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    padding: 0,
    '&:disabled': {
      opacity: 0.5,
    },
  },
}));
