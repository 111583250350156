/* eslint-disable react/no-this-in-sfc */
import { useTheme } from '@mui/material';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { HighchartsChart } from '../../../types/Highcharts';
import { DeltaType } from '../../DeltaChip';

interface Props {
  data: Highcharts.SeriesAreasplineOptions['data'];
  type: DeltaType;
  width?: number;
  height?: number;
  // loading: boolean;
}

export default function IndexValueSparkLineChart({ data, type, width = 84, height = 50 }: Props): JSX.Element {
  const [options, setOptions] = useState<Highcharts.Options | null>(null);
  const chartRef = React.createRef<{ chart: HighchartsChart; container: React.RefObject<HTMLDivElement> }>();
  const theme = useTheme();

  useEffect(() => {
    setOptions({
      chart: {
        backgroundColor: undefined,
        borderWidth: 0,
        margin: [2, 0, 2, 0],
        width,
        height,
        style: {
          overflow: 'visible',
        },
      },
      series: [
        {
          type: 'areaspline',
          data,
          color:
            // eslint-disable-next-line no-nested-ternary
            type === DeltaType.Positive
              ? theme.palette.chart1.main
              : type === DeltaType.Negative
              ? theme.palette.chart2.main
              : theme.palette.chart1.disabled,
          fillOpacity: 0.2,
          marker: {
            radius: 0.5,
          },
        },
      ],
      lang: {
        noData: undefined,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
        lineWidth: 0,
        tickWidth: 0,
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tickPositions: [],
      },
      noData: undefined,
      plotOptions: {
        series: {
          enableMouseTracking: false,
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    });
  }, [data, type, theme, height, width]);

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />;
}
