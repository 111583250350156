import React from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '../../Typography';
import { BasicCell as BasicCellProps } from '../types';

export default function BasicCell({
  mainContent,
  subContent,
  cellClassName,
  sx,
  ...rest
}: BasicCellProps): JSX.Element {
  return (
    <TableCell
      sx={sx}
      aria-label={rest['aria-label'] === undefined ? '' : rest['aria-label']}
      className={cellClassName}
    >
      <>
        <Typography variant="subtitle2">{mainContent}</Typography>
        {subContent && (
          <Typography zellarColour="textLabel" variant="body2">
            {subContent}
          </Typography>
        )}
      </>
    </TableCell>
  );
}
