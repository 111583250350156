/* eslint-disable no-nested-ternary */
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet_deprecated.svg';
import { useWalletBalance } from '../../hooks';
import { formatter } from '../../utils/currencyFormatter';
import { isNull } from '../../utils/isNull';
import { logError } from '../../utils/logger';
import Typography from '../Typography';
import { NavigationPathEnum } from '../../types';
import { colours } from '../../theme/light/palette';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(5),
    alignItems: 'center',
  },
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    color: theme.palette.secondary.contrastText,
  },
  iconContainer: {
    width: 67,
    height: 67,
    padding: '20px 11px',
    borderRadius: 10,
    border: `1px solid ${theme.palette.card.background.dark}`,
    background: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.button.primary.background.hover,
    },
  },

  walletIcon: {
    background: colours.white,
    borderRadius: 5,
  },
  skeleton: {
    background: theme.palette.grey[200],
  },
}));

export default function WalletHeading(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const { walletBalance, loading, error } = useWalletBalance();
  const onOpenWallet = () => {
    history.push(NavigationPathEnum.WALLET);
  };
  if (error) {
    logError({
      originalError: error,
      error: new Error('Encountered error when getting available balance'),
      filename: 'WalletHeading',
    });
  }

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer} onClick={onOpenWallet} aria-hidden="true">
        <WalletIcon fill={theme.palette.card.background.dark} className={classes.walletIcon} />
      </div>
      <div className={classes.verticalContainer}>
        <Typography variant="h3">
          {loading ? (
            <Skeleton width="120px" className={classes.skeleton} />
          ) : isNull(walletBalance) ? (
            t('common:currentlyUnavailable')
          ) : (
            formatter.format(walletBalance)
          )}
        </Typography>
        <Typography customVariant="body2">{t('wallet:totalFunds')}</Typography>
      </div>
    </div>
  );
}
