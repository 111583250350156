import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@cultwines/zellar-client-sdk';
import SelectBase from './index';

const useStyles = makeStyles((theme) => ({
  containerClassName: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },

  select: {
    width: '108px',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
    lineHeight: theme.typography.body2.lineHeight,

    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },

    [theme.breakpoints.up('sm')]: {
      width: '160px',
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
  },
}));

interface VintageSelectUncontrolledProps {
  options: SelectOption[];
  onChange: (optionId: number) => void;
  selectedOptionId: number;
  loading: boolean;
  sortOrder?: 'asc' | 'desc';
}

export default function VintageSelectUncontrolled({
  options,
  onChange,
  selectedOptionId,
  loading,
  sortOrder = 'desc',
}: VintageSelectUncontrolledProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const sortedOptions =
    sortOrder === 'desc'
      ? [...options].sort((a, b) => Number(b.label) - Number(a.label))
      : [...options].sort((a, b) => Number(a.label) - Number(b.label));
  return (
    <SelectBase
      containerClassName={classes.containerClassName}
      optionId={selectedOptionId}
      setOption={onChange}
      options={sortedOptions}
      inputClass={classes.select}
      loading={loading}
      inputLabel={t('common:vintage')}
      inlineLabel
    />
  );
}
