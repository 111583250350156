import ImagePlaceholder from '../../assets/images/stub-wine-image.png';
import { DrinkingWindowAdvice } from '../../components/DrinkingWindowChip';
import getImageUrl from '../../utils/getImageUrl';
import { isNull } from '../../utils/isNull';
import { massageToNull } from '../../utils/massageToNull';
import selectDrinkingWindowAdvice from './selectDrinkingWindowAdvice';
import { OwnedAssetsQuery } from '../../__generated__/graphql';

export type OwnedAsset = {
  assetId: number;
  name: string;
  region: string;
  appellation: string;
  units: number;
  unitSize: string;
  unitCount: number;
  totalPurchasePrice: number;
  totalAvgPurchasePrice: number;
  totalMarketValue: number;
  marketValue: number;
  score: number | null;
  vintage: number;
  imageFilename: string;
  // Removed due to mocked data usage will come back after private BETA
  // https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/7079
  // activeOffers: number;
  // quantityOwned: number;
  drinkingWindow: {
    advice: DrinkingWindowAdvice;
    dateFrom: string;
    dateTo: string;
  };
  performancePercentageDelta: number | null;
  performanceValueDelta: number | null | undefined;
  marketData: Record<string, number>;
  highestBid: number | null;
  lowestOffer: number | null;
};

export type OwnedAssetFacets = Omit<OwnedAsset, 'drinkingWindow' | 'marketData'>;

export function selectOwnedAssets(original: OwnedAssetsQuery | undefined): OwnedAsset[] {
  if (!original) {
    return [];
  }

  if (!original.holdingsCurrent || original.holdingsCurrent.length === 0) {
    return [];
  }

  const normalised = original.holdingsCurrent
    .filter((holding) => !isNull(holding) && !isNull(holding.asset) && !isNull(holding.asset.id))
    .map((holding) => {
      if (isNull(holding)) {
        throw new Error('this can never happen as we filter out the null values in prev step.');
      }

      const ret: OwnedAsset = {
        drinkingWindow: {
          advice: selectDrinkingWindowAdvice(holding.asset.vintage.drinkingWindow!.advice),
          dateFrom: holding.asset.vintage.drinkingWindow.dateFrom,
          dateTo: holding.asset.vintage.drinkingWindow.dateTo,
        },
        performancePercentageDelta: holding.calculatedMarketData.performance.percentageDifference
          ? parseFloat(holding.calculatedMarketData.performance.percentageDifference.toFixed(2))
          : null,
        performanceValueDelta: holding.calculatedMarketData.performance.valueDifference,
        marketData: {},
        lowestOffer: massageToNull(holding.asset.spread?.lowestOffer?.price),
        highestBid: massageToNull(holding.asset.spread?.highestBid?.price),
        score: holding.calculatedMarketData.combinedScore || null,
        totalMarketValue: holding.totalMarketValuation,
        marketValue: holding.asset.marketValue ?? 0,
        // https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/7079
        // quantityOwned: holding.totalQuantityOwned,
        // activeOffers: holding.activeOfferQuantity,
        totalPurchasePrice: holding.totalPurchasePrice,
        totalAvgPurchasePrice: holding.totalPurchasePrice / holding.totalQuantityOwned,
        units: holding.totalQuantityOwned,
        unitCount: holding.asset.unitCount,
        unitSize: holding.asset.unitSize,
        appellation: holding.asset.vintage.wine.appellation?.name ?? '',
        // Assume that region will always be present, as it is non-nullable on other return types from gql.
        region: holding.asset.vintage.wine.region!.name,
        vintage: holding.asset.vintage.vintage,
        assetId: holding.asset.id,
        name: holding.asset.vintage.wine.name,
        imageFilename: holding.asset.vintage.imageFileName
          ? getImageUrl(holding.asset.vintage.imageFileName, { height: 200 })
          : ImagePlaceholder,
      };
      return ret;
    });

  return normalised;
}
