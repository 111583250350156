import { FormikContextType, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export function useEditEmailAddress({
  emailAddress,
  emailAddressUpdated,
}: {
  emailAddress: string;
  emailAddressUpdated: (email: string) => Promise<void>;
}): FormikContextType<{ emailAddress: string }> {
  const { t } = useTranslation();
  const emailSchema = yup.object({
    emailAddress: yup
      .string()
      .email(t('auth:emailAddress.required'))
      .required(t('auth:emailAddress.required'))
      .test('match', t('account:accountDetails.email.duplicateErrorMessage'), (email) => {
        return email !== emailAddress;
      }),
  });

  const emailFormik = useFormik<yup.InferType<typeof emailSchema>>({
    initialValues: {
      emailAddress,
    },
    onSubmit: async ({ emailAddress: newEmailAddress }, { resetForm }) => {
      await emailAddressUpdated(newEmailAddress);
      resetForm();
    },
    validationSchema: emailSchema,
  });

  return emailFormik;
}
