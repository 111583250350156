import React from 'react';
import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import DialogTitle from '../Dialog/DialogTitle';
import Divider from '../Divider';
import { colours } from '../../theme/light/palette';
import { GET_ADDRESSES_QUERY } from '../../graphql/queries/userAddressesQuery';
import AddressSummary from '../AddressSummary';
import TransferWineTable, { AssetInstance } from '../TransferWineTable';
import { selectAddress } from '../../graphql/selectors/userAddressSelectors';
import { useSelectedRowsContext } from '../../context/selectedRowsContext';
import useUserDetails from '../../hooks/useUserDetails';
import { formatter } from '../../utils/currencyFormatter';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import EmptyAddressButton from '../EmptyAddressButton';
import { logError } from '../../utils/logger';
import { selectCountry } from '../AddressModal/selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    minHeight: 350,
    maxWidth: 768,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '@media (min-width: 768px)': {
      minWidth: 768,
    },
  },
  reviewSection: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  minValue: {
    textTransform: 'uppercase',
    color: colours.purpleMid,
  },
  reviewSelection: {
    marginTop: 25,
  },
  deliveryAddress: {
    marginTop: 25,
  },
  reviewSelectionFont: {
    textTransform: 'capitalize',
  },
  totalPriceFont: {
    textTransform: 'capitalize',
  },
  deliveryAddressFont: {
    textTransform: 'capitalize',
  },
  editAddressSection: {
    width: '100%',
    marginTop: 25,

    '@media (min-width: 768px)': {
      width: '40%',
      maxWidth: '400px',
    },
  },
  addYourAddressFont: {
    fontWeight: 700,
    cursor: 'pointer',
  },
  plus: {
    fontSize: 20,
    marginRight: 5,
  },
  transferButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(5),
  },
  button: {
    alignSelf: 'center',
  },
  reviewContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  leftContainer: {
    display: 'flex',
    gap: theme.spacing(10),
    paddingBottom: theme.spacing(2),
  },
  transferFailed: {
    textAlign: 'center',
    paddingTop: 30,
  },
  transferFailedText: {
    paddingTop: 10,
  },
  errorButton: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  errorContainer: {
    textAlign: 'center',
  },
  styleContainer: {
    height: 'auto',
  },
  emptyAddress: {
    marginTop: 10,
  },
}));

export default function ReviewSelection({
  onClose,
  openAddressModal,
  onSuccessModal,
}: {
  onClose: () => void;
  openAddressModal: () => void;
  onSuccessModal: () => void;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    data: addressQueryResponse,
    loading: addressLoading,
    error: failedtoLoadAddress,
  } = useQuery(GET_ADDRESSES_QUERY);
  const { userDetails, error: failedToLoadUserDetails } = useUserDetails();
  const address = selectAddress(addressQueryResponse, 'Delivery');
  const { state } = useSelectedRowsContext();
  const assetInstances = state.selectedTransferedRows?.filter((x) => x.type === 'AssetInstance') as AssetInstance[];
  const totalMarketValue = assetInstances
    .filter((instance) => !isNullOrUndefined(instance.marketValue))
    // TODO: Yuk!
    .map((instance): number => instance.marketValue as number)
    .reduce((prev, curr) => prev + curr, 0);

  if (failedtoLoadAddress) {
    logError({
      originalError: failedtoLoadAddress,
      error: new Error('Failed to load address'),
      filename: 'ReviewSelection',
      tags: { userFlow: 'transfer' },
    });
  }

  if (failedToLoadUserDetails) {
    logError({
      originalError: failedToLoadUserDetails,
      error: new Error('Failed to load user details'),
      filename: 'ReviewSelection',
      tags: { userFlow: 'transfer' },
    });
  }

  return (
    <div className={classes.container}>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {t('portfolio:requestTransferWineToExternalLocation')}
      </DialogTitle>
      <Divider />
      <div className={classes.reviewSelection}>
        <div className={classes.reviewContainer}>
          <div className={classes.leftContainer}>
            <Typography customVariant="h5" className={classes.reviewSelectionFont}>
              {t('portfolio:transferToExternalLocation.reviewSelection')}
            </Typography>
          </div>
          <Typography customVariant="h5" className={classes.totalPriceFont}>
            {t('portfolio:totalValue')}: {formatter.format(totalMarketValue)}
          </Typography>
        </div>
        <TransferWineTable assetInstances={assetInstances} />
      </div>
      <div className={classes.deliveryAddress}>
        <Typography customVariant="h5" className={classes.deliveryAddressFont}>
          {t('account:addressDetails.deliveryAddress.title')}
        </Typography>
        {address ? (
          <div className={classes.editAddressSection}>
            <AddressSummary
              address={address}
              name={`${userDetails?.firstName} ${userDetails?.surname}`}
              handleEditAddressClicked={openAddressModal}
            />
          </div>
        ) : (
          <EmptyAddressButton onClick={openAddressModal} className={classes.emptyAddress} />
        )}
      </div>
      <div className={classes.transferButton}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="large"
          disabled={addressLoading || !address || !selectCountry(address, 'Delivery')}
          onClick={onSuccessModal}
        >
          {t('portfolio:requestTransfer')}
        </Button>
      </div>
    </div>
  );
}
