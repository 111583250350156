import { SelectOption } from '@cultwines/zellar-client-sdk';
import i18n from '../../i18n';
import getImageUrl from '../../utils/getImageUrl';
import { Asset, ProductAssetWatchListByAssetIdQuery } from '../../__generated__/graphql';
import { ProductDisplay, ProductSummary } from './types';

export function selectNormalisedProduct(original: Asset | null | undefined): ProductDisplay {
  const wineName: string = original?.vintage?.wine?.name || i18n.t('product:notAvailable');
  const vintage = original?.vintage?.vintage;

  return {
    displayName: vintage ? `${wineName} ${vintage}` : wineName,
  };
}

export function selectProductSummary(
  original: ProductAssetWatchListByAssetIdQuery['productAsset'] | null | undefined,
): ProductSummary {
  const wineName: string = original?.vintage?.wine?.name || i18n.t('product:notAvailable');
  const vintage = original?.vintage?.vintage;
  const imageFileName = original?.vintage.imageFileName;
  const seoFriendlyName = original?.vintage?.wine?.seoFriendlyName;
  return {
    displayName: vintage ? `${wineName} ${vintage}` : wineName,
    imageUrl: imageFileName ? getImageUrl(imageFileName, { height: 400 }) : undefined,
    wineName,
    vintage,
    seoFriendlyName,
  };
}

function selectAssetId(assets: Asset[], selectedAssetId: number): number {
  if (assets.map((asset) => asset.id).includes(selectedAssetId)) {
    return selectedAssetId;
  }

  return assets[0].id;
}

export function selectVintages(
  raw: ProductAssetWatchListByAssetIdQuery['productAsset'] | null | undefined,
  assetId: number,
): SelectOption[] {
  if (!raw) {
    return [];
  }

  const vintages = raw.vintages.map((vintage) => ({
    label: vintage.vintage.toString(),
    // We must set the value with the currently selected asset id for it's associated vintage,
    // to ensure that the correct asset id is used as the value for the select option.
    value: selectAssetId(vintage.assets as Asset[], assetId),
  }));

  return vintages;
}
