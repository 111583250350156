import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { TradingCardMode } from './types';
import { colours } from '../../theme/light/palette';

export const useStyles = makeStyles<Theme, { mode: TradingCardMode }>((theme) => {
  const containerStyles = {
    bid: {
      backgroundColor: colours.lightYellow,
    },
    offer: {
      backgroundColor: colours.cultPurple,
    },
    light: {
      backgroundColor: theme.palette.card.background.light,
      border: `1px solid ${theme.palette.card.background.dark}`,
    },
  };

  return {
    container: (props) => ({
      padding: '25px 15px',
      boxShadow: 'none',
      color: theme.palette.textPrimary,
      ...containerStyles[props.mode],
    }),

    pill: {
      color: theme.palette.textPrimary,
      border: `1px solid ${theme.palette.card.background.dark}`,
      padding: '5px 10px',
      borderRadius: '25px',
      textAlign: 'center',
      fontSize: theme.typography.label2Active.fontSize,
      fontWeight: theme.typography.label2Active.fontWeight,
      lineHeight: theme.typography.label2Active.lineHeight,
      marginTop: theme.spacing(4),
      maxWidth: 'max-content',
    },
  };
});
