export function selectColumnTransformStyle(
  colIndex: number,
  visibleColumnIndex: number,
  percentage = 100,
): React.CSSProperties | undefined {
  if (visibleColumnIndex > colIndex) {
    return { transform: `translateX(-${percentage}%)` };
  }
  if (visibleColumnIndex < colIndex) {
    return { transform: `translateX(${percentage}%)` };
  }
  return undefined;
}
