/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconButton } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import Clear from '@mui/icons-material/Clear';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactComponent as Arrows } from '../../assets/icons/trade-arrows.svg';
import { ReactComponent as DownArrowFilled } from '../../assets/images/down-arrow-filled.svg';
import { useStyles } from './styles';
import { getKycStatus } from '../../services/auth';
import { KycStatus } from '../../__generated__/graphql';
import { Size } from '../BuyTradeActionButton';

const TRANSITION_DURATION = 333;
const ANCHOR_ORIGIN: PopoverOrigin = { horizontal: 'center', vertical: 'top' };
const TRANSFORM_ORIGIN: PopoverOrigin = { horizontal: 'center', vertical: 'top' };

type Func = () => void;
interface PopoverActionButtonProps {
  onSignInClicked?: Func | null;
  onProceedClicked?: Func | null;
  size?: Size;
  condensed?: boolean;
  disabled?: boolean;
  assetId: number;
  isLoggedIn?: boolean;
  children?: React.ReactNode;
}

/**
 * https://www.figma.com/file/TXv0dwPTUpo1949Z8ZFXmD/CW-ZELLAR-UI?node-id=352%3A7246
 */
export default function PopoverActionButton({
  onSignInClicked = null,
  onProceedClicked = null,
  condensed = false,
  size = Size.Small,
  disabled = false,
  assetId = -1,
  isLoggedIn = false,
  children = null,
}: PopoverActionButtonProps): JSX.Element {
  const { t } = useTranslation();
  const classesObject = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { kyc: kycFlagEnabled } = useFlags();
  const allowBuyTrade = !kycFlagEnabled || getKycStatus() === KycStatus.successful;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'fancy-button-select' : undefined;

  function handleSignInClick(): void {
    if (!onSignInClicked) {
      return;
    }
    onSignInClicked();
    handleClose();
  }

  function handleProceedClick(): void {
    if (!onProceedClicked) {
      return;
    }
    onProceedClicked();
    handleClose();
  }

  const Content = (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={ANCHOR_ORIGIN}
      transformOrigin={TRANSFORM_ORIGIN}
      PaperProps={{ className: classesObject.popover }}
      transitionDuration={TRANSITION_DURATION}
    >
      <div className={classesObject.container}>
        <Button
          color="primary"
          variant="contained"
          className={`${classesObject.iconButton} ${
            size === Size.Large ? classesObject.largeIconButton : classesObject.smallIconButton
          }`}
          onClick={handleClose}
        >
          <Clear />
        </Button>
        {!isLoggedIn && (
          <Button
            color="primary"
            variant="contained"
            className={size === Size.Large ? classesObject.largeButton : classesObject.smallButton}
            onClick={handleSignInClick}
            fullWidth
          >
            {t('common:login')}
          </Button>
        )}
      </div>
    </Popover>
  );
  const buttonClassName =
    size === Size.Large
      ? `${classesObject.largeButton} ${classesObject.largeAnchorButton}`
      : `${classesObject.smallButton} ${classesObject.smallAnchorButton}`;
  return (
    <>
      <Button color="inherit" variant="contained" style={{ padding: 0 }} onClick={handleClick}>
        {children}
      </Button>

      <Backdrop className={classesObject.backdrop} open={open}>
        {Content}
      </Backdrop>
    </>
  );
}
