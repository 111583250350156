import { useQuery } from '@apollo/client';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { sort } from '@cultwines/zellar-client-sdk/utils/sort';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GET_MY_ORDERS } from '../../graphql/queries/omsGetMyOrders';
import { MyOrder, MyOrdersFacets, selectMyOrders } from '../../graphql/selectors/selectMyOrders';
import useMediaQuery from '../../hooks/useMediaQuery';
import useTableControls from '../../hooks/useTableControls';
import { getPage } from '../../utils/getPage';
import { OrderDirection } from '../OrderBookModal/types';
import Toolbar, { ViewType } from '../Table/Toolbar';
import TablePagination from '../TablePagination';
import OrderCard from '../WineCard/OrderCard';
import CancelBidModal from './CancelBidModal';
import { getSortOptions, selectResultsCount } from './selectors';
import { useMyBidsStyles } from './styles';
import MyBidsTable from './BidsTable';
import MyOffersTable from './OffersTable';
import CancelOfferModal from './CancelOfferModal';
import { SelectedRowsProvider } from '../../context/selectedRowsContext';
import { uuid } from '../../utils/uuid';
import WineCardSkeleton from '../WineCardSkeleton';
import ErrorPlaceholder from '../ErrorPlaceholder';
import EmptyPlaceholder from '../EmptyPlaceholder';
import { AssetStatus } from '../../types/assetStatus';
import OrderModal from '../OrderModal';
import { Mode } from '../OrderModal/types';
import { OrderStatus } from '../../types/OrderStatus';
import { logError } from '../../utils/logger';
import { TableRefactorFlagSet } from '../../types/FeatureFlags';
import BidsTable from '../Trade/BidsTable';
import OffersTable from '../Trade/OffersTable';

const PAGE_SIZE_VARIANTS = [20, 60, 90];

interface MyOrdersProps {
  orderType: OrderDirection;
}

function MyOrders({ orderType }: MyOrdersProps): JSX.Element {
  const classes = useMyBidsStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const lessThanXl = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xl));
  const [viewType, setViewType] = React.useState<ViewType>('table');
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [proccessingCancelOpen, setProccessingCancelOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState<MyOrder | null>(null);
  const [orderStatus, setOrderStatus] = React.useState<'live' | 'all'>('live');
  const { tableRefactor } = useFlags<{ tableRefactor?: TableRefactorFlagSet }>();
  const handleToggleState = (value: boolean) => {
    setOrderStatus(value ? 'all' : 'live');
  };
  const {
    resetState: resetTableControlsState,
    handleChangePage,
    handleChangeResultsPerPage,
    handleSortUpdated,
    page,
    pageSize,
    sortDirection,
    sortFacet,
  } = useTableControls<MyOrdersFacets>({
    defaultFacet: 'wineName',
    pageSizeVariants: PAGE_SIZE_VARIANTS,
  });
  const {
    data: ordersRaw,
    loading: gettingOrders,
    error: failedToGetOrders,
  } = useQuery(GET_MY_ORDERS, { pollInterval: 5000 });
  const orders = React.useMemo(
    () =>
      selectMyOrders(ordersRaw, orderType)
        .filter((order) => (orderStatus === 'all' ? true : order.status === orderStatus))
        .sort((a, b) => sort(a[sortFacet], b[sortFacet], sortDirection))
        .slice(...getPage(page, pageSize)),
    [ordersRaw, sortFacet, sortDirection, page, pageSize, orderType, orderStatus],
  );
  const resultsCount = React.useMemo(
    () => selectResultsCount(ordersRaw, orderType, orderStatus),
    [ordersRaw, orderType, orderStatus],
  );
  const sortOptions = React.useMemo(
    () => getSortOptions(sortDirection, sortFacet, t, orderType),
    [sortDirection, sortFacet, t, orderType],
  );

  React.useEffect(() => {
    resetTableControlsState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  function handleToolbarSortChanged(id: string): void {
    const opt = sortOptions.find((sO) => sO.id === id);
    if (isUndefined(opt)) {
      return;
    }

    handleSortUpdated(opt.key, opt.direction);
  }

  const handleCancel = React.useCallback(
    // We need to match on order ID because there might be multiple
    // of the same asset in the results (e.g. you can have more than
    // one bid against an asset).
    (orderId: string) => {
      const myOrder = orders.find((order) => order.orderId === orderId)!;
      if (
        myOrder.status !== AssetStatus.Expired &&
        myOrder.status !== AssetStatus.Cancelled &&
        myOrder.status !== AssetStatus.Fulfilled
      ) {
        setSelectedOrder(orders.find((order) => order.orderId === orderId)!);
        setCancelModalOpen(true);
      }
    },
    [orders],
  );

  function handleCancelModalClose(): void {
    setSelectedOrder(null);
    setCancelModalOpen(false);
  }

  // Close cancel confirmation dialog and open cancel processing
  function handleCancelConfirm(): void {
    setCancelModalOpen(false);
    setProccessingCancelOpen(true);
  }

  function handleCancelClose(): void {
    setSelectedOrder(null);
    setProccessingCancelOpen(false);
  }

  const handleEdit = React.useCallback(
    // We need to match on order ID because there might be multiple
    // of the same asset in the results (e.g. you can have more than
    // one bid against an asset).
    (orderId: string) => {
      const myOrder = orders.find((order) => order.orderId === orderId)!;
      if (
        myOrder.status !== AssetStatus.Expired &&
        myOrder.status !== AssetStatus.Cancelled &&
        myOrder.status !== AssetStatus.Fulfilled
      ) {
        setSelectedOrder(myOrder);
        setEditModalOpen(true);
      }
    },
    [orders],
  );

  function handleEditClose(): void {
    setSelectedOrder(null);
    setEditModalOpen(false);
  }

  if (failedToGetOrders) {
    logError({ originalError: failedToGetOrders, error: new Error('Failed to get orders'), filename: 'MyOrders' });
  }

  const bidsTable = tableRefactor?.orders ? (
    <BidsTable
      assets={orders}
      error={failedToGetOrders?.message ?? undefined}
      loading={gettingOrders}
      sortDirection={sortDirection}
      sortColumn={sortFacet}
      onSortChanged={handleSortUpdated}
      handleCancel={handleCancel}
      handleEdit={handleEdit}
    />
  ) : (
    <MyBidsTable
      assets={orders}
      error={failedToGetOrders}
      loading={gettingOrders}
      activeSortKey={sortFacet}
      onSortChanged={handleSortUpdated}
      sortDirection={sortDirection}
      handleCancel={handleCancel}
      handleEdit={handleEdit}
    />
  );

  const offersTable = tableRefactor?.orders ? (
    <OffersTable
      assets={orders}
      error={failedToGetOrders?.message ?? undefined}
      loading={gettingOrders}
      sortDirection={sortDirection}
      sortColumn={sortFacet}
      onSortChanged={handleSortUpdated}
      handleCancel={handleCancel}
      handleEdit={handleEdit}
    />
  ) : (
    <MyOffersTable
      assets={orders}
      error={failedToGetOrders}
      loading={gettingOrders}
      activeSortKey={sortFacet}
      onSortChanged={handleSortUpdated}
      sortDirection={sortDirection}
      handleCancel={handleCancel}
      handleEdit={handleEdit}
    />
  );

  const Table = React.useMemo(
    () => (orderType === OrderDirection.Bids ? bidsTable : offersTable),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      orderType,
      orders,
      failedToGetOrders,
      gettingOrders,
      sortFacet,
      handleSortUpdated,
      sortDirection,
      handleCancel,
      handleEdit,
    ],
  );

  return (
    <div className={classes.container}>
      <div className={classes.orders}>
        <Toolbar
          resultCount={resultsCount}
          viewType={viewType}
          onChangeViewType={setViewType}
          onSortChanged={handleToolbarSortChanged}
          sortOptions={sortOptions}
          hideChangeViewType={lessThanXl}
          toggleButtonProps={{
            toggleState: orderStatus === 'all',
            onToggle: handleToggleState,
            falseStateName: t('trade:myTable.live'),
            trueStateName: t('trade:myTable.all'),
          }}
        />
        {viewType === 'card' || lessThanXl ? (
          <div className={classes.cardList}>
            {gettingOrders &&
              new Array(20).fill(0).map(() => <WineCardSkeleton className={classes.card} key={uuid()} />)}
            {failedToGetOrders && <ErrorPlaceholder error={failedToGetOrders.message} />}
            {!orders.length && !gettingOrders && !failedToGetOrders && (
              <EmptyPlaceholder
                label={orderType === OrderDirection.Bids ? t('trade:myTable.emptyBid') : t('trade:myTable.emptyOffer')}
                ActionButton={
                  <Button variant="contained" color="primary" size="large" onClick={() => history.push('/discover')}>
                    {t('common:discover')}
                  </Button>
                }
              />
            )}
            {!gettingOrders &&
              !failedToGetOrders &&
              orders.length > 0 &&
              orders.map((order) => (
                <OrderCard
                  viewType={orderType}
                  className={classes.card}
                  key={order.orderId}
                  order={order}
                  onDeleteClicked={handleCancel}
                  onEditClicked={handleEdit}
                />
              ))}
          </div>
        ) : (
          Table
        )}
        <TablePagination
          component="div"
          rowsPerPageOptions={PAGE_SIZE_VARIANTS}
          count={resultsCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeResultsPerPage}
        />
        {cancelModalOpen && selectedOrder && orderType === OrderDirection.Bids && (
          <CancelBidModal
            onCancelConfirm={handleCancelConfirm}
            open={cancelModalOpen}
            onClose={handleCancelModalClose}
          />
        )}

        {editModalOpen &&
          selectedOrder &&
          selectedOrder.status === AssetStatus.Live &&
          orderType === OrderDirection.Bids && (
            <OrderModal
              mode={Mode.Edit}
              assetId={selectedOrder.assetId}
              open={editModalOpen}
              onClose={handleEditClose}
              systemOrderId={selectedOrder.orderId}
              clientOrderId={selectedOrder.clientOrderId}
              tradeType="bid"
            />
          )}
        {proccessingCancelOpen &&
          selectedOrder &&
          selectedOrder.status === AssetStatus.Live &&
          orderType === OrderDirection.Bids && (
            <OrderModal
              mode={Mode.Cancel}
              open={proccessingCancelOpen}
              assetId={selectedOrder.assetId}
              systemOrderId={selectedOrder.orderId}
              clientOrderId={selectedOrder.clientOrderId}
              tradeType="bid"
              onClose={handleCancelClose}
            />
          )}
        {cancelModalOpen && selectedOrder && orderType === OrderDirection.Offers && (
          <CancelOfferModal
            onCancelConfirm={handleCancelConfirm}
            open={cancelModalOpen}
            onClose={handleCancelModalClose}
          />
        )}
        {editModalOpen &&
          selectedOrder &&
          selectedOrder.status === OrderStatus.Live &&
          orderType === OrderDirection.Offers && (
            <OrderModal
              mode={Mode.Edit}
              assetId={selectedOrder.assetId}
              open={editModalOpen}
              onClose={handleEditClose}
              systemOrderId={selectedOrder.orderId}
              clientOrderId={selectedOrder.clientOrderId}
              tradeType="offer"
            />
          )}
        {proccessingCancelOpen &&
          selectedOrder &&
          selectedOrder.status === AssetStatus.Live &&
          orderType === OrderDirection.Offers && (
            <OrderModal
              mode={Mode.Cancel}
              open={proccessingCancelOpen}
              assetId={selectedOrder.assetId}
              systemOrderId={selectedOrder.orderId}
              clientOrderId={selectedOrder.clientOrderId}
              tradeType="offer"
              onClose={handleCancelClose}
            />
          )}
      </div>
    </div>
  );
}

export default function MyOrdersWithContext(props: MyOrdersProps): JSX.Element {
  return (
    <SelectedRowsProvider>
      <MyOrders {...props} />
    </SelectedRowsProvider>
  );
}
