import { graphql } from '../../__generated__';

export const WATCHLIST_ITEMS = graphql(`
  query WatchlistItems {
    watchListItems {
      calculatedMarketData {
        combinedScore
        performance {
          valueDifference
          percentageDifference
        }
      }
      asset {
        id
        vintage {
          id
          imageFileName
          wine {
            id
            name
            country
            region {
              id
              name
            }
          }
          vintage
        }
        spread {
          percentageDifference
          lowestOffer {
            userId
            price
          }
          highestBid {
            userId
            price
          }
        }
        unitSize
        unitCount
        marketValue
      }
    }
  }
`);
