import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import Typography from '../Typography';
import { useMyOrders } from '../../hooks/useMyOrders';
import { colours } from '../../theme/onboarding/palette';
import { logError } from '../../utils/logger';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
    gap: theme.spacing(8),
    height: 300,
    paddingBottom: 0,
  },
  fontSize14px: {
    fontSize: '14px',
  },
  fontSize18px: {
    fontSize: '18px',
  },
  bold: {
    fontWeight: 'bold',
  },
  purpleMid: {
    color: colours.purpleMid,
  },
  green: {
    color: colours.brightGreen,
  },
}));

interface BuyNowProcessTransactionProps {
  clientOrderId: string | null;
  onFailure: () => void;
  onSuccess: () => void;
}

export default function BuyNowProcessingTransaction({
  clientOrderId,
  onFailure,
  onSuccess,
}: BuyNowProcessTransactionProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, error, startPolling, stopPolling } = useMyOrders();
  startPolling(5000);

  React.useEffect(() => {
    if (error) {
      logError({
        error,
        filename: 'BuyNowProcessingTransaction',
      });
      onFailure();
    }

    if (clientOrderId && data) {
      const order = data.omsGetMyOrders.find(
        (rawOrder) => rawOrder.clientOrderId.toUpperCase() === clientOrderId.toUpperCase(),
      );
      if (order && order.status === 'fulfilled') {
        stopPolling();
        onSuccess();
      }
      if (order && (order.status === 'cancelled' || order.status === 'rejected')) {
        stopPolling();
        logError({
          error: new Error(`Invalid order status: ${order.status}`),
          filename: 'BuyNowProcessingTransaction',
        });
        onFailure();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientOrderId, data, onFailure, onSuccess, stopPolling, error]);

  return (
    <div className={classes.modalContainer}>
      <Typography className={clsx(classes.fontSize18px, classes.bold)}>
        {t('product:detailsModal.buyNow.processing.title')}
      </Typography>
      <CircularProgress classes={{ circle: classes.green }} />
      <Typography className={clsx(classes.fontSize14px, classes.purpleMid)}>
        {t('product:detailsModal.buyNow.processing.message')}
      </Typography>
    </div>
  );
}
