import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: { display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  modalContainer: {
    maxWidth: 800,
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  inputLabel: {
    marginBottom: theme.spacing(3),
  },
  button: {
    alignSelf: 'flex-end',
  },
  halfWidth: {
    width: '48%',
    marginBottom: theme.spacing(5),
  },
  textField: {
    width: '100%',
  },
  checkboxContainer: {
    marginLeft: theme.spacing(0),
  },
  checkbox: {
    color: theme.palette.dividers.secondary,
  },
}));
