import { useQuery } from '@apollo/client';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { sort } from '@cultwines/zellar-client-sdk/utils/sort';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SelectedRowsProvider, useSelectedRowsContext } from '../../context/selectedRowsContext';
import { EXTERNAL_PORTFOLIO_QUERY } from '../../graphql/queries/externalPortfolio';
import {
  ExternalPortfolioItemFacets,
  selectExternalPortfolioItems,
} from '../../graphql/selectors/externalPortfolioItems';
import useMediaQuery from '../../hooks/useMediaQuery';
import { getPage } from '../../utils/getPage';
import { uuid } from '../../utils/uuid';
import EmptyPlaceholder from '../EmptyPlaceholder';
import ErrorPlaceholder from '../ErrorPlaceholder';
import Table from '../Table';
import Toolbar, { ViewType } from '../Table/Toolbar';
import { ExpandedContentProps, Headers, SortDirection } from '../Table/types';
import TablePagination from '../TablePagination';
import TransferAssetToExternalPortfolioModal from '../TransferAssetToExternalPortfolioModal';
import ExternalPortfolioCard from '../WineCard/ExternalPortfolioCard';
import WineCardSkeleton from '../WineCardSkeleton';
import ExternalPortfolioInstanceTable from './ExternalPortfolioInstanceTable';
import { selectTableData, TableData } from './selectors';
import { getSortOptions } from './sortOptions';
import CalculationToolTip from '../CalculationToolTip';
import { logError } from '../../utils/logger';
import { useShouldRenderMarketRoute } from '../../hooks/useShouldRenderMarketRoute';
import { TableRefactorFlagSet } from '../../types/FeatureFlags';
import ExternalPortfolioTable from '../ExternalPortfolioTable';
import { Modal } from '../../views/Discover/components/ActiveMarkets/types';
import OrderModal from '../OrderModal';
import { Mode } from '../OrderModal/types';
import BuyNowModal from '../BuyNowModal';
import { useQueryParameters } from '../../hooks';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gap: theme.spacing(4),
    },
  },
  cardList: {
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
    padding: '0 10px',
  },
  card: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: `calc(50% - ${theme.spacing(2)})`,
    },

    [theme.breakpoints.up('xl')]: {
      width: `calc(33% - ${theme.spacing(2.2)})`,
    },
  },
}));

const ExpandedContent = ({ rowId }: ExpandedContentProps) => <ExternalPortfolioInstanceTable assetId={Number(rowId)} />;

const PAGE_SIZE_VARIANTS = [10, 60, 90];

function ExternalPortfolios(): JSX.Element {
  const { tableRefactor } = useFlags<{ tableRefactor?: TableRefactorFlagSet }>();
  const { clickableTradeTiles13816 } = useFlags();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanXl = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xl));
  const { shouldRenderMarketRoute } = useShouldRenderMarketRoute();
  const { loading, data: tableDataRaw, error } = useQuery(EXTERNAL_PORTFOLIO_QUERY, { pollInterval: 3000 });
  const { state: selectedRowsState } = useSelectedRowsContext();
  const { showTopUpBuySuccessSummaryModal } = useCardPaymentCallback();
  const history = useHistory();
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc');
  const [sortFacet, setSortFacet] = React.useState<keyof ExternalPortfolioItemFacets>('name');
  const [page, setPage] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE_VARIANTS[0]);
  const [viewType, setViewType] = React.useState<ViewType>('table');
  const queryParams = useQueryParameters();
  const selectedModal = queryParams.get('modal');
  const selectedAssetId = queryParams.get('assetId');

  if (error) {
    logError({
      originalError: error,
      error: new Error('externalPortfolioUserAssets query failed'),
      filename: 'ExternalPortfolio',
    });
  }

  function handleSortUpdated(key: keyof ExternalPortfolioItemFacets): void;
  function handleSortUpdated(key: keyof ExternalPortfolioItemFacets, direction: SortDirection): void;
  function handleSortUpdated(key: keyof ExternalPortfolioItemFacets, direction?: SortDirection): void {
    if (!direction) {
      if (key !== sortFacet) {
        // a different facet has been selected
        setSortFacet(key);
        setSortDirection('asc');
      } else {
        // it's just the sort direction that has changed
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      }
    } else {
      setSortFacet(key);
      setSortDirection(direction);
    }
  }

  function handleChangePage(_: unknown, newPage: number): void {
    setPage(newPage);
  }

  function handleChangeResultPerPage(event: React.ChangeEvent<HTMLInputElement>): void {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleOpenBuyNowModalClicked(rowId: string | number) {
    history.push(`${history.location.pathname}?modal=${Modal.BuyNow}&assetId=${rowId}`);
  }

  function handleOpenBidModalClicked(rowId: string | number) {
    history.push(`${history.location.pathname}?modal=${Modal.Bid}&assetId=${rowId}`);
  }

  function handleCloseModal() {
    queryParams.delete('modal');
    queryParams.delete('assetId');
    history.push({ pathname: history.location.pathname, search: queryParams.toString(), hash: history.location.hash });
  }

  const common = {
    onClick: handleSortUpdated,
    direction: sortDirection,
  };

  const externalPortfolioItems = React.useMemo(
    () =>
      selectExternalPortfolioItems(tableDataRaw)
        .sort((a, b) => sort(a[sortFacet], b[sortFacet], sortDirection))
        .slice(...getPage(page, pageSize)),
    [tableDataRaw, sortFacet, sortDirection, page, pageSize],
  );
  const tableData = React.useMemo(
    () =>
      selectTableData({
        externalPortfolioItems,
        handleOpenBuyNowModalClicked,
        handleOpenBidModalClicked,
        clickableTradeTiles13816,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [externalPortfolioItems],
  );

  const headers: Headers<Omit<TableData, 'rowId'>, keyof ExternalPortfolioItemFacets> = {
    name: {
      title: t('portfolio:table.headers.wineName'),
      id: 'name',
      active: sortFacet === 'name',
      ...common,
    },
    vintage: { title: t('common:vintage'), id: 'vintage', active: sortFacet === 'vintage', ...common },
    score: { title: t('portfolio:table.headers.score'), id: 'score', active: sortFacet === 'score', ...common },
    drinkingWindow: { title: t('portfolio:table.headers.drinkingWindow') },
    units: { title: t('trade:units'), id: 'units', active: sortFacet === 'units', ...common },
    unitSize: { title: t('common:unitSize'), id: 'unitSize', active: sortFacet === 'unitSize', ...common },
    totalPurchasePrice: { title: t('portfolio:table.headers.totalPurchasePrice') },
    totalMarketValue: {
      title: t('portfolio:table.headers.totalMarketValuation'),
      id: 'marketValue',
      active: sortFacet === 'marketValue',
      extraElement: <CalculationToolTip title={t('market:marketValueCalculation')} />,
      ...common,
    },
    performance: {
      title: t('portfolio:table.headers.performance'),
      id: 'performancePercentageDelta',
      active: sortFacet === 'performancePercentageDelta',
      ...common,
    },
    highestBid: {
      title: t('product:trading.highestBid'),
      id: 'highestBid',
      active: sortFacet === 'highestBid',
      ...common,
    },
    lowestOffer: {
      title: t('product:trading.lowestOffer'),
      id: 'lowestOffer',
      active: sortFacet === 'lowestOffer',
      ...common,
    },
  };

  const sortOptions = React.useMemo(() => getSortOptions(sortDirection, sortFacet, t), [sortDirection, sortFacet, t]);

  function handleCompareClick(id: string | number): void {
    history.push(`/market?assets=${id}`);
  }

  function handleToolbarSortChanged(id: string): void {
    const opt = sortOptions.find((sO) => sO.id === id);
    if (isUndefined(opt)) {
      return;
    }

    setSortFacet(opt.key);
    setSortDirection(opt.direction);
  }

  function handleBulkCompareClicked() {
    history.push(`/market?assets=${selectedRowsState.selectedRows.join(',')}`);
  }

  const TableComponent = tableRefactor?.externalPortfolio ? (
    <ExternalPortfolioTable
      assets={externalPortfolioItems}
      sortColumn={sortFacet}
      sortDirection={sortDirection}
      onSortChanged={handleSortUpdated}
      loading={loading}
      error={error?.message}
      onCompareClicked={handleCompareClick}
      onEmptyClicked={() => setOpenModal(true)}
      onBuyNowClicked={handleOpenBuyNowModalClicked}
      onBidClicked={handleOpenBidModalClicked}
    />
  ) : (
    <Table
      data={tableData}
      headers={headers}
      emptyTableText={t('portfolio:noWines')}
      EmptyTableAction={
        <Button variant="contained" color="primary" size="large" onClick={() => setOpenModal(true)}>
          {t('portfolio:addWines')}
        </Button>
      }
      loading={loading}
      error={error?.message ?? null}
      actions={{
        compare: { onCompareClicked: handleCompareClick },
      }}
      SubContent={ExpandedContent}
    />
  );

  return (
    <div>
      <Toolbar
        resultCount={tableDataRaw?.externalPortfolioUserAssets.length ?? 0}
        viewType={viewType}
        onChangeViewType={setViewType}
        onSortChanged={handleToolbarSortChanged}
        sortOptions={sortOptions}
        hideChangeViewType={lessThanXl}
        displayCompareButton
        onCompareClicked={handleBulkCompareClicked}
        addWineToExternalPortfolio
        addWineToExternalPortfolioClicked={() => setOpenModal(true)}
      />
      {viewType === 'card' || lessThanXl ? (
        <div className={classes.cardList}>
          {loading &&
            !error &&
            new Array(20).fill(0).map(() => <WineCardSkeleton className={classes.card} key={uuid()} />)}
          {error && <ErrorPlaceholder error={error.message} />}
          {!externalPortfolioItems.length && !error && !loading ? (
            <EmptyPlaceholder
              label={t('portfolio:noWines')}
              ActionButton={
                <Button variant="contained" color="primary" size="large" onClick={() => setOpenModal(true)}>
                  {t('portfolio:addWines')}
                </Button>
              }
            />
          ) : (
            externalPortfolioItems.map((portfolioItem) => (
              <ExternalPortfolioCard
                className={classes.card}
                key={`card-${portfolioItem.id}`}
                externalPortfolioItem={portfolioItem}
                actions={{
                  // TODO: Need to actually show the options menu here, and include
                  // transfer as an option. Currently the options action is a dud and
                  // is not fit for purpose -> see https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/6299
                  // & https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/6300
                  compare: shouldRenderMarketRoute ? { onCompareClicked: handleCompareClick } : undefined,
                }}
              />
            ))
          )}
        </div>
      ) : (
        TableComponent
      )}
      <TablePagination
        component="div"
        rowsPerPageOptions={PAGE_SIZE_VARIANTS}
        count={tableDataRaw?.externalPortfolioUserAssets.length ?? 0}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeResultPerPage}
      />
      {openModal && <TransferAssetToExternalPortfolioModal open={openModal} onClose={() => setOpenModal(false)} />}
      {selectedAssetId && selectedModal?.toLowerCase() === Modal.Bid && (
        <OrderModal
          assetId={Number(selectedAssetId)}
          open={selectedModal === Modal.Bid}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="bid"
        />
      )}
      {selectedAssetId && selectedModal?.toLowerCase() === Modal.BuyNow && (
        <BuyNowModal
          assetId={Number(selectedAssetId)}
          open={selectedModal === Modal.BuyNow}
          onClose={handleCloseModal}
        />
      )}
      {selectedAssetId && showTopUpBuySuccessSummaryModal && (
        <BuyNowModal assetId={Number(selectedAssetId)} open onClose={handleCloseModal} />
      )}
    </div>
  );
}

export default function ExternalPortfolioTableWithContext(): JSX.Element {
  return (
    // Maximum number of assets that can be selected for compare.
    <SelectedRowsProvider defaultState={{ maxRows: 8 }}>
      <ExternalPortfolios />
    </SelectedRowsProvider>
  );
}
