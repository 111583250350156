import Box from '@mui/material/Box';
import React from 'react';

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
  className?: string;
  paddingTop?: number;
}

export default function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, paddingTop = 6, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box paddingTop={paddingTop}>{children}</Box>}
    </div>
  );
}
