import { graphql } from '../../../__generated__';

export const EXPANDED_SEARCH_RESULT_DATA = graphql(`
  query ExpandedSearchResultData($assetId: Int!) {
    productAsset(assetId: $assetId) {
      calculatedMarketData {
        liquidityScore
      }
      tradingInfo {
        lastTraded
        lastTradedValue
      }
      vintage {
        imageFileName
        drinkingWindow {
          advice
          dateFrom
          dateTo
        }
        wine {
          sparkling
          classification
          producer {
            id
            name
          }
          region {
            id
            name
          }
        }
      }
      spread(selectLowestOffer: true) {
        percentageDifference
      }
    }
  }
`);
