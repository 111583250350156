import { isNull } from './isNull';
import selectChartExtremes from './selectChartExtremes';
import { Asset, HistoricalMarketDataChange } from '../__generated__/graphql';

export default function selectChartExtremesFromMultipleSeries(
  data: Asset[] | HistoricalMarketDataChange[] | null | undefined,
): [min: number, max: number] {
  if (isNull(data) || !data?.length) {
    return [0, 0];
  }
  const combinedSeries: { [key: string]: number }[] = data.map((d: Asset | HistoricalMarketDataChange) => {
    if ('marketData' in d) {
      return d?.marketData.wineSearcher;
    }
    return d?.wineSearcher;
  });
  let min = 9999;
  let max = 0;
  combinedSeries.forEach((series) => {
    const [seriesMin, seriesMax] = selectChartExtremes(series);
    if (min > seriesMin) {
      min = seriesMin;
    }
    if (max < seriesMax) {
      max = seriesMax;
    }
  });
  return [min, max];
}
