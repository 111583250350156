import makeStyles from '@mui/styles/makeStyles';
import { colours } from '../../theme/light/palette';

export const useStyles = makeStyles((theme) => ({
  container: {
    gap: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardItems: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '382px',
    maxHeight: '577px',
    overflow: 'auto',
    gap: theme.spacing(2),
  },
  cardItem: {
    background: colours.white,
    borderColor: theme.palette.dividers.primary,
  },
  placeholder: {
    background: colours.lightGrey,
    borderRadius: '10px',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colours.darkGrey,
    textTransform: 'capitalize',
    fontSize: 18,
    fontWeight: 700,
  },
  addCardBtn: {
    width: 85,
    height: 20,
    fontSize: 12,
    textTransform: 'capitalize',
    color: colours.lightGrey,
    background: colours.dmPurple,
    borderRadius: 4,
    padding: 0,
    marginRight: 13,
    '&:hover': {
      background: theme.palette.button.tertiary.background.hover,
    },
  },
}));
