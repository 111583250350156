import { graphql } from '../../__generated__';

export const UPDATE_CATEGORY = graphql(`
  mutation UpdateCategory($id: String!, $payload: JSON!) {
    updateCategory(id: $id, payload: $payload) {
      id
      channels {
        email
        push
      }
    }
  }
`);
