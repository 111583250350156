/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import {
  CheckboxFilter,
  Facet,
  Facets,
  isCheckboxesFacet,
  isRangeFacet,
  RangeFilter,
} from '@cultwines/zellar-client-sdk/types/Filters';
import { Box, CircularProgress } from '@mui/material';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { useMutation } from '@apollo/client';
import { FilterDispatch } from '@cultwines/zellar-client-sdk/hooks/useFilters';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '../Typography';
import RangeFilterComponent from './RangeFilter';
import CheckboxFiltersComponent from './CheckboxFilters';
import Divider from '../Divider';
import FilterChip from '../FilterChip';
import IconButton from '../IconButton';
import { SEARCH_WINE_VINTAGES_FACETS } from '../../graphql/mutations/searchWineVintagesFacets';
import { logError } from '../../utils/logger';
import { selectFacets } from '../../views/Search/selectors';
import { buildActiveFilterString } from './helper';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    overflowY: 'auto',
    borderRadius: 4,
    background: theme.palette.grey[50],
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      width: '440px',
    },
  },
  button: {
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    marginLeft: 'auto',
  },
  buttonContent: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    textDecoration: 'underline',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  activeFilters: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
    marginTop: 18,
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  alignRight: {
    marginLeft: 'auto',
  },
  icon: {
    stroke: theme.palette.textLabel,
  },
}));

function FacetComponent<T>({ facet, dispatch }: { facet: Facet<T>; dispatch: FilterDispatch<T> }) {
  return (
    <>
      <Divider variant="fullWidth" />
      {isRangeFacet<T>(facet) && (
        <RangeFilterComponent
          facet={facet}
          onChange={(filter) =>
            dispatch({
              type: 'toggleFilter',
              payload: filter,
            })
          }
        />
      )}
      {isCheckboxesFacet<T>(facet) && (
        <CheckboxFiltersComponent
          facet={facet}
          onChecked={(filter) =>
            dispatch({
              type: 'toggleFilter',
              payload: filter,
            })
          }
          onUnchecked={(filter) => dispatch({ type: 'toggleFilter', payload: filter })}
        />
      )}
    </>
  );
}

interface SearchFiltersProps<T> {
  className?: string;
  queryString: string;
  preQueryString: string;
  filterNames: string[];
  facets: Facets<T>;
  activeFilters: Array<RangeFilter<T> | CheckboxFilter<T>>;
  dispatch: FilterDispatch<T>;
  onClose: () => void;
}

export default function SearchFiltersComponent<T>({
  facets,
  activeFilters,
  dispatch,
  onClose,
  queryString,
  preQueryString,
  filterNames,
  className = '',
}: SearchFiltersProps<T>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchWineVintagesFacets, { loading }] = useMutation(SEARCH_WINE_VINTAGES_FACETS);

  React.useEffect(() => {
    async function loadData() {
      try {
        const facetsResult = await searchWineVintagesFacets({
          variables: {
            queryString,
            preQueryString: buildActiveFilterString(activeFilters),
            filterNames: filterNames.length === 0 ? ['*'] : filterNames,
          },
        });

        if (isUndefined(facetsResult.data?.searchWineVintagesFacets?.facets)) {
          throw new Error('failed');
        }

        // Facets will have been updated as a result of this query, so
        // make sure to merge the incoming facets with the current ones.
        dispatch({
          type: 'mergeFacets',
          // @ts-ignore
          payload: selectFacets(facetsResult.data?.searchWineVintagesFacets?.facets, t),
        });
      } catch (e) {
        logError({ error: e as Error, tags: { userFlow: 'search' } });
      }
    }

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString, preQueryString]);

  const filterControls = React.useMemo(() => {
    return Object.keys(facets).map((key) => {
      return <FacetComponent dispatch={dispatch} facet={facets[key]} key={key} />;
    });
  }, [facets, dispatch]);

  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.header}>
        <Typography customVariant="h5">{t('search:filters.title')}</Typography>
        <IconButton className={classes.alignRight} onClick={onClose} size="large">
          <Close className={classes.icon} />
        </IconButton>
      </div>
      {loading && activeFilters.length === 0 ? (
        <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {activeFilters.length > 0 && (
            <>
              <Divider variant="fullWidth" />
              <div>
                <div className={classes.header}>
                  <Typography customVariant="subtitle2">{t('search:currentFilters')}</Typography>
                  <button
                    onClick={() => dispatch({ type: 'clearAllActiveFilters' })}
                    className={classes.button}
                    type="button"
                  >
                    <Typography className={classes.buttonContent} customVariant="subtitle2">
                      {t('market:clear')}
                    </Typography>
                  </button>
                </div>
                <div className={classes.activeFilters}>
                  {activeFilters.map((af) => (
                    <FilterChip key={af.id} filter={af} dispatch={dispatch} />
                  ))}
                </div>
              </div>
            </>
          )}
          {filterControls}
          <Divider variant="fullWidth" />
        </>
      )}
    </div>
  );
}
