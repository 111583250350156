/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxesFacet, CheckboxFilter } from '@cultwines/zellar-client-sdk/types/Filters';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '../Typography';
import FilterContainer from './FilterContainer';
import TextField from '../TextField';
import DotGauge from '../DotGauge';
import { getFilterfilters } from '../../utils/getFilterfilters';

const ITEM_HEIGHT = 24;
const MAX_HEIGHT = 208;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'flex-start',
  },
  noFiltersText: {
    fontStyle: 'italic',
    marginLeft: 4,
  },
  textbox: { marginBottom: 10 },
  filter: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    height: ITEM_HEIGHT,
  },
  capitalise: {
    textTransform: 'capitalize',
  },
}));

interface CheckboxFiltersProps<T> {
  facet: CheckboxesFacet<T>;
  onChecked: (filter: CheckboxFilter<T>) => void;
  onUnchecked: (filter: CheckboxFilter<T>) => void;
}

function Row({ style, index, data }: ListChildComponentProps): JSX.Element {
  const { filters, onChecked, onUnchecked } = data;
  const classes = useStyles();
  const filter = filters[index];
  return (
    <div className={classes.filter} key={filter.id} style={style}>
      <Checkbox
        name={filter.label}
        checked={filter.checked}
        onChange={(_, checked) =>
          checked ? onChecked({ ...filter, checked: true }) : onUnchecked({ ...filter, checked: false })
        }
        color="primary"
      />
      <InputLabel htmlFor={filter.label}>
        <Typography className={classes.capitalise} variant="body1">
          {filter.parentId === 'LiquidityRank' ? (
            <DotGauge dotCount={5} filledDots={Number(filter.label)} />
          ) : (
            filter.label
          )}
        </Typography>
      </InputLabel>
      <Typography variant="body1">({filter.count})</Typography>
    </div>
  );
}

function calculateHeight(count: number): number {
  if (count > 10) {
    return MAX_HEIGHT;
  }

  return count * ITEM_HEIGHT;
}

export default function CheckboxFiltersComponent<T>({
  facet,
  onChecked,
  onUnchecked,
}: CheckboxFiltersProps<T>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filterString, setFilterString] = React.useState<string>('');
  const subTitle = React.useMemo(
    () => `${facet.filters.filter((f) => f.checked).length} ${t('search:selected')}`,
    [facet, t],
  );

  const handleTextFieldChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setFilterString(event.target.value);
  };

  const filteredFilters = getFilterfilters(facet, filterString);

  return (
    <FilterContainer title={facet.name} subTitle={subTitle}>
      {facet.searchBoxPlaceholderText?.length && (
        <TextField
          className={classes.textbox}
          placeholder={facet.searchBoxPlaceholderText}
          variant="outlined"
          onChange={handleTextFieldChange}
          value={filterString}
        />
      )}
      {filteredFilters.length === 0 && (
        <Typography zellarColour="textLabel" customVariant="body1" className={classes.noFiltersText}>
          {t('search:filters.noFilterResults')}
        </Typography>
      )}
      <List
        height={calculateHeight(filteredFilters.length)}
        itemCount={filteredFilters.length}
        itemSize={ITEM_HEIGHT}
        width="100%"
        itemData={{ filters: filteredFilters, onChecked, onUnchecked }}
      >
        {Row}
      </List>
    </FilterContainer>
  );
}
