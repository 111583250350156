import React, { Fragment } from 'react';
import { ApolloError } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import Divider from '../../Divider';
import AssetInstance, { AssetInstanceProps as InstanceProps } from './HoldingAssetInstance';
import { useLastIndexAvailableForOffer } from '../../../hooks/useLastIndexAvailableForOffer';
import { ModifiedAssetInstance as OwnedAssetInstances_productAssetInstances } from '../../../types/AssetInstance';

const useStyles = makeStyles((theme) => ({
  instanceSkeleton: {
    width: `calc(100% - ${theme.spacing(2.5)})`,
    height: '80px',
    margin: theme.spacing(2.5),
  },
  divider: {
    marginLeft: -24,
    marginRight: -24,
  },
  dividerContainer: {
    width: '100%',
  },
}));

interface AssetInstancesProps {
  assetId: number;
  AssetInstanceProps?: Partial<InstanceProps>;
  data: OwnedAssetInstances_productAssetInstances[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  wineName: string;
  unitCount: number;
  unitSize: string;
  marketValue: number;
}

export default function AssetInstances({
  data,
  assetId,
  AssetInstanceProps,
  loading,
  error,
  wineName,
  unitCount,
  unitSize,
  marketValue,
}: AssetInstancesProps): JSX.Element {
  const classes = useStyles();
  const { lastIndexAvailableForOffer } = useLastIndexAvailableForOffer({ assetId });

  if (error) {
    return <div>{error.message}</div>;
  }
  if (loading) {
    return (
      <>
        {new Array(6).fill(0).map((_, i) => (
          <Skeleton
            className={classes.instanceSkeleton}
            variant="rectangular"
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          />
        ))}
      </>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {data?.map((instance, i) => (
        <Fragment key={assetId}>
          <AssetInstance
            assetId={assetId}
            key={`${assetId}-${instance.id}`}
            instance={instance}
            className={AssetInstanceProps ? AssetInstanceProps.className : ''}
            wineName={wineName}
            unitCount={unitCount}
            unitSize={unitSize}
            marketValue={marketValue}
            transferDisabled={instance.transferRequested || i > lastIndexAvailableForOffer}
          />
          {i < data?.length && (
            <div className={classes.dividerContainer}>
              <Divider className={classes.divider} variant="fullWidth" customVariant="tertiary" />
            </div>
          )}
        </Fragment>
      ))}
    </>
  );
}
