import { ApolloError, useQuery } from '@apollo/client';
import { AddressType } from '@cultwines/zellar-client-sdk';
import { GET_ADDRESSES_QUERY } from '../graphql/queries/userAddressesQuery';
import { selectAddress } from '../graphql/selectors/userAddressSelectors';
import { Address } from '../__generated__/graphql';

interface UseAddressProps {
  addressType: AddressType;
}

interface UseAddressReturnType {
  loading: boolean;
  error: ApolloError | undefined;
  address: Address | null;
}

export function useAddress({ addressType }: UseAddressProps): UseAddressReturnType {
  const { data: addressQueryResponse, loading, error } = useQuery(GET_ADDRESSES_QUERY);
  const address = selectAddress(addressQueryResponse, addressType);
  return {
    address,
    loading,
    error,
  };
}
