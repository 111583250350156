import { graphql } from '../../__generated__';

export const EXTERNAL_PORTFOLIO_INSTANCE_QUERY = graphql(`
  query ExternalPortfolioInstance($assetId: Int!) {
    externalPortfolioUserAssetInstances(assetId: $assetId) {
      id
      purchasePrice
      purchaseDate
    }
  }
`);
