import Checkbox from '@mui/material/Checkbox';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectedRowsContext } from '../../../../context/selectedRowsContext';
import { formatter } from '../../../../utils/currencyFormatter';
import { humanReadableDate } from '../../../../utils/humanReadableDate';
import GridItem from '../../../SearchResults/GridItem';
import Pill from '../../../Pill';
import Typography from '../../../Typography';
import { useAssetInstanceStyles } from './styles';

import { isNullOrUndefined } from '../../../../utils/isNullOrUndefined';
import { ModifiedAssetInstance as OwnedAssetInstances_productAssetInstances } from '../../../../types/AssetInstance';

export interface AssetInstanceProps {
  className?: string;
  instance: OwnedAssetInstances_productAssetInstances;
  assetId: number;
  wineName: string;
  unitSize: string;
  unitCount: number;
  marketValue: number;
  transferDisabled: boolean;
}

export default function AssetInstance({
  className = '',
  instance,
  assetId,
  wineName,
  unitCount,
  unitSize,
  marketValue,
  transferDisabled,
}: AssetInstanceProps): JSX.Element {
  const classes = useAssetInstanceStyles();
  const { transferAssetsOutOfCultX } = useFlags();
  const { t } = useTranslation();
  const { state, dispatch } = useSelectedRowsContext();
  const { entryDate, location, purchasePrice, valuation, id, transferRequested } = instance;

  function handleTransferCheckboxChangedInternal(e: React.ChangeEvent<HTMLInputElement>, subRowId: string): void {
    if (e.target.checked) {
      dispatch({
        type: 'rowTransferChecked',
        payload: {
          type: 'AssetInstance',
          id,
          assetId,
          currentLocation: instance.location,
          purchasedPrice: instance.purchasePrice,
          wineName,
          unitSize: `${unitCount} x ${unitSize}`,
          marketValue,
        },
      });

      dispatch({
        type: 'rowTransferChecked',
        payload: {
          type: 'ParentAsset',
          id: assetId,
          assetId,
        },
      });
    } else {
      dispatch({ type: 'rowTransferUnchecked', payload: assetId });
      dispatch({ type: 'rowTransferUnchecked', payload: subRowId });
    }
  }
  return (
    <div className={`${className} ${classes.container}`}>
      <div className={classes.row}>
        <GridItem
          label="location"
          value={
            <Typography className={classes.capitalise} customVariant="subtitle2">
              {location}
            </Typography>
          }
          labelVariant="caption"
        />
        <GridItem
          label={t('portfolio:table.headers.entryDate')}
          labelVariant="caption"
          value={humanReadableDate(entryDate)}
        />

        {state.selectTransferModeActive && (
          <Checkbox
            data-testid="instance-checkbox"
            className={classes.checkbox}
            checked={state.selectedTransferedRows.map((x) => x.id).includes(id) || !!transferRequested}
            onChange={(e) => handleTransferCheckboxChangedInternal(e, id.toString())}
            name="makeAsDefault"
            inputProps={{ 'aria-label': 'instance-card-checkbox' }}
            color="primary"
            disabled={transferDisabled}
          />
        )}
        <GridItem
          label={t('portfolio:table.headers.purchasePrice')}
          labelVariant="caption"
          value={formatter.format(purchasePrice)}
        />
        <GridItem
          label={t('portfolio:table.headers.marketValuation')}
          labelVariant="caption"
          value={!isNullOrUndefined(valuation) ? formatter.format(valuation) : t('product:notAvailable')}
        />
        {transferAssetsOutOfCultX && transferRequested && (
          <Pill colour="#FB9D0E" text={t('portfolio:transfer.requested')} />
        )}
      </div>
    </div>
  );
}
