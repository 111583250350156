import { graphql } from '../../__generated__';

export const ORDER_BOOK_QUERY = graphql(`
  query OrderBook($wineVintageId: Int!) {
    productWineVintage(wineVintageId: $wineVintageId) {
      assets {
        id
        unitCount
        unitSize
        openOrders {
          direction
          price
          outstandingQuantity
        }
        recentWineSearcherOffer {
          offers {
            price
          }
        }
        tradingInfo {
          lastTraded
          lastTradedValue
        }
      }
    }
  }
`);
