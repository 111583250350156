import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import CalculationToolTip from '../CalculationToolTip';

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'inherit',
    paddingTop: 6,
    paddingBottom: 6,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '300px',
  },
  title: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    background: 'inherit',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    gap: theme.spacing(2),
  },
  children: {
    marginTop: 8,
  },
}));

interface FilterContainerProps {
  title: string;
  subTitle?: string;
  className?: string;
  children?: React.ReactNode;
}

export default function FilterContainer({
  title,
  subTitle,
  className = '',
  children,
}: FilterContainerProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(true);
  return (
    <div className={`${classes.container} ${className}`}>
      <button className={classes.title} onClick={() => setExpanded(!expanded)} type="button">
        <Typography customVariant="subtitle2">{title}</Typography>
        {title === 'Liquidity' && <CalculationToolTip title={t('portfolio:liquidityScoreCalculation')} />}
        <div className={classes.right}>
          {subTitle && (
            <Typography customVariant="subtitle2" zellarColour="textInformation">
              {subTitle}
            </Typography>
          )}
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </div>
      </button>
      <Collapse in={expanded}>
        <div className={classes.children}>{children}</div>
      </Collapse>
    </div>
  );
}
