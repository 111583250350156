import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles(() => ({
  roundedCorners: {
    borderRadius: 4,
  },
}));
interface Props {
  className?: string;
}
export default function WineCardSkeleton({ className }: Props): JSX.Element {
  const classes = useStyles();
  return <Skeleton classes={{ root: `${className} ${classes.roundedCorners}` }} variant="rectangular" height={350} />;
}
