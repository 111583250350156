import { TableRow } from '@mui/material';
import React from 'react';
import TableCell from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { ExternalPortfolioItem } from '../../graphql/selectors/externalPortfolioItems';
import { Controls } from '../CultXPortfolioTable/Controls';
import WineNameCell from '../Table/Cells/WineName';
import BasicCell from '../Table/Cells/Basic';
import ScoreCell from '../Table/Cells/Score';
import DrinkingWindowCell from '../Table/Cells/DrinkingWindow';
import selectDrinkingWindowAdvice from '../../graphql/selectors/selectDrinkingWindowAdvice';
import { formatter } from '../../utils/currencyFormatter';
import SparklineCell from '../Table/Cells/Sparkline';
import TradingTile from '../TradingTile';
import ActionsCell from '../TableElements/ActionsCell';
import IconButton from '../IconButton';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import ExternalPortfolioInstanceTable from '../ExternalPortfolio/ExternalPortfolioInstanceTable';
import { SelectedRowsState, useSelectedRowsContext } from '../../context/selectedRowsContext';
import { SelectMode } from '../CultXPortfolioTable/types';
import UnitSizeCell from '../Table/Cells/UnitSize';
import { COLUMN_COUNT } from './types';
import { getProductUrl } from '../../utils/common';

interface TableRowProps {
  asset: ExternalPortfolioItem;
  onCompareClicked: (assetId: number) => void;
  onBuyNowClicked: (assetId: number) => void;
  onBidClicked: (assetId: number) => void;
}

function getSelectMode(selectedRowsState: SelectedRowsState): SelectMode | undefined {
  if (selectedRowsState.selectModeActive) {
    return 'compare';
  }

  return undefined;
}

export default function ExternalPortfolioTableRow({
  asset,
  onCompareClicked,
  onBuyNowClicked,
  onBidClicked,
}: TableRowProps): JSX.Element {
  const [expanded, setExpanded] = React.useState(false);
  const { state: selectedAssetsState, dispatch } = useSelectedRowsContext();
  const selectMode = getSelectMode(selectedAssetsState);
  const { clickableTradeTiles13816 } = useFlags();

  function handleCheckboxClicked(): void {
    dispatch({
      type: selectedAssetsState.selectedRows.map((sr) => Number(sr)).includes(asset.id) ? 'rowUnchecked' : 'rowChecked',
      payload: asset.id.toString(),
    });
  }

  const { name: wineName, vintage, id: assetId, unitSize, unitCount } = asset;
  const prodUrl = getProductUrl({ wineName, vintage, assetId, unitSize, unitCount });

  return (
    <>
      <TableRow key={asset.id}>
        <Controls
          onCheckedChanged={handleCheckboxClicked}
          expanded={expanded}
          setExpanded={setExpanded}
          checked={selectedAssetsState.selectedRows.map((sr) => Number(sr)).includes(asset.id)}
          selectMode={selectMode}
          disableCheckbox={
            selectedAssetsState.limitReached &&
            !selectedAssetsState.selectedRows.map((sr) => Number(sr)).includes(asset.id)
          }
        />
        <WineNameCell wineName={asset.name} appellation={null} link={`${prodUrl}`} region={asset.region} />
        <BasicCell mainContent={asset.vintage} />
        <ScoreCell score={asset.score} />
        <DrinkingWindowCell
          advice={selectDrinkingWindowAdvice(asset.drinkingWindow.advice)}
          date={asset.drinkingWindow.dateFrom}
        />
        <BasicCell mainContent={asset.units} />
        <UnitSizeCell unitCount={asset.unitCount} unitSize={asset.unitSize} />
        <BasicCell mainContent={formatter.format(asset.totalPurchasePrice)} />
        <BasicCell mainContent={formatter.format(asset.totalMarketValue)} />
        <SparklineCell data={{}} deltaNum={asset.performanceValueDelta} deltaPct={asset.performancePercentageDelta} />
        <TableCell>
          {clickableTradeTiles13816 ? (
            <TradingTile mode="bid" value={asset.highestBid} onClick={() => onBidClicked(asset.id)} />
          ) : (
            <TradingTile mode="bid" value={asset.highestBid} unitCount={asset.unitCount} unitSize={asset.unitSize} />
          )}
        </TableCell>
        <TableCell>
          {clickableTradeTiles13816 ? (
            <TradingTile
              mode="offer"
              value={asset.lowestOffer}
              onClick={!isUndefined(asset.lowestOffer) ? () => onBuyNowClicked(asset.id) : undefined}
            />
          ) : (
            <TradingTile mode="offer" value={asset.lowestOffer} unitCount={asset.unitCount} unitSize={asset.unitSize} />
          )}
        </TableCell>
        <ActionsCell>
          <IconButton
            onClick={() => onCompareClicked(asset.id)}
            size="large"
            disabled={selectedAssetsState.selectModeActive}
          >
            <CopyIcon style={{ width: '1em', height: '1em' }} />
          </IconButton>
        </ActionsCell>
      </TableRow>
      {expanded && !selectedAssetsState.selectModeActive && (
        <TableRow>
          <TableCell sx={{ padding: 0 }} colSpan={COLUMN_COUNT}>
            <Collapse in={expanded} unmountOnExit>
              <ExternalPortfolioInstanceTable assetId={asset.id} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
