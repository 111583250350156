import MuiDialogContent from '@mui/material/DialogContent';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}))(MuiDialogContent);

const DialogContentBorderBottomOnly = withStyles((theme: Theme) => ({
  dividers: {
    borderBottom: `1px solid ${theme.palette.dividers.primary}`,
    borderTop: 'none',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}))(MuiDialogContent);

const DialogContentBorderTopOnly = withStyles((theme: Theme) => ({
  dividers: {
    borderTop: `1px solid ${theme.palette.dividers.primary}`,
    borderBottom: 'none',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}))(MuiDialogContent);

export { DialogContent, DialogContentBorderBottomOnly, DialogContentBorderTopOnly };
