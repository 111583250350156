/**
 * A utility function for getting the slice of an array when doing client side pagination.
 * Important to note that this is 0 based, so for page 1 you need to do the following:
 *
 * ```ts
 * getPage(0, 20);
 * ```
 *
 * @param pageNumber The current page number (e.g. page 1 === 0)
 * @param pageSize The selected page size
 * @returns a tuple, first item is the start index, second number is the end index
 */
export function getPage(pageNumber: number, pageSize: number): [number, number] {
  if (pageSize === 0) {
    return [0, 0];
  }

  return [pageNumber * pageSize, (pageNumber + 1) * pageSize];
}
