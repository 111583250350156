import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    padding: 8,
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'inherit',
    border: 'none',
    flex: 1,
    '&:hover': {
      cursor: 'pointer',
      stroke: theme.palette.textHighlight,
      '& $icon': {
        stroke: theme.palette.textHighlight,
      },
    },

    '&:disabled': {
      cursor: 'not-allowed',
      '&:disabled $icon': {
        stroke: theme.palette.grey[100],
      },
    },
  },
  icon: {
    stroke: theme.palette.textPrimary,
  },
  text: {
    flex: 1,
    textAlign: 'center',
  },
  left: {
    justifyContent: 'flex-start',
  },
  right: {
    justifyContent: 'flex-end',
  },
}));

interface CounterProps {
  count: string;
  onAdd: () => void;
  onMinus: () => void;
  disableAdd?: boolean;
  disableMinus?: boolean;
  className?: string;
}

export default function Counter({
  count,
  onAdd,
  onMinus,
  disableAdd,
  disableMinus,
  className = '',
}: CounterProps): JSX.Element {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, className)}>
      <button className={clsx(classes.button, classes.left)} type="button" onClick={onMinus} disabled={disableMinus}>
        <MinusIcon className={classes.icon} />
      </button>
      <Typography className={classes.text} customVariant="body1">
        {count}
      </Typography>
      <button className={clsx(classes.button, classes.right)} type="button" onClick={onAdd} disabled={disableAdd}>
        <PlusIcon className={classes.icon} />
      </button>
    </div>
  );
}
