import React from 'react';
import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import { redirectToMobileAppStore } from '../../utils/redirectToMobileAppStore';
import AppLogo from '../../assets/images/logo-alt-2.png';
import { useRedirectToApp } from '../../hooks/useRedirectToApp';

export const BANNER_HEIGHT = 64;

interface BannerStyleProps {
  loginRoutes: boolean;
}

const useStyles = makeStyles<Theme, BannerStyleProps>((theme) => ({
  banner: {
    position: (props) => (props.loginRoutes ? 'fixed' : 'relative'),
    top: (props) => (props.loginRoutes ? 0 : undefined),
    background: '#FFF',
    color: '#150036',
    width: '100%',
    height: BANNER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 4,
    boxShadow: (props) => (props.loginRoutes ? 'none' : '0px 3px 4px 0px lightgrey'),
    marginBottom: 6,
    gap: theme.spacing(2),
    zIndex: theme.zIndex.appBar,

    '@media (min-width: 525px) and (min-height: 500px)': {
      display: 'none',
    },
  },

  openAppButton: {
    color: '#FFF',
    minWidth: 'fit-content',
    backgroundColor: '#FB9D0E',
    '&:focus': {
      backgroundColor: '#FB9D0E',
    },
    '&:active': {
      backgroundColor: '#FB9D0E',
    },
  },

  imageBackground: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 8px',
    borderRadius: 12,
    backgroundColor: '#150036',
  },

  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}));

interface BannerProps {
  loginRoutes?: boolean;
}

export default function Banner({ loginRoutes = false }: BannerProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles({ loginRoutes });
  const redirectToApp = useRedirectToApp();

  return (
    <div className={classes.banner}>
      <div className={classes.imageBackground}>
        <img src={AppLogo} alt="app logo" style={{ height: 28 }} />
      </div>
      <div>
        <Typography customVariant="body2Bold">{t('common:appNameSansYear')}</Typography>
        <Typography customVariant="body2">{t('common:mobileAppBannerText')}</Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button className={classes.openAppButton} size="small" variant="contained" onClick={redirectToApp}>
          {t('common:openApp')}
        </Button>
        <Button className={classes.openAppButton} size="small" variant="contained" onClick={redirectToMobileAppStore}>
          {t('common:intallApp')}
        </Button>
      </div>
    </div>
  );
}
