import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  borderBox: {
    width: '80%',
    marginBottom: '20px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    boxShadow: 'none',

    [theme.breakpoints.up('md')]: {
      border: `1px solid ${theme.palette.dividers.primary}`,
      borderRadius: 20,
      padding: '50px 75px',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '50px 125px',
    },
  },

  innerContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
    textAlign: 'center',
    alignItems: 'center',
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '90%',
    maxWidth: '480px',
  },

  icon: {
    width: '90px',
    height: '90px',

    [theme.breakpoints.up('sm')]: {
      width: '134px',
      height: '134px',
    },
  },

  label: {
    alignSelf: 'flex-start',
  },

  button: {
    padding: '8px 22px',
    minWidth: '120px',
  },

  helperText: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },

  links: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
  },
}));

export { useStyles };
