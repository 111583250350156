import { graphql } from '../../__generated__';

export const UPDATE_DEFAULT_MIT_CARD = graphql(`
  mutation UpdateDefaultMitCard($defaultMitCard: DefaultMitCardInput!) {
    updateDefaultMitCard(defaultMitCard: $defaultMitCard) {
      status
      errorMessage
    }
  }
`);
