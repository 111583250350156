import { graphql } from '../../__generated__';

export const SUGGEST_WINE_VINTAGES = graphql(`
  query SuggestWineVintages($queryString: String!) {
    suggestWineVintages(queryString: $queryString) {
      suggests {
        wineName
        region
        defaultAssetId
        vintage
        appellation
      }
      suggestedRegion {
        region
      }
      suggestedProducer {
        producer
      }
      suggestedAppellation {
        appellation
      }
    }
  }
`);
