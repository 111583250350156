import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Typography from '../Typography';
import DialogTitle from '../Dialog/DialogTitle';
import AddressSummary from '../AddressSummary';
import useUserDetails from '../../hooks/useUserDetails';
import EmptyAddressButton from '../EmptyAddressButton';
import { useAddress } from '../../hooks/useAddress';
import { logError } from '../../utils/logger';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  addressSummaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '18px',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      columnGap: '38px',
    },
  },
  addressSummary: {
    width: 'auto',

    '@media (min-width: 768px)': {
      width: '300px',
    },
  },
  confirmButton: {
    marginLeft: 'auto',
    height: 'fit-content',

    '@media (min-width: 768px)': {
      marginTop: 'auto',
    },
  },
  noAddressContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
  hint: {
    color: '#8A809A',
  },
}));

interface ReviewAddressProps {
  onClose: () => void;
  openAddressModal: () => void;
  openPaymentMethodModel: () => void;
}

/**
 * @deprecated we have combined billing address and payment method into one modal.
 * https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/13811
 */
export default function ReviewAddress({
  onClose,
  openAddressModal,
  openPaymentMethodModel,
}: ReviewAddressProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { address, error: useAddressError } = useAddress({ addressType: 'Billing' });
  const { userDetails, error: useUserDetailsError } = useUserDetails();

  if (useAddressError) {
    logError({
      originalError: useAddressError,
      error: new Error('Failed to load users billing address'),
      filename: 'ReviewAddress',
      tags: { userFlow: 'payment' },
    });
  }

  if (useUserDetailsError) {
    logError({
      originalError: useUserDetailsError,
      error: new Error('Failed to load users details'),
      filename: 'ReviewAddress',
      tags: { userFlow: 'payment' },
    });
  }
  return (
    <div className={classes.modalContainer}>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {t('wallet:billingAddressModal.title')}
      </DialogTitle>
      <DialogContentBorderTopOnly dividers>
        {address ? (
          <div className={classes.addressSummaryContainer}>
            <AddressSummary
              className={classes.addressSummary}
              address={address}
              name={`${userDetails?.firstName} ${userDetails?.surname}`}
              handleEditAddressClicked={openAddressModal}
            />
            <Button
              className={clsx(classes.confirmButton)}
              aria-label="confirm-button"
              variant="contained"
              color="primary"
              size="large"
              onClick={openPaymentMethodModel}
            >
              {t('product:detailsModal.confirm')}
            </Button>
          </div>
        ) : (
          <div className={classes.noAddressContainer}>
            <Typography customVariant="body1" className={classes.hint}>
              {t('wallet:billingAddressModal.hint')}
            </Typography>
            <EmptyAddressButton onClick={openAddressModal} />
          </div>
        )}
      </DialogContentBorderTopOnly>
    </div>
  );
}
