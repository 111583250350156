import { isUndefined } from '@cultwines/zellar-client-sdk';
import {
  Actions,
  BasicCell,
  CustomCell,
  DrinkingWindowCell,
  Row,
  ScoreCell,
  SparklineCell,
  UnitSizeCell,
  WineNameCell,
} from './types';

export function isScore(arg: unknown): arg is ScoreCell {
  return !isUndefined((arg as ScoreCell).score);
}

export function isBasic(arg: unknown): arg is BasicCell {
  return !isUndefined((arg as BasicCell).mainContent);
}

export function isWineName(arg: unknown): arg is WineNameCell {
  return !isUndefined((arg as WineNameCell).wineName);
}

export function isSparkline(arg: unknown): arg is SparklineCell {
  return !isUndefined((arg as SparklineCell).deltaNum);
}

export function isUnitSize(arg: unknown): arg is UnitSizeCell {
  return !isUndefined((arg as UnitSizeCell).unitCount);
}

export function isCustom(arg: unknown): arg is CustomCell {
  return !isUndefined((arg as CustomCell).content);
}

export function isDrinkingWindow(arg: unknown): arg is DrinkingWindowCell {
  return !isUndefined((arg as DrinkingWindowCell).advice);
}

/**
 * Actions can either be passed directly as a prop to the table, or be defined
 * on the table row level (this is a tech debt to be addressed under 7193).
 * Inspect them both to establish whether any actions are present.
 *
 * @param data the table data as these can have actions on them
 * @param actions the actions provided directly to the table
 * @returns
 */
export function hasActions<T extends Row>(data: T[], actions?: Actions): boolean {
  if (!isUndefined(actions) && Object.keys(actions).length > 0) {
    return true;
  }

  if (data.filter((d) => !isUndefined(d.actions)).length > 0) {
    return true;
  }

  return false;
}
