import { ApolloError, useQuery } from '@apollo/client';
import { SelectOption } from '@cultwines/zellar-client-sdk';
import { useMemo } from 'react';
import { selectActiveUnitSize } from '../components/Select/selectors';
import { UNIT_SIZE_QUERY } from '../graphql';
import { UnitSizeByAssetIdQueryVariables } from '../__generated__/graphql';

interface UseWalletBalanceHookResponse {
  unitSize: SelectOption | null;
  error: ApolloError | undefined;
  loading: boolean;
}

export const useUnitSize = ({ assetId }: UnitSizeByAssetIdQueryVariables): UseWalletBalanceHookResponse => {
  const { data, loading, error } = useQuery(UNIT_SIZE_QUERY, {
    variables: {
      assetId,
    },
  });
  const unitSize = useMemo(() => selectActiveUnitSize(data?.productAsset), [data]);

  return { unitSize, loading, error };
};
