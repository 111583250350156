import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { formatter } from '../../utils/currencyFormatter';
import Typography from '../Typography';
import Divider from '../Divider';
import { TradeVariant } from '../OrderModal/types';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.25),
    textTransform: 'uppercase',
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  fontSize14px: {
    fontSize: '14px',
  },
  fontWeight400: {
    fontWeight: 400,
  },
  fontWeight700: {
    fontWeight: 700,
  },
}));

interface CostBreakdownProps {
  variant: TradeVariant;
  transactionFee: number;
  totalPricePlusFees: number;
  totalUnitPrice: number;
  unitPrice: number;
  vat?: number;
  className?: string;
  showFees?: boolean;
}

export default function CostBreakdown({
  totalPricePlusFees,
  transactionFee,
  totalUnitPrice,
  unitPrice,
  variant,
  vat,
  className = '',
  showFees = true,
}: CostBreakdownProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={clsx(className, classes.container)}>
      <Typography className={clsx(classes.fontSize14px, classes.fontWeight700)}>
        {t(`product:detailsModal.${variant}.costBreakdown.title` as const)}
      </Typography>
      <Divider />
      <div className={classes.row}>
        <Typography zellarColour="textLabel" className={clsx(classes.fontSize14px, classes.fontWeight700)}>
          {t(`product:detailsModal.${variant}.costBreakdown.unitPrice` as const)}
        </Typography>
        <Typography className={clsx(classes.fontSize14px, classes.fontWeight700)}>
          {formatter.format(unitPrice)}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography zellarColour="textLabel" className={clsx(classes.fontSize14px, classes.fontWeight700)}>
          {t(`product:detailsModal.${variant}.costBreakdown.totalUnitPrice` as const)}
        </Typography>
        <Typography className={clsx(classes.fontSize14px, classes.fontWeight700)}>
          {formatter.format(totalUnitPrice)}
        </Typography>
      </div>
      {showFees && (
        <>
          <div className={classes.row}>
            <Typography zellarColour="textLabel" className={clsx(classes.fontSize14px, classes.fontWeight700)}>
              {t('product:detailsModal.buyNow.transactionFee', { fee: 2.5 })}
            </Typography>
            <Typography className={clsx(classes.fontSize14px, classes.fontWeight700)}>
              {formatter.format(transactionFee)}
            </Typography>
          </div>
          {!isUndefined(vat) && (
            <div className={classes.row}>
              <Typography zellarColour="textLabel" className={clsx(classes.fontSize14px, classes.fontWeight700)}>
                {t('common:vat')} (20%)
              </Typography>
              <Typography className={clsx(classes.fontSize14px, classes.fontWeight700)}>
                {formatter.format(vat)}
              </Typography>
            </div>
          )}
        </>
      )}
      <Divider />
      <div className={classes.row}>
        <Typography className={clsx(classes.fontSize14px, classes.fontWeight700)}>
          {t(`product:detailsModal.${variant}.costBreakdown.totalCost` as const)}
        </Typography>
        <Typography className={clsx(classes.fontSize14px, classes.fontWeight700)} data-testid="total-cost">
          {formatter.format(totalPricePlusFees)}
        </Typography>
      </div>
      <Divider />
    </div>
  );
}
