import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import Divider from '../../components/Divider';
import { useContentAreaPadding } from '../../components/AppLayout/useContentAreaPadding';
import MyAccountTabs from '../../components/MyAccountTabs';
import { useMyAccountStyles } from './styles';

export default function MyAccount(): JSX.Element {
  const appLayoutContentPadding = useContentAreaPadding();
  const { desktopOnly } = useFlags();
  const classes = useMyAccountStyles({ appLayoutContentPadding });
  return (
    <div className={classes.container}>
      {desktopOnly && <Divider className={classes.titleDivider} variant="fullWidth" customVariant="primary" />}
      <MyAccountTabs />
    </div>
  );
}
