import { isUndefined } from '@cultwines/zellar-client-sdk';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ListIcon } from '../../../../assets/icons/list.svg';
import { ReactComponent as CardIcon } from '../../../../assets/icons/card-view.svg';
import { ReactComponent as SortIcon } from '../../../../assets/icons/sort.svg';
import IconButton from '../../../../components/IconButton';
import Typography from '../../../../components/Typography';
import SortMenu, { SortOption } from '../../../../components/Table/SortMenu';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { SortDirection } from '../../../../components/Table/types';
import { ActiveMarketSortObject } from '../ActiveMarkets/types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  toolbar: {
    minHeight: '50px',
    display: 'flex',
  },
  right: {
    marginLeft: 'auto',
  },
  resultsCount: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: theme.spacing(3),
  },
  controls: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  icon: {
    stroke: theme.palette.textLabel,
  },
  fill: {
    fill: theme.palette.textLabel,
  },
  badge: {
    background: theme.palette.success.main,
    color: theme.palette.textPrimary,
  },
  compareSection: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  searchTextField: {
    marginLeft: 6,
    width: 400,
  },
  searchTextFieldInput: {
    borderRadius: '35px',
    height: 38,
  },
  searchTextFieldInputInner: {
    padding: 10,
  },
  mobileSearch: {
    width: '100%',
    marginBottom: 12,
  },
  circledCrossIcon: {
    padding: 0,
    stroke: theme.palette.grey[200],
  },
}));

type ViewType = 'card' | 'table';
interface ToolbarProps<K> {
  resultCount?: number;
  viewType?: ViewType;
  onChangeViewType?: (viewType: ViewType) => void;
  onSortChanged?: (id: string, sortDirection: SortDirection) => void;
  sortOptions?: SortOption<K>[];
  hideChangeViewType?: boolean;
  preferSortMenuButton?: boolean;
  customResultCount?: () => ReactNode;
  customFillterTool?: () => ReactNode;
  currentSelectedSort: SortOption<keyof ActiveMarketSortObject> | undefined;
  isLoadingSort?: boolean;
}

/**
 * ⚠️ This component relies on the `SelectedRowsProvider` being placed higher up in the component tree.
 */
export default function DiscoverToolBar<K>({
  resultCount,
  onChangeViewType,
  viewType,
  hideChangeViewType = false,
  sortOptions,
  onSortChanged,
  preferSortMenuButton = false,
  customResultCount,
  customFillterTool,
  currentSelectedSort,
  isLoadingSort,
}: ToolbarProps<K>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [sortMenuAnchor, setSortMenuAnchor] = React.useState<null | HTMLElement>(null);
  const sortMenuOpen = Boolean(sortMenuAnchor);

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>): void {
    setSortMenuAnchor(event.currentTarget);
  }

  function handleMenuClose(id: string, sortDirection: SortDirection): void {
    setSortMenuAnchor(null);

    if (onSortChanged) {
      onSortChanged(id, sortDirection);
    }
  }

  React.useEffect(() => {
    if (viewType === 'card') {
      setSortMenuAnchor(null);
    }
  }, [viewType]);

  const displaySortMenuButton = sortOptions && onSortChanged && (viewType === 'card' || preferSortMenuButton);

  return (
    <div className="tw-flex tw-flex-col tw-p-4">
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
        {!isUndefined(customResultCount) && <>{customResultCount()}</>}

        {!isUndefined(resultCount) && isUndefined(customResultCount) && (
          <Typography customVariant="subtitle3" zellarColour="textLabel">
            {resultCount} {resultCount === 1 ? t('common:result') : t('common:results')}
          </Typography>
        )}

        {!isUndefined(customFillterTool) && !lessThanMd && <div>{customFillterTool()}</div>}
        <div className="tw-flex tw-flex-row tw-items-center">
          {displaySortMenuButton && (
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
              {isLoadingSort ? (
                <div className="tw-w-[21px] tw-rounded-sm tw-h-[21px] tw-bg-gray-300  tw-animate-pulse" />
              ) : (
                <>
                  {!lessThanMd && (
                    <div className="tw-min-w-[115px] tw-text-right">
                      <Typography customVariant="subtitle3" className="tw-whitespace-nowrap" zellarColour="textLabel">
                        {t('common:sortBy')}: {currentSelectedSort?.primaryLabel}
                      </Typography>
                    </div>
                  )}
                  <IconButton onClick={handleMenuOpen} size="large">
                    <SortIcon className={classes.icon} />
                  </IconButton>
                </>
              )}
            </div>
          )}
          {viewType === 'card' && onChangeViewType && !hideChangeViewType && (
            <IconButton onClick={() => onChangeViewType('table')} size="large">
              <ListIcon className={classes.icon} />
            </IconButton>
          )}
          {viewType === 'table' && onChangeViewType && !hideChangeViewType && (
            <IconButton onClick={() => onChangeViewType('card')} size="large">
              <CardIcon />
            </IconButton>
          )}

          <SortMenu
            options={sortOptions ?? []}
            anchorEl={sortMenuAnchor}
            open={sortMenuOpen}
            optionSelected={handleMenuClose}
            onClose={() => setSortMenuAnchor(null)}
            /**
             * It's very awkward to simply place this popover menu below the button which opens it,
             * had to apply the following three props in this way to make it work. See
             * https://medium.com/cloud-native-the-gathering/material-ui-how-to-change-a-menus-menuitems-anchor-to-popover-below-instead-of-over-ab222e175cfd
             * for more info. Material UI by design has the popover appear over (z) the element you clicked to open it,
             * instead of below (y), which is what we want to do.
             */
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
        </div>
      </div>
      {!isUndefined(customFillterTool) && lessThanMd && <div className="tw-my-4">{customFillterTool()}</div>}
    </div>
  );
}
