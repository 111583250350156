export const colours = [
  '#882121',
  '#8B50A0',
  '#3C97DA',
  '#3DD499',
  '#DFC63B',
  '#E75656',
  '#522363',
  '#3E3495',
] as const;
