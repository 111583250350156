import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { colours } from '../../theme/light/palette';
import Typography from '../Typography';

interface Props {
  label: string;
  colour?: string;
}

const useStyles = makeStyles<Theme, { colour: string | undefined }>((theme) => ({
  container: {
    height: 29,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: ({ colour }) => colour || colours.cultOrange,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

function Badge({ label, colour }: Props): JSX.Element {
  const classes = useStyles({ colour });
  return (
    <div className={classes.container}>
      <Typography customVariant="body2Bold">{label}</Typography>
    </div>
  );
}

export default Badge;
