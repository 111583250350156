import { Badge, IconButton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CircledCheckMark } from '../../assets/icons/circled-checkmark_2.svg';
import { selectTimeAgoLabel } from '../../utils/selectTimeAgoLabel';
import Typography from '../Typography';
import { useStyles } from './styles';
import { Maybe, UpdateNotificationMutationVariables } from '../../__generated__/graphql';

export enum NotificationTypes {
  BidAccepted,
  OfferAccepted,
}

interface Props {
  index: number;
  id: string;
  title: string;
  label: string | null | undefined;
  type: NotificationTypes;
  dateTime: string;
  isRead: boolean;
  onClick: (index: number, params: UpdateNotificationMutationVariables) => Promise<void>;
  assetId: Maybe<number> | undefined;
  handleClose: () => void;
}

const NOTIFICATION_BID_SUCCESS_SUMMARY = 'Your bid has been successful';
const NOTIFICATION_OFFER_SUCCESS_SUMMARY = 'Your offer has been successful';

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      right: 24,
      top: -5,
      padding: '0 4px',
    },
  }),
)(Badge);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function selectIconByType(_: NotificationTypes): React.FC {
  // TODO: add additional types here
  return CircledCheckMark;
}

function NotificationCard({
  index,
  id,
  title,
  label,
  type,
  dateTime,
  isRead,
  onClick,
  assetId,
  handleClose,
}: Props): JSX.Element {
  const classes = useStyles();

  const timeAgoLabel = selectTimeAgoLabel(dateTime);
  const Icon = selectIconByType(type);
  const history = useHistory();

  async function handleClick() {
    // index is needed here so we know what "from"
    // parameter to use to update useQuery
    if (!isRead) {
      onClick(index, { id, payload: { isRead: true } });
    }

    if (title === NOTIFICATION_BID_SUCCESS_SUMMARY || title === NOTIFICATION_OFFER_SUCCESS_SUMMARY) {
      history.push('/portfolio');
      handleClose();
      return;
    }

    if (assetId) {
      history.push(`/product/${assetId}`);
      handleClose();
    }
  }

  return (
    <div onClick={handleClick} className={classes.container} aria-hidden="true">
      <div className={classes.horizontalContainer}>
        <div className={classes.iconAndTitle}>
          {!isRead ? (
            <IconButton size="large">
              <StyledBadge classes={{ badge: classes.badge }} variant="dot">
                <Icon />
              </StyledBadge>
            </IconButton>
          ) : (
            <IconButton size="large">
              <Icon />
            </IconButton>
          )}
          <Typography className={classes.title} zellarColour="textPrimary" customVariant="subtitle1">
            {title}
          </Typography>
        </div>
        <Typography zellarColour="textLabel" customVariant="body3" className={classes.uppercased}>
          {timeAgoLabel}
        </Typography>
      </div>
      {label && (
        <Typography zellarColour="textLabel" className={classes.label} customVariant="body2">
          {label}
        </Typography>
      )}
    </div>
  );
}

export default NotificationCard;
