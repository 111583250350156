import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '../Typography';
import { colours } from '../../theme/light/palette';

const useStyles = makeStyles((theme) => ({
  text: {
    width: 'fit-content',
    textTransform: 'uppercase',
    padding: '3px 6px',
    borderRadius: '4px',
  },
  hold: {
    background: colours.cultOrange,
    color: theme.palette.textSecondary,
  },
  drink: {
    background: colours.cultGreen,
  },
  past: {
    background: colours.cultYellow,
  },
  unknown: {
    background: theme.palette.grey[100],
  },
}));

export type DrinkingWindowAdvice = 'hold' | 'drink' | 'past' | 'unknown';

interface DrinkingWindowChipProps {
  advice: DrinkingWindowAdvice;
}

export default function DrinkingWindowChip({ advice }: DrinkingWindowChipProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const drinkingWindowAdviceI18n = t('common:drinkingWindow.advice', { returnObjects: true });

  return (
    <Typography customVariant="caption" className={`${classes.text} ${classes[advice]}`}>
      {drinkingWindowAdviceI18n[advice]}
    </Typography>
  );
}
