import { graphql } from '../../__generated__';

export const BUY_NOW = graphql(`
  mutation BuyNow($buyItNowInitiatedInput: BuyItNowInitiatedInput) {
    buyItNow(buyItNowInitiatedInput: $buyItNowInitiatedInput) {
      initiated
      errorMessage
      clientOrderId
    }
  }
`);
