import makeStyles from '@mui/styles/makeStyles';

export const useAssetInstanceStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },

  capitalise: {
    textTransform: 'capitalize',
  },

  row: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(10),
    rowGap: theme.spacing(4),
    alignItems: 'flex-start',
  },

  right: {
    marginLeft: 'auto',
  },

  transferLink: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    padding: `0 ${theme.spacing(2)}`,

    '&:hover': {
      background: 'none',
    },
  },

  link: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
  },

  wineTraceability: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  checkbox: {
    paddingTop: 10,
    '&.Mui-disabled': {
      color: theme.palette.grey[100],
    },
  },
}));
