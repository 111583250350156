import { Button, DialogContent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import * as yup from 'yup';
import { useFormik } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import InputMask from 'react-input-mask';
import { IdentificationFormModel } from './types';
import { useStyles } from './styles';
import DialogTitle from '../Dialog/DialogTitle';
import Divider from '../Divider';
import TextField from '../TextField';
import { isDateValid } from '../../utils/isDateValid';

interface Props {
  onClose: () => void;
  formModel: IdentificationFormModel;
  onNext: (model: IdentificationFormModel) => void;
}

export default function IdentificationModal({ onClose, formModel, onNext }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const kycIdentifyVerifySchema = yup.object({
    firstName: yup.string().required(t('common:kyc.verification.required.firstName')),
    middleName: yup.string(),
    lastName: yup.string().required(t('common:kyc.verification.required.lastName')),
    dob: yup
      .string()
      .required(t('common:kyc.verification.required.dob'))
      .test('val', t('common:kyc.verification.required.invalidFormat'), (val) => val === undefined || isDateValid(val)),
  });

  const formik = useFormik<yup.InferType<typeof kycIdentifyVerifySchema>>({
    initialValues: formModel,
    onSubmit: (value) => {
      onNext({
        ...value,
        middleName: value.middleName || '',
      });
    },
    validationSchema: kycIdentifyVerifySchema,
  });

  return (
    <div className={clsx([classes.idContainer], [classes.flex], [classes.gap3], [classes.column])}>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {t('common:kyc.verification.title')}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <form className={classes.container} onSubmit={formik.handleSubmit}>
          <div className={classes.oneLine}>
            <InputLabel htmlFor="firstName" className={classes.label}>
              <Typography variant="subtitle2">{t('common:kyc.verification.firstName')}*</Typography>
            </InputLabel>
            <TextField
              id="firstName"
              name="firstName"
              className={classes.field}
              value={formik.values.firstName}
              placeholder={t('common:kyc.verification.firstName')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              variant="outlined"
            />
          </div>
          <div className={classes.oneLine}>
            <InputLabel htmlFor="middleName" className={classes.label}>
              <Typography variant="subtitle2">{t('common:kyc.verification.middleName')}</Typography>
            </InputLabel>
            <TextField
              id="middleName"
              name="middleName"
              className={classes.field}
              value={formik.values.middleName}
              placeholder={t('common:kyc.verification.middleName')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.middleName && Boolean(formik.errors.middleName)}
              helperText={formik.touched.middleName && formik.errors.middleName}
              variant="outlined"
            />
          </div>
          <div className={classes.oneLine}>
            <InputLabel htmlFor="lastName" className={classes.label}>
              <Typography variant="subtitle2">{t('common:kyc.verification.lastName')}*</Typography>
            </InputLabel>
            <TextField
              id="lastName"
              name="lastName"
              className={classes.field}
              value={formik.values.lastName}
              placeholder={t('common:kyc.verification.lastName')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              variant="outlined"
            />
          </div>
          <div className={classes.oneLine}>
            <InputLabel htmlFor="dob" className={classes.label}>
              <Typography variant="subtitle2">{t('common:kyc.verification.dob')}*</Typography>
            </InputLabel>
            <InputMask
              mask="99/99/9999"
              value={formik.values.dob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {() => (
                <TextField
                  name="dob"
                  placeholder="DD/MM/YYYY"
                  error={formik.touched.dob && Boolean(formik.errors.dob)}
                  helperText={formik.touched.dob && formik.errors.dob}
                  variant="outlined"
                />
              )}
            </InputMask>
          </div>
          <Typography className={classes.description}>{t('common:kyc.verification.description')}</Typography>
          <Button
            className={clsx({ [classes.confirmButton]: true, [classes.right]: true })}
            variant="contained"
            color="primary"
            aria-label="confirm-identification-verification"
            size="large"
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            onClick={formik.submitForm}
          >
            {t('common:kyc.verification.confirm')}
          </Button>
        </form>
      </DialogContent>
    </div>
  );
}
