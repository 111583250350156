/* eslint-disable no-nested-ternary */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';
import { isNull } from '../../utils/isNull';
import CalculationToolTip from '../CalculationToolTip';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    color: theme.palette.secondary.contrastText,
  },
  skeleton: {
    background: theme.palette.grey[200],
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

interface PortfolioAttributeProps {
  value: number | null;
  label: string;
  loading: boolean;
  tooltipText?: string;
}

export default function PortfolioAttribute({
  value,
  label,
  loading,
  tooltipText,
}: PortfolioAttributeProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h3">
        {loading ? (
          <Skeleton width="120px" className={classes.skeleton} aria-label="capital-invested-skeleton" />
        ) : isNull(value) ? (
          t('common:currentlyUnavailable')
        ) : (
          `${value}%`
        )}
      </Typography>
      <Typography className={classes.flex} customVariant="body2">
        {label}
        {tooltipText && <CalculationToolTip title={tooltipText} />}
      </Typography>
    </div>
  );
}
