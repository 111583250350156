import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
}));

interface UnitSizeCellProps {
  unitCount: number;
  unitSize: string;
  active: boolean;
  className?: string;
}

function UnitSizeCell({ className = '', unitSize, unitCount, active }: UnitSizeCellProps): JSX.Element {
  const classes = useStyles();
  return (
    <Typography
      role="cell"
      customVariant={active ? 'body2Bold' : 'body2'}
      zellarColour={active ? 'textPrimary' : 'textInformation'}
      className={clsx(classes.label, className)}
    >
      {unitCount}x{unitSize}
    </Typography>
  );
}

export default UnitSizeCell;
