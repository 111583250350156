import { ApolloError, useQuery } from '@apollo/client';
import { PORTFOLIO_MARKET_VALUE_DATA } from '../graphql/queries/portfolioMarketValueData';

interface UsePortfolioMarketValueDataResponse {
  portfolioMarketData: Record<string, number>;
  previousData: Record<string, number>;
  loading: boolean;
  portfolioMarketValueLoadError?: ApolloError;
}

interface Props {
  dateFrom: string | undefined;
  dateTo: string | undefined;
}

export const usePortfolioMarketValueData = ({ dateFrom, dateTo }: Props): UsePortfolioMarketValueDataResponse => {
  const {
    data,
    loading,
    previousData: previous,
    error: portfolioMarketValueLoadError,
  } = useQuery(PORTFOLIO_MARKET_VALUE_DATA, {
    variables: {
      dateFrom,
      dateTo,
    },
  });

  const portfolioMarketData = data?.inventoriesByDatetimeRange;

  return {
    portfolioMarketData,
    loading,
    previousData: previous?.inventoriesByDatetimeRange,
    portfolioMarketValueLoadError,
  };
};
