import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import Typography from '../Typography';
import { AssetStatus } from '../../types/assetStatus';

const useStyles = makeStyles<Theme, { status: BidStatus }>((theme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: '4px 12px',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    background: theme.palette.grey[50],
    cursor: 'pointer',
  },
  circle: {
    background: (props) =>
      props.status === AssetStatus.Live ? theme.palette.navigation.background.active.main : theme.palette.primary.main,
    borderRadius: '50%',
    width: 12,
    height: 12,
  },
}));

type BidStatus = string;

interface Props {
  status: BidStatus;
  className?: string;
}

export default function OrderStatusIndicator({ status, className = '' }: Props): JSX.Element {
  const classes = useStyles({ status });
  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.circle} />
      <Typography style={{ textTransform: 'capitalize' }} customVariant="subtitle2">
        {status}
      </Typography>
    </div>
  );
}
