import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';

export interface CheckboxCellProps {
  checked?: boolean;
  onCheckedChanged?: () => void;
  disabled?: boolean;
}

export default function CheckboxCell({ checked, onCheckedChanged, disabled }: CheckboxCellProps) {
  return (
    <TableCell align="center">
      <Checkbox checked={checked} onChange={onCheckedChanged} disabled={disabled} />
    </TableCell>
  );
}
