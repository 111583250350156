import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
  },
  flexRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  cards: {
    gap: theme.spacing(2),
  },
  card: {
    minWidth: '150px',
    width: `calc(50% - ${theme.spacing(3)})`,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'space-between',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));
