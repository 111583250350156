/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery } from '@apollo/client';
import { Backdrop, CircularProgress } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ReactComponent as CircledCrossIcon } from '../../assets/icons/circled-cross.svg';
import { SUGGEST_WINE_VINTAGES } from '../../graphql/queries/suggestWineVintages';
import {
  selectWineVintagesSuggestions,
  WineVintageSearchSuggestions,
  WineVintageSuggestion,
} from '../../graphql/selectors/selectSearchWineVintagesSuggestions';
import { getRecentSearchTerms } from '../../services/recentSearches';
import { logError } from '../../utils/logger';
import { useDebounce } from '../../utils/useDebounce';
import IconButton from '../IconButton';
import RecentSearches, { RecentSearch } from './RecentSearches';
import { SearchInputClasses, useSearchInputStyles } from './styles';
import SearchSuggestions from './Suggestions';

interface SearchInputProps {
  onExpand?: () => void;
  onCollapse?: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onFocus: React.FocusEventHandler<HTMLInputElement>;
  onClickAway: () => void;
  onClearInput: () => void;
  searchTermSubmitted: (term: string) => void;
  wineVintageSelected: (wineVintage: WineVintageSuggestion) => void;
  regionSelected: (region: string) => void;
  producerSelected: (producer: string) => void;
  appellationSelected: (appellation: string) => void;
  isLoggedIn?: boolean;
  collapsed?: boolean;
  inputValue: string;
  collapsible?: boolean;
  placeholder?: string;
  className?: string;
  classes?: Partial<SearchInputClasses>;
  active?: boolean;
}

export default function SearchInput({
  onExpand,
  onCollapse,
  onChange,
  onFocus,
  onClickAway,
  onClearInput,
  searchTermSubmitted,
  wineVintageSelected,
  regionSelected,
  appellationSelected,
  producerSelected,
  inputValue,
  collapsible,
  placeholder,
  className = '',
  collapsed = false,
  classes = {},
  active = false,
  isLoggedIn,
}: SearchInputProps): JSX.Element {
  const styles = useSearchInputStyles();
  const [options, setOptions] = React.useState<WineVintageSearchSuggestions>({
    appellations: [],
    producers: [],
    regions: [],
    wines: [],
  });
  const ref = React.useRef<HTMLInputElement | null>(null);
  const recentSearches = getRecentSearchTerms('newest');
  const [isLoading, setIsLoading] = useState(false);
  const [
    fetchSuggestions,
    { data: currentData, previousData, loading, refetch: refetchSuggestions, error: fetchSuggestionsError },
  ] = useLazyQuery(SUGGEST_WINE_VINTAGES, {
    // Fixes https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/13372
    // https://cultwines.sentry.io/share/issue/ed6d1f5f034a4e5481db96d3482fa3bd/
    variables: { queryString: inputValue },
  });
  // We want to get the previous data whilst the next query invocation is loading,
  // otherwise the results get set and unset quickly when a user types.
  const data = currentData || previousData;

  const handleKeyboardEvent: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    searchTermSubmitted(inputValue);
  };

  function performFetch() {
    if (inputValue.trim().length > 0) {
      if (refetchSuggestions) {
        // Because we want to use the previousData, we need to call refetch for any subsequent queries,
        // otherwise it will get reset to undefined every time fetchSuggestions is called
        // https://github.com/apollographql/apollo-client/issues/7396#issuecomment-879228297
        refetchSuggestions({ queryString: inputValue });
      } else {
        fetchSuggestions({ variables: { queryString: inputValue } });
      }
    } else {
      setOptions({ appellations: [], producers: [], regions: [], wines: [] });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  const fetchWithDebounce = useDebounce(performFetch, 300);

  if (fetchSuggestionsError) {
    logError({
      originalError: fetchSuggestionsError,
      error: new Error('Failed to fetch suggestions'),
      filename: 'SearchInput',
      tags: { userFlow: 'search' },
      additionalInfo: {
        inputValue,
      },
    });
  }

  React.useEffect(() => {
    if (!active) {
      return undefined;
    }

    if (loading && isLoggedIn) {
      return undefined;
    }

    if (!isLoggedIn) {
      setIsLoading(true);
    }

    return fetchWithDebounce();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, active, loading, isLoggedIn]);

  React.useEffect(() => {
    if (!data) {
      setOptions({ appellations: [], producers: [], regions: [], wines: [] });
      return;
    }

    setOptions(selectWineVintagesSuggestions(data));
  }, [data]);

  function handleClickAway(): void {
    onClickAway();
  }

  function handleRecentSearchSelected(recentSearch: RecentSearch): void {
    switch (recentSearch.variant) {
      case 'region': {
        regionSelected(recentSearch.value);
        break;
      }

      case 'appellation': {
        appellationSelected(recentSearch.value);
        break;
      }

      case 'producer': {
        producerSelected(recentSearch.value);
        break;
      }

      case 'search': {
        searchTermSubmitted(recentSearch.value);
        break;
      }

      case 'wine': {
        wineVintageSelected(recentSearch.suggestion);
        break;
      }
    }
  }

  return (
    <>
      <Backdrop className={styles.backdrop} open={active} />
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={clsx({
            [styles.root]: true,
            [styles.expanded]: collapsible && !collapsed,
            [styles.condensed]: collapsible && collapsed,
            [className]: true,
            [classes.root!]: classes.root ?? false,
            [classes.expanded!]: classes.expanded && collapsible && !collapsed,
            [classes.condensed!]: classes.condensed && collapsible && collapsed,
          })}
        >
          <InputBase
            data-public
            inputProps={{ 'aria-label': 'search', 'data-cy': collapsed ? 'search-input-collapsed' : 'search-input' }}
            ref={ref}
            role="textbox"
            value={inputValue}
            onFocus={onFocus}
            onChange={onChange}
            onKeyDown={handleKeyboardEvent}
            onKeyPress={handleKeyboardEvent}
            placeholder={placeholder}
            endAdornment={
              <>
                {((loading && isLoggedIn) || (!isLoggedIn && isLoading)) && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {((!loading && isLoggedIn) || (!isLoggedIn && !isLoading)) && inputValue.length > 0 && (
                  <IconButton name="clear" className={styles.circledCrossIcon} onClick={onClearInput} size="large">
                    <CircledCrossIcon />
                  </IconButton>
                )}
              </>
            }
            className={clsx({
              [styles.searchBarRoot]: true,
              [styles.searchBarCondensed]: collapsible && collapsed,
              [styles.elevated]: active,
              [styles.searchBarActive]: active,
              [classes.searchBarRoot!]: classes.searchBarRoot ?? false,
              [classes.searchBarCondensed!]: classes.searchBarCondensed && collapsible && collapsed,
            })}
          />

          {/* <SearchSuggestions
            anchorEl={active && inputValue.length > 0 ? ref.current : null}
            suggestions={options}
            open={active && inputValue.length > 0}
            wineVintageSelected={wineVintageSelected}
            regionSelected={regionSelected}
            producerSelected={producerSelected}
            appellationSelected={appellationSelected}
          />

          <RecentSearches
            anchorEl={active && inputValue.length === 0 ? ref.current : null}
            open={active && inputValue.length === 0}
            searches={recentSearches}
            searchSelected={handleRecentSearchSelected}
          />

          {collapsible && collapsed && (
            <IconButton onClick={onExpand} size="large">
              <Search />
            </IconButton>
          )}
          {collapsible && !collapsed && (
            <IconButton onClick={onCollapse} size="large">
              <Clear />
            </IconButton>
          )} */}
        </div>
      </ClickAwayListener>
    </>
  );
}
