import { ApolloCache, Reference, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { DELETE_ASSET_FROM_WATCHLIST } from '../graphql';
import { DeleteAssetFromWatchListMutation, DeleteAssetFromWatchListMutationVariables } from '../__generated__/graphql';

interface MutationResponse {
  data: DeleteAssetFromWatchListMutation | null | undefined;

  /**
   * Despite the typescript typings for this, it can actually be an object which contains
   * information on graphql errors and network errors. Our `selectErrorMessage` helper can in
   * theory handle this, unintentionally or otherwise. On a close look, the returned object
   * looks a lot like the ApolloError.
   *
   * @example
   * `{"graphQLErrors":[{"message":"failed to add to watchlist"}],"networkError":null,"message":"failed to add to watchlist"}`
   */
  errors: readonly GraphQLError[] | undefined;
}

type CallbackFunction = (params: DeleteAssetFromWatchListMutationVariables) => Promise<MutationResponse>;
type UseDeleteWatchlistItemReturnType = {
  removeFromWatchlist: CallbackFunction;
  loading: boolean;
};

interface WatchListItemRef {
  asset: Reference;
}

export const useDeleteWatchListItem = (options = {}): UseDeleteWatchlistItemReturnType => {
  const [deleteWatchListItemMutation, { loading }] = useMutation(DELETE_ASSET_FROM_WATCHLIST, {
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });

  const removeFromWatchlist = useCallback(
    async ({ assetId }) => {
      const { data, errors } = await deleteWatchListItemMutation({
        variables: { assetId },
        update: (cache: ApolloCache<DeleteAssetFromWatchListMutation>) => {
          cache.modify({
            fields: {
              watchListItems(watchListItems, { readField }) {
                return watchListItems.filter((item: WatchListItemRef) => readField('id', item.asset) !== assetId);
              },
            },
          });
        },
        ...options,
      });
      return { data, errors };
    },
    [deleteWatchListItemMutation, options],
  );

  return {
    removeFromWatchlist,
    loading,
  };
};
