import { SelectOption } from '@cultwines/zellar-client-sdk';
import { UnitSizesByAssetIdQuery } from '../../__generated__/graphql';

const EMPTY_ARR = [] as [];

export function selectUnitSizeOptions(
  asset: UnitSizesByAssetIdQuery['productAsset'] | null | undefined,
): SelectOption[] {
  if (!asset?.vintage?.assets?.length) {
    return EMPTY_ARR;
  }
  return asset.vintage.assets.reduce((acc, cur) => {
    if (!cur) return acc;

    const { id, unitSize, unitCount } = cur;
    if (id && unitSize && unitCount) {
      acc.push({
        value: id,
        label: `${unitCount} x ${unitSize}`,
      });
    }
    return acc;
  }, [] as SelectOption[]);
}

export function selectActiveUnitSize(
  asset: { id: number; unitSize: string; unitCount: number } | null | undefined,
): SelectOption | null {
  if (!asset) {
    return null;
  }
  const { id, unitSize, unitCount } = asset;

  return {
    value: id,
    label: `${unitCount} x ${unitSize}`,
  };
}
