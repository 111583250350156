import { graphql } from '../../__generated__';

export const BASIC_ASSET_BY_ASSET_ID = graphql(`
  query BasicAssetByAssetId($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      vintage {
        id
        vintage
        wine {
          id
          name
        }
      }
      unitSize
      unitCount
    }
  }
`);
