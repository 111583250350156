import { graphql } from '../../__generated__';

export const OWNED_ASSETS_QUERY = graphql(`
  query OwnedAssets {
    holdingsCurrent {
      totalMarketValuation
      totalPurchasePrice
      totalQuantityOwned
      calculatedMarketData {
        combinedScore
        performance {
          valueDifference
          percentageDifference
        }
      }
      asset {
        id
        unitCount
        unitSize
        marketValue
        spread {
          highestBid {
            userId
            price
            quantity
          }
          lowestOffer {
            userId
            price
            quantity
          }
        }
        vintage {
          id
          imageFileName
          vintage
          wine {
            id
            name
            region {
              id
              name
            }
            appellation {
              id
              name
            }
          }
          drinkingWindow {
            dateFrom
            dateTo
            advice
          }
        }
      }
    }
  }
`);
