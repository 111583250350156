/* eslint-disable no-nested-ternary */
import { SortDirection } from '@cultwines/zellar-client-sdk';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import TableCellHead from '../TableElements/TableCellHead';
import RowSkeleton from '../TableElements/RowSkeleton';
import ErrorPlaceholder from '../ErrorPlaceholder';
import EmptyPlaceholder from '../EmptyPlaceholder';
import BasicCell from '../Table/Cells/Basic';
import WineNameCell from '../Table/Cells/WineName';
import UnitSizeCell from '../Table/Cells/UnitSize';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { MyOrder, MyOrdersFacets } from '../../graphql/selectors/selectMyOrders';
import { formatDateTime } from '../MyOrders/formatDateTime';
import ExpiryDate from '../MyOrders/ExpiryDate';
import OrderStatusIndicator from '../OrderStatusIndicator';
import ActionsCell from '../TableElements/ActionsCell';
import Typography from '../Typography';
import MenuButton from '../MenuButton';
import { AssetStatus } from '../../types/assetStatus';
import { useStyles } from './styles';
import { isNull } from '../../utils/isNull';
import { getProductUrl } from '../../utils/common';

interface TableProps {
  assets: MyOrder[];
  loading?: boolean;
  error?: string;
  perPage?: number;
  sortDirection: SortDirection;
  sortColumn: keyof MyOrdersFacets;
  onSortChanged: (key: keyof MyOrdersFacets) => void;
  handleEdit: (rowId: string) => void;
  handleCancel: (rowId: string) => void;
}

export default function BidsTable({
  assets,
  loading,
  error,
  perPage,
  sortColumn,
  sortDirection,
  onSortChanged,
  handleEdit,
  handleCancel,
}: TableProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const columnCount = 10;

  return (
    <TableContainer>
      <Table aria-label="bids table">
        <TableHead>
          <TableRow>
            <TableCellHead
              sortActive={sortColumn === 'wineName'}
              sortDirection={sortColumn === 'wineName' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('wineName')}
              title={t('market:table.wineName')}
            />
            <TableCellHead
              sortActive={sortColumn === 'vintage'}
              sortDirection={sortColumn === 'vintage' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('vintage')}
              title={t('common:vintage')}
            />
            <TableCellHead
              sortActive={sortColumn === 'date'}
              sortDirection={sortColumn === 'date' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('date')}
              title={t('trade:myTable.date')}
            />
            <TableCellHead
              sortActive={sortColumn === 'price'}
              sortDirection={sortColumn === 'price' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('price')}
              title={t('trade:myTable.myBid')}
            />
            <TableCellHead
              sortActive={sortColumn === 'highestBid'}
              sortDirection={sortColumn === 'highestBid' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('highestBid')}
              title={t('trade:myTable.highestBid')}
            />
            <TableCellHead
              sortActive={sortColumn === 'unitCount'}
              sortDirection={sortColumn === 'unitCount' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('unitCount')}
              title={t('common:unitSize')}
            />
            <TableCellHead title={t('trade:myTable.expiryDate')} hideSortButton />
            <TableCellHead
              title={t('trade:myTable.outstandingQuantity')}
              tooltip={t('trade:myTable.outstandingQuantityTooltip')}
              hideSortButton
            />
            <TableCellHead title={t('trade:myTable.bidStatus')} hideSortButton />
            <TableCellHead title={t('common:actions')} hideSortButton />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <RowSkeleton columnCount={columnCount} rowCount={perPage} />
          ) : error ? (
            <TableCell align="center" colSpan={columnCount}>
              <ErrorPlaceholder error={error} />
            </TableCell>
          ) : !assets.length ? (
            <TableCell align="center" colSpan={columnCount}>
              <EmptyPlaceholder
                label={t('trade:myTable.emptyBid')}
                ActionButton={
                  <Button variant="contained" color="primary" size="large" onClick={() => history.push('/discover')}>
                    {t('common:discover')}
                  </Button>
                }
              />
            </TableCell>
          ) : (
            assets.map((asset) => {
              const prodUrl = getProductUrl(asset);
              return (
                <TableRow key={asset.orderId}>
                  <WineNameCell
                    wineName={asset.wineName}
                    appellation={asset.appellation}
                    link={`${prodUrl}`}
                    region={asset.region}
                  />
                  <BasicCell mainContent={asset.vintage} />
                  <BasicCell mainContent={formatDateTime(asset.placedDate)} />
                  <BasicCell mainContent={formatterWholeNumber.format(asset.price)} />
                  <BasicCell
                    mainContent={!isNull(asset.highestBid) ? formatterWholeNumber.format(asset.highestBid) : '-'}
                    aria-label="highestBid"
                  />
                  <UnitSizeCell unitCount={asset.unitCount} unitSize={asset.unitSize} />
                  <BasicCell mainContent={<ExpiryDate expiryDate={asset.expiryDate} />} />
                  <BasicCell mainContent={`${asset.outstandingQuantity}/${asset.quantity}`} />
                  <BasicCell mainContent={<OrderStatusIndicator status={asset.status} />} />
                  <ActionsCell>
                    <MenuButton disabled={asset.status !== AssetStatus.Live}>
                      <MenuItem onClick={() => handleEdit(asset.orderId)}>
                        <Typography className={classes.menuItem} variant="body1">
                          {t('product:detailsModal.editBidTitle')}
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={() => handleCancel(asset.orderId)}>
                        <Typography className={classes.menuItem} variant="body1">
                          {t('trade:cancelBid')}
                        </Typography>
                      </MenuItem>
                    </MenuButton>
                  </ActionsCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
