import { useReactiveVar } from '@apollo/client';
import { Badge } from '@mui/material';
import Menu from '@mui/material/Menu';
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';
import Skeleton from '@mui/material/Skeleton';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CogIcon } from '../../assets/icons/cog.svg';
import UploadFileImage from '../../assets/images/upload-file.png';
import { isLoggedInVar } from '../../graphql/cache';
import { useNotifications } from '../../hooks/useNotifications';
import { useUpdateNotification } from '../../hooks/useUpdateNotification';
import IconButton from '../IconButton';
import { AccountSections } from '../MyAccountTabs/types';
import NotificationCard, { NotificationTypes } from '../NotificationCard';
import Typography from '../Typography';
import { useStyles } from './styles';
import { UpdateNotificationMutationVariables } from '../../__generated__/graphql';

const queryParameter = 'tab';
// Constant menu height 500 + 52 (paddings)
const FIXED_MENU_HEIGHT = 552;
const DEFAULT_PAGE_SIZE = 5;
function NotificationsIconButton(): JSX.Element {
  const [throttling, setThrottling] = useState(false);
  const [from, setFrom] = useState(0);
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const { loading, result } = useNotifications({ from, pageSize: DEFAULT_PAGE_SIZE });
  const menuRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { notifications, unreadCount, totalPages } = result;
  const updateNotification = useUpdateNotification();

  function isBottom(el: HTMLDivElement | null): boolean {
    if (!el) {
      return false;
    }
    // Check if bottom of menu was reached
    return el.getBoundingClientRect().bottom <= FIXED_MENU_HEIGHT;
  }

  function handleScroll() {
    // handleScroll event gets fired multiple times if scrolled very fast
    // throttling is for the purpose of not firing multiple requests of fast scrolling
    if (throttling) {
      return;
    }

    // Last page reached
    if (result.from / DEFAULT_PAGE_SIZE === totalPages - 1) {
      return;
    }

    // Detect that the bottom of Menu was reached - fire a request
    if (isBottom(contentRef.current) && !loading) {
      setThrottling(true);
      setFrom(notifications?.length || 0);
    }
    // Disable throttling after 500ms of previous API call
    setTimeout(() => {
      setThrottling(false);
    }, 500);
  }

  async function handleNotificationClick(index: number, variables: UpdateNotificationMutationVariables) {
    const { errors } = await updateNotification(variables);
    if (!errors) {
      const fromPage = Math.floor(index / 5);
      setFrom(fromPage * DEFAULT_PAGE_SIZE);
    }
  }

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleSettingsClick() {
    history.push(`/my-account?${queryParameter}=${AccountSections.Notifications}`);
  }

  return (
    <>
      <IconButton onClick={handleMenuOpen} disabled={!isLoggedIn} size="large">
        <Badge classes={{ badge: classes.badge }} badgeContent={isLoggedIn ? unreadCount : 0}>
          <NotificationsOutlined />
        </Badge>
      </IconButton>

      <Menu
        ref={menuRef}
        onScrollCapture={handleScroll}
        classes={{ list: classes.menuList, paper: classes.paper }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <div ref={contentRef}>
          <div className={classes.horizontalContainer}>
            <Typography customVariant="h6">{t('notifications:title')}</Typography>
            <CogIcon className={classes.settingsIcon} onClick={handleSettingsClick} />
          </div>
          {!notifications ||
            (!notifications.length && !loading && (
              <div className={classes.image}>
                <img width="343px" src={UploadFileImage} alt="file-upload" />
                <Typography customVariant="h4" zellarColour="textInformation" className={classes.emptyLabel}>
                  {t('notifications:noNotifications')}
                </Typography>
              </div>
            ))}
          {notifications &&
            notifications.length > 0 &&
            notifications.map((notification, index) => (
              <NotificationCard
                index={index}
                key={notification.id}
                id={notification.id}
                title={notification.summary}
                label={notification.description}
                isRead={notification.isRead}
                type={NotificationTypes.BidAccepted}
                dateTime={notification.createdDateTime}
                onClick={handleNotificationClick}
                assetId={notification.assetId}
                handleClose={handleClose}
              />
            ))}
          {loading && <Skeleton variant="rectangular" height="80px" />}
        </div>
      </Menu>
    </>
  );
}

export default NotificationsIconButton;
