import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { ReactComponent as EmailIcon } from '../../assets/icons/email-circled.svg';
import { ReactComponent as WarningCircled } from '../../assets/icons/warning-circled.svg';
import { ReactComponent as PadlockOpenWhiteElipses } from '../../assets/images/padlock-open-white-elipses.svg';
import Footer from '../../components/Footer';
import Splash from '../../components/Splash';
import { appIsWorkingVar } from '../../graphql/cache';
import { selectErrorMessage } from '../../graphql/selectors/selectErrorMessage';
import { logError } from '../../utils/logger';
import { useStyles } from './styles';
import { graphql } from '../../__generated__';

export const RESET_PASSWORD_MUTATION = graphql(`
  mutation ResetPasswordMutation($resetPasswordInput: AuthResetPasswordInput!) {
    authResetPassword(resetPasswordInput: $resetPasswordInput) {
      emailAddress
    }
  }
`);

export default function ResetPassword(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false);
  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION, {
    // Catches network errors and returns them in errors in response
    context: { serviceName: 'insecure' },
    onError: () => null,
  });

  const resetPasswordSchema = yup.object({
    emailAddress: yup.string().email(t('auth:emailAddress.required')).required(t('auth:emailAddress.required')),
  });

  const formik = useFormik<yup.InferType<typeof resetPasswordSchema>>({
    validationSchema: resetPasswordSchema,
    initialValues: {
      emailAddress: '',
    },
    onSubmit: async ({ emailAddress }) => {
      appIsWorkingVar(true);

      const { errors } = await resetPassword({
        variables: { resetPasswordInput: { clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!, emailAddress } },
      });
      if (errors) {
        logError({
          originalError: errors[0],
          error: new Error('Failed to reset password'),
          filename: 'ResetPassword',
          additionalInfo: {
            emailAddress,
            errors: JSON.stringify(errors),
          },
          tags: {
            userFlow: 'account',
          },
        });
        appIsWorkingVar(false);
        setEmailSubmitted(true);
        setErrorMessage(selectErrorMessage(errors));
      } else {
        appIsWorkingVar(false);
        setEmailSubmitted(true);
      }
    },
  });

  function resetState(): void {
    appIsWorkingVar(false);
    setEmailSubmitted(false);
    setErrorMessage(null);
    formik.resetForm();
  }

  return (
    <Splash displayBackArrow={!emailSubmitted}>
      <Box className={classes.borderBox}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {!emailSubmitted ? (
          <form className={classes.innerContent} aria-label="email-address" onSubmit={formik.handleSubmit}>
            <PadlockOpenWhiteElipses />
            <Typography variant="h1">{t('auth:resetPassword.forgotPasswordTitle')}</Typography>
            <div className={classes.field}>
              <InputLabel className={classes.label} htmlFor="emailAddress">
                <Typography variant="subtitle1">{t('auth:emailAddress.label')}</Typography>
              </InputLabel>
              <TextField
                id="emailAddress"
                name="emailAddress"
                value={formik.values.emailAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                placeholder={t('auth:emailAddress.label')}
                variant="outlined"
              />
              <Typography className={classes.helperText} variant="body1">
                {t('auth:resetPassword.forgotPasswordBody')}
              </Typography>
            </div>
            <Button
              className={classes.button}
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              {t('auth:resetPassword.button')}
            </Button>
          </form>
        ) : errorMessage ? (
          <div className={classes.innerContent}>
            <WarningCircled className={classes.icon} />
            <Typography variant="h1">{t('auth:resetPassword.error')}</Typography>
            <Button className={classes.button} onClick={resetState}>
              {t('common:back')}
            </Button>
          </div>
        ) : (
          <div className={classes.innerContent}>
            <Typography variant="h1">{t('auth:resetPassword.checkEmailTitle')}</Typography>
            <EmailIcon />
            <Typography variant="body1">{t('auth:resetPassword.checkEmailBody')}</Typography>
            <Button className={classes.button} component={RouterLink} to="/login">
              {t('auth:resetPassword.checkEmailCallToAction')}
            </Button>
          </div>
        )}
      </Box>
      <Footer />
    </Splash>
  );
}
