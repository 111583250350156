/* eslint-disable react/no-this-in-sfc */
import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { HighchartsChart } from '../../types/Highcharts';
import { selectBarChartData, selectLineChartData } from './selectors';
import Tooltip from './Tooltip';
import Logo from '../../assets/images/logo-grey.svg';
import calculateWatermarkPosition from '../../utils/calculateWatermarkPosition';
import { graphql } from '../../__generated__';
import { WineVintage } from '../../__generated__/graphql';

interface Props {
  assetId: number;
  title: string;
  isLoggedIn?: boolean;
  isProductError?: boolean;
}

const VINTAGE_SCORES = graphql(`
  query VintageScores($assetId: Int!) {
    productAsset(assetId: $assetId) {
      vintage {
        vintage
      }
      vintages {
        id
        vintage
        calculatedMarketData {
          combinedScore
          currentPrice
        }
      }
    }
  }
`);

export default function ProductVintageBarChart({
  assetId,
  title,
  isLoggedIn = false,
  isProductError,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [barChartData, setBarChartData] = useState<Highcharts.SeriesColumnOptions['data']>([]);
  const [lineChartData, setLineChartData] = useState<Highcharts.SeriesColumnOptions['data']>([]);
  const [options, setOptions] = useState<Highcharts.Options | null>(null);
  const theme = useTheme();
  const { data, loading } = useQuery(VINTAGE_SCORES, {
    variables: { assetId },
    ...(isLoggedIn
      ? {}
      : {
          context: {
            serviceName: 'insecure',
          },
        }), // Conditionally add serviceName
  });
  const chartRef = React.createRef<{ chart: HighchartsChart; container: React.RefObject<HTMLDivElement> }>();

  useEffect(() => {
    if (isProductError) return;
    const vintages = data?.productAsset?.vintages.filter((v) => v.vintage >= 1940) as WineVintage[];
    setBarChartData(selectBarChartData(vintages, data?.productAsset?.vintage?.vintage, theme.palette.chart1.main));
    setLineChartData(selectLineChartData(vintages));
  }, [data, theme.palette.chart1.main, isProductError]);

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (loading) {
      chart?.hideNoData();
      chart?.showLoading();
    } else {
      chart?.hideLoading();
    }
  }, [loading, chartRef]);

  useEffect(() => {
    let watermark: Highcharts.SVGElement | null = null;

    setOptions({
      series: [
        {
          type: 'column',
          name: t('product:currentVintage'),
          id: 'price',
          color: theme.palette.chart1.disabled,
          data: barChartData,
        },
        {
          type: 'spline',
          yAxis: 1,
          id: 'score',
          name: t('product:score'),
          color: theme.palette.scoreChart.main,
          data: lineChartData,
        },
      ],
      xAxis: {
        type: 'category',
        tickWidth: 0,
        labels: {
          rotation: -45,
          style: {
            color: theme.palette.label,
          },
        },
      },
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value}£',
          },
          title: {
            text: t('product:vintageChartTitle'),
          },
        },
        {
          labels: {
            // Formatting so that there are no decimals
            format: '{value:.0f}',
          },
          // Secondary yAxis
          title: {
            text: t('product:score'),
          },
          endOnTick: false,
          max: 100,
          opposite: true,
          gridLineWidth: 0,
        },
      ],
      chart: {
        events: {
          load() {
            const chart = this;
            watermark = chart.renderer.image(Logo).attr(calculateWatermarkPosition(chart)).add();
          },
          render() {
            const chart = this;
            watermark?.attr(calculateWatermarkPosition(chart));
          },
        },
      },
      plotOptions: {
        column: {
          maxPointWidth: 30,
        },
      },
      lang: {
        noData: t('product:noData'),
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: theme.palette.textPrimary,
        },
      },
      tooltip: {
        useHTML: true,
        borderColor: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
        shared: true,
        style: {
          textAlign: 'center',
        },
        formatter() {
          return renderToString(<Tooltip points={this.points} />);
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      legend: {
        itemStyle: {
          color: theme.palette.label,
        },
        align: 'left',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, title, theme, loading, barChartData, lineChartData]);

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />;
}
