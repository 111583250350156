import React, { ReactNode } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from '../../../../components/Typography';

interface ICards {
  title: string;
  text: string;
  ctaText: string;
  onCta: () => void;
  ctaIcon?: ReactNode;
  link?: string;
  lessThanMd?: boolean;
  isActive?: boolean;
}

const Cards = ({ title, text, ctaText, onCta, ctaIcon, link, lessThanMd, isActive }: ICards) => {
  return (
    <div
      className={`tw-bg-[#1D084F] tw-text-white tw-flex tw-flex-col tw-flex-1 tw-gap-2 tw-rounded-[8px] tw-h[122px]  tw-min-h-[146px]  tw-p-4 ${
        lessThanMd ? 'tw-w-full' : 'tw-min-w-[309px]'
      } `}
    >
      <Typography className="tw-text-[20px]" variant="subtitle1">
        {title}
      </Typography>
      <Typography className="tw-text-[#EAEAFD] tw-text-sm">{text}</Typography>

      {!link && (
        <div className="tw-mt-auto">
          <button
            className={`tw-mt-auto tw-cursor-pointer 
            tw-flex tw-flex-row tw-items-center tw-gap-1 
            tw-text-[#FB9D0E] 
            tw-border-none tw-p-0 tw-font-bold tw-text-sm
            ${isActive ? 'tw-text-white tw-rounded-md tw-p-1 tw-bg-[#FB9D0E]' : 'tw-text-[#FB9D0E] tw-bg-transparent'}`}
            onClick={onCta}
            type="button"
          >
            <Typography> {ctaText}</Typography>
            {ctaIcon}
          </button>
        </div>
      )}

      {link && (
        <Link
          to={link}
          className="tw-mt-auto tw-cursor-pointer tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-[#FB9D0E] tw-bg-transparent tw-border-none tw-p-0 tw-font-bold tw-text-sm tw-no-underline"
        >
          <Typography> {ctaText}</Typography>
          {ctaIcon}
        </Link>
      )}
    </div>
  );
};

interface IDiscoverCards {
  cards: ICards[];
}

const DiscoverCards = ({ cards }: IDiscoverCards) => {
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  if (lessThanMd) {
    return (
      <div className="tw-flex tw-flex-row  tw-w-full tw-mx-5 tw-items-center tw-justify-center ">
        <Carousel
          className="tw-w-full "
          autoPlay={false}
          navButtonsAlwaysVisible
          NavButton={({ onClick, prev }) => {
            if (prev) return null;
            return (
              <button
                type="button"
                onClick={() => onClick()}
                className="tw-bg-[#CACBFB] tw-flex tw-justify-center tw-items-center tw-mt-[107px] tw-mr-[12px] tw-w-[28px] tw-h-[28px] tw-rounded-full tw-opacity-100 tw-transition-none tw-focus:outline-none"
                style={{
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                <ArrowForwardIosIcon sx={{ color: '#3a2a57' }} className="tw-w-[15px] tw-h-[15px]" />
              </button>
            );
          }}
        >
          {cards.map((x, i) => (
            <Cards lessThanMd={lessThanMd} key={i} {...x} />
          ))}
        </Carousel>
      </div>
    );
  }
  return (
    <div className="tw-flex tw-flex-row  tw-gap-6 tw-w-full tw-mx-5 tw-overflow-x-auto">
      {cards.map((x, i) => (
        <Cards key={i} {...x} />
      ))}
    </div>
  );
};

export default DiscoverCards;
