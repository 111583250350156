import lato from 'typeface-lato';
import { Components } from '@mui/material/styles/components';

const sharedOverrides: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      '@global': {
        '@font-face': [lato],
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        height: '4px',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 'bold',
        borderRadius: 24,
        boxShadow: 'none',
        textTransform: 'none',
      },
      text: {
        padding: '10px 14px',
      },
      containedSecondary: {
        boxShadow: 'none',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: '6px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        height: 47,
      },
    },
  },

  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: { fontWeight: 'bold' },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: { whiteSpace: 'normal' },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        alignItems: 'center',

        /**
         * h4
         */
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
        fontWeight: 700,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontWeight: 400,
        textTransform: 'uppercase',
      },
    },
  },
};

export default sharedOverrides;
