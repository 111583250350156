import React, { useState } from 'react';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { BaseFieldProps } from './BaseFieldProps';
import { useAccountFieldStyles } from './sharedStyles';

interface AccountGroupFieldProps extends BaseFieldProps {
  groupContent?: React.ReactNode;
}

export default function AccountGroupField({
  content,
  Icon,
  groupContent,
  disabled = false,
}: AccountGroupFieldProps): JSX.Element {
  const sharedClasses = useAccountFieldStyles({ disabled, expandable: true });
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={sharedClasses.root}>
      <button
        onClick={() => setExpanded(!expanded)}
        className={`${sharedClasses.content} ${sharedClasses.clickable} ${sharedClasses.button}`}
        type="button"
        disabled={disabled}
      >
        <div className={sharedClasses.title}>
          <Icon className={`${sharedClasses.icon} ${disabled ? sharedClasses.disabled : ''}`} />
          {content}
        </div>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </button>
      {!disabled && <Collapse in={expanded}>{groupContent}</Collapse>}
    </div>
  );
}
