import Menu from '@mui/material/Menu';
import MoreHorizontal from '@mui/icons-material/MoreHoriz';
import React, { useState } from 'react';
import IconButton from '../IconButton';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
}

export default function MenuButton({ children, disabled = false }: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>): void {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(): void {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton variant="filled" onClick={handleMenuOpen} disabled={disabled} aria-label="menu" size="large">
        <MoreHorizontal />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {children}
      </Menu>
    </>
  );
}
