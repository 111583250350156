import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ORDER_BOOK_CELLS_COUNT } from './constants';
import { OrderBookRow } from './types';
import { selectOpacity } from './selectors';
import Cell from './Cell';
import { colours } from '../../theme/light/palette';

const useStyles = makeStyles<Theme, { isReverse: boolean }>(() => ({
  container: {
    width: '100%',
    overflowX: 'hidden',
  },
  scrollableContainer: {
    display: 'flex',
    overflowX: 'scroll',
    flexDirection: ({ isReverse }) => (isReverse ? 'row-reverse' : 'row'),
    width: '100%',
  },
}));

interface RowProps {
  assetId: number;
  variant: 'bid' | 'offer';
  row: OrderBookRow;
  className?: string;
  title?: string;
}

export default function Row({ className = '', assetId, variant, row, title }: RowProps): JSX.Element {
  const classes = useStyles({ isReverse: variant === 'bid' });

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.scrollableContainer} role="row" aria-label={title} title={title}>
        {row.cells.length <= ORDER_BOOK_CELLS_COUNT
          ? new Array(ORDER_BOOK_CELLS_COUNT).fill(0).map((_, i) => (
              <Cell
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                opacity={selectOpacity(i, assetId, row)}
                price={row.cells[i]?.price}
                quantity={row.cells[i]?.quantity}
                source={row.cells[i]?.source}
                background={variant === 'bid' ? colours.cultYellow : colours.cultPurple}
              />
            ))
          : row.cells.map((cell, i) => (
              <Cell
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                opacity={selectOpacity(i, assetId, row)}
                price={cell.price}
                quantity={cell.quantity}
                source={cell.source}
                background={variant === 'bid' ? colours.cultYellow : colours.cultPurple}
              />
            ))}
      </div>
    </div>
  );
}
