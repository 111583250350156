import { graphql } from '../../__generated__';

export const UPDATE_CATEGORY_TYPE = graphql(`
  mutation UpdateCategoryType($id: String!, $payload: JSON!) {
    updateCategoryType(id: $id, payload: $payload) {
      id
      name
      description
      isEnabled
    }
  }
`);
