import { PointOptionsObject } from 'highcharts';
import { WineVintage } from '../../__generated__/graphql';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

export function selectBarChartData(
  vintages: WineVintage[] | undefined,
  selectedVintage: number | undefined,
  unselectedColor: string,
): Highcharts.SeriesColumnOptions['data'] {
  if (!vintages || !vintages.length) {
    return [];
  }
  return vintages.map((vintage) => {
    const price = !isNullOrUndefined(vintage.calculatedMarketData.currentPrice)
      ? Number(vintage.calculatedMarketData.currentPrice.toFixed(2))
      : null;
    const chartPoint: PointOptionsObject = { name: vintage.vintage.toString(), y: price };
    if (vintage.vintage !== selectedVintage) {
      chartPoint.color = unselectedColor;
    }
    return chartPoint;
  });
}

export function selectLineChartData(vintages: WineVintage[] | undefined): Highcharts.SeriesColumnOptions['data'] {
  if (!vintages || !vintages.length) {
    return [];
  }
  return vintages.map((vintage) => [vintage.vintage.toString(), vintage.calculatedMarketData.combinedScore]);
}
