import React from 'react';
import { ApolloError } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import ErrorImage from '../../../assets/images/error.png';
import Typography from '../../Typography';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    textAlign: 'center',
  },
  text: {
    marginTop: 20,
  },
}));

function isApolloError(arg: unknown): arg is ApolloError {
  return (arg as ApolloError).extraInfo;
}

export default function ErrorPlaceholder({ error }: { error: ApolloError | string }): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={ErrorImage} alt="error" />
      <Typography customVariant="h3" className={classes.text}>
        {isApolloError(error) ? error.message : error}
      </Typography>
    </div>
  );
}
