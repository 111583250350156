/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { SortDirection } from '@cultwines/zellar-client-sdk';
import { useTranslation } from 'react-i18next';
import TableCellHead from '../TableElements/TableCellHead';
import RowSkeleton from '../TableElements/RowSkeleton';
import ExternalPortfolioTableRow from './Row';
import EmptyPlaceholder from '../EmptyPlaceholder';
import { ExternalPortfolioItem, ExternalPortfolioItemFacets } from '../../graphql/selectors/externalPortfolioItems';
import ErrorPlaceholder from '../ErrorPlaceholder';
import { COLUMN_COUNT } from './types';

interface TableProps {
  assets: ExternalPortfolioItem[];
  loading?: boolean;
  error?: string;
  perPage?: number;
  sortDirection: SortDirection;
  sortColumn: keyof ExternalPortfolioItemFacets;
  onSortChanged: (key: keyof ExternalPortfolioItemFacets) => void;
  onCompareClicked: (assetId: number) => void;
  onEmptyClicked: () => void;
  onBuyNowClicked: (assetId: number) => void;
  onBidClicked: (assetId: number) => void;
}

export default function ExternalPortfolioTable({
  assets,
  loading,
  error,
  perPage,
  sortColumn,
  sortDirection,
  onSortChanged,
  onCompareClicked,
  onEmptyClicked,
  onBuyNowClicked,
  onBidClicked,
}: TableProps) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table aria-label="external portfolio table">
        <TableHead>
          <TableRow>
            <TableCellHead hideSortButton />
            <TableCellHead
              sortActive={sortColumn === 'name'}
              sortDirection={sortColumn === 'name' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('name')}
              title={t('portfolio:table.headers.wineName')}
            />
            <TableCellHead
              sortActive={sortColumn === 'vintage'}
              sortDirection={sortColumn === 'vintage' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('vintage')}
              title={t('common:vintage')}
            />
            <TableCellHead
              sortActive={sortColumn === 'score'}
              sortDirection={sortColumn === 'score' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('score')}
              title={t('portfolio:table.headers.score')}
            />
            <TableCellHead title={t('portfolio:table.headers.drinkingWindow')} hideSortButton />
            <TableCellHead
              sortActive={sortColumn === 'units'}
              sortDirection={sortColumn === 'units' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('units')}
              title={t('trade:units')}
            />
            <TableCellHead
              sortActive={sortColumn === 'unitCount'}
              sortDirection={sortColumn === 'unitCount' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('unitCount')}
              title={t('common:unitSize')}
            />
            <TableCellHead
              sortActive={sortColumn === 'totalPurchasePrice'}
              sortDirection={sortColumn === 'totalPurchasePrice' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('totalPurchasePrice')}
              title={t('portfolio:table.headers.totalPurchasePrice')}
            />
            <TableCellHead
              sortActive={sortColumn === 'totalMarketValue'}
              sortDirection={sortColumn === 'totalMarketValue' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('totalMarketValue')}
              tooltip={t('market:marketValueCalculation')}
              title={t('portfolio:table.headers.totalMarketValuation')}
            />
            <TableCellHead
              sortActive={sortColumn === 'performancePercentageDelta'}
              sortDirection={sortColumn === 'performancePercentageDelta' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('performancePercentageDelta')}
              title={t('portfolio:table.headers.performance')}
            />
            <TableCellHead
              sortActive={sortColumn === 'highestBid'}
              sortDirection={sortColumn === 'highestBid' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('highestBid')}
              title={t('product:trading.highestBid')}
            />
            <TableCellHead
              sortActive={sortColumn === 'lowestOffer'}
              sortDirection={sortColumn === 'lowestOffer' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('lowestOffer')}
              title={t('product:trading.lowestOffer')}
            />
            <TableCellHead title={t('common:actions')} hideSortButton />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <RowSkeleton columnCount={COLUMN_COUNT} rowCount={perPage} />
          ) : error ? (
            <TableCell align="center" colSpan={COLUMN_COUNT}>
              <ErrorPlaceholder error={error} />
            </TableCell>
          ) : !assets.length ? (
            <TableCell align="center" colSpan={COLUMN_COUNT}>
              <EmptyPlaceholder
                label={t('portfolio:noWines')}
                ActionButton={
                  <Button variant="contained" color="primary" size="large" onClick={onEmptyClicked}>
                    {t('portfolio:addWines')}
                  </Button>
                }
              />
            </TableCell>
          ) : (
            assets.map((asset) => (
              <ExternalPortfolioTableRow
                key={asset.id}
                asset={asset}
                onCompareClicked={onCompareClicked}
                onBuyNowClicked={onBuyNowClicked}
                onBidClicked={onBidClicked}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
