import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import AddressSummary from '../AddressSummary';
import useUserDetails from '../../hooks/useUserDetails';
import EmptyAddressButton from '../EmptyAddressButton';
import { logError } from '../../utils/logger';
import { Address } from '../../__generated__/graphql';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: 8,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    background: theme.palette.grey[50],
  },
  modalContent: {
    paddingTop: 15,
  },
  title: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#1D084F',
    marginTop: theme.spacing(2),
  },
  addressSummaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '18px',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      columnGap: '38px',
    },
  },
  addressSummary: {
    width: '100%',
  },
  confirmButton: {
    marginLeft: 'auto',
    height: 'fit-content',

    '@media (min-width: 768px)': {
      marginTop: 'auto',
    },
  },
  noAddressContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
  hint: {
    color: '#8A809A',
  },
  emptyAddress: {
    width: '100%',
  },
}));

interface ReviewAddressProps {
  address: Address | null;
  openAddressModal: () => void;
}

export default function BillingAddress({ address, openAddressModal }: ReviewAddressProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userDetails, error: useUserDetailsError } = useUserDetails();

  if (useUserDetailsError) {
    logError({
      originalError: useUserDetailsError,
      error: new Error('Failed to load users details'),
      filename: 'ReviewAddress',
      tags: { userFlow: 'payment' },
    });
  }
  return (
    <div className={classes.modalContainer}>
      <Typography className={classes.title} customVariant="body1" data-private>
        {t('wallet:billingAddressModal.title')}
      </Typography>
      <div className={classes.modalContent}>
        {address ? (
          <div className={classes.addressSummaryContainer}>
            <AddressSummary
              className={classes.addressSummary}
              address={address}
              name={`${userDetails?.firstName} ${userDetails?.surname}`}
              handleEditAddressClicked={openAddressModal}
            />
          </div>
        ) : (
          <div className={classes.noAddressContainer}>
            <Typography customVariant="body1" className={classes.hint}>
              {t('wallet:billingAddressModal.hint')}
            </Typography>
            <EmptyAddressButton onClick={openAddressModal} className={classes.emptyAddress} />
          </div>
        )}
      </div>
    </div>
  );
}
