import { graphql } from '../../__generated__';

export const ADD_TO_EXTERNAL_PORTFOLIO = graphql(`
  mutation AddToExternalPortfolio($assetId: Int!, $purchasePrice: Float, $purchaseDate: String, $quantity: Int!) {
    externalPortfolioAddExternalPortfolio(
      externalPortfolio: {
        assetId: $assetId
        purchasePrice: $purchasePrice
        purchaseDate: $purchaseDate
        quantity: $quantity
      }
    )
  }
`);
