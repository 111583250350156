/* eslint-disable no-nested-ternary */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalStep } from '../Dialog/ContentSwitcher';
import OrderResultComponent from './Result';
import ProcessTransaction, { OrderModalProcessTransactionPropsBase } from './ProcessTransaction';
import OrderDetails, { OrderDetailsPropsBase } from './Details';
import OrderSummary, { OrderSummaryPropsBase } from './Summary';
import { Mode, TradeVariant } from './types';
import { OrderData } from '../OrderForm/types';
import { ModalError } from '../../types/ModalError';
import { cache } from '../../graphql/cache';
import useMediaQuery from '../../hooks/useMediaQuery';
import DialogContentSwitcherUnstyled from '../Dialog/ContentSwitcherUnstyled';
import useUserDetails from '../../hooks/useUserDetails';
import { AccountType } from '../../types/AccountType';

interface OrderModalPropsBase {
  assetId: number;
  tradeType: TradeVariant;
  open: boolean;
  onClose: () => void;
}

interface EditOrderModalProps extends OrderModalPropsBase {
  mode: Mode.Edit;
  systemOrderId: string;
  clientOrderId: string;
}

interface CreateOrderModalProps extends OrderModalPropsBase {
  mode: Mode.Create;
}

interface CancelOrderModalProps extends OrderModalPropsBase {
  mode: Mode.Cancel;
  systemOrderId: string;
  clientOrderId: string;
}

function getModalWidth(modalStep: ModalStep): 'xs' | 'sm' | 'md' | 'lg' | 'xl' {
  switch (modalStep) {
    case ModalStep.Initial: {
      return 'md';
    }

    case ModalStep.Success:
    case ModalStep.Failure:
    case ModalStep.Summary: {
      return 'sm';
    }

    case ModalStep.Processing: {
      return 'xs';
    }

    default: {
      return 'md';
    }
  }
}

export default function OrderModal(
  props: CreateOrderModalProps | EditOrderModalProps | CancelOrderModalProps,
): JSX.Element {
  const theme = useTheme();
  const lowerThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { assetId, mode, onClose, tradeType, open } = props;
  const { t } = useTranslation();
  const [error, setError] = React.useState<ModalError | undefined>();
  const [inMemOrderData, setInMemOrderData] = React.useState<OrderData | null>(null);
  const [modalStep, setModalStep] = React.useState<ModalStep>(
    mode === Mode.Cancel ? ModalStep.Processing : ModalStep.Initial,
  );
  const [cancelFailed, setCancelFailed] = React.useState(false);
  const [createFailed, setCreateFailed] = React.useState(false);
  const { userDetails } = useUserDetails();
  const showFees = userDetails?.accountType !== AccountType.Bidder;

  function handleFormError(): void {
    setError({
      title: t('product:detailsModal.buyNow.genericError.title'),
      buttonText: t('product:detailsModal.buyNow.genericError.button'),
      message: t('product:detailsModal.buyNow.genericError.message'),
    });
    setModalStep(ModalStep.Failure);
  }

  function onCancelOrderFailure(): void {
    setCancelFailed(true);
    setModalStep(ModalStep.Failure);
  }

  function onEditOrderFailure(): void {
    setCreateFailed(true);
    setModalStep(ModalStep.Failure);
  }

  function onSubmit(orderData: OrderData): void {
    setInMemOrderData(orderData);
    setModalStep(ModalStep.Summary);
  }

  function handleSuccess(): void {
    setModalStep(ModalStep.Success);
    cache.evict({ fieldName: 'omsGetMyOrders' });
    cache.evict({ fieldName: 'productAssetInstances' });
    cache.evict({ fieldName: 'isUserHoldingHasAsset' });
  }

  const initialComponentCommonProps: OrderDetailsPropsBase = {
    assetId,
    onClose,
    onError: handleFormError,
    onSuccess: onSubmit,
    tradeType,
    formState: inMemOrderData
      ? {
          expirationDate: inMemOrderData.expirationDate,
          price: inMemOrderData.price.toString(),
          quantity: inMemOrderData.quantity.toString(),
        }
      : undefined,
    showFees,
  };
  const InitialComponent =
    mode === Mode.Create ? (
      <OrderDetails mode={mode} {...initialComponentCommonProps} />
    ) : mode === Mode.Edit ? (
      <OrderDetails
        mode={mode}
        {...initialComponentCommonProps}
        orderId={(props as EditOrderModalProps).systemOrderId}
      />
    ) : undefined;

  const summaryComponentCommonProps: OrderSummaryPropsBase = {
    assetId,
    onClose,
    onEditDetails: () => setModalStep(ModalStep.Initial),
    mode,
    onSubmit: () => setModalStep(ModalStep.Processing),
    orderData: inMemOrderData!,
    tradeType,
    showFees,
  };
  const SummaryComponent = <OrderSummary {...summaryComponentCommonProps} />;

  const commonProcessTransactionProps: OrderModalProcessTransactionPropsBase = {
    assetId,
    onError: handleFormError,
    onSuccess: handleSuccess,
    tradeType,
    orderData: inMemOrderData,
  };
  const ProcessingComponent =
    mode === Mode.Create ? (
      <ProcessTransaction {...commonProcessTransactionProps} mode={Mode.Create} onCreateFailure={onEditOrderFailure} />
    ) : mode === Mode.Edit ? (
      <ProcessTransaction
        {...commonProcessTransactionProps}
        mode={Mode.Edit}
        existingOrderClientId={(props as EditOrderModalProps).clientOrderId}
        onCancelFailure={onCancelOrderFailure}
        systemOrderId={(props as EditOrderModalProps).systemOrderId}
        onEditFailure={onEditOrderFailure}
      />
    ) : (
      <ProcessTransaction
        {...commonProcessTransactionProps}
        mode={Mode.Cancel}
        systemOrderId={(props as CancelOrderModalProps).systemOrderId}
        existingOrderClientId={(props as CancelOrderModalProps).clientOrderId}
        onCancelFailure={onCancelOrderFailure}
      />
    );

  const FailureComponent =
    mode === Mode.Create ? (
      <OrderResultComponent
        mode={Mode.Create}
        tradeType={tradeType}
        result="failure"
        onClose={onClose}
        onConfirm={onClose}
        error={error}
      />
    ) : mode === Mode.Edit ? (
      <OrderResultComponent
        mode={Mode.Edit}
        tradeType={tradeType}
        result="failure"
        onClose={onClose}
        onConfirm={onClose}
        cancelFailed={cancelFailed}
        createFailed={createFailed}
        error={error}
      />
    ) : (
      <OrderResultComponent
        mode={Mode.Cancel}
        tradeType={tradeType}
        result="failure"
        onClose={onClose}
        onConfirm={onClose}
        error={error}
      />
    );
  return (
    <Dialog
      onClose={onClose}
      open={open}
      disableEscapeKeyDown
      maxWidth={getModalWidth(modalStep)}
      fullScreen={lowerThanMd}
    >
      <DialogContentSwitcherUnstyled
        modalStep={modalStep}
        InitialComponent={InitialComponent}
        FailureComponent={FailureComponent}
        ProcessingComponent={ProcessingComponent}
        SummaryComponent={SummaryComponent}
        SuccessComponent={
          <OrderResultComponent
            mode={mode}
            tradeType={tradeType}
            result="success"
            onClose={onClose}
            onConfirm={onClose}
          />
        }
      />
    </Dialog>
  );
}
