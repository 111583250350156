import { FormikContextType, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type PasswordDetails = {
  passwordCurrent: string;
  passwordNew: string;
  passwordConfirm: string;
};

export function useEditPassword({
  passwordUpdated,
  passwordCurrent,
  passwordConfirm,
  passwordNew,
}: {
  passwordUpdated: (args: PasswordDetails) => Promise<void>;
  passwordCurrent: string;
  passwordNew: string;
  passwordConfirm: string;
}): FormikContextType<PasswordDetails> {
  const { t } = useTranslation();
  const passwordSchema = yup.object({
    passwordCurrent: yup.string().required(t('auth:password.required')),
    passwordNew: yup
      .string()
      .min(8, t('auth:password.invalid'))
      // eslint-disable-next-line no-useless-escape
      .matches(/[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g)
      .required(t('auth:password.required')),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('passwordNew'), null], t('auth:passwordConfirmation.invalid'))
      .required(t('auth:passwordConfirmation.required')),
  });

  const passwordFormik = useFormik<yup.InferType<typeof passwordSchema>>({
    initialValues: {
      passwordCurrent,
      passwordNew,
      passwordConfirm,
    },
    onSubmit: async (
      { passwordConfirm: _passwordConfirm, passwordCurrent: _passwordCurrent, passwordNew: _passwordNew },
      { resetForm },
    ) => {
      await passwordUpdated({
        passwordNew: _passwordNew,
        passwordCurrent: _passwordCurrent,
        passwordConfirm: _passwordConfirm,
      });
      resetForm();
    },
    validationSchema: passwordSchema,
  });

  return passwordFormik;
}
