import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useAccountFieldStyles = makeStyles<Theme, { disabled: boolean; expandable: boolean }>((theme) => ({
  root: {
    border: (props) => `1px solid ${props.disabled ? theme.palette.grey[200] : theme.palette.text.primary}`,
    borderRadius: 8,
    display: 'flex',
    flexDirection: (props) => (props.expandable ? 'column' : 'row'),
    justifyContent: 'center',
    width: '100%',
    minHeight: '76px',
  },
  clickable: {
    cursor: (props) => (props.disabled ? 'default' : 'pointer'),
    pointerEvents: (props) => (props.disabled ? 'none' : 'auto'),
  },
  content: {
    padding: theme.spacing(5),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    borderRadius: 8,
    textAlign: 'inherit',
  },
  button: {
    color: (props) => (props.disabled ? theme.palette.grey[200] : theme.palette.text.primary),

    // Prevent base html button styles being enforced
    '&:disabled': {
      color: theme.palette.grey[200],
    },
  },
  title: {
    display: 'flex',
    gap: theme.spacing(5),
    alignItems: 'center',
  },
  disabled: {
    color: `${theme.palette.grey[200]} !important`,
    stroke: `${theme.palette.grey[200]} !important`,
  },
  icon: {
    stroke: theme.palette.text.primary,
  },
  iconDisabled: {
    opacity: 0.3,
  },
}));
