import { TableCell, TableRow } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GraphQLError } from 'graphql';
import { Controls } from '../CultXPortfolioTable/Controls';
import WineNameCell from '../Table/Cells/WineName';
import BasicCell from '../Table/Cells/Basic';
import UnitSizeCell from '../Table/Cells/UnitSize';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import SparklineCell from '../Table/Cells/Sparkline';
import TradingTile from '../TradingTile';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { isNull } from '../../utils/isNull';
import ActionsCell from '../TableElements/ActionsCell';
import BuyTradeActionButton, { Size as BuyTradeButtonSize } from '../BuyTradeActionButton';
import WatchlistButton from '../WatchlistButton';
import ExpandedContent from './ExpandedContent';
import { WineVintage } from './types';
import { getProductUrl } from '../../utils/common';

interface TableRowProps {
  wineVintage: WineVintage;
  onAddToWatchlist: () => void;
  onRemoveFromWatchlist: () => void;
  onWatchlistError: (errors: readonly GraphQLError[]) => void;
  onBuyNowClicked: (assetId: string) => void;
  onBidClicked: (assetId: string) => void;
  onOfferClicked: (assetId: string) => void;
}

export const COLUMN_COUNT = 11;

export default function SearchResultsTableRow({
  wineVintage,
  onAddToWatchlist,
  onRemoveFromWatchlist,
  onWatchlistError,
  onBuyNowClicked,
  onBidClicked,
  onOfferClicked,
}: TableRowProps): JSX.Element {
  const [expanded, setExpanded] = React.useState(false);
  const { clickableTradeTiles13816, showPerformaceDataInSearchResults } = useFlags();

  const { name: wineName, vintage, defaultAssetId: assetId } = wineVintage;
  const prodUrl = getProductUrl({ wineName, vintage, assetId });

  return (
    <>
      <TableRow key={wineVintage.defaultAssetId}>
        <Controls expanded={expanded} setExpanded={setExpanded} />
        <WineNameCell
          wineName={wineVintage.name}
          appellation={wineVintage.appellation ?? null}
          link={`${prodUrl}`}
          region={wineVintage.region}
        />
        <BasicCell mainContent={wineVintage.vintage} />
        <BasicCell mainContent={wineVintage.score} />
        <UnitSizeCell unitCount={wineVintage.defaultAssetUnitCount} unitSize={wineVintage.defaultAssetUnitSize} />
        <BasicCell mainContent={formatterWholeNumber.format(wineVintage.marketValue)} />
        {showPerformaceDataInSearchResults && (
          <SparklineCell
            data={wineVintage.performance.marketData}
            deltaNum={wineVintage.performance.valueDelta}
            deltaPct={wineVintage.performance.percentageDelta}
          />
        )}
        <TableCell>
          {clickableTradeTiles13816 ? (
            <TradingTile
              mode="bid"
              value={wineVintage.highestBid}
              onClick={() => onBidClicked(wineVintage.defaultAssetId)}
            />
          ) : (
            <TradingTile
              mode="bid"
              value={wineVintage.highestBid}
              unitCount={wineVintage.defaultAssetUnitCount}
              unitSize={wineVintage.defaultAssetUnitSize}
            />
          )}
        </TableCell>
        <TableCell>
          {clickableTradeTiles13816 ? (
            <TradingTile
              mode="offer"
              value={wineVintage.lowestOffer}
              onClick={
                !isNullOrUndefined(wineVintage.lowestOffer)
                  ? () => onBuyNowClicked(wineVintage.defaultAssetId)
                  : undefined
              }
            />
          ) : (
            <TradingTile
              mode="offer"
              value={wineVintage.lowestOffer}
              unitCount={wineVintage.defaultAssetUnitCount}
              unitSize={wineVintage.defaultAssetUnitSize}
            />
          )}
        </TableCell>
        <BasicCell mainContent={isNull(wineVintage.spread) ? '-' : `${wineVintage.spread}%`} />
        <ActionsCell>
          <BuyTradeActionButton
            assetId={Number(wineVintage.defaultAssetId)}
            onBidClicked={() => onBidClicked(wineVintage.defaultAssetId)}
            onOfferClicked={() => onOfferClicked(wineVintage.defaultAssetId)}
            onBuyClicked={() => onBuyNowClicked(wineVintage.defaultAssetId)}
            size={BuyTradeButtonSize.Small}
            condensed
          />
          <WatchlistButton
            assetId={Number(wineVintage.defaultAssetId)}
            onError={onWatchlistError}
            onAdd={onAddToWatchlist}
            onRemove={onRemoveFromWatchlist}
          />
        </ActionsCell>
      </TableRow>
      {expanded && (
        <TableRow>
          <TableCell sx={{ padding: 0 }} colSpan={COLUMN_COUNT}>
            <Collapse in={expanded} unmountOnExit>
              <ExpandedContent rowId={wineVintage.defaultAssetId} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
