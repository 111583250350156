import { useMutation } from '@apollo/client';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_ADDRESS } from '../../graphql/mutations/deleteAddress';
import { GET_ADDRESSES_QUERY } from '../../graphql/queries/userAddressesQuery';
import useMediaQuery from '../../hooks/useMediaQuery';
import { logError } from '../../utils/logger';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';
import { useStyles } from './styles';

interface DeleteAddressModalProps {
  open: boolean;
  addressId: string;
  onClose: () => void;
}

export default function DeleteAddressModal({ open, onClose, addressId }: DeleteAddressModalProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [deleteAddress, { loading: deleteAddressMutationLoading }] = useMutation(DELETE_ADDRESS, {
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });

  const { t } = useTranslation();
  const classes = useStyles();

  async function onDelete() {
    const { errors } = await deleteAddress({
      variables: { addressId },
      refetchQueries: [{ query: GET_ADDRESSES_QUERY }],
    });
    if (errors) {
      logError({ error: errors[0], filename: 'DeleteAddressModal' });
      onClose();
      enqueueSnackbar(t('account:addressDetails.deleteAddress.failureNotificationMessage'), { variant: 'error' });
    } else {
      onClose();
      enqueueSnackbar(t('account:addressDetails.deleteAddress.successNotificationMessage'), {
        variant: 'info',
      });
    }
  }

  return (
    <Dialog onClose={onClose} open={open} disableEscapeKeyDown maxWidth="md" fullScreen={lessThanMd}>
      <div className={classes.modalContainer}>
        <DialogTitle id="dialog-title" onClose={onClose}>
          <Typography customVariant="h3Black">{t('account:addressDetails.deleteAddress.modalTitle')}</Typography>
        </DialogTitle>
        <DialogContentBorderTopOnly className={classes.modalContent} dividers>
          <Typography className={classes.label} customVariant="h3Black">
            {t('account:addressDetails.deleteAddress.modalDescription')}
          </Typography>
          <div className={classes.actions}>
            <Typography variant="body1" className={classes.cancelLabel} onClick={onClose}>
              {t('common:cancel')}
            </Typography>
            <Button variant="contained" color="primary" size="large" onClick={onDelete}>
              {t('common:delete')}
              {deleteAddressMutationLoading && <CircularProgress color="primary" size={16} />}
            </Button>
          </div>
        </DialogContentBorderTopOnly>
      </div>
    </Dialog>
  );
}
