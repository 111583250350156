import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossedCircleCheckmark } from '../../assets/images/crossed-circle-checkmark.svg';
import { ModalError } from '../../types/ModalError';
import { DialogContent } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  iconContainer: {
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  label: {
    marginBottom: theme.spacing(8),
  },
  button: {
    minWidth: 200,
    alignSelf: 'center',
  },
}));

interface Props {
  error?: ModalError | null;
  onClose: () => void;
}

export default function BuyNowFailureContent({ onClose, error }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.modalContainer}>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {' '}
      </DialogTitle>
      <DialogContent className={classes.modalContainer}>
        <div className={classes.iconContainer}>
          <CrossedCircleCheckmark />
        </div>
        <Typography className={classes.title} variant="h2">
          {error?.title ?? t('product:detailsModal.buyNow.tradeCompleteError.title')}
        </Typography>
        <Typography className={classes.label} variant="body1">
          <div>{error?.message ?? t('product:detailsModal.buyNow.tradeCompleteError.message')}</div>
        </Typography>
        <Button className={classes.button} variant="contained" color="primary" size="large" onClick={onClose}>
          {error?.buttonText ?? t('product:detailsModal.buyNow.tradeCompleteError.button')}
        </Button>
      </DialogContent>
    </div>
  );
}
