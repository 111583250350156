/* eslint-disable react/no-this-in-sfc */
import { useTheme } from '@mui/material/styles';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { HighchartsChart } from '../../types/Highcharts';
import Tooltip, { TooltipUnit } from '../ProductLineChart/Tooltip';
import { dateFormatDisplayMonthAndYear } from '../../utils/dateFormatter';

interface Props {
  data: Highcharts.SeriesAreasplineOptions['data'];
  min: number;
  max: number;
  loading: boolean;
  disabled: boolean;
  chartHeight?: number;
  tooltipUnit?: TooltipUnit;
}

export default function PortfolioValueLineChart({
  data,
  min,
  max,
  loading,
  disabled,
  chartHeight = 232,
  tooltipUnit = 'currency',
}: Props): JSX.Element {
  const [options, setOptions] = useState<Highcharts.Options | null>(null);
  const theme = useTheme();
  const chartRef = React.createRef<{ chart: HighchartsChart; container: React.RefObject<HTMLDivElement> }>();
  const { t } = useTranslation();

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (loading) {
      chart?.hideNoData();
      chart?.showLoading();
    } else {
      chart?.hideLoading();
    }
  }, [loading, chartRef]);

  useEffect(() => {
    setOptions({
      chart: {
        height: chartHeight,
      },
      loading: {
        // wait for 333ms before showing loading state.
        showDuration: 333,
      },
      series: [
        {
          type: 'spline',
          data,
          color: disabled ? theme.palette.chart1.disabled : theme.palette.chart1.main,
        },
      ],
      plotOptions: {
        spline: {
          marker: {
            enabled: true,
            height: 16,
            width: 16,
          },
          lineWidth: 4,
        },
      },
      yAxis: {
        min,
        max,
        labels: {
          style: {
            color: theme.palette.label,
          },
        },
        gridLineDashStyle: 'Dash',
        opposite: true,
        title: undefined,
        startOnTick: false,
      },
      xAxis: {
        type: 'datetime',
        endOnTick: false,
        tickWidth: 0,
        // monthly ticks interval on x axis
        minTickInterval: 28 * 24 * 3600 * 1000,
        labels: {
          y: 32,
          style: {
            textTransform: 'uppercase',
            color: theme.palette.label,
            fontSize: theme.typography.body3Bold.fontSize?.toString(),
            fontWeight: theme.typography.body3Bold.fontWeight?.toString(),
          },
          formatter() {
            return dateFormatDisplayMonthAndYear(new Date(this.value));
          },
        },
      },
      lang: {
        noData: t('product:noData'),
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: theme.palette.textPrimary,
        },
      },
      tooltip: {
        useHTML: true,
        borderColor: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
        style: {
          color: theme.palette.text.primary,
          textAlign: 'center',
        },
        formatter() {
          return renderToString(<Tooltip x={this.x} y={this.y} unit={tooltipUnit} />);
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, theme, max, min, disabled]);

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />;
}
