import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Typography as TTypography } from '@mui/material/styles/createTypography';
import React from 'react';
import Typography from '../Typography';
import { useStyles } from './styles';
import { TradingCardMode } from './types';

interface TradingCardProps {
  className?: string;
  title: React.ReactNode;
  mode?: TradingCardMode;
  value: string;
  actionText?: string;
  onActionClicked?: () => void;
  labelVariant?: keyof TTypography;
  showButton: boolean;
}

export default function TradingCard({
  title,
  mode = 'light',
  value,
  actionText,
  onActionClicked,
  className = '',
  labelVariant = 'numberLarge',
  showButton,
}: TradingCardProps): JSX.Element {
  const classes = useStyles({ mode });

  return (
    <Card className={`${classes.container} ${className}`}>
      {title}
      <Typography customVariant={labelVariant}>{value}</Typography>
      {showButton && (
        <Button name="action-button" onClick={onActionClicked} className={classes.pill}>
          {actionText}
        </Button>
      )}
    </Card>
  );
}
