import React from 'react';
import withStyles from '@mui/styles/withStyles';
import MuiSwitch, { SwitchProps } from '@mui/material/Switch';

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 25,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    paddingLeft: 3,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(15px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 19,
    height: 19,
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
  },
  track: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 15,
    opacity: 1,
    backgroundColor: theme.palette.grey[200],
  },
  checked: {},
}))(MuiSwitch);

export default function Switch(props: SwitchProps): JSX.Element {
  return <StyledSwitch {...props} />;
}
