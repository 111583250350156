import { CardNumber, Cvv, ExpiryDate, Frames, FramesBillingAddress } from 'frames-react';
import { useTheme } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getISO2Country } from '@cultwines/zellar-client-sdk/utils/getISO2Country';
import { Country } from '@cultwines/zellar-client-sdk/types/Country';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useQuery } from '@apollo/client';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import Typography from '../Typography';
import { useStyles } from './styles';
import { useAddress } from '../../hooks/useAddress';
import { ReactComponent as PaymentMastercard } from '../../assets/icons/payment-mastercard.svg';
import { ReactComponent as PaymentVisa } from '../../assets/icons/payment-visa.svg';
import { isNull } from '../../utils/isNull';
import { useCreateCardPayment } from '../../hooks/useCreatePaymentCard';
import { selectCreatePaymentCardVariables } from './selectors';
import { paymentCardSchema, submitPaymentForm } from './helpers';
import MitExplanationDialog from './MitExplanationDialog';
import { GET_STORED_CARD_DETAILS } from '../../graphql/queries/storedCardDetails';
import { Address } from '../../__generated__/graphql';

function selectBillingAddress(address: Address | null): FramesBillingAddress | null {
  if (!address) {
    return null;
  }

  return {
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2 ?? undefined,
    city: address.town,
    state: address.county ?? undefined,
    country: getISO2Country(address.country as Country),
    zip: address.postcode,
  };
}

interface PaymentCardFormProps {
  amount: number;
  storeCardFlag?: boolean;
  mitConsentedFlag?: boolean;
  defaultMitFlag?: boolean;
  showStoreCard?: boolean;
  showEnableMIT?: boolean;
  showMakeDefault?: boolean;
  isSubmitting: boolean;
  setIsFormValid: (isValid: boolean) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  ExpandedContent?: React.ReactNode;
  customSuccessUri?: string;
}

export default function PaymentCardForm({
  amount,
  storeCardFlag = true,
  mitConsentedFlag = true,
  defaultMitFlag = true,
  showStoreCard = true,
  showEnableMIT = true,
  showMakeDefault = true,
  isSubmitting,
  setIsFormValid,
  setIsSubmitting,
  ExpandedContent,
  customSuccessUri,
}: PaymentCardFormProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const flags = useFlags();
  const { address, loading: loadingAddress, error: addressError } = useAddress({ addressType: 'Billing' });
  const { data: paymentCards } = useQuery(GET_STORED_CARD_DETAILS);
  const billingAddress = selectBillingAddress(address);
  const theme = useTheme();
  const [storeCardDetailsChecked, setStoreCardDetailsChecked] = React.useState(storeCardFlag);
  const [agreeToMITChecked, setAgreeToMITChecked] = React.useState(mitConsentedFlag);
  const [makeDefaultChecked, setMakeDefaultChecked] = React.useState(defaultMitFlag);
  const [mitExplanationDialogOpen, setMitExplanationDialogOpen] = React.useState(false);
  const { createCardPayment } = useCreateCardPayment();

  const haveNoMITCard = paymentCards?.paymentGetCardDetails?.every((x) => x.mitConsentedFlag === false);

  function handleStoreCardDetailsCheckboxToggled(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    if (!checked) {
      setAgreeToMITChecked(false);
    }
    setStoreCardDetailsChecked(checked);
  }

  function handleAgreeMITCheckboxToggled(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    setAgreeToMITChecked(checked);
    if (checked) {
      if (showMakeDefault && haveNoMITCard) {
        setMakeDefaultChecked(true);
      }
    } else {
      setMakeDefaultChecked(false);
    }
  }

  function handleLearnMoreClicked(): void {
    setMitExplanationDialogOpen(true);
  }

  const formik = useFormik<yup.InferType<typeof paymentCardSchema>>({
    initialValues: {
      name: '',
    },
    onSubmit: async (args) => {
      const tokenEvent = await Frames.submitCard();
      await submitPaymentForm(
        createCardPayment,
        selectCreatePaymentCardVariables({
          amount,
          clientName: args.name.toUpperCase(),
          storeCardFlag: storeCardDetailsChecked,
          mitConsentedFlag: agreeToMITChecked,
          defaultMitFlag: makeDefaultChecked,
          cardToken: tokenEvent.token,
          customSuccessUri,
        }),
      );
      setIsSubmitting(false);
    },
    validationSchema: paymentCardSchema,
  });

  useEffect(() => {
    if (isSubmitting) {
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  useEffect(() => {
    setIsFormValid(
      !loadingAddress && !isNull(address) && !addressError && !formik.isSubmitting && formik.isValid && formik.dirty,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAddress, address, addressError, formik.isSubmitting, formik.isValid, formik.dirty]);

  return (
    <>
      <form className={classes.expandedContentContainer} onSubmit={formik.handleSubmit}>
        <Typography className={`${classes.flexBetween} ${classes.title}`} customVariant="body1" data-private>
          {t('wallet:payByCard.cardFormTitle')}
          <div className={`${classes.flexBetween} ${classes.paymentMethod}`}>
            <PaymentVisa />
            <PaymentMastercard />
          </div>
        </Typography>
        <Frames
          config={{
            debug: process.env.NODE_ENV === 'development',
            publicKey: process.env.REACT_APP_CHECKOUT_COM_PUBLIC_KEY!,
            cardholder: {
              billingAddress,
              name: formik.values.name.toUpperCase(),
            },
            localization: {
              cardNumberPlaceholder: t('wallet:addPaymentOptionModal.cardNumber.label'),
              expiryMonthPlaceholder: t('wallet:addPaymentOptionModal.expiry.monthPlaceholder'),
              expiryYearPlaceholder: t('wallet:addPaymentOptionModal.expiry.yearPlaceholder'),
              cvvPlaceholder: t('wallet:addPaymentOptionModal.cvv.label'),
            },
            style: {
              base: {
                // Get it as close to our text field styling as possible.
                color: theme.palette.textPrimary,
                fontSize: '14px',
                fontWeight: 300,
                letterSpacing: 'normal',
                border: `1px solid ${theme.palette.grey[100]}`,
                borderRadius: '4px',
                height: '47px',
                padding: '18px 12px',
                background: theme.palette.background.default,
                fontFamily: theme.typography.fontFamily,
              },
              // For some reason they do not expose the `hover` option on the config interface,
              // even though they should do (https://www.checkout.com/docs/integrate/frames/frames-customization-guide)
              // Despite it not being on the typescript type for this config, if we pass it in it does actually get applied.
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              hover: {
                border: `1px solid ${theme.palette.textHighlight}`,
              },
              focus: {
                border: `2px solid ${theme.palette.textHighlight}`,
              },
              placeholder: {
                base: {
                  color: theme.palette.textInformation,
                },
              },
              invalid: {
                border: `1px solid ${theme.palette.error.main}`,
              },
            },
          }}
          // TODO: in https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/9914
          cardValidationChanged={() => {}}
          cardTokenized={() => {}}
        >
          <div className={classes.wrapper}>
            <TextField
              id="name"
              name="name"
              variant="outlined"
              placeholder="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              inputProps={{ style: { textTransform: 'uppercase' }, 'aria-label': 'name' }}
            />
            <CardNumber className={classes.frameForm} />
            <ExpiryDate className={classes.frameForm} />
            <Cvv className={classes.frameForm} />
            <div className={clsx(classes.flexBetween, classes.footer)}>
              <div className={classes.checkboxes}>
                {ExpandedContent && ExpandedContent}

                {flags.storeAndReuseCardDetailsDuringTopup && showStoreCard && (
                  <div className={classes.checkboxWrapper}>
                    <Checkbox
                      checked={storeCardDetailsChecked}
                      inputProps={{ 'aria-label': 'store-details-checkbox' }}
                      color="primary"
                      onChange={handleStoreCardDetailsCheckboxToggled}
                    />
                    <Typography className={clsx(classes.storeCardDescriptionText, classes.ml1)}>
                      {t('wallet:payByCard.storeCardDescription')}
                    </Typography>
                  </div>
                )}
                {flags.storeAndReuseCardDetailsDuringTopup &&
                  flags.merchantInitiatedTransaction12341 &&
                  showEnableMIT && (
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        checked={agreeToMITChecked}
                        disabled={!storeCardDetailsChecked}
                        inputProps={{ 'aria-label': 'agree-to-mit-checkbox' }}
                        color="primary"
                        onChange={handleAgreeMITCheckboxToggled}
                      />
                      <div className={clsx(classes.column, classes.rowGap1, classes.ml1)}>
                        <Typography className={classes.storeCardDescriptionText} variant="caption">
                          {t('wallet:payByCard.addCardModal.mitConsentCheckboxLabel')}
                          <button
                            onClick={handleLearnMoreClicked}
                            type="button"
                            className={clsx(classes.learnMore, classes.bold)}
                          >
                            {t('wallet:payByCard.addCardModal.learnMore')}
                          </button>
                        </Typography>
                        <Typography className={clsx(classes.pill, classes.uppercase, classes.bold)} variant="caption">
                          {t('common:recommended')}
                        </Typography>
                      </div>
                    </div>
                  )}
                {showMakeDefault && (
                  <div className={classes.checkboxWrapper}>
                    <Checkbox
                      checked={makeDefaultChecked}
                      disabled={!agreeToMITChecked || haveNoMITCard}
                      inputProps={{ 'aria-label': 'make-default-checkbox' }}
                      color="primary"
                      onChange={(_, v) => setMakeDefaultChecked(v)}
                    />
                    <Typography className={clsx(classes.storeCardDescriptionText, classes.ml1)}>
                      {t('wallet:payByCard.makeDefaultDescription')}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Frames>
      </form>
      {mitExplanationDialogOpen && (
        <MitExplanationDialog open={mitExplanationDialogOpen} onClose={() => setMitExplanationDialogOpen(false)} />
      )}
    </>
  );
}
