import { ApolloCache, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { UPDATE_CATEGORY } from '../graphql/mutations/updateCategory';
import { UpdateCategoryMutation, UpdateCategoryMutationVariables } from '../__generated__/graphql';

interface MutationResponse {
  data: UpdateCategoryMutation | null | undefined;
  errors: readonly GraphQLError[] | undefined;
}

type CallbackFunction = (params: UpdateCategoryMutationVariables) => Promise<MutationResponse>;

export const useUpdateCategory = (): CallbackFunction => {
  const [updateCategoryMutation] = useMutation(UPDATE_CATEGORY, {
    // Catches network errors and returns them in errors in response
    onError: () => null,
    update: (cache: ApolloCache<UpdateCategoryMutation>, { data: responseData }) => {
      if (responseData?.updateCategory) {
        const id = cache.identify({ ...responseData?.updateCategory });
        cache.modify({
          id,
          fields: {
            channels() {
              return responseData.updateCategory.channels;
            },
          },
        });
      }
    },
  });

  const updateCategory = useCallback(
    async (variables: UpdateCategoryMutationVariables) => {
      const { data, errors } = await updateCategoryMutation({
        variables,
      });
      return { data, errors };
    },
    [updateCategoryMutation],
  );
  return updateCategory;
};
