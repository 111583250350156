import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import UploadFileImage from '../../assets/images/upload-file.png';
import Typography from '../Typography';

const useStyles = makeStyles(() => ({
  placeholder: {
    textAlign: 'center',
    paddingBottom: 90,
    width: '100%',
  },
  placeholderText: {
    // The image has a huge amount of white space around it,
    // for the time being just workaround that by adding the negative
    // margin. In the long run we need fresh images for the various placeholders
    // anyway, as currently they are all using the same "empty folder" image.
    marginTop: -40,
    marginBottom: 20,
  },
}));

interface Props {
  label: string;
  ActionButton?: React.ReactNode;
}
export default function EmptyPlaceholder({ label, ActionButton }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.placeholder}>
      <img width="auto" src={UploadFileImage} alt="file-upload" />
      <Typography className={classes.placeholderText} variant="h3">
        {label}
      </Typography>
      {ActionButton && ActionButton}
    </div>
  );
}
