/* eslint-disable no-nested-ternary */
export const sortItems = <T>(items: T[], isAscending: boolean, prop: keyof T): T[] => {
  return items.sort((a, b) => {
    const aValue = a[prop] === 0 ? null : a[prop];
    const bValue = b[prop] === 0 ? null : b[prop];

    if (aValue == null && bValue == null) return 0;
    if (aValue == null) return 1;
    if (bValue == null) return -1;

    if (isAscending) return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
    return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
  });
};
