import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { AddressType, isUndefined } from '@cultwines/zellar-client-sdk';
import Button from '@mui/material/Button';
import AddressModal from '../../AddressModal';
import { ReactComponent as PinIcon } from '../../../assets/icons/pin.svg';
import { ReactComponent as Trash } from '../../../assets/images/trash.svg';
import { GET_ADDRESSES_QUERY } from '../../../graphql/queries/userAddressesQuery';
import { selectAddress } from '../../../graphql/selectors/userAddressSelectors';
import Typography from '../../Typography';
import IconButton from '../../IconButton';
import { useMyAddressStyles } from './styles';
import DeleteAddressModal from '../../DeleteAddressModal';
import { logError } from '../../../utils/logger';
import { selectCountry } from '../../AddressModal/selectors';
import { Address } from '../../../__generated__/graphql';

function commaSeparatedAddress(address: Address): string {
  const line1 = `${address.addressLine1},`;
  const line2 = address.addressLine2 ? ` ${address.addressLine2},` : '';
  const line3 = address.addressLine3 ? ` ${address.addressLine3},` : '';
  const town = address.town.length > 0 ? ` ${address.town},` : '';
  const county = address.county ? ` ${address.county},` : '';
  const postcode = ` ${address.postcode}`;

  return `${line1}${line2}${line3}${town}${county}${postcode}`;
}

interface MyAddressProps {
  className?: string;
}

export default function MyAddress({ className = '' }: MyAddressProps): JSX.Element {
  const classes = useMyAddressStyles();
  const { t } = useTranslation();
  const [showAddressModal, setShowAddressModal] = React.useState(false);
  const [addressType, setAddressType] = React.useState<AddressType | null>(null);
  const { data, error: failedToGetAddresses } = useQuery(GET_ADDRESSES_QUERY);
  const [addressIdForDeletion, setAddressIdForDeletion] = React.useState<string | null>(null);
  const [billingAddressIdForDeletion, setBillingAddressIdForDeletion] = React.useState<string | null>(null);

  if (failedToGetAddresses) {
    logError({
      originalError: failedToGetAddresses,
      error: new Error('Failed to get addresses'),
      filename: 'MyAddress',
    });
  }

  const billingAddress = selectAddress(data, 'Billing');
  const deliveryAddress = selectAddress(data, 'Delivery');

  return (
    <>
      <div className={className}>
        <Typography variant="h4">{t('account:navigation.myAddress')}</Typography>
        <div className={classes.fields}>
          <div className={classes.section}>
            <Typography variant="subtitle1">{t('account:addressDetails.billingAddress.title')}</Typography>
            <div className={`${classes.address} ${billingAddress ? classes.border : ''}`}>
              {billingAddress && (
                <div className={classes.addressDetails}>
                  <PinIcon />
                  <Typography variant="subtitle2">{commaSeparatedAddress(billingAddress)}</Typography>
                </div>
              )}
              <div className={`${classes.rightAlign} ${classes.actions}`}>
                {billingAddress && (
                  <IconButton onClick={() => setBillingAddressIdForDeletion(billingAddress.addressId)} size="large">
                    <Trash />
                  </IconButton>
                )}
                <Button
                  onClick={() => {
                    setAddressType('Billing');
                    setShowAddressModal(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {billingAddress ? t('common:edit') : t('account:addressDetails.billingAddress.add')}
                </Button>
              </div>
            </div>
          </div>
          <div className={classes.section}>
            <Typography variant="subtitle1">{t('account:addressDetails.deliveryAddress.title')}</Typography>
            <div className={`${classes.address} ${deliveryAddress ? classes.border : ''}`}>
              {deliveryAddress && (
                <div className={classes.addressDetails}>
                  <PinIcon />
                  <Typography variant="subtitle2">{commaSeparatedAddress(deliveryAddress)}</Typography>
                </div>
              )}
              <div className={`${classes.rightAlign} ${classes.actions}`}>
                {deliveryAddress && (
                  <IconButton onClick={() => setAddressIdForDeletion(deliveryAddress.addressId)} size="large">
                    <Trash />
                  </IconButton>
                )}
                <Button
                  onClick={() => {
                    setAddressType('Delivery');
                    setShowAddressModal(true);
                  }}
                  variant="contained"
                  color="primary"
                  disabled={isUndefined(selectCountry(deliveryAddress ?? undefined, 'Delivery'))}
                >
                  {deliveryAddress ? t('common:edit') : t('account:addressDetails.deliveryAddress.add')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DeleteAddressModal
        open={Boolean(addressIdForDeletion) || Boolean(billingAddressIdForDeletion)}
        addressId={addressIdForDeletion! || billingAddressIdForDeletion!}
        onClose={() => {
          setAddressIdForDeletion(null);
          setBillingAddressIdForDeletion(null);
        }}
      />

      <AddressModal
        open={showAddressModal}
        onClose={() => {
          setShowAddressModal(false);
          setAddressType(null);
        }}
        addressType={addressType ?? 'Billing'}
      />
    </>
  );
}
