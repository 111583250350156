import { Namespace, Resources, TFunction } from 'react-i18next';
import React from 'react';
import { SxProps } from '@mui/material';
import { Link as RRLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { uuid } from '../../utils/uuid';
import { WatchlistAsset, WatchlistFacets } from '../../views/WatchList/selectors';
import { SortOption } from '../Table/SortMenu';
import { ActionCallback, BasicCell, CustomCell, Row, SortDirection, UnitSizeCell, WineNameCell } from '../Table/types';
import TradingTile from '../TradingTile';
import isUndefined from '../../utils/isUndefined';
import { makeUrlSafe } from '../../utils/common';
import { UrlPath } from '../../types/Enums';
import { allRegionMap } from '../../utils/allRegionMapper';
import mapRegionName from '../../utils/mapRegionName';
import lightThemePalette from '../../theme/light/palette';
import Typography from '../Typography';
import { deltaPercentage } from '../../utils/deltaPercentage';
import { isNull } from '../../utils/isNull';

/**
 * @deprecated
 */
export interface TableData extends Row {
  wineName: WineNameCell;
  vintage: BasicCell;
  unitSize: UnitSizeCell;
  marketValue: BasicCell;
  highestBid: CustomCell;
  lowestOffer: CustomCell;
  region: BasicCell;
  spread: BasicCell;
}

interface SelectWatchlistTableDataProps {
  onOfferClicked: ActionCallback;
  onBidClicked: ActionCallback;
  onBuyNowClicked: ActionCallback;
  onDeleteClicked: ActionCallback;
  watchlistItems: WatchlistAsset[];
  clickableTradeTiles13816?: boolean;
  t?: TFunction<Namespace<keyof Resources>>;
}

/**
 * @deprecated
 */
export function selectWatchlistTableData({
  onBidClicked,
  onDeleteClicked,
  onOfferClicked,
  onBuyNowClicked,
  watchlistItems,
  clickableTradeTiles13816,
  t,
}: SelectWatchlistTableDataProps): TableData[] {
  return watchlistItems.map((item) => {
    const { zellarColour, deltaSymbol } = deltaPercentage(
      typeof item.performancePercentageDelta === 'number'
        ? item.performancePercentageDelta
        : item.performancePercentageDelta,
    );

    const HighestBidTradingTile = clickableTradeTiles13816 ? (
      <TradingTile mode="bid" value={item.highestBid} onClick={() => onBidClicked(item.assetId.toString())} />
    ) : (
      <TradingTile mode="bid" value={item.highestBid} unitCount={item.unitCount} unitSize={item.unitSize} />
    );

    const LowestOfferTradingTile = clickableTradeTiles13816 ? (
      <TradingTile
        mode="offer"
        value={item.lowestOffer}
        onClick={!isUndefined(item.lowestOffer) ? () => onBuyNowClicked(item.assetId.toString()) : undefined}
      />
    ) : (
      <TradingTile mode="offer" value={item.lowestOffer} unitCount={item.unitCount} unitSize={item.unitSize} />
    );

    const { assetId, name: wineName, vintage, unitCount, unitSize } = item;
    const unitsSizeStr = `${unitCount}x${unitSize}`;
    const safeWineName = makeUrlSafe(wineName);
    const pageSlug = `${assetId}_${safeWineName}_${vintage}_${unitsSizeStr}`;
    const prodPageUrl = `${UrlPath.PRODUCT_PAGE}/${pageSlug}`;

    const getRegionColor = (regionName: string) => {
      const regionData = allRegionMap.find((x) => x.region.toLowerCase() === regionName.toLowerCase());
      const name = mapRegionName(regionData?.allocatedRegion ?? regionName);
      const regionsColors = lightThemePalette.regions as Record<string, string>;

      return regionsColors[name];
    };

    const getSpread = (spreadNumber: number | null | undefined) => {
      if (isNull(spreadNumber) || !spreadNumber) return '-';

      if (spreadNumber > 0) return <span className="tw-text-[#1FE85C]">{`+${spreadNumber}%`}</span>;

      return <span className="tw-text-[#EC2E48]">{`-${spreadNumber}%`}</span>;
    };

    const sxStyle: SxProps = {
      padding: 0,
      border: '1px solid #00000033',
      textAlign: 'center',
    };

    const regionBockColor = (
      <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
        <div
          style={{
            backgroundColor: getRegionColor(item.region),
            width: '6px',
            height: '80px',
          }}
        />
        <Typography variant="subtitle2">{item.region}</Typography>
      </div>
    );

    return {
      region: {
        mainContent: regionBockColor,
        sx: sxStyle,
      },

      wineName: {
        customCell: (
          <Link
            className="tw-flex tw-flex-col tw-h-[70px] tw-justify-center tw-no-underline tw-text-black"
            component={RRLink}
            to={`${UrlPath.PRODUCT_PAGE}/${pageSlug}`}
          >
            <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <Typography variant="subtitle2">{item.name}</Typography>

              <div className="tw-w-[40px] tw-min-w-[40px] tw-max-h-[25px] tw-h-[25px] tw-text-center tw-bg-[#B1DED8] tw-py-1 tw-ml-2 tw-rounded-[4px] tw-text-xs tw-flex tw-justify-center tw-items-center">
                <Typography variant="subtitle2">{item?.score ?? '-'}</Typography>
              </div>
            </div>
          </Link>
        ),
        wineName: item.name,
        region: item.region,
        link: prodPageUrl,
        appellation: null,
        sx: { padding: 0, paddingX: 6, border: '1px solid #00000033' },
      },
      vintage: { mainContent: item.vintage, sx: sxStyle },
      unitSize: {
        unitCount: item.unitCount,
        unitSize: item.unitSize,
        sx: sxStyle,
      },
      score: {
        score: item.score,
        sx: sxStyle,
      },
      marketValue: {
        mainContent: (
          <div>
            {formatterWholeNumber.format(item.marketValue)}
            {item.performancePercentageDelta !== 0 && (
              <Typography customVariant="subtitle3" zellarColour={zellarColour}>
                {deltaSymbol}
                {item.performancePercentageDelta}% {t?.('product:lastTwelveMonths')}
              </Typography>
            )}
          </div>
        ),

        sx: sxStyle,
      },
      highestBid: {
        content: <div className="tw-flex tw-flex-row tw-items-center tw-justify-center"> {HighestBidTradingTile}</div>,
        sx: sxStyle,
      },
      lowestOffer: {
        content: <div className="tw-flex tw-flex-row tw-items-center tw-justify-center"> {LowestOfferTradingTile}</div>,
        sx: sxStyle,
      },
      performance: {
        data: item.marketData,
        deltaNum: item.performanceValueDelta,
        deltaPct: item.performancePercentageDelta,
        sx: sxStyle,
      },

      spread: {
        mainContent: getSpread(isNull(item.spread) ? null : item.spread),
        sx: sxStyle,
      },

      rowId: item.assetId.toString(),
      actions: {
        trade: {
          onBidClicked,
          onOfferClicked,
          onBuyClicked: onBuyNowClicked,
          assetId: item.assetId,
          condensed: true,
        },
        delete: {
          onDeleteClicked,
        },
        sx: sxStyle,
      },
    };
  });
}

export function getSortOptions(
  sortDirection: SortDirection,
  activeSortKey: keyof WatchlistFacets,
  t: TFunction<Namespace<keyof Resources>>,
): SortOption<keyof WatchlistFacets>[] {
  const sortOptions: SortOption<keyof WatchlistFacets>[] = [
    {
      id: uuid(),
      direction: 'desc',
      key: 'bestBid',
      active: activeSortKey === 'bestBid',
      primaryLabel: t('discover:activeMarkets.sortOptions.bestBids'),
      secondaryLabel: t('search:sort.bestFirst'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'bestOffer',
      active: activeSortKey === 'bestOffer',
      primaryLabel: t('discover:activeMarkets.sortOptions.bestOffers'),
      secondaryLabel: t('search:sort.bestFirst'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'name',
      active: activeSortKey === 'name' && sortDirection === 'desc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.zToA'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'name',
      active: activeSortKey === 'name' && sortDirection === 'asc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.aToZ'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'region',
      active: activeSortKey === 'region' && sortDirection === 'desc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.zToA'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'region',
      active: activeSortKey === 'region' && sortDirection === 'asc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.aToZ'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'score',
      active: activeSortKey === 'score' && sortDirection === 'desc',
      primaryLabel: t('search:filters.score'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'score',
      active: activeSortKey === 'score' && sortDirection === 'asc',
      primaryLabel: t('search:filters.score'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'vintage',
      active: activeSortKey === 'vintage' && sortDirection === 'desc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.newestToOldest'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'vintage',
      active: activeSortKey === 'vintage' && sortDirection === 'asc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.oldestToNewest'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'marketValue',
      active: activeSortKey === 'marketValue' && sortDirection === 'desc',
      primaryLabel: t('search:filters.marketValue'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'marketValue',
      active: activeSortKey === 'marketValue' && sortDirection === 'asc',
      primaryLabel: t('search:filters.marketValue'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'unitCount',
      active: activeSortKey === 'unitCount' && sortDirection === 'desc',
      primaryLabel: t('trade:units'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'unitCount',
      active: activeSortKey === 'unitCount' && sortDirection === 'asc',
      primaryLabel: t('trade:units'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'performancePercentageDelta',
      active: activeSortKey === 'performancePercentageDelta' && sortDirection === 'desc',
      primaryLabel: t('search:filters.performance'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'performancePercentageDelta',
      active: activeSortKey === 'performancePercentageDelta' && sortDirection === 'asc',
      primaryLabel: t('search:filters.performance'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'highestBid',
      active: activeSortKey === 'highestBid' && sortDirection === 'desc',
      primaryLabel: t('product:trading.highestBid'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'highestBid',
      active: activeSortKey === 'highestBid' && sortDirection === 'asc',
      primaryLabel: t('product:trading.highestBid'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'lowestOffer',
      active: activeSortKey === 'lowestOffer' && sortDirection === 'desc',
      primaryLabel: t('product:trading.lowestOffer'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'lowestOffer',
      active: activeSortKey === 'lowestOffer' && sortDirection === 'asc',
      primaryLabel: t('product:trading.lowestOffer'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'score',
      active: activeSortKey === 'score' && sortDirection === 'desc',
      primaryLabel: t('discover:activeMarkets.sortOptions.score'),
      secondaryLabel: t('search:sort.highToLow'),
      filterKey: 'score',
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'score',
      active: activeSortKey === 'score' && sortDirection === 'asc',
      primaryLabel: t('discover:activeMarkets.sortOptions.score'),
      secondaryLabel: t('search:sort.lowToHigh'),
      filterKey: 'score',
    },
  ];

  return sortOptions;
}
