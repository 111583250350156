import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Counter from '../Counter';
import Typography from '../Typography';
import { colours } from '../../theme/light/palette';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  unitCountText: {
    marginTop: theme.spacing(1),
    color: colours.mutedGreen,
  },
}));

interface UnitPickerProps {
  unitSize: string;
  selectedUnitCount: number;
  availableUnitCount: number;
  handleAdd: () => void;
  handleMinus: () => void;
}

export default function UnitPicker({
  unitSize,
  availableUnitCount,
  selectedUnitCount,
  handleAdd,
  handleMinus,
}: UnitPickerProps): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div>
        <Typography customVariant="h5">{unitSize}</Typography>
        <Typography customVariant="subtitle2" className={classes.unitCountText}>
          {availableUnitCount} Unit(s) available
        </Typography>
      </div>
      <Counter
        count={`${selectedUnitCount} ${selectedUnitCount > 1 ? 'Units' : 'Unit'}`}
        onAdd={handleAdd}
        onMinus={handleMinus}
        disableAdd={selectedUnitCount === availableUnitCount}
        disableMinus={selectedUnitCount === 1}
      />
    </div>
  );
}
