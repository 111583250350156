import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { dateFormatDisplayMonthAndYear } from '../../utils/dateFormatter';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary,
  },
  title: {
    /**
     * Unable to reference any of the custom typography
     * variants that have been added here without breaking
     * the application when it tries to render the tooltip.
     * It is directly related to how this component is rendered,
     * as the custom variants can be accessed in other components
     * without issue.
     */
    fontSize: '14px',
    lineHeight: '18px',
  },
  date: {
    /**
     * Unable to reference any of the custom typography
     * variants that have been added here without breaking
     * the application when it tries to render the tooltip.
     * It is directly related to how this component is rendered,
     * as the custom variants can be accessed in other components
     * without issue.
     */
    opacity: '50%',
    fontSize: '10px',
    lineHeight: '12px',
  },
}));

interface Props {
  x: number;
  y: number;
  unit?: TooltipUnit;
}

export type TooltipUnit = 'currency' | 'percentage' | 'none';

function formatLabel(y: number, format: TooltipUnit): string {
  switch (format) {
    case 'currency': {
      return formatterWholeNumber.format(y);
    }

    case 'percentage': {
      return `${y.toFixed(2)}%`;
    }

    case 'none': {
      return y.toString();
    }

    default: {
      return y.toString();
    }
  }
}

export default function Tooltip({ x, y, unit = 'currency' }: Props): JSX.Element {
  const { classes: classNames } = useStyles();
  return (
    <div className={classNames.container}>
      <div className={classNames.title}>{formatLabel(y, unit)}</div>
      <div className={classNames.date}>{dateFormatDisplayMonthAndYear(new Date(x))}</div>
    </div>
  );
}
