import { selectExpirationDateOptions } from '../Select/constants';
import { ExpirationDate } from '../Select/types';
import { CreateOrderInput } from '../../__generated__/graphql';

function selectCancelAt(expiration: ExpirationDate): string {
  const date = new Date();
  date.setDate(date.getDate() + expiration);
  return date.toISOString();
}

export const selectExpirationLabel = (expirationDate: ExpirationDate): string => {
  const options = selectExpirationDateOptions();
  const target = options.find((option) => option.value === expirationDate);
  return target?.label || 'N/a';
};

interface OrderVariables {
  price: number;
  quantity: number;
  expirationDate: ExpirationDate;
}
export function selectCreateOrderVariables(
  { price, quantity, expirationDate }: OrderVariables,
  assetId: number,
): CreateOrderInput {
  return {
    assetId,
    price,
    quantity,
    totalPrice: price * quantity,
    cancelAt: selectCancelAt(expirationDate),
  };
}
