import isUndefined from './isUndefined';

export default function normalizeChartDateDataWithMarker(
  data: Record<string, number> | undefined,
  marker?: boolean,
): Array<{ x: number; y: number; marker?: { enabled: boolean; radius: number } }> {
  if (isUndefined(data)) {
    return [];
  }
  return Object.entries(data).map((d, i) => {
    if (marker) {
      if (i + 1 === Object.keys(data).length) {
        return { x: new Date(d[0]).getTime(), y: d[1], marker: { enabled: true, radius: 10 } };
      }
    }
    return { x: new Date(d[0]).getTime(), y: d[1] };
  });
}
