import { alpha } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';

export const colours = {
  darkBlue: '#150036',
  dmPurple: '#1D084F',
  brightGreen: '#1FE85C',
  mutedGreen: '#5DD39E',
  cultGreen: '#B1DED8',
  cultPurple: '#CACBFB',
  cultPurple70Percent: 'rgba(202, 203, 251, 0.7)',
  lightYellow: '#F9E29B',
  lightYellow70Percent: 'rgba(249, 226, 155, 0.7)',
  cultYellow: '#F6D46B',
  cultOrange: '#FF906D',
  burntOrange: '#EF6F12',
  red: '#EA4A60',
  grey: '#E0E0E0',
  lightestGrey: 'rgba(21, 0, 54, 0.2)',
  lightGrey: '#F8F8F9',
  darkGrey: '#B8B8B8',
  black: '#000000',
  white: '#FFFFFF',
  purpleMid: '#8A809A',
  activeBlue: '#B4CCE9',
  lightBlue: '#DDEAF9',
  highlightBlue: '#EDF5FF',
  brightBlueHighlight: '#007EFF',
  brightOrange: '#FB9D0E',
  brighterOrange: '#FFB340',
  alertRed: '#EC2E48',
  paleRed: '#E75656',
  darkRed: '#882121',
  violet: '#D152FE',
  deepPurple: '#522363',
  electricBlue: '#3C97DA',
  blueberry: '#3E3495',
  forestGreen: '#25752D',
  cornsilk: '#fff8ee',
};

const lightThemePalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: colours.brightOrange,
    contrastText: colours.white,
  },
  secondary: {
    main: colours.white,
    contrastText: colours.darkBlue,
  },
  error: {
    main: colours.alertRed,
    contrastText: colours.white,
  },
  info: {
    main: colours.darkBlue,
    contrastText: colours.white,
  },
  warning: {
    main: colours.alertRed,
    contrastText: colours.white,
  },
  success: {
    main: colours.brightGreen,
    contrastText: colours.darkBlue,
  },
  text: {
    primary: colours.darkBlue,
    secondary: colours.white,
  },
  textPrimary: colours.darkBlue,
  textSecondary: colours.white,
  textLabel: colours.purpleMid,
  textInformation: colours.darkGrey,
  textFailure: colours.alertRed,
  textSuccess: colours.brightGreen,
  textHighlight: colours.brightBlueHighlight,
  textSecondaryHighlight: colours.brightOrange,
  label: colours.darkGrey,
  background: {
    default: colours.white,
    paper: colours.white,
  },
  dividers: {
    primary: colours.grey,
    secondary: colours.purpleMid,
    tertiary: colours.darkGrey,
  },
  navigation: {
    background: {
      active: {
        main: colours.brightGreen,
        contrastText: colours.darkBlue,
      },
      default: {
        main: colours.darkBlue,
        contrastText: colours.brightGreen,
      },
    },
  },
  card: {
    background: {
      dark: colours.darkBlue,
      light: colours.white,
      main: colours.white,
    },
    accent: colours.brightGreen,
  },
  grey: {
    A100: colours.lightestGrey,
    '50': colours.lightGrey,
    '100': colours.grey,
    '200': colours.darkGrey,
  },
  chart1: {
    main: colours.brightGreen,
    disabled: colours.darkGrey,
  },
  chart2: {
    main: colours.red,
  },
  chart3: {
    main: colours.cultPurple,
  },
  chart4: {
    main: colours.darkBlue,
  },
  scoreChart: {
    main: colours.cultYellow,
  },
  textField: {
    active: colours.brightBlueHighlight,
    hover: colours.brightBlueHighlight,
  },
  select: {
    menuItem: {
      active: colours.activeBlue,
      hover: colours.highlightBlue,
    },
  },
  button: {
    primary: {
      background: {
        main: colours.brightOrange,
        hover: colours.burntOrange,
        disabled: colours.darkGrey,
      },
      text: {
        main: colours.white,
        disabled: colours.darkBlue,
      },
    },
    tertiary: {
      background: {
        main: colours.darkBlue,
        hover: alpha(colours.darkBlue, 0.7),
        disabled: colours.deepPurple,
      },
      text: {
        main: colours.white,
        disabled: colours.purpleMid,
      },
    },
  },
  regions: {
    'rest-of-world': colours.forestGreen,
    bordeaux: colours.paleRed,
    burgundy: colours.darkRed,
    champagne: colours.cultYellow,
    italy: colours.electricBlue,
    rhone: colours.violet,
    usa: colours.blueberry,
  },
  common: {
    ...colours,
  },
};

export default lightThemePalette;
