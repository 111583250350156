import { ApolloQueryResult, useQuery } from '@apollo/client';
import { USER_EXTERNAL_PORTFOLIO_VALUE } from '../graphql/queries/userExternalPortfolioValue';
import { UserExternalPortfolioTotalValueQuery } from '../__generated__/graphql';

interface UseExternalPortfolioValueHookResponse {
  data: number | undefined;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<UserExternalPortfolioTotalValueQuery>>;
}

export const useExternalPortfolioValue = (): UseExternalPortfolioValueHookResponse => {
  const { data, loading, refetch } = useQuery(USER_EXTERNAL_PORTFOLIO_VALUE, {
    pollInterval: 4000,
  });
  return { data: data?.userExternalPortfolioTotalValue, loading, refetch };
};
