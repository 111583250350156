import { useQuery } from '@apollo/client';
import { Stack, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHistoricalMarketValueChangeData from '../../hooks/useHistoricalMarketValueChangeData';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ChartType } from '../../types/ChartType';
import { DateRangeVariant } from '../../types/DateRangeVariant';
import calculateDateRange from '../../utils/calculateDateRange';
import normalizeChartDateData from '../../utils/normalizeChartDateData';
import normalizeMultipleChartDateDataWithId from '../../utils/normalizeMultipleChartDateDataWithId';
import selectChartExtremes from '../../utils/selectChartExtremes';
import selectChartExtremesFromMultipleSeries from '../../utils/selectChartExtremesFromMultipleSeries';
import ToggleButton from '../ToggleButton';
import DateRangePicker from './DateRangePicker';
import SeriesLineChart from './SeriesLineChart';
import { HistoricalMarketDataChange } from '../../__generated__/graphql';
import { GET_MARKET_DATA_QUERY } from '../../graphql/queries/marketData';
import { PRODUCT_DATA_WATCHLIST_QUERY } from '../ProductSummary/queries';
import MarketPerformanceDisplay from './MarketPerformanceDisplay';
import MarketValue from './MarketValue';
import { selectProductSummary } from '../ProductSummary/selectors';
import { usePercentageDifference } from '../../hooks/usePercentageDifference';

const dateRangeVariants = ['6M', '1Y', '5Y', '10Y', 'YTD', 'MAX'] as DateRangeVariant[];
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  toggleButtons: {
    marginLeft: 'auto',
  },
  footer: {
    marginLeft: theme.spacing(9),
  },
}));

interface ProductLineChartProps {
  assetId: number;
  isLoggedIn?: boolean;
  isProductError?: boolean;
}

export default function ProductLineChart({
  assetId,
  isLoggedIn = true,
  isProductError,
}: ProductLineChartProps): JSX.Element {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeVariant>('MAX');
  const [chartType, setChartType] = useState<ChartType>(ChartType.Absolute);
  const [dateFrom, setDateFrom] = useState<string>(calculateDateRange(selectedDateRange).from);
  const [dateTo, setDateTo] = useState<string>(calculateDateRange(selectedDateRange).to);
  const classes = useStyles();
  const theme = useTheme();
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();

  const gqlContext = isLoggedIn ? {} : { context: { serviceName: 'insecure' } };
  const { data, loading, error, previousData } = useQuery(GET_MARKET_DATA_QUERY, {
    variables: {
      assetId,
      dateFrom,
      dateTo,
      includeWineSearcher: true,
    },
    ...gqlContext,
  });
  const { data: percentageData } = useHistoricalMarketValueChangeData(
    {
      variables: {
        assetIds: [assetId],
        dateFrom,
        dateTo,
      },
    },
    isLoggedIn,
  );

  const { data: assetData, loading: loadingAssetData } = useQuery(PRODUCT_DATA_WATCHLIST_QUERY, {
    variables: {
      assetId,
    },
    ...gqlContext,
  });
  const { percentageDifference } = usePercentageDifference(
    { assetId, dateRange: calculateDateRange('1Y') },
    isLoggedIn,
  );

  const normalisedProductData = { ...selectProductSummary(assetData?.productAsset), ...assetData?.productAsset };

  function handleDateRangeChanged(dateRangeVariant: DateRangeVariant) {
    setSelectedDateRange(dateRangeVariant);
    const range = calculateDateRange(dateRangeVariant);
    setDateFrom(range.from);
    setDateTo(range.to);
  }

  function handleToggle(toggleState: boolean): void {
    setChartType(toggleState ? ChartType.Rebase : ChartType.Absolute);
  }

  const latestPercentageData = percentageData?.historicalMarketValueChange as HistoricalMarketDataChange[];
  const normalisedPercentageData = normalizeMultipleChartDateDataWithId(latestPercentageData)
    .map((asset) => asset.data)
    .reduce((prev, curr) => [...prev, ...curr], []);
  const latestMarketData = data?.productAsset?.marketData || previousData?.productAsset?.marketData;
  const latestData = latestMarketData?.wineSearcher;

  const normalisedData = normalizeChartDateData(latestData);
  const [percentageMin, percentageMax] = useMemo(
    () => selectChartExtremesFromMultipleSeries(latestPercentageData),
    [latestPercentageData],
  );
  const [min, max] = useMemo(() => selectChartExtremes(latestData), [latestData]);

  const getChartTitle = () => {
    let chartTitle = '';

    if (!greaterThanSm) {
      chartTitle = '';
    } else if (chartType === ChartType.Absolute) {
      chartTitle = t('product:price');
    } else {
      chartTitle = t('common:percentage');
    }
    return chartTitle;
  };

  if (error || isProductError) {
    return <Typography>{t('common:error')}</Typography>;
  }
  return (
    <div className={classes.container}>
      <Stack direction={greaterThanSm ? 'row' : 'column'} alignItems="center" justifyContent="space-between">
        <div className={`tw-flex tw-flex-row tw-items-center ${greaterThanSm ? '' : 'tw-mb-8'}`}>
          <MarketValue
            greaterThanSm={greaterThanSm}
            translation={t}
            percentageDifference={percentageDifference ?? 0}
            marketValue={normalisedProductData.marketValue ?? 0}
            loading={loadingAssetData}
          />

          <MarketPerformanceDisplay
            greaterThanSm={greaterThanSm}
            translation={t}
            performance={latestMarketData?.performance}
            dateRange={selectedDateRange}
            loading={loading}
          />
        </div>

        <ToggleButton
          falseStateName={t('market:absolute')}
          trueStateName={t('market:rebase')}
          onToggle={handleToggle}
          toggleState={!!chartType}
        />
      </Stack>
      <SeriesLineChart
        data={chartType === ChartType.Absolute ? normalisedData : normalisedPercentageData}
        title={getChartTitle()}
        min={chartType === ChartType.Absolute ? min : percentageMin}
        max={chartType === ChartType.Absolute ? max : percentageMax}
        loading={loading}
        chartType={chartType}
      />

      <div className={greaterThanSm ? classes.footer : ''}>
        <DateRangePicker
          dateRangeUpdated={handleDateRangeChanged}
          selectedDateRangeVariant={selectedDateRange}
          variants={dateRangeVariants}
          assetId={assetId}
        />
      </div>
    </div>
  );
}
