import { graphql } from '../../__generated__';

export const GET_STORED_CARD_DETAILS = graphql(`
  query PaymentGetCardDetails {
    paymentGetCardDetails {
      instrumentId
      last4
      clientName
      expiry
      scheme
      mitConsentedFlag
      defaultMitFlag
    }
  }
`);
