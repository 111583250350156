import { Asset, HistoricalMarketDataChange } from '../__generated__/graphql';
import normalizeChartDateData from './normalizeChartDateData';

interface NormalizedDataWithId {
  assetId: number;
  data: Array<{ x: number; y: number }>;
}

function isRebaseData(arg: Asset | HistoricalMarketDataChange): arg is HistoricalMarketDataChange {
  return arg.__typename === 'HistoricalMarketDataChange';
}

export default function normalizeMultipleChartDateDataWithId(
  data: Asset[] | HistoricalMarketDataChange[] | null | undefined,
): Array<NormalizedDataWithId> {
  if (!data || !data.length) {
    return [];
  }
  return data.map((d: Asset | HistoricalMarketDataChange) => {
    if (isRebaseData(d)) {
      return {
        assetId: d.assetId,
        data: normalizeChartDateData(d.wineSearcher),
      };
    }

    return {
      assetId: d.id,
      data: normalizeChartDateData(d.marketData.wineSearcher),
    };
  });
}
