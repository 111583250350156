import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParameters } from '../../hooks';
import { Modal } from '../../types/Modal';
import BuyNowModal from '../BuyNowModal';
import BuyTradeActionButton, { Size } from '../BuyTradeActionButton';
import DotGauge from '../DotGauge';
import DrinkingWindowChip, { DrinkingWindowAdvice } from '../DrinkingWindowChip';
import { LiquidityScoreRating } from '../SearchResults/types';
import Typography from '../Typography';
import OrderModal from '../OrderModal';
import { Mode } from '../OrderModal/types';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';

const useStyles = makeStyles(() => ({
  column: {
    width: '100%',
  },
  buttonContainer: {
    width: 'fit-content',
    margin: '30px auto',
  },
  cell: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '4px',
    textTransform: 'capitalize',
  },
}));

export interface ColumnData {
  marketValue: string | null;
  performance: string | null;
  score: string;
  drinkingWindow: DrinkingWindowAdvice;
  highestBid: string | null;
  lowestOffer: string | null;
  lastTraded: string | null;
  liquidityScore: LiquidityScoreRating | null;
  assetId: number;
}

interface Props {
  assetId: number;
  data: ColumnData;
  className?: string;
}

function Cell({ children }: { children: React.ReactNode }): JSX.Element {
  const classes = useStyles();
  return <div className={classes.cell}>{children}</div>;
}

export default function Column({ assetId, data, className = '' }: Props): JSX.Element | null {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQueryParameters();
  const openModal = queryParams.get('modal');
  const selectedAssetId = queryParams.get('assetId');
  const { showTopUpBuySuccessSummaryModal } = useCardPaymentCallback();

  function handleOpenBuyNowModalClicked() {
    history.push(`${history.location.pathname}${history.location.search}&modal=${Modal.BuyNow}&assetId=${assetId}`);
  }

  function handleOpenBidModalClicked() {
    history.push(`${history.location.pathname}${history.location.search}&modal=${Modal.Bid}&assetId=${assetId}`);
  }

  function handleOpenOfferModalClicked() {
    history.push(`${history.location.pathname}${history.location.search}&modal=${Modal.Offer}&assetId=${assetId}`);
  }

  function handleCloseModal() {
    queryParams.delete('modal');
    queryParams.delete('assetId');
    history.push({ pathname: history.location.pathname, search: queryParams.toString(), hash: history.location.hash });
  }

  return (
    <div className={clsx({ [classes.column]: true, [className]: true })}>
      <Cell>
        <Typography customVariant="subtitle2">{isNull(data.marketValue) ? '-' : data.marketValue}</Typography>
      </Cell>
      <Cell>
        <Typography customVariant="subtitle2">{isNull(data.performance) ? '-' : data.performance}</Typography>
      </Cell>
      <Cell>
        {isNull(data.liquidityScore) ? (
          <Typography>-</Typography>
        ) : (
          <DotGauge dotCount={5} filledDots={data.liquidityScore} />
        )}
      </Cell>
      <Cell>
        <Typography customVariant="subtitle2">{data.score}</Typography>
      </Cell>
      <Cell>
        <DrinkingWindowChip advice={data.drinkingWindow} />
      </Cell>
      <Cell>
        <Typography customVariant="subtitle2">{isNull(data.highestBid) ? '-' : data.highestBid}</Typography>
      </Cell>
      <Cell>
        <Typography customVariant="subtitle2">{isNull(data.lowestOffer) ? '-' : data.lowestOffer}</Typography>
      </Cell>
      <Cell>
        <Typography customVariant="subtitle2">{isNull(data.lastTraded) ? '-' : data.lastTraded}</Typography>
      </Cell>
      <div className={classes.buttonContainer}>
        <BuyTradeActionButton
          size={Size.Small}
          onBidClicked={handleOpenBidModalClicked}
          onOfferClicked={handleOpenOfferModalClicked}
          onBuyClicked={handleOpenBuyNowModalClicked}
          assetId={assetId}
        />
      </div>
      {openModal === Modal.Bid && selectedAssetId && Number(selectedAssetId) === assetId && (
        <OrderModal
          assetId={assetId}
          open={openModal === Modal.Bid}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="bid"
        />
      )}
      {openModal === Modal.Offer && selectedAssetId && Number(selectedAssetId) === assetId && (
        <OrderModal
          assetId={assetId}
          open={openModal === Modal.Offer}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="offer"
        />
      )}
      {openModal === Modal.BuyNow && selectedAssetId && Number(selectedAssetId) === assetId && (
        <BuyNowModal assetId={assetId} open={openModal === Modal.BuyNow} onClose={handleCloseModal} />
      )}
      {selectedAssetId && showTopUpBuySuccessSummaryModal && (
        <BuyNowModal assetId={Number(selectedAssetId)} open onClose={handleCloseModal} />
      )}
    </div>
  );
}
