import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';

interface Props {
  callback: () => Promise<unknown>;
  snackbarKey: string;
}

const useStyles = makeStyles(() => ({
  label: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

export default function UndoButton({ callback, snackbarKey }: Props): JSX.Element {
  const { closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  async function handleClick() {
    await callback();
    closeSnackbar(snackbarKey);
  }

  return (
    <Typography onClick={handleClick} className={classes.label} customVariant="body1">
      {t('common:undo')}
    </Typography>
  );
}
