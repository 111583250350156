/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TypographyBase, { TypographyProps as PropsBase } from '@mui/material/Typography';
import { Typography as TTypography, TypographyStyle } from '@mui/material/styles/createTypography';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

/**
 * Looks at the set of typography variants we have in the theme, including
 * the custom ones we've added by extending the `TypographyOptions`
 * and `Typography` types. This allows us to generate a class for each of our
 * variants, and return that in a format which can be provided to material UI.
 * This is effectively the shorthand version of writing out the following for each
 * of the typography variants we have:
 *
 * ```typescript
 * const useStyles = makeStyles(
 *   theme => ({
 *     customTypographyVariant1: {
 *       ...theme.typography.customTypographyVariant1
 *     }
 *   })
 * );
 * ```
 *
 * @param theme The material ui theme.
 * @returns An object which is used to represent a set of
 * classes, which can be passed to `makeStyles`.
 */
const variants = (theme: Theme) => {
  return Object.keys(theme.typography)
    .map((key) => {
      return {
        [key]: theme.typography[key as keyof TTypography] as TypographyStyle,
      };
    })
    .reduce((prev, curr) => ({ ...prev, ...curr }));
};

export type TextColour =
  | 'textPrimary'
  | 'textSecondary'
  | 'textLabel'
  | 'textInformation'
  | 'textSuccess'
  | 'textFailure'
  | 'textSecondaryHighlight'
  | 'textHighlight';

const useStyles = makeStyles((theme) => variants(theme));
const useTextColourStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.textPrimary,
  },
  textSecondary: {
    color: theme.palette.textSecondary,
  },
  textLabel: {
    color: theme.palette.textLabel,
  },
  textInformation: {
    color: theme.palette.textInformation,
  },
  textSuccess: {
    color: theme.palette.textSuccess,
  },
  textFailure: {
    color: theme.palette.textFailure,
  },
  textHighlight: {
    color: theme.palette.textHighlight,
  },
  textSecondaryHighlight: {
    color: theme.palette.textSecondaryHighlight,
  },
}));
export type TypographyVariantClassKey = keyof TTypography;

interface TypographyProps extends PropsBase {
  /**
   * Use this when you need a variant that is not part of the default supported
   * set defined by material ui. This whole wrapper exists to make the process
   * of requesting one of our custom variants a breeze. See https://github.com/mui-org/material-ui/issues/15573 &
   * https://next.material-ui.com/customization/theme-components/#adding-new-component-variants for
   * more information on why we need to do this.
   */
  customVariant?: TypographyVariantClassKey;
  zellarColour?: TextColour;
}

export default function Typography({
  customVariant,
  zellarColour,
  className = '',
  ...rest
}: TypographyProps): JSX.Element {
  const variantClasses = useStyles();
  const colourClasses = useTextColourStyles();

  if (!customVariant) {
    return <TypographyBase className={`${zellarColour ? colourClasses[zellarColour] : ''} ${className}`} {...rest} />;
  }

  // extract `variant` in case that was also supplied.
  const { variant, ...remainder } = rest;
  return (
    <TypographyBase
      className={`${variantClasses[customVariant]} ${zellarColour ? colourClasses[zellarColour] : ''} ${className}`}
      {...remainder}
    />
  );
}
