import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  billingCountrySelectOptions,
  deliveryCountrySelectOptions,
  AddressType,
  COUNTRIES,
} from '@cultwines/zellar-client-sdk';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Select from '../Select';
import { useAddressFormStyles } from './styles';
import TextField from '../TextField';
import { AddressInput } from '../../__generated__/graphql';

const addressSchema = yup.object({
  addressLine1: yup.string().required(),
  addressLine2: yup.string(),
  addressLine3: yup.string(),
  town: yup.string().required(),
  countyState: yup.string(),
  postZipCode: yup.string().required(),
  country: yup
    .object({
      value: yup
        .number()
        .oneOf(COUNTRIES.map((_, i) => i))
        .required(),
      label: yup
        .string()
        .oneOf([...COUNTRIES])
        .required(),
    })
    .required(),
});

export interface AddressFormProps {
  addressType: AddressType;
  initialValues: yup.InferType<typeof addressSchema>;
  onSubmit: (address: AddressInput, addressId?: string) => void;
  addressId?: string;
}

export default function AddressForm({
  onSubmit,
  initialValues,
  addressType,
  addressId,
}: AddressFormProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useAddressFormStyles();

  const formik = useFormik<yup.InferType<typeof addressSchema>>({
    initialValues,
    onSubmit: (args) => {
      onSubmit(
        {
          addressLine1: args.addressLine1,
          addressLine2: args.addressLine2,
          addressLine3: args.addressLine3,
          // I don't think town should be required.
          town: args.town ?? '',
          postcode: args.postZipCode,
          type: addressType,
          county: args.countyState,
          country: args.country.label,
        },
        addressId,
      );
    },
    validationSchema: addressSchema,
  });

  return (
    <form className={classes.container} onSubmit={formik.handleSubmit}>
      <div className={classes.oneLine}>
        <InputLabel htmlFor="addressLine1" className={classes.label}>
          <Typography variant="subtitle2">{t('account:addressDetails.addressForm.addressLine1')}*</Typography>
        </InputLabel>
        <TextField
          id="addressLine1"
          name="addressLine1"
          className={classes.field}
          value={formik.values.addressLine1}
          placeholder={t('account:addressDetails.addressForm.addressLine1')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
          helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
          variant="outlined"
        />
      </div>
      <div className={classes.oneLine}>
        <InputLabel htmlFor="addressLine2" className={classes.label}>
          <Typography variant="subtitle2">{t('account:addressDetails.addressForm.addressLine2')}</Typography>
        </InputLabel>
        <TextField
          id="addressLine2"
          name="addressLine2"
          className={classes.field}
          value={formik.values.addressLine2}
          placeholder={t('account:addressDetails.addressForm.addressLine2')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
          helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
          variant="outlined"
        />
      </div>
      <div className={classes.oneLine}>
        <InputLabel htmlFor="addressLine3" className={classes.label}>
          <Typography variant="subtitle2">{t('account:addressDetails.addressForm.addressLine3')}</Typography>
        </InputLabel>
        <TextField
          id="addressLine3"
          name="addressLine3"
          value={formik.values.addressLine3}
          className={classes.field}
          placeholder={t('account:addressDetails.addressForm.addressLine3')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.addressLine3 && Boolean(formik.errors.addressLine3)}
          helperText={formik.touched.addressLine3 && formik.errors.addressLine3}
          variant="outlined"
        />
      </div>
      <div className={classes.multiInputLine}>
        <div>
          <InputLabel htmlFor="town" className={classes.label}>
            <Typography variant="subtitle2">{t('account:addressDetails.addressForm.town')}*</Typography>
          </InputLabel>
          <TextField
            id="town"
            name="town"
            className={classes.field}
            value={formik.values.town}
            placeholder={t('account:addressDetails.addressForm.town')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.town && Boolean(formik.errors.town)}
            helperText={formik.touched.town && formik.errors.town}
            variant="outlined"
          />
        </div>
        <div>
          <InputLabel htmlFor="countyState" className={classes.label}>
            <Typography variant="subtitle2">
              {formik.values.country.label === 'United Kingdom'
                ? t('account:addressDetails.addressForm.county')
                : t('account:addressDetails.addressForm.state')}
            </Typography>
          </InputLabel>
          <TextField
            id="countyState"
            name="countyState"
            className={classes.field}
            value={formik.values.countyState}
            placeholder={
              formik.values.country.label === 'United Kingdom'
                ? t('account:addressDetails.addressForm.county')
                : t('account:addressDetails.addressForm.state')
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.countyState && Boolean(formik.errors.countyState)}
            helperText={formik.touched.countyState && formik.errors.countyState}
            variant="outlined"
          />
        </div>
        <div>
          <InputLabel htmlFor="postZipCode" className={classes.label}>
            <Typography variant="subtitle2">
              {formik.values.country.label === 'United Kingdom'
                ? t('account:addressDetails.addressForm.postcode')
                : t('account:addressDetails.addressForm.zipcode')}
              *
            </Typography>
          </InputLabel>
          <TextField
            id="postZipCode"
            name="postZipCode"
            className={classes.field}
            value={formik.values.postZipCode}
            placeholder={
              formik.values.country.label === 'United Kingdom'
                ? t('account:addressDetails.addressForm.postcode')
                : t('account:addressDetails.addressForm.zipcode')
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.postZipCode && Boolean(formik.errors.postZipCode)}
            helperText={formik.touched.postZipCode && formik.errors.postZipCode}
            variant="outlined"
          />
        </div>
      </div>
      <div className={classes.oneLine}>
        <InputLabel htmlFor="country" className={classes.label}>
          <Typography variant="subtitle2">{t('account:addressDetails.addressForm.country')}*</Typography>
        </InputLabel>
        {addressType === 'Billing' ? (
          <Select
            inputClass={classes.select}
            options={billingCountrySelectOptions}
            setOption={(option) =>
              formik.setFieldValue(
                'country',
                billingCountrySelectOptions.find((c) => c.value === option),
              )
            }
            optionId={formik.values.country.value}
          />
        ) : (
          <Select
            inputClass={classes.select}
            options={deliveryCountrySelectOptions}
            setOption={(option) =>
              formik.setFieldValue(
                'country',
                deliveryCountrySelectOptions.find((c) => c.value === option),
              )
            }
            optionId={formik.values.country.value}
          />
        )}
      </div>
      <Button
        className={classes.submitButton}
        type="submit"
        variant="contained"
        color="primary"
        disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
      >
        {addressId ? t('common:editAddress') : t('common:addAddress')}
      </Button>
    </form>
  );
}
