import { graphql } from '../../__generated__';

export const ORDER_SPREAD_SUBSCRIPTION = graphql(`
  subscription GetOrderByAssetId($assetId: Int!) {
    getOrderByAssetId(assetId: $assetId) {
      asset {
        spread {
          highestBid {
            price
          }
          lowestOffer {
            price
          }
          percentageDifference
        }
      }
    }
  }
`);
