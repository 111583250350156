import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Pencil } from '../../assets/images/pencil.svg';
import { formatter } from '../../utils/currencyFormatter';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import Divider from '../Divider';
import IconButton from '../IconButton';
import Typography from '../Typography';
import { selectExpirationLabel } from './selectors';
import { useDisplayName } from './useDisplayName';
import { Mode, TradeVariant } from './types';
import { OrderData } from '../OrderForm/types';
import { useUnitSize } from '../../hooks';
import { logError } from '../../utils/logger';

const useStyles = makeStyles((theme) => ({
  fontSize20px: {
    fontSize: '20px',
  },
  fontSize16px: {
    fontSize: '16px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  modalContainer: {
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  capitalise: {
    textTransform: 'capitalize',
  },
  button: {
    width: '60%',
    alignSelf: 'center',
  },
  summaryContainer: {
    gap: theme.spacing(3),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  iconContainer: {
    width: 34,
    height: 34,
  },
  detailRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  width100: {
    width: '100%',
  },
  bold: {
    fontWeight: 700,
  },
  checkboxContainer: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: theme.spacing(3),
  },
  checkbox: {
    color: theme.palette.dividers.secondary,
    marginRight: '6px',
  },
}));

export type OrderSummaryPropsBase = {
  onEditDetails: () => void;
  onClose: () => void;
  onSubmit: () => void;
  assetId: number;
  orderData: OrderData;
  tradeType: TradeVariant;
  mode: Mode;
  disableClose?: boolean;
  disableSubmit?: boolean;
  showFees?: boolean;
};

export default function Summary(props: OrderSummaryPropsBase): JSX.Element {
  const {
    onEditDetails,
    onSubmit,
    onClose,
    assetId,
    tradeType,
    mode,
    orderData,
    disableClose = false,
    disableSubmit = false,
    showFees = true,
  } = props;
  const {
    expirationDate,
    price: unitPrice,
    quantity,
    totalPricePlusFee,
    totalUnitPrice,
    transactionFee,
    vat,
  } = orderData;
  const { t } = useTranslation();
  const classes = useStyles();
  const [disclaimerChecked, setDisclaimerChecked] = React.useState(false);
  const { displayName, loading: productLoading, error: loadDisplayNameError } = useDisplayName({ assetId });
  const { unitSize, error: loadUnitSizeError } = useUnitSize({ assetId });

  if (loadDisplayNameError) {
    logError({
      error: new Error('Error encountered when loading display name'),
      originalError: loadDisplayNameError,
      filename: 'OrderModal/Summary',
      additionalInfo: {
        assetId,
        mode,
        tradeType,
      },
    });
  }

  if (loadUnitSizeError) {
    logError({
      error: new Error('Error encountered when loading unit size'),
      originalError: loadUnitSizeError,
      filename: 'OrderModal/Summary',
      additionalInfo: {
        assetId,
        mode,
        tradeType,
      },
    });
  }

  const shouldDisableSubmit = disableSubmit || (mode === 'edit' && !disclaimerChecked);

  return (
    <div className={classes.modalContainer}>
      <DialogTitle
        classes={{ title: classes.fontSize16px }}
        id="dialog-title"
        onClose={onClose}
        disableClose={disableClose}
      >
        {t(`product:detailsModal.${tradeType}.${mode}.title` as const)}
      </DialogTitle>
      <DialogContentBorderTopOnly className={classes.modalContent} dividers>
        <div className={classes.titleContainer}>
          {!productLoading ? (
            <Typography className={classes.fontSize20px} variant="h2">
              {displayName}
            </Typography>
          ) : (
            <Typography aria-label="display-name-skeleton" className={classes.fontSize20px}>
              <Skeleton />
            </Typography>
          )}
          <IconButton variant="filled" className={classes.iconContainer} onClick={onEditDetails} size="large">
            <Pencil />
          </IconButton>
        </div>
        <div className={classes.summaryContainer}>
          <div className={classes.detailRow}>
            <Typography className={clsx(classes.fontSize16px, classes.bold)}>{t('product:unitSize')}</Typography>
            <Typography customVariant="body1">{unitSize?.label}</Typography>
          </div>
          <div className={classes.detailRow}>
            <Typography className={clsx(classes.fontSize16px, classes.bold)}>
              {t('product:detailsModal.quantity')}
            </Typography>
            <Typography customVariant="body1">{quantity}</Typography>
          </div>
          <div className={classes.detailRow}>
            <Typography className={clsx(classes.fontSize16px, classes.bold)}>
              {t('product:detailsModal.expiration')}
            </Typography>
            <Typography customVariant="body1">{selectExpirationLabel(expirationDate)}</Typography>
          </div>
          <Divider className={classes.width100} />
          <div className={classes.detailRow}>
            <Typography className={clsx(classes.fontSize16px, classes.bold, classes.capitalise)}>
              {t(`product:detailsModal.${tradeType}.costBreakdown.unitPrice` as const)}
            </Typography>
            <Typography customVariant="body1">{formatter.format(unitPrice)}</Typography>
          </div>
          <div className={classes.detailRow}>
            <Typography className={clsx(classes.fontSize16px, classes.bold, classes.capitalise)}>
              {t(`product:detailsModal.${tradeType}.costBreakdown.totalUnitPrice` as const)}
            </Typography>
            <Typography customVariant="body1">{formatter.format(totalUnitPrice)}</Typography>
          </div>
          {showFees && (
            <>
              <div className={classes.detailRow}>
                <Typography className={clsx(classes.fontSize16px, classes.bold)}>
                  {t('product:detailsModal.buyNow.transactionFee', { fee: 2.5 })}
                </Typography>
                <Typography customVariant="body1">{formatter.format(transactionFee ?? 0)}</Typography>
              </div>
              {!isUndefined(vat) && (
                <div className={classes.detailRow}>
                  <Typography className={clsx(classes.fontSize16px, classes.bold)}>VAT (20%)</Typography>
                  <Typography customVariant="body1">{formatter.format(vat)}</Typography>
                </div>
              )}
            </>
          )}
          <Divider className={classes.width100} />
          <div className={classes.detailRow}>
            <Typography className={clsx(classes.fontSize16px, classes.bold)}>
              {t(`product:detailsModal.${tradeType}.costBreakdown.totalCost` as const)}
            </Typography>
            <Typography customVariant="body1">{formatter.format(totalPricePlusFee)}</Typography>
          </div>
        </div>
        {mode === 'edit' && (
          <FormControlLabel
            className={classes.checkboxContainer}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={disclaimerChecked}
                onChange={(_, v) => setDisclaimerChecked(v)}
                color="primary"
              />
            }
            label={t(`trade:${tradeType}.editDisclaimer` as const)}
          />
        )}
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="large"
          disabled={shouldDisableSubmit}
          onClick={onSubmit}
        >
          {t('product:detailsModal.confirm')}
        </Button>
      </DialogContentBorderTopOnly>
    </div>
  );
}
