import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles(() => ({
  placeholder: {
    height: '750px',
    width: '100%',
  },
}));

export default function ColumnSkeleton(): JSX.Element | null {
  const classes = useStyles();

  return <Skeleton className={classes.placeholder} variant="rectangular" />;
}
