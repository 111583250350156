import React, { useState } from 'react';
import clsx from 'clsx';
import { Backdrop, Button, Popover, PopoverOrigin } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { useQuery, useReactiveVar } from '@apollo/client';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import IconButton from '../IconButton';
import { useAddWatchListItem } from '../../hooks/useAddWatchListItem';
import { useDeleteWatchListItem } from '../../hooks/useDeleteWatchListItem';
import { logError } from '../../utils/logger';
import { graphql } from '../../__generated__';
import { isLoggedInVar } from '../../graphql/cache';

const TRANSITION_DURATION = 333;
const ANCHOR_ORIGIN: PopoverOrigin = { horizontal: 'center', vertical: 'top' };
const TRANSFORM_ORIGIN: PopoverOrigin = { horizontal: 'center', vertical: 'top' };

const useStyles = makeStyles((theme) => ({
  inWatchlist: {
    color: theme.palette.textSecondary,
    backgroundColor: theme.palette.textPrimary,

    '&:hover': {
      // TODO: this colour is a placeholder, we need to find out
      // what colour the dark blue buttons should get on hover, however
      // as of right now I just don't have time to chase that up. I am
      // setting this here to prevent the material ui default hover colour
      // which is a really light grey that looks bad on these dark blue buttons.
      backgroundColor: theme.palette.textPrimary,
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  backdrop: {
    /**
     * This ensures that the backdrop sits over the top
     * of any images and charts that are on the screen,
     * which by default the backdrop did not cover.
     */
    zIndex: theme.zIndex.drawer + 1,
  },
  popover: {
    /**
     * Make the popover background disappear and
     * blend in, which makes it like the buttons
     * are 'floating', as per mockups.
     */
    background: 'none',
    boxShadow: 'none',
    width: '128px',
  },
  largeButton: {
    padding: '12px 0px',
  },
  smallButton: {
    padding: '6px 0px',
  },
  condensed: {
    margin: 0,
    width: 36,
    height: 36,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.button.primary.background.hover,
    },
  },
  iconButton: {
    minWidth: 0,
  },
  smallIconButton: {
    padding: '6px',
  },
  largeIconButton: {
    padding: '12px',
  },
  largeAnchorButton: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: theme.spacing(16),
    minWidth: 160,
  },
  smallAnchorButton: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: theme.spacing(2),
    minWidth: 160,
  },
  anchorButtonInner: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  label: {
    gap: 'inherit',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  tradeArrows: {
    width: '24px',
    height: '24px',
    stroke: theme.palette.primary.contrastText,
  },
  downArrow: {
    width: '11px',
    height: '7px',
  },
  iconButtonRoot: {
    '&:disabled': {
      backgroundColor: theme.palette.button.primary.background.disabled,
    },
  },
}));

export const WATCHLIST = graphql(`
  query Watchlist {
    watchListItems {
      asset {
        id
      }
    }
  }
`);

interface WatchlistButtonProps {
  assetId: number;
  onAdd: (assetId: number) => void;
  onRemove: (assetId: number) => void;
  onError: (errors: readonly GraphQLError[]) => void;
}

export default function WatchlistButton({ onAdd, onRemove, onError, assetId }: WatchlistButtonProps): JSX.Element {
  const classes = useStyles();
  // Instead of querying for each asset individually, just get the full watchlist so
  // that we can benefit from apollo cache (e.g. only needs 1 network request regardless
  // of how many buttons we render.)
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const history = useHistory();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'fancy-button-select' : undefined;
  const { data: watchlistResponse, loading: loadingWatchlist, error: failedToLoadWatchlist } = useQuery(WATCHLIST);
  const { addToWatchlist, loading: addingToWatchlist } = useAddWatchListItem();
  const { removeFromWatchlist, loading: removingFromWatchlist } = useDeleteWatchListItem();

  const isOnWatchlist = watchlistResponse?.watchListItems.some((item) => item.asset.id === assetId);
  const [optimisticIsOnWatchlist, setOptimisticIsOnWatchlist] = React.useState(isOnWatchlist);

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (isOnWatchlist && !optimisticIsOnWatchlist) {
      setOptimisticIsOnWatchlist(true);
    }

    if (!isOnWatchlist && optimisticIsOnWatchlist) {
      setOptimisticIsOnWatchlist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnWatchlist]);

  async function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (!isLoggedIn) {
      setAnchorEl(event.currentTarget);
      return;
    }

    if (optimisticIsOnWatchlist) {
      setOptimisticIsOnWatchlist(false);
      const { errors } = await removeFromWatchlist({ assetId });
      if (errors) {
        setOptimisticIsOnWatchlist(true);
        logError({
          error: new Error('Failed to remove from watchlist'),
          originalError: errors[0],
          filename: 'WatchlistButton',
          additionalInfo: {
            assetId,
            errors: JSON.stringify(errors),
          },
        });
        onError(errors);
      } else {
        onRemove(assetId);
      }
    } else {
      setOptimisticIsOnWatchlist(true);
      const { errors } = await addToWatchlist({ assetId });
      if (errors) {
        setOptimisticIsOnWatchlist(false);
        logError({
          error: new Error('Failed to add to watchlist'),
          originalError: errors[0],
          filename: 'WatchlistButton',
          additionalInfo: {
            assetId,
            errors: JSON.stringify(errors),
          },
        });
        onError(errors);
      } else {
        onAdd(assetId);
      }
    }
  }

  const Content = (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={ANCHOR_ORIGIN}
      transformOrigin={TRANSFORM_ORIGIN}
      PaperProps={{ className: classes.popover }}
      transitionDuration={TRANSITION_DURATION}
    >
      <div className={classes.container}>
        <Button
          color="primary"
          variant="contained"
          className={`${classes.iconButton} ${classes.smallIconButton}`}
          onClick={handleClose}
        >
          <Clear />
        </Button>
        {!isLoggedIn && (
          <Button
            color="primary"
            variant="contained"
            className={classes.smallButton}
            onClick={() => {
              history.push('/login');
            }}
            fullWidth
          >
            {t('common:login')}
          </Button>
        )}
      </div>
    </Popover>
  );

  return (
    <>
      <IconButton
        aria-label="watchlist"
        onClick={handleClick}
        className={clsx({ [classes.inWatchlist]: optimisticIsOnWatchlist })}
        variant="filled"
        disabled={
          (loadingWatchlist || addingToWatchlist || removingFromWatchlist || Boolean(failedToLoadWatchlist)) &&
          isLoggedIn
        }
        size="large"
      >
        <VisibilityOutlined />
      </IconButton>

      <Backdrop className={classes.backdrop} open={open}>
        {Content}
      </Backdrop>
    </>
  );
}
