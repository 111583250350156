import { ApolloError, useQuery } from '@apollo/client';
import { graphql } from '../__generated__';
import { HistoricalMarketValueChangeQuery, HistoricalMarketValueChangeQueryVariables } from '../__generated__/graphql';

const HISTORICAL_MARKET_VALUE_CHANGE = graphql(`
  query HistoricalMarketValueChange($assetIds: [Int!]!, $dateFrom: String!, $dateTo: String!) {
    historicalMarketValueChange(assetIds: $assetIds, dateFrom: $dateFrom, dateTo: $dateTo) {
      wineSearcher
      assetId
    }
  }
`);

type UseHistoricalMarketValueChangeDataProps = {
  variables: HistoricalMarketValueChangeQueryVariables;
  skip?: boolean;
};

interface UseHistoricalMarketValueChangeDataReturnType {
  data?: HistoricalMarketValueChangeQuery;
  error?: ApolloError;
  loading: boolean;
}

export default function useHistoricalMarketValueChangeData(
  { variables, skip }: UseHistoricalMarketValueChangeDataProps,
  isLoggedIn = false,
): UseHistoricalMarketValueChangeDataReturnType {
  const { data, error, loading } = useQuery(HISTORICAL_MARKET_VALUE_CHANGE, {
    variables,
    skip,
    ...(isLoggedIn
      ? {}
      : {
          context: {
            serviceName: 'insecure',
          },
        }), // Conditionally add serviceName
  });

  return {
    data,
    error,
    loading,
  };
}
