import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import TableSkeleton from './TableSkeleton';

interface OmsOrder {
  isOwner: boolean;
  price: number;
  quantity: number;
}

interface RowProps {
  data: OmsOrder;
}

interface TableProps {
  data: OmsOrder[] | null;
  loading: boolean;
}

const useTableStyles = makeStyles((theme) => ({
  table: {
    height: 'fit-content',
    width: 190,
    textAlign: 'center',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderCollapse: 'collapse',
    '& tr': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}));

const useRowStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: theme.palette.grey[100],
  },
}));

function Row({ data }: RowProps): JSX.Element {
  const classes = useRowStyles();

  if (!data) {
    return <tr />;
  }
  const rowClassName = data.isOwner ? classes.row : '';

  return (
    <tr className={rowClassName}>
      <td>{formatterWholeNumber.format(data.price)}</td>
      <td>{data.quantity}</td>
    </tr>
  );
}

export default function OffersTable({ data, loading }: TableProps): JSX.Element {
  const classes = useTableStyles();
  const { t } = useTranslation();
  if (loading) {
    return <TableSkeleton />;
  }

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th>{t('product:detailsModal.offerPrice')}</th>
          <th>{t('product:detailsModal.quantity')}</th>
        </tr>
      </thead>
      <tbody>
        {/* eslint-disable-next-line react/no-array-index-key */}
        {data && data.length ? data.map((offer, i) => <Row key={`orderbook-row-${i}`} data={offer} />) : null}
      </tbody>
    </table>
  );
}
