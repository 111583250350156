import { createTheme, adaptV4Theme } from '@mui/material/styles';
import palette from './palette';
import { sharedOptions, sharedOverrides } from '../shared';

const lightTheme = createTheme(
  adaptV4Theme({
    palette,
    ...sharedOptions,
  }),
);

/**
 * See this SO post for an explanation on
 * why we are doing it this way ->
 * https://stackoverflow.com/questions/57630926/material-ui-theme-overrides-leveraging-theme-palette-colors
 *
 * In essence, we want to use the theme palette
 * colours in the overrides, but we need to create
 * the theme first. It is not enough to use
 * `lightThemePalette`, as it is a `Partial<PaletteOptions>`,
 * and Typescript complains a lot when we try to access properties on it.
 */
lightTheme.components = {
  ...sharedOverrides,

  MuiTableCell: {
    styleOverrides: {
      ...sharedOverrides.MuiTableCell?.styleOverrides,
      head: {
        ...(sharedOverrides.MuiTableCell?.styleOverrides?.head as Record<string, unknown>),
        color: lightTheme.palette.textLabel,
        background: lightTheme.palette.grey[50],
        borderTop: `1px solid ${lightTheme.palette.grey[100]}`,
      },
    },
  },

  MuiTabs: {
    styleOverrides: {
      ...sharedOverrides.MuiTabs?.styleOverrides,
      root: {
        ...(sharedOverrides.MuiTabs?.styleOverrides?.root as Record<string, unknown>),
        borderBottom: `1px solid ${lightTheme.palette.grey[100]}`,
      },
      indicator: {
        ...(sharedOverrides.MuiTabs?.styleOverrides?.indicator as Record<string, unknown>),
        backgroundColor: lightTheme.palette.text.primary,
      },
    },
  },

  MuiTab: {
    styleOverrides: {
      ...sharedOverrides.MuiTab?.styleOverrides,
      root: {
        ...lightTheme.typography.label1,
        color: lightTheme.palette.textPrimary,
        '&.Mui-selected': {
          ...lightTheme.typography.label1Active,
          borderBottom: '4px',
          color: lightTheme.palette.textPrimary,
        },
      },
    },
  },

  MuiInputBase: {
    styleOverrides: {
      ...sharedOverrides.MuiInputBase?.styleOverrides,
      root: {
        ...(sharedOverrides.MuiInputBase?.styleOverrides?.root as Record<string, unknown>),
        // color: lightTheme.palette.textPrimary,
      },
    },
  },

  MuiTextField: {
    styleOverrides: {
      ...sharedOverrides.MuiTextField?.styleOverrides,
      root: {
        background: lightTheme.palette.textSecondary,
      },
    },
  },

  MuiOutlinedInput: {
    styleOverrides: {
      ...sharedOverrides.MuiOutlinedInput?.styleOverrides,
      notchedOutline: {
        borderColor: lightTheme.palette.grey[100],
      },
      root: {
        color: lightTheme.palette.textPrimary,
        ...(sharedOverrides.MuiOutlinedInput?.styleOverrides?.root as Record<string, unknown>),
        '&:hover fieldset': {
          borderColor: `${lightTheme.palette.textField.hover} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${lightTheme.palette.textField.active} !important`,
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 0,
        color: lightTheme.palette.grey[900],
      },
      colorPrimary: {
        '&.Mui-checked': {
          color: lightTheme.palette.textPrimary,
        },

        '&.Mui-disabled': {
          color: lightTheme.palette.grey[100],
        },
      },
      colorSecondary: {
        '&.Mui-checked': {
          color: lightTheme.palette.textSecondary,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      ...sharedOverrides.MuiButton?.styleOverrides,

      containedPrimary: {
        '&:hover': {
          backgroundColor: lightTheme.palette.button.primary.background.hover,
        },
      },

      contained: {
        ...(sharedOverrides.MuiButton?.styleOverrides?.contained as Record<string, unknown>),
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none',
        },

        '&:disabled': {
          backgroundColor: lightTheme.palette.grey[200],
          color: lightTheme.palette.textSecondary,
        },
      },
      containedSecondary: {
        ...(sharedOverrides.MuiButton?.styleOverrides?.containedSecondary as Record<string, unknown>),
        border: `1px solid ${lightTheme.palette.text.primary}`,
        backgroundColor: lightTheme.palette.grey[50],

        '&:hover': {
          backgroundColor: lightTheme.palette.background.default,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: lightTheme.palette.select.menuItem.hover,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      ...sharedOverrides.MuiInputLabel?.styleOverrides,
      root: {
        ...(sharedOverrides.MuiInputLabel?.styleOverrides?.root as Record<string, unknown>),
        color: lightTheme.palette.text.primary,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation4: {
        boxShadow: 'none',
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        color: lightTheme.palette.textLabel,
      },
      selectRoot: {
        textDecoration: 'underline',
        fontSize: lightTheme.typography.label1Active.fontSize,
        fontWeight: lightTheme.typography.label1Active.fontWeight,
      },
      actions: {
        color: lightTheme.palette.textPrimary,
      },
    },
  },
};

export { lightTheme };
