import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '250px',
  },
  menuItem: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    margin: `${theme.spacing(0.5)} 0`,
  },
  button: {
    borderRadius: 24,
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.text.primary}`,
    textAlign: 'center',
    justifyContent: 'center',
  },
}));
