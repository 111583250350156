import { gql } from '@apollo/client';

/**
 * Global application state
 * that we want available to
 * query via GraphQL.
 */
export const typeDefs = gql(`
  extend type Query {
    isLoggedIn: Boolean!
    appIsWorking: Boolean!
  }
`);
