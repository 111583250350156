import React from 'react';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { useTranslation } from 'react-i18next';
import PortfolioAttribute from '../PortfolioAttribute';

function selectRealisedGains(data: number | undefined): number | null {
  if (isUndefined(data)) {
    return null;
  }

  return data;
}

export default function RealisedGainsComponent({
  loading,
  simpleReturn,
}: {
  simpleReturn: number | undefined;
  loading: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const value = selectRealisedGains(simpleReturn);
  return (
    <PortfolioAttribute
      label={t('portfolio:attributes.realisedGains.label')}
      loading={loading}
      value={value}
      tooltipText={t('portfolio:attributes.realisedGains.tooltip')}
    />
  );
}
