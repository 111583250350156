/* eslint-disable no-nested-ternary */
import { SortDirection } from '@cultwines/zellar-client-sdk';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { GraphQLError } from 'graphql';
import TableCellHead from '../TableElements/TableCellHead';
import RowSkeleton from '../TableElements/RowSkeleton';
import ErrorPlaceholder from '../ErrorPlaceholder';
import EmptyPlaceholder from '../EmptyPlaceholder';
import { WineVintage } from './types';
import { SearchWinesFacets } from '../../views/Search/selectors';
import SearchResultsTableRow, { COLUMN_COUNT } from './Row';

interface TableProps {
  wineVintages: WineVintage[];
  loading?: boolean;
  error?: string;
  perPage?: number;
  sortDirection: SortDirection | null;
  sortColumn: keyof SearchWinesFacets | null;
  onSortChanged: (key: keyof SearchWinesFacets) => void;
  onAddToWatchlist: () => void;
  onRemoveFromWatchlist: () => void;
  onWatchlistError: (errors: readonly GraphQLError[]) => void;
  onBuyNowClicked: (assetId: string) => void;
  onBidClicked: (assetId: string) => void;
  onOfferClicked: (assetId: string) => void;
}

export default function SearchResultsTable({
  wineVintages,
  loading,
  error,
  perPage,
  sortColumn,
  sortDirection,
  onAddToWatchlist,
  onRemoveFromWatchlist,
  onWatchlistError,
  onSortChanged,
  onBuyNowClicked,
  onBidClicked,
  onOfferClicked,
}: TableProps) {
  const { t } = useTranslation();
  const { showPerformaceDataInSearchResults } = useFlags();

  return (
    <TableContainer>
      <Table aria-label="search results table">
        <TableHead>
          <TableRow>
            <TableCellHead hideSortButton />
            <TableCellHead
              sortActive={sortColumn === 'WineName'}
              sortDirection={sortColumn === 'WineName' ? sortDirection! : undefined}
              onSortButtonClicked={() => onSortChanged('WineName')}
              title={t('market:table.wineName')}
            />
            <TableCellHead
              sortActive={sortColumn === 'Vintage'}
              sortDirection={sortColumn === 'Vintage' ? sortDirection! : undefined}
              onSortButtonClicked={() => onSortChanged('Vintage')}
              title={t('common:vintage')}
            />
            <TableCellHead
              sortActive={sortColumn === 'Score'}
              sortDirection={sortColumn === 'Score' ? sortDirection! : undefined}
              onSortButtonClicked={() => onSortChanged('Score')}
              title={t('product:score')}
            />
            <TableCellHead title={t('common:unitSize')} hideSortButton />
            <TableCellHead
              sortActive={sortColumn === 'MarketValue'}
              sortDirection={sortColumn === 'MarketValue' ? sortDirection! : undefined}
              onSortButtonClicked={() => onSortChanged('MarketValue')}
              title={t('product:marketValue')}
              tooltip={t('market:marketValueCalculation12x75cl')}
            />
            {showPerformaceDataInSearchResults && (
              <TableCellHead
                sortActive={sortColumn === 'PercentageDifference'}
                sortDirection={sortColumn === 'PercentageDifference' ? sortDirection! : undefined}
                onSortButtonClicked={() => onSortChanged('PercentageDifference')}
                title={t('market:performanceLTM')}
              />
            )}
            <TableCellHead title={t('product:trading.highestBid')} hideSortButton />
            <TableCellHead title={t('product:trading.lowestOffer')} hideSortButton />
            <TableCellHead title={t('product:trading.spread')} hideSortButton />
            <TableCellHead title={t('common:actions')} hideSortButton />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <RowSkeleton columnCount={COLUMN_COUNT} rowCount={perPage} />
          ) : error ? (
            <TableCell align="center" colSpan={COLUMN_COUNT}>
              <ErrorPlaceholder error={error} />
            </TableCell>
          ) : !wineVintages.length ? (
            <TableCell align="center" colSpan={COLUMN_COUNT}>
              <EmptyPlaceholder label={t('search:noSearchResults')} />
            </TableCell>
          ) : (
            wineVintages.map((wineVintage, index) => (
              <SearchResultsTableRow
                key={wineVintage.defaultAssetId + index}
                wineVintage={wineVintage}
                onAddToWatchlist={onAddToWatchlist}
                onRemoveFromWatchlist={onRemoveFromWatchlist}
                onWatchlistError={onWatchlistError}
                onBuyNowClicked={onBuyNowClicked}
                onBidClicked={onBidClicked}
                onOfferClicked={onOfferClicked}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
