import { graphql } from '../../__generated__';

export const PRODUCT_DATA_WATCHLIST_QUERY = graphql(`
  query ProductAssetWatchListByAssetId($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      vintages {
        id
        vintage
        assets {
          id
          unitSize
          unitCount
        }
      }
      vintage {
        id
        vintage
        wine {
          id
          name
          seoFriendlyName
        }
        imageFileName
      }
      marketValue
    }
  }
`);
