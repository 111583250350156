interface AllRegionMapType {
  region: string;
  allocatedRegion: string;
}
export const allRegionMap: AllRegionMapType[] = [
  { region: 'Bordeaux', allocatedRegion: 'Bordeaux' },
  { region: 'Burgundy', allocatedRegion: 'Burgundy' },
  { region: 'Champagne', allocatedRegion: 'Champagne' },
  { region: 'Abruzzo', allocatedRegion: 'Italy' },
  { region: 'Basilicata', allocatedRegion: 'Italy' },
  { region: 'Campania', allocatedRegion: 'Italy' },
  { region: 'Italy', allocatedRegion: 'Italy' },
  { region: 'Lombardia', allocatedRegion: 'Italy' },
  { region: 'Piedmont', allocatedRegion: 'Italy' },
  { region: 'Sicily', allocatedRegion: 'Italy' },
  { region: 'Sardinia', allocatedRegion: 'Italy' },
  { region: 'Trentino Alto Adige', allocatedRegion: 'Italy' },
  { region: 'Tuscany', allocatedRegion: 'Italy' },
  { region: 'Umbria', allocatedRegion: 'Italy' },
  { region: 'Veneto', allocatedRegion: 'Italy' },
  { region: 'Vigneti delle Dolomiti', allocatedRegion: 'Italy' },
  { region: 'Aconcagua', allocatedRegion: 'Rest of World' },
  { region: 'Alsace', allocatedRegion: 'Rest of World' },
  { region: 'Andalucia', allocatedRegion: 'Rest of World' },
  { region: 'Auckland', allocatedRegion: 'Rest of World' },
  { region: 'Baden', allocatedRegion: 'Rest of World' },
  { region: 'Beaujolais', allocatedRegion: 'Rest of World' },
  { region: 'Bekaa Valley', allocatedRegion: 'Rest of World' },
  { region: 'Burgenland', allocatedRegion: 'Rest of World' },
  { region: 'Canterbury', allocatedRegion: 'Rest of World' },
  { region: 'Cape South Coast', allocatedRegion: 'Rest of World' },
  { region: 'Castilla y Leon', allocatedRegion: 'Rest of World' },
  { region: 'Catalunya', allocatedRegion: 'Rest of World' },
  { region: 'Central Otago', allocatedRegion: 'Rest of World' },
  { region: 'Central Valley', allocatedRegion: 'Rest of World' },
  { region: 'Coastal Region', allocatedRegion: 'Rest of World' },
  { region: 'Cognac', allocatedRegion: 'Rest of World' },
  { region: 'Douro', allocatedRegion: 'Rest of World' },
  { region: 'England', allocatedRegion: 'Rest of World' },
  { region: 'France', allocatedRegion: 'Rest of World' },
  { region: 'Franken', allocatedRegion: 'Rest of World' },
  { region: 'Galicia', allocatedRegion: 'Rest of World' },
  { region: 'Graubunden', allocatedRegion: 'Rest of World' },
  { region: "Hawke's Bay", allocatedRegion: 'Rest of World' },
  { region: 'Jura', allocatedRegion: 'Rest of World' },
  { region: 'Languedoc', allocatedRegion: 'Rest of World' },
  { region: 'Loire', allocatedRegion: 'Rest of World' },
  { region: 'Mendoza', allocatedRegion: 'Rest of World' },
  { region: 'Mosel', allocatedRegion: 'Rest of World' },
  { region: 'Nahe', allocatedRegion: 'Rest of World' },
  { region: 'New South Wales', allocatedRegion: 'Rest of World' },
  { region: 'Niederosterreich', allocatedRegion: 'Rest of World' },
  { region: 'Olifants River', allocatedRegion: 'Rest of World' },
  { region: 'Patagonia', allocatedRegion: 'Rest of World' },
  { region: 'Pfalz', allocatedRegion: 'Rest of World' },
  { region: 'Porto', allocatedRegion: 'Rest of World' },
  { region: 'Provence', allocatedRegion: 'Rest of World' },
  { region: 'Rheingau', allocatedRegion: 'Rest of World' },
  { region: 'Rheinhessen', allocatedRegion: 'Rest of World' },
  { region: 'Rioja', allocatedRegion: 'Rest of World' },
  { region: 'Roussillon', allocatedRegion: 'Rest of World' },
  { region: 'Scotland', allocatedRegion: 'Rest of World' },
  { region: 'South Australia', allocatedRegion: 'Rest of World' },
  {
    region: 'South Eastern Australia',
    allocatedRegion: 'Rest of World',
  },
  { region: 'Tasmania', allocatedRegion: 'Rest of World' },
  { region: 'Tokaj', allocatedRegion: 'Rest of World' },
  { region: 'Vaucluse', allocatedRegion: 'Rest of World' },
  { region: 'Victoria', allocatedRegion: 'Rest of World' },
  { region: 'Western Australia', allocatedRegion: 'Rest of World' },
  { region: 'Rhone', allocatedRegion: 'Rhone' },
  { region: 'California', allocatedRegion: 'USA' },
  { region: 'Oregon', allocatedRegion: 'USA' },
];
