import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';
import WineConfirmSuccess from '../../assets/images/wine-confirm-success.png';
import { DialogContent } from '../Dialog/DialogContent';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  iconContainer: {
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  label: {
    marginBottom: theme.spacing(8),
  },
  button: {
    minWidth: 200,
    alignSelf: 'center',
  },
}));

interface Props {
  onClose: () => void;
}

export default function BuyNowSuccessContent({ onClose }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  function handleGoToPortfolioClicked(): void {
    history.push('/portfolio');
  }

  return (
    <div className={classes.modalContainer}>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {' '}
      </DialogTitle>
      <DialogContent className={classes.modalContainer}>
        <div className={classes.iconContainer}>
          <img src={WineConfirmSuccess} height="212" alt="success" />
        </div>
        <Typography className={classes.title} variant="h2">
          {t('product:detailsModal.buyNow.success.title')}
        </Typography>
        <Typography className={classes.label} variant="body1">
          <div>{t('product:detailsModal.buyNow.success.message1')}</div>
          <div>{t('product:detailsModal.buyNow.success.message2')}</div>
        </Typography>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleGoToPortfolioClicked}
        >
          {t('product:detailsModal.buyNow.success.button')}
        </Button>
      </DialogContent>
    </div>
  );
}
