import { graphql } from '../../__generated__';

export const ASSETS_BY_VINTAGE_ID = graphql(`
  query AssetsByVintageId($wineVintageId: Int!) {
    productWineVintage(wineVintageId: $wineVintageId) {
      id
      assets {
        id
        unitSize
        unitCount
      }
    }
  }
`);
