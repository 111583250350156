import { Namespace, Resources, TFunction } from 'react-i18next';
import * as yup from 'yup';
import { TradeVariant } from '../../OrderModal/types';

export const getValidationSchema = (
  t: TFunction<Namespace<keyof Resources>>,
  tradeType: TradeVariant,
  maximumCasesAvailableForOffer: number,
) => {
  return yup.object({
    price: yup
      .string()
      .test('val', t('product:detailsModal.priceError'), (val) => /^[1-9]\d*$/.test(`${val}`))
      .required(t('product:detailsModal.priceError')),
    quantity: yup
      .string()
      .test('val', t('product:detailsModal.quantityError'), (val) => /^[1-9]\d*$/.test(`${val}`))
      .test('val', t('product:detailsModal.maxQuantityExceededError'), (val) => {
        if (tradeType === 'bid') {
          return true;
        }

        return Number(val) <= maximumCasesAvailableForOffer;
      })
      .required(t('product:detailsModal.quantityError')),
    expirationDate: yup.number().required(),
  });
};
