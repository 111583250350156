import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { AddressType } from '@cultwines/zellar-client-sdk';
import { ReactComponent as PinIcon } from '../../assets/icons/pin.svg';
import { ReactComponent as EditPencilIcon } from '../../assets/icons/edit-pencil.svg';
import IconButton from '../IconButton';
import Typography from '../Typography';
import { selectCountry } from '../AddressModal/selectors';
import { Address } from '../../__generated__/graphql';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.textField.active}`,
    borderRadius: '4px',
    background: theme.palette.grey[50],
    padding: theme.spacing(3),
    gap: theme.spacing(3),
  },
  icon: {
    stroke: theme.palette.textField.active,
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  details: {
    flexDirection: 'column',
  },
  footer: {
    marginTop: theme.spacing(2),
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}));

interface AddressSummaryProps {
  address: Address;
  name: string;
  handleEditAddressClicked: () => void;
  className?: string;
}

export default function AddressSummary({
  address,
  name,
  handleEditAddressClicked,
  className = '',
}: AddressSummaryProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.section}>
        <PinIcon />
        {selectCountry(address, address.type as AddressType) ? (
          <IconButton onClick={handleEditAddressClicked} size="large">
            <EditPencilIcon />
          </IconButton>
        ) : (
          <Typography zellarColour="textFailure">Invalid Address</Typography>
        )}
      </div>
      <div className={`${classes.section} ${classes.details}`}>
        <div>
          <Typography customVariant="body1" zellarColour="textLabel" data-private>
            {address.addressLine1}
          </Typography>
          <Typography customVariant="body1" zellarColour="textLabel" data-private>
            {address.county ? address.county : address.town} {address.postcode}
          </Typography>
        </div>
        <div className={`${classes.section} ${classes.footer}`}>
          <Typography customVariant="caption" zellarColour="textInformation" className={classes.uppercase} data-private>
            {name}
          </Typography>
          <Typography customVariant="caption" zellarColour="textInformation" className={classes.uppercase} data-private>
            {t('common:default')}
          </Typography>
        </div>
      </div>
    </div>
  );
}
