import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

const useStyles = makeStyles(() => ({
  rect: {
    height: 126,
    width: 190,
  },
}));

export default function TradingCardSkeleton(): JSX.Element {
  const classes = useStyles();
  return <Skeleton variant="rectangular" className={classes.rect} />;
}
