import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '../Typography';
import { ReactComponent as ManualBankTransferIcon } from '../../assets/icons/manual-bank-transfer.svg';

const useStyles = makeStyles((theme) => {
  return {
    amountLabel: {
      marginBottom: theme.spacing(3),
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    paymentOptionContainer: {
      borderRadius: '4px',
      border: '1px solid white',
      background: theme.palette.grey[50],
      padding: theme.spacing(3),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    active: {
      border: `1px solid ${theme.palette.textHighlight}`,
    },
    icon: {
      stroke: theme.palette.textField.active,
    },
    section: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    column: {
      flexDirection: 'column',
    },
    flexStart: {
      alignItems: 'flex-start',
    },
    right: {
      marginLeft: 'auto',
    },
    flex: {
      display: 'flex',
    },
    gap3: {
      gap: theme.spacing(3),
    },
    flexEnd: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    confirmButton: {
      alignSelf: 'center',
    },
  };
});

interface ManualBankTransferProps {
  onBankTransferSelected: () => void;
  amount: number;
}
export default function ManualBankTransfer({ onBankTransferSelected, amount }: ManualBankTransferProps): JSX.Element {
  const [checked, setChecked] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  function handleChange() {
    setChecked(!checked);
  }

  useMemo(() => {
    setValid(checked && amount > 0);
  }, [amount, checked]);

  return (
    <div
      className={clsx({
        [classes.paymentOptionContainer]: true,
        [classes.flex]: true,
        [classes.gap3]: true,
        [classes.column]: true,
        [classes.active]: checked,
      })}
    >
      <div className={clsx({ [classes.row]: true, [classes.flexStart]: true, [classes.gap3]: true })}>
        <ManualBankTransferIcon />
        <div>
          <Typography className={classes.amountLabel} customVariant="subtitle1">
            {t('wallet:paymentMethodModal.manualBankTransfer.label')}
          </Typography>
          <Typography className={`${classes.amountLabel}`} customVariant="subtitle3" zellarColour="textLabel">
            {t('wallet:paymentMethodModal.manualBankTransfer.description')}
          </Typography>
        </div>
        <input
          type="radio"
          value="bankTransfer"
          aria-label="choose-manual-bank-transfer"
          checked={checked}
          onClick={handleChange}
        />
      </div>
      <Button
        className={clsx({ [classes.confirmButton]: true, [classes.right]: true })}
        variant="contained"
        color="primary"
        aria-label="confirm-manual-bank-transfer"
        size="large"
        disabled={!valid}
        onClick={onBankTransferSelected}
      >
        {t('product:detailsModal.confirm')}
      </Button>
    </div>
  );
}
