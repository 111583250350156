import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { RegionName } from '../../../types/RegionName';
import mapRegionName from '../../../utils/mapRegionName';

const useStyles = makeStyles<Theme, { region: RegionName }>((theme) => ({
  imageContainer: {
    background: (props) => theme.palette.regions[props.region],
    width: '86px',
    height: '85px',
    borderRadius: '100%',
    overflow: 'visible',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 40px',
    margin: '20px',
  },
  image: {
    height: '125px',
  },
}));

export default function WineImage({ region, imageUrl }: { region: string; imageUrl: string }): JSX.Element {
  const classes = useStyles({ region: mapRegionName(region) });

  return (
    <div className={classes.imageContainer}>
      <img className={classes.image} src={imageUrl} alt="wine" />
    </div>
  );
}
