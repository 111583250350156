import { graphql } from '../../__generated__';

export const GET_MARKET_DATA_QUERY = graphql(`
  query MarketDataByAssetId($assetId: Int!, $dateFrom: String, $dateTo: String, $includeWineSearcher: Boolean!) {
    productAsset(assetId: $assetId) {
      marketData(dateFrom: $dateFrom, dateTo: $dateTo) {
        performance {
          valueDifference
          percentageDifference
        }
        wineSearcher @include(if: $includeWineSearcher)
      }
    }
  }
`);
