import { ApolloCache, Reference, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { CANCEL_ORDER } from '../graphql';
import { CancelOrderMutation, CancelOrderMutationVariables } from '../__generated__/graphql';

interface MutationResponse {
  data: CancelOrderMutation | null | undefined;
  errors: readonly GraphQLError[] | undefined;
}

export type CancelOrderFn = (params: CancelOrderMutationVariables) => Promise<MutationResponse>;

interface ReturnProps {
  cancelOrder: CancelOrderFn;
  loading: boolean;
}

export const useCancelOrder = (options = {}): ReturnProps => {
  const [cancelOrderMutation, { loading }] = useMutation(CANCEL_ORDER, {
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });
  const cancelOrder = useCallback(
    async (variables) => {
      const { data, errors } = await cancelOrderMutation({
        variables,
        update: (cache: ApolloCache<CancelOrderMutation>) => {
          cache.modify({
            fields: {
              omsGetMyOrders(orders, { readField }) {
                const ordersCopy = [...orders];
                const index = ordersCopy.findIndex((item: Reference) => readField('id', item) === variables.orderId);
                const mutatedOrder = { ...ordersCopy[index], cancelled: true };
                ordersCopy.splice(index, 1, mutatedOrder);
                return ordersCopy;
              },
            },
          });
        },
        ...options,
      });

      return { data, errors };
    },
    [cancelOrderMutation, options],
  );
  return { cancelOrder, loading };
};
