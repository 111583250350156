import { graphql } from '../../__generated__';

export const WITHDRAW_CONFIRMATION = graphql(`
  mutation WithdrawConfirmation($input: WithdrawConfirmationInput!) {
    withdrawConfirmation(input: $input) {
      accountName
      sortCode
      accountNumber
      iban
      bic
      amount
    }
  }
`);
