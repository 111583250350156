import { AddressType } from '@cultwines/zellar-client-sdk';
import { useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ModalStep } from '../Dialog/ContentSwitcher';
import DialogContentSwitcherUnstyled from '../Dialog/ContentSwitcherUnstyled';
import AddressFormContent from './AddressFormContent';

interface AddressModalProps {
  addressType: AddressType;
  open: boolean;
  onClose: () => void;
}

export default function AddressModal({ addressType, open, onClose }: AddressModalProps): JSX.Element {
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [modalStep, setModalStep] = React.useState<ModalStep>(ModalStep.Initial);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  function handleClose(): void {
    setModalStep(ModalStep.Initial);
    onClose();
  }

  function handleEditFailure() {
    handleClose();
    enqueueSnackbar(t('account:addressDetails.editFailure'), {
      variant: 'error',
    });
  }

  function handleEditSuccess() {
    handleClose();
    enqueueSnackbar(t('account:addressDetails.editSuccess'), {
      variant: 'success',
    });
  }

  function handleCreateFailure() {
    handleClose();
    enqueueSnackbar(t('account:addressDetails.createFailure'), {
      variant: 'error',
    });
  }

  function handleCreateSuccess() {
    handleClose();
    enqueueSnackbar(t('account:addressDetails.createSuccess'), {
      variant: 'success',
    });
  }

  return (
    // needs to be maxWidth="md" to suit other values set in ModalContentSwitcher and modalStyles()
    <Dialog open={open} onClose={onClose} maxWidth="md" fullScreen={lessThanMd}>
      <DialogContentSwitcherUnstyled
        modalStep={modalStep}
        InitialComponent={
          <AddressFormContent
            addressType={addressType}
            onClose={handleClose}
            onEditFailure={handleEditFailure}
            onEditSuccess={handleEditSuccess}
            onCreateSuccess={handleCreateSuccess}
            onCreateFailure={handleCreateFailure}
          />
        }
      />
    </Dialog>
  );
}
