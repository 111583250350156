import { FinancialOperationMonthWiseFlow } from '../../__generated__/graphql';

type DataPoint = (string | number)[];

interface MonthwiseFlowChartData {
  inward: Array<DataPoint>;
  outward: Array<DataPoint>;
}

export function selectMonthwiseChartData(
  data: FinancialOperationMonthWiseFlow | undefined | null,
): MonthwiseFlowChartData {
  if (!data || !data.monthlyExpenses) {
    return {
      inward: [],
      outward: [],
    };
  }
  const result = data.monthlyExpenses.reduce(
    (acc, cur) => {
      const date = new Date(cur.monthDate).toISOString().slice(0, 7);
      acc.inward.push([date, cur.inward]);
      acc.outward.push([date, cur.outward]);
      return acc;
    },
    { inward: [], outward: [] } as MonthwiseFlowChartData,
  );
  return result;
}
