/* eslint-disable react/destructuring-assignment */
import React from 'react';
import WineCard from '..';
import { ExternalPortfolioItem } from '../../../graphql/selectors/externalPortfolioItems';
import ExternalItemAssetInstances from './ExternalItemAssetInstances';
import { Actions } from '../../Table/types';
import { useWineCardStyles } from '../styles';
import mapRegionName from '../../../utils/mapRegionName';

interface SearchWineVintageCardProps {
  externalPortfolioItem: ExternalPortfolioItem;
  className?: string;
  actions?: Actions;
}

export default function ExternalPortfolioCard({
  actions,
  className = '',
  externalPortfolioItem,
}: SearchWineVintageCardProps): JSX.Element {
  const {
    id,
    name,
    vintage,
    drinkingWindow,
    units,
    unitSize,
    unitCount,
    score,
    region,
    totalMarketValue,
    totalPurchasePrice,
    performancePercentageDelta,
    highestBid,
    lowestOffer,
    imageFilename,
  } = externalPortfolioItem;
  const classes = useWineCardStyles({ region: mapRegionName(region) });

  return (
    <WineCard
      className={className}
      cardId={String(id)}
      assetId={id}
      wineName={name}
      vintage={vintage}
      drinkingWindow={drinkingWindow}
      units={units}
      unitSize={unitSize}
      unitCount={unitCount}
      score={score}
      totalMarketValue={totalMarketValue}
      performancePercentageDelta={{ value: performancePercentageDelta, display1YRLabel: false }}
      totalPurchasePrice={totalPurchasePrice}
      highestBid={highestBid}
      lowestOffer={lowestOffer}
      actions={actions}
      region={region}
      imageUrl={imageFilename}
      tradeTileVariant="unit-size"
      ExpandedContent={
        <ExternalItemAssetInstances
          assetId={id}
          AssetInstanceProps={{ className: `${classes.inner} ${classes.borderTop}` }}
        />
      }
    />
  );
}
