export function isOlderThan(dateString: string | undefined, expectedAge: number): boolean {
  if (!dateString || !expectedAge) {
    return false;
  }
  const today = new Date();
  // Covert US date format to GB
  const date = new Date(
    Date.UTC(Number(dateString.split('/')[2]), Number(dateString.split('/')[1]) - 1, Number(dateString.split('/')[0])),
  ).toLocaleDateString('en-GB');

  const birthDateDay = Number(date.split('/')[0]);
  const birthDateMonth = Number(date.split('/')[1]);
  const birthDateYear = Number(date.split('/')[2]);

  const currentDay = today.getDate();
  const currentYear = today.getFullYear();
  const currentMonth = Number(today.getMonth().toLocaleString('en-GB').replace(',', '')) + 1;
  // Birthday year is less than 18
  if (currentYear - birthDateYear < expectedAge) {
    return false;
  }
  // Birthday year was 18 years ago, but birthday month is after current month
  if (currentYear - birthDateYear === expectedAge && birthDateMonth - currentMonth > 0) {
    return false;
  }
  // Birthday year was 18 years ago,
  // birthday month is previous current month
  // but birthday day is after current day
  if (
    currentYear - birthDateYear === expectedAge &&
    birthDateMonth - currentMonth === 0 &&
    currentDay - birthDateDay < 0
  ) {
    return false;
  }

  return true;
}
