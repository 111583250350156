import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getISO3Country } from '@cultwines/zellar-client-sdk/utils/getISO3Country';
import { Country } from '@cultwines/zellar-client-sdk/types/Country';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { billingCountrySelectOptions, COUNTRIES } from '@cultwines/zellar-client-sdk';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import DialogTitle from '../Dialog/DialogTitle';
import { DialogContent } from '../Dialog/DialogContent';
import { useStyles } from './styles';
import TextField from '../TextField';
import Select from '../Select';
import { KYC_INITIATE } from '../../graphql/mutations/kycInitiate';
import Divider from '../Divider';
import { KycInitiateModel } from './types';
import { KycInitiateInput } from '../../__generated__/graphql';

interface AddressFormProps {
  initialValues: KycInitiateModel;
  onClose: () => void;
  onSuccess: () => void;
  onFailure: () => void;
}

export default function AddressModal({ initialValues, onClose, onSuccess, onFailure }: AddressFormProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const kycInitiateSchema = yup.object({
    firstName: yup.string().required(),
    middleName: yup.string(),
    lastName: yup.string().required(),
    dob: yup.string().required(),
    addressLine1: yup.string().required(t('common:kyc.address.addressLine1')),
    addressLine2: yup.string(),
    addressLine3: yup.string(),
    town: yup.string(),
    county: yup.string().required(t('common:kyc.address.county')),
    postcode: yup.string().required(t('common:kyc.address.postcode')),
    country: yup
      .object({
        value: yup
          .number()
          .oneOf(COUNTRIES.map((_, i) => i))
          .required(),
        label: yup
          .string()
          .oneOf([...COUNTRIES])
          .required(),
      })
      .required(t('common:kyc.address.country')),
  });
  const [postKycInitiate] = useMutation(KYC_INITIATE, {
    onError: () => null,
  });

  async function handleSubmit(kycInitiate: KycInitiateInput) {
    const { errors } = await postKycInitiate({
      variables: {
        input: kycInitiate,
      },
    });
    if (errors) {
      onFailure();
    } else {
      onSuccess();
    }
  }
  const formik = useFormik<yup.InferType<typeof kycInitiateSchema>>({
    initialValues,
    onSubmit: (args) => {
      handleSubmit({
        firstName: args.firstName,
        middleName: args.middleName,
        lastName: args.lastName,
        dob: args.dob,
        addressLine1: args.addressLine1 ?? '',
        addressLine2: args.addressLine2,
        addressLine3: args.addressLine3,
        town: args.town ?? '',
        postcode: args.postcode ?? '',
        county: args.county ?? '',
        country: getISO3Country(args.country.label as Country),
      });
    },
    validationSchema: kycInitiateSchema,
  });

  return (
    <div className={classes.modalContainer}>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {t('common:kyc.address.title')}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <form className={classes.container} onSubmit={formik.handleSubmit}>
          <div className={classes.oneLine}>
            <InputLabel htmlFor="addressLine1" className={classes.label}>
              <Typography variant="subtitle2">{t('common:kyc.address.addressLine1')} *</Typography>
            </InputLabel>
            <TextField
              id="addressLine1"
              name="addressLine1"
              className={classes.field}
              value={formik.values.addressLine1}
              placeholder={t('common:kyc.address.addressLine1')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
              helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
              variant="outlined"
            />
          </div>
          <div className={classes.oneLine}>
            <InputLabel htmlFor="addressLine2" className={classes.label}>
              <Typography variant="subtitle2">{t('common:kyc.address.addressLine2')}</Typography>
            </InputLabel>
            <TextField
              id="addressLine2"
              name="addressLine2"
              className={classes.field}
              value={formik.values.addressLine2}
              placeholder={t('common:kyc.address.addressLine2')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
              helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
              variant="outlined"
            />
          </div>
          <div className={classes.multiInputLine}>
            <div className={classes.addressLine3}>
              <InputLabel htmlFor="addressLine3" className={classes.label}>
                <Typography variant="subtitle2">{t('common:kyc.address.addressLine3')}</Typography>
              </InputLabel>
              <TextField
                id="addressLine3"
                name="addressLine3"
                value={formik.values.addressLine3}
                className={classes.field}
                placeholder={t('common:kyc.address.addressLine3')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.addressLine3 && Boolean(formik.errors.addressLine3)}
                helperText={formik.touched.addressLine3 && formik.errors.addressLine3}
                variant="outlined"
              />
            </div>
            <div>
              <InputLabel htmlFor="town" className={classes.label}>
                <Typography variant="subtitle2">{t('common:kyc.address.town')}</Typography>
              </InputLabel>
              <TextField
                id="town"
                name="town"
                className={classes.field}
                value={formik.values.town}
                placeholder={t('common:kyc.address.town')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.town && Boolean(formik.errors.town)}
                helperText={formik.touched.town && formik.errors.town}
                variant="outlined"
              />
            </div>
          </div>
          <div className={classes.multiInputLine}>
            <div>
              <InputLabel htmlFor="county" className={classes.label}>
                <Typography variant="subtitle2">{t('common:kyc.address.county')} *</Typography>
              </InputLabel>
              <TextField
                id="county"
                name="county"
                className={classes.field}
                value={formik.values.county}
                placeholder={t('common:kyc.address.county')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.county && Boolean(formik.errors.county)}
                helperText={formik.touched.county && formik.errors.county}
                variant="outlined"
              />
            </div>
            <div>
              <InputLabel htmlFor="postcode" className={classes.label}>
                <Typography variant="subtitle2">{t('common:kyc.address.postcode')} *</Typography>
              </InputLabel>
              <TextField
                id="postcode"
                name="postcode"
                className={classes.field}
                value={formik.values.postcode}
                placeholder={t('common:kyc.address.postcode')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.postcode && Boolean(formik.errors.postcode)}
                helperText={formik.touched.postcode && formik.errors.postcode}
                variant="outlined"
              />
            </div>
            <div>
              <InputLabel htmlFor="country" className={classes.label}>
                <Typography variant="subtitle2">{t('common:kyc.address.country')} *</Typography>
              </InputLabel>
              <Select
                inputClass={classes.select}
                options={billingCountrySelectOptions}
                setOption={(option) =>
                  formik.setFieldValue(
                    'country',
                    billingCountrySelectOptions.find((c) => c.value === option),
                  )
                }
                optionId={formik.values.country.value}
              />
            </div>
          </div>
          <Typography className={classes.description}>{t('common:kyc.address.description')}</Typography>
          <Button
            className={clsx({ [classes.confirmButton]: true, [classes.right]: true })}
            variant="contained"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          >
            {t('common:kyc.address.confirm')}
          </Button>
        </form>
      </DialogContent>
    </div>
  );
}
