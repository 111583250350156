import React, { useState } from 'react';
import Box from '@mui/material/Box';
import LoginForm from '../../../components/LoginForm';
import OTPForm from '../../../components/OTPForm';
import { useStyles } from '../styles';
import Footer from '../../../components/Footer';

export default function LoginComp(): JSX.Element {
  const classes = useStyles();
  const [mfaOOBCode, setMFAOOBCode] = useState<string | null>(null);
  const [mfaToken, setMFAToken] = useState<string | null>(null);

  function handleMFARequested({
    mfaOOBCode: _mfaOOBCode,
    mfaToken: _mfaToken,
  }: {
    mfaToken: string;
    mfaOOBCode: string;
    emailAddress: string;
  }): void {
    setMFAOOBCode(_mfaOOBCode);
    setMFAToken(_mfaToken);
  }

  return (
    <div className={classes.wrapper}>
      <Box className={classes.formBox}>
        {mfaToken && mfaOOBCode ? (
          <OTPForm mfaToken={mfaToken} mfaOOBCode={mfaOOBCode} />
        ) : (
          <LoginForm onMFARequested={handleMFARequested} />
        )}
      </Box>
      <Footer />
    </div>
  );
}
