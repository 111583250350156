/* eslint-disable no-nested-ternary */
import { useSubscription } from '@apollo/client';
import { useMediaQuery, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CurrentHoldings from '../../components/CurrentHoldings';
import ExternalPortfolioTable from '../../components/ExternalPortfolio';
import PortfolioHeadings from '../../components/PortfolioHeadings';
import PortfolioValueLineChart from '../../components/PortfolioValueLineChart';
import TabPanel from '../../components/TabPanel';
import { cache } from '../../graphql/cache';
import { MY_TRADES_SUBSCRIPTION } from '../../graphql/subscriptions/getMyTrades';
import { useQueryParameters } from '../../hooks';
import { logError } from '../../utils/logger';
import { usePortfolioViewStyles } from './styles';

export enum PortfolioTabs {
  CultXPortfolio = 0,
  ExternalPortfolio = 1,
}

export default function Portfolio(): JSX.Element {
  const { getMyTradesSubscription, showPortfolioChart } = useFlags();
  const { data: subscriptionData, error: subscriptionError } = useSubscription(MY_TRADES_SUBSCRIPTION, {
    skip: !getMyTradesSubscription,
  });
  const query = useQueryParameters();
  const history = useHistory();
  const classes = usePortfolioViewStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = React.useState(Number(query.get('tab')) || PortfolioTabs.CultXPortfolio);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    history.push(`/portfolio?tab=${newValue}`);
    setValue(newValue);
  };

  /**
   * I found myself needing to set the noSsr flag to true here as a workaround
   * to an issue which they claim to have fixed. If you remove this flag and log out
   * the greaterThanXl boolean when you're on a screen width greater than xl, then navigate
   * to the portfolio page. Upon inspecting the logs you'll see it goes from
   * false -> true -> false, which cause the AssetList to render momentarily before
   * switching back to the table. As I said, mui developers closed the issue related to this,
   * but it is clearly still broken -> https://github.com/mui-org/material-ui/issues/21142
   */
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md), { noSsr: true });
  useEffect(() => {
    if (subscriptionError) {
      logError({
        error: new Error('MY_TRADES_SUBSCRIPTION failed'),
        originalError: subscriptionError,
        filename: 'src/views/Portfolio/index.tsx',
      });
    }
  }, [subscriptionError]);

  useEffect(() => {
    if (subscriptionData && getMyTradesSubscription) {
      cache.evict({ fieldName: 'omsGetMyTrades' });
      cache.evict({ fieldName: 'holdingsCurrent' });
      cache.evict({ fieldName: 'productAssetInstances' });
    }
  }, [subscriptionData, getMyTradesSubscription]);

  return (
    <div ref={containerRef}>
      <PortfolioHeadings selectedTabIndex={value} />
      <Tabs value={value} onChange={handleChange} variant={lessThanMd ? 'fullWidth' : 'standard'}>
        <Tab label={t('portfolio:cultxPortfolio')} />
        <Tab label={t('portfolio:externalPortfolio.title')} />
      </Tabs>
      <TabPanel value={value} index={PortfolioTabs.CultXPortfolio} className={classes.tabPanel}>
        {!!showPortfolioChart && <PortfolioValueLineChart hasFirstTrade />}
        <CurrentHoldings />
      </TabPanel>
      <TabPanel value={value} index={PortfolioTabs.ExternalPortfolio} className={classes.tabPanel}>
        <ExternalPortfolioTable />
      </TabPanel>
    </div>
  );
}
