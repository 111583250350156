import { Namespace, Resources, TFunction } from 'react-i18next';
import { ExternalPortfolioItemFacets } from '../../graphql/selectors/externalPortfolioItems';
import { uuid } from '../../utils/uuid';
import { SortOption } from '../Table/SortMenu';
import { SortDirection } from '../Table/types';

export function getSortOptions(
  sortDirection: SortDirection,
  activeSortKey: keyof ExternalPortfolioItemFacets,
  t: TFunction<Namespace<keyof Resources>>,
): SortOption<keyof ExternalPortfolioItemFacets>[] {
  const sortOptions: SortOption<keyof ExternalPortfolioItemFacets>[] = [
    {
      id: uuid(),
      direction: 'desc',
      key: 'score',
      active: activeSortKey === 'score' && sortDirection === 'desc',
      primaryLabel: t('search:filters.score'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'score',
      active: activeSortKey === 'score' && sortDirection === 'asc',
      primaryLabel: t('search:filters.score'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'vintage',
      active: activeSortKey === 'vintage' && sortDirection === 'desc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.newestToOldest'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'vintage',
      active: activeSortKey === 'vintage' && sortDirection === 'asc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.oldestToNewest'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'marketValue',
      active: activeSortKey === 'marketValue' && sortDirection === 'desc',
      primaryLabel: t('portfolio:table.headers.totalMarketValuation'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'marketValue',
      active: activeSortKey === 'marketValue' && sortDirection === 'asc',
      primaryLabel: t('portfolio:table.headers.totalMarketValuation'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'unitCount',
      active: activeSortKey === 'unitCount' && sortDirection === 'desc',
      primaryLabel: t('common:unitCount'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'unitCount',
      active: activeSortKey === 'unitCount' && sortDirection === 'asc',
      primaryLabel: t('common:unitCount'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'unitSize',
      active: activeSortKey === 'unitSize' && sortDirection === 'desc',
      primaryLabel: t('common:unitSize'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'unitSize',
      active: activeSortKey === 'unitSize' && sortDirection === 'asc',
      primaryLabel: t('common:unitSize'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'performancePercentageDelta',
      active: activeSortKey === 'performancePercentageDelta' && sortDirection === 'desc',
      primaryLabel: t('search:filters.performance'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'performancePercentageDelta',
      active: activeSortKey === 'performancePercentageDelta' && sortDirection === 'asc',
      primaryLabel: t('search:filters.performance'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'highestBid',
      active: activeSortKey === 'highestBid' && sortDirection === 'desc',
      primaryLabel: t('product:trading.highestBid'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'highestBid',
      active: activeSortKey === 'highestBid' && sortDirection === 'asc',
      primaryLabel: t('product:trading.highestBid'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'lowestOffer',
      active: activeSortKey === 'lowestOffer' && sortDirection === 'desc',
      primaryLabel: t('product:trading.lowestOffer'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'lowestOffer',
      active: activeSortKey === 'lowestOffer' && sortDirection === 'asc',
      primaryLabel: t('product:trading.lowestOffer'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
  ];

  return sortOptions;
}
