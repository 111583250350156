import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

export default function NotSupported(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Typography variant="h4">{t('account:deviceNotSupported')}</Typography>;
    </div>
  );
}
