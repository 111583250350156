/* eslint-disable react/jsx-no-duplicate-props */
import { Button, Grid, InputBaseComponentProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '../TextField';
import NumberFormatCustom from '../CurrencyTextField/NumberFormatCustom';
import { getWithdrawUKValidationSchema } from './helpers';
import { useStyles } from './styles';
import { WithdrawFundsModelType } from './types';

interface Props {
  disabled?: boolean;
  onSubmit: (values: WithdrawFundsModelType) => void;
}

export default function WithdrawUKForm({ disabled, onSubmit }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const validationSchema = getWithdrawUKValidationSchema(t);
  const formik = useFormik<yup.InferType<typeof validationSchema>>({
    initialValues: {
      accountName: '',
      sortCode: '',
      accountNumber: '',
    },
    onSubmit,
    validationSchema,
  });

  return (
    <form className={classes.formContainer} onSubmit={formik.handleSubmit}>
      <Grid container spacing={4}>
        <Grid justifyContent="center" item xs={6}>
          <TextField
            name="accountName"
            placeholder={`${t('account:paymentDetails.withdrawableForm.accountName')}*`}
            value={formik.values.accountName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.accountName && Boolean(formik.errors.accountName)}
            helperText={formik.touched.accountName && formik.errors.accountName}
            inputProps={{ 'aria-label': 'account-name' }}
          />
        </Grid>
        <Grid justifyContent="center" item xs={6}>
          <TextField
            name="sortCode"
            placeholder={`${t('account:paymentDetails.withdrawableForm.sortCode')}*`}
            value={formik.values.sortCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.sortCode && Boolean(formik.errors.sortCode)}
            helperText={formik.touched.sortCode && formik.errors.sortCode}
            InputProps={{
              inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>,
            }}
            inputProps={{
              'data-format': '##-##-##',
              'aria-label': 'sort-code',
            }}
          />
        </Grid>
        <Grid justifyContent="center" item xs={6}>
          <TextField
            name="accountNumber"
            placeholder={`${t('account:paymentDetails.withdrawableForm.accountNumber')}*`}
            value={formik.values.accountNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
            helperText={formik.touched.accountNumber && formik.errors.accountNumber}
            inputProps={{ 'aria-label': 'account-number' }}
          />
        </Grid>
      </Grid>
      <div className={classes.buttonsContainer}>
        <Button
          type="submit"
          disabled={disabled || formik.isSubmitting || !formik.isValid || !formik.dirty}
          className={classes.button}
          variant="contained"
          color="primary"
          size="large"
          aria-label="confirm"
        >
          {t('account:paymentDetails.withdrawableForm.confirmButton')}
        </Button>
      </div>
    </form>
  );
}
