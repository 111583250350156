import { graphql } from '../../__generated__';

export const UPDATE_MIT_CONSENT = graphql(`
  mutation UpdateMitConsent($updateMitConsentInput: UpdateMitConsentInput!) {
    updateMitConsent(updateMitConsentInput: $updateMitConsentInput) {
      status
      errorMessage
    }
  }
`);
