import { graphql } from '../../__generated__';

export const GET_ADDRESSES_QUERY = graphql(`
  query GetAddressesQuery {
    zellarClientAddresses {
      addressId
      type
      addressLine1
      addressLine2
      addressLine3
      town
      county
      country
      postcode
    }
  }
`);
