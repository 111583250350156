function treatAsUTC(date: Date) {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

export function getDaysDifferenceBetweenDates(date1: Date, date2: Date): string | null {
  if (!date1 || !date2 || Number.isNaN(date1) || Number.isNaN(date2)) {
    return null;
  }
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const float = (treatAsUTC(date1).getTime() - treatAsUTC(date2).getTime()) / millisecondsPerDay;
  return float.toFixed(0);
}
