import selectDrinkingWindowAdvice from '../../graphql/selectors/selectDrinkingWindowAdvice';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { humanReadableDate } from '../../utils/humanReadableDate';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { massageToNull } from '../../utils/massageToNull';
import { selectLiquidityScore } from '../../views/Search/selectors';
import { ColumnData } from './Column';
import { MultipleAssetsWithHoldingsQuery } from '../../__generated__/graphql';

const EMPTY_ARR = [] as ColumnData[];

export function selectNormalizedTableData(data: MultipleAssetsWithHoldingsQuery | undefined): ColumnData[] {
  if (!data) {
    return EMPTY_ARR;
  }
  const { productAssets } = data;
  if (!productAssets || !productAssets.length) {
    return EMPTY_ARR;
  }
  return productAssets.map(({ id, marketValue, spread, vintage, tradingInfo, calculatedMarketData }) => {
    const { lastTraded } = tradingInfo;

    return {
      marketValue: !isNullOrUndefined(marketValue) ? formatterWholeNumber.format(Number(marketValue.toFixed(2))) : null,
      performance:
        calculatedMarketData?.performance.percentageDifference !== undefined &&
        calculatedMarketData?.performance.percentageDifference !== null
          ? `${parseFloat(calculatedMarketData.performance.percentageDifference.toFixed(2))}%`
          : null,
      score: !isNullOrUndefined(calculatedMarketData?.combinedScore)
        ? `${calculatedMarketData?.combinedScore}/100`
        : '-/100',
      drinkingWindow: selectDrinkingWindowAdvice(vintage.drinkingWindow?.advice),
      highestBid: spread?.highestBid?.price ? formatterWholeNumber.format(spread?.highestBid?.price) : null,
      lowestOffer: spread?.lowestOffer?.price ? formatterWholeNumber.format(spread?.lowestOffer?.price) : null,
      lastTraded: lastTraded ? humanReadableDate(lastTraded) : null,
      liquidityScore: selectLiquidityScore(massageToNull(calculatedMarketData?.liquidityScore)),
      assetId: id,
    };
  });
}
