import { ApolloError, useQuery } from '@apollo/client';
import React from 'react';
import { Order } from './types';
import { graphql } from '../../__generated__';
import { BookedOrder, OrderFormQueryQuery } from '../../__generated__/graphql';

const ORDER_FORM_QUERY = graphql(`
  query OrderFormQuery($skipOrder: Boolean!) {
    availableBalance
    financeCurrentBalance {
      currentBalanceGbp
    }
    authUser {
      userId
      emailAddress
      firstName
      surname
      kycStatus
      accountType
      mitConsentedFlag
    }
    omsGetMyOrders @skip(if: $skipOrder) {
      id
      clientOrderId
      status
      asset {
        id
        unitSize
        unitCount
        spread {
          lowestOffer {
            userId
            price
          }
          highestBid {
            userId
            price
          }
        }
        vintage {
          id
          imageFileName
          calculatedMarketData {
            combinedScore
          }
          wine {
            id
            name
            region {
              name
            }
            appellation {
              name
            }
          }
          vintage
        }
      }
      executableWindow {
        endTime
      }
      price
      direction
      cancelled
      submittedTimeUtc
      outstandingQuantity
      quantity
    }
  }
`);

interface UseOrderFormProps {
  orderId?: string;
  skipOrder?: boolean;
}

export interface UseOrderFormResponse {
  tradingHeadroom: number;
  fundsOnAccount: number | null;
  userDetails: OrderFormQueryQuery['authUser'] | undefined;
  order: Order | null;
  loading: boolean;
  error: ApolloError | undefined;
}

function selectOrder(data: BookedOrder[] | undefined, orderId: string | undefined): Order | null {
  if (!data || !orderId) {
    return null;
  }

  const order = data.find((o) => o.id === orderId);
  if (!order) {
    return null;
  }

  return {
    clientOrderId: order.clientOrderId,
    expiryDate: order.executableWindow.endTime,
    originalQuantity: order.quantity,
    price: order.price,
    outstandingQuantity: order.outstandingQuantity,
  };
}

export const useOrderForm = ({ orderId, skipOrder = false }: UseOrderFormProps): UseOrderFormResponse => {
  const { data, loading, error } = useQuery(ORDER_FORM_QUERY, {
    variables: {
      skipOrder,
    },
  });
  const order = React.useMemo(() => selectOrder(data?.omsGetMyOrders as BookedOrder[], orderId), [data, orderId]);

  return {
    tradingHeadroom: data?.availableBalance ?? 0,
    fundsOnAccount: data?.financeCurrentBalance?.currentBalanceGbp ?? null,
    userDetails: data?.authUser,
    order,
    loading,
    error,
  };
};
