import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import ConfirmDeleteMit from './ConfirmDeleteMit';
import ManageCardForm from './ManageCardForm';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useStyles } from './styles';
import ConfirmDeleteCardDialog from '../PaymentCardModal/ConfirmDeleteCardDialog';
import ConfirmDeleteMitCard from './ConfirmDeleteMitCard';
import useUserDetails from '../../hooks/useUserDetails';
import { AccountType } from '../../types/AccountType';
import ConfirmDeleteMitCardWhaleAccount from './ConfirmDeleteMitCardWhaleAccount';
import { CardDetailResponse } from '../../__generated__/graphql';

interface ManageCardModalProps {
  data: CardDetailResponse;
  onClose: () => void;
}

export default function ManageCardModal({ data, onClose }: ManageCardModalProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { enqueueSnackbar } = useSnackbar();
  const [initialModalType, setInitialModalType] = useState<
    | 'manageCardForm'
    | 'confirmDeleteMit'
    | 'confirmDeleteMitCard'
    | 'confirmDeleteMitCardWhaleAccount'
    | 'confirmDeleteCard'
  >('manageCardForm');
  const { userDetails } = useUserDetails();

  function handleDefaultResetWarning() {
    enqueueSnackbar(t('wallet:manageCardModal.disableDefaultCard'), {
      variant: 'warning',
    });
  }

  function handleFailure() {
    onClose();
    enqueueSnackbar(t('common:somethingWentWrong'), {
      variant: 'error',
    });
  }

  function handleCancel() {
    setInitialModalType('manageCardForm');
  }

  function handleDeleteCard() {
    if (userDetails?.accountType === AccountType.Whale) {
      setInitialModalType('confirmDeleteMitCardWhaleAccount');
    } else {
      setInitialModalType(data.mitConsentedFlag ? 'confirmDeleteMitCard' : 'confirmDeleteCard');
    }
  }

  function handleSuccess() {
    onClose();
    enqueueSnackbar(t('common:success'), {
      variant: 'success',
    });
  }

  return (
    <Dialog maxWidth="lg" fullScreen={lessThanMd} open>
      <div className={classes.root}>
        {(() => {
          switch (initialModalType) {
            case 'manageCardForm':
              return (
                <ManageCardForm
                  selectedCard={data}
                  onClose={onClose}
                  onFailure={handleFailure}
                  onResetDefault={handleDefaultResetWarning}
                  onDeleteMit={() => setInitialModalType('confirmDeleteMit')}
                  onDeleteCard={handleDeleteCard}
                />
              );
            case 'confirmDeleteMit':
              return (
                <ConfirmDeleteMit
                  instrumentId={data.instrumentId}
                  onClose={handleCancel}
                  onFailure={handleFailure}
                  onSuccess={handleSuccess}
                />
              );
            case 'confirmDeleteMitCard':
              return (
                <ConfirmDeleteMitCard
                  instrumentId={data.instrumentId}
                  onClose={handleCancel}
                  onFailure={handleFailure}
                  onSuccess={handleSuccess}
                />
              );
            case 'confirmDeleteMitCardWhaleAccount':
              return (
                <ConfirmDeleteMitCardWhaleAccount
                  instrumentId={data.instrumentId}
                  onClose={handleCancel}
                  onFailure={handleFailure}
                  onSuccess={handleSuccess}
                />
              );
            case 'confirmDeleteCard':
              return (
                <ConfirmDeleteCardDialog
                  instrumentId={data.instrumentId}
                  handleClose={handleCancel}
                  handleDone={onClose}
                  open
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    </Dialog>
  );
}
