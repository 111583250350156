import { createTheme, adaptV4Theme } from '@mui/material/styles';
import palette from './palette';
import { sharedOptions, sharedOverrides } from '../shared';

const onboardingTheme = createTheme(
  adaptV4Theme({
    ...sharedOptions,
    palette,
  }),
);

/**
 * See this SO post for an explanation on
 * why we are doing it this way ->
 * https://stackoverflow.com/questions/57630926/material-ui-theme-overrides-leveraging-theme-palette-colors
 *
 * In essence, we want to use the theme palette
 * colours in the overrides, but we need to create
 * the theme first. It is not enough to use
 * `lightThemePalette`, as it is a `Partial<PaletteOptions>`,
 * and Typescript complains a lot when we try to access properties on it.
 */
onboardingTheme.components = {
  ...sharedOverrides,
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        color: `${onboardingTheme.palette.text.primary} !important`,
        backgroundColor: `${onboardingTheme.palette.background.default} !important`,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      ...sharedOverrides.MuiTextField?.styleOverrides,
      root: {
        ...(sharedOverrides.MuiTextField?.styleOverrides?.root as Record<string, unknown>),
        background: onboardingTheme.palette.dividers.primary,
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      ...sharedOverrides.MuiInput?.styleOverrides,
      root: {
        ...(sharedOverrides.MuiInput?.styleOverrides?.root as Record<string, unknown>),
        color: onboardingTheme.palette.textField.text,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      ...sharedOverrides.MuiOutlinedInput?.styleOverrides,
      root: {
        ...(sharedOverrides.MuiOutlinedInput?.styleOverrides?.root as Record<string, unknown>),
        color: onboardingTheme.palette.textField.text,
        '&:hover fieldset': {
          borderColor: `${onboardingTheme.palette.textField.hover} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${onboardingTheme.palette.textField.active} !important`,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      ...sharedOverrides.MuiButton?.styleOverrides,
      root: {
        ...(sharedOverrides.MuiButton?.styleOverrides?.root as Record<string, unknown>),
        backgroundColor: onboardingTheme.palette.button.primary.background.main,
        color: onboardingTheme.palette.button.primary.text.main,

        '&:hover': {
          backgroundColor: onboardingTheme.palette.button.primary.background.hover,
        },

        '&:disabled': {
          backgroundColor: onboardingTheme.palette.button.primary.background.disabled,
          color: onboardingTheme.palette.text.disabled,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      ...sharedOverrides.MuiMenuItem?.styleOverrides,
      root: {
        ...(sharedOverrides.MuiMenuItem?.styleOverrides?.root as Record<string, unknown>),
        color: onboardingTheme.palette.textField.text,
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      ...sharedOverrides.MuiAlert?.styleOverrides,
      standardError: {
        ...(sharedOverrides.MuiAlert?.styleOverrides?.standardError as Record<string, unknown>),
        backgroundColor: onboardingTheme.palette.error.main,
        color: onboardingTheme.palette.text.primary,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      ...sharedOverrides.MuiInputLabel?.styleOverrides,
      root: {
        ...(sharedOverrides.MuiInputLabel?.styleOverrides?.root as Record<string, unknown>),
        color: onboardingTheme.palette.text.primary,
      },
    },
  },
};

export { onboardingTheme };
