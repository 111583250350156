import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en-GB',
    fallbackLng: 'en',
    ns: [
      'common',
      'account',
      'auth',
      'product',
      'portfolio',
      'market',
      'wallet',
      'trade',
      'overview',
      'search',
      'notifications',
      'discover',
    ],
    defaultNS: 'common',
    keySeparator: '.',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
