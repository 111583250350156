import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },

  quantityLabel: {
    color: theme.palette.dividers.secondary,
  },

  placeholder: {
    width: '100%',
    height: '300px',
    background: theme.palette.grey[50],
    color: theme.palette.textInformation,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  header: {
    backgroundColor: theme.palette.grey[50],
    textTransform: 'uppercase',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },

  cellRoot: {
    border: 0,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: '20px 8px',
  },

  positiveValue: {
    color: theme.palette.success.main,
  },

  negativeValue: {
    color: theme.palette.error.main,
  },

  horizontalContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
  },

  skeletonTableCellRoot: {
    border: 0,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: 6,
  },

  lastCellRoot: {
    border: 0,
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: '20px 30px',
    width: 270,
  },

  tr: {
    padding: 10,
    textTransform: 'uppercase',
    fontSize: 12,
    color: theme.palette.dividers.secondary,
  },

  lastTr: {
    padding: '10px 35px',
    textTransform: 'uppercase',
    fontSize: 12,
    color: theme.palette.dividers.secondary,
  },

  uppercase: {
    textTransform: 'uppercase',
  },
}));
