/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import AddWineToCompareModal from '../../components/AddWineToCompareModal';
import TableTab from '../../components/MarketComparisonDetailsTable';
import Chart from '../../components/MarketComparisonLineChart';
import TabPanel from '../../components/TabPanel';
import useColourMap from '../../hooks/useColourMap';
import useMediaQuery from '../../hooks/useMediaQuery';
import { colours } from './constants';
import Controls from './Controls';
import CardPaymentErrorModal from '../../components/CardPaymentCallbackModal/errorContent';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';
import CardPaymentSuccessModal from '../../components/CardPaymentCallbackModal/successContent';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  horizontalContainer: {
    display: 'flex',
    width: '100%',
  },
  chartTab: {
    display: 'flex',
    flexDirection: 'column',
  },
  greyBackground: {
    background: theme.palette.grey[50],
  },
}));

export default function Market(): JSX.Element {
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const queryParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const assetIds = React.useMemo(() => queryParams.get('assets')?.split(',') ?? [], [queryParams]);
  const lessThanLg = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));
  const { update: updateColourMap, assetColours } = useColourMap(colours);
  const { showCarPaymentErrorModal, showCarPaymentSuccessModal } = useCardPaymentCallback();

  useEffect(() => {
    updateColourMap(assetIds);
  }, [assetIds]);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function handleNextClick() {
    setVisibleColumnIndex(visibleColumnIndex + 1);
  }

  function handleBackClick() {
    setVisibleColumnIndex(visibleColumnIndex - 1);
  }

  function handleClear() {
    setVisibleColumnIndex(1);
    history.push({
      pathname: '/market',
    });
  }

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  function closeCardPaymentErrorModal() {
    history.push({ pathname: location.pathname, search: location.search, hash: '' });
  }

  function closeCardPaymentSuccessModal() {
    history.push({ pathname: location.pathname, search: location.search, hash: '' });
  }

  function handleRemoveAsset(assetId: string): void {
    const updatedAssets = assetIds.filter((id) => id !== assetId);
    const search = updatedAssets.length > 0 ? `?assets=${updatedAssets.join(',')}` : undefined;
    history.push({
      pathname: '/market',
      search,
    });
  }

  function handleAssetAdded(assetId: string): void {
    const assets = [...assetIds, assetId];
    const stringified = assets.join(',');
    history.push({
      pathname: '/market',
      search: `?assets=${stringified}`,
    });
  }

  const carouselPagesCount = lessThanLg ? 4 : 2;
  const columnCount = lessThanLg ? 2 : 4;

  return (
    <div className={classes.container}>
      <Tabs value={value} indicatorColor="primary" onChange={handleChange}>
        <Tab label={t('common:overview')} />
        <Tab label={t('product:details')} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div className={classes.chartTab}>
          <Controls
            handleBackClick={handleBackClick}
            handleClear={handleClear}
            handleForwardClick={handleNextClick}
            page={visibleColumnIndex}
            pageCount={carouselPagesCount}
          />
          <Chart
            assetIds={assetIds}
            carouselPagesCount={carouselPagesCount}
            coloursByAssetId={assetColours}
            onAddClicked={openModal}
            onRemoveClicked={handleRemoveAsset}
            visibleColumnIndex={visibleColumnIndex}
            columnCount={columnCount}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.chartTab}>
          <Controls
            handleBackClick={handleBackClick}
            handleClear={handleClear}
            handleForwardClick={handleNextClick}
            page={visibleColumnIndex}
            pageCount={carouselPagesCount}
          />
          <TableTab
            assetIds={assetIds}
            carouselPagesCount={carouselPagesCount}
            coloursByAssetId={assetColours}
            columnCount={columnCount}
            onAddClicked={openModal}
            onRemoveClicked={handleRemoveAsset}
            visibleColumnIndex={visibleColumnIndex}
          />
        </div>
      </TabPanel>
      {modalOpen && (
        <AddWineToCompareModal
          onAssetAdded={handleAssetAdded}
          open={modalOpen}
          onClose={closeModal}
          selectedAssetIds={assetIds}
        />
      )}
      {showCarPaymentErrorModal && (
        <CardPaymentErrorModal open={showCarPaymentErrorModal} onClose={closeCardPaymentErrorModal} />
      )}
      {showCarPaymentSuccessModal && (
        <CardPaymentSuccessModal open={showCarPaymentSuccessModal} onClose={closeCardPaymentSuccessModal} />
      )}
    </div>
  );
}
