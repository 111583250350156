import i18n from '../i18n';

const HOUR = 60 * 60 * 1000;
const DAY = 24 * 60 * 60 * 1000;

export function selectTimeAgoLabel(dateTime: string): string {
  const date = new Date(dateTime).getTime();
  const currentDate = Date.now();
  const anHourAgo = currentDate - HOUR;
  const aDayAgo = currentDate - DAY;

  const diff = Math.abs(currentDate - date);

  if (date > anHourAgo) {
    const minutes = Math.floor(diff / 1000 / 60);
    const minAgoLabel = minutes === 1 ? i18n.t('notifications:minAgo') : i18n.t('notifications:minsAgo');
    return `${minutes} ${minAgoLabel}`;
  }

  if (date > aDayAgo) {
    const hours = Math.floor(diff / 1000 / 60 / 60);
    const hourAgoLabel = hours === 1 ? i18n.t('notifications:hourAgo') : i18n.t('notifications:hoursAgo');
    return `${hours} ${hourAgoLabel}`;
  }
  const days = Math.floor(diff / 1000 / 60 / 60 / 24);
  const dayAgoLabel = days === 1 ? i18n.t('notifications:dayAgo') : i18n.t('notifications:daysAgo');
  return `${days} ${dayAgoLabel}`;
}
