import React from 'react';
import { useTheme, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactComponent as CultXLock } from '../../../assets/icons/cultx-lock-v2.svg';
import { ReactComponent as CultXBadge } from '../../../assets/icons/cultx-badge-v2.svg';
import { ReactComponent as RedWineGlass } from '../../../assets/icons/cultx-red-wine-glass.svg';

import Typography from '../../Typography';
import { useStyles } from '../styles';

export default function QualityGuarantee(): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();

  const accordionStyle: SxProps<Theme> = {
    border: 1,
    borderRadius: 1,
    borderColor: theme.palette.grey[100],
    '&:before': {
      display: 'none',
    },
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
      <Typography variant="h3" className="tw-mb-1">
        {t('product:accordionSectionTitle')}
      </Typography>
      <Accordion disableGutters elevation={0} sx={accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <CultXLock style={{ marginTop: 2 }} />
          <Typography style={{ margin: 'auto' }} variant="subtitle2">
            {t('product:accordion.qa')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2"> {t('product:accordion.qaText')}</Typography>
          <Typography variant="body2">
            <a
              href="https://knowledge.cultx.com/where-is-the-wine-stored"
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              {t('product:accordion.qaLink')}
            </a>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters elevation={0} sx={accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <CultXBadge style={{ marginTop: 2 }} />
          <Typography style={{ margin: 'auto' }} variant="subtitle2">
            {t('product:accordion.guarantee')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2"> {t('product:accordion.guaranteeText')}</Typography>
          <Typography variant="body2">
            <a
              href="https://knowledge.cultx.com/how-do-we-ensure-authenticity"
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              {t('product:accordion.guaranteeLink')}
            </a>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters elevation={0} sx={accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <RedWineGlass style={{ marginTop: 2 }} />
          <Typography style={{ margin: 'auto' }} variant="subtitle2">
            {t('product:accordion.delivery')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2"> {t('product:accordion.deliveryText')}</Typography>
          <Typography variant="body2">
            <a href="https://www.cultx.com/delivery" className={classes.link} target="_blank" rel="noreferrer">
              {t('product:accordion.deliveryLink')}
            </a>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
