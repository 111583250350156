import { graphql } from '../../__generated__';

export const TRANSFER_OUT_SUBSCRIPTION = graphql(`
  subscription GetMyTransferOutRequest {
    getMyTransferOutRequest {
      userId
      assetUnitIds
      isSuccess
    }
  }
`);
