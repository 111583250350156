import { ApolloCache, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { ADD_ASSET_TO_WATCHLIST } from '../graphql';
import { AddAssetToWatchListMutation, AddAssetToWatchListMutationVariables } from '../__generated__/graphql';

interface MutationResponse {
  data: AddAssetToWatchListMutation | null | undefined;

  /**
   * Despite the typescript typings for this, it can actually be an object which contains
   * information on graphql errors and network errors. Our `selectErrorMessage` helper can in
   * theory handle this, unintentionally or otherwise. On a close look, the returned object
   * looks a lot like the ApolloError.
   *
   * @example
   * `{"graphQLErrors":[{"message":"failed to add to watchlist"}],"networkError":null,"message":"failed to add to watchlist"}`
   */
  errors: readonly GraphQLError[] | undefined;
}
export type AddToWatchlistFn = (params: AddAssetToWatchListMutationVariables) => Promise<MutationResponse>;
type UseAddWatchlistItemResponse = { addToWatchlist: AddToWatchlistFn; loading: boolean };

export const useAddWatchListItem = (options = {}): UseAddWatchlistItemResponse => {
  const [addWatchListItemMutation, { loading }] = useMutation(ADD_ASSET_TO_WATCHLIST, {
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });

  const addToWatchlist = useCallback(
    async ({ assetId }) => {
      const { data, errors } = await addWatchListItemMutation({
        variables: { assetId },
        update: (cache: ApolloCache<AddAssetToWatchListMutation>) => {
          cache.modify({
            fields: {
              watchListItems() {
                return undefined;
              },
            },
          });
        },
        ...options,
      });
      return { data, errors };
    },
    [addWatchListItemMutation, options],
  );

  return { addToWatchlist, loading };
};
