import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  horizontalContainer: {
    height: 60,
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  settingsIcon: {
    height: 24,
    width: 24,
    stroke: theme.palette.text.primary,
    cursor: 'pointer',
  },
  menuList: {
    width: 343,
    padding: 0,
  },
  image: {
    textAlign: 'center',
    boxShadow: 'inset 0px 1px 0px rgba(184, 184, 184, 0.5);',
  },
  emptyLabel: {
    marginBottom: 48,
  },
  badge: {
    background: theme.palette.success.main,
    color: theme.palette.textPrimary,
  },
  paper: {
    maxHeight: 500,
  },
}));
