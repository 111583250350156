import { ApolloError } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NetworkError } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';
import * as Sentry from '@sentry/react';

type UserFlow = 'auth' | 'payment' | 'account' | 'search' | 'trade' | 'transfer';
type Tags = {
  userFlow: UserFlow;
  [key: string]: string | number;
};

type LogErrorProps = {
  error: Error;
  originalError?: Error | GraphQLError | ApolloError | NetworkError;
  additionalInfo?: { [key: string]: string | number | boolean };
  filename?: string;
  tags?: Tags;
};

export function logError({ error, originalError, filename, additionalInfo, tags }: LogErrorProps): void {
  const captureContext = {
    extra: {
      filename: filename ?? '',
      originalError: originalError ? JSON.stringify(originalError) : '',
      error: JSON.stringify(error),
      ...additionalInfo,
    },
    tags,
  };

  Sentry.captureException(error, captureContext);
}

type LogMessageOptions = {
  tags?: Tags;
  additionalInfo?: Record<string, string | number | boolean>;
};

export function logMessage(message: string, { tags, additionalInfo }: LogMessageOptions): void {
  const captureContext = { tags, extra: additionalInfo };
  Sentry.captureMessage(message, captureContext);
}

export function setLoggerUser(email: string): void {
  Sentry.setUser({ email });
}

export function clearLoggerUser(): void {
  Sentry.setUser(null);
}
