import { graphql } from '../../__generated__';

export const SEARCH_WINE_VINTAGES_RESULTS = graphql(`
  mutation SearchWineVintages(
    $queryString: String!
    $from: Int!
    $pageSize: Int!
    $sortOrder: String
    $sortFilter: String
    $preQueryString: String
    $filterNames: [String!]!
  ) {
    searchWineVintages(
      queryString: $queryString
      from: $from
      pageSize: $pageSize
      sortOrder: $sortOrder
      sortFilter: $sortFilter
      preQueryString: $preQueryString
      filterNames: $filterNames
    ) {
      total
      results {
        wineVintageId
        defaultAssetId
        wineId
        wineName
        appellation
        region
        vintage
        score
        marketValue
        lastTradePrice
        lastTradeDate
        vintage
        producer
        classification
        calculatedMarketData {
          liquidityScore
          performance {
            valueDifference
            percentageDifference
          }
        }
        defaultAsset {
          id
          unitCount
          unitSize
          spread(selectLowestOffer: true) {
            highestBid {
              price
              assetId
              unitCount
              unitSize
            }
            lowestOffer {
              price
              assetId
              unitCount
              unitSize
            }
            percentageDifference
          }
          vintage {
            id
            imageFileName
            wine {
              sparkling
            }
            drinkingWindow {
              dateFrom
              dateTo
              advice
            }
          }
        }
      }
    }
  }
`);
