/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';
import WineConfirmSuccess from '../../assets/images/wine-confirm-success.png';
import { ReactComponent as WineErrorIcon } from '../../assets/images/wine-comfirm-error.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    width: '50%',
    textAlign: 'center',
    color: '#8A809A',
  },
  circle: {
    borderRadius: 180,
    borderWidth: 20,
    marginTop: 20,
  },
  hint: {
    marginTop: 20,
    marginBottom: 20,
  },
  button: {
    minWidth: 250,
    marginTop: 20,
    marginBottom: 20,
  },
}));

interface WithdrawResultModalProps {
  initModalTpe: ResultTpe;
  onClose: () => void;
  onclick: () => void;
}

export enum ResultTpe {
  Success,
  Error,
}

export default function WithdrawResultModal({ onClose, onclick, initModalTpe }: WithdrawResultModalProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { icon, hint, description, buttonText } =
    initModalTpe === ResultTpe.Success
      ? {
          icon: <img src={WineConfirmSuccess} height="212" alt="success-message" />,
          hint: t('wallet:withdraw.success.hint'),
          description: t('wallet:withdraw.success.description'),
          buttonText: t('wallet:withdraw.success.confirm'),
        }
      : {
          icon: <WineErrorIcon />,
          hint: t('wallet:withdraw.error.hint'),
          description: t('wallet:withdraw.error.description'),
          buttonText: t('wallet:withdraw.error.confirm'),
        };
  return (
    <>
      <DialogTitle id="dialog-title" onClose={onClose}>
        <></>
      </DialogTitle>
      <Typography variant="h1" />
      <div className={classes.container}>
        <div className={classes.circle}>{icon}</div>
        <Typography customVariant="h5" className={classes.hint}>
          {hint}
        </Typography>
        <Typography customVariant="body1" className={classes.description}>
          {description}
        </Typography>
        <Button variant="contained" color="primary" size="large" onClick={onclick} className={classes.button}>
          {buttonText}
        </Button>
      </div>
    </>
  );
}
