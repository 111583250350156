import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 340,
    boxShadow: 'inset 0px 1px 0px 0px rgba(184, 184, 184, 0.5);',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(5),
    paddingRight: theme.spacing(2),

    '&:hover': {
      background: '#F8F8F9',
    },
  },
  horizontalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    maxHeight: 40,
    width: 190,
    overflow: 'hidden',
  },
  label: {
    height: 40,
    marginLeft: 50,
    width: 240,
    overflow: 'hidden',
  },
  iconAndTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(5),
  },
  uppercased: {
    textTransform: 'uppercase',
  },
  badge: {
    background: theme.palette.success.main,
  },
}));
