import i18n from '../../i18n';
import { Asset } from '../../__generated__/graphql';

export function selectUnitSizeData(arg: Asset | undefined | null): string {
  if (arg?.unitCount && arg?.unitSize) {
    return `${arg.unitCount} x ${arg.unitSize}`;
  }

  return i18n.t('product:notAvailable');
}
