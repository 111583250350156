/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Skeleton from '@mui/material/Skeleton';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import Typography from '../../Typography';
import { formatter } from '../../../utils/currencyFormatter';
import { useAvailableWithdrawalAmount } from '../../../hooks/useAvailableWithdrawalAmount';
import { useAvailableBalance } from '../../../hooks/useAvailableBalance';

const useStyles = makeStyles(() => {
  return {
    balanceContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      gap: 2,
      textAlign: 'end',
    },
    uppercase: {
      textTransform: 'uppercase',
    },
  };
});

const AvailableWithdrawalAmount: React.FC = () => {
  const classes = useStyles();
  const { merchantInitiatedTransaction12341 } = useFlags();
  const { t } = useTranslation();
  const { availableWithdrawalAmount, loading: loadingAvailableWithdrawAmount } = useAvailableWithdrawalAmount({
    skip: !merchantInitiatedTransaction12341,
    pollInterval: 5000,
  });
  const { availableBalance: _availableBalance, loading: loadingAvailableBalance } = useAvailableBalance({
    skip: merchantInitiatedTransaction12341,
  });
  const loading = merchantInitiatedTransaction12341 ? loadingAvailableWithdrawAmount : loadingAvailableBalance;
  const availableBalance = merchantInitiatedTransaction12341
    ? availableWithdrawalAmount?.availableWithdrawalAmount?.currentWithdrawalGbp ?? undefined
    : _availableBalance;

  return (
    <div className={classes.balanceContainer}>
      <Typography variant="overline" zellarColour="textLabel" className={classes.uppercase}>
        {t('wallet:paymentMethodModal.availableToWithdraw')}
      </Typography>
      {loading ? (
        <Typography variant="h4">
          <Skeleton width="40px" />
        </Typography>
      ) : (
        <Typography variant="h4">
          {isUndefined(availableBalance) ? t('common:currentlyUnavailable') : formatter.format(availableBalance)}
        </Typography>
      )}
    </div>
  );
};

export default AvailableWithdrawalAmount;
