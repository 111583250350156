import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../Typography';

const useStyles = makeStyles<Theme, { expanded: boolean }>(() => ({
  notes: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    // unexpanded line amount to show
    WebkitLineClamp: (props) => (props.expanded ? 10 : 2),
    WebkitBoxOrient: 'vertical',
  },
  showMore: {
    cursor: 'pointer',
  },
}));

interface Props {
  label: string;
}

export default function TastingNotes({ label }: Props): JSX.Element {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles({ expanded });

  return (
    <>
      <Typography className={classes.notes} customVariant="body1">
        {label}
      </Typography>
      <Typography onClick={() => setExpanded(!expanded)} className={classes.showMore} customVariant="body1Bold">
        {expanded ? t('common:showLess') : t('common:showMore')}
      </Typography>
    </>
  );
}
