import * as yup from 'yup';
import i18n from './i18n';

/* Translations for the yup errors that are localized and shown near the inputs */
export const initYupLocalizations = (): void => {
  yup.setLocale({
    mixed: {
      notType: i18n.t('product:detailsModal.error.number'),
    },
  });
};
