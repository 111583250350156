import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(10),
    width: '100%',
  },
}));
