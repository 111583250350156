import * as yup from 'yup';
import { CallbackFunction } from '../../../hooks/useCreatePaymentCard';
import { CardPaymentInput } from '../../../__generated__/graphql';
import { logError, logMessage } from '../../../utils/logger';

export const paymentCardSchema = yup.object({
  name: yup
    .string()
    .min(2)
    .max(24)
    .test((val) => /^[a-zA-Z][a-zA-Z ]*[a-zA-Z]$/.test(`${val}`))
    .required(),
});

export async function submitPaymentForm(
  createCardPayment: CallbackFunction,
  cardPaymentInput: CardPaymentInput,
): Promise<void> {
  if (Boolean(process.env.REACT_APP_MOCK_GQL) === true) {
    window.location.hash = '#success';
    return;
  }

  try {
    const { data, errors } = await createCardPayment(cardPaymentInput);

    if (errors) {
      logError({
        originalError: errors[0],
        error: new Error('Card payment request failed'),
        filename: 'PayByCard',
        tags: { userFlow: 'payment' },
        additionalInfo: {
          errors: JSON.stringify(errors),
        },
      });
    }

    if (data?.createCardPayment.status === 'Declined') {
      logMessage('Card payment declined', {
        tags: { userFlow: 'payment' },
        additionalInfo: { cardPayment: JSON.stringify(data.createCardPayment.errorMessage) },
      });
      // TODO: Handle this case in https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/8778
      // eslint-disable-next-line no-console
      console.log(
        'Declined: Effort to handle this case is captured in https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/8778',
      );
    }

    if (data?.createCardPayment.status === 'Pending') {
      const { redirectUrl } = data.createCardPayment;
      if (!redirectUrl) {
        const err = new Error('Redirect error is null');
        logError({ error: err, filename: 'PayByCard', tags: { userFlow: 'payment' } });
        throw err;
      }

      window.location.href = redirectUrl;
    }
  } catch (e) {
    // TODO: Handle this error and show an error modal.
    // https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/8778
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
