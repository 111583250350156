import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(3),
  },
}));

interface TradingCardSkeletonProps {
  className?: string;
}

export default function TradingCardSkeleton({ className = '' }: TradingCardSkeletonProps): JSX.Element {
  const classes = useStyles();
  return (
    <Box width="130px" className={`${className} ${classes.box}`} aria-label="trading-card-skeleton">
      <Typography variant="subtitle1">
        <Skeleton />
      </Typography>
      <Typography customVariant="numberLarge">
        <Skeleton width="66%" />
      </Typography>
      <Skeleton width="33%" height="24px" />
    </Box>
  );
}
