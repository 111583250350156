import { ApolloCache, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { UPDATE_CATEGORY_TYPE } from '../graphql/mutations/updateCategoryType';
import { UpdateCategoryTypeMutation, UpdateCategoryTypeMutationVariables } from '../__generated__/graphql';

interface MutationResponse {
  data: UpdateCategoryTypeMutation | null | undefined;
  errors: readonly GraphQLError[] | undefined;
}

type CallbackFunction = (params: UpdateCategoryTypeMutationVariables) => Promise<MutationResponse>;

export const useUpdateCategoryType = (): CallbackFunction => {
  const [updateCategoryTypeMutation] = useMutation(UPDATE_CATEGORY_TYPE, {
    // Catches network errors and returns them in errors in response
    onError: () => null,
    update: (cache: ApolloCache<UpdateCategoryTypeMutation>, { data: responseData }) => {
      if (responseData?.updateCategoryType) {
        const id = cache.identify({ ...responseData?.updateCategoryType });
        cache.modify({
          id,
          fields: {
            isEnabled() {
              return responseData.updateCategoryType.isEnabled;
            },
          },
        });
      }
    },
  });

  const updateCategoryType = useCallback(
    async (variables: UpdateCategoryTypeMutationVariables) => {
      const { data, errors } = await updateCategoryTypeMutation({
        variables,
      });
      return { data, errors };
    },
    [updateCategoryTypeMutation],
  );
  return updateCategoryType;
};
