import { graphql } from '../../__generated__';

export const DELETE_PAYMENT_CARD = graphql(`
  mutation DeletePaymentCard($instrumentId: String!) {
    deleteCardDetail(instrumentId: $instrumentId) {
      isSuccess
      errorMessage
    }
  }
`);
