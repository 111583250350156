import makeStyles from '@mui/styles/makeStyles';

export const useLoginFormStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
    textAlign: 'center',
    paddingTop: '10px',
  },
  forgotPasswordLink: {
    textAlign: 'left',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(3),
  },
  label: {
    alignSelf: 'flex-start',
  },
  headingV2: {
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '33.6px',
    textAlign: 'center',
  },
  fieldLabelV2: {
    fontSize: '14px',
    lineHeight: '16.8px',
    fontWeight: 700,
  },
  headingContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  textField: {
    backgroundColor: 'white',
  },
}));
