import { PaletteOptions } from '@mui/material/styles';

export const colours = {
  darkBlue: '#150036',
  brightGreen: '#1FE85C',
  purpleMid: '#8A809A',
  white: '#FFFFFF',
  purpleDeep: '#493B5F',
  highlightBlue: '#EDF5FF',
  brightBlueHighlight: '#007EFF',
  activeBlue: '#B4CCE9',
  brightOrange: '#FB9D0E',
  brighterOrange: '#FFB340',
  grey: '#C4C4C4',
  alertRed: '#EC2E48',
};

const onboardingPalette: PaletteOptions = {
  primary: {
    main: colours.brightBlueHighlight,
  },
  secondary: {
    main: colours.brightOrange,
  },
  text: {
    primary: colours.white,
    secondary: colours.purpleMid,
    disabled: colours.purpleMid,
  },
  navigation: {
    background: {
      active: {
        main: colours.brightGreen,
        contrastText: colours.darkBlue,
      },
      default: {
        main: colours.darkBlue,
        contrastText: colours.brightGreen,
      },
    },
  },
  button: {
    primary: {
      background: {
        main: colours.brightOrange,
        hover: colours.brighterOrange,
        disabled: colours.purpleDeep,
      },
      text: {
        main: colours.white,
        disabled: colours.purpleMid,
      },
    },
  },
  background: {
    default: colours.darkBlue,
    paper: colours.white,
  },
  error: {
    main: colours.alertRed,
  },
  textField: {
    active: colours.brightBlueHighlight,
    hover: colours.brightBlueHighlight,
    text: colours.darkBlue,
  },
  select: {
    menuItem: {
      active: colours.activeBlue,
      hover: colours.highlightBlue,
    },
  },
  dividers: {
    primary: colours.purpleMid,
  },
};

export default onboardingPalette;
