import { useLocation } from 'react-router-dom';

interface UseCardPaymentCallbackResponse {
  showCarPaymentErrorModal: boolean;
  showCarPaymentSuccessModal: boolean;
  showAddPaymentCardModal: boolean;
  showTopUpBuySuccessSummaryModal: boolean;
  showAddCardSuccessModal: boolean;
}

export default function useCardPaymentCallback(): UseCardPaymentCallbackResponse {
  const location = useLocation();
  return {
    showCarPaymentErrorModal: location.hash === '#failure',
    showCarPaymentSuccessModal: location.hash === '#success',
    showAddCardSuccessModal: location.hash === '#successAddCard',
    showAddPaymentCardModal: location.hash === '#add_card',
    showTopUpBuySuccessSummaryModal: location.hash === '#buy_topup',
  };
}
