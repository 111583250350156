import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    gap: theme.spacing(4),
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  row: {
    display: 'flex',
    width: '100%',
    gap: theme.spacing(4),
  },
  inputsContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    textAlign: 'initial',
  },
  search: {
    width: '100%',
  },
  unitSize: {
    width: 200,
  },
  quantity: {
    width: 200,
  },
  purchaseDate: {
    width: 200,
  },
  purchasePrice: {
    width: 200,
  },
  cancelLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 'bold',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  submit: {
    width: 200,
  },
}));

export { useStyles };
