import { useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import ReviewSelection from './ReviewSelection';
import AddressModal from '../AddressModal';
import ProcessModal from './ProcessModal';
import SuccessModal from './SuccessModal';
import FailureScreen from './FailureScreen';
import useMediaQuery from '../../hooks/useMediaQuery';

interface TransferToExternalLocationModalProps {
  open: boolean;
  onClose: () => void;
}
type ModalTypeState = 'reviewSelection' | 'addressForm' | 'transferProcessing' | 'transferSuccess' | 'transferFailed';
export default function TransferToExternalLocationModal({
  open,
  onClose,
}: TransferToExternalLocationModalProps): JSX.Element {
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [modalType, setModalType] = React.useState<ModalTypeState>('reviewSelection');
  return (
    <Dialog open={open} maxWidth="lg" fullScreen={lessThanMd}>
      <>
        {(() => {
          switch (modalType) {
            case 'reviewSelection':
              return (
                <ReviewSelection
                  onClose={onClose}
                  openAddressModal={() => setModalType('addressForm')}
                  onSuccessModal={() => setModalType('transferProcessing')}
                />
              );
            case 'addressForm': {
              return <AddressModal addressType="Delivery" onClose={() => setModalType('reviewSelection')} open />;
            }
            case 'transferProcessing': {
              return (
                <ProcessModal
                  onSuccess={() => setModalType('transferSuccess')}
                  onError={() => setModalType('transferFailed')}
                />
              );
            }
            case 'transferSuccess': {
              return <SuccessModal onClose={onClose} />;
            }
            case 'transferFailed': {
              return <FailureScreen onClose={onClose} />;
            }
            default:
              return null;
          }
        })()}
      </>
    </Dialog>
  );
}
