import React from 'react';
import TableCell from '@mui/material/TableCell';
import { CustomCell as CustomCellProps } from '../types';

export default function CustomCell({ content, cellClassName, sx }: CustomCellProps): JSX.Element {
  return (
    <TableCell className={cellClassName} sx={sx}>
      {content}
    </TableCell>
  );
}
