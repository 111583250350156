import { useReactiveVar } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RRLink } from 'react-router-dom';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as SquaredCheckIcon } from '../../assets/icons/squared-check.svg';
import { isLoggedInVar } from '../../graphql/cache';
import { useShouldRenderMarketRoute } from '../../hooks/useShouldRenderMarketRoute';
import MenuButton from '../MenuButton';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'center',
  },
}));

interface OptionsMenuProps {
  assetId: number;
  handleTransferRequested: (assetId: number) => void;
}

export default function OptionsMenu({ assetId, handleTransferRequested }: OptionsMenuProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const { shouldRenderMarketRoute } = useShouldRenderMarketRoute();

  return (
    <MenuButton>
      {shouldRenderMarketRoute && (
        <MenuItem component={RRLink} to={`/market?assets=${assetId}`}>
          <Typography className={classes.item} variant="body1">
            <CopyIcon />
            {t('common:compare')}
          </Typography>
        </MenuItem>
      )}
      <MenuItem onClick={() => handleTransferRequested(assetId)} disabled={!isLoggedIn}>
        <Typography className={classes.item} variant="body1">
          <SquaredCheckIcon />
          {t('product:addToExternalPortfolio')}
        </Typography>
      </MenuItem>
    </MenuButton>
  );
}
