/* eslint-disable @typescript-eslint/naming-convention */
import { ApolloError } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isUndefined from '../../utils/isUndefined';
import Table from '../Table';
import { Headers, SortDirection } from '../Table/types';
import HoldingInstanceTable from './AssetInstanceTable';
import { selectTableData, TableData } from './selectors';
import { OwnedAsset, OwnedAssetFacets } from '../../graphql/selectors/ownedAssets';
import { Mode } from '../OrderModal/types';
import OrderModal from '../OrderModal';
import CalculationToolTip from '../CalculationToolTip';
import CreateBuyNowModal from '../BuyNowModal';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';
import { useQueryParameters } from '../../hooks';

const ExpandedContent = ({ rowId }: { rowId: string }) => <HoldingInstanceTable assetId={Number(rowId)} />;

interface HoldingsTableProps {
  assets: OwnedAsset[];
  loading: boolean;
  error?: ApolloError;
  activeSortKey: keyof OwnedAssetFacets;
  sortDirection: SortDirection;
  onSortChanged: (key: keyof OwnedAssetFacets, direction: SortDirection) => void;
  onEmptyClicked: () => void;
}

export default function HoldingsTable({
  assets,
  loading,
  error,
  activeSortKey,
  sortDirection,
  onSortChanged,
  onEmptyClicked,
}: HoldingsTableProps): JSX.Element {
  const { clickableTradeTiles13816 } = useFlags();
  const { showTopUpBuySuccessSummaryModal } = useCardPaymentCallback();
  const queryParams = useQueryParameters();
  const { t } = useTranslation();
  const history = useHistory();
  const [assetId, setAssetId] = React.useState<number | null>(null);
  const queryParamAssetId = queryParams.get('assetId');
  const [openModal, setOpenModal] = React.useState<'buy' | 'bid' | 'offer' | null>(null);
  const common = {
    onClick: onSortChanged,
    direction: sortDirection,
  };

  function handleOfferClick(rowId: string) {
    setAssetId(Number(rowId));
    setOpenModal('offer');
  }

  function handleBidClick(rowId: string) {
    setAssetId(Number(rowId));
    setOpenModal('bid');
  }

  function handleBuyClick(rowId: string) {
    setAssetId(Number(rowId));
    setOpenModal('buy');
  }

  function handleModalClose() {
    setAssetId(null);
    setOpenModal(null);
  }

  const handleCompareClick = React.useCallback((rowId: string) => history.push(`/market?assets=${rowId}`), [history]);

  const tableData = React.useMemo(
    () =>
      selectTableData({
        assets,
        handleCompareClick,
        handleBidClick,
        handleBuyClick,
        handleOfferClick,
        clickableTradeTiles13816,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assets, handleCompareClick],
  );
  const headers: Headers<Omit<TableData, 'rowId' | 'asset'>, keyof OwnedAssetFacets> = {
    wineName: {
      title: t('portfolio:table.headers.wineName'),
      id: 'name',
      active: activeSortKey === 'name',
      ...common,
    },
    vintage: { title: t('common:vintage'), id: 'vintage', active: activeSortKey === 'vintage', ...common },
    score: { title: t('portfolio:table.headers.score'), id: 'score', active: activeSortKey === 'score', ...common },
    drinkingWindow: { title: t('portfolio:table.headers.drinkingWindow') },
    units: { title: t('trade:units'), id: 'units', active: activeSortKey === 'units', ...common },
    unitSize: { title: t('common:unitSize'), id: 'unitCount', active: activeSortKey === 'unitCount', ...common },
    totalPurchasePrice: {
      title: t('portfolio:table.headers.totalPurchasePrice'),
      id: 'totalAvgPurchasePrice',
      active: activeSortKey === 'totalAvgPurchasePrice',
      ...common,
    },
    totalMarketValuation: {
      title: t('portfolio:table.headers.totalMarketValuation'),
      id: 'totalMarketValue',
      active: activeSortKey === 'totalMarketValue',
      extraElement: <CalculationToolTip title={t('market:marketValueCalculation')} />,
      ...common,
    },
    performance: {
      title: t('portfolio:table.headers.performance'),
      id: 'performancePercentageDelta',
      active: activeSortKey === 'performancePercentageDelta',
      ...common,
    },
    highestBid: {
      title: t('product:trading.highestBid'),
      id: 'highestBid',
      active: activeSortKey === 'highestBid',
      ...common,
    },
    lowestOffer: {
      title: t('product:trading.lowestOffer'),
      id: 'lowestOffer',
      active: activeSortKey === 'lowestOffer',
      ...common,
    },
  };

  return (
    <>
      <Table
        data={tableData}
        headers={headers}
        emptyTableText={t('portfolio:noAssets')}
        EmptyTableAction={
          <Button variant="contained" color="primary" size="large" onClick={onEmptyClicked}>
            {t('common:discover')}
          </Button>
        }
        loading={loading}
        error={isUndefined(error) ? null : error.message}
        SubContent={ExpandedContent}
      />
      {openModal === 'buy' && assetId && <CreateBuyNowModal assetId={assetId} onClose={handleModalClose} open />}
      {queryParamAssetId && showTopUpBuySuccessSummaryModal && (
        <CreateBuyNowModal assetId={Number(queryParamAssetId)} open onClose={handleModalClose} />
      )}
      {openModal === 'offer' && assetId && (
        <OrderModal assetId={assetId} onClose={handleModalClose} mode={Mode.Create} tradeType="offer" open />
      )}
      {openModal === 'bid' && assetId && (
        <OrderModal assetId={assetId} onClose={handleModalClose} mode={Mode.Create} tradeType="bid" open />
      )}
    </>
  );
}
