import React, { useState } from 'react';
import { Alert, Button, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { KycStatus } from '../../__generated__/graphql';
import KycFlowModal from '../KycFlowModal';

interface Props {
  kycStatus: string | null;
}

const useStyles = makeStyles(() => ({
  container: {
    width: '684px',
  },
  root: {
    padding: '16px 16px',
    backgroundColor: '#FB9D0E',
    color: 'white',
    fontSize: 14,
    width: '100%',
    fontWeight: 700,
    lineHeight: '1.125rem',
    textAlign: 'center',
    borderRadius: '4px',
  },
  alert: {
    backgroundColor: '#FB9D0E',
    color: 'white',
    width: '100%',
  },
}));

export default function KycPrompt({ kycStatus }: Props): JSX.Element {
  const classes = useStyles();
  const vertical = 'bottom';
  const horizontal = 'center';
  const { t } = useTranslation();
  const [close, setClose] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const open = React.useMemo(() => {
    return !close && kycStatus !== KycStatus.successful;
  }, [kycStatus, close]);
  function handleClose() {
    setModalOpen(false);
  }

  if (open) {
    const openModal = () => {
      setModalOpen(true);
    };
    return (
      <>
        <Snackbar
          className={classes.container}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          open
        >
          {kycStatus === KycStatus.pending ? (
            <div aria-hidden="true" onClick={() => setClose(true)} className={classes.root}>
              {t(`common:kyc.kycInProgress`)}
            </div>
          ) : (
            <Alert
              className={classes.alert}
              icon={false}
              action={
                <Button onClick={openModal} style={{ color: 'white' }}>
                  {t('common:kyc.verify')}
                </Button>
              }
            >
              {t(`common:kyc.needVerifyKyc`)}
            </Alert>
          )}
        </Snackbar>
        {modalOpen && <KycFlowModal open={modalOpen} onClose={handleClose} />}
      </>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
