import React from 'react';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material';

const ActionsCellInner = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  alignItems: 'center',
}));

interface ActionsCellProps {
  border?: 'right' | 'left';
}

const ActionsCell: React.FC<ActionsCellProps> = ({ children, border }) => {
  return (
    <TableCell
      sx={{
        borderLeft: (theme) => (border === 'left' ? `1px solid ${theme.palette.grey[100]}` : undefined),
        borderRight: (theme) => (border === 'right' ? `1px solid ${theme.palette.grey[100]}` : undefined),
      }}
    >
      <ActionsCellInner>{children}</ActionsCellInner>
    </TableCell>
  );
};

export default styled(ActionsCell)();
