import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  modalContainer: {
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: 550,
    gap: theme.spacing(6),
  },
  addressContainer: {
    display: 'flex',
    gap: theme.spacing(5),
    width: '100%',
  },
  identificationContainer: {
    padding: 15,
    minHeight: 286,
    minWidth: 575,
    maxWidth: 575,
    width: '100%',
  },
  confirmButton: {
    width: 90,
    alignSelf: 'flex-end',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  oneLine: {
    width: '100%',
  },
  disabled: {
    opacity: 0.3,
  },
  multiInputLine: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    gap: '5%',
  },
  addressLine3: {
    width: '95%',
  },
  field: {
    width: 'inherit',
  },
  label: {
    marginBottom: 5,
  },
  select: {
    width: '200px',
  },
  right: {
    marginLeft: 'auto',
  },
  flex: {
    display: 'flex',
  },
  gap3: {
    gap: theme.spacing(3),
  },
  column: {
    flexDirection: 'column',
  },
  idContainer: {
    borderRadius: '4px',
    border: '1px solid white',
    padding: theme.spacing(3),
  },
  description: {
    color: theme.palette.textLabel,
  },
}));
