import React from 'react';
import { SortDirection } from '@cultwines/zellar-client-sdk';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { TableCell } from '@mui/material';
import { OwnedAsset, OwnedAssetFacets } from '../../graphql/selectors/ownedAssets';
import EmptyPlaceholder from '../EmptyPlaceholder';
import RowSkeleton from '../TableElements/RowSkeleton';
import TableCellHead from '../TableElements/TableCellHead';
import CultXPortfolioTableRow from './Row';
import ErrorPlaceholder from '../ErrorPlaceholder';
import { COLUMN_COUNT } from './types';

interface CultXPortfolioProps {
  assets: OwnedAsset[];
  loading?: boolean;
  error?: string;
  perPage?: number;
  sortDirection: SortDirection;
  sortColumn: keyof OwnedAssetFacets;
  onSortChanged: (key: keyof OwnedAssetFacets) => void;
  onBuyNowClicked: (assetId: number) => void;
  onBidClicked: (assetId: number) => void;
  onOfferClicked: (assetId: number) => void;
  onCompareClicked: (assetId: number) => void;
  onEmptyClicked: () => void;
}

/**
 * TODO: Things still needed in this file:
 * 1. ~translations~
 * 2. ~data formatting~
 * 3. ~actions added~
 * 5. ~transfer wines (e.g. checkboxes)~
 * 6. ~compare multiple (e.g. other checkboxes)~
 * 7. pagination, if any things related to this are needed?
 * 8. ~sub table~
 * 9. a thorough once over, this file started out as a prototype to test out new refactoring changes!
 *
 */
export default function CultXPortfolioTable({
  assets,
  loading,
  error,
  perPage,
  sortColumn,
  sortDirection,
  onSortChanged,
  onBidClicked,
  onBuyNowClicked,
  onCompareClicked,
  onOfferClicked,
  onEmptyClicked,
}: CultXPortfolioProps) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table aria-label="cultx portfolio table">
        <TableHead>
          <TableRow>
            <TableCellHead hideSortButton /> {/* this is the header for the expander button/checkbox */}
            <TableCellHead
              sortActive={sortColumn === 'name'}
              sortDirection={sortColumn === 'name' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('name')}
              title={t('portfolio:table.headers.wineName')}
            />
            <TableCellHead
              sortActive={sortColumn === 'vintage'}
              sortDirection={sortColumn === 'vintage' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('vintage')}
              title={t('common:vintage')}
            />
            <TableCellHead
              sortActive={sortColumn === 'score'}
              sortDirection={sortColumn === 'score' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('score')}
              title={t('portfolio:table.headers.score')}
            />
            <TableCellHead title={t('portfolio:table.headers.drinkingWindow')} hideSortButton />
            <TableCellHead
              sortActive={sortColumn === 'units'}
              sortDirection={sortColumn === 'units' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('units')}
              title={t('trade:units')}
            />
            <TableCellHead
              sortActive={sortColumn === 'unitCount'}
              sortDirection={sortColumn === 'unitCount' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('unitCount')}
              title={t('common:unitCount')}
            />
            <TableCellHead
              sortActive={sortColumn === 'totalPurchasePrice'}
              sortDirection={sortColumn === 'totalPurchasePrice' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('totalPurchasePrice')}
              title={t('portfolio:table.headers.totalPurchasePrice')}
            />
            <TableCellHead
              sortActive={sortColumn === 'totalMarketValue'}
              sortDirection={sortColumn === 'totalMarketValue' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('totalMarketValue')}
              tooltip={t('market:marketValueCalculation')}
              title={t('portfolio:table.headers.totalMarketValuation')}
            />
            <TableCellHead
              sortActive={sortColumn === 'performancePercentageDelta'}
              sortDirection={sortColumn === 'performancePercentageDelta' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('performancePercentageDelta')}
              title={t('portfolio:table.headers.performance')}
            />
            <TableCellHead
              sortActive={sortColumn === 'highestBid'}
              sortDirection={sortColumn === 'highestBid' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('highestBid')}
              title={t('product:trading.highestBid')}
            />
            <TableCellHead
              sortActive={sortColumn === 'lowestOffer'}
              sortDirection={sortColumn === 'lowestOffer' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('lowestOffer')}
              title={t('product:trading.lowestOffer')}
            />
            <TableCellHead title={t('common:actions')} hideSortButton />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <RowSkeleton columnCount={COLUMN_COUNT} rowCount={perPage} />}
          {!loading && error && (
            <TableCell align="center" colSpan={COLUMN_COUNT}>
              <ErrorPlaceholder error={error} />
            </TableCell>
          )}
          {!loading && !error && !assets.length && (
            <TableCell align="center" colSpan={COLUMN_COUNT}>
              <EmptyPlaceholder
                label={t('portfolio:noAssets')}
                ActionButton={
                  <Button variant="contained" color="primary" size="large" onClick={onEmptyClicked}>
                    {t('common:discover')}
                  </Button>
                }
              />
            </TableCell>
          )}
          {!loading &&
            assets.map((asset) => (
              <CultXPortfolioTableRow
                key={asset.assetId}
                asset={asset}
                onBidClicked={onBidClicked}
                onBuyNowClicked={onBuyNowClicked}
                onOfferClicked={onOfferClicked}
                onCompareClicked={onCompareClicked}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
