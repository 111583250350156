import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';
import { useStyles } from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  assetId: number;
}

export default function TransferAssetModal({ open, onClose, assetId }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  function handleClose(): void {
    onClose();
  }

  const handleSubmit = async () => {
    // TODO
    // eslint-disable-next-line no-console
    console.log('transfer asset mutation here');
    // eslint-disable-next-line no-console
    console.log(assetId);
    const error = false;
    if (error) {
      handleClose();
      enqueueSnackbar(t('portfolio:transfer.failure'), {
        variant: 'error',
      });
    } else {
      handleClose();
      enqueueSnackbar(t('portfolio:transfer.success'), {
        variant: 'success',
      });
    }
  };

  return (
    // needs to be maxWidth="md" to suit other values set in ModalContentSwitcher and modalStyles()
    <Dialog onClose={onClose} open={open} disableEscapeKeyDown maxWidth="md">
      <div className={classes.modalContainer}>
        <DialogTitle id="dialog-title" onClose={onClose}>
          {t('portfolio:transfer.request')}
        </DialogTitle>
        <DialogContentBorderTopOnly className={classes.modalContent} dividers>
          <Typography className={classes.label} customVariant="h3Black">
            {t('portfolio:transfer.body')}
          </Typography>
          <div className={classes.actions}>
            <Typography variant="body1" className={classes.cancelLabel} onClick={onClose}>
              {t('common:cancel')}
            </Typography>
            <Button variant="contained" color="primary" size="large" onClick={handleSubmit}>
              {t('portfolio:transfer.request')}
            </Button>
          </div>
        </DialogContentBorderTopOnly>
      </div>
    </Dialog>
  );
}
