import React from 'react';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import { useIconButtonStyles } from './styles';

interface IconButtonProps extends MuiIconButtonProps {
  variant?: 'filled' | 'normal';
}

export default function IconButton({ variant = 'normal', children, ...baseProps }: IconButtonProps): JSX.Element {
  const classes = useIconButtonStyles({ filled: variant === 'filled' });
  return (
    <MuiIconButton {...baseProps} classes={{ root: classes.button }}>
      {children}
    </MuiIconButton>
  );
}
