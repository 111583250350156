import { ApolloProvider } from '@apollo/client/react';
import { ThemeProvider, StyledEngineProvider, styled } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/react';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ContentBottomPaddingProvider } from './context/contentBottomPaddingContext';
import apolloClient from './graphql/client';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import { lightTheme } from './theme';
import { initYupLocalizations } from './yup';
import ErrorPlaceholder from './components/ErrorPlaceholder';
import './index.css';

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantInfo {
    background-color: #b1ded8;
    color: #150036;
  }
  &.SnackbarItem-variantSuccess {
    background-color: #b1ded8;
    color: #150036;
  }
`;

// Only for production
if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        // This enables us to inspect the gql queries and mutations.
        networkDetailAllowUrls: [process.env.REACT_APP_GRAPHQL_URL!],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0,
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
}

// https://stackoverflow.com/a/57472299
// Required for highcharts to be able to show the no data label
NoDataToDisplay(Highcharts);

// Solves unexpected bug: 'Highcharts is not defined'
// https://github.com/highcharts/highcharts/issues/4994#issuecomment-182717711
window.Highcharts = Highcharts;

// Initializes localization of error messages
initYupLocalizations();

function AppWithWrappers(): JSX.Element {
  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <I18nextProvider i18n={i18n}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={lightTheme}>
              <StyledSnackbarProvider
                autoHideDuration={3000}
                maxSnack={1}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                TransitionComponent={Collapse}
              >
                <CssBaseline />
                <Suspense fallback="loading...">
                  <ContentBottomPaddingProvider>
                    <App />
                  </ContentBottomPaddingProvider>
                </Suspense>
              </StyledSnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </I18nextProvider>
      </Router>
    </ApolloProvider>
  );
}

function AppWithWrappersAndSentry(): JSX.Element {
  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorPlaceholder error="Something went wrong! Please try again" />}
      showDialog={false}
    >
      <AppWithWrappers />
    </Sentry.ErrorBoundary>
  );
}

/**
 * Not using React.StrictMode here due
 * to a number of incompatabilities with
 * material ui v4. The general response to any issues
 * related to their styling package is that they're deprecating
 * use of it in upcoming versions and thus won't fix any issues like these.
 *
 * -> https://stackoverflow.com/questions/64256794/react-cssbaseline-component-doesnt-update-after-material-ui-theme-changed
 * -> https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
 * -> https://github.com/mui-org/material-ui/issues/13394
 */
ReactDOM.render(
  process.env.REACT_APP_SENTRY_URL ? <AppWithWrappersAndSentry /> : <AppWithWrappers />,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
