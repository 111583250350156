import { UrlPath } from '../types/Enums';
import { PriceRangeEnum } from '../views/Discover/components/ActiveMarkets/types';

export const makeUrlSafe = (wineName: string) => {
  return wineName
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading/trailing spaces
    .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-'); // Replace spaces with hyphens
};

export const getAssetIdFromUrl = (url: string) => {
  const urlParts = url.split('_');
  return urlParts[0];
};

interface ProductType {
  assetId: number;
  wineName: string;
  vintage: number;
  unitCount?: number;
  unitSize?: string;
}

export const getProductUrl = <T>(asset: T) => {
  const { assetId, wineName, vintage, unitCount, unitSize } = asset as unknown as ProductType;

  const unitsSizeStr = unitCount ? `${unitCount}x${unitSize}` : '';
  const safeWineName = makeUrlSafe(wineName);
  const pageSlug = `${assetId}_${safeWineName}_${vintage}_${unitsSizeStr}`;
  return `${UrlPath.PRODUCT_PAGE}/${pageSlug}`;
};

export const getPriceRange = (price: number | null) => {
  if (!price) return null;
  if (price < 500) return PriceRangeEnum.Under500;
  if (price < 1000) return PriceRangeEnum.Under1000;
  if (price >= 1000 && price < 2000) return PriceRangeEnum.Range_1k_2k;
  if (price >= 2000 && price < 5000) return PriceRangeEnum.Range_2k_5k;
  if (price >= 5000 && price < 10000) return PriceRangeEnum.Range_5k_10k;
  if (price >= 10000) return PriceRangeEnum.Range_10k_Plus;
  return null;
};
