import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import WatchlistAssets from '../../components/WatchlistAssets';
import useTableControls from '../../hooks/useTableControls';
import { useWatchlistItems } from '../../hooks/useWatchlistItems';
import { getPage } from '../../utils/getPage';
import { selectError, selectWatchlistItems, WatchlistAsset, WatchlistFacets } from './selectors';
import { FilterConfigType, FiltersType } from '../../components/Filters';
import { applyFilters } from '../../utils/applyFilters';
import { sortItems } from '../../utils/sortFunction';
import { SortOverides } from './types';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gap: theme.spacing(4),
    },
  },
  searchResults: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      minWidth: '75%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const SORT_OVERRIDES: SortOverides<WatchlistAsset>[] = [
  {
    sortKey: 'bestOffer',
    customSortFunction: (arrayToSort) => {
      return arrayToSort.sort((a, b) => {
        if (!a.bestOffer) {
          return 1;
        }
        if (!b.bestOffer) {
          return -1;
        }

        return a.bestOffer - b.bestOffer;
      });
    },
  },
  {
    sortKey: 'bestBid',
    customSortFunction: (arrayToSort) => {
      return arrayToSort.sort((a, b) => {
        if (!a.bestBid) {
          return 1;
        }
        if (!b.bestBid) {
          return -1;
        }
        return b.bestBid - a.bestBid;
      });
    },
  },
];

const PAGE_SIZE_VARIANTS = [20, 60, 90];

export default function Watchlist(): JSX.Element {
  const classes = useStyles();
  const { loading, error, watchlistItems: watchlistItemsRaw } = useWatchlistItems();
  const { handleChangePage, handleChangeResultsPerPage, handleSortUpdated, page, pageSize, sortDirection, sortFacet } =
    useTableControls<WatchlistFacets>({
      defaultFacet: 'bestOffer',
      pageSizeVariants: PAGE_SIZE_VARIANTS,
    });
  const [filterConfig, setFilterConfig] = React.useState<FilterConfigType<WatchlistAsset>>({
    isFiltered: false,
    showSortResult: false,
    filters: {} as FiltersType<WatchlistAsset>,
  });

  const assets = React.useMemo(() => {
    const allDataSource = selectWatchlistItems(watchlistItemsRaw);
    let newSource = [...allDataSource];

    if (filterConfig.isFiltered || filterConfig.showSortResult) {
      const { filteredDataSource } = applyFilters(allDataSource, filterConfig.filters);
      newSource = [...filteredDataSource];
    }

    const sortConfigData = SORT_OVERRIDES.find((x) => x.sortKey === sortFacet);
    if (sortConfigData && sortConfigData?.customSortFunction) {
      newSource = sortConfigData?.customSortFunction(newSource);
    } else {
      newSource = [...sortItems(newSource, sortDirection === 'asc', sortFacet as unknown as keyof WatchlistAsset)];
    }
    return newSource.slice(...getPage(page, pageSize));
  }, [
    watchlistItemsRaw,
    sortFacet,
    sortDirection,
    page,
    pageSize,
    filterConfig.filters,
    filterConfig.isFiltered,
    filterConfig.showSortResult,
  ]);

  const allAssets = React.useMemo(() => selectWatchlistItems(watchlistItemsRaw), [watchlistItemsRaw]);

  return (
    <div className={classes.container}>
      <WatchlistAssets
        allResults={allAssets}
        setFilterConfig={setFilterConfig}
        filterConfig={filterConfig}
        className={classes.searchResults}
        results={assets}
        resultsCount={watchlistItemsRaw.length}
        loading={loading}
        error={selectError(error)}
        page={page}
        pageSizeVariants={PAGE_SIZE_VARIANTS}
        rowsPerPage={pageSize}
        onChangePage={handleChangePage}
        onRowsPerPageChanged={handleChangeResultsPerPage}
        activeSortKey={sortFacet}
        onSortChanged={handleSortUpdated}
        sortDirection={sortDirection}
      />
    </div>
  );
}
