import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Typography from '../../components/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(15),
    flexFlow: 'column nowrap',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  link: {
    fontSize: '2em',
    marginTop: theme.spacing(20),
  },
}));

export default function RedirectToMobileApp(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Typography variant="h4">{t('common:redirect.hint')}</Typography>
      <Divider />
      <a className={classes.link} href={`cultx://${window.location.href}`}>
        {t('common:redirect.text')}
      </a>
    </div>
  );
}
