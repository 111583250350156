import { RegionName } from '../types/RegionName';

/**
 * Takes a string, which can be any region name (e.g. Alsace, Australia, Bordeaux), and maps it
 * to a 'Zellar' region name. Any which do not directly map to a 'Zellar' region name, are mapped
 * to 'rest-of-world'.
 * @param rawName The true region name
 */
export default function mapRegionName(rawName: string): RegionName {
  switch (rawName.toLowerCase()) {
    case 'burgundy': {
      return 'burgundy';
    }

    case 'bordeaux': {
      return 'bordeaux';
    }

    case 'champagne': {
      return 'champagne';
    }

    case 'italy': {
      return 'italy';
    }

    case 'rhone': {
      return 'rhone';
    }

    case 'usa': {
      return 'usa';
    }

    default: {
      return 'rest-of-world';
    }
  }
}
