import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  backdrop: {
    /**
     * This ensures that the backdrop sits over the top
     * of any images and charts that are on the screen,
     * which by default the backdrop did not cover.
     */
    zIndex: theme.zIndex.drawer + 1,
  },
  popover: {
    /**
     * Make the popover background disappear and
     * blend in, which makes it like the buttons
     * are 'floating', as per mockups.
     */
    background: 'none',
    boxShadow: 'none',
    width: '128px',
  },
  largeButton: {
    padding: '12px 0px',
  },
  smallButton: {
    padding: '6px 0px',
  },
  condensed: {
    margin: 0,
    width: 36,
    height: 36,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.button.primary.background.hover,
    },
  },
  iconButton: {
    minWidth: 0,
  },
  smallIconButton: {
    padding: '6px',
  },
  largeIconButton: {
    padding: '12px',
  },
  largeAnchorButton: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: theme.spacing(16),
    minWidth: 160,
  },
  smallAnchorButton: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: theme.spacing(2),
    minWidth: 160,
  },
  anchorButtonInner: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  label: {
    gap: 'inherit',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  tradeArrows: {
    width: '24px',
    height: '24px',
    stroke: theme.palette.primary.contrastText,
  },
  downArrow: {
    width: '11px',
    height: '7px',
  },
  iconButtonRoot: {
    '&:disabled': {
      backgroundColor: theme.palette.button.primary.background.disabled,
    },
  },
}));
