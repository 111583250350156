/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-this-in-sfc */
import { useTheme } from '@mui/material/styles';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { ChartType } from '../../types/ChartType';
import { HighchartsChart } from '../../types/Highcharts';
import Tooltip from './Tooltip';
import { dateFormatDisplayMonthAndYear } from '../../utils/dateFormatter';
import Logo from '../../assets/images/logo-grey.svg';
import calculateWatermarkPosition from '../../utils/calculateWatermarkPosition';

interface Props {
  data: Highcharts.SeriesAreasplineOptions['data'];
  title: string;
  min: number;
  max: number;
  loading: boolean;
  chartType: ChartType;
}

export default function SeriesLineChart({ data, title, min, max, loading, chartType }: Props): JSX.Element {
  const [options, setOptions] = useState<Highcharts.Options | null>(null);
  const theme = useTheme();
  const chartRef = React.createRef<{ chart: HighchartsChart; container: React.RefObject<HTMLDivElement> }>();
  const { t } = useTranslation();

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (loading) {
      chart?.hideNoData();
      chart?.showLoading();
    } else {
      chart?.hideLoading();
    }
  }, [loading, chartRef]);

  useEffect(() => {
    let watermark: Highcharts.SVGElement | null = null;

    setOptions({
      loading: {
        // wait for 333ms before showing loading state.
        showDuration: 333,
      },
      series: [
        {
          type: 'spline',
          data,
          color: theme.palette.chart1.main,
        },
      ],
      chart: {
        events: {
          load() {
            const chart = this;
            watermark = chart.renderer.image(Logo).attr(calculateWatermarkPosition(chart)).add();
          },
          render() {
            const chart = this;
            watermark?.attr(calculateWatermarkPosition(chart));
          },
        },
      },
      plotOptions: {
        spline: {
          lineWidth: 3,
        },
      },
      yAxis: {
        min: chartType === ChartType.Absolute ? 0 : min,
        max,
        title: {
          text: title,
          style: {
            color: theme.palette.label,
          },
        },
        labels: {
          formatter:
            chartType === ChartType.Absolute
              ? undefined
              : function formatLabels() {
                  return this.value < 0 ? `${this.value}%` : `+${this.value}%`;
                },
          style: {
            color: theme.palette.label,
          },
        },
        gridLineDashStyle: 'Dash',
        startOnTick: false,
      },
      xAxis: {
        tickWidth: 0,
        labels: {
          y: 32,
          style: {
            textTransform: 'uppercase',
            color: theme.palette.label,
            fontSize: theme.typography.body3Bold.fontSize?.toString(),
            fontWeight: theme.typography.body3Bold.fontWeight?.toString(),
          },
          formatter() {
            return dateFormatDisplayMonthAndYear(new Date(this.value));
          },
        },
      },
      lang: {
        noData: t('product:noData'),
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: theme.palette.textPrimary,
        },
      },
      tooltip: {
        useHTML: true,
        borderColor: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
        style: {
          color: theme.palette.text.primary,
          textAlign: 'center',
        },
        formatter() {
          return renderToString(
            <Tooltip unit={chartType === ChartType.Absolute ? 'currency' : 'percentage'} x={this.x} y={this.y} />,
          );
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, title, theme, max, min]);

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />;
}
