import { ApolloCache, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { ROOT_QUERY } from '../graphql';
import { UPDATE_NOTIFICATION } from '../graphql/mutations/updateNotification';
import { UpdateNotificationMutation, UpdateNotificationMutationVariables } from '../__generated__/graphql';

interface MutationResponse {
  data: UpdateNotificationMutation | null | undefined;
  errors: readonly GraphQLError[] | undefined;
}

type CallbackFunction = (params: UpdateNotificationMutationVariables) => Promise<MutationResponse>;

export const useUpdateNotification = (): CallbackFunction => {
  const [updateNotificationMutation, { loading }] = useMutation(UPDATE_NOTIFICATION, {
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });
  const updateNotification = useCallback(
    async (variables: UpdateNotificationMutationVariables) => {
      const { data, errors } = await updateNotificationMutation({
        variables,
        update: (cache: ApolloCache<UpdateNotificationMutation>, { data: responseData }) => {
          if (responseData?.updateNotification) {
            // https://www.apollographql.com/docs/react/caching/cache-interaction/#obtaining-an-objects-cache-id
            // Update unreadCount - shown on badge
            const id = cache.identify({ ...responseData.updateNotification });
            // https://www.apollographql.com/docs/react/caching/cache-interaction/#using-cachemodify
            cache.modify({
              id: ROOT_QUERY,
              fields: {
                userNotifications(userNotifications) {
                  return { ...userNotifications, unreadCount: userNotifications.unreadCount - 1 };
                },
              },
            });
            // update isRead to true - shown in list of notifications
            cache.modify({
              id,
              fields: {
                isRead() {
                  return true;
                },
              },
            });
          }
        },
      });
      return { data, errors, loading };
    },
    [updateNotificationMutation, loading],
  );
  return updateNotification;
};
