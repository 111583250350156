import { InMemoryCache, makeVar } from '@apollo/client';
import { getAccessToken, getRefreshToken } from '../services/auth';

export const isLoggedInVar = makeVar<boolean>(Boolean(getAccessToken()) && Boolean(getRefreshToken()));
export const appIsWorkingVar = makeVar<boolean>(false);
export const registerRedirectVar = makeVar<{ redirectMessage: string; redirectSuccess: boolean } | null>(null);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        appIsWorking: {
          read() {
            return appIsWorkingVar();
          },
        },
        registerRedirect: {
          read() {
            return registerRedirectVar();
          },
        },
      },
    },
    // ActiveMarket: {
    //   keyFields: ['assetId'],
    // },
    WineVintageResult: {
      keyFields: ['defaultAssetId'],
    },
  },
});
