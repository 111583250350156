import React from 'react';
import { Button, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from '../PaymentCardModal/types';
import Checkbox from '../Checkbox';
import Typography from '../Typography';
import IconButton from '../IconButton';
import { ReactComponent as PaymentMastercard } from '../../assets/icons/payment-mastercard.svg';
import { ReactComponent as PaymentVisa } from '../../assets/icons/payment-visa.svg';
import { ReactComponent as Trash } from '../../assets/images/trash.svg';
import { ReactComponent as CheckMark } from '../../assets/icons/checkmark-yellow.svg';
import { useStyles } from './styles';
import { CardDetailResponse } from '../../__generated__/graphql';

interface PaymentCardProps {
  className?: string;
  data: CardDetailResponse;
  showCheckBox?: boolean;
  checked?: boolean;
  onChecked?: (item: CardDetailResponse) => void;
  onDeleted?: (item: CardDetailResponse) => void;
  onManageCardClicked?: (item: CardDetailResponse) => void;
}

export default function PaymentCard({
  className,
  data,
  showCheckBox,
  onManageCardClicked,
  checked,
  onChecked,
  onDeleted,
}: PaymentCardProps): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const { merchantInitiatedTransaction12341 } = useFlags();
  const { t } = useTranslation();

  return (
    <div
      data-testid="details-item"
      className={`${classes.detailsItem} ${className} ${checked ? classes.selectedItem : ''}`}
    >
      <div className={classes.columnBetween} style={{ gap: theme.spacing(2) }}>
        {data.scheme === PaymentMethod.VISA ? <PaymentVisa /> : <PaymentMastercard />}
        <Typography className={classes.clientName} customVariant="body1" data-private>
          {data.clientName}
        </Typography>
        <Typography customVariant="body1" zellarColour="textLabel" data-private>
          &sdot;&sdot;&sdot; &sdot;&sdot;&sdot; &sdot;&sdot;&sdot; {data.last4}
        </Typography>
        <Typography customVariant="caption" zellarColour="textInformation" data-private>
          {data.expiry}
        </Typography>
      </div>
      <div className={classes.rightContainer}>
        {showCheckBox && (
          <Checkbox
            inputProps={{ 'aria-label': 'details-item-checkbox' }}
            checked={checked}
            color="primary"
            onClick={() => onChecked?.(data)}
          />
        )}
        {merchantInitiatedTransaction12341 && onManageCardClicked ? (
          <Button className={classes.manageCard} onClick={() => onManageCardClicked(data)}>
            {t('wallet:manageCard')}
          </Button>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {onDeleted && (
              <IconButton onClick={() => onDeleted?.(data)} size="large">
                <Trash />
              </IconButton>
            )}
          </>
        )}
        {merchantInitiatedTransaction12341 && data.mitConsentedFlag && (
          <Typography className={classes.mit} customVariant="body2" data-private>
            {t('wallet:payByCard.mitEnabled')} <CheckMark />
            <CheckMark className={classes.checkMark} />
          </Typography>
        )}
      </div>
    </div>
  );
}
