/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '@cultwines/zellar-client-sdk';
import { useHistory } from 'react-router-dom';
import TableCellHead from '../TableElements/TableCellHead';
import RowSkeleton from '../TableElements/RowSkeleton';
import ErrorPlaceholder from '../ErrorPlaceholder';
import EmptyPlaceholder from '../EmptyPlaceholder';
import BasicCell from '../Table/Cells/Basic';
import WineNameCell from '../Table/Cells/WineName';
import UnitSizeCell from '../Table/Cells/UnitSize';
import { humanReadableDate } from '../../utils/humanReadableDate';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { RecentTradesFacets, Trade } from './types';
import { getProductUrl } from '../../utils/common';

interface TableProps {
  trades: Trade[];
  loading?: boolean;
  error?: string;
  perPage?: number;
  sortDirection: SortDirection;
  sortColumn: keyof RecentTradesFacets;
  onSortChanged: (key: keyof RecentTradesFacets) => void;
}

export default function TradeHistoryTable({
  trades,
  loading,
  error,
  perPage,
  sortColumn,
  sortDirection,
  onSortChanged,
}: TableProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const columnCount = 6;

  return (
    <TableContainer>
      <Table aria-label="active markets table">
        <TableHead>
          <TableRow>
            <TableCellHead
              sortActive={sortColumn === 'activity'}
              sortDirection={sortColumn === 'activity' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('activity')}
              title={t('trade:activity')}
            />
            <TableCellHead
              sortActive={sortColumn === 'wineName'}
              sortDirection={sortColumn === 'wineName' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('wineName')}
              title={t('market:table.wineName')}
            />
            <TableCellHead
              sortActive={sortColumn === 'vintage'}
              sortDirection={sortColumn === 'vintage' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('vintage')}
              title={t('common:vintage')}
            />
            <TableCellHead title={t('common:unitSize')} hideSortButton />
            <TableCellHead
              sortActive={sortColumn === 'tradeDate'}
              sortDirection={sortColumn === 'tradeDate' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('tradeDate')}
              title={t('trade:tradeDate')}
            />
            <TableCellHead
              sortActive={sortColumn === 'tradePrice'}
              sortDirection={sortColumn === 'tradePrice' ? sortDirection : undefined}
              onSortButtonClicked={() => onSortChanged('tradePrice')}
              title={t('trade:tradePrice')}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <RowSkeleton columnCount={columnCount} rowCount={perPage} />
          ) : error ? (
            <TableCell align="center" colSpan={columnCount}>
              <ErrorPlaceholder error={error} />
            </TableCell>
          ) : !trades.length ? (
            <TableCell align="center" colSpan={columnCount}>
              <EmptyPlaceholder
                label={t('portfolio:noWines')}
                ActionButton={
                  <Button variant="contained" color="primary" size="large" onClick={() => history.push('/discover')}>
                    {t('portfolio:addWines')}
                  </Button>
                }
              />
            </TableCell>
          ) : (
            trades.map((trade) => {
              const { wineName, vintage, assetId, unitSize, unitCount } = trade;
              const prodUrl = getProductUrl({ wineName, vintage, assetId, unitSize, unitCount });

              return (
                <TableRow key={trade.id}>
                  <BasicCell
                    mainContent={
                      trade.activity === 'bid'
                        ? t('portfolio:tradeHistoryTable.bid')
                        : t('portfolio:tradeHistoryTable.offer')
                    }
                  />
                  <WineNameCell
                    wineName={trade.wineName}
                    appellation={trade.appellation}
                    link={`${prodUrl}`}
                    region={trade.region}
                  />
                  <BasicCell mainContent={trade.vintage} />
                  <UnitSizeCell unitCount={trade.unitCount} unitSize={trade.unitSize} />
                  <BasicCell mainContent={humanReadableDate(trade.tradeDate)} />
                  <BasicCell mainContent={formatterWholeNumber.format(trade.tradePrice)} />
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
