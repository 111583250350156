import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { ReactComponent as SmartInvestingIcon } from '../../assets/icons/registration-smart-investing.svg';
import { ReactComponent as DiscoveryIcon } from '../../assets/icons/registration-discovery.svg';
import { ReactComponent as TrustIcon } from '../../assets/icons/registration-trust.svg';

const useStyles = makeStyles((theme) => ({
  marketingWidget: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  icon: {
    height: '50px',
    minWidth: '50px',
  },
  sellingPointHeader: {
    color: '#FB9D0E',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: 5,
  },

  sellingPointDescription: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
}));

interface Props {
  title: string;
  text: string;
}

export const infoIcon = (title: string): JSX.Element | null => {
  switch (title) {
    case 'Smart investing':
      return <SmartInvestingIcon />;
    case 'Discovery':
      return <DiscoveryIcon />;
    case 'Trust':
      return <TrustIcon />;
    default:
      return null;
  }
};

export default function SellingPoint({ title, text }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.marketingWidget}>
      <div className={classes.icon}>{infoIcon(title)}</div>
      <div>
        <Typography className={classes.sellingPointHeader} variant="h3">
          {title}
        </Typography>
        <Typography className={classes.sellingPointDescription} variant="body1">
          {text}
        </Typography>
      </div>
    </div>
  );
}
