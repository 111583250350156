/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloError, useQuery, useReactiveVar, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { isLoggedInVar } from '../graphql/cache';
import { GET_USER_NOTIFICATIONS } from '../graphql/queries/userNotifications';
import { Notification, UserNotificationsQueryVariables } from '../__generated__/graphql';
import { graphql } from '../__generated__';
import { logError } from '../utils/logger';

interface HookResult {
  notifications: Notification[] | null;
  unreadCount: number;
  totalPages: number;
  from: number;
}
interface UseNotificationsHookResponse {
  result: HookResult;
  error: ApolloError | undefined;
  loading: boolean;
}

const NOTIFICATIONS_SUBSCRIPTIONS = graphql(`
  subscription GetNotification {
    getNotification {
      id
      summary
      description
      isRead
      pushData
      createdDateTime
      updatedDateTime
      assetId
    }
  }
`);

export const useNotifications = ({ from, pageSize }: UserNotificationsQueryVariables): UseNotificationsHookResponse => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const { loading, data, error } = useQuery(GET_USER_NOTIFICATIONS, {
    variables: { from, pageSize },
    skip: !isLoggedIn,
  });
  const { data: subscriptionData } = useSubscription(NOTIFICATIONS_SUBSCRIPTIONS, {
    onError: (e) =>
      logError({
        error: new Error('NOTIFICATIONS_SUBSCRIPTIONS failed'),
        filename: 'useNotifications',
        originalError: e,
      }),
  });
  const [unreadCount, setUnreadCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [fromRecord, setFromRecord] = useState(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notifiFromSocket, setNotifFromSocket] = useState<Notification | null>(null);

  useEffect(() => {
    if (subscriptionData) {
      setNotifFromSocket(subscriptionData.getNotification as Notification);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (!notifiFromSocket) {
      return;
    }
    setUnreadCount(unreadCount + 1);
    setNotifications([notifiFromSocket, ...notifications]);

    // https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/5910/
    // FIXME: not fully clear if this is working or not
    // and won't be clear until the notifications received
    // from the socket will be persisten in DB
    // Currently marking the notification as read
    // that is received from the socket returns
    // a BE error as that notification does not exist in the database
    // client.writeQuery({
    //   query: GET_USER_NOTIFICATIONS,
    //   data: {
    //     userNotifications: {
    //       ...data!.userNotifications,
    //       results: [notifiFromSocket, ...data!.userNotifications.results],
    //     },
    //   },
    // });
  }, [notifiFromSocket]);

  useEffect(() => {
    if (!data?.userNotifications) {
      return;
    }
    setUnreadCount(data.userNotifications.unreadCount);
    setFromRecord(data.userNotifications.from);
    setTotalPages(data.userNotifications.totalPages);

    if (notifications.length) {
      // Update state from cache
      const stateCopy = [...notifications];
      // Remove old items from state array
      stateCopy.splice(from, pageSize);
      // and replace them with new ones from data
      stateCopy.splice(from, 0, ...(data.userNotifications.results as Notification[]));
      setNotifications(stateCopy);
    } else {
      setNotifications(data.userNotifications.results as Notification[]);
    }
  }, [data]);
  const result = { notifications, totalPages, from: fromRecord, unreadCount };
  return { result, loading, error };
};
