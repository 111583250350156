import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RRLink, useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { useSignOut } from '../../hooks/useSignOut';
import { NavigationPathEnum } from '../../types';
import LogoutModel from '../LogoutModel';

interface UserOptionsMenuProps extends MenuProps {
  optionSelected: () => void;
}

export default function UserOptionsMenu({ optionSelected, ...props }: UserOptionsMenuProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const signOut = useSignOut();
  const [showlogoutPopUp, setShowLogoutPopUp] = React.useState(false);

  // https://www.apollographql.com/docs/tutorial/local-state/#enable-logout
  async function handleSignOut(): Promise<void> {
    // inform parent component that a menu option was clicked,
    // so that it knows to close the menu
    optionSelected();

    await signOut();
    setShowLogoutPopUp(true);
  }

  function handleClick(): void {
    optionSelected();
  }

  return (
    <>
      <Menu className={classes.container} {...props}>
        <MenuItem onClick={handleClick} component={RRLink} to={NavigationPathEnum.WALLET}>
          <Typography className={classes.menuItem} variant="body1">
            {t('account:userOptionsMenu.myWallet')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleClick} component={RRLink} to={NavigationPathEnum.MY_ACCOUNT}>
          <Typography className={classes.menuItem} variant="body1">
            {t('account:userOptionsMenu.myAccountSettings')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleSignOut} className={classes.button}>
          <Typography variant="subtitle1">{t('common:signOut')}</Typography>
        </MenuItem>
      </Menu>

      <LogoutModel
        isOpen={showlogoutPopUp}
        onClose={() => setShowLogoutPopUp(false)}
        t={t}
        onCta={() => {
          history.push('/login');
        }}
      />
    </>
  );
}
