import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import Typography from '../../components/Typography';
import IconButton from '../../components/IconButton';

const useStyles = makeStyles((theme) => ({
  controls: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginBottom: '10px',
    gap: theme.spacing(2),
  },
  leftArrowIconButton: {
    borderRadius: 8,
    width: 28,
    height: 28,
    backgroundColor: theme.palette.button.tertiary.background.main,

    '&:hover': {
      background: theme.palette.button.tertiary.background.hover,
    },

    '&:disabled': {
      background: theme.palette.button.tertiary.background.disabled,
    },

    '&:focus': {
      backgroundColor: theme.palette.secondary.contrastText,
    },
  },
  rightArrowIconButton: {
    stroke: theme.palette.background.default,
    borderRadius: 8,
    width: 28,
    height: 28,
    backgroundColor: theme.palette.button.tertiary.background.main,

    '&:hover': {
      background: theme.palette.button.tertiary.background.hover,
    },

    '&:disabled': {
      background: theme.palette.button.tertiary.background.disabled,
    },

    '&:focus': {
      backgroundColor: theme.palette.secondary.contrastText,
    },
  },
  clearText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

interface ControlsProps {
  handleClear?: () => void;
  handleBackClick: () => void;
  handleForwardClick: () => void;
  page?: number;
  pageCount?: number;
  showCount?: boolean;
  leftArrowClassName?: string;
  rightArrowClassName?: string;
}

export default function Controls({
  handleBackClick,
  handleClear,
  handleForwardClick,
  page,
  pageCount,
  showCount = true,
  leftArrowClassName,
  rightArrowClassName,
}: ControlsProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.controls}>
      {showCount && (
        <Typography className={classes.clearText} customVariant="body2Bold" onClick={handleClear}>
          {t('market:clear')}
        </Typography>
      )}
      <IconButton
        className={`${classes.leftArrowIconButton} ${leftArrowClassName}`}
        onClick={handleBackClick}
        disabled={page === 1}
        size="large"
      >
        <ChevronLeft />
      </IconButton>
      {showCount && (
        <Typography customVariant="body1">
          {page} of {pageCount}
        </Typography>
      )}
      <IconButton
        className={`${classes.rightArrowIconButton} ${rightArrowClassName}`}
        onClick={handleForwardClick}
        disabled={page === pageCount}
        size="large"
      >
        <ChevronRight />
      </IconButton>
    </div>
  );
}
