import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    width: '100%',
  },
  fieldsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(5),
    marginTop: 16,
    justifyContent: 'space-between',
  },
  fieldContainer: {
    minWidth: 200,
  },
  field: {
    width: '100%',
  },
  fieldLabel: {
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '60%',
    alignSelf: 'center',
  },
  costBreakdown: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  expirationDateSelect: {
    height: theme.spacing(8),
    border: `1px solid ${theme.palette.navigation.background.default.main}`,
    width: '200px',
    fontSize: theme.typography.body1Bold.fontSize,
    fontWeight: theme.typography.body1Bold.fontWeight,
    lineHeight: theme.typography.body1Bold.lineHeight,

    [theme.breakpoints.down('md')]: {
      width: '200px',
    },
  },
  checkboxContainer: {
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(1),
  },
  checkbox: {
    color: theme.palette.dividers.secondary,
  },
  alert: {
    marginTop: theme.spacing(2),
    width: '100%',
    fontWeight: 700,
  },
  bold: {
    fontWeight: 700,
  },
  priceInputContainer: {
    width: '100%',
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
  },
  disclaimerText: {
    color: '#8A809A',
    fontSize: '10px',
    lineHeight: '12px',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  linkText: {
    color: '#FB9D0E',
  },
}));

export { useStyles };
