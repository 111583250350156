import React from 'react';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Carousel from '../Carousel';
import Card, { EmptyAssetTileCard as EmptyCard } from '../AssetTileCard';
import { MULTIPLE_ASSETS_WITH_HOLDINGS } from './queries';
import { selectNormalizedTableData } from './selectors';
import Column from './Column';
import ColumnSkeleton from './ColumnSkeleton';
import Typography from '../Typography';
import { AssetColours } from '../../hooks/useColourMap';
import CalculationToolTip from '../CalculationToolTip';
import { logError } from '../../utils/logger';

const useStyles = makeStyles((theme) => ({
  headers: {
    minWidth: 100,
    marginRight: 10,
  },
  header: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  card: {
    height: 200,
  },
  quarter: {
    width: '25%',
  },
  half: {
    width: '50%',
  },
  full: {
    width: '100%',
  },
  greyBackground: {
    background: theme.palette.grey[50],
  },
  container: {
    height: 800,
    position: 'relative',
    display: 'flex',
    marginTop: '10px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  spacer: {
    height: 200,
  },
}));

interface ChartTabProps {
  assetIds: string[];
  carouselPagesCount: 2 | 4;
  visibleColumnIndex: number;
  columnCount: number;
  coloursByAssetId: AssetColours;
  onAddClicked: () => void;
  onRemoveClicked: (assetId: string) => void;
}

export default function TableTab({
  assetIds,
  carouselPagesCount,
  visibleColumnIndex,
  coloursByAssetId,
  columnCount,
  onAddClicked,
  onRemoveClicked,
}: ChartTabProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const {
    data,
    loading,
    previousData,
    error: productAssetsQueryError,
  } = useQuery(MULTIPLE_ASSETS_WITH_HOLDINGS, {
    variables: {
      assetIds: assetIds.map((id) => Number(id)),
    },
    errorPolicy: 'all', // otherwise data is undefined in event of any errors.
  });
  const aggregatedData = data || previousData;
  const tableData = React.useMemo(() => selectNormalizedTableData(aggregatedData), [aggregatedData]);

  if (productAssetsQueryError) {
    logError({
      originalError: productAssetsQueryError,
      error: new Error('Failed to load product assets'),
      filename: 'MarketComparisonDetailsTable',
    });
  }

  const WrappedColumn = React.useCallback(
    ({ index, assetId }) => {
      if (assetId && tableData[index]) {
        return (
          <Column
            className={clsx({ [classes.greyBackground]: index % 2 === 0 })}
            assetId={Number(assetId)}
            data={tableData.find((td) => td.assetId === Number(assetId))!}
          />
        );
      }
      if (loading) {
        return <ColumnSkeleton />;
      }

      // We don't actually need to render anything as the place holder.
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    },
    [classes.greyBackground, loading, tableData],
  );

  const columnMap = React.useCallback(
    (section: number, column: number): JSX.Element => {
      const index = section === 0 ? column : column + columnCount * section;
      const assetId = assetIds[index];
      return !isUndefined(assetId) ? (
        <div
          className={clsx({
            [classes.column]: true,
            [classes.half]: carouselPagesCount === 4,
            [classes.quarter]: carouselPagesCount === 2,
          })}
        >
          <Card
            className={clsx({
              [classes.full]: true,
              [classes.greyBackground]: index % 2 === 0,
              [classes.card]: true,
            })}
            cardsPerPage={carouselPagesCount === 2 ? 4 : 2}
            colour={coloursByAssetId[assetId] ?? theme.palette.grey[200]}
            key={index}
            assetId={Number(assetId)}
            onRemoveClicked={() => onRemoveClicked(assetId)}
          />
          <WrappedColumn index={index} assetId={assetId} />
        </div>
      ) : (
        <EmptyCard
          className={clsx({
            [classes.half]: carouselPagesCount === 4,
            [classes.quarter]: carouselPagesCount === 2,
            [classes.greyBackground]: index % 2 === 0,
            [classes.card]: true,
          })}
          borderColour={coloursByAssetId[assetId] ?? theme.palette.grey[200]}
          onAddClicked={onAddClicked}
        />
      );
    },
    [
      columnCount,
      assetIds,
      classes.column,
      classes.half,
      classes.quarter,
      classes.full,
      classes.greyBackground,
      classes.card,
      carouselPagesCount,
      coloursByAssetId,
      theme.palette.grey,
      WrappedColumn,
      onAddClicked,
      onRemoveClicked,
    ],
  );

  const Columns = React.useMemo(() => {
    if (carouselPagesCount === 2) {
      return Array(2)
        .fill(0)
        .map((_, page) => (
          <>
            {Array(4)
              .fill(0)
              .map((__, column) => columnMap(page, column))}
          </>
        ));
    }

    return Array(4)
      .fill(0)
      .map((_, page) => (
        <>
          {Array(2)
            .fill(0)
            .map((__, column) => columnMap(page, column))}
        </>
      ));
  }, [columnMap, carouselPagesCount]);

  return (
    <div className={classes.container}>
      <div className={classes.headers}>
        <div className={classes.spacer} />
        <div className={classes.header}>
          <Typography customVariant="body2" zellarColour="textLabel" className={classes.uppercase}>
            {t('product:marketValue')}
            <CalculationToolTip title={t('market:marketValueCalculation')} />
          </Typography>
        </div>
        <div className={classes.header}>
          <Typography customVariant="body2" zellarColour="textLabel" className={classes.uppercase}>
            {t('market:performanceLTM')}
          </Typography>
        </div>
        <div className={classes.header}>
          <Typography customVariant="body2" zellarColour="textLabel" className={classes.uppercase}>
            {t('search:labels.liquidityScore')}
            <CalculationToolTip title={t('portfolio:liquidityScoreCalculation')} />
          </Typography>
        </div>
        <div className={classes.header}>
          <Typography customVariant="body2" zellarColour="textLabel" className={classes.uppercase}>
            {t('product:score')}
          </Typography>
        </div>
        <div className={classes.header}>
          <Typography customVariant="body2" zellarColour="textLabel" className={classes.uppercase}>
            {t('portfolio:table.headers.drinkingWindow')}
          </Typography>
        </div>
        <div className={classes.header}>
          <Typography customVariant="body2" zellarColour="textLabel" className={classes.uppercase}>
            {t('product:trading.highestBid')}
          </Typography>
        </div>
        <div className={classes.header}>
          <Typography customVariant="body2" zellarColour="textLabel" className={classes.uppercase}>
            {t('product:trading.lowestOffer')}
          </Typography>
        </div>
        <div className={classes.header}>
          <Typography customVariant="body2" zellarColour="textLabel" className={classes.uppercase}>
            {t('market:lastTraded')}
          </Typography>
        </div>
      </div>
      <Carousel columns={Columns} visibleColumnIndex={visibleColumnIndex} percentage={100} />
    </div>
  );
}
