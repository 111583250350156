import { ApolloError } from '@apollo/client';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MyOfferOrdersFacets, MyOrder, MyOrdersFacets } from '../../graphql/selectors/selectMyOrders';
import Table from '../Table';
import { Headers, SortDirection } from '../Table/types';
import { MyOffersTableData, selectMyOffersTableData } from './selectors';
import CalculationToolTip from '../CalculationToolTip';

interface MyOffersTableProps {
  assets: MyOrder[];
  loading: boolean;
  error?: ApolloError;
  activeSortKey: keyof MyOrdersFacets;
  sortDirection: SortDirection;
  handleEdit: (rowId: string) => void;
  handleCancel: (rowId: string) => void;
  onSortChanged: (key: keyof MyOrdersFacets, direction: SortDirection) => void;
}

export default function MyOffersTable({
  loading,
  assets,
  error,
  sortDirection,
  activeSortKey,
  onSortChanged,
  handleEdit,
  handleCancel,
}: MyOffersTableProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const tableData = selectMyOffersTableData({ assets, handleCancel, handleEdit, t });
  const common = {
    onClick: onSortChanged,
    direction: sortDirection,
  };
  const headers: Headers<Omit<MyOffersTableData, 'rowId'>, keyof MyOfferOrdersFacets> = {
    wineName: { title: t('trade:myTable.wineName'), active: activeSortKey === 'wineName', id: 'wineName', ...common },
    vintage: { title: t('common:vintage'), active: activeSortKey === 'vintage', id: 'vintage', ...common },
    date: { title: t('trade:myTable.date'), active: activeSortKey === 'date', id: 'date', ...common },
    myOffer: { title: t('trade:myTable.myOffer'), active: activeSortKey === 'price', id: 'price', ...common },
    lowestOffer: {
      title: t('trade:myTable.lowestOffer'),
      active: activeSortKey === 'lowestOffer',
      id: 'lowestOffer',
      ...common,
    },
    unitSize: { title: t('common:unitSize'), active: activeSortKey === 'unitCount', id: 'unitCount', ...common },
    expiryDate: { title: t('trade:myTable.expiryDate') },
    outstandingQuantity: {
      title: t('trade:myTable.outstandingQuantity'),
      extraElement: <CalculationToolTip title={t('trade:myTable.outstandingQuantityTooltip')} />,
    },
    orderStatus: { title: t('trade:myTable.offerStatus') },
  };

  return (
    <Table
      data={tableData}
      error={error?.message ?? null}
      loading={loading}
      headers={headers}
      emptyTableText={t('trade:myTable.emptyOffer')}
      EmptyTableAction={
        <Button variant="contained" color="primary" size="large" onClick={() => history.push('/discover')}>
          {t('common:discover')}
        </Button>
      }
    />
  );
}
