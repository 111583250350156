/* eslint-disable no-nested-ternary */
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Typography from '../Typography';
import { useAvailableBalance } from '../../hooks/useAvailableBalance';
import { formatter } from '../../utils/currencyFormatter';
import { logError } from '../../utils/logger';
import Divider from '../Divider';
import { useWalletBalance } from '../../hooks';
import { isNull } from '../../utils/isNull';
import CultXTooltip from '../CultxTooltip';
import useUserDetails from '../../hooks/useUserDetails';
import { AccountType } from '../../types/AccountType';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'inline-flex',
    gap: theme.spacing(1),
  },
  icon: {
    fill: theme.palette.card.accent,
    stroke: theme.palette.card.accent,
    width: theme.spacing(4),
    height: theme.spacing(4),
    strokeWidth: '0.5px',
  },
  container: {
    overflow: 'visible',
    padding: '25px 15px',
    boxShadow: 'none',
    color: theme.palette.textPrimary,
    backgroundColor: theme.palette.card.background.light,
    border: `1px solid ${theme.palette.card.background.dark}`,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
  },
  textWrap: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    textAlign: 'center',
  },
  pill: {
    color: theme.palette.textPrimary,
    border: `1px solid ${theme.palette.card.background.dark}`,
    padding: '5px 10px',
    borderRadius: '25px',
    textAlign: 'center',
    fontSize: theme.typography.label2Active.fontSize,
    fontWeight: theme.typography.label2Active.fontWeight,
    lineHeight: theme.typography.label2Active.lineHeight,
    marginTop: theme.spacing(4),
    maxWidth: 'max-content',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}));

interface OfferCardProps {
  className?: string;
  onError?: () => void;
  onLoadingStateChanged?: (loading: boolean) => void;
  showButton: boolean;
  setAddPaymentCardModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AvailableBalanceCard({
  className = '',
  showButton,
  onError,
  onLoadingStateChanged,
  setAddPaymentCardModalOpen,
}: OfferCardProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  function handleClick() {
    if (setAddPaymentCardModalOpen) {
      setAddPaymentCardModalOpen(true);
    }
  }

  const { userDetails } = useUserDetails();
  const isWhaleAccount = userDetails?.accountType === AccountType.Whale;
  // eslint-disable-next-line no-nested-ternary
  const tradingHeadroomTitle = userDetails?.mitConsentedFlag
    ? isWhaleAccount
      ? t('wallet:tradingHeadroomTooltipMITEnabledWhaleAccount')
      : t('wallet:tradingHeadroomTooltipMITEnabled')
    : t('wallet:tradingHeadroomTooltipMITDisabled');

  const {
    availableBalance,
    error: failedToLoadAvailableBalance,
    loading: loadingAvailableBalance,
  } = useAvailableBalance();
  const { walletBalance, error: failedToLoadWalletBalance, loading: loadingWalletBalance } = useWalletBalance();

  React.useEffect(() => {
    if (failedToLoadAvailableBalance || failedToLoadWalletBalance) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logError({ error: failedToLoadAvailableBalance || failedToLoadWalletBalance, filename: 'AvailableBalanceCard' });
      onError?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedToLoadAvailableBalance, failedToLoadWalletBalance]);

  React.useEffect(() => {
    onLoadingStateChanged?.(loadingAvailableBalance || loadingWalletBalance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAvailableBalance, loadingWalletBalance]);

  return (
    <Card className={`${classes.container} ${className}`}>
      <div className={classes.content}>
        <div className={classes.textContainer}>
          <div className={classes.textWrap}>
            <div>
              <Typography
                style={{ display: 'inline', marginRight: 3 }}
                className={classes.uppercase}
                customVariant="label2"
                zellarColour="textLabel"
              >
                {t('product:detailsModal.totalFunds')}
              </Typography>
              <CultXTooltip aria-label="fundsonaccount" title={t('wallet:fundsOnAccountTooltip')} />
            </div>
            <Typography customVariant="numberMedium">
              {loadingWalletBalance ? (
                <Skeleton />
              ) : isNull(walletBalance) ? (
                t('common:currentlyUnavailable')
              ) : (
                formatter.format(walletBalance)
              )}
            </Typography>
          </div>
          <Divider orientation="vertical" flexItem style={{ background: '#8A809A' }} />
          <div className={classes.textWrap}>
            <div>
              <Typography
                style={{ display: 'inline', marginRight: 3 }}
                className={classes.uppercase}
                customVariant="label2"
                zellarColour="textLabel"
              >
                {t('product:detailsModal.availableBalance')}
              </Typography>
              <CultXTooltip aria-label="tradingheadroom" title={tradingHeadroomTitle} />
            </div>
            <Typography customVariant="numberMedium">
              {loadingAvailableBalance ? <Skeleton /> : formatter.format(availableBalance)}
            </Typography>
          </div>
        </div>

        {showButton && (
          <Button name="action-button" onClick={handleClick} className={classes.pill}>
            {t('product:detailsModal.balanceButton')}
          </Button>
        )}
      </div>
    </Card>
  );
}
