import React from 'react';
import { useQuery } from '@apollo/client';
import WineCard from '..';
import { useSelectedRowsContext } from '../../../context/selectedRowsContext';
import { OWNED_ASSET_INSTANCES_QUERY } from '../../../graphql/queries/ownedAssetInstance';
import { OwnedAsset } from '../../../graphql/selectors/ownedAssets';
import mapRegionName from '../../../utils/mapRegionName';
import { useWineCardStyles } from '../styles';
import HoldingAssetInstances from './HoldingAssetInstances';
import useCanCreateOffer from '../../../hooks/useCanCreateOffer';
import { useLastIndexAvailableForOffer } from '../../../hooks/useLastIndexAvailableForOffer';
import { useShouldRenderMarketRoute } from '../../../hooks/useShouldRenderMarketRoute';

type CurrentHoldingsAssetCardProps = {
  asset: OwnedAsset;
  className?: string;
  onBidClicked: (rowId: string) => void;
  onOfferClicked: (rowId: string) => void;
  onCompareClicked: (rowId: string) => void;
  onBuyClicked: (rowId: string) => void;
};

export default function CurrentHoldingsAssetCard({
  className = '',
  asset: wine,
  onBidClicked,
  onOfferClicked,
  onCompareClicked,
  onBuyClicked,
}: CurrentHoldingsAssetCardProps): JSX.Element {
  const classes = useWineCardStyles({ region: mapRegionName(wine.region) });
  const { dispatch } = useSelectedRowsContext();
  const { shouldRenderMarketRoute } = useShouldRenderMarketRoute();

  const { loading, data, error } = useQuery(OWNED_ASSET_INSTANCES_QUERY, { variables: { assetId: wine.assetId } });
  const { canCreateOffer } = useCanCreateOffer({ assetId: Number(wine.assetId) });
  const { lastIndexAvailableForOffer } = useLastIndexAvailableForOffer({ assetId: wine.assetId });

  function handleTransferCheckboxClicked(_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    const assetInstances = data?.productAssetInstances
      .filter((instance, index) => !instance.transferRequested && index <= lastIndexAvailableForOffer)
      .map((instance) => ({
        type: 'AssetInstance',
        id: instance.id,
        assetId: instance.assetId,
        currentLocation: instance.location,
        purchasedPrice: instance.purchasePrice,
        wineName: wine.name,
        unitSize: `${wine.unitCount} x ${wine.unitSize}`,
        marketValue: wine.marketValue,
      }));

    if (checked) {
      const parentAsset = { type: 'ParentAsset', id: wine.assetId, assetId: wine.assetId };
      const arr = assetInstances || [];
      dispatch({ type: 'allTransferRowsChecked', payload: [...arr, parentAsset] });
    } else {
      const parentAsset = { type: 'ParentAsset', id: wine.assetId, assetId: wine.assetId };
      const assetInstanceIds = assetInstances?.map((instance) => ({ id: instance.id })) || [];
      dispatch({
        type: 'allTransferRowsUnchecked',
        payload: [...assetInstanceIds, { id: parentAsset.id.toString() }],
      });
    }
  }

  return (
    <WineCard
      cardId={wine.assetId.toString()}
      assetId={wine.assetId}
      units={wine.units}
      unitCount={wine.unitCount}
      unitSize={wine.unitSize}
      wineName={wine.name}
      performancePercentageDelta={{ value: wine.performancePercentageDelta, display1YRLabel: false }}
      region={wine.region}
      score={wine.score}
      vintage={wine.vintage}
      actions={{
        trade: {
          condensed: true,
          onBidClicked,
          onOfferClicked,
          onBuyClicked,
          assetId: wine.assetId,
        },
        compare: shouldRenderMarketRoute ? { onCompareClicked } : undefined,
        transfer: {
          onTransferClicked: handleTransferCheckboxClicked,
          transferRequested: data?.productAssetInstances.filter((instance) => !instance.transferRequested).length === 0,
          transferDisabled: !canCreateOffer || loading,
        },
      }}
      appellation={wine.appellation}
      className={className}
      drinkingWindow={wine.drinkingWindow}
      highestBid={wine.highestBid}
      lowestOffer={wine.lowestOffer}
      totalMarketValue={wine.totalMarketValue}
      totalPurchasePrice={wine.totalPurchasePrice}
      imageUrl={wine.imageFilename}
      tradeTileVariant="unit-size"
      // Removed mocked data usage, will come back after private BETA
      // https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/7079
      // activeOffers={wine.activeOffers}
      // quantityOwned={wine.quantityOwned}
      ExpandedContent={
        <HoldingAssetInstances
          data={data?.productAssetInstances}
          loading={loading}
          error={error}
          assetId={wine.assetId}
          AssetInstanceProps={{ className: `${classes.inner} ${classes.borderTop}` }}
          wineName={wine.name}
          unitCount={wine.unitCount}
          unitSize={wine.unitSize}
          marketValue={wine.marketValue}
        />
      }
    />
  );
}
