import { graphql } from '../../__generated__';

export const GET_OPEN_ORDERS_BY_ASSET_ID = graphql(`
  query GetOpenOrdersByAssetId($assetId: Int!) {
    openOrders(assetId: $assetId) {
      id
      assetId
      direction
      price
      quantity
      submittedTimeUtc
      userId
      cancelled
      outstandingQuantity
      status
      asset {
        unitSize
        unitCount
        vintage {
          vintage
          wine {
            name
          }
        }
      }
    }
  }
`);
