import makeStyles from '@mui/styles/makeStyles';

export const usePortfolioViewStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
  },
  tabPanel: {
    paddingTop: theme.spacing(6),
  },
  dividerLarge: {
    marginTop: '24px',
    marginBottom: 0,
  },
  dividerSmall: {
    marginTop: '16px',
    marginBottom: '30px',
  },
  placeholder: {
    marginTop: 40,
    textAlign: 'center',
  },
  emptyPlaceholderText: {
    marginTop: -40,
  },
  tableContainer: {
    marginTop: theme.spacing(10),
  },
  tableHeader: {
    paddingBottom: 20,
  },
}));
