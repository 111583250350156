import React from 'react';
import MuiDivider, { DividerProps as MuiDividerProps } from '@mui/material/Divider';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type DividerVariant = 'primary' | 'secondary' | 'tertiary';

const useStyles = makeStyles<Theme, { customVariant: DividerVariant }>((theme) => ({
  divider: {
    background: (props) =>
      // eslint-disable-next-line no-nested-ternary
      props.customVariant === 'primary'
        ? theme.palette.dividers.primary
        : props.customVariant === 'secondary'
        ? theme.palette.dividers.secondary
        : theme.palette.dividers.tertiary,
  },
}));

type DividerProps = {
  customVariant?: DividerVariant;
} & MuiDividerProps;

export default function Divider({ customVariant = 'primary', ...rest }: DividerProps): JSX.Element {
  const classes = useStyles({ customVariant });

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiDivider className={classes.divider} {...rest} />;
}
