import React from 'react';
import { OwnedAsset } from '../../graphql/selectors/ownedAssets';
import selectDrinkingWindowAdvice from '../../graphql/selectors/selectDrinkingWindowAdvice';
import { formatter } from '../../utils/currencyFormatter';
import {
  ActionCallback,
  BasicCell,
  DrinkingWindowCell,
  Row as RowType,
  ScoreCell,
  SparklineCell,
  WineNameCell,
  CustomCell,
  UnitSizeCell,
} from '../Table/types';
import TradingTile from '../TradingTile';
import isUndefined from '../../utils/isUndefined';
import { getProductUrl } from '../../utils/common';

export interface TableData extends RowType {
  wineName: WineNameCell;
  vintage: BasicCell;
  drinkingWindow: DrinkingWindowCell;
  score: ScoreCell;
  units: BasicCell;
  unitSize: UnitSizeCell;
  totalPurchasePrice: BasicCell;
  totalMarketValuation: BasicCell;
  performance: SparklineCell;
  highestBid: CustomCell;
  lowestOffer: CustomCell;
  asset: OwnedAsset;
}

interface SelectTableDataProps {
  handleOfferClick: ActionCallback;
  handleBidClick: ActionCallback;
  handleBuyClick: ActionCallback;
  handleCompareClick: ActionCallback;
  assets: OwnedAsset[];
  clickableTradeTiles13816?: boolean;
}

export function selectTableData({
  assets,
  handleBidClick,
  handleBuyClick,
  handleOfferClick,
  handleCompareClick,
  clickableTradeTiles13816,
}: SelectTableDataProps): TableData[] {
  return assets.map((item) => {
    const { name: wineName, vintage, assetId, unitSize, unitCount } = item;
    const prodUrl = getProductUrl({ wineName, vintage, assetId, unitSize, unitCount });
    const HighestBidTradingTile = clickableTradeTiles13816 ? (
      <TradingTile mode="bid" value={item.highestBid} onClick={() => handleBidClick(item.assetId.toString())} />
    ) : (
      <TradingTile mode="bid" value={item.highestBid} unitCount={item.unitCount} unitSize={item.unitSize} />
    );

    const LowestOfferTradingTile = clickableTradeTiles13816 ? (
      <TradingTile
        mode="offer"
        value={item.lowestOffer}
        onClick={!isUndefined(item.lowestOffer) ? () => handleBuyClick(item.assetId.toString()) : undefined}
      />
    ) : (
      <TradingTile mode="offer" value={item.lowestOffer} unitCount={item.unitCount} unitSize={item.unitSize} />
    );

    return {
      wineName: {
        appellation: item.appellation,
        region: item.region,
        wineName: item.name,
        link: `${prodUrl}`,
      },
      vintage: {
        mainContent: item.vintage,
      },
      drinkingWindow: {
        advice: selectDrinkingWindowAdvice(item.drinkingWindow.advice),
        date: item.drinkingWindow.dateFrom,
      },
      score: {
        score: item.score,
      },
      units: {
        mainContent: item.units,
      },
      unitSize: {
        unitCount: item.unitCount,
        unitSize: item.unitSize,
      },
      totalPurchasePrice: {
        mainContent: formatter.format(item.totalPurchasePrice),
      },
      totalMarketValuation: {
        mainContent: formatter.format(item.totalMarketValue),
      },
      performance: {
        data: item.marketData,
        deltaNum: item.performanceValueDelta,
        deltaPct: item.performancePercentageDelta,
      },
      highestBid: {
        content: HighestBidTradingTile,
      },
      lowestOffer: {
        content: LowestOfferTradingTile,
      },
      rowId: item.assetId.toString(),
      asset: item,
      actions: {
        trade: {
          assetId: item.assetId,
          condensed: true,
          onBidClicked: handleBidClick,
          onBuyClicked: handleBuyClick,
          onOfferClicked: handleOfferClick,
        },
        compare: {
          onCompareClicked: handleCompareClick,
        },
      },
    };
  });
}
