/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { Fragment } from 'react';
import { Namespace, Resources, TFunction, useTranslation } from 'react-i18next';
import { MenuItem, SxProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as Pencil } from '../../../assets/icons/pencil.svg';
import { ReactComponent as Trash } from '../../../assets/images/trash.svg';
import IconButton from '../../IconButton';
import Typography from '../../Typography';
import { Actions, OptionsProps, Row } from '../types';
import MenuButton from '../../MenuButton';
import WatchlistButton from '../../WatchlistButton';
import { useSelectedRowsContext } from '../../../context/selectedRowsContext';
import { KycStatus } from '../../../__generated__/graphql';
import { getKycStatus } from '../../../services/auth';
import BuyTradeActionButton, { Size } from '../../BuyTradeActionButton';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
  },
  menuItem: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    margin: `${theme.spacing(0.5)} 0`,
  },
  icon: {
    '&:disabled': {
      opacity: 0.5,
    },
  },

  deleteIcon: {
    color: theme.palette.textPrimary,
    backgroundColor: '#E0E0E0',

    '&:hover': {
      backgroundColor: '#E0E0E0',
    },
  },

  transferButton: {
    padding: 0,
  },
  checkbox: {
    '&.Mui-disabled': {
      color: theme.palette.grey[100],
    },
  },
  checkboxRoot: {
    '&:disabled': {
      color: 'grey',
    },
  },
}));

function Options({ rowId, actions, disabled }: OptionsProps): JSX.Element {
  const classes = useStyles();
  return (
    <MenuButton disabled={disabled}>
      {actions?.map((action) => (
        <MenuItem onClick={() => action.callback(rowId)} key={action.text}>
          <Typography className={classes.menuItem} variant="body1">
            {action.text}
          </Typography>
        </MenuItem>
      ))}
    </MenuButton>
  );
}

interface ActionsCellProps<T extends Row> {
  actions: Actions;
  className?: string;
  datum: T;
  disabled?: boolean;
  sx?: SxProps;
}

/**
 * @deprecated use src/components/TableElements instead.
 */
export function getActions(
  actions: Actions,
  rowId: string,
  t: TFunction<Namespace<keyof Resources>>,
  disabled = false,
): JSX.Element {
  const classes = useStyles();
  const { state } = useSelectedRowsContext();
  const { kyc: kycFlagEnabled } = useFlags();
  let disableBuyTrade = getKycStatus() !== KycStatus.successful;
  if (!kycFlagEnabled) {
    disableBuyTrade = disabled;
  }
  return (
    <>
      {Object.keys(actions)
        .filter((a) => actions[a as keyof typeof actions] !== undefined)
        .map((actionKey) => {
          switch (actionKey) {
            case 'watchlist': {
              return (
                <WatchlistButton
                  key="watchlist-button"
                  assetId={Number(rowId)}
                  onError={actions[actionKey]!.onError}
                  onAdd={(assetId: number) => actions[actionKey]!.onAdd(assetId.toString())}
                  onRemove={(assetId: number) => actions[actionKey]!.onRemove(assetId.toString())}
                />
              );
            }

            case 'delete': {
              return (
                <IconButton
                  key="delete-button"
                  className={classes.deleteIcon}
                  onClick={() => actions[actionKey]?.onDeleteClicked(rowId)}
                  disabled={disabled}
                  size="large"
                >
                  <Trash style={{ width: '24px', height: '24px' }} />
                </IconButton>
              );
            }

            case 'edit': {
              return (
                <IconButton
                  key="edit-button"
                  className={classes.icon}
                  onClick={() => actions[actionKey]?.onEditClicked(rowId)}
                  disabled={disabled}
                  size="large"
                >
                  <Pencil style={{ width: '1em', height: '1em' }} />
                </IconButton>
              );
            }

            case 'options': {
              return (
                <Options key="options" rowId={rowId} {...actions[actionKey]} disabled={actions[actionKey]?.disabled} />
              );
            }

            case 'trade': {
              const onBidClicked = actions[actionKey]?.onBidClicked;
              const onOfferClicked = actions[actionKey]?.onOfferClicked;
              const onBuyClicked = actions[actionKey]?.onBuyClicked;
              const condensed = actions[actionKey]?.condensed ?? false;
              return (
                <BuyTradeActionButton
                  key="buy-trade-action-button"
                  assetId={actions[actionKey]!.assetId!}
                  onBidClicked={onBidClicked ? () => onBidClicked(rowId) : null}
                  onOfferClicked={onOfferClicked ? () => onOfferClicked(rowId) : null}
                  onBuyClicked={onBuyClicked ? () => onBuyClicked(rowId) : null}
                  size={Size.Small}
                  condensed={condensed}
                  disabled={disableBuyTrade}
                />
              );
            }

            case 'compare': {
              return (
                <IconButton
                  key="compare-button"
                  className={classes.icon}
                  onClick={() => actions[actionKey]?.onCompareClicked(rowId)}
                  disabled={disabled}
                  size="large"
                >
                  <CopyIcon style={{ width: '1em', height: '1em' }} />
                </IconButton>
              );
            }

            case 'transfer': {
              return (
                <Fragment key="transfer-button">
                  {state.selectTransferModeActive && (
                    <Checkbox
                      classes={{ root: classes.checkboxRoot, colorPrimary: classes.checkboxRoot }}
                      data-testid="parent-transfer-checkbox"
                      className={classes.checkbox}
                      checked={
                        state.selectedTransferedRows.map((x) => x.id).includes(Number(rowId)) ||
                        actions[actionKey]?.transferRequested
                      }
                      onChange={actions[actionKey]!.onTransferClicked}
                      name="makeAsDefault"
                      inputProps={{ 'aria-label': 'action-checkbox' }}
                      color="primary"
                      disabled={actions[actionKey]?.transferDisabled}
                    />
                  )}
                </Fragment>
              );
            }

            default: {
              // eslint-disable-next-line no-console
              console.warn('Did you forget to add the action type to getActions?');
            }
          }
        })}
    </>
  );
}

export default function ActionsCell<T extends Row>({
  actions,
  datum,
  className = '',
  disabled = false,
  sx,
}: ActionsCellProps<T>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <TableCell className={className} sx={sx}>
      <div className={classes.container}>{getActions(actions, datum.rowId, t, disabled)}</div>
    </TableCell>
  );
}
