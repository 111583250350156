import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Skeleton } from '@mui/material';
import { Row as RowBase, Headers } from './types';
import { renderCell } from './Row';
import { hasActions } from './utils';
import ActionsCell from './Cells/Actions';
import ErrorPlaceholder from '../ErrorPlaceholder';
import EmptyPlaceholder from '../EmptyPlaceholder';
import Checkbox from '../Checkbox';
import { useSelectedRowsContext } from '../../context/selectedRowsContext';

const useStyles = makeStyles((theme) => ({
  table: {
    background: theme.palette.grey[50],
  },
  header: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    textTransform: 'uppercase',
  },
  spacerCell: {
    // width of the expander icon
    width: 36,
    padding: 0,
  },
  bottomSpacer: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  cellRoot: {
    borderBottom: 0,
  },
  actionsCell: {
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
  },
  checkbox: {
    '&.Mui-disabled': {
      color: theme.palette.grey[100],
    },
  },
}));

interface SubTableProps<Row extends RowBase, RowKeys> {
  headers: Headers<Omit<Row, 'rowId' | 'actions'>, RowKeys>;
  rows: Row[];
  loading: boolean;
  error: string | null;
  emptyTableText: string;
  EmptyTableAction?: React.ReactNode;
  ErrorAction?: React.ReactNode;
  handleTransferSubCheckboxChanged?: (subRowId: string, assetId: number | undefined) => void;
}

/**
 * @deprecated use material-ui components and src/components/TableElements instead.
 */
export default function SubTable<Row extends RowBase, RowKeys>({
  headers,
  rows,
  loading,
  error,
  emptyTableText,
  EmptyTableAction,
  ErrorAction,
  handleTransferSubCheckboxChanged,
}: SubTableProps<Row, RowKeys>): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { state, dispatch } = useSelectedRowsContext();

  function handleTransferSubCheckboxChangedInternal(
    e: React.ChangeEvent<HTMLInputElement>,
    subRowId: string,
    assetId: number | undefined,
  ): void {
    if (e.target.checked) {
      handleTransferSubCheckboxChanged?.(subRowId, assetId);
      // find the parent and add it.
      const parent = rows.find((r) => r.assetId === assetId)!;
      dispatch({
        type: 'rowTransferChecked',
        payload: {
          type: 'ParentAsset',
          id: parent.assetId,
          assetId: parent.assetId,
        },
      });
    } else {
      dispatch({ type: 'rowTransferUnchecked', payload: assetId });
      dispatch({ type: 'rowTransferUnchecked', payload: subRowId });
    }
  }

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              variant="head"
              className={clsx({
                [classes.spacerCell]: true,
                [classes.cellRoot]: true,
              })}
            />
            {Object.keys(headers).map((key, index) => {
              const header = headers[key as keyof typeof headers];
              return (
                <TableCell
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  variant="head"
                  className={clsx({
                    [classes.header]: true,
                  })}
                >
                  <div>{header.title}</div>
                </TableCell>
              );
            })}
            {hasActions(rows) && (
              <TableCell variant="head" className={classes.header}>
                {t('common:actions')}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <>
              {new Array(3).fill(0).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={i}>
                  <TableCell colSpan={12}>
                    <Skeleton width="100%" variant="rectangular" height={36} />
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
          {error && !loading && (
            <TableCell colSpan={12}>
              <ErrorPlaceholder error={error} action={ErrorAction} />
            </TableCell>
          )}
          {!rows.length && !error && !loading && (
            <TableCell>
              <EmptyPlaceholder label={emptyTableText} ActionButton={EmptyTableAction} />
            </TableCell>
          )}
          {!error &&
            !loading &&
            rows.length > 0 &&
            rows.map((row, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index}>
                {state.selectTransferModeActive ? (
                  <TableCell>
                    <Checkbox
                      inputProps={{ 'aria-label': 'sub-table-checkbox' }}
                      className={classes.checkbox}
                      checked={
                        state.selectedTransferedRows.map((x) => x.id).includes(row.rowId) || row.transferRequested
                      }
                      color="primary"
                      onChange={(e) => {
                        handleTransferSubCheckboxChangedInternal(e, row.rowId, row.assetId);
                      }}
                      disabled={row.transferDisabled}
                    />
                  </TableCell>
                ) : (
                  <TableCell
                    className={clsx({
                      [classes.spacerCell]: true,
                      [classes.cellRoot]: index < rows.length - 1,
                      [classes.bottomSpacer]: index === rows.length - 1,
                    })}
                  />
                )}
                {Object.keys(headers).map((key) => (
                  <React.Fragment key={key}>{renderCell(row[key as keyof typeof row])}</React.Fragment>
                ))}
                {row.actions && Object.keys(row.actions).length > 0 && (
                  <ActionsCell className={classes.actionsCell} actions={row.actions} datum={row} />
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
