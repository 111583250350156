/* eslint-disable react/jsx-no-useless-fragment */
import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { Namespace, Resources, TFunction, useTranslation } from 'react-i18next';
import { ReactComponent as NotificationBellIcon } from '../../../assets/icons/notification-bell.svg';
import { GET_NOTIFICATION_SETTINGS } from '../../../graphql/queries/notificationSettings';
import { useUpdateCategory } from '../../../hooks/useUpdateCategory';
import { useUpdateCategoryType } from '../../../hooks/useUpdateCategoryType';
import { logError } from '../../../utils/logger';
import Group from '../../MyAccountFields/Group';
import Switch from '../../Switch';
import Typography from '../../Typography';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1.5),
  },
  fields: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  hint: {
    color: theme.palette.textLabel,
  },
  expandedContent: {
    padding: theme.spacing(6),
    background: theme.palette.grey[50],
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    gap: theme.spacing(4),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 16,
    marginBottom: 24,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
  },
  title: {
    marginBottom: theme.spacing(6),
  },
}));

interface NotificationSettingsProps {
  className?: string;
}

function getCategoryChannelLabels(channels: Channels | undefined, t: TFunction<Namespace<keyof Resources>>) {
  if (!channels) {
    return t('account:notificationsSettings.noChannels');
  }
  const { email, push } = channels;
  if (email && push) {
    return `${t('account:notificationsSettings.email')} ${t('account:notificationsSettings.push')}`;
  }
  if (email) {
    return t('account:notificationsSettings.email');
  }
  return push ? t('account:notificationsSettings.push') : t('account:notificationsSettings.noChannels');
}

interface Channels {
  email: boolean;
  push: boolean;
}

export default function NotificationSettings({ className = '' }: NotificationSettingsProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_NOTIFICATION_SETTINGS);
  const updateCategoryType = useUpdateCategoryType();
  const updateCategory = useUpdateCategory();
  function handleCategoryTypeChange(id: string, isEnabled: boolean) {
    updateCategoryType({ id, payload: { isEnabled } });
  }

  function handleCategoryChannelChange(id: string, payload: Channels) {
    updateCategory({ id, payload });
  }

  if (error) {
    logError({
      originalError: error,
      error: new Error('Failed to get notifications settings'),
      filename: 'NotificationSettings',
    });
    return (
      <div className={className}>
        <Typography variant="h4" className={classes.heading}>
          {error.message}
        </Typography>
      </div>
    );
  }

  const categories = data?.userNotificationSettings.categories;
  return (
    <div className={className}>
      <Typography variant="h4" className={classes.heading}>
        {t('account:navigation.notifications')}
      </Typography>
      <Typography variant="body2" className={classes.hint}>
        {t('account:notificationsSettings.instructions')}
      </Typography>
      <div className={classes.fields}>
        <Typography variant="h5" zellarColour="textLabel">
          {t('account:notificationsSettings.wantToReceive')}
        </Typography>
        {loading ? (
          <Skeleton width="100%" height="300px" variant="rectangular" />
        ) : (
          <>
            {categories?.length &&
              categories.map((category) => (
                <Group
                  key={category.id}
                  Icon={NotificationBellIcon}
                  content={
                    <div>
                      <Typography variant="subtitle1">{category.name}</Typography>
                      <Typography variant="body1">
                        {getCategoryChannelLabels(category.channels || undefined, t)}
                      </Typography>
                    </div>
                  }
                  groupContent={
                    <div className={classes.expandedContent}>
                      <Typography className={classes.title} variant="h5" zellarColour="textLabel">
                        {t('account:notificationsSettings.wantToReceive')}
                      </Typography>
                      {category.types?.map((categoryType) => (
                        <div key={categoryType.id} className={classes.row}>
                          <div className={classes.textContainer}>
                            <Typography variant="subtitle1">{categoryType.name}</Typography>
                            <Typography variant="body1">{categoryType.description}</Typography>
                          </div>
                          <Switch
                            onChange={(e) => handleCategoryTypeChange(categoryType.id, e.target.checked)}
                            checked={categoryType.isEnabled}
                          />
                        </div>
                      ))}
                      <Typography variant="h5" zellarColour="textLabel" className={classes.title}>
                        {t('account:notificationsSettings.channels')}
                      </Typography>
                      <div className={classes.row}>
                        <Typography variant="subtitle1">{t('account:notificationsSettings.email')}</Typography>
                        <Switch
                          onChange={(e) =>
                            handleCategoryChannelChange(category.id, {
                              email: e.target.checked,
                              push: category.channels?.push || false,
                            })
                          }
                          checked={category.channels?.email}
                        />
                      </div>
                      <div className={classes.row}>
                        <Typography variant="subtitle1">{t('account:notificationsSettings.push')}</Typography>
                        <Switch
                          onChange={(e) =>
                            handleCategoryChannelChange(category.id, {
                              email: category.channels?.email || false,
                              push: e.target.checked,
                            })
                          }
                          checked={category.channels?.push}
                        />
                      </div>
                    </div>
                  }
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
}
