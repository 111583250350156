import React from 'react';
import { TFunction } from 'i18next';
import { Button, Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 240,
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  label: {
    fontWeight: 700,
    paddingBottom: 10,
  },
  cancelLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  actions: {
    gap: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

interface LogoutModelProps {
  onCta: () => void;
  onClose: () => void;
  isOpen: boolean;
  t: TFunction;
}
const LogoutModel = ({ onClose, isOpen, t, onCta }: LogoutModelProps) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} disableEscapeKeyDown maxWidth="md">
      <div className={classes.modalContainer}>
        <DialogTitle id="dialog-title" onClose={onClose}>
          {t('common:logoutModel.title')}
        </DialogTitle>
        <DialogContentBorderTopOnly className={classes.modalContent} dividers>
          <Typography className={classes.label} customVariant="body1">
            {t('common:logoutModel.subTitle')}
          </Typography>

          <div className={classes.actions}>
            <Button variant="contained" color="primary" size="large" onClick={onClose}>
              {t('common:logoutModel.close')}
            </Button>
            <Typography variant="body1" className={classes.cancelLabel} onClick={onCta}>
              {t('common:logoutModel.cta')}
            </Typography>
          </div>
        </DialogContentBorderTopOnly>
      </div>
    </Dialog>
  );
};

export default LogoutModel;
