/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import MainApplicationLayout from './components/AppLayout';
import PrivateRoute from './components/PrivateRoute';
import { isLoggedInVar, registerRedirectVar } from './graphql/cache';
import { useQueryParameters } from './hooks';
import { onboardingTheme } from './theme';
import { isMobileDimensions } from './utils/isMobileDimensions';
import Article from './views/Article';
import Discover from './views/Discover';
import Login from './views/Login';
import Market from './views/Market';
import MyAccount from './views/MyAccount';
import NotFound from './views/NotFound';
import NotSupported from './views/NotSupported';
import Portfolio from './views/Portfolio';
import Product from './views/Product';
import Register from './views/Register';
import ResetPassword from './views/ResetPassword';
import Search from './views/Search';
import Trade from './views/Trade';
import Wallet from './views/Wallet';
import WatchList from './views/WatchList';
import useUserDetails from './hooks/useUserDetails';
import { updateKycStatus } from './services/auth';
import RedirectToMobileApp from './views/RedirectToMobileApp';
import MaintenancePage from './views/Maintenance';
import { useShouldRenderMarketRoute } from './hooks/useShouldRenderMarketRoute';
import { RedirectAferLogin } from './components/RedirectAfterLogin';
import { setLoggerUser } from './utils/logger';
import { NavigationPathEnum } from './types';
import { AccountType } from './types/AccountType';
import { GET_STORED_CARD_DETAILS } from './graphql/queries/storedCardDetails';
import { KycStatus } from './__generated__/graphql';
import { UrlPath } from './types/Enums';

// const StyleGuide = React.lazy(() => import('./views/Styleguide'));
declare global {
  interface Heap {
    identify(identity: string): VoidFunction;
  }

  interface Window {
    heap: Heap;
  }
}

export default function Routes(): JSX.Element {
  const { searchBreakingChanges, desktopOnly, underMaintenance } = useFlags();
  const { t } = useTranslation();
  const { heap } = window;
  const { shouldRenderMarketRoute } = useShouldRenderMarketRoute();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const isMobile = isMobileDimensions();
  const history = useHistory();
  const queryParameters = useQueryParameters();
  const ldClient = useLDClient();
  const ldUser = ldClient?.getUser();
  const { userDetails, refetch } = useUserDetails();
  const [fetchSavedCards] = useLazyQuery(GET_STORED_CARD_DETAILS);
  const handleWhaleAccount = async () => {
    const {
      data: savedCardsDetails,
      error: savedCardsDetailsError,
      loading: savedCardsDetailsLoading,
    } = await fetchSavedCards();
    if (
      !savedCardsDetailsLoading &&
      !savedCardsDetailsError &&
      savedCardsDetails?.paymentGetCardDetails?.length === 0
    ) {
      // This was used to prompt whale accounts with no cards to add a payment method, this is no longer
      // needed for now since all accounts are by default whale.
      // history.replace({ pathname: NavigationPathEnum.WALLET, hash: 'add_card' });
    }
  };
  React.useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    if (userDetails && ldClient) {
      const _ldUser = {
        key: userDetails.emailAddress,
        email: userDetails.emailAddress,
      };
      ldClient?.identify(_ldUser, undefined);
      heap.identify(userDetails.emailAddress);
      setLoggerUser(userDetails.emailAddress);
      updateKycStatus(userDetails?.kycStatus || KycStatus.not_attempted);
    }
    if (userDetails && userDetails.accountType === AccountType.Whale) {
      handleWhaleAccount();
    }
  }, [userDetails, userDetails?.emailAddress]);

  // FIXME:
  // https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/7005
  // This is a short term solution for internal alpha. When true layer top ups complete, fail or are abandoned,
  // the user is redirected back to the url we pass to the backend, with a query parameter tacked on by true layer. Eventually
  // we are going to use that query parameter to make a subsequent request to the backend to find out what the status of the payment
  // is, whether it was successful etc. Because we are redirecting back to where the user performed the top up from (e.g. /product/{id})
  // we need to make sure to remove the query param from the url. When we eventually implement the payment completion view which the user
  // will get sent to after leaving the hosted payment page, then that additional query param won't be an issue as it'll only ever
  // be present on that payment status page (e.g. we won't end up with /product/1?payment_id=a-payment-id).
  React.useEffect(() => {
    if (queryParameters.has('payment_id') || queryParameters.has('error')) {
      if (queryParameters.has('payment_id')) {
        queryParameters.delete('payment_id');
      }

      if (queryParameters.has('error')) {
        queryParameters.delete('error');
      }

      history.replace({
        search: queryParameters.toString(),
      });
    }
  }, [queryParameters]);

  React.useEffect(() => {
    if (!ldUser || ldUser.anonymous === true) {
      return;
    }

    if (
      isMobile &&
      !window.location.href.includes('redirect-to-mobile-app') &&
      !window.location.href.includes('not-supported') &&
      desktopOnly === true
    ) {
      history.push('/not-supported');
    }
  }, [isMobile, desktopOnly, ldUser]);

  React.useEffect(() => {
    const redirectMessage = queryParameters.get('message');
    const redirectSuccess = queryParameters.get('success');

    if (redirectMessage && redirectSuccess) {
      registerRedirectVar({ redirectMessage, redirectSuccess: redirectSuccess === 'true' });
    }
  }, []);

  // Need to use an env variable here because Launch Darkly is open to exploitation,
  // if we have a positive flag such as `maintenanceModeActive` then a user
  // can technically disable the flag by blocking any requests to launch darkly.
  // This is a temporary but solid fix because it's the weekend, can think of something
  // more elegant next week if necessary.
  if (process.env.REACT_APP_MAINTENANCE_MODE_ACTIVE === 'true' || underMaintenance === true) {
    return (
      <Switch>
        <Route path="/under-maintenance">
          <MaintenancePage />
        </Route>
        <Route path="*">
          <Redirect to="/under-maintenance" />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <PrivateRoute path="/" isLoggedIn={isLoggedIn} exact>
        <MainApplicationLayout>
          <Redirect to={UrlPath.DISCOVER} />
        </MainApplicationLayout>
      </PrivateRoute>
      {/* Hidden as it is not needed for the MVP
      <PrivateRoute path="/overview" isLoggedIn={isLoggedIn} exact>
        <MainApplicationLayout AppBarProps={{ title: t('overview:title') }} appBarVariant="search">
          <Overview />
        </MainApplicationLayout>
      </PrivateRoute> */}

      <PrivateRoute path="/portfolio" isLoggedIn={isLoggedIn}>
        <MainApplicationLayout AppBarProps={{ title: t('common:portfolio') }}>
          <Portfolio />
        </MainApplicationLayout>
      </PrivateRoute>
      {shouldRenderMarketRoute && (
        <PrivateRoute path="/market" isLoggedIn={isLoggedIn}>
          <MainApplicationLayout AppBarProps={{ title: t('market:title'), displayBackButton: true }}>
            <Market />
          </MainApplicationLayout>
        </PrivateRoute>
      )}
      <PrivateRoute path="/my-account" isLoggedIn={isLoggedIn}>
        <MainApplicationLayout AppBarProps={{ title: t('account:title'), displayBackButton: true }}>
          <MyAccount />
        </MainApplicationLayout>
      </PrivateRoute>

      <PrivateRoute path={`${UrlPath.DISCOVER}/article/:slug`} isLoggedIn={isLoggedIn}>
        <MainApplicationLayout AppBarProps={{ displayBackButton: true }}>
          <Article />
        </MainApplicationLayout>
      </PrivateRoute>
      <PrivateRoute isLoggedIn={isLoggedIn} path="/search">
        {searchBreakingChanges ? (
          <MainApplicationLayout AppBarProps={{ title: t('common:search'), displayBackButton: true }}>
            <Search />
          </MainApplicationLayout>
        ) : (
          <Redirect to={UrlPath.DISCOVER} />
        )}
      </PrivateRoute>
      <PrivateRoute path="/trade" isLoggedIn={isLoggedIn}>
        <MainApplicationLayout AppBarProps={{ title: t('common:myTrade') }}>
          <Trade />
        </MainApplicationLayout>
      </PrivateRoute>
      <PrivateRoute path={NavigationPathEnum.WALLET} isLoggedIn={isLoggedIn}>
        <MainApplicationLayout AppBarProps={{ title: t('product:detailsModal.wallet'), displayBackButton: true }}>
          <Wallet />
        </MainApplicationLayout>
      </PrivateRoute>
      <PrivateRoute path={NavigationPathEnum.WATCHLIST} isLoggedIn={isLoggedIn}>
        <MainApplicationLayout AppBarProps={{ title: t('account:userOptionsMenu.myWatchlist') }}>
          <WatchList />
        </MainApplicationLayout>
      </PrivateRoute>
      {/* <Route path="/styleguide">
        <Suspense fallback={<div>Loading...</div>}>
          <StyleGuide />
        </Suspense>
      </Route> */}

      <Route exact path={UrlPath.DISCOVER}>
        <MainApplicationLayout AppBarProps={{ title: t('common:discover') }}>
          <Discover />
        </MainApplicationLayout>
      </Route>

      <Route path={`${UrlPath.PRODUCT_PAGE}/:id?`}>
        <MainApplicationLayout AppBarProps={{ title: t('common:product'), displayBackButton: true }}>
          <Product isLoggedIn={isLoggedIn} />
        </MainApplicationLayout>
      </Route>

      <Route path={UrlPath.LOGIN}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={onboardingTheme}>
            <CssBaseline />
            {isLoggedIn ? <RedirectAferLogin /> : <Login />}
          </ThemeProvider>
        </StyledEngineProvider>
      </Route>
      <Route path={UrlPath.REGISTER}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={onboardingTheme}>
            <CssBaseline />
            {isLoggedIn ? <RedirectAferLogin /> : <Register />}
          </ThemeProvider>
        </StyledEngineProvider>
      </Route>
      <Route path="/reset-password">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={onboardingTheme}>
            <CssBaseline />
            {isLoggedIn ? <Redirect to="/" /> : <ResetPassword />}
          </ThemeProvider>
        </StyledEngineProvider>
      </Route>
      {desktopOnly && ldUser && !ldUser.anonymous && (
        <Route path="/not-supported">
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={onboardingTheme}>
              <CssBaseline />
              <NotSupported />
            </ThemeProvider>
          </StyledEngineProvider>
        </Route>
      )}
      <Route path="/redirect-to-mobile-app">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={onboardingTheme}>
            <CssBaseline />
            <RedirectToMobileApp />
          </ThemeProvider>
        </StyledEngineProvider>
      </Route>
      <Route path="/under-maintenance">
        <Redirect to="/" />
      </Route>

      <Route path="/page-not-found">
        <MainApplicationLayout AppBarProps={{ displayBackButton: true }}>
          <NotFound />
        </MainApplicationLayout>
      </Route>

      <Route path="*">
        <MainApplicationLayout AppBarProps={{ displayBackButton: true }}>
          <NotFound />
        </MainApplicationLayout>
      </Route>
    </Switch>
  );
}
