import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import PlaceholderImage from '../../assets/images/stub-wine-image.png';
import { OrderDirection } from '../../components/OrderBookModal/types';
import getImageUrl from '../../utils/getImageUrl';
import { massageToNull } from '../../utils/massageToNull';
import { OmsGetMyOrdersQuery } from '../../__generated__/graphql';

export interface MyOrder {
  assetId: number;
  orderId: string;
  clientOrderId: string;
  wineName: string;
  region: string;
  appellation: string;
  vintage: number;
  unitSize: string;
  unitCount: number;
  quantity: number;
  placedDate: string;
  score: number | null;
  date: string;
  // TODO: Expiry date is nullable on the backend, but I am not convinced
  // that it should be so. We enforce setting an expiry date/duration for
  // all bids and offers, so I don't believe it should be nullable.
  // Raised https://dev.azure.com/CultWines/Cult%20Wines%20Marketplace/_workitems/edit/6482
  expiryDate: string | null | undefined;
  status: string;
  price: number;
  highestBid: number | null;
  outstandingQuantity: number;
  lowestOffer: number | null;
  imageFilename: string;
}

export type MyOrdersFacets = Omit<MyOrder, 'assetId' | 'status' | 'expiryDate' | 'placedDate' | 'unitSize'>;

export type MyBidOrdersFacets = Omit<
  MyOrder,
  'assetId' | 'status' | 'expiryDate' | 'placedDate' | 'lowestOffer' | 'unitSize'
>;
export type MyOfferOrdersFacets = Omit<
  MyOrder,
  'assetId' | 'status' | 'expiryDate' | 'placedDate' | 'highestBid' | 'unitSize'
>;

export function selectMyOrders(gqlResponse: OmsGetMyOrdersQuery | undefined, orderType: OrderDirection): MyOrder[] {
  if (isUndefined(gqlResponse)) {
    return [];
  }

  return gqlResponse.omsGetMyOrders
    .filter((order) => order.direction === orderType)
    .map((order) => ({
      wineName: order.asset.vintage.wine.name,
      appellation: order.asset.vintage.wine.appellation?.name ?? '',
      assetId: order.asset.id,
      expiryDate: order.executableWindow.endTime,
      highestBid: massageToNull(order.asset.spread?.highestBid?.price),
      lowestOffer: massageToNull(order.asset.spread?.lowestOffer?.price),
      score: order.asset.vintage.calculatedMarketData.combinedScore || null,
      date: order.submittedTimeUtc,
      placedDate: order.submittedTimeUtc,
      status: order.status,
      unitCount: order.asset.unitCount,
      unitSize: order.asset.unitSize,
      vintage: order.asset.vintage.vintage,
      region: order.asset.vintage.wine.region.name,
      outstandingQuantity: order.outstandingQuantity,
      quantity: order.quantity,
      orderId: order.id,
      clientOrderId: order.clientOrderId,
      price: order.price,
      imageFilename: order.asset.vintage.imageFileName
        ? getImageUrl(order.asset.vintage.imageFileName, { height: 200 })
        : PlaceholderImage,
    }));
}
