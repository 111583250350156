import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colours } from '../../theme/light/palette';

export const useMyAccountTabStyles = makeStyles<Theme, { appLayoutContentPadding: number }>((theme) => ({
  container_DEPRECATED: {
    display: 'flex',
    height: '100%',

    // Negate the padding of the MainApplicationLayout parent
    // as this element needs to sit flush against the navigation bar
    // and the bottom of the window.
    marginLeft: (props) => `-${props.appLayoutContentPadding}px`,
    marginBottom: (props) => `-${props.appLayoutContentPadding}px`,
  },

  responsiveContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',

    // Negate the padding of the MainApplicationLayout parent
    // as this element needs to sit flush against the navigation bar
    // and the bottom of the window.
    marginLeft: (props) => `-${props.appLayoutContentPadding}px`,
    marginBottom: (props) => `-${props.appLayoutContentPadding}px`,

    '@media (min-width: 768px)': {
      flexDirection: 'row',
    },
  },

  phantomBorder: {
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    height: '100%',
    width: '100%',
  },

  tabPanelVisible: {
    width: '100%',
  },

  tabPanelHidden: {
    width: 0,
  },

  tabPanelContainer: {
    width: '100%',
    padding: theme.spacing(6),
    paddingTop: 0,
  },

  label: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    justifyContent: 'flex-start',
  },
  icon: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // '& circle': {
    //   bac: theme.palette.navigation.background.active.main,
    // },
    borderRadius: '100%',
    backgroundColor: colours.brightGreen,
  },
}));
