import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '../Typography';
import DialogTitle from '../Dialog/DialogTitle';
import ErrorImage from '../../assets/images/wine-comfirm-error.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    height: 456,
    display: 'flex',
    flexDirection: 'column',

    '@media (min-width: 768px)': {
      width: 576,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: theme.spacing(5),
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(5),
  },
  text: {
    width: '60%',
  },
  button: {
    width: 225,
    alignSelf: 'center',
  },
}));

export default function FailureScreen({ onClose }: { onClose: () => void }): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <DialogTitle id="failure-transfer" onClose={onClose}>
        {null}
      </DialogTitle>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <img src={ErrorImage} alt="error" />
          <Typography customVariant="h6">{t('common:failure')}</Typography>
          <Typography customVariant="body1" className={classes.text} zellarColour="textLabel">
            {t('common:somethingWentWrong')}
          </Typography>
        </div>
        <div>
          <Button className={classes.button} variant="contained" color="primary" size="large" onClick={onClose}>
            {t('common:tryAgain')}
          </Button>
        </div>
      </div>
    </div>
  );
}
