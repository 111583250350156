import React from 'react';
import { useTranslation } from 'react-i18next';
import MuiTablePagination, { TablePaginationProps as BaseProps } from '@mui/material/TablePagination';
import Actions from './Actions';

const TablePagination = (props: BaseProps): JSX.Element => {
  const { t } = useTranslation();
  const labelDisplayedRows: BaseProps['labelDisplayedRows'] = ({ count, from, to }) => {
    return `${from}-${to} ${t('common:of')} ${count !== -1 ? count : `${t('common:moreThan')} ${to}`}`;
  };
  return (
    <MuiTablePagination
      labelRowsPerPage={t('common:table.pagination.resultsPerPage')}
      labelDisplayedRows={labelDisplayedRows}
      ActionsComponent={Actions}
      {...props}
    />
  );
};

// https://github.com/mui-org/material-ui/issues/23780#issuecomment-735696076
export default TablePagination as typeof MuiTablePagination;
