import { isUndefined } from '@cultwines/zellar-client-sdk';
import { Theme, Tooltip, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InformationIcon } from '../../assets/icons/information-circled.svg';
import Typography from '../Typography';
import { selectPriceLabel } from './selectors';
import { OrderBookDataSource } from './types';

const useStyles = makeStyles<Theme, { isReverse: boolean }>(() => ({
  cell: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 6,
    minWidth: 67,
    height: 60,
  },
  icon: {
    height: 18,
  },
}));

interface CellProps {
  opacity: number;
  price?: number;
  quantity: number | undefined;
  background: string;
  source: OrderBookDataSource;
}

export default function Cell({ opacity, price, quantity, background, source }: CellProps): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles({ isReverse: false });
  return (
    <Tooltip title={source === 'WineSearcher' ? t('market:wineSearcherOfferPrice') : ''}>
      <div
        className={classes.cell}
        style={{
          background,
          opacity: source === 'WineSearcher' ? 0.3 : opacity,
        }}
        role="cell"
      >
        {!isUndefined(price) && (
          <>
            <Typography>{selectPriceLabel(price)}</Typography>
            {source === 'WineSearcher' ? (
              <div className={classes.icon} role="img" title="information-icon">
                <InformationIcon fill={theme.palette.secondary.contrastText} />
              </div>
            ) : (
              <div className={classes.icon}>
                <Typography customVariant="body2" zellarColour="textLabel">
                  {quantity}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
    </Tooltip>
  );
}
