import { Button, DialogActions } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DialogTitle from '../Dialog/DialogTitle';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import Typography from '../Typography';
import { useStyles } from './styles';
import { logMessage } from '../../utils/logger';
import { useDeletePaymentCard } from '../../hooks/useDeletePaymentCard';

interface ConfirmDeleteMitCardWhaleAccountProps {
  instrumentId: string | null | undefined;
  onClose: () => void;
  onFailure: () => void;
  onSuccess: () => void;
}

export default function ConfirmDeleteMitCardWhaleAccount({
  instrumentId,
  onClose,
  onFailure,
  onSuccess,
}: ConfirmDeleteMitCardWhaleAccountProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { deleteCard, error, loading, success } = useDeletePaymentCard();

  async function handleConfirm() {
    if (!instrumentId) {
      logMessage('Tried to delete a card but the instrumentId was null', {
        additionalInfo: { filename: 'src/components/ManageCardModal/ConfirmDeleteCard' },
      });
      onFailure();
      return;
    }

    await deleteCard(instrumentId);
  }

  useEffect(() => {
    if (error) {
      onFailure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (success) {
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <>
      <DialogTitle id="dialog-title" onClose={onClose}>
        <div className={classes.textCapitalize}>{t('wallet:manageCardModal.confirmTitle')}</div>
      </DialogTitle>

      <DialogContentBorderTopOnly dividers>
        <div className={`${classes.container} ${classes.textCenter}`}>
          <Typography variant="subtitle1">{t('wallet:manageCardModal.deleteMit.implication')}</Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            {t('wallet:manageCardModal.deleteMit.description')}
          </Typography>
          <Typography variant="subtitle1">{t('wallet:manageCardModal.proceed')}</Typography>
          <DialogActions className={classes.justifyCenter}>
            <Button disabled={loading} variant="outlined" color="info" onClick={handleConfirm}>
              {t('common:yes')}
            </Button>
            <Button disabled={loading} variant="outlined" color="info" onClick={onClose} autoFocus>
              {t('common:no')}
            </Button>
          </DialogActions>
        </div>
      </DialogContentBorderTopOnly>
    </>
  );
}
