import { AddressType } from '@cultwines/zellar-client-sdk';
import { Address, GetAddressesQueryQuery } from '../../__generated__/graphql';

export function selectAddress(
  queryResponse: GetAddressesQueryQuery | undefined,
  addressType: AddressType,
): Address | null {
  const address = queryResponse?.zellarClientAddresses?.find((a) => a.type === addressType) as Address;

  return address ?? null;
}
