export function addDaysToDate(fromDate: Date, daysToAdd: number): string {
  if (daysToAdd <= 0) {
    throw new Error('daysToAdd must be greater than 0');
  }
  const newDate = new Date(fromDate);
  newDate.setDate(newDate.getDate() + daysToAdd);
  const day = newDate.getDate();
  const month = newDate.toDateString().split(' ')[1];
  return `${day} ${month}`;
}
