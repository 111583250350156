import { graphql } from '../../__generated__';

export const ORDER_BOOK_SUBSCRIPTION = graphql(`
  subscription GetOrderBooksByAssetId($assetId: Int!) {
    getOrderBooksByAssetId(assetId: $assetId) {
      assetId
      lastTradeDate
      bids {
        direction
        orderId
        price
        quantity
        quantityOutstanding
      }
      offers {
        direction
        orderId
        price
        quantity
        quantityOutstanding
      }
    }
  }
`);
