import { useQuery } from '@apollo/client';
import { SelectOption } from '@cultwines/zellar-client-sdk';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PRODUCT_DATA_WITH_USERID_QUERY } from '../../graphql/queries/productData';
import { logError } from '../../utils/logger';
import { DialogContentBorderTopOnly as DialogContent } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import { selectNormalisedProduct } from '../ProductSummary/selectors';
import UnitSizeSelect from '../Select/UnitSizeSelect';
import Typography from '../Typography';
import BidsTable from './BidsTable';
import OffersTable from './OffersTable';
import { selectBids, selectOffers } from './selectors';
import { graphql } from '../../__generated__';
import { Asset, BookedOrder } from '../../__generated__/graphql';

const useStyles = makeStyles((theme) => ({
  modalContainer: { maxWidth: 800, paddingLeft: theme.spacing(7.5), paddingRight: theme.spacing(7.5) },
  title: {
    marginBottom: theme.spacing(6),
  },
  legendContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    gap: theme.spacing(4),
  },
  legendIcon: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    backgroundColor: theme.palette.grey[100],
  },
  button: {
    width: '80%',
    alignSelf: 'center',
  },
}));

interface Props {
  assetId: number;
  onClose: () => void;
  open: boolean;
}

const OMS_OPEN_ORDERS = graphql(`
  query OmsOpenOrders($assetId: Int!) {
    openOrders(assetId: $assetId) {
      id
      userId
      price
      direction
      quantity
    }
  }
`);

export default function OfferBookModal({ assetId, onClose, open }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const [unitSizeId, setUnitSizeId] = useState(assetId);
  const {
    data,
    loading: productLoading,
    error: productDataQueryError,
  } = useQuery(PRODUCT_DATA_WITH_USERID_QUERY, {
    variables: {
      assetId,
    },
  });

  const {
    data: ordersData,
    loading: orderBookLoading,
    error: orderBookQueryError,
    startPolling,
    stopPolling,
  } = useQuery(OMS_OPEN_ORDERS, {
    variables: {
      assetId: unitSizeId,
    },
    fetchPolicy: 'network-only',
  });

  if (productDataQueryError) {
    logError({
      originalError: productDataQueryError,
      error: new Error('Failed to load product data'),
      filename: 'OrderBookModal',
      additionalInfo: { assetId },
    });
  }

  if (orderBookQueryError) {
    logError({
      originalError: orderBookQueryError,
      error: new Error('Failed to load order book data'),
      filename: 'OrderBookModal',
      additionalInfo: { unitSizeId },
    });
  }

  useEffect(() => {
    if (open) {
      startPolling(2000);
    }
  }, [startPolling, open]);

  function handleClose() {
    stopPolling();
    onClose();
  }
  const bids = useMemo(
    () => selectBids(ordersData?.openOrders as BookedOrder[], data?.authUser.userId),
    [ordersData, data],
  );
  const offers = useMemo(
    () => selectOffers(ordersData?.openOrders as BookedOrder[], data?.authUser.userId),
    [ordersData, data],
  );
  const { displayName } = useMemo(() => selectNormalisedProduct(data?.productAsset as Asset), [data]);

  function afterUnitSizeChange(option: SelectOption) {
    setUnitSizeId(option.value);
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      <div className={classes.modalContainer}>
        <DialogTitle id="dialog-title" onClose={handleClose}>
          {t('product:detailsModal.orderBookTitle')}
        </DialogTitle>
        <DialogContent dividers>
          {!productLoading ? (
            <Typography className={classes.title} variant="h2">
              {displayName}
            </Typography>
          ) : (
            <Typography variant="h2" className={classes.title}>
              <Skeleton />
            </Typography>
          )}
          <UnitSizeSelect assetId={unitSizeId} afterUnitSizeChange={afterUnitSizeChange} />
          <div className={classes.tableContainer}>
            <BidsTable data={bids} loading={orderBookLoading} />
            <OffersTable data={offers} loading={orderBookLoading} />
          </div>
          <div className={classes.legendContainer}>
            <div className={classes.legendIcon} />
            <div>{t('product:detailsModal.orderBookLegend')}</div>
          </div>
          <Button className={classes.button} variant="contained" color="primary" size="large" onClick={handleClose}>
            {t('product:detailsModal.ok')}
          </Button>
        </DialogContent>
      </div>
    </Dialog>
  );
}
