/**
 * This is a copy of the old OperationType which was generated from the graphql schema. It is deprecated
 * and soon to be removed, but the operationType on the schema from financeGetRecent can still be any of these values.
 * We rely on TopUp and Withdraw that were removed when I ran the latest schema and types generation script, so I have
 * stopped using the graphql generated one and created my own for backwards compatibility purposes.
 */
export enum FinancialOperationType {
  Adjustment = 'Adjustment',
  Fresh = 'Fresh',
  Purchase = 'Purchase',
  Refund = 'Refund',
  Sale = 'Sale',
  Withdraw = 'Withdraw',
  TopUp = 'TopUp',
}
