import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  modalContainer: {
    maxWidth: 800,
    minWidth: 400,
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  container: {
    display: 'flex',
    gap: theme.spacing(5),
  },
  button: {
    width: 90,
    alignSelf: 'flex-end',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  tile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    background: theme.palette.grey[50],
    borderRadius: 10,
    height: 64,
    width: 64,
  },
  disabled: {
    opacity: 0.3,
  },
  tilesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  inputsContainer: {
    display: 'flex',
    gap: theme.spacing(4),
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
}));
