/* eslint-disable no-nested-ternary */
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useExternalPortfolioValue } from '../../hooks/useExternalPortfolioValue';
import { formatter } from '../../utils/currencyFormatter';
import isUndefined from '../../utils/isUndefined';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    color: theme.palette.secondary.contrastText,
  },
  skeleton: {
    background: theme.palette.grey[200],
  },
}));

export default function ExternalPortfolioValueHeading(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, loading } = useExternalPortfolioValue();

  return (
    <div className={classes.container}>
      <Typography variant="h3">
        {loading ? (
          <Skeleton width="120px" className={classes.skeleton} />
        ) : isUndefined(data) ? (
          t('common:currentlyUnavailable')
        ) : (
          formatter.format(data)
        )}
      </Typography>
      <Typography customVariant="body2">{t('portfolio:externalTotalValue')}</Typography>
    </div>
  );
}
