import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

export function RedirectAferLogin(): JSX.Element {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  return <Redirect to={id ? `/product/${id}` : '/'} />;
}
