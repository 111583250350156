import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';
import WineConfirmSuccess from '../../assets/images/wine-confirm-success.png';

interface Props {
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(10),
  },
  successMessage: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  successTitle: {
    paddingTop: theme.spacing(5),
  },
  successText: {
    width: '100%',
    paddingTop: theme.spacing(1.5),
  },
  button: {
    width: 225,
    alignSelf: 'center',
    marginTop: theme.spacing(5),
  },
}));

export default function ConfirmSuccess({ onClose }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle id="success-transfer" onClose={onClose}>
        {null}
      </DialogTitle>
      <div className={classes.contentContainer}>
        <div className={classes.successMessage}>
          <img src={WineConfirmSuccess} height="212" alt="success-message" />
          <Typography customVariant="h6" className={classes.successTitle}>
            {t('common:success')}
          </Typography>
          <Typography customVariant="body1" className={classes.successText} zellarColour="textLabel">
            {t('wallet:paymentMethodModal.confirmSuccess.thank')}
          </Typography>
          <Typography customVariant="body1" className={classes.successText} zellarColour="textLabel">
            {t('wallet:paymentMethodModal.confirmSuccess.description')}
          </Typography>
        </div>
        <div>
          <Button className={classes.button} variant="contained" color="primary" size="large" onClick={onClose}>
            {t('wallet:paymentMethodModal.confirmSuccess.close')}
          </Button>
        </div>
      </div>
    </>
  );
}
