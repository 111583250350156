import makeStyles from '@mui/styles/makeStyles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import TabPanel from '../../components/TabPanel';
import { OrderDirection } from '../../components/OrderBookModal/types';
import MyOrders from '../../components/MyOrders';
import CardPaymentErrorModal from '../../components/CardPaymentCallbackModal/errorContent';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';
import CardPaymentSuccessModal from '../../components/CardPaymentCallbackModal/successContent';
import RecentTradesTable from '../../components/RecentTradesTable';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabsContainer: {
    width: 'fit-content',
  },
}));

export default function Trade(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const history = useHistory();
  const cardPaymentAuthorisationResponse = useCardPaymentCallback();
  const [showCarPaymentErrorModal, setShowCarPaymentErrorModal] = React.useState(
    cardPaymentAuthorisationResponse.showCarPaymentErrorModal,
  );
  const [showCarPaymentSuccessModal, setShowCarPaymentSuccessModal] = React.useState(
    cardPaymentAuthorisationResponse.showCarPaymentSuccessModal,
  );

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function closeCardPaymentErrorModal() {
    setShowCarPaymentErrorModal(false);
    history.push({ pathname: location.pathname, search: location.search, hash: '' });
  }
  function closeCardPaymentSuccessModal() {
    setShowCarPaymentSuccessModal(false);
    history.push({ pathname: location.pathname, search: location.search, hash: '' });
  }

  return (
    <div className={classes.container}>
      <Tabs className={classes.tabsContainer} value={value} onChange={handleChange}>
        <Tab label={t('trade:ownBids')} />
        <Tab label={t('trade:ownOffers')} />
        {/* Commented out intentionally - should be reused after MVP */}
        {/* <Tab disabled label={t('trade:receivedBids')} /> */}
        <Tab label={t('market:tradeHistory')} />
      </Tabs>
      <TabPanel paddingTop={0} value={value} index={0}>
        <MyOrders orderType={OrderDirection.Bids} />
      </TabPanel>
      <TabPanel paddingTop={0} value={value} index={1}>
        <MyOrders orderType={OrderDirection.Offers} />
      </TabPanel>
      <TabPanel paddingTop={0} value={value} index={2}>
        <RecentTradesTable />
      </TabPanel>
      {showCarPaymentErrorModal && (
        <CardPaymentErrorModal open={showCarPaymentErrorModal} onClose={closeCardPaymentErrorModal} />
      )}
      {showCarPaymentSuccessModal && (
        <CardPaymentSuccessModal open={showCarPaymentSuccessModal} onClose={closeCardPaymentSuccessModal} />
      )}
    </div>
  );
}
