import React from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '../../Typography';
import { UnitSizeCell as UnitSizeCellProps } from '../types';

export default function UnitSizeCell({ unitCount, unitSize, cellClassName, sx }: UnitSizeCellProps): JSX.Element {
  return (
    <TableCell className={cellClassName} sx={sx}>
      <Typography variant="subtitle2">
        {unitCount} x {unitSize}
      </Typography>
    </TableCell>
  );
}
