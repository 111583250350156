/* eslint-disable no-nested-ternary */
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import ExternalPortfolioValueHeading from './ExternalPortfolioValueHeading';
import InvestedHeading from './InvestedHeading';
import PortfolioMarketValueHeading from './PortfolioMarketValueHeading';
import RealisedGainsComponent from './RealisedGains';
import WalletHeading from './WalletHeading';
import { useUserPortfolioHighlights } from '../../hooks/useUserPortfolioHighlights';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    rowGap: theme.spacing(6),
    flexWrap: 'wrap-reverse',
    alignItems: 'center',
  },

  right: {
    marginLeft: 'auto',
  },

  left: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    columnGap: theme.spacing(8),
    rowGap: theme.spacing(6),
  },
}));

export default function PortfolioHeadings({ selectedTabIndex }: { selectedTabIndex: number }): JSX.Element | null {
  const classes = useStyles();
  const { error, loading, userPortfolioHighlights } = useUserPortfolioHighlights();

  if (error) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        {selectedTabIndex === 1 ? (
          <ExternalPortfolioValueHeading />
        ) : (
          <>
            <PortfolioMarketValueHeading
              portfolioValue={userPortfolioHighlights?.userPortfolioHighlights.portfolioValue}
              loading={loading}
            />
            <InvestedHeading
              totalContributions={userPortfolioHighlights?.userPortfolioHighlights.totalContributions}
              loading={loading}
            />
            <RealisedGainsComponent
              simpleReturn={userPortfolioHighlights?.userPortfolioHighlights.simpleReturn}
              loading={loading}
            />
          </>
        )}
      </div>
      <div className={classes.right}>
        <WalletHeading />
      </div>
    </div>
  );
}
