export interface ActiveMarketsFacets {
  name: string;
  region: string;
  vintage: number;
  unitSize: string;
  marketValue: string;
  highestBid: string;
  lowestOffer: string;
  spread: string;
}

/**
 * @deprecated
 * TODO: This is the result of technical debt. Essentially, the toolbar component which
 * houses the sort menu expects there to be a type which maps directly to the sort options. Up
 * until Active Markets feature, all sort options could be traced directly to a single datum
 * (e.g. wine name, vintage, region, unit count), so having this requirement of a Type which
 * the keys are used for sorting purposes was not an issue. With introduction of Active Markets
 * we now have sort options which are not tied to a particular data point, but more a 'smart' sort
 * (e.g. BestBids). Some thought needs to go into removing the expectation that the consumer
 * of Toolbar will have a perfectly mapped Type where each key is going to be sortable. Created
 * placeholder ticket https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/12426
 */
export type ActiveMarketSortObject = {
  BestOffers: undefined;
  BestBids: undefined;
  LatestOffers: undefined;
  LatestBids: undefined;
  LowestOffer: undefined;
  HighestBid: undefined;
  WineName: undefined;
  Spread: undefined;
  MarketValue: undefined;
  Region: undefined;
  Vintage: undefined;
  UnitSize: undefined;
  Score: undefined;
};

export enum Modal {
  Bid = 'bid',
  Offer = 'offer',
  BuyNow = 'buynow',
}

export enum PriceRangeEnum {
  Under500 = 'Under £500',
  Under1000 = 'Under £1000',
  Range_1k_2k = '£1k-£2k',
  Range_2k_5k = '£2k-£5k',
  Range_5k_10k = '£5k-£10k',
  Range_10k_Plus = '£10k+',
}
