import { SelectOption } from '@cultwines/zellar-client-sdk';
import i18n from '../../i18n';
import { addDaysToDate } from '../../utils/addDaysToDate';
import { ExpirationDate } from './types';

const selectExpirationDateOptions = (): SelectOption[] => {
  return [
    {
      value: ExpirationDate.OneDay,
      label: `${i18n.t('product:expirationDate.1')} (${addDaysToDate(new Date(), 1)})`,
    },
    {
      value: ExpirationDate.SevenDays,
      label: `${i18n.t('product:expirationDate.7')} (${addDaysToDate(new Date(), 7)})`,
    },
    {
      value: ExpirationDate.FourteenDays,
      label: `${i18n.t('product:expirationDate.14')} (${addDaysToDate(new Date(), 14)})`,
    },
    {
      value: ExpirationDate.ThirtyDays,
      label: `${i18n.t('product:expirationDate.30')} (${addDaysToDate(new Date(), 30)})`,
    },
    {
      value: ExpirationDate.SixtyDays,
      label: `${i18n.t('product:expirationDate.60')} (${addDaysToDate(new Date(), 60)})`,
    },
    {
      value: ExpirationDate.NinetyDays,
      label: `${i18n.t('product:expirationDate.90')} (${addDaysToDate(new Date(), 90)})`,
    },
  ];
};

export { selectExpirationDateOptions };
