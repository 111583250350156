import isUndefined from './isUndefined';

export default function selectChartExtremes(data: Record<string, number> | undefined): [min: number, max: number] {
  if (isUndefined(data)) {
    return [0, 0];
  }
  const values = Object.values(data);
  if (!values.length) {
    return [0, 0];
  }
  let min = values[0];
  let max = values[0];
  values.forEach((value) => {
    if (value < min) {
      min = value;
    } else if (value > max) {
      max = value;
    }
  });
  // Give some room for the lowest and highest point of y
  // axis depending on the lowest and highest chart points
  let minMultiplierValue = 0.95;
  let maxMultiplierValue = 1.05;
  if (min < 0) {
    minMultiplierValue = min > -2 ? 2.05 : 1.05;
  }
  if (max < 0) {
    maxMultiplierValue = 0.95;
  }
  const minNormalised = Number((min * minMultiplierValue).toFixed(0));
  const maxNormalised = Number((max * maxMultiplierValue).toFixed(0));
  return [minNormalised - 1, maxNormalised + 1];
}
