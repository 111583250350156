import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import { RecentSearch } from '../components/SearchInput/RecentSearches';

const KEY = 'RECENT_SEARCHES';
const LIMIT = 10;

export function addRecentSearchTerm(search: RecentSearch): void {
  const oldState = window.localStorage.getItem(KEY);
  if (!isNull(oldState)) {
    const parsed: RecentSearch[] = JSON.parse(oldState);
    const alreadyInState = parsed.filter((rc) => rc.value === search.value).length > 0;
    if (alreadyInState) {
      // As it's already in state, then we move it to the back of the queue.
      const index = parsed.findIndex((rc) => rc.value === search.value)!;
      const minusDuplicate = [...parsed];
      minusDuplicate.splice(index, 1);
      const newState: RecentSearch[] = [...minusDuplicate, search];
      window.localStorage.setItem(KEY, JSON.stringify(newState));
      return;
    }

    if (parsed.length === LIMIT) {
      parsed.shift();
    }
    const newState: RecentSearch[] = [...parsed, search];
    window.localStorage.setItem(KEY, JSON.stringify(newState));
  } else {
    const newState: RecentSearch[] = [search];
    window.localStorage.setItem(KEY, JSON.stringify(newState));
  }
}

export function getRecentSearchTerms(order: 'newest' | 'oldest' = 'newest'): RecentSearch[] {
  const stringifiedState = window.localStorage.getItem(KEY);
  if (isNull(stringifiedState)) {
    return [];
  }

  const parsed: RecentSearch[] = JSON.parse(stringifiedState);
  return order === 'newest' ? parsed.reverse() : parsed;
}
