import { ApolloError } from '@apollo/client';
import { CheckboxesFacet, RangeFacet } from '@cultwines/zellar-client-sdk/types/Filters';
import { mapRawFacetToCheckboxFacet, mapRawFacetToRangeFacet } from '@cultwines/zellar-client-sdk/utils/filtering';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { Namespace, Resources, TFunction } from 'react-i18next';
import ImagePlaceholder from '../../assets/images/stub-wine-image.png';
import { LiquidityScoreRating, WineType, WineVintage } from '../../components/SearchResults/types';
import selectDrinkingWindowAdvice from '../../graphql/selectors/selectDrinkingWindowAdvice';
import getImageUrl from '../../utils/getImageUrl';
import { isNull } from '../../utils/isNull';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { massageToNull } from '../../utils/massageToNull';
import { uuid } from '../../utils/uuid';
import {
  Asset,
  SearchWineVintagesFacets,
  SearchWineVintagesMutation,
  WineVintageResult,
} from '../../__generated__/graphql';

export type SearchWinesFacets = Partial<{
  Appellation: CheckboxesFacet<SearchWinesFacets>;
  Classification: CheckboxesFacet<SearchWinesFacets>;
  MarketValue: RangeFacet<SearchWinesFacets>;
  Region: CheckboxesFacet<SearchWinesFacets>;
  Score: RangeFacet<SearchWinesFacets>;
  PercentageDifference: RangeFacet<SearchWinesFacets>;
  Producer: CheckboxesFacet<SearchWinesFacets>;
  Vintage: CheckboxesFacet<SearchWinesFacets>;
  WineName: CheckboxesFacet<SearchWinesFacets>;
  LiquidityRank: CheckboxesFacet<SearchWinesFacets>;
}>;

const symbolToTitleMap = new Map<keyof SearchWinesFacets, string>([
  ['Score', '(%)'],
  ['MarketValue', '(£)'],
  ['PercentageDifference', ' (1YR) (%)'],
]);

export function selectFacets(
  filtersRaw: SearchWineVintagesFacets | undefined,
  t: TFunction<Namespace<keyof Resources>>,
): SearchWinesFacets {
  if (isUndefined(filtersRaw)) {
    return {};
  }

  const {
    WineName,
    Region,
    Score,
    MarketValue,
    PercentageDifference,
    Vintage,
    Appellation,
    Classification,
    Producer,
    LiquidityRank,
  } = filtersRaw;

  const searchWinesFacets: SearchWinesFacets = {
    Region: isUndefined(Region)
      ? undefined
      : mapRawFacetToCheckboxFacet({
          rawFacet: Region,
          parentId: 'Region',
          name: t('search:filters.region'),
          searchBoxPlaceholderText: t('search:filters.searchBoxPlaceholders.region'),
        }),
    Producer: isUndefined(Producer)
      ? undefined
      : mapRawFacetToCheckboxFacet({
          rawFacet: Producer,
          parentId: 'Producer',
          name: t('search:filters.producer'),
          searchBoxPlaceholderText: t('search:filters.searchBoxPlaceholders.producer'),
        }),
    Appellation: isUndefined(Appellation)
      ? undefined
      : mapRawFacetToCheckboxFacet({
          rawFacet: Appellation,
          parentId: 'Appellation',
          name: t('search:filters.appellation'),
          searchBoxPlaceholderText: t('search:filters.searchBoxPlaceholders.appellation'),
        }),
    Classification: isUndefined(Classification)
      ? undefined
      : mapRawFacetToCheckboxFacet({
          rawFacet: Classification,
          parentId: 'Classification',
          name: t('search:filters.classification'),
          searchBoxPlaceholderText: t('search:filters.searchBoxPlaceholders.classification'),
        }),
    WineName: isUndefined(WineName)
      ? undefined
      : mapRawFacetToCheckboxFacet({
          rawFacet: WineName,
          parentId: 'WineName',
          name: t('common:wineName'),
          searchBoxPlaceholderText: t('search:filters.searchBoxPlaceholders.wineName'),
        }),
    Vintage: isUndefined(Vintage) ? undefined : mapRawFacetToCheckboxFacet(Vintage, 'Vintage', t('common:vintage')),
    Score: isUndefined(Score)
      ? undefined
      : mapRawFacetToRangeFacet('Score', `${t('product:score')} ${symbolToTitleMap.get('Score')}`, uuid()),
    MarketValue: isUndefined(MarketValue)
      ? undefined
      : mapRawFacetToRangeFacet(
          'MarketValue',
          `${t('product:marketValue')} ${symbolToTitleMap.get('MarketValue')}`,
          uuid(),
        ),
    PercentageDifference: isUndefined(PercentageDifference)
      ? undefined
      : mapRawFacetToRangeFacet(
          'PercentageDifference',
          `${t('search:filters.performance')} ${symbolToTitleMap.get('PercentageDifference')}`,
          uuid(),
        ),
    LiquidityRank: isNullOrUndefined(LiquidityRank)
      ? undefined
      : mapRawFacetToCheckboxFacet({
          name: t('search:filters.liquidity'),
          parentId: 'LiquidityRank',
          rawFacet: LiquidityRank,
        }),
  };

  return searchWinesFacets;
}

export function selectLiquidityScore(score: number | null | undefined): LiquidityScoreRating | null {
  switch (score) {
    case 1: {
      return 1;
    }

    case 2: {
      return 2;
    }

    case 3: {
      return 3;
    }

    case 4: {
      return 4;
    }

    case 5: {
      return 5;
    }

    default: {
      return null;
    }
  }
}

export function selectSpread(spread: number | null | undefined): number | null {
  return isNullOrUndefined(spread) ? null : spread;
}

export function selectWineType(isSparkling: boolean | null | undefined): WineType | null {
  if (isNull(isSparkling)) {
    return null;
  }

  return isSparkling ? 'sparkling' : 'still';
}

function selectDefaultAsset(defaultAsset: Asset): {
  unitCount: number;
  unitSize: string;
  assetId: number;
  lowestOffer: number | null;
  highestBid: number | null;
} {
  if (
    isNullOrUndefined(defaultAsset.spread) ||
    isNullOrUndefined(defaultAsset.spread.lowestOffer) ||
    isNullOrUndefined(defaultAsset.spread.lowestOffer.assetId) ||
    isNullOrUndefined(defaultAsset.spread.lowestOffer.unitCount) ||
    isNullOrUndefined(defaultAsset.spread.lowestOffer.unitSize)
  ) {
    return {
      unitCount: defaultAsset.unitCount,
      unitSize: defaultAsset.unitSize,
      assetId: defaultAsset.id,
      lowestOffer: massageToNull(defaultAsset.spread?.lowestOffer?.price),
      highestBid: massageToNull(defaultAsset.spread?.highestBid?.price),
    };
  }

  return {
    unitCount: defaultAsset.spread.lowestOffer.unitCount,
    unitSize: defaultAsset.spread.lowestOffer.unitSize,
    assetId: defaultAsset.spread.lowestOffer.assetId,
    lowestOffer: massageToNull(defaultAsset.spread?.lowestOffer?.price),
    highestBid: massageToNull(defaultAsset.spread?.highestBid?.price),
  };
}

export function selectWines(
  results: WineVintageResult[] | undefined,
  t: TFunction<Namespace<keyof Resources>>,
): WineVintage[] {
  if (isUndefined(results) || results.length === 0) {
    return [];
  }
  return results.map((r) => {
    const defaultAsset = selectDefaultAsset(r.defaultAsset);
    return {
      wineVintageId: `${r.wineVintageId}`,
      defaultAssetId: `${defaultAsset.assetId}`,
      defaultAssetUnitCount: defaultAsset.unitCount,
      defaultAssetUnitSize: defaultAsset.unitSize,
      imageFileName: r.defaultAsset.vintage.imageFileName
        ? getImageUrl(r.defaultAsset.vintage.imageFileName, { height: 200 })
        : ImagePlaceholder,
      name: r.wineName,
      vintage: r.vintage,
      performance: {
        percentageDelta: !isNullOrUndefined(r.calculatedMarketData.performance.percentageDifference)
          ? parseFloat(r.calculatedMarketData.performance.percentageDifference.toFixed(2))
          : null,
        valueDelta: r.calculatedMarketData.performance.valueDifference ?? 0,
        marketData: {},
      },
      appellation: r.appellation || undefined,
      region: r.region,
      score: Number(r.score),
      marketValue: r.marketValue,
      highestBid: defaultAsset.highestBid,
      lowestOffer: defaultAsset.lowestOffer,
      classification: r.classification,
      producer: r.producer,
      drinkingWindow: {
        advice: selectDrinkingWindowAdvice(r.defaultAsset.vintage.drinkingWindow.advice),
        text:
          r.defaultAsset.vintage.drinkingWindow.dateFrom && r.defaultAsset.vintage.drinkingWindow.dateFrom.length > 0
            ? `${t('common:drinkingWindow.date')} ${r.defaultAsset.vintage.drinkingWindow.dateFrom}`
            : '',
      },
      lastTradeValue: r.lastTradePrice,
      lastTradeDate: r.lastTradeDate,
      liquidityScore: selectLiquidityScore(r.calculatedMarketData.liquidityScore),
      spread: selectSpread(r.defaultAsset.spread?.percentageDifference),
      wineType: selectWineType(r.defaultAsset.vintage.wine.sparkling),
    };
  });
}

export function selectWinesCount(data: SearchWineVintagesMutation | undefined | null): number {
  return data?.searchWineVintages?.total ?? 0;
}

export function selectError(error: ApolloError | undefined): string | null {
  if (isUndefined(error)) {
    return null;
  }

  return error.message;
}
