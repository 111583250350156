export const formatDateTime = (date: string | null): string | null => {
  // TODO: Should this return null if no date can be parsed, or should it throw an error?
  if (!date || Number.isNaN(Date.parse(date))) {
    return null;
  }
  const newDate = new Date(date);
  return newDate.toLocaleString('en-GB', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};
