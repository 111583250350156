import { Button, DialogActions } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import DialogTitle from '../Dialog/DialogTitle';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import Typography from '../Typography';
import { useStyles } from './styles';
import { UPDATE_MIT_CONSENT } from '../../graphql/mutations/updateMitConsent';
import { GET_STORED_CARD_DETAILS } from '../../graphql/queries/storedCardDetails';
import { cache } from '../../graphql/cache';
import { logError, logMessage } from '../../utils/logger';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

interface ConfirmDeleteMitProps {
  instrumentId: string | null | undefined;
  onClose: () => void;
  onFailure: () => void;
  onSuccess: () => void;
}

export default function ConfirmDeleteMit({
  instrumentId,
  onClose,
  onFailure,
  onSuccess,
}: ConfirmDeleteMitProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const [updateMitConsent, { loading: processingMitConsentRequest }] = useMutation(UPDATE_MIT_CONSENT, {
    refetchQueries: [{ query: GET_STORED_CARD_DETAILS }],
    onError: () => null,
  });

  async function onConfirm() {
    if (isNullOrUndefined(instrumentId)) {
      logMessage('Tried to delete a card mit but the instrumentId was null', {
        additionalInfo: { filename: 'src/components/ManageCardModal/ConfirmDeleteMit' },
      });
      onFailure();
      return;
    }

    try {
      const { data: updateMitConsentResponse, errors: updateMitConsentError } = await updateMitConsent({
        variables: {
          updateMitConsentInput: {
            consentFlag: false,
            instrumentId,
          },
        },
      });
      if (updateMitConsentResponse?.updateMitConsent.errorMessage) {
        throw new Error(updateMitConsentResponse?.updateMitConsent.errorMessage);
      }
      if (updateMitConsentError) {
        throw updateMitConsentError[0];
      }
      cache.evict({ fieldName: 'paymentGetCardDetails' });
      cache.evict({ fieldName: 'omsGetMyOrders' });
      onSuccess();
    } catch (error) {
      logError({
        originalError: error as Error,
        error: new Error('delete a card MIT request failed'),
        filename: 'ConfirmDeleteMitDialog',
        tags: { userFlow: 'payment' },
        additionalInfo: {
          errors: JSON.stringify(error),
        },
      });
      onFailure();
    }
  }

  return (
    <>
      <DialogTitle id="dialog-title" onClose={onClose}>
        <div className={classes.textCapitalize}>{t('wallet:manageCardModal.confirmTitle')}</div>
      </DialogTitle>

      <DialogContentBorderTopOnly dividers>
        <div className={`${classes.container} ${classes.textCenter}`}>
          <Typography variant="subtitle1">{t('wallet:manageCardModal.deleteMit.implication')}</Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            {t('wallet:manageCardModal.deleteMit.description')}
          </Typography>
          <Typography variant="subtitle1">{t('wallet:manageCardModal.proceed')}</Typography>
          <DialogActions className={classes.justifyCenter}>
            <Button disabled={processingMitConsentRequest} variant="outlined" color="info" onClick={onConfirm}>
              {t('common:yes')}
            </Button>
            <Button disabled={processingMitConsentRequest} variant="outlined" color="info" onClick={onClose} autoFocus>
              {t('common:no')}
            </Button>
          </DialogActions>
        </div>
      </DialogContentBorderTopOnly>
    </>
  );
}
