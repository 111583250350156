import React from 'react';
import { useHistory } from 'react-router-dom';
import { RecentSearch } from '../components/SearchInput/RecentSearch';
import { WineVintageSuggestion } from '../graphql/selectors/selectSearchWineVintagesSuggestions';
import { addRecentSearchTerm } from '../services/recentSearches';
import { uuid } from '../utils/uuid';
import { makeUrlSafe } from '../utils/common';
import { UrlPath } from '../types/Enums';

interface UseSearchBarProps {
  initialSearchTerm?: string;
  onSubmit?: (text: string) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSearchBar({ initialSearchTerm = '', onSubmit = () => {} }: UseSearchBarProps) {
  const [searchInputValue, setSearchInputValue] = React.useState(initialSearchTerm);
  const [searchBarActive, setSearchBarActive] = React.useState(false);
  const [searchBarCollapsed, setSearchBarCollapsed] = React.useState(true);
  const history = useHistory();

  function handleSearchValueChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    setSearchInputValue(e.target.value);
  }

  function handleSearchBarCollapse(): void {
    setSearchBarCollapsed(true);
  }

  function handleSearchBarExpand(): void {
    setSearchBarCollapsed(false);
  }

  function handleSearchBarFocus(): void {
    setSearchBarActive(true);
  }

  function handleClickAway(): void {
    setSearchBarActive(false);
  }

  function handleClearSearchInput(): void {
    setSearchInputValue('');
  }

  function handleSearchTermSubmitted(term: string): void {
    if (term.length === 0 || term.trim().length === 0) {
      return;
    }

    setSearchBarActive(false);
    setSearchBarCollapsed(true);
    if (onSubmit) {
      onSubmit(term);
      return;
    }
    const recentSearch: RecentSearch = {
      id: uuid(),
      value: term,
      url: `/search?term=${term}`,
      variant: 'search',
    };
    addRecentSearchTerm(recentSearch);
    history.push(`/search?term=${term}`);
  }

  function handleWineVintageSelected(wineVintage: WineVintageSuggestion): void {
    const { defaultAssetId, wineName, vintage } = wineVintage;
    const safeWineName = makeUrlSafe(wineName);
    const pageSlug = `${defaultAssetId}_${safeWineName}_${vintage}`;
    const prodPageUrl = `${UrlPath.PRODUCT_PAGE}/${pageSlug}`;

    setSearchBarActive(false);
    setSearchBarCollapsed(true);
    setSearchInputValue('');
    addRecentSearchTerm({
      id: uuid(),
      value: `${wineVintage.wineName} ${wineVintage.vintage}`,
      url: `${prodPageUrl}`,
      variant: 'wine',
      suggestion: wineVintage,
    });
    history.push(`${prodPageUrl}`);
  }

  function handleRegionSelected(region: string): void {
    setSearchBarActive(false);
    setSearchBarCollapsed(true);
    setSearchInputValue('');
    addRecentSearchTerm({
      id: uuid(),
      value: region,
      url: `/search?term=Region:(${region})`,
      variant: 'region',
    });

    history.push(`/search?term=Region:(${region})`);
  }

  function handleAppellationSuggestionSelected(appellation: string): void {
    setSearchBarActive(false);
    setSearchBarCollapsed(true);
    setSearchInputValue('');
    addRecentSearchTerm({
      id: uuid(),
      value: appellation,
      url: `/search?term=Appellation:(${appellation})`,
      variant: 'appellation',
    });

    history.push(`/search?term=Appellation:(${appellation})`);
  }

  function handleProducerSuggestionSelected(producer: string): void {
    setSearchBarActive(false);
    setSearchBarCollapsed(true);
    setSearchInputValue('');
    addRecentSearchTerm({
      id: uuid(),
      value: producer,
      url: `/search?term=Producer:(${producer})`,
      variant: 'producer',
    });

    history.push(`/search?term=Producer:(${producer})`);
  }

  return {
    setSearchInputValue,
    handleSearchValueChanged,
    handleSearchBarCollapse,
    handleSearchBarExpand,
    handleSearchTermSubmitted,
    handleWineVintageSelected,
    handleRegionSelected,
    handleAppellationSuggestionSelected,
    handleProducerSuggestionSelected,
    handleSearchBarFocus,
    handleClickAway,
    handleClearSearchInput,
    searchInputValue,
    searchBarActive,
    searchBarCollapsed,
  };
}
