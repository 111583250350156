import { graphql } from '../../__generated__';

export const GET_VINTAGES_BY_WINE_ID = graphql(`
  query VintagesByWineId($wineId: Int!) {
    productWineVintages(wineId: $wineId) {
      id
      vintage
    }
  }
`);
