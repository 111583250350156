/* eslint-disable react/jsx-no-duplicate-props */
import { InputBaseComponentProps } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import React from 'react';
import NumberFormatCustom from './NumberFormatCustom';

const useStyles = makeStyles((theme) => ({
  adornment: {
    // makes the adornment paragraph black because
    // somewhy it gets secondary text color - white
    '& p': { color: theme.palette.text.primary },
  },
}));
interface Props {
  className?: string;
  value: string;
  name: string;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  error?: boolean;
  helperText?: string | boolean;
  disabled?: boolean;
}

export default function CurrencyTextField({
  className = '',
  value,
  name,
  placeholder,
  onChange,
  onBlur,
  onKeyDown,
  error,
  helperText,
  disabled = false,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <TextField
      className={className}
      name={name}
      id={name}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      variant="outlined"
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      error={error}
      helperText={helperText}
      data-testid={name}
      InputProps={{
        inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>,
        startAdornment: (
          <InputAdornment className={classes.adornment} position="start">
            £
          </InputAdornment>
        ),
      }}
      inputProps={{ 'aria-label': name }}
    />
  );
}
