import { graphql } from '../../__generated__';

export const KYC_INITIATE = graphql(`
  mutation KycInitiate($input: KycInitiateInput!) {
    kycInitiate(kycInitiateInput: $input) {
      isKycInitiated
      errorMessage
    }
  }
`);
