import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import Typography from '../Typography';
import { GET_STORED_CARD_DETAILS } from '../../graphql/queries/storedCardDetails';
import { useStyles } from './styles';
import AddPaymentCardModal from './index';
import { useCreateCardPayment } from '../../hooks/useCreatePaymentCard';
import { selectCreatePaymentCardVariables } from './selectors';
import ConfirmDeleteCardDialog from './ConfirmDeleteCardDialog';
import PaymentCard from '../PaymentCard';
import { submitPaymentForm } from './helpers';

interface PaymentCardDetailsProps {
  amount: number;
  isSubmitting: boolean;
  setIsFormValid: (isValid: boolean) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  hideUseDifferentCard?: boolean;
  customSuccessUri?: string;
}

export default function PaymentCardDetails({
  amount,
  isSubmitting,
  setIsFormValid,
  setIsSubmitting,
  hideUseDifferentCard,
  customSuccessUri,
}: PaymentCardDetailsProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = useQuery(GET_STORED_CARD_DETAILS);
  const { createCardPayment } = useCreateCardPayment();
  const [instrumentSelectedForDeletion, setInstrumentSelectedForDeletion] = React.useState<null | undefined | string>(
    null,
  );
  const [paymentCardModalOpen, setPaymentCardModalOpen] = React.useState(false);
  const [exclusiveChecked, setExclusiveChecked] = React.useState([0, false]);

  const toggleExclusiveCheckbox = (index: number) => {
    const checked = index === exclusiveChecked[0] ? !exclusiveChecked[1] : true;
    setExclusiveChecked([index, checked]);
    setIsFormValid(checked);
  };

  function handleDeleteCardClicked(instrumentId: string | null | undefined): void {
    setInstrumentSelectedForDeletion(instrumentId);
  }

  async function handleCloseConfirmDeleteCardPrompt() {
    setInstrumentSelectedForDeletion(null);
  }

  async function submitForm() {
    const storedCardDetails = data?.paymentGetCardDetails?.[exclusiveChecked[0] as number];
    await submitPaymentForm(
      createCardPayment,
      selectCreatePaymentCardVariables({
        amount,
        instrumentId: storedCardDetails?.instrumentId,
        customSuccessUri,
      }),
    );
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (isSubmitting) {
      submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  useEffect(() => {
    const defaultCardIndex = data?.paymentGetCardDetails?.findIndex((c) => c.defaultMitFlag);
    if (!isUndefined(defaultCardIndex) && defaultCardIndex !== -1) {
      toggleExclusiveCheckbox(defaultCardIndex);
      // return;
    }

    // if (data?.paymentGetCardDetails?.length === 1) {
    //   toggleExclusiveCheckbox(0);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.paymentGetCardDetails]);

  return (
    <>
      <div className={classes.expandedContentContainer}>
        <Typography className={`${classes.flexBetween} ${classes.title}`} customVariant="body1" data-private>
          {t('wallet:payByCard.cardDetailsTitle')}
        </Typography>
        <Grid container spacing={2}>
          {data?.paymentGetCardDetails?.map((item, index) => (
            <Grid item xs={6} key={item.instrumentId}>
              <PaymentCard
                showCheckBox
                data={item}
                checked={Boolean(index === exclusiveChecked[0] && exclusiveChecked[1])}
                onChecked={() => toggleExclusiveCheckbox(index)}
                onDeleted={() => handleDeleteCardClicked(item.instrumentId)}
              />
            </Grid>
          ))}
        </Grid>

        {hideUseDifferentCard === true ? null : (
          <div className={classes.flexEnd}>
            <Button
              name="action-button"
              className={classes.differentCardBtn}
              onClick={() => setPaymentCardModalOpen(true)}
              disabled={amount === 0}
            >
              {t('wallet:payByCard.differentCard')}
            </Button>
          </div>
        )}
      </div>
      {instrumentSelectedForDeletion && (
        <ConfirmDeleteCardDialog
          open={Boolean(instrumentSelectedForDeletion)}
          handleClose={handleCloseConfirmDeleteCardPrompt}
          handleDone={handleCloseConfirmDeleteCardPrompt}
          instrumentId={instrumentSelectedForDeletion}
        />
      )}
      <AddPaymentCardModal open={paymentCardModalOpen} amount={amount} onClose={() => setPaymentCardModalOpen(false)} />
    </>
  );
}
