/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import DialogTitle from '../Dialog/DialogTitle';
import { DialogContent } from '../Dialog/DialogContent';
import InsertAmountField from './components/InsertAmountField';
import AvailableWithdrawalAmount from './components/AvailableWithdrawalAmount';
import { getInsertAmountValidationSchema, validateWithdrawFundsForm } from './helpers';
import { WITHDRAW_CONFIRMATION } from '../../graphql/mutations/withdrawConfirmation';
import { logError } from '../../utils/logger';
import { useAvailableWithdrawalAmount } from '../../hooks/useAvailableWithdrawalAmount';
import { useAvailableBalance } from '../../hooks/useAvailableBalance';
import DisplayForm from './DisplayForm';
import { WithdrawFundsModelType } from './types';

interface WithdrawFundsModalProps {
  onClose: () => void;
  onConfirmError: () => void;
  onConfirmSuccess: () => void;
}

const WithdrawFundsModal: React.FC<WithdrawFundsModalProps> = ({ onClose, onConfirmError, onConfirmSuccess }) => {
  const { t } = useTranslation();
  const { merchantInitiatedTransaction12341 } = useFlags();
  const [amount, setAmount] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const {
    availableWithdrawalAmount,
    loading: loadingAvailableWithdrawAmount,
    error: failedToLoadAvailableWithdrawAmount,
  } = useAvailableWithdrawalAmount({
    skip: !merchantInitiatedTransaction12341,
    pollInterval: 5000,
  });
  const {
    availableBalance: _availableBalance,
    loading: loadingAvailableBalance,
    error: failedToLoadAvailableBalance,
  } = useAvailableBalance({
    skip: merchantInitiatedTransaction12341,
  });
  const loading = merchantInitiatedTransaction12341 ? loadingAvailableWithdrawAmount : loadingAvailableBalance;
  const availableBalance = merchantInitiatedTransaction12341
    ? availableWithdrawalAmount?.availableWithdrawalAmount?.currentWithdrawalGbp ?? 0
    : _availableBalance;

  const insertAmountValidationSchema = getInsertAmountValidationSchema(t, availableBalance);
  const [withdrawConfirmationMutation] = useMutation(WITHDRAW_CONFIRMATION, {
    onError: () => null,
  });

  if (failedToLoadAvailableBalance || failedToLoadAvailableWithdrawAmount) {
    logError({
      originalError: failedToLoadAvailableBalance || failedToLoadAvailableWithdrawAmount,
      error: new Error('Failed to get available balance'),
      filename: 'WithdrawFundsModal',
      tags: { userFlow: 'payment' },
    });
  }

  const onFieldChange = useCallback(
    (value: string, fieldId: string, fieldValid: boolean) => {
      const amountValue = value.replace(/,/g, '');
      setAmount(amountValue);
      setIsFormValid(validateWithdrawFundsForm(amountValue, availableBalance, fieldValid));
    },
    [availableBalance],
  );

  const onConfirm = async (formData: WithdrawFundsModelType) => {
    const { errors } = await withdrawConfirmationMutation({
      variables: {
        input: { ...formData, amount: parseFloat(amount) },
      },
    });
    if (errors) {
      logError({
        originalError: errors[0],
        error: new Error('Withdraw funds request failed'),
        filename: 'WithdrawFundsModal',
        tags: { userFlow: 'payment' },
        additionalInfo: {
          errors: JSON.stringify(errors),
        },
      });
      onConfirmError();
    } else {
      onConfirmSuccess();
    }
  };

  return (
    <>
      <DialogTitle id="widthdraw-funds-dialog" onClose={onClose}>
        {t('wallet:withdrawFunds')}
      </DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <InsertAmountField
            onError={onFieldChange}
            isDisabled={(availableBalance || 0) === 0}
            value={amount}
            placeholder={t('wallet:topUpModal.amount.label')}
            onChange={onFieldChange}
            validationSchema={insertAmountValidationSchema}
          />
          <AvailableWithdrawalAmount />
        </div>
      </DialogContent>
      <DialogContent>
        <DisplayForm onSubmit={onConfirm} disabled={!isFormValid || loading} />
      </DialogContent>
    </>
  );
};

export default WithdrawFundsModal;
