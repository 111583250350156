import React from 'react';
import clsx from 'clsx';
import MuiAppBar from '@mui/material/AppBar';
import ToolBar from '@mui/material/Toolbar';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import IconButton from '../IconButton';
import SearchInput from '../SearchInput';
import NotificationsIconButton from '../NotificationsIconButton';
import HamburgerMenu from '../HamburgerMenu';
import UserOptionsMenu from '../UserOptionsMenu';
import { isLoggedInVar } from '../../graphql/cache';
import { useSearchBar } from '../../hooks/useSearchBar';
import { useQueryParameters } from '../../hooks';
import useMediaQuery from '../../hooks/useMediaQuery';
import { UrlPath } from '../../types/Enums';
import { ReactComponent as LoggedOutAvatar } from '../../assets/icons/user-loggedout.svg';

const useStyles = makeStyles((theme) => ({
  appbarContainer: {
    '@media (min-width: 768px) and (min-height: 750px)': {
      borderTopLeftRadius: '20px',
    },
  },
  title: {
    marginLeft: 12,
    marginRight: 12,
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    marginLeft: 'auto',
    width: '50%',
    justifyContent: 'flex-end',
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  avatarIconButton: {
    display: 'none',
    '@media (min-width: 768px) and (min-height: 750px)': {
      display: 'block',
    },
  },
  hamburgerButton: {
    display: 'block',
    '@media (min-width: 768px) and (min-height: 750px)': {
      display: 'none',
    },
  },
  backButton: {
    backgroundColor: theme.palette.grey[100],
  },
  fullWidth: {
    width: '100%',
  },
  hide: {
    display: 'none',
  },
}));

export interface AppBarProps {
  title?: string;
  displayBackButton?: boolean;
  backButtonUrl?: string;
}

export default function AppBar({
  title,
  displayBackButton = false,
  backButtonUrl = undefined,
}: AppBarProps): JSX.Element {
  const query = useQueryParameters();
  const theme = useTheme();
  const greaterThanLg = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.lg));
  const searchTermQueryParameter = query.get('term');
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [userOptionsMenuAnchor, setUserOptionsMenuAnchor] = React.useState<null | HTMLElement>(null);
  const userOptionsMenuOpen = Boolean(userOptionsMenuAnchor);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const {
    searchBarActive,
    searchBarCollapsed,
    handleAppellationSuggestionSelected,
    handleProducerSuggestionSelected,
    handleRegionSelected,
    handleSearchBarCollapse,
    handleSearchBarExpand,
    handleSearchTermSubmitted,
    handleSearchValueChanged,
    handleWineVintageSelected,
    searchInputValue,
    handleClickAway,
    handleSearchBarFocus,
    handleClearSearchInput,
  } = useSearchBar({ initialSearchTerm: searchTermQueryParameter ?? undefined });

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>): void {
    setUserOptionsMenuAnchor(event.currentTarget);
  }

  function handleMenuClose(): void {
    setUserOptionsMenuAnchor(null);
  }

  function handleBackButtonClick(): void {
    handleClearSearchInput();
    /**
     * -1 is equal to one step back in the router
     * history. See https://reactrouter.com/web/api/history
     */

    if (backButtonUrl) history.push(backButtonUrl);
    else history.go(-1);
  }

  return (
    <MuiAppBar position="sticky" className={classes.appbarContainer} color="secondary">
      <ToolBar>
        {displayBackButton && (
          <IconButton
            variant="filled"
            onClick={handleBackButtonClick}
            className={clsx(classes.backButton, { [classes.hide]: !searchBarCollapsed && !greaterThanLg })}
            aria-label="back-button"
            size="large"
          >
            <ArrowBack />
          </IconButton>
        )}
        {title && (
          <Typography
            className={clsx(classes.title, { [classes.hide]: !searchBarCollapsed && !greaterThanLg })}
            customVariant="h1"
          >
            {title}
          </Typography>
        )}
        <div className={clsx(classes.right, { [classes.fullWidth]: !searchBarCollapsed && !greaterThanLg })}>
          <SearchInput
            active={searchBarActive}
            onExpand={handleSearchBarExpand}
            onCollapse={handleSearchBarCollapse}
            onFocus={handleSearchBarFocus}
            onClickAway={handleClickAway}
            collapsible
            collapsed={searchBarCollapsed}
            onChange={handleSearchValueChanged}
            searchTermSubmitted={handleSearchTermSubmitted}
            onClearInput={handleClearSearchInput}
            inputValue={searchInputValue}
            placeholder={t('overview:searchPlaceholder')}
            wineVintageSelected={handleWineVintageSelected}
            regionSelected={handleRegionSelected}
            appellationSelected={handleAppellationSuggestionSelected}
            producerSelected={handleProducerSuggestionSelected}
          />
          <NotificationsIconButton />
          <IconButton
            className={classes.avatarIconButton}
            onClick={(e) => {
              if (isLoggedIn) {
                handleMenuOpen(e);
              } else {
                history.push(UrlPath.LOGIN);
              }
            }}
            size="large"
          >
            {isLoggedIn ? (
              <Avatar alt={`${t('common:profile')}`.toLowerCase()} className={classes.avatar} />
            ) : (
              <div className="tw-bg-[#bdbdbd] tw-rounded-full tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center ">
                <LoggedOutAvatar height={15} width={15} />
              </div>
            )}
          </IconButton>

          <HamburgerMenu className={classes.hamburgerButton} />
          <UserOptionsMenu
            anchorEl={userOptionsMenuAnchor}
            open={userOptionsMenuOpen}
            onClose={handleMenuClose}
            optionSelected={handleMenuClose}
            /**
             * It's very awkward to simply place this popover menu below the button which opens it,
             * had to apply the following three props in this way to make it work. See
             * https://medium.com/cloud-native-the-gathering/material-ui-how-to-change-a-menus-menuitems-anchor-to-popover-below-instead-of-over-ab222e175cfd
             * for more info. Material UI by design has the popover appear over (z) the element you clicked to open it,
             * instead of below (y), which is what we want to do.
             */
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
        </div>
      </ToolBar>
    </MuiAppBar>
  );
}
