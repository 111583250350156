import { CardPaymentInput } from '../../__generated__/graphql';

interface PaymentCardVariables {
  amount: number;
  clientName?: string;
  storeCardFlag?: boolean;
  cardToken?: string;
  instrumentId?: string | null;
  mitConsentedFlag?: boolean | null;
  defaultMitFlag?: boolean | null;
  customSuccessUri?: string;
}

export function selectCreatePaymentCardVariables({
  amount,
  clientName,
  storeCardFlag,
  cardToken,
  instrumentId,
  mitConsentedFlag,
  defaultMitFlag,
  customSuccessUri,
}: PaymentCardVariables): CardPaymentInput {
  const baseReturnUri = window.location.origin + window.location.pathname;
  const successReturnUri = customSuccessUri
    ? `${baseReturnUri}${customSuccessUri}`
    : `${baseReturnUri}${amount === 0 ? '#successAddCard' : '#success'} `;
  const failureReturnUri = `${baseReturnUri}#failure`;

  return {
    amount,
    clientName,
    storeCardFlag,
    cardToken,
    instrumentId,
    mitConsentedFlag,
    defaultMitFlag,
    failureUrl: failureReturnUri,
    successUrl: successReturnUri,
  };
}
