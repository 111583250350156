import { useQuery } from '@apollo/client';
import { AVAILABLE_WITHDRAWAL_AMOUNT } from '../graphql/queries/availableWithdrawalAmount';

interface UseAvailableWithdrawAmountProps {
  skip?: boolean;
  pollInterval?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAvailableWithdrawalAmount = (props?: UseAvailableWithdrawAmountProps) => {
  const { data, loading, error } = useQuery(AVAILABLE_WITHDRAWAL_AMOUNT, {
    skip: props?.skip,
    pollInterval: props?.pollInterval ?? 5000,
  });
  return { availableWithdrawalAmount: data, loading, error };
};
