/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Squares } from '../../assets/images/overlapping-squares.svg';
import Typography from '../Typography';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    position: 'absolute',
    top: 50,
    left: '50%',
    // Remove offset of half element width for left 50% to center
    // Source: https://stackoverflow.com/a/7558734
    marginLeft: -150,

    '&:hover': {
      opacity: 0.5,
    },
    '&:active': {
      opacity: 0.3,
    },
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 210,
    height: 210,
    borderRadius: '50%',
    background: theme.palette.text.primary,
    marginBottom: theme.spacing(7.5),
  },
  text: {
    width: 300,
    userSelect: 'none',
  },
}));

interface Props {
  handleClick: () => void;
}

export function CallToAction({ handleClick }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div onClick={handleClick} className={classes.container}>
      <div className={classes.circle}>
        <Squares />
      </div>
      <Typography className={classes.text} variant="h4">
        {t('market:ctaPlaceholder')}
      </Typography>
    </div>
  );
}
