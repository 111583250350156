import { graphql } from '../../__generated__';

export const MONTHWISE_FLOW_QUERY = graphql(`
  query FinanceGetMonthlyWiseFlowByMonthRange($monthRange: Int!) {
    financeGetMonthlyWiseFlowByMonthRange(monthRange: $monthRange) {
      monthlyExpenses {
        monthDate
        monthName
        inward
        outward
      }
    }
  }
`);
