import makeStyles from '@mui/styles/makeStyles';

export const useMyBidsStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gap: theme.spacing(4),
    },
  },
  cardList: {
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
    padding: '0 10px',
  },
  card: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: `calc(50% - ${theme.spacing(2)})`,
    },

    [theme.breakpoints.up('xl')]: {
      width: `calc(33% - ${theme.spacing(2.2)})`,
    },
  },

  filters: {
    background: theme.palette.grey[50],
  },
  orders: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      minWidth: '75%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));
