import { graphql } from '../../__generated__';

export const OWNED_ASSET_INSTANCES_QUERY = graphql(`
  query OwnedAssetInstances($assetId: Int!) {
    productAssetInstances(assetId: $assetId) {
      id
      location
      bondedStatus
      entryDate
      traceability
      purchasePrice
      valuation
      assetId
      transferRequested
    }
  }
`);
