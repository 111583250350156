import { CheckboxesFacet } from '@cultwines/zellar-client-sdk';

export function getFilterfilters<T>(facet: CheckboxesFacet<T>, filterString: string): Array<{ label: string }> {
  const filteredFilters =
    filterString.length > 0
      ? facet.filters.filter((f) =>
          f.label.toLocaleLowerCase().includes(
            filterString
              // method returns the Unicode Normalization Form of the string (exp: special characters)
              // https://stackoverflow.com/questions/63013552/whats-the-point-of-string-normalize
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLocaleLowerCase(),
          ),
        )
      : facet.filters;
  return filteredFilters;
}
