import { CheckboxFilter, RangeFilter } from '@cultwines/zellar-client-sdk';

const groupBy = function <TItem>(xs: TItem[], key: string): { [key: string]: TItem[] } {
  return xs.reduce(function (rv: any, x: any) {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const buildActiveFilterString = <T>(activeFilters: Array<RangeFilter<T> | CheckboxFilter<T>>) => {
  const groupedActiveFilters = groupBy(activeFilters, 'parentId');
  const filterString = Object.keys(groupedActiveFilters).reduce((resultString, af) => {
    const filterStr = groupedActiveFilters[af].map((x) => `${x.parentId as string}:('${x.id}')`).join(' OR ');
    return `${resultString ? `${resultString} AND ` : ''} (${filterStr})`.trimEnd();
  }, '');

  return filterString;
};
