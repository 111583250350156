import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { isMobileDimensions } from '../utils/isMobileDimensions';

interface PrivateRouteProps extends RouteProps {
  children: React.ReactNode;
  isLoggedIn: boolean;
}

export default function PrivateRoute(props: PrivateRouteProps): JSX.Element {
  const { children, isLoggedIn, ...rest } = props;
  const isMobile = isMobileDimensions();

  // Add support for cultx web app to redirect user to portfolio or product if user viewing on mobile device and not logged in
  if (isMobile && !isLoggedIn) {
    if (window.location.href.includes('portfolio')) {
      return <Redirect to="/redirect-to-mobile-app/portfolio" />;
    }
    if (window.location.href.includes('product')) {
      const assetId = window.location.href.split('/').splice(-1)[0];
      return <Redirect to={`/redirect-to-mobile-app/product/${assetId}`} />;
    }
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return isLoggedIn ? <Route {...rest}>{children}</Route> : <Redirect to="/login" />;
}
