import { graphql } from '../../__generated__';

export const AVAILABLE_UNIT_SIZES = graphql(`
  query UnitSizesByAssetId($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      unitSize
      unitCount
      vintage {
        id
        assets {
          id
          unitSize
          unitCount
        }
      }
    }
  }
`);

export const UNIT_SIZE_QUERY = graphql(`
  query UnitSizeByAssetId($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      unitSize
      unitCount
      tradingInfo {
        lastTraded
        lastTradedValue
      }
    }
  }
`);
