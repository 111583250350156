import { ApolloError, useQuery } from '@apollo/client';
import { GET_MARKET_DATA_QUERY } from '../graphql/queries/marketData';
import isUndefined from '../utils/isUndefined';
import { DateRange } from '../utils/calculateDateRange';

interface Props {
  assetId: number | undefined;
  dateRange: DateRange;
}

interface ReturnType {
  loading: boolean;
  error: ApolloError | undefined;
  percentageDifference: number | null | undefined;
}

export function usePercentageDifference({ assetId, dateRange }: Props, isLoggedIn = false): ReturnType {
  const { data, loading, error } = useQuery(GET_MARKET_DATA_QUERY, {
    variables: {
      assetId: assetId ?? 0,
      dateFrom: dateRange.from,
      dateTo: dateRange.to,
      includeWineSearcher: false,
    },
    skip: isUndefined(assetId),
    ...(isLoggedIn
      ? {}
      : {
          context: {
            serviceName: 'insecure',
          },
        }),
  });

  return {
    percentageDifference: data?.productAsset?.marketData.performance.percentageDifference,
    loading,
    error,
  };
}
