import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

const useStyles = makeStyles((theme) => ({
  activeButton: {
    background: theme.palette.button.tertiary.background.main,
    color: theme.palette.button.tertiary.text.main,
    '&:disabled': {
      background: theme.palette.button.tertiary.background.disabled,
      color: theme.palette.button.tertiary.text.disabled,
    },
    '&:hover': {
      background: theme.palette.button.tertiary.background.hover,
    },
  },
}));

export interface ToggleButtonProps {
  toggleState: boolean;
  onToggle: (toggleState: boolean) => void;
  trueStateName: string;
  falseStateName: string;
}

export default function ToggleButton({
  toggleState,
  onToggle,
  trueStateName,
  falseStateName,
}: ToggleButtonProps): JSX.Element {
  const classes = useStyles();
  return (
    <ButtonGroup color="info" disableElevation variant="outlined" size="small">
      <Button
        className={clsx({
          [classes.activeButton]: !toggleState,
        })}
        onClick={() => onToggle(false)}
      >
        {falseStateName}
      </Button>
      <Button
        className={clsx({
          [classes.activeButton]: toggleState,
        })}
        onClick={() => onToggle(true)}
      >
        {trueStateName}
      </Button>
    </ButtonGroup>
  );
}
