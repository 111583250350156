import makeStyles from '@mui/styles/makeStyles';

export const useMyAddressStyles = makeStyles((theme) => ({
  fields: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  section: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  address: {
    padding: theme.spacing(6),
    display: 'flex',
    alignItems: 'flex-end',
  },
  border: {
    border: `1px solid ${theme.palette.grey[100]}`,
  },
  addressDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    marginRight: theme.spacing(2.5),
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(2.5),
    alignItems: 'center',
  },
  rightAlign: {
    marginLeft: 'auto',
  },
}));
