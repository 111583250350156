import { useCallback } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useQueryParams } from './useQueryParams';

export const useRedirectToApp = () => {
  const { pathname } = useLocation();
  const match = useRouteMatch(['/login', '/register']);
  const queryParams = useQueryParams();
  const productId = queryParams.get('id');

  return useCallback(() => {
    if (match && productId) {
      window.location.href = `cultx://product/${productId}`;
      return;
    }
    window.location.href = `cultx:/${pathname}`;
  }, [match, pathname, productId]);
};
