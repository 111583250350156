import { useQuery, ApolloError } from '@apollo/client';
import { graphql } from '../__generated__';
import { UserPortfolioHighlightsQuery } from '../__generated__/graphql';
import { logError } from '../utils/logger';

interface UserPortfolioHighlightsResponse {
  userPortfolioHighlights: UserPortfolioHighlightsQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const PORTFOLIO_HIGHLIGHTS = graphql(`
  query UserPortfolioHighlights {
    userPortfolioHighlights {
      portfolioValue
      totalContributions
      simpleReturn
    }
  }
`);

export const useUserPortfolioHighlights = (): UserPortfolioHighlightsResponse => {
  const {
    data: userPortfolioHighlights,
    error,
    loading,
  } = useQuery(PORTFOLIO_HIGHLIGHTS, {
    onError: (e) => {
      logError({
        originalError: e,
        error: new Error('userPortfolioHighlights query failed'),
        filename: 'useUserPortfolioHighlights.ts',
      });
    },
  });

  return { userPortfolioHighlights, loading, error };
};
