import { graphql } from '../../__generated__';

export const SUGGEST_WINES = graphql(`
  query SuggestWines($queryString: String!) {
    suggestWines(queryString: $queryString) {
      suggests {
        wineId
        wineName
      }
    }
  }
`);
