import { ApolloError, useQuery } from '@apollo/client';
import React from 'react';
import { PRODUCT_DATA_QUERY } from '../../graphql/queries/productData';
import { selectNormalisedProduct } from '../ProductSummary/selectors';
import { Asset } from '../../__generated__/graphql';

interface UseDisplayNameParams {
  assetId: number;
}

interface UseDisplayNameReturnType {
  displayName: string | undefined;
  loading: boolean;
  error?: ApolloError;
}

export function useDisplayName({ assetId }: UseDisplayNameParams): UseDisplayNameReturnType {
  const { data, loading, error } = useQuery(PRODUCT_DATA_QUERY, {
    variables: {
      assetId,
    },
  });

  const product = React.useMemo(() => selectNormalisedProduct(data?.productAsset as Asset), [data]);

  return {
    displayName: product.displayName,
    loading,
    error,
  };
}
