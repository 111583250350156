import { graphql } from '../../__generated__';

export const CONFIRM_MANUAL_BANK_TRANSFER = graphql(`
  mutation ConfirmManualBankTransfer {
    confirmManualBankTransfer {
      isSuccess
      errorMessage
    }
  }
`);
