import React from 'react';

type ColourRegister = Record<string, string | null>;
export type AssetColours = Record<string, string>;

interface UseColourMapReturnType {
  update: (assetIds: string[]) => void;
  colourRegister: ColourRegister;
  assetColours: AssetColours;
}

function getDefaultRegisterState(colours: readonly string[]): ColourRegister {
  const obj = colours.reduce((prev, curr) => {
    return { ...prev, [curr]: null };
  }, {});
  return obj;
}

export default function useColourMap(colours: readonly string[]): UseColourMapReturnType {
  const [colourRegister, setColourRegister] = React.useState<ColourRegister>(getDefaultRegisterState(colours));
  const [assetColours, setAssetColours] = React.useState<AssetColours>({});

  function update(assetIds: string[]): void {
    if (assetIds.length > Object.keys(colourRegister).length) {
      // eslint-disable-next-line no-console
      console.error('You have more asset Ids than colours.');
      return;
    }
    const availableColours = Object.keys(colourRegister).filter((colour) => {
      if (colourRegister[colour] === null) {
        return true;
      }

      return !assetIds.includes(colourRegister[colour]!);
    });
    const currentAssets = Object.values(assetIds).filter((asset) => Object.values(colourRegister).includes(asset));
    const assetsThatNeedAColour = assetIds.filter((assetId) => !currentAssets.includes(assetId));
    const updatedColourRegister = availableColours.reduce(
      (o, key, i) => ({ ...o, [key]: assetsThatNeedAColour[i] ? assetsThatNeedAColour[i] : null }),
      colourRegister,
    );
    const updatedAssetColours: AssetColours = Object.keys(updatedColourRegister)
      .filter((colour) => updatedColourRegister[colour] !== null)
      .map((colour) => ({ [updatedColourRegister[colour] as string]: colour }))
      .reduce((prev, curr) => ({ ...prev, ...curr }), {});

    setColourRegister(updatedColourRegister);
    setAssetColours(updatedAssetColours);
  }

  return {
    update,
    colourRegister,
    assetColours,
  };
}
