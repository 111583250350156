/* eslint-disable @typescript-eslint/no-var-requires */
import { makeStyles } from 'tss-react/mui';
import * as Highcharts from 'highcharts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatterWholeNumber } from '../../utils/currencyFormatter';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary,
  },
  /**
   * Unable to reference any of the custom typography
   * variants that have been added here without breaking
   * the application when it tries to render the tooltip.
   * It is directly related to how this component is rendered,
   * as the custom variants can be accessed in other components
   * without issue.
   */
  title: {
    fontSize: '14px',
    fontWeight: 700,
  },
}));

interface Props {
  point: Highcharts.Point;
}

export default function Tooltip({ point }: Props): JSX.Element {
  const { classes: classNames } = useStyles();
  const { t } = useTranslation();
  const { y } = point.options;
  const isInward = point.series.index === 0;
  return (
    <span className={classNames.container}>
      <span className={classNames.title}>{formatterWholeNumber.format(Number(y))}</span>
      <span>{isInward ? t('wallet:monthWiseFlow.inward') : t('wallet:monthWiseFlow.outward')}</span>
    </span>
  );
}
