/* eslint-disable no-nested-ternary */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPlaceholder from '../ErrorPlaceholder';
import EmptyPlaceholder from '../EmptyPlaceholder';
import { ExternalPortfolioInstance } from '../../__generated__/graphql';
import BasicCell from '../Table/Cells/Basic';
import { formatter } from '../../utils/currencyFormatter';
import { humanReadableDate } from '../../utils/humanReadableDate';
import ActionsCell from '../TableElements/ActionsCell';
import IconButton from '../IconButton';
import { ReactComponent as Trash } from '../../assets/images/trash.svg';
import RowSkeleton from '../TableElements/RowSkeleton';
import { COLUMN_COUNT } from './types';
import TableCellHead from '../TableElements/TableCellHead';

interface SubTableProps {
  instances: ExternalPortfolioInstance[] | undefined;
  loading: boolean;
  error: string | null;
  onDeleteClicked: (assetId: number) => void;
}

export default function ExternalPortfolioSubTable({
  instances,
  loading,
  error,
  onDeleteClicked,
}: SubTableProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <TableContainer>
      <Table aria-label="external portfolio sub table">
        <TableHead>
          <TableRow>
            <TableCellHead hideSortButton />
            <TableCellHead title={t('portfolio:table.headers.location')} hideSortButton />
            <TableCellHead title={t('portfolio:externalPortfolio.currentLocation')} hideSortButton />
            <TableCellHead title={t('portfolio:table.headers.purchasePrice')} hideSortButton />
            <TableCellHead title={t('portfolio:externalPortfolio.purchaseDate')} hideSortButton />
            <TableCellHead title={t('common:actions')} hideSortButton />
          </TableRow>
        </TableHead>
        <TableBody sx={{ background: theme.palette.grey[50] }}>
          {loading ? (
            <RowSkeleton columnCount={COLUMN_COUNT} rowCount={3} />
          ) : error ? (
            <TableCell align="center" colSpan={COLUMN_COUNT}>
              <ErrorPlaceholder error={error} />
            </TableCell>
          ) : !instances?.length ? (
            <TableCell align="center" colSpan={COLUMN_COUNT}>
              <EmptyPlaceholder label={t('common:empty')} />
            </TableCell>
          ) : (
            instances.map((instance) => (
              <TableRow key={instance.id}>
                <TableCell width={36} />
                <BasicCell mainContent={t('common:unavailable')} />
                <BasicCell mainContent={t('common:unavailable')} />
                <BasicCell mainContent={formatter.format(instance.purchasePrice ?? 0)} />
                <BasicCell
                  mainContent={instance.purchaseDate ? humanReadableDate(instance.purchaseDate.slice(0, 10)) : '--'}
                />
                <ActionsCell border="left">
                  <IconButton key="delete-button" onClick={() => onDeleteClicked(instance.id)} size="large">
                    <Trash style={{ width: '1em', height: '1em' }} />
                  </IconButton>
                </ActionsCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
