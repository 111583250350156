import isUndefined from './isUndefined';

export default function normalizeChartDateData(
  data: Record<string, number> | undefined,
): Array<{ x: number; y: number }> {
  if (isUndefined(data)) {
    return [];
  }
  return (
    Object.entries(data)
      .map((d) => ({ x: new Date(d[0]).getTime(), y: d[1] }))
      // Fixes 10575
      // For some reason this function screws up the order of the rebased data on 5y, when converting from
      // the key (e.g. 2022-01-01) to the ticks value. I haven't established why this is happening, and why
      // it only appears to happen for this specific scenario (absolute data seems to be completely fine),
      // but sorting at this point in ascending order fixes the issue, and that's all I have time for at present.
      .sort(({ x: x1 }, { x: x2 }) => x1 - x2)
  );
}
