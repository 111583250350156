import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useMyAccountStyles = makeStyles<Theme, { appLayoutContentPadding: number }>((theme) => ({
  container: {
    height: '100%',
  },
  titleDivider: {
    // Negate padding on MainApplicationLayout content.
    marginLeft: (props) => `-${props.appLayoutContentPadding}px`,
    marginRight: (props) => `-${props.appLayoutContentPadding}px`,

    marginTop: theme.spacing(3),
  },
}));
