import Chip from '@mui/material/Chip';
import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DateRangeVariant } from '../../types';
import calculateDateRange from '../../utils/calculateDateRange';
import { deltaPercentage } from '../../utils/deltaPercentage';
import Typography from '../Typography';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { colours } from '../../theme/light/palette';
import { usePercentageDifference } from '../../hooks/usePercentageDifference';

const useStyles = makeStyles((theme) => ({
  chip: {
    minWidth: 45,
    marginRight: theme.spacing(3),
    background: colours.lightGrey,
    '&.MuiChip-filled': {
      color: colours.white,
      background: colours.darkBlue,
    },
  },
}));

interface Props {
  selectedDateRangeVariant: DateRangeVariant;
  onClick: (newDateRange: DateRangeVariant) => void;
  disabled?: boolean;
  assetId?: number;
  rangeVariant: DateRangeVariant;
}

function selectPerformance(percentageDifference: number | null | undefined) {
  if (isNullOrUndefined(percentageDifference)) {
    return '';
  }
  const { zellarColour, deltaSymbol } = deltaPercentage(percentageDifference);
  return (
    <Typography zellarColour={zellarColour} display="inline">
      {` (${deltaSymbol ?? ''}${percentageDifference.toFixed(2)}%)`}
    </Typography>
  );
}

export default function PerformanceChip({
  rangeVariant,
  selectedDateRangeVariant,
  disabled = false,
  assetId,
  onClick,
}: Props): JSX.Element {
  const classes = useStyles();
  const dateRange = calculateDateRange(rangeVariant);
  const { percentageDifference } = usePercentageDifference({ assetId, dateRange });
  const performance = useMemo(() => selectPerformance(percentageDifference), [percentageDifference]);

  return (
    <Chip
      className={classes.chip}
      onClick={() => onClick(rangeVariant)}
      label={
        <>
          {rangeVariant}
          {performance}
        </>
      }
      variant={disabled || rangeVariant === selectedDateRangeVariant ? 'filled' : 'outlined'}
      size="small"
      color="default"
      disabled={disabled}
    />
  );
}
