import { FormikContextType, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { isOlderThan } from '../../../utils/isOlderThan';
import { isDateValid } from '../../../utils/isDateValid';

export function useEditDateOfBirth({
  dateOfBirthUpdated,
}: {
  dateOfBirthUpdated: (email: string) => Promise<void>;
}): FormikContextType<{ dateOfBirth: string }> {
  const { t } = useTranslation();
  const schema = yup.object({
    dateOfBirth: yup
      .string()
      .required(t('account:accountDetails.dateOfBirth.required'))
      .test(
        'val',
        t('account:accountDetails.dateOfBirth.invalidFormat'),
        (val) => val === undefined || isDateValid(val),
      )
      .test(
        'val',
        t('account:accountDetails.dateOfBirth.tooYoung'),
        (val) => val === undefined || isOlderThan(val, 18),
      ),
  });

  const formik = useFormik<yup.InferType<typeof schema>>({
    initialValues: {
      dateOfBirth: '',
    },
    onSubmit: async ({ dateOfBirth: newEmailAddress }, { resetForm }) => {
      await dateOfBirthUpdated(newEmailAddress);
      resetForm();
    },
    validationSchema: schema,
  });

  return formik;
}
