import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RegistrationForm from '../../components/RegistrationForm';
import Splash from '../../components/Splash';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useStyles } from './styles';
import SellingPoint from '../LoginV2/SellingPoint';
import LoginV2 from '../LoginV2';
import { UrlPath } from '../../types/Enums';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Register_old(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const [userHasRegistered, setUserHasRegistered] = React.useState(false);

  function onUserRegisteredSuccessfully() {
    setUserHasRegistered(true);
  }

  return (
    <Splash>
      <div className={classes.container}>
        {userHasRegistered ? (
          <Typography variant="h2" textAlign="center">
            {t('account:register.success')}
          </Typography>
        ) : (
          <>
            {greaterThanSm && (
              <div className={`${classes.marketing} ${classes.column}`}>
                <div className={classes.header}>
                  <Typography variant="h1">{t('account:auth_v2.heading')}</Typography>
                  <Typography variant="h4">{t('account:auth_v2.signUpSubHeading')}</Typography>
                </div>
                <div className={classes.marketingWidgets}>
                  <SellingPoint
                    title={t('account:register.sellingPoint1.title')}
                    text={t('account:register.sellingPoint1.text')}
                  />
                  <SellingPoint
                    title={t('account:register.sellingPoint2.title')}
                    text={t('account:register.sellingPoint2.text')}
                  />
                  <SellingPoint
                    title={t('account:register.sellingPoint3.title')}
                    text={t('account:register.sellingPoint3.text')}
                  />
                </div>
                <Typography variant="subtitle2" color="textSecondary">
                  {`${t('account:register.footer1')} ${t('account:register.footer2')} ${t('account:register.footer3')}`}
                </Typography>
              </div>
            )}
            <Box className={`${classes.formBox} ${classes.column}`}>
              <RegistrationForm onUserRegisteredSuccessfully={onUserRegisteredSuccessfully} />
            </Box>
          </>
        )}
      </div>
    </Splash>
  );
}

export default function Register(): JSX.Element {
  return <LoginV2 tab={UrlPath.REGISTER} />;
}
