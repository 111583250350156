import * as Highcharts from 'highcharts';

interface Position {
  x: number;
  y: number;
}

/**
 * Calculate the Watermark's center positions in chart
 * @param chart
 */
export default function calculateWatermarkPosition(chart: Highcharts.Chart): Position {
  return {
    x: chart.plotLeft + chart.plotWidth / 2 - 60,
    y: chart.plotTop + chart.plotHeight / 2 - 53,
  };
}
