import { styled } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { colours } from '../../theme/light/palette';
import { TradingTileMode } from './types';

export const useStyles = makeStyles<Theme, { mode: TradingTileMode }>(() => ({
  tile: {
    display: 'flex',
    height: 72,
    width: 72,
    borderRadius: 4,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: (props) => (props.mode === 'bid' ? colours.lightYellow : colours.cultPurple),
  },

  spaceEvenly: {
    justifyContent: 'space-evenly',
  },

  justifyCenter: {
    justifyContent: 'center',
  },
}));

export const ButtonCard = styled('button')(({ mode }: { mode: TradingTileMode }) => ({
  width: 72,
  height: 72,
  borderRadius: 4,
  border: 'none',
  cursor: 'pointer',
  backgroundColor: mode === 'bid' ? colours.lightYellow : colours.cultPurple,

  '&:hover': {
    backgroundColor: mode === 'bid' ? colours.lightYellow70Percent : colours.cultPurple70Percent,
  },
}));
