import { Namespace, Resources, TFunction } from 'react-i18next';
import * as yup from 'yup';
import { ModelKey, ModelValidationSchemaType } from '../types';

export const getWithdrawUKValidationSchema = (t: TFunction<Namespace<keyof Resources>>) => {
  return yup.object({
    [ModelKey.AccountName]: yup.string().required(t('account:paymentDetails.withdrawableForm.noAccountName')),
    [ModelKey.SortCode]: yup
      .string()
      .required()
      .test(
        ModelKey.SortCode,
        t('account:paymentDetails.withdrawableForm.exceedSortCodeLimit'),
        (sortCode) => (sortCode || '').length === 6,
      )
      .required(t('account:paymentDetails.withdrawableForm.noSortCode')),
    [ModelKey.AccountNumber]: yup
      .string()
      .required()
      .test(
        ModelKey.AccountNumber,
        t('account:paymentDetails.withdrawableForm.exceedAccountNumberLimit'),
        (accountNumber) => (accountNumber || '').length === 8,
      )
      .required(t('account:paymentDetails.withdrawableForm.noAccountNumber')),
  });
};

export const getWithdrawInternationalValidationSchema = (t: TFunction<Namespace<keyof Resources>>) => {
  return yup.object({
    [ModelKey.AccountName]: yup.string().required(t('account:paymentDetails.withdrawableForm.noAccountName')),
    [ModelKey.SortCode]: yup.string().required(t('account:paymentDetails.withdrawableForm.noSortCode')),
    [ModelKey.AccountNumber]: yup
      .string()
      .required()
      .required(t('account:paymentDetails.withdrawableForm.noAccountNumber')),
    [ModelKey.Bic]: yup.string(),
    [ModelKey.Iban]: yup.string(),
  });
};

export const getInsertAmountValidationSchema = (
  t: TFunction<Namespace<keyof Resources>>,
  walletBalance: number | null,
  minAmount = 0,
): ModelValidationSchemaType => ({
  [ModelKey.Amount]: yup
    .string()
    .test(
      'amount',
      t('account:paymentDetails.withdrawableForm.greaterThanMinRequired', { minAmount }),
      (amount) => amount === undefined || Number(amount) > 0,
    )
    .test(
      'amount',
      t('account:paymentDetails.withdrawableForm.exceedWalletBalance'),
      (amount) => amount === undefined || Number(amount) <= (walletBalance || 0),
    )
    .required(t('wallet:topUpModal.amount.error.noAmountEntered')),
});

export const validateWithdrawFundsForm = (
  amount: string,
  walletBalance: number | null,
  fieldValid: boolean,
): boolean => {
  if (fieldValid && walletBalance) {
    return amount !== '' && walletBalance >= parseFloat(amount);
  }
  return false;
};
