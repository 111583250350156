import { useQuery } from '@apollo/client';
import { SelectOption } from '@cultwines/zellar-client-sdk';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '.';
import { GET_VINTAGES_BY_WINE_ID } from '../../graphql/queries/vintagesByWineId';
import { VintagesByWineIdQuery, VintagesByWineIdQueryVariables, WineVintage } from '../../__generated__/graphql';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '120px',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 700,
    lineHeight: theme.typography.body1.lineHeight,

    [theme.breakpoints.up('sm')]: {
      width: '160px',
    },
  },
}));

interface VintageSelectProps {
  wineId: number | undefined;
  onChange: (option: SelectOption | null) => void;
  sortOrder?: 'asc' | 'desc';
}

function selectOptions(vintages: WineVintage[] | null | undefined, sortOrder: 'asc' | 'desc'): SelectOption[] {
  if (!vintages?.length) {
    return [];
  }
  const options = vintages.reduce((acc, cur) => {
    if (!cur) return acc;

    const { id, vintage } = cur;
    if (id && vintage) {
      acc.push({
        value: id,
        label: vintage.toString(),
      });
    }
    return acc;
  }, [] as SelectOption[]);

  return [...options].sort((a, b) =>
    sortOrder === 'desc' ? Number(b.label) - Number(a.label) : Number(a.label) - Number(b.label),
  );
}

function selectActiveOption(
  vintages: WineVintage[] | null | undefined,
  sortDirection: 'asc' | 'desc',
): SelectOption | null {
  if (!vintages?.length) {
    return null;
  }
  const { id, vintage } = [...vintages].sort((a, b) =>
    sortDirection === 'desc' ? b.vintage - a.vintage : a.vintage - b.vintage,
  )[0];
  return {
    value: id,
    label: vintage.toString(),
  };
}

export default function VintageSelect({ wineId, onChange, sortOrder = 'desc' }: VintageSelectProps): JSX.Element {
  const [optionId, setOptionId] = useState<number | undefined>(undefined);
  const classes = useStyles();
  const { t } = useTranslation();
  const { error, data, loading } = useQuery<VintagesByWineIdQuery, Partial<VintagesByWineIdQueryVariables>>(
    GET_VINTAGES_BY_WINE_ID,
    {
      variables: { wineId },
      skip: !wineId,
    },
  );

  const options = useMemo(
    () => selectOptions(data?.productWineVintages as WineVintage[], sortOrder),
    [data, sortOrder],
  );
  const initialOption = useMemo(
    () => selectActiveOption(data?.productWineVintages as WineVintage[], sortOrder),
    [data, sortOrder],
  );

  useEffect(() => {
    setOptionId(initialOption?.value);
    onChange(initialOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOption]);

  function handleChange(selectedId: number) {
    const selectedOption = options.find((vintage) => vintage.value === selectedId);
    if (selectedOption) {
      setOptionId(selectedOption.value);
      onChange(selectedOption);
    }
  }

  if (error) {
    return <Typography>Failed to load data.</Typography>;
  }
  return (
    <Select
      optionId={optionId}
      setOption={handleChange}
      options={options}
      inputClass={classes.select}
      placeholder={t('common:select')}
      loading={loading}
    />
  );
}
