import { useMutation, useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import Skeleton from '@mui/material/Skeleton';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg';
import { ReactComponent as PadlockOpenIcon } from '../../../assets/icons/padlock-open.svg';
import { humanReadableDate } from '../../../utils/humanReadableDate';
import { humanReadableDateToDefault } from '../../../utils/humanReadableDateToDefault';
import { logError } from '../../../utils/logger';
import AddPaymentOptionModal from '../../AddPaymentOptionModal';
import DateFieldLabel from '../../DateFieldLabel';
import AccountGroupField from '../../MyAccountFields/Group';
import TextField from '../../TextField';
import Typography from '../../Typography';
import { useAccountDetailsStyles } from './styles';
import { useEditDateOfBirth } from './useEditDateOfBirth';
import { useEditEmailAddress } from './useEditEmailAddress';
import { PasswordDetails, useEditPassword } from './useEditPassword';
import { graphql } from '../../../__generated__';

const GET_USER_QUERY = graphql(`
  query GetUserAccountData {
    authUser {
      userId
      emailAddress
      firstName
      surname
      dateOfBirth
    }
  }
`);

const UPDATE_USER_MUTATION = graphql(`
  mutation UpdateUserData($user: AuthUpdateUserDtoInput!) {
    authUserUpdate(updateUserInput: $user) {
      userId
    }
  }
`);

const UPDATE_USER_PASSWORD_MUTATION = graphql(`
  mutation UpdateUserPassword($passwordData: AuthChangePasswordInput!) {
    authChangePassword(changePasswordInput: $passwordData) {
      wasPasswordChanged
    }
  }
`);

interface AccountDetailsProps {
  className?: string;
}

export default function AccountDetails({ className = '' }: AccountDetailsProps): JSX.Element {
  const classes = useAccountDetailsStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const { data, loading, error: failedToGetUserAccountData } = useQuery(GET_USER_QUERY);
  const [updateUser, { loading: updateUserLoading, error: updateUserError }] = useMutation(UPDATE_USER_MUTATION, {
    refetchQueries: [{ query: GET_USER_QUERY }],
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });
  const [updatePassword, { loading: updatePasswordLoading, error: updatePasswordError }] = useMutation(
    UPDATE_USER_PASSWORD_MUTATION,
    {
      // Catches network errors and returns them in errors in response
      onError: () => null,
    },
  );

  if (failedToGetUserAccountData) {
    logError({
      originalError: failedToGetUserAccountData,
      error: new Error('Failed to get user account data'),
      filename: 'AccountDetails',
    });
  }

  async function handleEmailAddressUpdated(newEmailAddress: string) {
    const { errors } = await updateUser({
      variables: {
        user: {
          emailAddress: newEmailAddress,
          firstName: data!.authUser.firstName,
          surname: data!.authUser.surname,
          dateOfBirth: data!.authUser.dateOfBirth,
        },
      },
    });
    if (errors) {
      logError({
        originalError: errors[0],
        error: new Error('Failed to update email address'),
        filename: 'AccountDetails',
        additionalInfo: {
          errors: JSON.stringify(errors),
        },
      });
      enqueueSnackbar(t('auth:emailAddress.failure'), { variant: 'error' });
    } else {
      enqueueSnackbar(t('auth:emailAddress.success'), {
        variant: 'success',
      });
    }
  }

  async function handleDateOfBirthUpdated(newDateOfBirth: string) {
    const { errors } = await updateUser({
      variables: {
        user: {
          emailAddress: data!.authUser.emailAddress,
          firstName: data!.authUser.firstName,
          surname: data!.authUser.surname,
          dateOfBirth: humanReadableDateToDefault(newDateOfBirth),
        },
      },
    });
    if (errors) {
      logError({
        originalError: errors[0],
        error: new Error('Failed to update date of birth'),
        filename: 'AccountDetails',
        additionalInfo: {
          errors: JSON.stringify(errors),
        },
      });
      enqueueSnackbar(t('account:accountDetails.dateOfBirth.failure'), { variant: 'error' });
    } else {
      enqueueSnackbar(t('account:accountDetails.dateOfBirth.success'), {
        variant: 'success',
      });
    }
  }

  async function handlePasswordUpdated({
    passwordNew,
    passwordCurrent,
    passwordConfirm,
  }: PasswordDetails): Promise<void> {
    const { errors } = await updatePassword({
      variables: {
        passwordData: {
          clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
          passwordCurrent,
          passwordConfirm,
          passwordNew,
        },
      },
    });
    if (errors) {
      logError({
        originalError: errors[0],
        error: new Error('Failed to update password'),
        filename: 'AccountDetails',
        additionalInfo: {
          errors: JSON.stringify(errors),
        },
      });
      enqueueSnackbar(t('auth:password.failure'), { variant: 'error' });
    } else {
      enqueueSnackbar(t('auth:password.success'), { variant: 'success' });
    }
  }

  const editEmailFormik = useEditEmailAddress({
    emailAddress: data?.authUser.emailAddress ?? '',
    emailAddressUpdated: handleEmailAddressUpdated,
  });

  const editPasswordFormik = useEditPassword({
    passwordUpdated: handlePasswordUpdated,
    passwordConfirm: '',
    passwordNew: '',
    passwordCurrent: '',
  });

  const editDateOfBirth = useEditDateOfBirth({
    dateOfBirthUpdated: handleDateOfBirthUpdated,
  });

  return (
    <div className={className}>
      <Typography variant="h4">{t('account:navigation.accountDetails')}</Typography>
      <div className={classes.fields}>
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height="74px" />
        ) : (
          <AccountGroupField
            Icon={EmailIcon}
            content={
              <div>
                <InputLabel classes={{ root: classes.inputLabel }} htmlFor="emailAddress">
                  <Typography variant="subtitle1">{t('account:accountDetails.email.label')}</Typography>
                </InputLabel>
                <Typography variant="body1" zellarColour="textLabel">
                  {data?.authUser.emailAddress}
                </Typography>
              </div>
            }
            groupContent={
              <form onSubmit={editEmailFormik.handleSubmit} className={classes.expandedContent}>
                <TextField
                  id="emailAddress"
                  name="emailAddress"
                  placeholder={data?.authUser.emailAddress}
                  value={editEmailFormik.values.emailAddress}
                  onChange={editEmailFormik.handleChange}
                  onBlur={editEmailFormik.handleBlur}
                  error={editEmailFormik.touched.emailAddress && Boolean(editEmailFormik.errors.emailAddress)}
                  helperText={editEmailFormik.touched.emailAddress && editEmailFormik.errors.emailAddress}
                  variant="outlined"
                  type="email"
                  className={classes.halfWidth}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    updateUserLoading ||
                    editEmailFormik.isSubmitting ||
                    !editEmailFormik.isValid ||
                    !editEmailFormik.dirty
                  }
                >
                  {t('common:confirm')}
                  {updateUserLoading && !updateUserError && <CircularProgress color="primary" size={16} />}
                </Button>
              </form>
            }
          />
        )}
        <AccountGroupField
          content={
            <div>
              <Typography variant="subtitle1">{t('account:accountDetails.password.label')}</Typography>
              <Typography variant="body1" zellarColour="textLabel">
                &lowast;&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;
              </Typography>
            </div>
          }
          groupContent={
            <form onSubmit={editPasswordFormik.handleSubmit} className={classes.expandedContent}>
              <div className={classes.passwordFields}>
                <div>
                  <InputLabel className={classes.textFieldLabel} htmlFor="passwordCurrent">
                    <Typography customVariant="subtitle1Bold">
                      {t('account:accountDetails.password.form.passwordCurrent')}
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="passwordCurrent"
                    name="passwordCurrent"
                    placeholder={t('account:accountDetails.password.form.passwordCurrent')}
                    value={editPasswordFormik.values.passwordCurrent}
                    onChange={editPasswordFormik.handleChange}
                    onBlur={editPasswordFormik.handleBlur}
                    error={
                      editPasswordFormik.touched.passwordCurrent && Boolean(editPasswordFormik.errors.passwordCurrent)
                    }
                    helperText={editPasswordFormik.touched.passwordCurrent && editPasswordFormik.errors.passwordCurrent}
                    type="password"
                    variant="outlined"
                  />
                </div>
                <div>
                  <InputLabel className={classes.textFieldLabel} htmlFor="passwordNew">
                    <Typography customVariant="subtitle2">
                      {t('account:accountDetails.password.form.passwordNew')}
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="passwordNew"
                    name="passwordNew"
                    placeholder={t('account:accountDetails.password.form.passwordNew')}
                    value={editPasswordFormik.values.passwordNew}
                    onChange={editPasswordFormik.handleChange}
                    onBlur={editPasswordFormik.handleBlur}
                    error={editPasswordFormik.touched.passwordNew && Boolean(editPasswordFormik.errors.passwordNew)}
                    helperText={editPasswordFormik.touched.passwordNew && editPasswordFormik.errors.passwordNew}
                    type="password"
                    variant="outlined"
                  />
                </div>
                <div>
                  <InputLabel className={classes.textFieldLabel} htmlFor="passwordConfirm">
                    <Typography customVariant="subtitle2">{t('auth:passwordConfirmation.label')}</Typography>
                  </InputLabel>
                  <TextField
                    id="passwordConfirm"
                    name="passwordConfirm"
                    placeholder={t('auth:passwordConfirmation.label')}
                    value={editPasswordFormik.values.passwordConfirm}
                    onChange={editPasswordFormik.handleChange}
                    onBlur={editPasswordFormik.handleBlur}
                    error={
                      editPasswordFormik.touched.passwordConfirm && Boolean(editPasswordFormik.errors.passwordConfirm)
                    }
                    helperText={editPasswordFormik.touched.passwordConfirm && editPasswordFormik.errors.passwordConfirm}
                    type="password"
                    variant="outlined"
                  />
                </div>
              </div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  updatePasswordLoading ||
                  editPasswordFormik.isSubmitting ||
                  !editPasswordFormik.isValid ||
                  !editPasswordFormik.dirty
                }
              >
                {t('common:confirm')}
                {updatePasswordLoading && !updatePasswordError && <CircularProgress color="primary" size={16} />}
              </Button>
            </form>
          }
          Icon={PadlockOpenIcon}
        />
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height="74px" />
        ) : (
          <AccountGroupField
            content={
              <div>
                <Typography variant="subtitle1">{t('account:accountDetails.dateOfBirth.label')}</Typography>
                <Typography variant="body1" zellarColour="textLabel">
                  {data?.authUser.dateOfBirth
                    ? humanReadableDate(data.authUser.dateOfBirth)
                    : t('account:accountDetails.dateOfBirth.notSet')}
                </Typography>
              </div>
            }
            groupContent={
              <form onSubmit={editDateOfBirth.handleSubmit} className={classes.expandedContent}>
                <div>
                  <InputLabel className={classes.textFieldLabel} htmlFor="dateOfBirth">
                    <DateFieldLabel
                      labelVariant="subtitle2"
                      captionColour="textLabel"
                      label={t('account:accountDetails.dateOfBirth.label')}
                    />
                  </InputLabel>
                  <InputMask
                    mask="99/99/9999"
                    value={editDateOfBirth.values.dateOfBirth}
                    onChange={editDateOfBirth.handleChange}
                    onBlur={editDateOfBirth.handleBlur}
                  >
                    {() => (
                      <TextField
                        name="dateOfBirth"
                        placeholder="DD/MM/YYYY"
                        error={editDateOfBirth.touched.dateOfBirth && Boolean(editDateOfBirth.errors.dateOfBirth)}
                        helperText={editDateOfBirth.touched.dateOfBirth && editDateOfBirth.errors.dateOfBirth}
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    updateUserLoading ||
                    editDateOfBirth.isSubmitting ||
                    !editDateOfBirth.isValid ||
                    !editDateOfBirth.dirty
                  }
                >
                  {t('common:confirm')}
                  {updateUserLoading && !updateUserError && <CircularProgress color="primary" size={16} />}
                </Button>
              </form>
            }
            Icon={PadlockOpenIcon}
          />
        )}
        {/* Not needed for MVP */}
        {/* <AccountGroupField
          Icon={SmartphoneIcon}
          content={
            <div>
              <Typography variant="subtitle1">{t('account:accountDetails.mobile.label')}</Typography>
              <Typography variant="body1">{t('account:accountDetails.mobile.prompt')}</Typography>
            </div>
          }
          disabled
        />
        <AccountBooleanField
          Icon={KeyIcon}
          formControlName="twofa"
          content={
            <div>
              <InputLabel classes={{ root: classes.inputLabel }} disabled htmlFor="twofa">
                <Typography variant="subtitle1">{t('account:accountDetails.twoFactorAuth')}</Typography>
              </InputLabel>
            </div>
          }
          onClick={() => {}}
          disabled
        /> */}
        {/* Not needed for MVP */}
        {/* <AccountGroupField
          Icon={CreditCardIcon}
          content={
            <div>
              <Typography variant="subtitle1">{t('account:accountDetails.paymentOptions.label')}</Typography>
              <Typography variant="body1" zellarColour="textLabel">
                {t('account:accountDetails.paymentOptions.prompt')}
              </Typography>
            </div>
          }
          groupContent={
            <div className={classes.expandedContent}>
              <Button
                classes={{ label: classes.buttonLabel }}
                variant="contained"
                color="primary"
                onClick={() => setPaymentModalVisible(true)}
              >
                {t('wallet:addPaymentOption')}
              </Button>
            </div>
          }
        /> */}
        {/* Not needed for MVP */}
        {/* <AccountSegueField
          Icon={ZellarLogo}
          disabled
          content={
            <div>
              <Typography variant="subtitle1">{t('account:accountDetails.plan')}</Typography>
              <Typography variant="body1">Free</Typography>
            </div>
          }
        /> */}
      </div>
      {paymentModalVisible && (
        <AddPaymentOptionModal open={paymentModalVisible} onClose={() => setPaymentModalVisible(false)} />
      )}
    </div>
  );
}
