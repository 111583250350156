import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Divider from '../../components/Divider';
import MonthwiseFlow from '../../components/MonthwiseFlow';
// import PaymentOptions from '../../components/PaymentOptions';
import TransactionHistoryTable from '../../components/TransactionHistoryTable';
import WalletSummary from '../../components/WalletSummary';
import CardPaymentErrorModal from '../../components/CardPaymentCallbackModal/errorContent';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';
import CardPaymentSuccessModal from '../../components/CardPaymentCallbackModal/successContent';
import SavedCards from '../../components/SavedCards';
import useMediaQuery from '../../hooks/useMediaQuery';
import TopUpModal from '../../components/TopUpModal';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
  row: {
    display: 'flex',
    gap: theme.spacing(10),
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  halfWidth: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: `calc(50% - ${theme.spacing(10)})`,
    },
  },
  transactionsContainer: {
    flexGrow: 1,
  },
  paymentOptionsContainer: {
    [theme.breakpoints.up('lg')]: {
      width: 400,
    },
    width: '100%',
  },
}));

export default function Market(): JSX.Element {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const { showCarPaymentErrorModal, showCarPaymentSuccessModal, showAddPaymentCardModal, showAddCardSuccessModal } =
    useCardPaymentCallback();
  const greaterThanLg = useMediaQuery(theme.breakpoints.up('lg'));

  function handleCloseModal() {
    history.push({ pathname: location.pathname, search: location.search, hash: '' });
  }

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <WalletSummary className={classes.halfWidth} />
        <MonthwiseFlow className={classes.halfWidth} />
      </div>
      <Divider variant="fullWidth" customVariant="primary" />
      <div className={classes.row}>
        <div className={classes.transactionsContainer}>
          <TransactionHistoryTable />
        </div>
        {greaterThanLg && <Divider orientation="vertical" flexItem />}
        <div className={classes.paymentOptionsContainer}>
          <SavedCards />
        </div>
      </div>

      {showCarPaymentErrorModal && <CardPaymentErrorModal open={showCarPaymentErrorModal} onClose={handleCloseModal} />}
      {showCarPaymentSuccessModal && (
        <CardPaymentSuccessModal open={showCarPaymentSuccessModal} onClose={handleCloseModal} />
      )}
      {showAddCardSuccessModal && (
        <CardPaymentSuccessModal open={showAddCardSuccessModal} onClose={handleCloseModal} isAddedCard />
      )}
      {showAddPaymentCardModal && <TopUpModal open={showAddPaymentCardModal} onClose={handleCloseModal} isAddNewCard />}
    </div>
  );
}
