import makeStyles from '@mui/styles/makeStyles';
import { colours } from '../../theme/light/palette';

export const useStyles = makeStyles((theme) => ({
  columnBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  detailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.dividers.tertiary}`,
    background: theme.palette.grey[50],
    borderRadius: '4px',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  selectedItem: {
    borderColor: `${colours.dmPurple} !important`,
  },
  clientName: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  manageCard: {
    width: 85,
    height: 20,
    fontSize: 12,
    textTransform: 'capitalize',
    color: colours.lightGrey,
    background: colours.dmPurple,
    borderRadius: 4,
    padding: 0,
    '&:hover': {
      background: theme.palette.button.tertiary.background.hover,
    },
  },
  mit: {
    fontStyle: 'italic',
    textTransform: 'capitalize',
    lineHeight: '0.75rem',
    marginTop: 'auto',
  },
  checkMark: {
    marginLeft: -4,
  },
}));
