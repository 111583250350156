import React from 'react';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CircleCheckmark } from '../../assets/images/circle-checkmark.svg';
import { ReactComponent as CrossedCircleCheckmark } from '../../assets/images/crossed-circle-checkmark.svg';
import { ReactComponent as CheckMark } from '../../assets/icons/checkmark.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';
import { Mode, TradeVariant } from './types';
import { ModalError } from '../../types/ModalError';
import { DialogContent } from '../Dialog/DialogContent';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  iconContainer: {
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  label: {
    marginBottom: theme.spacing(8),
  },
  button: {
    minWidth: 200,
    alignSelf: 'center',
    marginTop: theme.spacing(6),
  },
  horizontalContainer: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
  },
  crossIcon: {
    stroke: theme.palette.error.main,
  },
}));

type OrderResult = 'success' | 'failure';

interface OrderResultPropsBase {
  onClose: () => void;
  onConfirm: () => void;
  tradeType: TradeVariant;
  result: OrderResult;
  error?: ModalError;
}

interface CreateOrderResultProps extends OrderResultPropsBase {
  mode: Mode.Create;
}

interface CancelOrderResultProps extends OrderResultPropsBase {
  mode: Mode.Cancel;
}

interface EditOrderResultProps extends OrderResultPropsBase {
  mode: Mode.Edit;
  cancelFailed?: boolean;
  createFailed?: boolean;
}

export default function Result(
  props: CreateOrderResultProps | EditOrderResultProps | CancelOrderResultProps,
): JSX.Element {
  const { onClose, onConfirm, tradeType, result, mode, error } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.modalContainer}>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {' '}
      </DialogTitle>
      <DialogContent className={classes.modalContainer}>
        <div className={classes.iconContainer}>
          {result === 'success' ? <CircleCheckmark /> : <CrossedCircleCheckmark />}
        </div>
        <Typography className={classes.title} variant="h2">
          {error?.title ?? t(`product:detailsModal.${tradeType}.${mode}.${result}.title` as const)}
        </Typography>
        <Typography className={classes.label} variant="body1">
          {error?.message ?? t(`product:detailsModal.${tradeType}.${mode}.${result}.message` as const)}
        </Typography>
        {mode === Mode.Edit &&
          result === 'failure' &&
          ((props as EditOrderResultProps).cancelFailed || (props as EditOrderResultProps).createFailed) && (
            <>
              <div className={classes.horizontalContainer}>
                {(props as EditOrderResultProps).cancelFailed ? (
                  <>
                    <Cross className={classes.crossIcon} />
                    <Typography customVariant="body1">
                      {t(`product:detailsModal.${tradeType}.edit.cancel.failure` as const)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <CheckMark />
                    <Typography customVariant="body1">
                      {t(`product:detailsModal.${tradeType}.edit.cancel.success` as const)}
                    </Typography>
                  </>
                )}
              </div>
              <div className={classes.horizontalContainer}>
                {(props as EditOrderResultProps).createFailed ? (
                  <>
                    <Cross className={classes.crossIcon} />
                    <Typography customVariant="body1">
                      {t(`product:detailsModal.${tradeType}.edit.create.failure` as const)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <CheckMark />
                    <Typography customVariant="body1">
                      {t(`product:detailsModal.${tradeType}.edit.create.success` as const)}
                    </Typography>
                  </>
                )}
              </div>
            </>
          )}
        <Button className={classes.button} variant="contained" color="primary" size="large" onClick={onConfirm}>
          {error?.buttonText ?? t('product:detailsModal.ok')}
        </Button>
      </DialogContent>
    </div>
  );
}
