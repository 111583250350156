import React, { useEffect } from 'react';
import { ActionEventType } from '../../types/Enums';
import { ActiveMarketExtended } from '../../types/ActiveMarketType';
import { ActiveMarketSortObject } from '../../views/Discover/components/ActiveMarkets/types';
import { SortDirection } from '../Table/types';
import { ViewType } from '../Table/Toolbar';
import { FiltersType } from '../Filters';

interface AppState {
  unitSizeValue?: number;
  unitSizeLabel?: string;
  isProductError?: boolean;
}

interface SearchAndFilters {
  seachText?: string;
  filters?: FiltersType<ActiveMarketExtended>;
  isFiltered?: boolean;
  sortFacet?: keyof ActiveMarketSortObject;
  sortDirection?: SortDirection;
  viewType?: ViewType;
}

type ScrollToAction = { type: ActionEventType.SCROLL_TO_EVENT; payload: { x: number; y: number } };
type UpdateStateAction = { type: ActionEventType.UPDATE_STATE; payload: AppState };
type UpdateSearchAndFiltersAction = { type: ActionEventType.SEARCH_AND_FILTERS; payload: SearchAndFilters };
type Action = ScrollToAction | UpdateStateAction | UpdateSearchAndFiltersAction;
type Dispatch = React.Dispatch<Action>;
type State = {
  scrollX: number;
  _ref: React.RefObject<HTMLDivElement>;
  appState: AppState;
  searchAndFilters: SearchAndFilters;
};
type AppLayoutContextProps = {
  children: React.ReactNode;
};

const AppLayoutContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionEventType.SCROLL_TO_EVENT: {
      state._ref.current?.scroll(action.payload.x, action.payload.y);
      return { ...state, scrollX: action.payload.x };
    }
    case ActionEventType.UPDATE_STATE:
      return { ...state, appState: { ...state.appState, ...action.payload } };
    case ActionEventType.SEARCH_AND_FILTERS:
      return { ...state, searchAndFilters: { ...state.searchAndFilters, ...action.payload } };

    default:
      return state;
  }
}
type Reducer = typeof reducer;

function AppLayoutContextProvider({ children }: AppLayoutContextProps): JSX.Element {
  const ref = React.createRef<HTMLDivElement>();
  const initialState: State = {
    _ref: ref,
    scrollX: 0,
    appState: {},
    searchAndFilters: { filters: {}, seachText: '' },
  };
  const [state, dispatch] = React.useReducer<Reducer, State>(reducer, initialState, () => initialState);

  useEffect(() => {
    const path = window.location.href;
    if (!path.includes('/product/')) {
      dispatch({
        type: ActionEventType.UPDATE_STATE,
        payload: {
          unitSizeValue: undefined,
          unitSizeLabel: undefined,
          isProductError: undefined,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return <AppLayoutContext.Provider value={{ dispatch, state }}>{children}</AppLayoutContext.Provider>;
}

function useAppLayoutContext(): { state: State; dispatch: Dispatch } {
  const context = React.useContext(AppLayoutContext);
  if (context === undefined) {
    throw new Error('useAppLayoutContext must be used within a ContentBottomPaddingProvider');
  }

  return context;
}

export { AppLayoutContextProvider, useAppLayoutContext };
