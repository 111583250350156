import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { Tooltip, ClickAwayListener } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Typography from '../Typography';

const ToolTip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRadius: 4,
    padding: theme.spacing(2),
    marginTop: 4,
    border: `1px solid ${theme.palette.text.primary}`,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  calculationToolTipIcon: {
    marginTop: -2,
    textTransform: 'lowercase',
    background: 'transparent',
    border: 'none',
    padding: 0,
    color: theme.palette.textPrimary,
    height: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltip: {
    cursor: 'help',
  },
}));

interface CalculationToolTipProps {
  title: string;
  buttonClasses?: string;
}

export default function CalculationToolTip({ title, buttonClasses = '' }: CalculationToolTipProps): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickAway() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ToolTip
        className={classes.tooltip}
        title={<Typography variant="body2">{title}</Typography>}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        arrow
      >
        <button type="button" onClick={handleOpen} className={clsx(classes.calculationToolTipIcon, buttonClasses)}>
          ⓘ
        </button>
      </ToolTip>
    </ClickAwayListener>
  );
}
