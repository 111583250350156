/* eslint-disable @typescript-eslint/no-var-requires */
import { makeStyles } from 'tss-react/mui';
import * as Highcharts from 'highcharts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatterWholeNumber } from '../../utils/currencyFormatter';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.75),
    color: theme.palette.text.primary,
  },
  /**
   * Unable to reference any of the custom typography
   * variants that have been added here without breaking
   * the application when it tries to render the tooltip.
   * It is directly related to how this component is rendered,
   * as the custom variants can be accessed in other components
   * without issue.
   */
  title: {
    fontSize: '14px',
    fontWeight: 700,
  },
  seriesTitle: {
    fontSize: '12px',
    fontWeight: 700,
  },
}));

interface Props {
  points: Highcharts.TooltipFormatterContextObject[] | undefined;
}

export default function Tooltip({ points }: Props): JSX.Element | null {
  const { classes } = useStyles();
  const { t } = useTranslation();
  if (!points || !points.length) {
    return null;
  }
  const marketValuePoint = points.find((p) => p.series.userOptions.id === 'price');
  const scorePoint = points.find((p) => p.series.userOptions.id === 'score');
  return (
    <span className={classes.container}>
      <span className={classes.title}>{points[0].key}</span>
      {marketValuePoint && (
        <span>
          <span className={classes.seriesTitle}>{t('product:marketValue')}: </span>
          {formatterWholeNumber.format(marketValuePoint.y)}
        </span>
      )}
      {scorePoint && (
        <span>
          <span className={classes.seriesTitle}>{t('product:score')}: </span>
          {scorePoint.y}
        </span>
      )}
    </span>
  );
}
