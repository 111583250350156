import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MaintenanceImg from '../assets/images/maintenance.png';
import Typography from '../components/Typography';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    alignItems: 'center',
    padding: 12,
    textAlign: 'center',
  },
  image: {
    width: '80%',
    height: 'auto',
    maxWidth: 337,
    maxHeight: 337,
  },
}));

export default function MaintenancePage(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <img className={classes.image} src={MaintenanceImg} alt="Undergoing maintenance" />
        <Typography customVariant="h2">CultX is currently undergoing maintenance, please check back later</Typography>
      </div>
    </div>
  );
}
