import { graphql } from '../../__generated__';

export const GET_NOTIFICATION_SETTINGS = graphql(`
  query GetNotificationSettings {
    userNotificationSettings {
      categories {
        id
        name
        types {
          id
          name
          description
          isEnabled
        }
        channels {
          email
          push
        }
      }
    }
  }
`);
