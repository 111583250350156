import React from 'react';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { humanReadableDate } from '../../utils/humanReadableDate';
import Typography from '../Typography';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

interface LastTradeProps {
  lastTradeValue: number | null | undefined;
  lastTradeDate: string | null | undefined;
}

export default function LastTrade({ lastTradeDate, lastTradeValue }: LastTradeProps): JSX.Element {
  return isNull(lastTradeDate) && isNull(lastTradeValue) ? (
    <Typography customVariant="subtitle2">-</Typography>
  ) : (
    <>
      {!isNullOrUndefined(lastTradeValue) && (
        <Typography customVariant="subtitle2">{formatterWholeNumber.format(lastTradeValue)}</Typography>
      )}
      {!isNullOrUndefined(lastTradeDate) && (
        <Typography customVariant="body2" zellarColour="textInformation">
          {humanReadableDate(lastTradeDate)}
        </Typography>
      )}
    </>
  );
}
