/**
 * Queries
 * Further info: https://shopify.dev/concepts/graphql/queries
 */
export { GET_WINE_SCORES } from './queries/getWineScores';
export { UNIT_SIZE_QUERY } from './queries/unitSize';
export { WATCHLIST_ITEMS } from './queries/watchlistItems';
/**
 * Mutations
 * Further info: https://shopify.dev/concepts/graphql/mutations
 */
export { ADD_ASSET_TO_WATCHLIST } from './mutations/addAssetToWatchList';
export { DELETE_ASSET_FROM_WATCHLIST } from './mutations/deleteAssetFromWatchList';
export { CREATE_BID } from './mutations/createBid';
export { CREATE_OFFER } from './mutations/createOffer';
export { CANCEL_ORDER } from './mutations/cancelOrder';

export const ROOT_QUERY = 'ROOT_QUERY';
