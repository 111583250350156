/* eslint-disable react/no-array-index-key */
import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BrokenCircleCheckMarkIcon } from '../../assets/icons/broken-circle-checkmark.svg';
import { ReactComponent as ThumbsUpIcon } from '../../assets/icons/thumbsup.svg';
import { ReactComponent as UndoIcon } from '../../assets/icons/undo.svg';
import { FinancialOperationType as OperationType } from '../../types/FinancialOperationType';
import { formatter } from '../../utils/currencyFormatter';
import Typography from '../Typography';
import useStyles from './styles';
import { graphql } from '../../__generated__';

export const GET_RECENT_TRANSACTIONS = graphql(`
  query GetRecentTransactions {
    financeGetRecent {
      operationType
      operationAmountGbp
      operationSource
      operationDate
    }
  }
`);

export default function TransactionHistoryTable(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { data, error, loading } = useQuery(GET_RECENT_TRANSACTIONS, {
    pollInterval: 5000,
  });

  // sort by most recent desc and take 10
  const transactions =
    data?.financeGetRecent
      .filter((operation) => operation.operationType !== OperationType.Adjustment)
      .sort((a, b) => new Date(b.operationDate).valueOf() - new Date(a.operationDate).valueOf())
      .slice(0, 9) ?? [];

  return (
    <div className={classes.container}>
      <Typography variant="h4">{t('wallet:transactionHistoryTable.title')}</Typography>
      {!loading && error && (
        <div className={classes.placeholder}>
          <Typography variant="h6">{t('wallet:transactionHistoryTable.error')}</Typography>
        </div>
      )}
      {!loading && !error && transactions.length === 0 && (
        <div className={classes.placeholder}>
          <Typography variant="h6">{t('wallet:transactionHistoryTable.empty')}</Typography>
        </div>
      )}
      {(loading || transactions.length > 0) && (
        <TableContainer>
          <Table>
            <TableHead classes={{ root: classes.header }}>
              <TableRow>
                <TableCell classes={{ root: classes.tr }} variant="head">
                  {t('wallet:transactionHistoryTable.headings.activity')}
                </TableCell>
                <TableCell classes={{ root: classes.tr }} variant="head">
                  {t('wallet:transactionHistoryTable.headings.date')}
                </TableCell>
                <TableCell classes={{ root: classes.tr }} variant="head">
                  {t('wallet:transactionHistoryTable.headings.amount')}
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                {new Array(6).fill(0).map((_, i) => (
                  <TableRow key={i}>
                    <TableCell classes={{ root: classes.skeletonTableCellRoot }}>
                      <Skeleton
                        aria-label={`transaction-history-table-row-${i}-cell-1-skeleton`}
                        width="100%"
                        height={40}
                        variant="rectangular"
                        key={`row-${i}-cell-1`}
                      />
                    </TableCell>
                    <TableCell classes={{ root: classes.skeletonTableCellRoot }}>
                      <Skeleton
                        aria-label={`transaction-history-table-row-${i}-cell-2-skeleton`}
                        width="100%"
                        height={40}
                        variant="rectangular"
                        key={`row-${i}-cell-2`}
                      />
                    </TableCell>
                    <TableCell classes={{ root: classes.skeletonTableCellRoot }}>
                      <Skeleton
                        aria-label={`transaction-history-table-row-${i}-cell-3-skeleton`}
                        width="100%"
                        height={40}
                        variant="rectangular"
                        key={`row-${i}-cell-3`}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {transactions.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell classes={{ root: classes.cellRoot }}>
                      <div className={classes.horizontalContainer}>
                        {item.operationType === OperationType.Purchase && <ThumbsUpIcon />}
                        {item.operationType === OperationType.Refund && <UndoIcon />}
                        {item.operationType === OperationType.Sale && (
                          <BrokenCircleCheckMarkIcon stroke={theme.palette.textPrimary} />
                        )}
                        {item.operationType === OperationType.Fresh && (
                          <BrokenCircleCheckMarkIcon stroke={theme.palette.textPrimary} />
                        )}
                        <Typography customVariant="subtitle3" className={classes.uppercase}>
                          {item.operationType}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell classes={{ root: classes.cellRoot }}>
                      <Typography customVariant="body1">{new Date(item.operationDate).toLocaleDateString()}</Typography>
                    </TableCell>
                    <TableCell classes={{ root: classes.cellRoot }}>
                      <Typography
                        className={
                          item.operationType === OperationType.TopUp ? classes.positiveValue : classes.negativeValue
                        }
                        customVariant="subtitle2"
                      >
                        {item.operationType === OperationType.TopUp ? '+' : '-'}
                        {formatter.format(item.operationAmountGbp)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
