import { ApolloCache, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { CREATE_CARD_PAYMENT } from '../graphql/mutations/createCardPayment';
import { CardPaymentInput, CreateCardPaymentMutationMutation } from '../__generated__/graphql';

interface MutationResponse {
  data: CreateCardPaymentMutationMutation | null | undefined;
  errors: readonly GraphQLError[] | undefined;
}

export type CallbackFunction = (params: CardPaymentInput) => Promise<MutationResponse>;

interface ReturnProps {
  createCardPayment: CallbackFunction;
  loading: boolean;
}

export const useCreateCardPayment = (options = {}): ReturnProps => {
  const [postCardPayment, { loading }] = useMutation(CREATE_CARD_PAYMENT, {
    onError: () => null,
  });

  const createCardPayment = useCallback(
    async (variables: CardPaymentInput) => {
      const { data, errors } = await postCardPayment({
        variables: { args: variables },
        update: (cache: ApolloCache<CreateCardPaymentMutationMutation>) => {
          if (variables.storeCardFlag) {
            cache.modify({
              fields: {
                paymentGetCardDetails() {
                  return undefined;
                },
              },
            });
          }
        },
        ...options,
      });

      return { data, loading, errors };
    },
    [postCardPayment, loading, options],
  );
  return { createCardPayment, loading };
};
